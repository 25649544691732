/* eslint-disable prettier/prettier */
import axios from 'axios';

export default axios.create({
  baseURL:
    window.location.hostname === 'partners.cloudez.io'
      ? 'https://api.cloudez.io/'
      : process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : 'https://api.cloudez.io/',
});
