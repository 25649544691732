import { useCallback, useState } from 'react';

// import { updateTicketService } from 'services/ticket';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { Input } from 'components/Input';
import { Button } from '@cloudez/cloudez-design-system';
import Modal from 'components/Modal';

import { Header, Icon, Text, Footer } from 'components/ListingModal/styles';

import { deleteBillingService } from 'services/common';

import { Content, Title } from './styles';

const UpdateModal = ({ show, setShow, code, action }) => {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState('');

  const deleteBilling = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await deleteBillingService(code);

      action(data);

      setLoading(false);
      setShow();
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, [code]);

  return (
    <Modal width="498px" show={show} setShow={setShow}>
      <Header style={{ borderBottom: '0px' }}>
        <Icon>
          <FontAwesomeIcon
            style={{ fontSize: '20px' }}
            icon={faExclamationTriangle}
          />
        </Icon>
        <Text>
          <Title>Atualizar informações financeiras</Title>
        </Text>
      </Header>

      <Content>
        <div>
          <b> Atenção!</b> Para alterar suas informações financeiras, primeiro
          <br />
          precisaremos remover <b>TODOS</b> os seus dados antigos.
        </div>
        <div>
          Por favor, digite <b style={{ color: '#fb264b' }}>REMOVER</b> no campo
          abaixo
          <br /> para confirmar
        </div>

        <div>
          <Input value={value} onChange={e => setValue(e.target.value)} />
        </div>
      </Content>

      <Footer
        style={{ borderTop: '0px', justifyContent: 'center', gap: '20px' }}
      >
        <Button onClick={() => setShow(false)} icon={undefined} outline>
          Cancelar
        </Button>
        <Button
          disabled={loading || value !== 'REMOVER'}
          icon={loading ? faSpinner : undefined}
          onClick={deleteBilling}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default UpdateModal;
