import styled from 'styled-components';

export const DownloadCard = styled.div`
  width: 100%;
  height: 340px;

  background: ${props => props.theme.interface1};
  border-radius: 5px;

  padding: 35px 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    line-height: 1.4;
  }

  svg {
    color: #d92947;
  }

  span {
    font-weight: bold;
    font-size: 20px;
    color: ${props => props.theme.interface5};
    text-align: center;
    line-height: 24px;

    margin-top: 10px;
  }

  p {
    font-size: 14px;
    text-align: center;
    color: ${props => props.theme.interface5};
  }

  p.extension {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;
