import axios from 'axios';

const local = process.env.NODE_ENV === 'development';

const channels = {
  'react-bug':
    'AAAAdMXrExc/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=U0s5amkIiVHpOAr419sZsfzStT3NRyu-vE6a1SFfQi8%3D',
  'apoio-comercial':
    'AAAAtjsAaI0/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=ngy25Q7hbiRU5GvRvvmWivgDO62X_VALjf3XvMd3I3A%3D',
};

export default async (text = '', channel) => {
  if (local) {
    console.log(text);
    return;
  }

  try {
    await axios.post(
      `https://chat.googleapis.com/v1/spaces/${channels[channel]}`,
      {
        text,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (e) {
    console.log(e);
  }
};
