import { useContext } from 'react';
import {
  NotificationsContext,
  NotificationsState,
} from 'context/notifications';

export function useNotifications(): NotificationsState {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'useNotifications must be used within an NotificationsProvider',
    );
  }

  return context;
}
