import styled, { css } from 'styled-components';

interface ITabTitleProps {
  isOpen: boolean;
  first: boolean;
  last: boolean;
}

export const TabTitle = styled.div<ITabTitleProps>`
  padding: 8px 14px;
  color: ${props =>
    !props.isOpen ? props.theme.brand_primary_color : props.theme.interface1};
  background: ${props =>
    props.isOpen ? props.theme.brand_primary_color : props.theme.interface1};

  font-size: 17px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.first
      ? css`
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        `
      : props.last && !props.isOpen
      ? css`
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        `
      : null}
`;

export const Sync = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.color};
  padding: 10px 0px;

  p {
    font-size: 13px;
  }
`;

export const TabContent = styled.ul`
  background: ${props => props.theme.interface2};
  padding: 10px 10px;
`;
