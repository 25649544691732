import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useLayout } from 'hooks';

import Partner from 'assets/img/stamps/partner.svg';
import PartnerDark from 'assets/img/stamps/partner-dark.svg';
import Certified from 'assets/img/stamps/certified.svg';
import CertifiedDark from 'assets/img/stamps/certified-dark.svg';
import Master from 'assets/img/stamps/master.svg';
import MasterDark from 'assets/img/stamps/master-dark.svg';

import { TextBox } from './styles';

const Presentation: React.FC = () => {
  const theme = useContext(ThemeContext);
  const { sideMenuCollapsed } = useLayout();

  const menuLinks = document.querySelectorAll('.stamps a[href^="#"]');

  function getDistanceFromTheTop(element) {
    const id = element.getAttribute('href');
    return document.querySelector(id).offsetTop;
  }

  function scrollToSection(event) {
    event.preventDefault();
    const distanceFromTheTop = getDistanceFromTheTop(event.target) - 25;
    smoothScrollTo(0, distanceFromTheTop);
  }

  menuLinks.forEach(link => {
    link.addEventListener('click', scrollToSection);
  });

  function smoothScrollTo(
    endX: number,
    endY: number,
    duration?: string | number,
  ) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    const easeInOutQuart = (time, from, distance, duration) => {
      if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
      return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);
    }, 1000 / 60);
  }

  return (
    <TextBox sideMenuCollapsed={sideMenuCollapsed}>
      <div className="firstBox">
        <div>
          A cada ano que passa, temos a satisfação de conquistar novos parceiros
          e expandir nossa presença em todos os Estados brasileiros, não apenas
          ajudando pequenos e médios negócios digitais a oferecerem serviços
          diferenciados, mas também ao capacitar nossos parceiros para saírem do
          oceano vermelho e transformarem o mercado com um padrão elevado de
          qualidade nos serviços prestados.
        </div>
        <div>
          Vamos além das tecnologias avançadas e dedicamos esforços para sermos
          uma parte importante das operações dos parceiros. Nossos parceiros são
          parte essencial e insubstituível da nossa empresa.
        </div>
        <div>
          O modelo de negócio escolhido pela Cloudez visa o crescimento contínuo
          e colaborativo. Todos os avanços e ações realizados por nossas equipes
          têm como objetivo tornar o dia a dia dos nossos parceiros mais
          tranquilo, o que permite que foquem mais na gestão de seus negócios
          enquanto as máquinas trabalham a seu favor.
        </div>
        <div>
          Comprometidos com essa missão, nos últimos anos, desenvolvemos ações e
          serviços para diferenciar os negócios das agências que estão conosco e
          amadurecer nossa parceria. Esse compromisso nos impulsiona a colocar
          ainda mais esforços em aprimorar nossa tecnologia.
        </div>
        <div>
          Estamos na fase final de lançamento de uma nova e inovadora tecnologia
          focada em performance e segurança que já está em fase de testes com os
          primeiros clientes e que, em breve, estará à disposição de todos os
          nossos parceiros para que possam incorporar aos seus serviços.
        </div>
      </div>
      <div style={{ justifyContent: 'space-between' }} className="midBox">
        <div>
          Além disso, estamos liberando no próximo semestre a possibilidade de
          contratação de clouds localizados no Brasil minimizando latência e
          aumentando a performance de negócios que dependem dessas
          características em seus negócios.
        </div>
        <div>
          Entretanto, como toda empresa, sofremos com os reajustes anuais dos
          serviços e fornecedores que contratamos, porém desde o início sempre
          optamos por não seguir esse caminho, principalmente porque
          reconhecemos o impacto significativo de qualquer mudança de preço no
          nosso mercado, por isso, tentamos preservar ao máximo os valores
          atuais.
        </div>
        <div>
          No entanto, o aumento de preços em serviços terceirizados, crises
          globais e cenários adversos também afetaram nossa operação. Por este
          motivo, estamos ajustando nossos valores para manter a qualidade do
          nosso produto, equipe e serviços de alta qualidade, reafirmando nosso
          compromisso em oferecer ainda mais a entrega de tecnologia e
          performance.
        </div>
        <div>
          Faremos um reajuste dos valores de nossos planos e junta com uma
          mudança positiva em nosso produto permitindo que, a partir de agora,
          todos os planos da Cloudez contem automaticamente com as
          funcionalidades de CDN e PageSpeed Optimizer. Ajustamos nossa
          estratégia pois entendemos que, ao almejar a máxima democratização de
          tecnologias, não há sentido em limitar o acesso dos nossos parceiros a
          elas.
        </div>
      </div>
      <div className="lastBox">
        <div>
          Diante disso, decidimos encerrar a diferenciação entre Cloud Basic e
          Cloud Turbo, todos os nossos planos terão os mesmos benefícios do modo
          Turbo, mas permanecemos com os planos de Cloud Volume para que possam
          hospedar contas menores e centralizar seus clientes ao educá-los em
          Mentalidade Cloud.
        </div>
        <div>
          Dessa forma, informamos que a partir de 01/11/2023, os valores dos
          planos da Cloudez sofrerão modificações para parceiros do primeiro
          selo (Partner) e a partir do dia 01/12/2023 para o segundo e terceiro
          selo (Certified e Master), conforme a tabela especificada abaixo. Os
          preços dos parceiros de selo Expert e Exclusive não passarão por
          reajustes.
        </div>
        <div className="stampsContainer">
          <p>Confira os novos preços</p>
          <div className="stamps">
            <div>
              <img
                width="145px"
                height="145px"
                src={theme.darkMode ? PartnerDark : Partner}
                alt="Selo Partner"
              />
              <a href="#partner">Veja os novos preços para PARTNER</a>
            </div>
            <div>
              <img
                width="145px"
                height="145px"
                src={theme.darkMode ? CertifiedDark : Certified}
                alt="Selo Partner"
              />
              <a href="#certified">Veja os novos preços para CERTIFIED</a>
            </div>
            <div>
              <img
                width="145px"
                height="145px"
                src={theme.darkMode ? MasterDark : Master}
                alt="Selo Partner"
              />
              <a href="#master">Veja os novos preços para MASTER</a>
            </div>
          </div>
        </div>
      </div>
    </TextBox>
  );
};

export default Presentation;
