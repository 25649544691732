import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Input } from 'components/Input';

interface ISearchProps {
  noResults?: boolean;
  onboard?: boolean;
  block?: boolean;
}

export const SearchWrapper = styled.form<ISearchProps>`
  opacity: 1;
  width: 100%;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 10px;
    font-size: 17px;
    color: ${props =>
      props.noResults ? props.theme.error : props.theme.interface3};
  }

  p {
    display: ${props => (props.noResults ? 'inline-block' : 'none')};
    font-size: 9px;
    font-style: italic;
    color: ${props => props.theme.error};
    position: relative;
    top: 10px;
    left: -120px;
  }

  &:focus-within {
    svg {
      transition: all 0.2s ease-in-out;
      color: ${props =>
        props.noResults ? props.theme.error : props.theme.brand_primary_color};
    }
  }
`;

export const SearchInput = styled(Input)<ISearchProps>`
  padding: 8px 8px 8px 40px;
  width: 330px;
  background-color: ${props => props.theme.darkMode && props.theme.interface1};

  ${props =>
    props.noResults &&
    css`
      border: 2px solid ${props => props.theme.error};
    `};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 65px;
  background: ${props =>
    props.theme.darkMode
      ? transparentize(0.84, props.theme.interface5)
      : transparentize(0.4, props.theme.interface3)};
  border-radius: 5px;
  margin-bottom: 40px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (max-width: 768px) {
    button {
      display: none;
    }

    ${SearchInput} {
      width: 100%;
    }
  }
`;

export const Filters = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 5px 1px 5px;
  overflow: auto;

  button {
    margin-bottom: 4px;
    width: 46%;
    height: 20px;
    font-size: 9px;
    padding: 4px 6px;
    font-weight: normal;
  }
`;

export const FilterHover = styled.div`
  cursor: default;
  line-height: 1.5;
  font-size: 10px;
  padding: 6px;
  position: absolute;
  top: 30px;
  left: -7px;
  display: none;
  border-radius: 5px;
  background: ${props =>
    props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  color: ${props => props.theme.interface1};
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    width: 8px;
    height: 8px;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  }
`;

export const Filter = styled.div`
  position: relative;

  &:hover {
    ${FilterHover} {
      display: block;
    }
  }

  svg {
    font-size: 14px;
    cursor: pointer;
  }
`;

export const FilterWrapper = styled.div`
  height: 100%;
  margin-right: 10px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-right: 5px;
    margin-left: 12px;
  }
`;

export const FilterSeparator = styled.div`
  height: 24px;
  width: 1px;
  margin: 0px 12px;
  background: ${props => props.theme.interface3};

  @media (max-width: 768px) {
    margin: 0px 8px;
  }
`;
