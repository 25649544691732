import { toast } from 'react-toastify';
import history from 'services/history';
import { getInvoiceService } from 'services/invoices';
import { getUserBillingService, getUserService } from 'services/user';
import {
  getCreditcardService,
  getCompanyBillingService,
} from 'services/common';

type Props = {
  type?: string;
  payload: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getInvoice = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    const { data } = await getInvoiceService(id);

    dispatch({
      type: 'GET_INVOICE',
      payload: data,
    });
  } catch (e) {
    if (e?.response?.status === 404) {
      toast.error('Invoice não encontrada');
      history.push('/404');
    }
  }
};

export const getUserBilling = async (
  dispatch: ({ type, payload }: Props) => void,
  user,
) => {
  try {
    const { data } = await getUserBillingService(user);

    dispatch({
      type: 'GET_USER_BILLING',
      payload: data,
    });
  } catch (e) {}
};

export const getCompanyBilling = async (
  dispatch: ({ type, payload }: Props) => void,
  company,
) => {
  try {
    const { data } = await getCompanyBillingService(company);

    dispatch({
      type: 'GET_COMPANY_BILLING',
      payload: data,
    });
  } catch (e) {}
};

export const getUser = async (
  dispatch: ({ type, payload }: Props) => void,
  user,
) => {
  try {
    const { data } = await getUserService(user);

    dispatch({
      type: 'GET_USER',
      payload: data,
    });
  } catch (e) {}
};

export const getCreditCard = async (
  dispatch: ({ type, payload }: Props) => void,
) => {
  try {
    const { data } = await getCreditcardService();

    dispatch({
      type: 'GET_CREDIT_CARD',
      payload: data[0],
    });
  } catch (e) {}
};
