import history from 'services/history';
import {
  getInvoicesService,
  updateInvoiceService,
  deleteInvoiceService,
} from 'services/invoices';
import { toast } from 'react-toastify';

type Props = {
  type?: string;
  payload: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

const mapPriorityStatus = (priority: number) => {
  switch (priority) {
    case 0:
      return undefined;
    case 1:
      return false;
    case 2:
      return true;
    default:
      true;
  }
};

export const getInvoices = async (
  dispatch: ({ type, payload }: Props) => void,
  page: string | number,
  search,
  status,
  orderValue,
  ctrl,
) => {
  setLoading(dispatch, true);

  try {
    const { data } = await getInvoicesService(
      {
        ...search,
        page,
        status,
        [`${orderValue?.column}_order`]: mapPriorityStatus(orderValue?.value),
      },
      ctrl,
    );

    dispatch({
      type: 'GET_INVOICES',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);

    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const updateInvoice = async (
  dispatch: ({ type, payload }: Props) => void,
  field: string,
  value,
  id: number | string,
) => {
  try {
    const { data } = await updateInvoiceService(id, {
      [field]: value,
    });

    dispatch({
      type: 'UPDATE_INVOICE',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_INVOICE_ERROR',
      payload: e?.response?.data,
    });
  }
};

export const deleteInvoice = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    await deleteInvoiceService(id);

    dispatch({
      type: 'DELETE_INVOICE',
      payload: id,
    });
    toast.success('Fatura removida com sucesso');
  } catch (e) {
    toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
  }
};
