import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MainSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  row-gap: 20px;

  gap: 13px;

  justify-content: center;

  flex-wrap: wrap;

  .background {
    box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);

    max-width: 662px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .accessAcademy {
    width: 465px;

    display: flex;
    flex-direction: column;

    gap: 13px;
    align-items: center;

    background-color: ${props => props.theme.interface1};

    padding: 36px;

    border-radius: 5px;

    box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);

    .title {
      font-weight: bold;
      font-size: 24px;

      color: #333333;
    }

    .text {
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;

      width: 100%;

      color: #64728c;
    }

    @media (max-width: 768px) {
      width: 100%;

      button {
        width: 250px;
      }
    }
  }
`;
export const Courses = styled.div`
  gap: 24px;
  display: flex;
  justify-content: center;
  gap: flex;
  flex-direction: row;
  flex-wrap: wrap;

  cursor: pointer;

  img:hover {
    transition: transform 0.2s;

    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    img {
      width: 100%;

      max-width: 400px;
    }
  }
`;
