import styled from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';

export const UndoButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${props => props.theme.brand_secondary_color};
  margin-right: -20px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 70px;
  margin-bottom: 40px;
  /*
  ${Button} {
    margin-left: 62px;
  } */

  @media (max-width: 768px) {
    margin-bottom: 60px;

    ${Button} {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const Title = styled.div`
  margin-left: 30px;

  h3 {
    font-size: 18px;
    a {
      text-decoration: none;
      color: ${props => props.theme.interface6};
    }
  }

  p {
    font-size: 12px;
    color: ${props => props.theme.interface5};
  }
`;

export const Card = styled.div`
  height: 180px;
  padding: 25px 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardHeader = styled.div`
  font-size: 18px;

  font-weight: bold;

  color: ${props => props.theme.interface5};
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Informations = styled.div`
  height: 100%;
  width: 100%;
`;

export const Information = styled.div`
  svg {
    color: #cfd3de;
    font-size: 18px;
  }

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const AddressCard = styled.div`
  height: 100%;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${props => props.theme.interface4};
  text-transform: uppercase;
  display: block;
`;

interface IValueProps {
  mrr?: boolean;
}

export const Value = styled.span<IValueProps>`
  font-size: 14px;
  font-weight: ${props => props.mrr && 'bold'};
  color: ${props =>
    props.mrr ? props.theme.brand_primary_color : props.theme.interface5};
`;

export const Active = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

interface IStatusProps {
  active: boolean;
}

export const Status = styled.div<IStatusProps>`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 4px;
  width: 100%;
  justify-content: space-between;

  color: ${props =>
    props.active ? props.theme.success : props.theme.interface5};

  ${Active} {
    background: ${props =>
      props.active ? props.theme.success : props.theme.interface5};
  }
`;

export const CardBody = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
