import React, { useContext, useState } from 'react';

import { Customers } from 'types/customers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faStar,
  faSpinner,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Col } from 'react-bootstrap';

import {
  Card,
  CardContent,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  ApplicationSubName,
} from 'components/Listing/styles';
import { Acesso, AcessosList, AcessoHover, Value } from 'components/Acessos';

import history from 'services/history';

import CurrencyFormat from 'react-currency-format';
import { ThemeContext } from 'styled-components';
import { CustomerListingContext } from '../_context/state';

interface ClientCardProps {
  user: Customers;
}

const ClientCard: React.FC<ClientCardProps> = ({ user }) => {
  const { updateCustomer } = useContext(CustomerListingContext);

  const [favLoading, setFavLoading] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const theme = useContext(ThemeContext);

  return (
    <ListingItem key={user.id}>
      <Card onClick={() => history.push(`/customers/${user.id}`)}>
        <CardContent>
          <Col
            xs={!showAccess && 10}
            sm={!showAccess && 10}
            md={!showAccess && 4}
            lg="5"
            xl="5"
          >
            <ApplicationValue>
              <FontAwesomeIcon icon={faUser} size="3x" />
              {!showAccess &&
                (user.full_name ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <ApplicationName>{user.full_name}</ApplicationName>
                    <ApplicationSubName>{user.email}</ApplicationSubName>
                  </div>
                ) : (
                  <ApplicationName>{user.email}</ApplicationName>
                ))}
            </ApplicationValue>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <ApplicationValue style={{ fontWeight: 'normal' }}>
              <p>
                <CurrencyFormat
                  value={user.mrr?.amount}
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={user?.mrr?.currency === 'BRL' ? 'R$ ' : '$ '}
                />
              </p>
            </ApplicationValue>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <ApplicationValue style={{ fontWeight: 'normal' }}>
              <p>{user.clouds}</p>
            </ApplicationValue>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <ApplicationValue style={{ fontWeight: 'normal' }}>
              <p>{user.invoices}</p>
            </ApplicationValue>
          </Col>

          {!showAccess && (
            <Col className="d-sm-flex d-md-none">
              <div>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    color: '#64728C',
                  }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowAccess(true);
                  }}
                />
              </div>
            </Col>
          )}

          <Col
            xl="2"
            lg="2"
            md="2"
            sm="2"
            className={!showAccess && 'd-none d-md-flex'}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowAccess(false);
            }}
            style={{
              background: showAccess && theme.interface3,
              height: '100%',
              width: '100%',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
          >
            <AcessosList showAccess={showAccess}>
              <Acesso active hover fixed={user.is_blocked}>
                <FontAwesomeIcon icon={faBan} />
                <AcessoHover>
                  <Value>
                    <span>Blocked</span>
                  </Value>
                </AcessoHover>
              </Acesso>
              <Acesso active hover favorite fixed={user.is_pinned}>
                <FontAwesomeIcon
                  data-testid="favoriteIcon"
                  onClick={async e => {
                    setFavLoading(true);
                    e.stopPropagation();

                    await updateCustomer('is_pinned', !user.is_pinned, user.id);

                    setFavLoading(false);
                  }}
                  spin={favLoading}
                  icon={favLoading ? faSpinner : faStar}
                />
                <AcessoHover>
                  <Value>
                    <span>Favorite</span>
                  </Value>
                </AcessoHover>
              </Acesso>
            </AcessosList>
          </Col>
        </CardContent>
      </Card>
    </ListingItem>
  );
};

export default ClientCard;
