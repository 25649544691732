import React from 'react';
import { Router } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';

import { fas } from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';
import Routes from 'routes';

import ErrorBoundary from 'components/ErrorBoundary';
import GlobalStyle from 'styles/global';
import AppProvider from 'context';

library.add(fas);

const App = () => {
  return (
    <React.StrictMode>
      <Router history={history}>
        <AppProvider>
          <GlobalStyle />
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </AppProvider>
      </Router>
    </React.StrictMode>
  );
};

export default App;
