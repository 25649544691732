import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 40px 0px;

  @media (max-width: 768px) {
    button {
      width: 60px;

      span {
        display: none;
      }

      &::after {
        content: 'Novo';
      }
    }
  }
`;

export const CreateWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CreateHeader = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  padding: 20px 0px 0px 0px;
  margin-bottom: 20px;

  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  color: ${props => props.theme.interface7};
`;

export const Title = styled.h1`
  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.interface7} !important;
  font-size: 28px;
  font-weight: bold;

  svg {
    color: ${props => props.theme.brand_primary_color} !important;
  }
`;

export const NoResults = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: 40px;
  border-top: 1px dashed ${props => props.theme.interface5};
  border-bottom: 1px dashed ${props => props.theme.interface5};
`;

interface INoResultsDetailProps {
  row: boolean;
  width: string;
  height: string;
}

export const NoResultsDetail = styled.div<INoResultsDetailProps>`
  display: flex;
  width: ${props => props.width || '231px'};
  height: ${props => props.height || '142px'};
  align-items: center;
  justify-content: ${props => (props.row ? 'space-between' : 'center')};
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: ${props => (props.row ? '13px 10px' : '20px 10px')};
  border: 1px dashed ${props => props.theme.interface5};
  border-radius: 5px;
  text-transform: uppercase;

  svg {
    color: ${props => props.theme.interface5};
    margin-bottom: ${props => (props.row ? '0px' : '10px')};
    margin-right: 14px;
    font-size: ${props => (props.row ? '24px' : '35px')};
  }

  font-size: 12px;
`;

export const Breadcrumbs = styled.div`
  line-height: 30px;
  /* font-weight: 400; */
  width: 100%;
  margin-bottom: -4px;
  display: flex;
  align-items: center;

  svg {
    margin: 0 8px !important;
    color: ${props => props.theme.brand_primary_color} !important;
    font-size: 10px;
  }
`;
interface IBreadcrumbProps {
  active?: boolean;
}

export const Breadcrumb = styled.span<IBreadcrumbProps>`
  font-size: 12px;

  a {
    color: ${props => props.theme.interface5};

    ${props =>
      props.active &&
      css`
        color: ${props => props.theme.brand_primary_color};
      `};

    &:hover {
      text-decoration: underline;
    }
  }
`;
