import { useLayout } from 'hooks';
import { Col, Row } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';

const Header = () => {
  return (
    <Row style={{ marginBottom: '40px', height: '70px' }}>
      <Col md="10">
        <span className="mr-4">
          <Placeholder width="56px" height="56px" circle />
        </span>
        <Placeholder width="150px" height="50px" />
      </Col>
      <Col>
        <Placeholder width="100%" height="50px" />
      </Col>
    </Row>
  );
};

interface ICardProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Card: React.FC<ICardProps> = ({ children, style }) => {
  const theme = useLayout();
  return (
    <div
      style={{
        borderRadius: '5px',
        backgroundColor: theme.interface1,
        height: '250px',
        padding: '25px 15px',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const Info = () => {
  return (
    <>
      <Row>
        <Col md="7">
          <Card>
            <Row style={{ paddingLeft: '15px', paddingBottom: '15px' }}>
              <Placeholder height="40px" width="150px" />
            </Row>
            <Row
              style={{
                width: '100%',
                paddingBottom: '10px',
              }}
            >
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: '100%',
                  gap: '1rem',
                }}
              >
                <Placeholder width="100%" height="30px" />
                <Placeholder width="100%" height="30px" />
                <Placeholder width="100%" height="30px" />
              </Col>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: '100%',
                  gap: '1rem',
                }}
              >
                <Placeholder width="100%" height="30px" />
                <Placeholder width="100%" height="30px" />
                <Placeholder width="100%" height="30px" />
              </Col>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: '100%',
                  gap: '1rem',
                }}
              >
                <Placeholder width="100%" height="30px" />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl="5">
          <Card>
            <Placeholder height="14px" width="150px" />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '1rem',
              }}
              className="mt-5"
            >
              <Placeholder width="100px" height="40px" />
              <Placeholder width="100px" height="40px" />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default function () {
  return (
    <>
      <Header />
      <Info />
      <Row className="mt-3">
        <Col>
          <div
            style={{
              height: '32px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Placeholder height="14px" width="50px" />
            <Placeholder height="14px" width="50px" />
            <Placeholder height="14px" width="50px" />
            <Placeholder height="14px" width="50px" />
          </div>
          <Card
            style={{
              height: '186px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <Placeholder width="156px" height="130px" />
            <Placeholder width="156px" height="130px" />
            <Placeholder width="156px" height="130px" />
            <Placeholder width="156px" height="130px" />
          </Card>
        </Col>
      </Row>
    </>
  );
}
