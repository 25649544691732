import { createContext, useReducer } from 'react';

import {
  CompanyBilling,
  CreditCard,
  Invoice,
  User,
  UserBilling,
} from 'types/invoice';

import reducer from './reducer';
import {
  setLoading,
  getInvoice,
  getUser,
  getUserBilling,
  getCreditCard,
  getCompanyBilling,
} from './actions';

export interface InvoiceDetailState {
  invoice: Invoice;
  user: User;
  userBilling: UserBilling;
  companyBilling: CompanyBilling;
  creditCard: CreditCard;
  errors: any;
  loading: boolean;

  getInvoice(id: number | string): Promise<void>;
  getUser(user: any): Promise<void>;
  getUserBilling(user: any): Promise<void>;
  getCompanyBilling(company: any): Promise<void>;
  getCreditCard(): Promise<void>;
  setLoading(value: boolean): void;
}

// initial state
const initialState: InvoiceDetailState = {
  invoice: null,
  user: null,
  userBilling: null,
  companyBilling: null,
  creditCard: null,
  errors: null,
  loading: false,

  getInvoice: async () => {},
  getUser: async () => {},
  getUserBilling: async () => {},
  getCompanyBilling: async () => {},
  getCreditCard: async () => {},
  setLoading: (): void => {},
};

// context
export const InvoiceDetailContext = createContext(initialState);

// provider
export const InvoiceDetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <InvoiceDetailContext.Provider
      value={{
        // state
        invoice: state.invoice,
        user: state.user,
        userBilling: state.userBilling,
        companyBilling: state.companyBilling,
        creditCard: state.creditCard,
        loading: state.loading,
        errors: state.errors,

        // actions
        getInvoice: async id => await getInvoice(dispatch, id),
        getUser: async user => await getUser(dispatch, user),
        getUserBilling: async user => await getUserBilling(dispatch, user),
        getCompanyBilling: async company =>
          await getCompanyBilling(dispatch, company),
        getCreditCard: async () => await getCreditCard(dispatch),
        setLoading: value => setLoading(dispatch, value),
      }}
    >
      {children}
    </InvoiceDetailContext.Provider>
  );
};
