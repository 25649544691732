import React, { useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from '@cloudez/cloudez-design-system';

import { CustomTextArea } from 'components/NewComponents';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { ratingMigrationService } from 'services/friday';

import { ContentText } from './styles';
import { MigrationListingContext } from '../../_context/state';

const RatingModal = ({ show, setShow, migrationItem }): JSX.Element => {
  const [loadingRate, setLoadingRate] = useState(false);

  const [message, setMessage] = useState('');

  const [rating, setRating] = useState(null);

  const { ratingMigration } = useContext(MigrationListingContext);

  const onSubmitRating = async () => {
    setLoadingRate(true);
    try {
      await ratingMigrationService(migrationItem.id, {
        rating,
        rating_message: message,
      });

      ratingMigration('rating', rating, migrationItem.id);

      setLoadingRate(false);
      setShow(false);
      toast.success('Recebemos o seu feedback!');
    } catch (err) {
      setLoadingRate(false);
      console.log('err', err);
      toast.error('Não foi possível enviar o feedback, tente novamente.');
    }
  };

  useEffect(() => {
    setRating(null);
  }, [show]);

  return (
    <Modal width="455px" show={show} setShow={setShow} noExit={loadingRate}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faThumbsUp} />
        </Icon>
        <Text>
          <Title>Avalie a sua migração</Title>
          <Subtitle>Ao final do processo, faça a sua avaliação.</Subtitle>
        </Text>
      </Header>
      <Content>
        <ContentText>Como você avalia o processo da sua migração?</ContentText>
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginBottom: '30px',
            gap: '20px',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{ opacity: rating === false ? '1' : '0.5' }}
            error
            icon
            height="50px"
            width="170px"
            onClick={() => setRating(false)}
          >
            <FontAwesomeIcon style={{ color: 'white' }} icon={faThumbsDown} />
          </Button>
          <Button
            style={{ opacity: rating === true ? '1' : '0.5' }}
            success
            icon
            height="50px"
            width="170px"
            onClick={() => setRating(true)}
          >
            <FontAwesomeIcon style={{ color: 'white' }} icon={faThumbsUp} />
          </Button>
        </div>
        <div>
          <ContentText>
            No campo abaixo, nos conte o motivo da sua escolha:
          </ContentText>

          <CustomTextArea
            style={{ resize: 'none' }}
            height="122px"
            width="100%"
            value={message}
            onChange={e => setMessage(e.target.value)}
            block
            name="motive"
          />
        </div>
      </Content>
      <Footer>
        <Button
          width="149px"
          icon
          onClick={() => {
            onSubmitRating();
          }}
          disabled={loadingRate}
        >
          {loadingRate ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Concluir'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RatingModal;
