import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import * as actions from './actions';

interface OnboardState {
  primaryLogo: any;
  reducedLogo: any;
  favicon: any;

  setPrimaryLogo: (value: any) => void;
  setReducedLogo: (value: any) => void;
  setFavicon: (value: any) => void;
}

const initialState: OnboardState = {
  primaryLogo: null,
  reducedLogo: null,
  favicon: null,

  setPrimaryLogo: (): void => {},
  setReducedLogo: (): void => {},
  setFavicon: (): void => {},
};

export const OnboardContext = createContext(initialState);

export const OnboardProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OnboardContext.Provider
      value={{
        ...state,
        setPrimaryLogo: value => actions.setPrimaryLogo(dispatch, value),
        setReducedLogo: value => actions.setReducedLogo(dispatch, value),
        setFavicon: value => actions.setFavicon(dispatch, value),
      }}
    >
      {children}
    </OnboardContext.Provider>
  );
};
