import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { NoResultsDetail } from 'components/NoResults';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplicationIcon from 'components/ApplicationIcon';
import Pagination from 'components/Pagination';
import { WebsiteCard, Content, Logo } from './styles';
import Placeholder from '../SharedPlan/Placeholder';

const Websites = ({
  websites,
  getWebsites,
  setSelectedShareResource,
  selectedShareResource,
  search,
  loading,
}) => {
  return (
    <>
      <Row
        style={{
          width: '100%',
          margin: 0,
          marginTop: '25px',
        }}
      >
        {!loading ? (
          websites?.websites?.length ? (
            websites?.websites?.map(c => {
              return (
                <Col key={c.id} xl="2">
                  <WebsiteCard
                    key={c.id}
                    cloud={c}
                    selected={
                      selectedShareResource && selectedShareResource.id === c.id
                    }
                    onClick={() => setSelectedShareResource(c)}
                  >
                    <Content>
                      <Logo>
                        <ApplicationIcon
                          width="45px"
                          height="40px"
                          icon={`${c.type.slug}`}
                        />
                      </Logo>
                    </Content>
                    <span>{c.domain || c.name}</span>
                    <p>{c.cloud.display}</p>
                  </WebsiteCard>
                </Col>
              );
            })
          ) : (
            <Col xl="2">
              <NoResultsDetail height="130px" width="100%">
                <FontAwesomeIcon icon={faCloud} />
                Nenhum cloud.
              </NoResultsDetail>
            </Col>
          )
        ) : (
          <Placeholder />
        )}
      </Row>
      <Pagination
        onChangePage={getWebsites}
        pageSize="12"
        count={websites?.count}
        previous={websites?.previous}
        next={websites?.next}
        current={websites?.current}
        search={search}
      />
    </>
  );
};

export default Websites;
