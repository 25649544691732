import queryString from 'query-string';

export default (state, action) => {
  switch (action.type) {
    case 'GET_CUSTOMERS':
      const customers = action.payload.results;
      const { count } = action.payload;
      const next = action.payload.next
        ? queryString.parseUrl(action.payload.next).query.page
        : null;
      let current;
      let previous;

      if (action.payload.previous) {
        if (queryString.parseUrl(action.payload.previous).query.page) {
          previous = queryString.parseUrl(action.payload.previous).query.page;
        } else {
          previous = 1;
        }
      } else {
        previous = null;
      }

      if (previous) {
        if (next) {
          current = Number(next) - 1;
        } else {
          current = Math.ceil(count / 10);
        }
      } else {
        current = 1;
      }

      return {
        ...state,
        customers,
        count,
        next,
        previous,
        current,
      };

    case 'UPDATE_CUSTOMER': {
      return {
        ...state,
        customers: state?.customers?.map(customer =>
          customer.id === action.payload.id ? action.payload : customer,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
