/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import api from 'services/api';
import { createPagination } from 'utils/createPagination';
import { useLayout } from 'hooks';
import SearchBar from 'components/SearchBar';
import { Header } from '../../styles';
import Websites from '../Websites';
import Emails from '../Emails';

const SharedPlan = ({
  selectedPlan,
  selectedShareResource,
  setSelectedShareResource,
  selectedCloud,
}) => {
  const [websites, setWebsites] = useState(null);
  const [emails, setEmails] = useState(null);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);

  const theme = useLayout();

  const getWebsitesCallback = useCallback(
    async (page?: number, search?: string) => {
      setLoading(true);
      const { data } = await api.get(`/v3/website/`, {
        params: {
          page,
          page_size: 12,
          search,
        },
      });

      const pagination = createPagination(data, 12);

      setWebsites({ websites: data.results, ...pagination });
      setLoading(false);
    },
    [],
  );

  const getEmailsCallback = useCallback(
    async (page?: number, search?: string) => {
      setLoading(true);
      const { data } = await api.get(`/v3/email/`, {
        params: {
          page,
          page_size: 12,
          search,
        },
      });

      const pagination = createPagination(data, 12);

      setEmails({ emails: data.results, ...pagination });
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    if (selectedPlan?.category === 9) getWebsitesCallback(1);
    else if (selectedPlan?.category === 10) getEmailsCallback(1);
    // eslint-disable-next-line
  }, [selectedCloud, selectedPlan]);

  const searchValue = (e, searchValue) => {
    if (e) e.preventDefault();
    if (selectedPlan?.category === 9) getWebsitesCallback(1, searchValue);
    else if (selectedPlan?.category === 10) getEmailsCallback(1, searchValue);
  };

  return (
    <>
      <Header>
        <h1>
          Selecione um{' '}
          {selectedPlan.category === 9
            ? 'website'
            : selectedPlan.category === 10
            ? 'email'
            : ''}
        </h1>
      </Header>

      <SearchBar
        search={search}
        setSearch={setSearch}
        cb={searchValue}
        noResults={
          (selectedPlan.category === 9
            ? websites?.websites?.length === 0
            : emails?.emails?.length === 0) && search
        }
      />

      <Row
        style={{
          width: '100%',
          margin: 0,
          background: theme.interface1,
        }}
      >
        {selectedPlan.category === 9 ? (
          <Websites
            getWebsites={getWebsitesCallback}
            websites={websites}
            selectedShareResource={selectedShareResource}
            setSelectedShareResource={setSelectedShareResource}
            search={search}
            loading={loading}
          />
        ) : selectedPlan.category === 10 ? (
          <Emails
            getEmails={getEmailsCallback}
            emails={emails}
            selectedShareResource={selectedShareResource}
            setSelectedShareResource={setSelectedShareResource}
            search={search}
            loading={loading}
          />
        ) : null}
      </Row>
    </>
  );
};

export default SharedPlan;
