import React from 'react';

import { useAuth } from 'hooks';
import cloudezFooter from 'assets/img/svg/logo_cloudez.svg';
import { Wrapper, FooterStyle, Logo, Text } from './styles';

const Footer: React.FC = () => {
  const { company } = useAuth();
  return (
    <Wrapper>
      <FooterStyle hasChat={company?.has_cloudez_chat}>
        <Logo src={cloudezFooter} />
        <Text>
          Cloud Easy, Corp © 2017.
          <br />
          Cloudez™ - All rights reserved.
        </Text>
      </FooterStyle>
    </Wrapper>
  );
};

export default Footer;
