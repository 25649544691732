import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 30px;
  height: auto;
  padding: 20px;

  h2 {
    color: ${props => props.theme.interface5};
    font-size: 18px;
  }
`;

interface IPlanCardProps {
  selected: boolean;
}

export const PlanCard = styled.div<IPlanCardProps>`
  margin: 10px 0px;
  width: 165px;
  height: 234px;
  border-radius: 5px;
  background: ${props => props.theme.interface1};
  border: 1px solid ${props => props.theme.brand_primary_color};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  color: ${props => props.theme.brand_primary_color};

  p {
    font-weight: bold;
    font-size: 16px;
  }

  span {
    p {
      font-size: 8px;
      display: inline;
    }

    span {
      font-weight: normal;
      font-size: 14px;
    }

    font-size: 40px;
    font-weight: bold;
    display: block;
  }

  div {
    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 14px;
      font-weight: bold;

      & + span {
        margin-top: 10px;
      }
    }
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color};
    `}
`;
