import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 330px;
  padding: 0px 15px;
  margin-top: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin: auto;
    margin-top: 40px;
  }
`;

interface IButtonProps {
  current?: number;
  previous?: number;
  next?: number;
  disabled?: boolean;
}

export const Button = styled.a<IButtonProps>`
  font-size: 12px;

  color: ${props =>
    props.disabled
      ? props.theme.darkMode
        ? props.theme.interface6
        : props.theme.interface4
      : props.theme.brand_primary_color};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  background: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 2.3px;
  }
`;

export const First = styled(Button)<IButtonProps>`
  svg + svg {
    margin-left: -2px;
  }
`;

export const Last = styled(Button)<IButtonProps>`
  svg + svg {
    margin-left: -2px;
  }
`;

export const Previous = styled(Button)<IButtonProps>`
  svg {
    margin-right: 4px;
  }
`;

export const Next = styled(Button)<IButtonProps>`
  svg {
    margin-left: 4px;
  }
`;

export const PageNumber = styled(Button)<IButtonProps>`
  padding: 1px 4px 2px 4px;
  margin: 3px;
  border-radius: 5px;
  background: none;
  font-weight: ${props => (props.current ? '900' : 'normal')};
  color: ${props =>
    props.current || props.previous || props.next
      ? props.theme.brand_primary_color
      : props.theme.interface4};
`;

export const Pages = styled.div`
  display: flex;
`;
