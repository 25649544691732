import React, { useEffect, useState } from 'react';

import {
  faCloudUploadAlt,
  faExclamationCircle,
  faSearch,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Alert } from 'components/Alert';

import { Button, Pagination } from '@cloudez/cloudez-design-system';

import { CustomInput } from 'components/NewComponents';
import ApplicationIcon from 'components/ApplicationIcon';

import { toast } from 'react-toastify';
import faSolidServer from 'assets/img/svg/fa-solid_server.svg';
import otherExistingEmail from 'assets/img/svg/otherExistingEmail.svg';
import { useMigCreateEmail } from '../../contexts/MigEmailContext';
import { useMigDetail } from '../../contexts/MigDetailContext';

import { Card, CardBox, Container, ButtonsContainer } from './styles';

const DestData = (): JSX.Element => {
  const {
    selectedDestItem,
    setSelectedDestItem,
    selectedOriginItem,
    setSelectedOriginItem,
    clouds,
    getClouds,
    stage,
    changeStage,
    getEmails,
    emails,
    setEmails,
  } = useMigCreateEmail();

  const { setStep } = useMigDetail();

  const [selectedType, setSelectedType] = useState<any>({});
  const [search, setSearch] = useState<string | null>(null);

  const cards = [
    {
      id: 'email',
      icon: otherExistingEmail,
      alt: 'otherExistingEmail',
      text: 'Para outro webmail existente',
    },
    {
      id: 'cloud',
      icon: faSolidServer,
      alt: 'newWebmailCloud',
      text: 'Criar novo webmail em um cloud',
    },
  ];

  const goBackFunc = () => {
    if (stage.existingEmail) {
      setSelectedDestItem({});
      changeStage();
      return;
    }

    if (stage.newWebsiteCloud) {
      setSelectedDestItem({});
      changeStage();
      return;
    }

    if (selectedOriginItem?.id) {
      setEmails(null);
    }

    setSelectedOriginItem({});
    setStep(1);
  };

  const goAheadFunc = () => {
    if (
      selectedOriginItem?.id === selectedDestItem?.id &&
      selectedOriginItem !== 'external'
    ) {
      toast.error(
        'O webmail de destino não pode ser igual ao webmail de origem',
      );
      return;
    }

    if (!selectedDestItem.id) {
      if (selectedType.id === 'email') {
        changeStage('existingEmail');
      } else if (selectedType.id === 'cloud') {
        changeStage('newWebsiteCloud');
      }
    } else {
      setStep(3);
    }

    if (selectedDestItem.id) {
      setStep(3);
    }
    setSearch(null);
  };

  const getEmailsCallback = (page = 1) => {
    getEmails({ page_size: 4, page, search });
  };

  const getCloudsCallback = (page = 1) => {
    getClouds({ page_size: 4, page, search });
  };

  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (search) {
      setSearching(true);
      const timer = setTimeout(() => {
        if (stage.newWebsiteCloud) {
          getCloudsCallback();
        }
        if (stage.existingEmail) {
          getEmailsCallback();
        }
        setSearching(false);
      }, 1000);

      return () => clearTimeout(timer);
    }

    if (stage.newWebsiteCloud && !selectedDestItem?.id) {
      getCloudsCallback();
    }
    if (stage.existingEmail && !selectedDestItem?.id) {
      getEmailsCallback();
    }
    setSearching(false);
  }, [search, stage]);

  return (
    <Container>
      <div className="title">
        <FontAwesomeIcon icon={faCloudUploadAlt} />
        <span>Dados do destino</span>
      </div>
      <span className="subtitle">
        {(() => {
          switch (true) {
            case stage.existingEmail:
              return 'Selecione abaixo qual será o webmail de destino:';

            case stage.newWebsiteCloud:
              return 'Selecione abaixo qual será o cloud de destino:';

            default:
              return 'Selecione abaixo qual será o destino dos seus emails:';
          }
        })()}
      </span>
      {(() => {
        switch (true) {
          case stage.existingEmail || stage.newWebsiteCloud:
            return (
              <CustomInput
                error={
                  emails?.results?.length === 0 && stage.existingEmail
                    ? 'Não foi encontrado nenhum webmail'
                    : clouds?.results?.length === 0 && stage.newWebsiteCloud
                    ? 'Não foi encontrado nenhum cloud'
                    : null
                }
                icon={{ svg: searching ? faSpinner : faSearch }}
                block
                value={search}
                onChange={e => setSearch(e.target.value)}
                name="search"
                placeholder={`Se desejar, pesquise aqui o ${
                  stage.existingEmail ? 'website' : 'cloud'
                } de destino`}
              />
            );
          default:
            return null;
        }
      })()}

      {(() => {
        switch (true) {
          case stage.existingEmail:
            return (
              <CardBox>
                {emails?.results?.map(item => {
                  return (
                    <Card
                      onClick={() => setSelectedDestItem(item)}
                      selected={selectedDestItem.id === Number(item.id)}
                    >
                      <ApplicationIcon
                        width="50px"
                        height="50px"
                        icon="existingEmail"
                      />
                      <b>{item.domain}</b>
                      <span>{item.cloud_display}</span>
                    </Card>
                  );
                })}
              </CardBox>
            );
          case stage.newWebsiteCloud:
            return (
              <CardBox>
                {clouds?.results?.map((item: any) => {
                  return (
                    <Card
                      onClick={() => setSelectedDestItem(item)}
                      selected={selectedDestItem.id === Number(item.id)}
                    >
                      <img src={faSolidServer} alt="newWebmailCloud" />
                      <b>{item.nickname ? item.nickname : item.name}</b>
                      <span>{item.cloud_size.disk}</span>
                    </Card>
                  );
                })}
              </CardBox>
            );
          default:
            return (
              <CardBox>
                {cards.map(item => {
                  return (
                    <Card
                      onClick={() => setSelectedType(item)}
                      selected={selectedType.id === item.id}
                    >
                      <img src={item.icon} alt={item.alt} />
                      <b>{item.text}</b>
                    </Card>
                  );
                })}
              </CardBox>
            );
        }
      })()}
      {(() => {
        switch (true) {
          case stage.existingEmail:
            return (
              <Pagination
                style={{ marginTop: '0' }}
                onChangePage={getEmailsCallback}
                pageSize={4}
                count={emails?.count}
                previous={emails?.previous}
                next={emails?.next}
                current={emails?.current}
              />
            );

          case stage.newWebsiteCloud:
            return (
              <Pagination
                style={{ marginTop: '0' }}
                onChangePage={getCloudsCallback}
                pageSize={4}
                count={clouds?.count}
                previous={clouds?.previous}
                next={clouds?.next}
                current={clouds?.current}
              />
            );
          default:
            return null;
        }
      })()}
      {(() => {
        switch (true) {
          case stage.existingEmail || selectedType.id === 'email':
            return (
              <Alert info>
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
                <span style={{ maxWidth: '90%' }}>
                  <b>O que será feito?</b> Nós vamos substituir os arquivos que
                  já existem no domínio que você selecionar pelos arquivos que
                  serão baixados da origem!
                </span>
              </Alert>
            );
          case stage.newWebsiteCloud || selectedType.id === 'cloud':
            return (
              <Alert info>
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
                <span>
                  <b>O que será feito?</b> Vamos copiar seus arquivos de origem
                  e vamos criar um novo website no cloud selecionado.
                </span>
              </Alert>
            );
          default:
            return null;
        }
      })()}
      <ButtonsContainer>
        <Button
          onClick={goBackFunc}
          style={{ fontSize: '14px' }}
          width="117px"
          type="button"
          text
        >
          Voltar
        </Button>
        <Button
          onClick={goAheadFunc}
          style={{
            fontSize: '16px',
            textTransform: 'none',
          }}
          disabled={
            (!selectedType?.id && !selectedDestItem?.id) ||
            (stage.existingEmail && !selectedDestItem?.id) ||
            (stage.newWebsiteCloud && !selectedDestItem?.id)
          }
          width="140px"
        >
          Continuar
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default DestData;
