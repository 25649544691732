import React, { useEffect, useState, useContext, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';

import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import Slider from 'react-slick';

import { useDocTitle } from 'hooks';
import useDevice from 'hooks/device';

import { NoResultsDetail } from 'components/NoResults';
import { Header, Title } from 'components/Common';
import Pagination from 'components/Pagination';
import SearchBar from 'components/SearchBar';
import { Tab, Tabs } from 'components/Tabs';
import { Button } from '@cloudez/cloudez-design-system';

import PlanPlaceholder from './Placeholder';
import EditPlanModal from './EditPlanModal';
import NewPlanModal from './NewPlanModal';
import BasePlanCard from './BasePlanCard';
import PlanCard from './PlanCard';

import { NewPlan, Subtitle, Wrapper } from './styles';

import { PlanListingProvider, PlanListingContext } from './_context/state';

const Plans = () => {
  useDocTitle('Planos - Cloudez Partners');

  const [newPlanModal, setNewPlanModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editPlan, setEditPlan] = useState(null);
  // const [plansTab, setPlansTab] = useState(1);
  // const [basePlansTab, setBasePlansTab] = useState(1);

  const { isMobile } = useDevice();

  const {
    loading,
    plans,
    count,
    previous,
    next,
    current,
    getPlans,
    basePlans,
    getBasePlans,
    plansTab,
    setPlansTab,
    basePlansTab,
    setBasePlansTab,
  } = useContext(PlanListingContext);

  const {
    page = 1,
    search = '',
    size_category = 'clouds',
    base_size_category = 'default',
  } = queryString.parse(history.location.search);

  const queryParams = {
    page,
    search,
    size_category,
    base_size_category,
  };

  const getPlansCallback = (page, search, _size_category) => {
    getPlans(page, search, _size_category || size_category);

    const _query = {
      page,
      search,
      size_category: _size_category || size_category,
    };

    if (!search) delete _query.search;
    const query = queryString.stringify(_query);

    history.replace(`/plans?${query}`);
  };

  useEffect(() => {
    getPlansCallback(page, search, size_category);
    getBasePlans(queryParams, base_size_category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchValue = (e: { preventDefault: () => void }, searchValue: any) => {
    if (e) e.preventDefault();
    getPlansCallback(1, searchValue, size_category);
  };

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    focusOnSelect: true,
    centerPadding: '60px',
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 2,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePlansTabChange = useCallback(
    plansTab => {
      setPlansTab(plansTab);
      switch (plansTab) {
        case 1:
          getPlansCallback(1, '', 'clouds');
          break;
        case 2:
          getPlansCallback(1, '', 'emails');
          break;
        case 3:
          getPlansCallback(1, '', 'shared');
          break;
        default:
          break;
      }
    },
    [search],
  );

  const handleBasePlansTabChange = useCallback(
    plansTab => {
      setBasePlansTab(plansTab);
      const query = {
        page,
        search,
        size_category,
        base_size_category,
      };
      switch (plansTab) {
        case 1:
          getBasePlans(query, 'default');
          break;
        case 2:
          getBasePlans(query, 'ram');
          break;
        case 3:
          getBasePlans(query, 'cpu');
          break;
        case 4:
          getBasePlans(query, 'email');
          break;
        default:
          break;
      }
    },
    [page, search, base_size_category, size_category],
  );

  return (
    <>
      <Header>
        <Title>Planos</Title>
      </Header>

      <Wrapper>
        <SearchBar
          search={search}
          cb={searchValue}
          noResults={plans && plans.length === 0 && search}
        />

        <Subtitle>Meus planos</Subtitle>

        <Tabs className="mb-3">
          <Tab active={plansTab === 1} onClick={() => handlePlansTabChange(1)}>
            Clouds
          </Tab>
          <Tab active={plansTab === 2} onClick={() => handlePlansTabChange(2)}>
            E-mails
          </Tab>
          <Tab active={plansTab === 3} onClick={() => handlePlansTabChange(3)}>
            Compartilhados
          </Tab>
        </Tabs>

        {newPlanModal && (
          <NewPlanModal
            plans={plans}
            show={newPlanModal}
            setShow={() => {
              setNewPlanModal(false);
            }}
            // basePlans={basePlans}
          />
        )}

        {!isMobile ? (
          <Row>
            <Col xl="3" lg="3" md="3" sm="6" xs="12">
              <NewPlan>
                <span>NOVO PLANO</span>
                <Button
                  size="sm"
                  onClick={() => {
                    setNewPlanModal(true);
                  }}
                >
                  Criar
                  <FontAwesomeIcon icon={faPlusSquare} />
                </Button>
              </NewPlan>
            </Col>

            {editModal && (
              <EditPlanModal
                plans={plans}
                // basePlans={basePlans}
                show={editModal}
                setShow={() => setEditModal(false)}
                plan={editPlan}
              />
            )}

            {!loading ? (
              plans?.length > 0 ? (
                plans.map((p: any) => {
                  return (
                    <Col key={p.id} xl="3" lg="3" md="3" sm="6" xs="12">
                      <PlanCard
                        p={p}
                        setEditModal={setEditModal}
                        setEditPlan={setEditPlan}
                      />
                    </Col>
                  );
                })
              ) : (
                <Col xl="3" lg="3" md="3" sm="6" xs="12">
                  <NoResultsDetail
                    height="346px"
                    width="243px"
                    style={{ borderWidth: '2px', margin: '15px 20px' }}
                  >
                    Nenhum plano
                  </NoResultsDetail>
                </Col>
              )
            ) : (
              <PlanPlaceholder />
            )}
          </Row>
        ) : (
          <div>
            {editModal && (
              <EditPlanModal
                plans={plans}
                // basePlans={basePlans}
                show={editModal}
                setShow={() => setEditModal(false)}
                plan={editPlan}
              />
            )}
            {loading ? (
              <div>
                <PlanPlaceholder />
              </div>
            ) : (
              <Slider {...settings}>
                <div>
                  <NewPlan>
                    <span>NOVO PLANO</span>
                    <Button size="sm" onClick={() => setNewPlanModal(true)}>
                      Criar
                      <FontAwesomeIcon icon={faPlusSquare} />
                    </Button>
                  </NewPlan>
                </div>
                {plans?.map((p: any) => {
                  return (
                    <div key={p.id}>
                      <PlanCard
                        p={p}
                        setEditModal={setEditModal}
                        setEditPlan={setEditPlan}
                      />
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        )}

        <Pagination
          onChangePage={getPlansCallback}
          count={count}
          previous={previous}
          next={next}
          current={current}
          search={search}
          pageSize="7"
        />
      </Wrapper>

      <Subtitle>Planos base</Subtitle>

      <Tabs className="mb-3">
        <Tab
          active={basePlansTab === 1}
          onClick={() => handleBasePlansTabChange(1)}
        >
          Clouds padrão
        </Tab>
        <Tab
          active={basePlansTab === 2}
          onClick={() => handleBasePlansTabChange(2)}
        >
          Clouds focados em Memória
        </Tab>
        <Tab
          active={basePlansTab === 3}
          onClick={() => handleBasePlansTabChange(3)}
        >
          Clouds focados em Processamento
        </Tab>
        <Tab
          active={basePlansTab === 4}
          onClick={() => handleBasePlansTabChange(4)}
        >
          Clouds para E-mails
        </Tab>
      </Tabs>

      {!isMobile ? (
        <Row>
          {!loading ? (
            basePlans?.length > 0 ? (
              basePlans.map((p: any) => {
                return (
                  <Col key={p.id} xl="3" lg="3" md="3" sm="6" xs="12">
                    <BasePlanCard p={p} />
                  </Col>
                );
              })
            ) : (
              <Col xl="3" lg="3" md="3" sm="6" xs="12">
                <NoResultsDetail height="234px" width="100%">
                  Nenhum plano
                </NoResultsDetail>
              </Col>
            )
          ) : (
            <PlanPlaceholder />
          )}
        </Row>
      ) : (
        <div>
          {editModal && (
            <EditPlanModal
              plans={plans}
              show={editModal}
              setShow={() => setEditModal(false)}
              plan={editPlan}
            />
          )}
          {loading ? (
            <div>
              <PlanPlaceholder />
            </div>
          ) : (
            <Slider {...settings}>
              {basePlans?.map((p: any) => {
                return (
                  <div key={p.id}>
                    <BasePlanCard p={p} />
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      )}
    </>
  );
};

export default (props: JSX.IntrinsicAttributes) => (
  <PlanListingProvider>
    <Plans {...props} />
  </PlanListingProvider>
);
