import React, { useContext, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { SketchPicker } from 'react-color';

import buildSelector from 'utils/buildSelector';

import { ThemeContext } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Field, Label, Input, Error } from 'components/Input';
import ImageCropper from 'components/ImageCropper';
import { Button } from '@cloudez/cloudez-design-system';

import ChurnModal from './ChurnModal';
import Preview from './Preview';

import { Color } from '../styles';
import { CompanyContext } from '../_context/state';

const styles = {
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
};

interface VisualProperties {
  errors: any;
  color1: string;
  survey: any;
  loading: boolean;
  updateCompany: any;
  color2: string;
  name: string;
  setName: (value: string) => void;
  setColor1: (value: string) => void;
  setColor2: (value: string) => void;
}

const Visual = ({
  survey,
  loading,
  setName,
  setColor1,
  setColor2,
  color1,
  color2,
  name,
  updateCompany,
  errors,
}: VisualProperties): JSX.Element => {
  const theme = useContext(ThemeContext);
  const [picker1, setPicker1] = useState(false);
  const [picker2, setPicker2] = useState(false);
  const [churnModal, setChurnModal] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState('');

  const [openReducedCrop, setOpenReducedCrop] = useState(false);
  const [openPrimaryCrop, setOpenPrimaryCrop] = useState(false);

  const {
    setPrimaryLogo,
    setReducedLogo,
    setFavicon,
    primaryLogo,
    reducedLogo,
    favicon,
  } = useContext(CompanyContext);

  const [faviconInput] = useState(buildSelector(setFavicon));

  const handleConfirm = file => {
    switch (selectedLogo) {
      case 'primary':
        setPrimaryLogo(file);
        break;
      case 'reduced':
        setReducedLogo(file);
        break;
      case 'favicon':
        setFavicon(file);
        break;
      default:
        break;
    }
  };

  const handleSelectClick = logo => {
    switch (logo) {
      case 'primary':
        setOpenPrimaryCrop(true);
        break;
      case 'reduced':
        setOpenReducedCrop(true);
        break;
      case 'favicon':
        break;
      default:
        break;
    }

    setSelectedLogo(logo);
  };

  return (
    <>
      {churnModal && (
        <ChurnModal
          show={churnModal}
          setShow={() => setChurnModal(false)}
          survey={survey}
        />
      )}
      <Row
        style={{
          width: '100%',
          margin: 0,
        }}
      >
        <Col xs="12" md="4">
          <Field>
            <Label htmlFor="companyName" required>
              Nome da empresa
            </Label>
            <Input
              id="companyName"
              block="true"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            {errors.name && <Error>{errors.name[0]}</Error>}
          </Field>
        </Col>
        <Col xs="12" md="4">
          <Field>
            <Label required>Cor primária</Label>

            <Color
              data-testid="primaryColor"
              color={color1}
              onClick={() => {
                setPicker1(!picker1);
                setPicker2(false);
              }}
            />
          </Field>
          {picker1 && (
            <div style={styles.popover as React.CSSProperties}>
              <div
                style={styles.cover as React.CSSProperties}
                onClick={() => setPicker1(false)}
              />
              <SketchPicker
                disableAlpha
                color={color1}
                onChange={(c, e) => {
                  setColor1(c.hex);
                }}
              />
            </div>
          )}
        </Col>
        <Col xs="12" md="4">
          <Field>
            <Label required>Cor secundária</Label>

            <Color
              data-testid="secondaryColor"
              color={color2}
              onClick={() => {
                setPicker2(!picker2);
                setPicker1(false);
              }}
            />
          </Field>
          {picker2 && (
            <div style={styles.popover as React.CSSProperties}>
              <div
                style={styles.cover as React.CSSProperties}
                onClick={() => setPicker2(false)}
              />
              <SketchPicker
                disableAlpha
                color={color2}
                onChange={(c, e) => {
                  setColor2(c.hex);
                }}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row
        style={{
          width: '100%',
          margin: 0,
        }}
      >
        <Col xs="12" md="4">
          <Field>
            <Label required>Logo primária</Label>
            <Button
              data-testid="selectPrimary"
              style={{
                width: '100%',
              }}
              outline
              onClick={() => handleSelectClick('primary')}
            >
              Selecionar
            </Button>
            <span
              style={{
                fontSize: '13px',
                color: theme.interface5,
                width: '100%',
              }}
            >
              {primaryLogo && primaryLogo.name}
            </span>
            {errors.logo_primary && <Error>{errors.logo_primary[0]}</Error>}
          </Field>
        </Col>
        <Col xs="12" md="4">
          <Field>
            <Label required>Logo reduzida</Label>
            <Button
              style={{
                width: '100%',
              }}
              outline
              onClick={() => handleSelectClick('reduced')}
            >
              Selecionar
            </Button>
            <span
              style={{
                fontSize: '13px',
                color: theme.interface5,
                width: '100%',
              }}
            >
              {reducedLogo && reducedLogo.name}
            </span>
            {errors.logo_reduced && <Error>{errors.logo_reduced[0]}</Error>}
          </Field>
        </Col>
        <Col xs="12" md="4">
          <Field>
            <Label required>Favicon</Label>
            <Button
              style={{
                width: '100%',
              }}
              outline
              onClick={() => faviconInput.click()}
            >
              Selecionar
            </Button>
            <span
              style={{
                fontSize: '13px',
                color: theme.interface5,
                width: '100%',
              }}
            >
              {favicon && favicon.name}
            </span>
            {errors.favicon && <Error>{errors.favicon[0]}</Error>}
          </Field>
        </Col>
      </Row>

      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '30px',
          color: theme.interface5,
        }}
      >
        <div>
          <span>
            Essas configurações se aplicam ao painel de gerenciamento de clouds.
          </span>
          <p>O painel de parceiro não é configurável.</p>
        </div>
      </Row>
      <Preview
        primary={color1}
        secondary={color2}
        logo1={primaryLogo}
        logo2={reducedLogo}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          height: '100%',
        }}
      >
        <Button onClick={() => setChurnModal(true)} icon outline error>
          Deletar revenda
        </Button>

        <Button disabled={loading} icon={loading} onClick={updateCompany}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Atualizar'}
        </Button>
      </div>

      <ImageCropper
        onConfirm={handleConfirm}
        show={openPrimaryCrop}
        setShow={setOpenPrimaryCrop}
        cropSize={{
          width: 340,
          height: 120,
        }}
        title="LOGO PRIMÁRIA - 170x60px"
        subtitle="Escolha a logo primária"
      />

      <ImageCropper
        onConfirm={handleConfirm}
        show={openReducedCrop}
        setShow={setOpenReducedCrop}
        cropSize={{
          width: 170,
          height: 170,
        }}
        title="LOGO REDUZIDA - 60x60px"
        subtitle="Escolha a logo reduzida"
      />
    </>
  );
};

export default Visual;
