import styled from 'styled-components';
import { transparentize } from 'polished';

export const HttpsText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
  margin-bottom: 15px;
`;

export const Value = styled.p`
  font-weight: 700;
  font-size: 24px !important;

  display: flex;
  align-items: center;

  color: ${props => props.theme.brand_primary_color} !important;
`;

export const Advanced = styled.div`
  font-size: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  gap: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    color: ${props => props.theme.brand_primary_color} !important;
    font-weight: 400;
    font-size: 16px !important;
  }
`;

export const NewPlanCard = styled.div`
  margin: 10px auto;
  width: 140px;
  height: 190px;
  border-radius: 5px;
  background: ${props => props.theme.interface1};
  box-shadow: 0px 3px 15px
    ${props => transparentize(0.6, props.theme.interface5)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  color: ${props => props.theme.brand_primary_color};

  p {
    font-weight: bold;
    font-size: 16px;
  }

  span {
    p {
      font-size: 8px;
      display: inline;
    }

    span {
      font-weight: normal;
      font-size: 10px;
    }

    font-size: 22px;
    font-weight: bold;
    display: block;
  }

  div {
    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 10px;
      font-weight: bold;
      & + span {
        margin-top: 10px;
      }
    }
  }
`;
