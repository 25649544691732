import React, { useState, useContext, useMemo, useEffect } from 'react';

import axios from 'axios';

import { ThemeContext } from 'styled-components';

import { Link } from 'react-router-dom';

import api from 'services/api';

import * as yup from 'yup';

import CurrencyFormat from 'react-currency-format';
import InputMask from 'react-input-mask';
import Cards from 'react-credit-cards';

import history from 'services/history';

import { Label, Button } from '@cloudez/cloudez-design-system';
import { Tabs, Tab, TabContent } from 'components/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { createCreditCardTokenExternal } from 'utils/iugu';
import toastError from 'utils/toastError';
import clearMask from 'utils/clearMask';

import {
  getCepService,
  deleteCreditcardService,
  getCreditcardService,
} from 'services/common';

import { useAuth } from 'hooks/auth';
import {
  CustomInput,
  CustomRadio,
  CustomSelect,
  Flexbox,
  SquareCheckbox,
} from 'components/NewComponents';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  ButtonsWrapper,
  LoadingIframeBox,
  LoginLink,
  Total,
  Wrapper,
} from './styles';

const loginSchema = yup.object().shape({
  email: yup.string().required('O email é obrigatório.'),
  password: yup.string().required('A senha é obrigatória.'),
});

const paymentSchema = yup.object().shape({
  number: yup.string().required('Este campo é obrigatório'),
  name: yup.string().required('Este campo é obrigatório'),
  expiration: yup.string().required('Este campo é obrigatório'),
  verification_value: yup.string().required('Este campo é obrigatório'),
});

const billingSchema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório'),
  email: yup
    .string()
    .email('Por favor, insira um email válido.')
    .required('Este campo é obrigatório'),
  document_id: yup.string().required('Este campo é obrigatório'),
  zipcode: yup.string().required('Este campo é obrigatório'),
  address_number: yup.string().required('Este campo é obrigatório'),
  country: yup.string().required('Este campo é obrigatório'),
  address1: yup.string().required('Este campo é obrigatório'),
  address2: yup.string().required('Este campo é obrigatório'),
  state: yup.string().required('Este campo é obrigatório.'),
  city: yup.string().required('Este campo é obrigatório'),
  inscricao_estadual: yup.string().when('document_id', {
    is: val => val?.length > 14,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
});

type FormValues = {
  billing: {
    name: string;
    email: string;
    state: any;
    address1: string;
    address_number: string;
    phone_number: string;
    inscricao_estadual: string;
    country: string;
    owner: string;
    address2: string;
    city: string;
    zipcode: any;
    document_id: string;
  };
  payment: {
    name: string;
    number: string;
    verification_value: string;
    expiration: string;
  };
};

const schema = yup.object().shape({
  billing: billingSchema,
  payment: paymentSchema,
});

const schemaBilling = yup.object().shape({
  billing: billingSchema,
});

const schemaPayment = yup.object().shape({
  payment: paymentSchema,
});

const Payment = ({
  billing,
  creditCard,
  setCreditCard,
  invoice,
  isAuthenticated,
  hasInfo,
  hash,
  loadingHasInfo,
}) => {
  const theme = useContext(ThemeContext);

  const { signIn, authLoading } = useAuth();

  const [focus, setFocus] = useState('number');

  const [tab, setTab] = useState(1);

  const [creditCardLoading, setCreditCardLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [changeCard, setChangeCard] = useState(false);
  const [saveCard, setSaveCard] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [document, setDocument] = useState(1);

  const states = [
    { title: 'Acre', value: 'AC' },
    { title: 'Alagoas', value: 'AL' },
    { title: 'Amapá', value: 'AP' },
    { title: 'Amazonas', value: 'AM' },
    { title: 'Bahia', value: 'BA' },
    { title: 'Ceará', value: 'CE' },
    { title: 'Distrito Federal', value: 'DF' },
    { title: 'Espírito Santo', value: 'ES' },
    { title: 'Goias', value: 'GO' },
    { title: 'Maranhão', value: 'MA' },
    { title: 'Mato Grosso', value: 'MT' },
    { title: 'Mato Grosso do Sul', value: 'MS' },
    { title: 'Minas Gerais', value: 'MG' },
    { title: 'Pará', value: 'PA' },
    { title: 'Paraíba', value: 'PB' },
    { title: 'Paraná', value: 'PR' },
    { title: 'Pernambuco', value: 'PE' },
    { title: 'Piauí', value: 'PI' },
    { title: 'Rio de Janeiro', value: 'RJ' },
    { title: 'Rio Grande do Norte', value: 'RN' },
    { title: 'Rio Grande do Sul', value: 'RS' },
    { title: 'Rondônia', value: 'RO' },
    { title: 'Roraima', value: 'RR' },
    { title: 'Santa Catarina', value: 'SC' },
    { title: 'São Paulo', value: 'SP' },
    { title: 'Sergipe', value: 'SE' },
    { title: 'Tocantins', value: 'TO' },
  ];

  const createCard = !creditCard || changeCard;

  const schemaToUse = useMemo(() => {
    if (showLoginForm) {
      return loginSchema;
    }

    if (!isAuthenticated && hasInfo) {
      if (tab === 1) return schemaPayment;

      if (tab === 2) return null;
    }

    if (tab === 1 && createCard) {
      return schema;
    }

    return schemaBilling;
  }, [showLoginForm, tab, creditCard, changeCard, isAuthenticated, hasInfo]);

  const {
    register,
    control,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schemaToUse),
  });

  const onMyFrameLoad = () => {
    setCreditCardLoading(false);
  };

  const getCEP = async _cep => {
    try {
      const { data } = await getCepService(_cep);

      setValue(
        'billing.state',
        states.find(item => item.value === data.uf)?.value,
      );

      setValue('billing.address1', data.logradouro);
      setValue('billing.address2', data.bairro);
      setValue('billing.city', data.localidade);
      setValue('billing.zipcode', data.cep);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteCreditCard = async () => {
    setDeleteLoading(true);
    try {
      await deleteCreditcardService(creditCard.id);
      setCreditCard(null);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toastError(error);
    }
  };

  const onSubmit = async d => {
    const number = d?.billing?.phone_number
      ? clearMask(d?.billing?.phone_number)
      : '';
    const documentId = d?.billing?.document_id?.replace(/[^a-zA-Z0-9 ]/g, '');

    if (document === 1 && documentId?.length < 14) {
      setError('billing.document_id', {
        type: 'custom',
        message: 'CNPJ inválido',
      });
      return;
    }
    if (
      (document === 0 && documentId?.length < 11) ||
      (document === 0 && documentId?.length >= 14)
    ) {
      setError('billing.document_id', {
        type: 'custom',
        message: 'CPF inválido',
      });
      return;
    }
    setLoading(true);
    const billingPayload = {
      ...d.billing,
      user: billing?.user,
      phone_number: `+55${number}`,
    };
    try {
      let apiUrl;
      if (window.location.hostname === 'demo.cloudez.io') {
        apiUrl = 'https:dev-api.cloudez.io/';
      } else {
        apiUrl = process.env.REACT_APP_API_URL;
      }
      if (isAuthenticated || !hasInfo) {
        await api.patch(`/v3/billing-hash/${hash}/`, billingPayload);
      }
      if (tab === 1) {
        if (createCard) {
          try {
            await createCreditCardTokenExternal(d.payment, hash);
          } catch (e) {
            setLoading(false);
            toastError(e);
            return;
          }
        }
        try {
          await axios.post(`${apiUrl}v2/invoice-hash/${hash}/charge/`);
          history.push(`/invoices/success`);
        } catch (e) {
          setLoading(false);
          toastError(e);
        }
        if (!saveCard) {
          const { data } = await getCreditcardService();
          deleteCreditcardService(data[0].id);
        }
      } else {
        try {
          await api.get(`${apiUrl}v2/invoice-hash/${hash}/`);
          history.push(`/invoices/bank-slip/${hash}/`);
        } catch (e) {
          setLoading(false);
          toastError(e);
        }
      }
    } catch (e) {
      toastError(e);
      setLoading(false);
    }
  };

  const submitLogin = data => {
    signIn(data.email, data.password);
  };

  useEffect(() => {
    if (billing) {
      setValue('billing.name', billing?.name);
      setValue('billing.email', billing?.email);
      setValue('billing.address2', billing?.address2);
      setValue('billing.zipcode', billing?.zipcode);
      setValue('billing.address_number', billing?.address_number);
      setValue('billing.phone_number', billing?.phone_number);
      setValue('billing.document_id', billing?.document_id);
      setValue('billing.inscricao_estadual', billing?.inscricao_estadual);
      setValue('billing.address1', billing?.address1);
      setValue('billing.city', billing?.city);

      setValue(
        'billing.state',
        states.find(item => item.value === billing.state)?.value,
      );
      setValue('billing.country', billing?.country);

      if (
        billing.document_id <= 11 &&
        watch('billing.document_id').length <= 11
      ) {
        setDocument(0);
      } else {
        setDocument(1);
      }
    }
  }, [billing]);

  useEffect(() => {
    if (isAuthenticated) {
      setShowLoginForm(false);

      if (watch('billing.zipcode')?.replace(/\.|-|\//g, '').length === 8) {
        getCEP(watch('billing.zipcode')?.replace(/\.|-|\//g, ''));
      }
    }
  }, [isAuthenticated, watch('billing.zipcode')]);

  return (
    <div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(showLoginForm ? submitLogin : onSubmit)}
      >
        <Flexbox>
          <Wrapper isFocused>
            {loadingHasInfo ? (
              <div style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={faSpinner} spin size="lg" />
              </div>
            ) : (
              <Flexbox flexDir="column">
                <h3>
                  Dados Cadastrais
                  {hasInfo && !isAuthenticated && (
                    <p>
                      Utilizaremos os dados já cadastrados em nosso sistema.
                      Para conferir ou alterar,{' '}
                      <LoginLink onClick={() => setShowLoginForm(true)}>
                        faça login
                      </LoginLink>
                      .
                    </p>
                  )}
                </h3>

                {showLoginForm && !isAuthenticated && (
                  <Flexbox
                    style={{ padding: '0px 20px 20px 20px' }}
                    flexDir="column"
                    spacing="20px"
                  >
                    <CustomInput
                      label="Email"
                      placeholder="email@exemplo.com"
                      name="email"
                      block
                      register={register}
                    />

                    <CustomInput
                      label="Senha"
                      type={showPw ? 'text' : 'password'}
                      icon={{
                        svg: !showPw ? faEye : faEyeSlash,
                        action: () => setShowPw(!showPw),
                        isRight: true,
                      }}
                      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                      name="password"
                      block
                      register={register}
                    />

                    <Link
                      style={{
                        fontSize: 12,
                        marginTop: 0,
                        color: theme.interface5,
                      }}
                      to="/password/reset"
                    >
                      NÃO LEMBRO MINHA SENHA
                    </Link>

                    <ButtonsWrapper>
                      <Button
                        disabled={authLoading}
                        onClick={() => setShowLoginForm(false)}
                        outline
                        type="button"
                      >
                        Cancelar
                      </Button>

                      <Button type="submit" icon disabled={authLoading}>
                        {authLoading ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          'Entrar'
                        )}
                      </Button>
                    </ButtonsWrapper>
                  </Flexbox>
                )}

                {(isAuthenticated || !hasInfo) && (
                  <Flexbox style={{ padding: '0px 20px' }} flexDir="column">
                    <CustomInput
                      block
                      name="billing.name"
                      label="Nome *"
                      error={errors?.billing?.name?.message}
                      register={register}
                    />
                    <Flexbox>
                      <CustomInput
                        width="40%"
                        label="Email financeiro *"
                        placeholder="cliente@company.com"
                        type="email"
                        name="billing.email"
                        error={errors?.billing?.email?.message}
                        register={register}
                      />
                      <Controller
                        control={control}
                        name="billing.phone_number"
                        render={({ field }) => (
                          <InputMask
                            width="40%"
                            mask="(99) 99999-9999"
                            maskChar={null}
                            block
                            label="Telefone *"
                            name="billing.phone_number"
                            value={field?.value}
                            onChange={e => field.onChange(e.target.value)}
                            error={errors?.billing?.owner?.message}
                          >
                            {inputProps => <CustomInput {...inputProps} />}
                          </InputMask>
                        )}
                      />
                      <div>
                        <Label>
                          <span
                            style={{
                              display: 'inline',
                              cursor: 'pointer',
                              color:
                                document === 0
                                  ? theme.brand_primary_color
                                  : theme.interface4,
                            }}
                            onClick={() => {
                              setDocument(0);
                              setValue('billing.document_id', '');
                            }}
                          >
                            CPF
                          </span>
                          /
                          <span
                            style={{
                              display: 'inline',
                              cursor: 'pointer',
                              color:
                                document === 1
                                  ? theme.brand_primary_color
                                  : theme.interface4,
                            }}
                            onClick={() => {
                              setDocument(1);
                              setValue('billing.document_id', '');
                            }}
                          >
                            CNPJ
                          </span>
                        </Label>
                        <Controller
                          control={control}
                          name="billing.document_id"
                          render={({ field }) => (
                            <InputMask
                              mask={
                                document === 0
                                  ? '999.999.999-99'
                                  : '99.999.999/9999-99'
                              }
                              maskChar={null}
                              error={errors?.billing?.document_id?.message}
                              value={field?.value}
                              onChange={e => {
                                field.onChange(e.target.value);
                              }}
                              block
                            >
                              {inputProps => <CustomInput {...inputProps} />}
                            </InputMask>
                          )}
                        />
                      </div>
                    </Flexbox>
                    <Flexbox>
                      {document === 1 && (
                        <div style={{ width: '100%' }}>
                          <CustomInput
                            mb="5px"
                            name="billing.inscricao_estadual"
                            label="Inscrição estadual"
                            block
                            disabled={
                              watch('billing.inscricao_estadual') === 'ISENTO'
                            }
                            error={errors?.billing?.inscricao_estadual?.message}
                            register={register}
                          />

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <Label
                              style={{
                                fontSize: '11px',
                              }}
                            >
                              Não possuo inscrição estadual *
                            </Label>
                            <CustomRadio
                              name="notHasIe"
                              checked={
                                watch('billing.inscricao_estadual') === 'ISENTO'
                              }
                              error={
                                errors?.billing?.inscricao_estadual?.message
                              }
                              onChange={e => {
                                setValue(
                                  'billing.inscricao_estadual',
                                  e.target.checked === true ? 'ISENTO' : '',
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <Controller
                        control={control}
                        name="billing.zipcode"
                        render={({ field }) => (
                          <InputMask
                            block
                            mask="99999-999"
                            maskChar={null}
                            label="CEP *"
                            error={errors?.billing?.zipcode?.message}
                            value={field?.value}
                            onChange={e => {
                              const cep = e.target.value;
                              field.onChange(cep);
                              if (cep.length >= 9) {
                                getCEP(cep.replace(/\.|-|\//g, ''));
                              }
                            }}
                          >
                            {inputProps => <CustomInput {...inputProps} />}
                          </InputMask>
                        )}
                      />
                      <CustomInput
                        width="190px"
                        label="Número *"
                        name="billing.address_number"
                        error={errors?.billing?.address_number?.message}
                        register={register}
                      />
                    </Flexbox>
                    <Flexbox>
                      <CustomInput
                        name="billing.country"
                        block
                        label="País *"
                        error={errors?.billing?.country?.message}
                        register={register}
                      />

                      <CustomInput
                        name="billing.address2"
                        block
                        label="Bairro"
                        error={errors?.billing?.address2?.message}
                        disabled={!watch('billing.zipcode')}
                        background={!watch('billing.zipcode')}
                        register={register}
                      />
                    </Flexbox>
                    <Flexbox>
                      <CustomInput
                        name="billing.address1"
                        block
                        label="Endereço"
                        error={errors?.billing?.address1?.message}
                        disabled={!watch('billing.zipcode')}
                        background={!watch('billing.zipcode')}
                        register={register}
                      />
                      <CustomSelect
                        name="billing.state"
                        label="Estado"
                        options={states}
                        error={errors?.billing?.state?.message}
                        disabled={!watch('billing.zipcode')}
                        register={register}
                      />
                      <CustomInput
                        label="Cidade"
                        error={errors?.billing?.city?.message}
                        disabled={!watch('billing.zipcode')}
                        background={!watch('billing.zipcode')}
                        block
                        name="billing.city"
                        register={register}
                      />
                    </Flexbox>
                  </Flexbox>
                )}
              </Flexbox>
            )}
          </Wrapper>
          <Flexbox w="100%" flexDir="column">
            <Wrapper disabled={showLoginForm || loadingHasInfo} isFocused>
              <h3>
                Método de Pagamento
                <p>
                  Selecione o método de pagamento somente caso não esteja
                  atualizado.
                </p>
              </h3>
              <Tabs>
                <Tab active={tab === 1} onClick={() => setTab(1)}>
                  Cartão de crédito
                </Tab>
                <Tab
                  active={tab === 2}
                  disabled={creditCard}
                  onClick={() => !creditCard && setTab(2)}
                >
                  Boleto
                </Tab>
              </Tabs>
              <TabContent>
                {/* credit card */}
                {tab === 1 && (
                  <>
                    {/* no credit card */}
                    {createCard &&
                      (invoice?.user_gateway !== 'PagBrasil' ? (
                        <Flexbox>
                          <Flexbox flexDir="column">
                            <Cards
                              cvc={watch('payment.verification_value') || ''}
                              expiry={watch('payment.expiration') || ''}
                              focused={focus}
                              name={watch('payment.name') || ''}
                              number={watch('payment.number') || ''}
                            />
                            <Flexbox alignItems="baseline" spacing="0px">
                              <SquareCheckbox
                                value={saveCard}
                                checked={saveCard}
                                name="test"
                                onChange={() => setSaveCard(!saveCard)}
                              />
                              <span className="saveCard">
                                Salvar este cartão para pagamentos futuros
                              </span>
                            </Flexbox>
                          </Flexbox>

                          <Flexbox flexDir="column">
                            <Controller
                              control={control}
                              name="payment.number"
                              render={({ field }) => (
                                <InputMask
                                  mask="9999 9999 9999 9999"
                                  maskChar={null}
                                  block
                                  onFocus={e => {
                                    setFocus(e.target.name.split('.')[1]);
                                  }}
                                  error={errors?.payment?.number?.message}
                                  label="Número do cartão"
                                  value={field?.value}
                                  onChange={e => field.onChange(e.target.value)}
                                >
                                  {inputProps => (
                                    <CustomInput {...inputProps} />
                                  )}
                                </InputMask>
                              )}
                            />

                            <CustomInput
                              block
                              label="Nome"
                              name="payment.name"
                              register={register}
                              onFocus={e => {
                                setFocus(e.target.name.split('.')[1]);
                              }}
                              error={errors?.payment?.name?.message}
                            />
                            <Flexbox>
                              <Controller
                                control={control}
                                name="payment.expiration"
                                render={({ field }) => (
                                  <InputMask
                                    mask="99/99"
                                    maskChar={null}
                                    block
                                    onFocus={e => {
                                      setFocus(e.target.name.split('.')[1]);
                                    }}
                                    error={errors?.payment?.expiration?.message}
                                    label="Validade"
                                    value={field?.value}
                                    onChange={e =>
                                      field.onChange(e.target.value)
                                    }
                                  >
                                    {inputProps => (
                                      <CustomInput {...inputProps} />
                                    )}
                                  </InputMask>
                                )}
                              />

                              <CustomInput
                                maxLength={4}
                                block
                                register={register}
                                label="cvc"
                                name="payment.verification_value"
                                onFocus={e => {
                                  setFocus(e.target.name.split('.')[1]);
                                }}
                                error={
                                  errors?.payment?.verification_value?.message
                                }
                              />
                            </Flexbox>
                          </Flexbox>
                        </Flexbox>
                      ) : (
                        <>
                          {creditCardLoading && (
                            <LoadingIframeBox>
                              <FontAwesomeIcon icon={faSpinner} spin />
                            </LoadingIframeBox>
                          )}
                          <iframe
                            title="payment"
                            style={{
                              display: creditCardLoading ? 'none' : 'flex',
                              width: '100%',
                              height: '520px',
                              overflow: 'none',
                            }}
                            frameBorder="0"
                            // onLoad={onMyFrameLoad}
                            src={`https://payment.cloudez.io/?uuid=${invoice.user_uuid}&brand_primary_color=${theme.brand_primary_color}&brand_secondary_color=${theme.brand_secondary_color}&domain=${window.location.origin}`}
                            // src={`http://localhost:3001/?uuid=${invoice.user_uuid}&brand_primary_color=${theme.brand_primary_color}&brand_secondary_color=${theme.brand_secondary_color}&domain=${window.location.origin}`}
                          />
                        </>
                      ))}

                    {/* has credit card */}
                    {!createCard && (
                      <Flexbox flexDir="column" spacing="20px">
                        <CustomInput
                          label="Cartão de crédito"
                          height="40px"
                          block
                          name="creditCard"
                          value={`${creditCard.brand} ${creditCard.display_number}`}
                          disabled
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '25px',
                            fontSize: '11px',
                          }}
                        >
                          <Button
                            form="b"
                            outline
                            style={{
                              width: '130px',
                              padding: '0 10px',
                              fontSize: '11px',
                              height: '100%',
                            }}
                            onClick={() => {
                              setChangeCard(!changeCard);
                            }}
                          >
                            Trocar cartão
                          </Button>
                          <Button
                            form="b"
                            error
                            style={{
                              width: '130px',
                              padding: '0px 10px',
                              fontSize: '11px',
                              height: '100%',
                            }}
                            disabled={deleteLoading}
                            onClick={() => {
                              deleteCreditCard();
                            }}
                            icon
                          >
                            {deleteLoading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              'Excluir cartão'
                            )}
                          </Button>
                        </div>
                      </Flexbox>
                    )}
                  </>
                )}

                {/* bankslip */}
                {tab === 2 && (
                  <p
                    style={{
                      color: theme.interface5,
                      textAlign: 'center',
                      padding: '18px',
                      border: `1px solid ${theme.brand_primary_color}`,
                    }}
                  >
                    Um boleto será criado e enviado para seu email.
                  </p>
                )}
              </TabContent>
              <Total>
                <p>Total: </p>
                <CurrencyFormat
                  value={invoice?.total?.amount}
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={invoice?.total?.currency === 'BRL' ? 'R$ ' : '$'}
                />
              </Total>
            </Wrapper>
            <Button
              style={{
                marginLeft: 'auto',
              }}
              icon
              disabled={loading || showLoginForm || loadingHasInfo}
              type="submit"
            >
              {loading || loadingHasInfo ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : tab === 1 ? (
                'Pagar'
              ) : (
                'Gerar boleto'
              )}
            </Button>
          </Flexbox>
        </Flexbox>
      </form>
    </div>
  );
};

export default Payment;
