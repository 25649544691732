import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';
import * as Styles from './styles';

const Header = () => (
  <div style={{ paddingTop: '20px' }}>
    <Row>
      <Col>
        <Placeholder width="100px" />
      </Col>
    </Row>
    <Row className="mt-1" style={{ marginBottom: '40px' }}>
      <Col className="d-flex align-items-end justify-content-between">
        <Placeholder height="28px" width="150px" />
        <Placeholder height="28px" width="150px" />
      </Col>
    </Row>
  </div>
);

const Invoice = () => (
  <Row>
    <Col>
      <Styles.Card style={{ height: '580px' }}>
        <Row style={{ height: '150px' }}>
          <Col>
            <Placeholder height="100%" />
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Placeholder height="90px" width="120px" />
          </Col>
        </Row>
        <Styles.Separator />
        <Row style={{ height: '90px' }}>
          <Col>
            <Placeholder width="150px" height="100%" />
            <Placeholder width="180px" height="100%" />
            <Placeholder width="195px" height="100%" />
            <Placeholder width="250px" height="100%" />
          </Col>
          <Col className="d-flex align-items-end justify-content-center flex-column">
            <Placeholder width="150px" height="" />
            <Placeholder width="150px" height="" />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="d-flex justify-content-center flex-column">
            <Placeholder height="233px" width="100%" />
          </Col>
        </Row>
      </Styles.Card>
    </Col>
  </Row>
);

export default function () {
  return (
    <>
      <Header />
      <Invoice />
    </>
  );
}
