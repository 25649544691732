import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 32px;

  gap: 22px;

  .successText {
    font-size: 16px;
    color: ${props =>
      props.theme.darkMode ? props.theme.interface5 : props.theme.interface7};

    b {
      font-size: 16px;
      color: ${props =>
        props.theme.darkMode ? props.theme.interface5 : props.theme.interface7};
    }

    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 16px;
    }
  }
`;
