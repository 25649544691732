import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { PlanPreview, SharedPreview } from './styles';

const PlanPreviewComponent = ({
  name,
  basePlan,
  category,
  description,
  basePrice,
  isEmail,
  turboBasePrice,
  hasTurbo,
}) => {
  return (
    <PlanPreview>
      {category && category === 1 ? (
        <>
          <div className="plan-title">
            <span>{name || '--------------'}</span>
            <span>{basePlan ? <>{basePlan?.memory} RAM</> : '-------'}</span>
          </div>

          <p className="tip">
            {description ||
              (basePlan && basePlan.info['tip_pt-br']) ||
              '------------------------'}
          </p>

          <div className="stats">
            <div>
              <span>DISCO</span>
              <span className="value">{basePlan?.disk || '-'}</span>
            </div>

            <div>
              <span>PROCESSADOR</span>
              <span className="value">
                {basePlan ? <>{basePlan?.cores} CPU Core </> : '-'}
              </span>
            </div>
          </div>

          {isEmail && (
            <div className="type">
              <h3>Email</h3>
              <Row>
                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount"
                      value={basePlan.price.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Preço Base</span>
                  </div>
                </Col>

                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount final"
                      value={basePlan.price.amount + Number(basePrice)}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Final</span>
                  </div>
                </Col>
              </Row>
              <div className="profit">
                <div className="price">
                  <span className="label">Lucro Mensal*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={parseFloat(basePrice) || '0'}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>

                <div className="price">
                  <span className="label">Lucro Anual*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={
                      Number(basePrice) * 12 - Number(basePrice) * 12 * 0.15 ||
                      0
                    }
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>
              </div>
            </div>
          )}

          {hasTurbo && basePlan && !isEmail && (
            <div className="type">
              <h3>Turbo</h3>
              <Row>
                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount"
                      value={basePlan.turbo_price.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Preço Base</span>
                  </div>
                </Col>

                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount final"
                      value={
                        basePlan.turbo_price.amount + Number(turboBasePrice)
                      }
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Final</span>
                  </div>
                </Col>
              </Row>
              <div className="profit">
                <div className="price">
                  <span className="label">Lucro Mensal*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={parseFloat(turboBasePrice) || 0}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>

                <div className="price">
                  <span className="label">Lucro Anual*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={
                      Number(turboBasePrice) * 12 -
                        Number(turboBasePrice) * 12 * 0.15 || 0
                    }
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : category ? (
        <SharedPreview>
          <h4>{name || '--------------'}</h4>
          <div className="shared-info">
            <span className="category">
              {category === 9 ? 'Site compartilhado' : 'E-mail compartilhado'}
            </span>

            <div className="price">
              <h6>Lucro Mensal*</h6>
              <CurrencyFormat
                className="profit-amount"
                value={parseFloat(basePrice) || 0}
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                // prefix={
                //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                // }
                prefix="R$ "
              />
            </div>

            <div className="price">
              <h6>Lucro Anual*</h6>
              <CurrencyFormat
                className="profit-amount"
                value={
                  Number(basePrice) * 12 - Number(basePrice) * 12 * 0.15 || 0
                }
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                // prefix={
                //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                // }
                prefix="R$ "
              />
            </div>
          </div>
        </SharedPreview>
      ) : (
        <div className="no-category">Selecione uma categoria</div>
      )}
    </PlanPreview>
  );
};

export default PlanPreviewComponent;
