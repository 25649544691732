import { faImage, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'components/HoverableIcon/styles';
import { Text } from 'components/ListingModal/styles';
import Modal from 'components/Modal';
import { ModalContent } from 'components/Modal/styles';
import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const CropperWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

export const CropperModal = styled(Modal)`
  ${ModalContent} {
    width: 1000px !important;
  }
`;

const FileUploadContainer = props => (
  <div {...props}>
    <Icon size="1x" icon={faPlus} className="mr-2" />
    <Icon size="2x" icon={faImage} />
    <Text>Clique aqui para escolher uma imagem!</Text>
  </div>
);

export const FileUploadButton = styled(FileUploadContainer)`
  width: 100%;
  height: 300px;

  padding: 0px 1rem;

  border: 2px dashed ${props => props.theme.brand_primary_color};

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-in-out;

  ${Text} {
    color: ${props => props.theme.brand_primary_color};
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.brand_primary_color};
    border-radius: 5px;
    border-style: solid;

    ${Icon}, ${Text} {
      color: #fff;
    }

    transition: all 0.2s ease-in-out;
  }
`;
