import styled, { css } from 'styled-components';

import check from 'assets/img/check.png';

interface IRoundedCheckboxProps {
  value: any;
}

export const RoundedCheckbox = styled.input.attrs({
  type: 'checkbox',
})<IRoundedCheckboxProps>`
  z-index: 2000;
  width: 20px;
  cursor: pointer;
  height: 20px;

  & + small {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  &:not(:checked) + small:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid ${props => props.theme.interface7};
    background: #fff;
    cursor: pointer;
    border-radius: 5px;
  }

  &:checked + small:after {
    content: '';
    color: ${props => props.theme.interface1};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: ${props => props.theme.interface7};
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center center;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;
