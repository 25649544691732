import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  useRef,
} from 'react';

import history from 'services/history';

import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';

import { useDocTitle } from 'hooks';

import { ListingHeader, Listing } from 'components/Listing/styles';
import CustomAnimatedList from 'components/CustomAnimatedList';
import AdvancedSearch from 'components/AdvancedSearch';
import { Header, Title } from 'components/Common';
import { NoResults } from 'components/NoResults';
import Pagination from 'components/Pagination';
import SearchBar from 'components/SearchBar';

import { Button } from '@cloudez/cloudez-design-system';

import UserPlaceholder from './Placeholder';
import ClientCard from './ClientCard';

import {
  CustomerListingContext,
  CustomerListingProvider,
} from './_context/state';

const Customers: React.FC = () => {
  useDocTitle('Clientes - Cloudez Partners');

  const { loading, customers, count, previous, next, current, getCustomers } =
    useContext(CustomerListingContext);

  const { page = 1 } = queryString.parse(history.location.search);
  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());
  const getCustomersCallback = useCallback(
    (page, search) => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      getCustomers(page, search, abortControllerRef.current);

      const _query = {
        ...search,
        page,
      };

      if (!search) delete _query.search;
      const query = queryString.stringify(_query);
      history.replace(`/customers?${query}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getCustomersCallback(page, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchValue = searchValue => {
    getCustomersCallback(1, searchValue);
  };

  return (
    <>
      <Header>
        <Title>Clientes</Title>
        <Button onClick={() => history.push('/customers/create')}>
          Novo cliente
        </Button>
      </Header>

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          setValue={setSearch}
          noResults={customers?.length === 0 && search}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Nome',
              value: 'full_name',
            },
          ]}
        />
      </SearchBar>

      {loading ? (
        <UserPlaceholder />
      ) : customers && customers.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col xs sm md="4" lg="5" xl="5">
                <p>Email</p>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p>MRR</p>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p>Clouds</p>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p>Faturas</p>
              </Col>

              <Col sm="2" className="d-sm-flex" />
            </Row>
          </ListingHeader>

          <Listing>
            <CustomAnimatedList>
              {customers.map(user => {
                return <ClientCard user={user} key={user.id} />;
              })}
            </CustomAnimatedList>
          </Listing>

          <Pagination
            onChangePage={getCustomersCallback}
            count={count}
            previous={previous}
            next={next}
            current={current}
            search={search}
            pageSize="10"
          />
        </>
      ) : (
        <NoResults>Nenhum cliente</NoResults>
      )}
    </>
  );
};

export default props => (
  <CustomerListingProvider>
    <Customers {...props} />
  </CustomerListingProvider>
);
