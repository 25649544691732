import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;

  display: flex;
  align-items: center;
  text-align: center;

  background: ${props => props.theme.interface2};
  border-radius: 15px;

  gap: 6px;

  padding: 12px 24px;

  pre {
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
  }

  span {
    font-family: 'Nunito';
    font-size: 10px;
  }

  color: ${props => props.theme.interface5};
`;
