import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;

  p {
    font-size: 16;
    margin-bottom: 50px;
    font-weight: bold;
    color: ${props => props.theme.interface5};
  }

  button {
    margin-top: 10px;
  }
`;
