import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import * as Yup from 'yup';
import { Button } from '@cloudez/cloudez-design-system';
import { Title, Header, Breadcrumbs, Breadcrumb } from 'components/Common';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Label, FormInput, FormTextArea } from 'components/Input';
import {
  faSpinner,
  faExchangeAlt,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';
import api from 'services/api';
import history from 'services/history';
import ticketinfo from 'assets/img/svg/ticketinfo.svg';
import {
  MigrationWrapper,
  MigrationForm,
  MigrationInfo,
  Field,
  Cost,
} from './styles';
import { MigrationCreateProvider } from '../_context/state';

const schema = Yup.object().shape({
  domain: Yup.string().required('Por favor, insira um domínio.'),
  message: Yup.string(),
});

const MigrationCreateAssisted = () => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const submit = async payload => {
    setLoading(true);
    try {
      const migration = {
        ...payload,
        type: 4,
      };

      const { data } = await api.post('/v2/migration-assisted/', migration);

      setLoading(false);
      history.push(`/invoices/${data.invoice}`);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/migrations">Migração</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/migrations/create">Nova migração</Link>
            </Breadcrumb>
          </Breadcrumbs>
          <FontAwesomeIcon
            icon={faExchangeAlt}
            style={{ marginRight: '10px' }}
          />
          Nova migração assistida
        </Title>
      </Header>

      <MigrationWrapper>
        <MigrationForm>
          <Form schema={schema} onSubmit={submit}>
            <Row>
              <Col md="8">
                <Field>
                  <Label>Domínio</Label>
                  <FormInput
                    block
                    name="domain"
                    placeholder="Ex.: Migração de site"
                  />
                </Field>
              </Col>
              <Col md="4">
                <Field>
                  <Label>Custo</Label>
                  <Cost>
                    <p>
                      <span>R$</span> 50,00
                    </p>
                  </Cost>
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <Field>
                  <Label>Messagem</Label>
                  <FormTextArea
                    style={{
                      minHeight: '50px',
                    }}
                    placeholder="Digite algumas informações da hospedagem"
                    block="true"
                    name="message"
                  />
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md="8" />
              <Col md="4">
                <Button
                  type="submit"
                  style={{
                    width: '100%',
                    marginLeft: 'auto',
                  }}
                  icon
                  disabled={loading}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Criar'
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </MigrationForm>

        <MigrationInfo>
          <div>
            <h1>Pessoas é o que nos motiva!</h1>
            <h3>
              Queremos te ajudar a utilizar o melhor da tecnologia Cloud, sem
              complicações
            </h3>
            <p>
              Temos um time que vai ficar feliz em atender você mas talvez seja
              mais ágil tirar suas dúvidas em nossa base de conhecimento. Ah,
              tenha em mente que vamos te ajudar em tudo que pudermos mas que
              algumas questões são muito particulares às aplicações e nesse caso
              algumas vezes foge ao nosso escopo.
            </p>
          </div>
          <div
            style={{
              backgroundColor: theme.brand_primary_color,
              margin: '10px auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '215px',
            }}
          >
            <img src={ticketinfo} alt="ticketinfo" />
          </div>
        </MigrationInfo>
      </MigrationWrapper>
    </>
  );
};

export default props => (
  <MigrationCreateProvider>
    <MigrationCreateAssisted {...props} />
  </MigrationCreateProvider>
);
