import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import { Row, Col } from 'react-bootstrap';

import {
  Field,
  FormInput,
  Input,
  Label,
  Checkbox,
  Form,
  Error,
} from 'components/Input';
import Axios from 'axios';
import { Scope } from '@rocketseat/unform';
import api from 'services/api';
import { SearchWrapper } from 'components/SearchBar/styles';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeContext } from 'styled-components';
import { FormSelect } from '@cloudez/cloudez-design-system';
import { Wrapper, Helper } from './styles';

const userSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .email('Por favor, insira um email válido.')
      .required('Este campo é obrigatório'),
    full_name: Yup.string().required('Este campo é obrigatório'),
    phone: Yup.object().shape({
      number: Yup.string().required('Este campo é obrigatório'),
    }),
  }),
});

const billingSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .email('Por favor, insira um email válido.')
    .required('Este campo é obrigatório'),
  document_id: Yup.string(),
  address_number: Yup.string().required('Este campo é obrigatório'),
  country: Yup.string().required('Este campo é obrigatório'),
  address1: Yup.string().required('Este campo é obrigatório'),
  address2: Yup.string().required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
});

const schema = Yup.object().shape({
  billing: billingSchema,
  user: userSchema,
});

const Info = ({
  setStage,
  stage,
  setPayload,
  loading: _loading,
  setLoading: _setLoading,
}) => {
  const theme = useContext(ThemeContext);
  const [userErrors, setUserErrors] = useState<any>([]);
  const [document, setDocument] = useState(0);
  const [billingErrors, setBillingErrors] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [createUser, setCreateUser] = useState(true);
  const [user, setUser] = useState(null);
  const [cep, setCEP] = useState('');
  const [askClientData, setAskClientData] = useState(true);
  const [sendCreate, setSendCreate] = useState(true);
  const [address, setAddress] = useState({
    state: '',
    address1: '',
    address2: '',
    city: '',
    zipcode: '',
  });

  const getCEP = async _cep => {
    try {
      setLoading(true);
      const { data } = await Axios.get(
        `https://viacep.com.br/ws/${_cep}/json/`,
      );

      setAddress({
        state: data.uf,
        address1: data.logradouro,
        address2: data.bairro,
        city: data.localidade,
        zipcode: _cep,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmit = async d => {
    _setLoading(true);
    let _user = null;

    try {
      if (createUser) {
        const user = askClientData
          ? { ...d.user, notify_email: sendCreate }
          : { ...d.user.user, notify_email: sendCreate };
        // eslint-disable-next-line
        user.phone.number = `+55${user.phone.number.replace(/\.|\-|\//g, '')}`;

        const { data } = await api.post('/v3/company/user/', user);
        _user = data;
        setUser(data);
        setUserErrors([]);
        setCreateUser(false);
        _setLoading(false);
      }
    } catch (e) {
      _setLoading(false);
      setUserErrors(e.response.data);
    }

    try {
      if (!askClientData && (_user || user)) {
        d.billing.document_id = d.billing.document_id.replace(
          // eslint-disable-next-line
          /\.|\-|\//g,
          '',
        );

        const billingPayload = {
          ...d.billing,
          phone_number: user.phone.number,
          ...address,
          user: user ? user.id : _user.id,
        };

        await api.patch(`/v3/billing/${billingPayload.user}/`, billingPayload);
        setBillingErrors([]);

        _setLoading(false);
        setPayload(user || _user);
        setStage(stage + 1);
      } else if (_user || user) {
        _setLoading(false);
        setPayload(user || _user);
        setStage(stage + 1);
      }
    } catch (e) {
      _setLoading(false);
      setBillingErrors(e.response.data);
    }
  };

  return (
    <>
      <Form
        schema={askClientData ? userSchema : schema}
        onSubmit={handleSubmit}
        id="infoForm"
      >
        <Row>
          <Col xl="6" md="12">
            <Wrapper isFocused>
              <h3>Informações pessoais</h3>
              <Row
                style={{
                  margin: '0',
                }}
              >
                <Col>
                  <Scope path={askClientData ? 'user' : 'user.user'}>
                    <Field>
                      <Label htmlFor="user.full_name" required>
                        Nome *
                      </Label>
                      <FormInput
                        block="true"
                        name="full_name"
                        error={!!userErrors.full_name}
                      />
                      {userErrors.full_name && (
                        <Error>{userErrors.full_name[0]}</Error>
                      )}
                    </Field>
                    <Field>
                      <Label htmlFor="user.email" required>
                        Email *
                      </Label>
                      <FormInput
                        block="true"
                        name="email"
                        error={!!userErrors.email}
                      />
                      {userErrors.email && <Error>{userErrors.email[0]}</Error>}
                    </Field>
                    <Field>
                      <Label htmlFor="user.phone.number" required>
                        Telefone *
                      </Label>
                      <Scope path="phone">
                        <InputMask
                          mask="(99) 99999-9999"
                          maskChar={null}
                          block="true"
                          name="number"
                          error={!!userErrors.phone}
                        >
                          {inputProps => {
                            return <FormInput {...inputProps} />;
                          }}
                        </InputMask>

                        {userErrors.phone && (
                          <Error>{userErrors.phone.number[0]}</Error>
                        )}
                      </Scope>
                    </Field>
                  </Scope>
                  <Field>
                    <Label>
                      Enviar email de criação de conta para o cliente
                    </Label>
                    <Checkbox
                      data-testid="sendCreate"
                      checked={sendCreate}
                      value={sendCreate}
                      onChange={e => setSendCreate(e.target.checked)}
                    />
                  </Field>
                  <Field>
                    <Label>Solicitar dados ao cliente por email</Label>
                    <Checkbox
                      data-testid="askClientData"
                      checked={askClientData}
                      value={askClientData}
                      onChange={e => setAskClientData(e.target.checked)}
                    />
                    {askClientData && (
                      <Helper>
                        Será enviado um e-mail para que o cliente insira os
                        dados financeiros
                      </Helper>
                    )}
                  </Field>
                  <Field>
                    <Label>Preencher dados agora</Label>
                    <Checkbox
                      data-testid="moreInfos"
                      checked={!askClientData}
                      value={!askClientData}
                      onChange={e => setAskClientData(!e.target.checked)}
                    />
                    {!askClientData && (
                      <Helper>
                        Preencha os campos abaixo com os dados do cliente. Caso
                        não os tenha, ligue a opção acima.
                      </Helper>
                    )}
                  </Field>
                </Col>
              </Row>
            </Wrapper>
          </Col>
          <Col xl="6" md="12">
            <Wrapper isFocused={!askClientData}>
              <h3>
                Informações financeiras
                <p>
                  Primeiro, preencha os dados do cliente ao lado e clique em
                  CRIAR
                </p>
              </h3>

              <Row
                style={{
                  margin: '0',
                }}
              >
                <Scope path="billing">
                  <Col>
                    <Field>
                      <Label required>Nome *</Label>
                      <FormInput
                        disabled={askClientData}
                        background={askClientData}
                        block="true"
                        name="name"
                        error={!!billingErrors.name}
                      />
                      {billingErrors.name && (
                        <Error>{billingErrors.name[0]}</Error>
                      )}
                    </Field>
                    <Row>
                      <Col>
                        <Field>
                          <Label required>Email financeiro *</Label>
                          <FormInput
                            disabled={askClientData}
                            background={askClientData}
                            block="true"
                            placeholder="cliente@company.com"
                            type="email"
                            name="email"
                            error={!!billingErrors.email}
                          />
                          {billingErrors.email && (
                            <Error>{billingErrors.email[0]}</Error>
                          )}
                        </Field>
                      </Col>
                      <Col>
                        <Field>
                          <Label>
                            <span
                              style={{
                                cursor: 'pointer',
                                color:
                                  document === 0
                                    ? theme.brand_primary_color
                                    : theme.interface4,
                                display: 'inline',
                              }}
                              onClick={() => setDocument(0)}
                            >
                              CPF
                            </span>
                            /
                            <span
                              style={{
                                cursor: 'pointer',
                                color:
                                  document === 1
                                    ? theme.brand_primary_color
                                    : theme.interface4,
                                display: 'inline',
                              }}
                              onClick={() => setDocument(1)}
                            >
                              CNPJ
                            </span>
                          </Label>
                          <InputMask
                            mask={
                              document === 0
                                ? '999.999.999-999'
                                : '99.999.999/9999-99'
                            }
                            maskChar={null}
                            block="true"
                            background={askClientData}
                            onChange={e => {
                              if (e.target.value.length >= 14) {
                                setDocument(1);
                              }
                            }}
                            name="document_id"
                            error={!!billingErrors.document_id}
                          >
                            {inputProps => (
                              <div>
                                <FormInput
                                  disabled={askClientData}
                                  {...inputProps}
                                />
                              </div>
                            )}
                          </InputMask>
                          {billingErrors.document_id && (
                            <Error>{billingErrors.document_id[0]}</Error>
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="3">
                        <Field>
                          <Label required>Número *</Label>
                          <FormInput
                            disabled={askClientData}
                            background={askClientData}
                            block="true"
                            name="address_number"
                            error={!!billingErrors.address_number}
                          />
                          {billingErrors.address_number && (
                            <Error>{billingErrors.address_number[0]}</Error>
                          )}
                        </Field>
                      </Col>

                      <Col>
                        <Field>
                          <Label
                            style={{
                              marginBottom: '-12px',
                            }}
                            required
                          >
                            CEP *
                          </Label>

                          <InputMask
                            mask="99999-999"
                            data-testid="cep"
                            maskChar={null}
                            alwaysShowMask="true"
                            loading={loading}
                            value={cep}
                            onChange={e => {
                              setCEP(e.target.value);
                              if (e.target.value.length >= 9) {
                                const _cep = e.target.value.replace(
                                  // eslint-disable-next-line
                                  /\.|\-|\//g,
                                  '',
                                );
                                getCEP(_cep);
                              }
                            }}
                            block="true"
                          >
                            {inputProps => {
                              return (
                                <SearchWrapper
                                  onboard
                                  block
                                  style={{
                                    marginTop: '18px',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={loading ? faSpinner : faSearch}
                                    spin={loading}
                                    onClick={
                                      loading
                                        ? null
                                        : e => {
                                            const _cep = cep.replace(
                                              // eslint-disable-next-line
                                              /\.|\-|\//g,
                                              '',
                                            );
                                            getCEP(_cep);
                                          }
                                    }
                                  />
                                  <Input
                                    disabled={askClientData}
                                    {...inputProps}
                                    style={{ paddingLeft: '38px' }}
                                    error={!!billingErrors.zipcode}
                                  />
                                  {billingErrors.zipcode && (
                                    <Error>{billingErrors.zipcode[0]}</Error>
                                  )}
                                </SearchWrapper>
                              );
                            }}
                          </InputMask>
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field>
                          <Label>Endereço</Label>
                          <FormInput
                            disabled={!address.zipcode}
                            background={!address.zipcode}
                            block="true"
                            value={address.address1}
                            onChange={e =>
                              setAddress({
                                ...address,
                                address1: e.target.value,
                              })
                            }
                            name="address1"
                          />
                        </Field>
                      </Col>
                      <Col>
                        <Field>
                          <Label>BAIRRO</Label>
                          <FormInput
                            disabled={!address.zipcode}
                            background={!address.zipcode}
                            block="true"
                            value={address.address2}
                            onChange={e =>
                              setAddress({
                                ...address,
                                address2: e.target.value,
                              })
                            }
                            name="address2"
                          />
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Field>
                          <Label required>País *</Label>
                          <FormInput
                            block="true"
                            name="country"
                            disabled={askClientData}
                            error={!!billingErrors.country}
                          />
                          {billingErrors.country && (
                            <Error>{billingErrors.country[0]}</Error>
                          )}
                        </Field>
                      </Col>
                      <Col xl="2">
                        <Field>
                          <Label>Estado</Label>
                          <FormSelect
                            options={[
                              { title: 'AC', id: 'AC' },
                              { title: 'AL', id: 'AL' },
                              { title: 'AP', id: 'AP' },
                              { title: 'AM', id: 'AM' },
                              { title: 'BA', id: 'BA' },
                              { title: 'CE', id: 'CE' },
                              { title: 'DF', id: 'DF' },
                              { title: 'ES', id: 'ES' },
                              { title: 'GO', id: 'GO' },
                              { title: 'MA', id: 'MA' },
                              { title: 'MT', id: 'MT' },
                              { title: 'MS', id: 'MS' },
                              { title: 'MG', id: 'MG' },
                              { title: 'PA', id: 'PA' },
                              { title: 'PB', id: 'PB' },
                              { title: 'PR', id: 'PR' },
                              { title: 'PE', id: 'PE' },
                              { title: 'PI', id: 'PI' },
                              { title: 'RJ', id: 'RJ' },
                              { title: 'RN', id: 'RN' },
                              { title: 'RS', id: 'RS' },
                              { title: 'RO', id: 'RO' },
                              { title: 'RR', id: 'RR' },
                              { title: 'SC', id: 'SC' },
                              { title: 'SP', id: 'SP' },
                              { title: 'SE', id: 'SE' },
                              { title: 'TO', id: 'TO' },
                            ]}
                            disabled={!address.zipcode}
                            block
                            value={address.state}
                            onChange={e =>
                              setAddress({
                                ...address,
                                state: e.target.value,
                              })
                            }
                            name="state"
                          />
                        </Field>
                      </Col>
                      <Col>
                        <Field>
                          <Label>Cidade</Label>
                          <FormInput
                            disabled={!address.zipcode}
                            background={!address.zipcode}
                            onChange={e =>
                              setAddress({
                                ...address,
                                city: e.target.value,
                              })
                            }
                            block="true"
                            value={address.city}
                            name="city"
                          />
                        </Field>
                      </Col>
                    </Row>
                  </Col>
                </Scope>
              </Row>
            </Wrapper>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Info;
