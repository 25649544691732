import React, { InputHTMLAttributes } from 'react';
import { Label } from '@cloudez/cloudez-design-system';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseFormRegister } from 'react-hook-form';
import { Input } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  strongLabel?: boolean;
  name: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: any;
  block?: boolean;
  background?: boolean;
  icon?: {
    svg: IconDefinition;
    action?: any;
    isRight?: boolean;
    color?: any;
  };
  mb?: string;
}

export const CustomInput = ({
  label,
  name,
  register,
  required,
  error,
  icon,
  strongLabel,
  background,
  width,
  ...rest
}: InputProps): JSX.Element => {
  return (
    <Input
      width={width}
      icon={!!icon?.svg}
      error={error}
      background={background}
      {...rest}
    >
      {label && <Label strongLabel={strongLabel}>{label}</Label>}
      <div className={`withIcon ${icon?.isRight ? 'isRight' : null}`}>
        {register ? (
          <input {...register(name, { required })} {...rest} />
        ) : (
          <input {...rest} />
        )}
        {icon?.svg && (
          <div className={`icon ${icon?.isRight ? 'isRight' : null}`}>
            <FontAwesomeIcon
              spin={icon.svg.iconName === 'spinner'}
              onClick={icon?.action}
              icon={icon.svg}
              style={{ color: icon?.color }}
            />
          </div>
        )}
      </div>

      {error && <span className="error">{error}</span>}
    </Input>
  );
};
