import { useState, useEffect, useContext } from 'react';
import { Button } from '@cloudez/cloudez-design-system';

import api from 'services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import history from 'services/history';

import { ThemeContext } from 'styled-components';
import { useAuth } from 'hooks';
import InvoiceSent from './InvoiceSent';
import Form from './Form';
import { Header, Wrapper } from './styles';
import Visual from './Visual';
import { OnboardContext, OnboardProvider } from './_context/state';

const Onboard = () => {
  const { user, getCompany, getUser, company } = useAuth();
  const theme = useContext(ThemeContext);

  const { primaryLogo, reducedLogo, favicon } = useContext(OnboardContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [stage, setStage] = useState(1);
  const [plans, setPlans] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [color1, setColor1] = useState('#1A213A');
  const [color2, setColor2] = useState('#FFDC51');
  const [picker1, setPicker1] = useState(false);
  const [picker2, setPicker2] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyDomain, setCompanyDomain] = useState('');
  const [payload, setPayload] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [companyBilling, setCompanyBilling] = useState({
    document_id: '',
    business_name: '',
    fantasy_name: '',
    inscricao_estadual: '',
    address1: '',
    address2: '',
    address_number: '',
    country: 'Brasil',
    city: '',
    state: '',
    zipcode: '',
  });
  // const [selectedCloud, setSelectedCloud] = useState(null);
  // const [selectedNodeappType, setSelectedNodeappType] = useState(null);
  // const [selectedShareResource, setSelectedShareResource] = useState(null);
  // const [domain, setDomain] = useState('');
  // const [tab, setTab] = useState(1);

  // const createClientPlan = async () => {
  //   setLoading(true);
  //   let data = {
  //     user: payload.id,
  //     plan_type: selectedPlan.id,
  //   };

  //   if (selectedPlan.category === 9) {
  //     if (tab === 1) {
  //       data = {
  //         ...data,
  //         nodeapp_id: selectedShareResource.id,
  //       };
  //     } else {
  //       data = {
  //         ...data,
  //         nodeapp_type: selectedNodeappType.id,
  //         domain: domain !== '' ? domain : null,
  //         node_id: selectedCloud.id,
  //       };
  //     }
  //   } else if (selectedPlan.category === 10) {
  //     if (tab === 1) {
  //       data = {
  //         ...data,
  //         zoneapp_id: selectedShareResource.id,
  //       };
  //     } else {
  //       data = {
  //         ...data,
  //         domain,
  //         node_id: selectedCloud.id,
  //       };
  //     }
  //   }

  //   try {
  //     const invoice = await api.post('/v3/company/user/setup/', data);

  //     setInvoice(invoice.data.invoice);

  //     setLoading(false);
  //     setStage(stage + 1);
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(
  //       'Algo de errado aconteceu. Por favor, entre em contato com o suporte.',
  //     );
  //   }
  // };

  useEffect(() => {
    async function getPlans() {
      try {
        const {
          data: { results },
        } = await api.get('/v3/company/plan/type/');
        setPlans(results);
      } catch (e) {
        console.log(e);
      }
    }

    getCompany();
    if (!user.company && !plans) getPlans();
    // eslint-disable-next-line
  }, []);

  // const createCompanyBilling = async code => {
  //   try {
  //     await api.patch(`/v3/company/billing/${code}/`, companyBilling);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const createCompany = async () => {
    const data = new FormData() as any;

    data.append('logo_primary', primaryLogo);
    data.append('logo_reduced', reducedLogo);
    data.append('favicon', favicon);
    data.append('brand_primary_color', color1);
    data.append('brand_secondary_color', color2);
    data.append('name', companyName);
    data.append('app_domains', companyDomain);
    data.append('activation_type', 7);

    try {
      setLoading(true);
      let _data;
      if (!company) {
        const response = await api.post('/v3/company/', data);
        _data = response.data;
        setErrors({});
      }
      await getUser(user.id);
      setLoading(false);
      history.push('/invoices');
      // setStage(stage + 1);
    } catch (e) {
      setErrors({
        ...errors,
        ...e.response.data,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompany();
  }, [user]);

  return (
    <>
      <Wrapper>
        <Header>
          <h1>
            {stage === 1
              ? 'Configuração do painel'
              : stage === 2
              ? 'Cadastro'
              : // : stage === 3
              // ? 'Criação de cliente'
              // : stage === 4
              // ? 'Criação de plano'
              stage === 3
              ? 'Finalização'
              : ''}
          </h1>
          {/* <Progress status={stage} /> */}
        </Header>

        {stage === 1 && (
          <Visual
            errors={errors}
            color1={color1}
            color2={color2}
            picker1={picker1}
            picker2={picker2}
            setColor1={setColor1}
            setColor2={setColor2}
            setPicker1={setPicker1}
            setPicker2={setPicker2}
            companyName={companyName}
            setCompanyName={setCompanyName}
            companyDomain={companyDomain}
            setCompanyDomain={setCompanyDomain}
            setDisabled={setDisabled}
          />
        )}

        {stage === 2 && (
          <Form
            companyBilling={companyBilling}
            setCompanyBilling={setCompanyBilling}
            setDisabled={setDisabled}
            errors={errors}
          />
        )}
        {/* {stage === 3 && (
          <Info
            setStage={setStage}
            stage={stage}
            setPayload={setPayload}
            payload={payload}
            setLoading={setLoading}
            loading={loading}
          />
        )}

        {stage === 4 && (
          <Plans
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
            selectedCloud={selectedCloud}
            setSelectedCloud={setSelectedCloud}
            tab={tab}
            setTab={setTab}
            selectedShareResource={selectedShareResource}
            setSelectedShareResource={setSelectedShareResource}
            domain={domain}
            setDomain={setDomain}
            selectedNodeappType={selectedNodeappType}
            setSelectedNodeappType={setSelectedNodeappType}
            onboard
          />
        )} */}

        {stage === 3 && (
          <InvoiceSent
            selectedPlan={selectedPlan}
            companyBilling={companyBilling}
            user={payload}
            invoice={invoice}
          />
        )}

        {((stage === 2 && disabled) ||
          (stage === 1 &&
            (!primaryLogo ||
              !reducedLogo ||
              companyName === '' ||
              companyDomain === '')) ||
          (stage === 4 && !selectedPlan)) && (
          <span
            style={{
              color: theme.interface5,
              marginBottom: '-10px',
              fontSize: '12px',
            }}
          >
            * Os campos em destaque são obrigatórios
          </span>
        )}
        {/* {stage === 3 ? (
          <Button
            icon={loading}
            style={{
              marginTop: '20px',
            }}
            type="submit"
            form="infoForm"
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Próximo passo'
            )}
          </Button>
        ) : ( */}
        <Button
          icon={loading}
          style={{
            marginTop: '20px',
          }}
          // disabled={
          //   stage < 5
          //     ? (stage === 1 && disabled) ||
          //       (stage === 2 &&
          //         (!primaryLogo ||
          //           !reducedLogo ||
          //           companyName === '' ||
          //           companyDomain === '')) ||
          //       (stage === 4 && !selectedPlan) ||
          //       loading
          //     : false
          // }
          onClick={() => {
            if (stage <= 5) {
              if (stage === 1) {
                createCompany();
              }
              // else if (stage === 2) {
              //   createCompanyBilling(user.company);
              // } else if (stage === 3) {
              //   getCompany();
              //   history.push('/dashboard');
              // } else {
              //   setStage(stage + 1);
              //   setDisabled(true);
              // }
            }
          }}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : stage <= 2 ? (
            'Criar Painel'
          ) : stage === 3 ? (
            'Painel'
          ) : (
            'Finalizar'
          )}
        </Button>
        {/* )} */}

        {stage > 1 && stage < 2 && (
          <Button
            outline
            onClick={() => setStage(stage - 1)}
            style={{
              marginTop: '20px',
            }}
          >
            Voltar
          </Button>
        )}

        {/* {stage === 3 && (
          <Button
            outline
            icon={loading}
            style={{
              marginTop: '20px',
            }}
            onClick={() => {
              getCompany();
              history.push('/dashboard');
            }}
          >
            criar depois
          </Button>
        )} */}
      </Wrapper>
    </>
  );
};

export default props => (
  <OnboardProvider>
    <Onboard {...props} />
  </OnboardProvider>
);
