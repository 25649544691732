import React, { useContext, useMemo } from 'react';
import CurrencyFormat from 'react-currency-format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faSpinner,
  faCloud,
  faWindowMaximize,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { Field, Select, Label, Checkbox } from 'components/Input';
import { Value } from 'components/Acessos';
import { ThemeContext } from 'styled-components';
import { useAuth } from 'hooks/auth';
import { Row, Col } from 'react-bootstrap';
import { Badge, PlanPreview, SharedPreview } from '../Plans/styles';

import { PlanCard } from './styles';

const NewConfirmModal = ({
  show,
  setShow,
  p,
  lifespan,
  setLifespan,
  action,
  loading,
  user,
  setIsTurbo,
  isTurbo,
  selectedShareResource,
}) => {
  const theme = useContext(ThemeContext);
  const { company } = useAuth();

  const isEmail = useMemo(() => {
    if (!p.cloud_size) return false;
    return p.cloud_size.category === 3;
  }, [p.cloud_size]);

  const lifespans = {
    1: {
      name: 'mensal',
      discount: 1,
    },
    12: {
      name: 'anual',
      discount: 0.9,
    },
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Confirmar operação</Title>
          <Subtitle>
            Deseja vincular este plano ao cliente? Esta operação irá criar uma
            fatura.
          </Subtitle>
        </Text>
      </Header>

      <Content>
        <Field
          style={{
            marginBottom: 30,
          }}
        >
          <Label>Nome do cliente</Label>
          <Value
            style={{
              color: theme.interface5,
              fontSize: '14px',
            }}
          >
            {user?.name || user?.full_name}
          </Value>
        </Field>
        <Field
          style={{
            marginBottom: 30,
          }}
        >
          <Label>Email do cliente</Label>
          <Value
            style={{
              color: theme.interface5,
              fontSize: '14px',
            }}
          >
            {user?.email}
          </Value>
        </Field>

        {(p.category === 9 || p.category === 10) && (
          <Field
            style={{
              marginBottom: 30,
            }}
          >
            <Label>{p.category === 9 ? 'Website' : 'Email'} selecionado</Label>
            <Value
              style={{
                color: theme.interface5,
                fontSize: '14px',
              }}
            >
              {selectedShareResource?.domain}
            </Value>
          </Field>
        )}
        {(company.provider.includes('partner-g3') ||
          company.provider.includes('partner-g4') ||
          company?.provider?.includes('dpg-custom')) &&
          p.has_basic &&
          p.has_turbo && (
            <Field>
              <Label>Modo do cloud</Label>
              <Select
                height="40px"
                placeholder="Selecione um modo"
                value={isTurbo}
                onChange={e => setIsTurbo(e.target.value === 'true')}
                block="true"
                name="recorrencia"
              >
                <option value="false">Basic</option>
                <option value="true">Turbo</option>
              </Select>
            </Field>
          )}
        <Field
          style={{
            marginBottom: 30,
          }}
        >
          <Label htmlFor="lifeSpan">Recorrência</Label>
          <Select
            id="lifeSpan"
            height="40px"
            placeholder="Selecione uma recorrência"
            value={lifespan}
            onChange={e => setLifespan(e.target.value)}
            block="true"
            name="recorrencia"
          >
            <option value="1">Mensal</option>
            <option value="12">Anual (10% de desconto)</option>
          </Select>
        </Field>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PlanPreview>
            {p.category && p.category === 1 ? (
              <>
                <div className="plan-title">
                  <span>{p.name || '--------------'}</span>
                  <span>
                    {p.cloud_size ? <>{p.cloud_size?.memory} RAM</> : '-------'}
                  </span>
                </div>

                <p className="tip">
                  {p.description !== 'False'
                    ? p.description
                    : '------------------------'}
                </p>

                <div className="stats">
                  <div>
                    <span>DISCO</span>
                    <span className="value">{p.cloud_size?.disk || '-'}</span>
                  </div>

                  <div>
                    <span>PROCESSADOR</span>
                    <span className="value">
                      {p.cloud_size ? (
                        <>{p.cloud_size?.cores} CPU Core </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </div>
                </div>

                {isEmail && (
                  <div className="type">
                    <h3>Email</h3>
                    <Row>
                      <Col>
                        <div className="price">
                          <CurrencyFormat
                            className="amount"
                            value={
                              lifespan === '1'
                                ? p.cloud_size.price.amount *
                                  lifespans[lifespan].discount
                                : p.cloud_size.price.amount *
                                  12 *
                                  lifespans[lifespan].discount
                            }
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            // prefix={
                            //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                            // }
                            prefix="R$"
                          />
                          <span className="label">Preço Base</span>
                        </div>
                      </Col>

                      <Col>
                        <div className="price">
                          <CurrencyFormat
                            className="amount final"
                            value={
                              lifespan === '1'
                                ? (p.cloud_size.price.amount +
                                    Number(p.base_price.amount)) *
                                  lifespans[lifespan].discount
                                : (p.cloud_size.price.amount +
                                    Number(p.base_price.amount)) *
                                  12 *
                                  lifespans[lifespan].discount
                            }
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            // prefix={
                            //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                            // }
                            prefix="R$"
                          />
                          <span className="label">Final</span>
                        </div>
                      </Col>
                    </Row>
                    <div className="profit">
                      <div className="price">
                        <span className="label">Lucro Mensal*</span>

                        <CurrencyFormat
                          className="profit-amount"
                          value={p.base_price.amount || '0'}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          // prefix={
                          //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                          // }
                          prefix="R$"
                        />
                      </div>

                      <div className="price">
                        <span className="label">Lucro Anual*</span>

                        <CurrencyFormat
                          className="profit-amount"
                          value={
                            p.base_price.amount * 12 -
                              p.base_price.amount * 12 * 0.15 || 0
                          }
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          // prefix={
                          //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                          // }
                          prefix="R$"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {p.has_basic && p.cloud_size && !isEmail && (
                  <div className="type">
                    <h3>Basic</h3>
                    <Row>
                      <Col>
                        <div className="price">
                          <CurrencyFormat
                            className="amount"
                            value={
                              lifespan === '1'
                                ? p.cloud_size.price.amount *
                                  lifespans[lifespan].discount
                                : p.cloud_size.price.amount *
                                  12 *
                                  lifespans[lifespan].discount
                            }
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            // prefix={
                            //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                            // }
                            prefix="R$"
                          />
                          <span className="label">Preço Base</span>
                        </div>
                      </Col>

                      <Col>
                        <div className="price">
                          <CurrencyFormat
                            className="amount final"
                            value={
                              lifespan === '1'
                                ? (p.cloud_size?.price.amount +
                                    Number(p.base_price.amount)) *
                                  lifespans[lifespan].discount
                                : (p.cloud_size?.price.amount +
                                    Number(p.base_price.amount)) *
                                  12 *
                                  lifespans[lifespan].discount
                            }
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            // prefix={
                            //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                            // }
                            prefix="R$"
                          />
                          <span className="label">Final</span>
                        </div>
                      </Col>
                    </Row>
                    <div className="profit">
                      <div className="price">
                        <span className="label">Lucro Mensal*</span>

                        <CurrencyFormat
                          className="profit-amount"
                          value={p.base_price.amount || '0'}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          // prefix={
                          //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                          // }
                          prefix="R$"
                        />
                      </div>

                      <div className="price">
                        <span className="label">Lucro Anual*</span>

                        <CurrencyFormat
                          className="profit-amount"
                          value={
                            p.base_price.amount * 12 -
                              p.base_price.amount * 12 * 0.15 || 0
                          }
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          // prefix={
                          //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                          // }
                          prefix="R$"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {p.has_turbo && p.cloud_size && !isEmail && (
                  <div className="type">
                    <h3>Turbo</h3>
                    <Row>
                      <Col>
                        <div className="price">
                          <CurrencyFormat
                            className="amount"
                            value={
                              lifespan === '1'
                                ? p.cloud_size.turbo_price.amount *
                                  lifespans[lifespan].discount
                                : p.cloud_size.turbo_price.amount *
                                  12 *
                                  lifespans[lifespan].discount
                            }
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            // prefix={
                            //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                            // }
                            prefix="R$"
                          />
                          <span className="label">Preço Base</span>
                        </div>
                      </Col>

                      <Col>
                        <div className="price">
                          <CurrencyFormat
                            className="amount final"
                            value={
                              lifespan === '1'
                                ? (p.cloud_size.turbo_price.amount +
                                    Number(p.turbo_base_price.amount)) *
                                  lifespans[lifespan].discount
                                : (p.cloud_size.turbo_price.amount +
                                    Number(p.turbo_base_price.amount)) *
                                  12 *
                                  lifespans[lifespan].discount
                            }
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            // prefix={
                            //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                            // }
                            prefix="R$"
                          />
                          <span className="label">Final</span>
                        </div>
                      </Col>
                    </Row>
                    <div className="profit">
                      <div className="price">
                        <span className="label">Lucro Mensal*</span>

                        <CurrencyFormat
                          className="profit-amount"
                          value={p.turbo_base_price.amount || 0}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          // prefix={
                          //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                          // }
                          prefix="R$"
                        />
                      </div>

                      <div className="price">
                        <span className="label">Lucro Anual*</span>

                        <CurrencyFormat
                          className="profit-amount"
                          value={
                            p.turbo_base_price.amount * 12 -
                              p.turbo_base_price.amount * 12 * 0.15 || 0
                          }
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          // prefix={
                          //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                          // }
                          prefix="R$"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : p.category ? (
              <SharedPreview>
                <h4>{p.name || '--------------'}</h4>
                <div className="shared-info">
                  <span className="category">
                    {p.category === 9
                      ? 'Site compartilhado'
                      : 'E-mail compartilhado'}
                  </span>

                  <div className="price">
                    <h6>Lucro Mensal*</h6>
                    <CurrencyFormat
                      className="profit-amount"
                      value={p.base_price.amount || 0}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$ "
                    />
                  </div>

                  <div className="price">
                    <h6>Lucro Anual*</h6>
                    <CurrencyFormat
                      className="profit-amount"
                      value={p.base_price.amount * 12 - p.base_price.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$ "
                    />
                  </div>
                </div>
              </SharedPreview>
            ) : (
              <div className="no-category">Selecione uma categoria</div>
            )}
          </PlanPreview>
        </div>
      </Content>
      <Footer>
        <Button onClick={action} icon disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default NewConfirmModal;
