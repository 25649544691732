import styled from 'styled-components';

export const HttpsText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
  margin-bottom: 15px;
`;

export const Value = styled.p`
  font-weight: 700;
  font-size: 24px !important;

  display: flex;
  align-items: center;

  color: ${props => props.theme.brand_primary_color} !important;
`;

export const Advanced = styled.div`
  font-size: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  gap: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    color: ${props => props.theme.brand_primary_color} !important;
    font-weight: 400;
    font-size: 16px !important;
  }
`;
