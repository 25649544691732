import { useContext } from 'react';
import { HelpContext, HelpContextState } from 'context/help';

export function useHelp(): HelpContextState {
  const context = useContext(HelpContext);

  if (!context) {
    throw new Error('useHelp must be used within an AuthProvider');
  }

  return context;
}
