import styled from 'styled-components';
import { Icon, IconHover, IconAvatar } from 'components/HoverableIcon/styles';

export const Elipse = styled.div<any>`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => props.theme.interface2};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;

  span {
    position: absolute;
    top: -2px;
    right: -12px;
  }

  svg {
    font-size: 14px;
  }

  &:hover {
    background: ${props => props.theme.brand_primary_color};
    color: ${props => props.theme.interface1};

    ${Icon} {
      transition: all 0.2s ease-in;
      display: none;
    }

    ${IconHover} {
      display: block;
    }

    ${IconAvatar} {
      border: 1px solid ${props => props.theme.brand_primary_color};
      width: 100%;
      height: 100%;
      transition: all 0.1s ease-in;
    }
  }
`;
