import React, { CSSProperties, useCallback } from 'react';

import { ParsedQuery } from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faArrowLeft,
  faChevronRight,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

import {
  Wrapper,
  First,
  Last,
  Previous,
  Next,
  PageNumber,
  Pages,
} from './styles';

type PaginationProps = {
  search?: ParsedQuery<string> | string | string[];
  next: number;
  onChangePage: (
    newPage: string | number,
    search: ParsedQuery<string> | string | string[],
    status?: number,
    filter?: string,
    order?: any,
  ) => void;
  count: number;
  style?: CSSProperties;
  previous: number;
  current: number;
  isDropdown?: boolean;
  ticket?: boolean;
  pageSize?: number | string;
  status?: number;
  filter?: any;
  clouds?: any;
  order?: any;
};

const Pagination: React.FC<PaginationProps> = ({
  search,
  next,
  previous,
  count,
  current,
  onChangePage,
  pageSize = 10,
  ticket = false,
  status,
  filter,
  clouds,
  style,
  isDropdown = false,
  order,
}) => {
  const last = Math.ceil(count / Number(pageSize));

  const handlePageChange = useCallback(
    newPage => {
      if (ticket) {
        onChangePage(newPage, search, status, order);
      } else {
        onChangePage(newPage, search, filter, clouds, order);
      }
    },
    [ticket, search, status, clouds, filter, onChangePage, order],
  );

  return (
    <Wrapper style={style}>
      <First
        onClick={() => previous && handlePageChange(1)}
        disabled={!previous}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faArrowLeft} />
      </First>

      <Previous
        onClick={() => previous && handlePageChange(previous)}
        disabled={!previous}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        {!isDropdown && <p>voltar</p>}
      </Previous>

      <Pages>
        {previous && (
          <PageNumber onClick={() => handlePageChange(previous)}>
            {previous}
          </PageNumber>
        )}

        <PageNumber current={previous || next}>{current}</PageNumber>

        {next && (
          <PageNumber onClick={() => handlePageChange(next)}>{next}</PageNumber>
        )}
      </Pages>

      <Next onClick={() => next && handlePageChange(next)} disabled={!next}>
        {!isDropdown && <p>avançar</p>}
        <FontAwesomeIcon icon={faArrowRight} />
      </Next>

      <Last onClick={() => next && handlePageChange(last)} disabled={!next}>
        <FontAwesomeIcon icon={faArrowRight} />
        <FontAwesomeIcon icon={faChevronRight} />
      </Last>
    </Wrapper>
  );
};

export default Pagination;
