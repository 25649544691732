import { createContext, useReducer } from 'react';
import { Billing, Clouds, CompanyPlan, Invoices, User } from 'types/customers';
import reducer from './reducer';
import {
  getUser,
  getBilling,
  getInvoice,
  getClouds,
  getCompanyPlan,
  getWebsites,
  getEmails,
  setLoading,
} from './actions';

export interface CustomerDetailState {
  user: User;
  invoices: Invoices[];
  billing: Billing;
  companyPlan: CompanyPlan[];
  websites: any[];
  clouds: Clouds[];
  emails: any[];
  loading: boolean;

  setLoading(value: boolean): void;
  getBilling(id: number | string): Promise<void>;
  getUser(id: number | string): Promise<void>;
  getInvoice(id: number | string, page?: number): Promise<any>;
  getClouds(id: number | string, page?: number): Promise<any>;
  getCompanyPlan(id: number | string, page?: number): Promise<any>;
  getWebsites(id: number | string): Promise<void>;
  getEmails(id: number | string): Promise<void>;
  setBilling(tab: number | string): Promise<void>;
  setCompanyPlan(tab: number | string | any): Promise<void>;
  setUser(tab: number | string): Promise<void>;
}

// initial state
const initialState: CustomerDetailState = {
  user: null,
  invoices: null,
  billing: null,
  companyPlan: null,
  clouds: null,
  websites: null,
  emails: null,
  loading: false,

  setLoading: (): void => {},
  getBilling: async () => {},
  getUser: async () => {},
  getInvoice: async () => {},
  getClouds: async () => {},
  getCompanyPlan: async () => {},
  getWebsites: async () => {},
  getEmails: async () => {},
  setBilling: async () => {},
  setCompanyPlan: async () => {},
  setUser: async () => {},
};

// context
export const CustomerDetailContext =
  createContext<CustomerDetailState>(initialState);

// provider
export const CustomerDetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CustomerDetailContext.Provider
      value={{
        // state
        user: state.user,
        invoices: state.invoices,
        billing: state.billing,
        companyPlan: state.companyPlan,
        clouds: state.clouds,
        websites: state.websites,
        emails: state.emails,
        loading: state.loading,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getUser: async id => await getUser(dispatch, id),
        getBilling: async id => await getBilling(dispatch, id),
        getInvoice: async (id, page?) => await getInvoice(dispatch, id, page),
        getClouds: async (id, page?) => await getClouds(dispatch, id, page),
        getCompanyPlan: async (id, page?) =>
          await getCompanyPlan(dispatch, id, page),
        getWebsites: async id => await getWebsites(dispatch, id),
        getEmails: async id => await getEmails(dispatch, id),
        setBilling: async tab =>
          dispatch({
            type: 'SET_BILLING',
            payload: tab,
          }),
        setCompanyPlan: async tab =>
          dispatch({
            type: 'SET_COMPANY_PLAN',
            payload: tab,
          }),
        setUser: async tab =>
          dispatch({
            type: 'SET_USER',
            payload: tab,
          }),
      }}
    >
      {children}
    </CustomerDetailContext.Provider>
  );
};
