import styled, { CSSObject } from 'styled-components';

interface IStyledEmptyStateProps {
  img: string;
}

export const StyledEmptyState = styled.div<IStyledEmptyStateProps>`
  display: inline-flex;

  align-items: center;
  justify-content: center;

  background-image: url(${props => props.img});
  background-size: auto;
  background-repeat: no-repeat;

  height: 152px;
  width: 223px;

  & > div {
    text-align: center;

    font-size: 20px;
    color: ${props => props.theme.interface5};
  }
`;
