import api from 'services/api';

export const getUsersService = async (params?: any): Promise<any> => {
  try {
    return await api.get(`/v3/user/`, { params });
  } catch (e) {
    throw e;
  }
};

export const getUserService = async (id: number | string) => {
  try {
    return await api.get(`/v3/user/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const deleteUserService = async (id: number | string) => {
  try {
    return await api.delete(`/v3/user/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getUserBillingService = async (id: number | string) => {
  try {
    return await api.get(`/v3/billing/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const resendCodeService = async (params?) => {
  try {
    return await api.post('otp/email-device/ask-code/', params);
  } catch (e) {
    throw e;
  }
};
