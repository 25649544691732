import React from 'react';
import { Col } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';

const DashboardInvoice = () => {
  const placeholders = [];

  for (let i = 1; i <= 1; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col xl="2" key={p}>
          <div style={{ display: 'flex', gap: '23px' }}>
            <div
              style={{
                background: '#fff',
                width: 'auto',
                height: 'auto',
                padding: '0.5rem 1rem 0.5rem 1rem',
                borderRadius: '5px',
              }}
            >
              <div
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <Placeholder width={88} height={17} />
                <Placeholder width={317} height={40} />
              </div>
              <div style={{ display: 'flex', gap: '5rem', marginTop: '20px' }}>
                <Placeholder width={1110} height={16} />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Placeholder width={1110} height={16} />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Placeholder width={1110} height={16} />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Placeholder width={1110} height={16} />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Placeholder width={1110} height={16} />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Placeholder width={1110} height={16} />
              </div>

              <div style={{ marginTop: '32px' }}>
                <Placeholder width={277} height={13} />
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default DashboardInvoice;
