import { LastLocationProvider } from 'react-router-last-location';

import { AuthProvider } from './auth';
import { LayoutProvider } from './layout';
import { NotificationsProvider } from './notifications';
import { HelpBaseProvider } from './help';

const AppProvider: React.FC = ({ children }) => (
  <LastLocationProvider>
    <AuthProvider>
      <LayoutProvider>
        <NotificationsProvider>
          <HelpBaseProvider>{children}</HelpBaseProvider>
        </NotificationsProvider>
      </LayoutProvider>
    </AuthProvider>
  </LastLocationProvider>
);

export default AppProvider;
