/* eslint-disable no-useless-escape */
import { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLayout } from 'hooks';
import mixpanel from 'mixpanel-browser';
import JwtDecode from 'jwt-decode';
import md5 from 'md5';
import moment from 'moment';

import { toast } from 'react-toastify';
import api from 'services/api';
import { getUserService } from 'services/user';
import setApiToken from 'utils/setApiToken';

export interface AuthState {
  user: any;
  token: string;
  company: any;
  authLoading: boolean;

  token2FA: string;
  loginData: { email: string; password: string };
  devices: any;

  signInError: string;

  signIn: (
    email: string,
    password: string,
    params?: any,
    twoAuthData?: any,
  ) => void;
  signOut: () => void;
  getCompany: () => void;
  getUser: (id?: number) => void;
  updateUser: (field: any, value, id: number | string, me: boolean) => void;
  updateUserSuccess: (user: string) => void;
  setToken: (token: string) => void;
  updateCompanySettings: (field: string, value: string) => void;

  setLoginData: any;
}

export const AuthContext = createContext({} as AuthState);

export const AuthProvider: React.FC = ({ children }) => {
  const layout = useLayout();

  const [loginData, setLoginData] = useState({} as any);
  const [signInError, setSignInError] = useState('');
  const [devices, setDevices] = useState([]);

  const loadCloudezChat = useCallback((company, user) => {
    const _dataChat = document.querySelectorAll('[data-chat="true"]');

    if (_dataChat.length >= 0) {
      _dataChat.forEach(el => {
        el.remove();
      });
    }

    const script = document.createElement('script') as any;
    script.type = 'text/javascript';
    script.id = 'chat-cloudez';
    script.async = true;
    script.src = process.env.REACT_APP_CHAT_URL;
    script.setAttribute('data-chat', true);
    script.setAttribute('company', company.code);
    const domain = company.app_domains.find(d => !d.includes('cloudez.app'));
    script.setAttribute('company_domain', domain);
    script.setAttribute('primary_color', '#0052EF');
    script.setAttribute('username', user.full_name);
    script.setAttribute('email', user.email);
    script.setAttribute('uuid', user.uuid);
    script.setAttribute('is_partner', true);
    script.setAttribute('is_external', false);

    document.querySelector('body').appendChild(script);
  }, []);

  function handleWootric(user) {
    if (user.is_company_owner && process.env.NODE_ENV !== 'development') {
      const body = document.getElementsByTagName('body')[0];
      const scriptWootricBdy = document.createElement('script');
      scriptWootricBdy.type = 'text/javascript';
      scriptWootricBdy.setAttribute('name', 'wootricBdy');
      scriptWootricBdy.text = `
        window.wootricSettings = {
          email: '${user.email}',
          external_id: '${user.id}',
          created_at: ${user.customer_at},
          account_token: 'NPS-a7d87ce4'
        };
      `;

      body.appendChild(scriptWootricBdy);

      const { wootric, wootricLoaded } = window as any;

      if (typeof wootric === 'function' && !wootricLoaded) {
        const scriptWootricRun = document.createElement('script');

        scriptWootricRun.type = 'text/javascript';
        scriptWootricRun.setAttribute('name', 'wootricRun');
        scriptWootricRun.text = `
            window.wootricLoaded = true;
            window.wootric('run');
        `;

        body.appendChild(scriptWootricRun);
      }
    }
  }

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@cloudez-partners-v3/token');
    const user = localStorage.getItem('@cloudez-partners-v3/user');

    if (token && user) {
      setApiToken(token);

      handleWootric(user);
      return { token, user: JSON.parse(user), authLoading: false };
    }

    return {};
  });
  const [authLoading, setAuthLoading] = useState(false);

  const [company, setCompany] = useState(_state => {
    const _company = localStorage.getItem('@cloudez-partners-v3/company');

    if (_company && _company !== 'undefined') {
      const company = JSON.parse(_company);

      // if (data.user && company?.has_cloudez_chat && !company?.is_provider)
      //   loadCloudezChat(company, data.user);

      return company;
    }

    return null;
  });

  const history = useHistory();

  const handleAmpl = user => {
    if (user.is_staff) return;

    const body = document.getElementsByTagName('body')[0];

    const amplScript = document.createElement('script');
    amplScript.type = 'text/javascript';
    amplScript.setAttribute('name', 'amplScript');
    amplScript.text = `
      window.amplData = {
        email: '${user.email}',
        created_at: '${user.customer_at}',
        name: '${user.full_name}',
        phone: '${user.phone}',
        company: '${user.company_name}',
        survey: '625885f1bf18a2e07fbc88fb',
        callback: function(data){}
      }
    `;

    const amplRunScript = document.createElement('script');
    amplRunScript.type = 'text/javascript';
    amplRunScript.setAttribute('name', 'amplRunScript');
    amplRunScript.text = `
      window.AmplSurvey.run('run');
    `;

    body?.appendChild(amplScript);
    body?.appendChild(amplRunScript);
  };

  function handleCompass(user, company) {
    const body = document.getElementsByTagName('body')[0];
    const compassScript = document.createElement('script');
    compassScript.type = 'text/javascript';
    compassScript.setAttribute('name', 'conpassScript');
    compassScript.text = `
      if (window.Conpass) {
        window.Conpass.init({
          name: '${user.full_name}',
          email: '${user.email}',
          custom_fields: {
            id: ${user.id},
            company: '${user.company_slug}',
            is_customer: ${user.is_customer},
            is_staff: ${user.is_staff},
            company_owner: ${user.is_company_owner},
            has_turbo: ${
              company?.provider?.includes('partner-g3') ||
              company?.provider?.includes('partner-g4') ||
              company?.provider?.includes('dpg-custom')
            },
            is_partners_app: true,
          }
        });
      }
    `;

    body.appendChild(compassScript);
  }

  function handleHellpier() {
    const body = document.getElementsByTagName('body')[0];
    const hellpierScript = document.createElement('script');
    hellpierScript.type = 'text/javascript';
    hellpierScript.setAttribute('name', 'hellpierScript');
    hellpierScript.text = `
      window.help_company_key = '28a73e1614f346f090f70990';
      window.helppier_app_id = 'yBYuPnvwp5B593eLt';
      var script = document.createElement('script');
      script.setAttribute('id', 'helppierEmbed');
      script.setAttribute('defer', '');
      script.setAttribute(
        'src',
        "https://km.helppier.com/widget/js/start.js?help_company_key=28a73e1614f346f090f70990",
      );
      document.head.appendChild(script);
    `;

    body.appendChild(hellpierScript);
  }

  function handleMixpanel() {
    mixpanel.init('17e38c4ab634161d26641511ee93249d', {
      debug: true,
      ignore_dnt: true,
    });
    mixpanel.track('Sign up');
  }

  const getAvatar = email => {
    const hash = md5(email);
    return `https://www.gravatar.com/avatar/${hash}?&d=mp`;
  };

  const updateUser = async (field, value, id, me = true) => {
    try {
      const response = await api.patch(`/v3/user/${id}/`, {
        [field]: value,
      });

      if (me) {
        updateUserSuccess(response.data);
      }

      toast.success('Usuário atualizado com sucesso');
    } catch (e) {
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  const updateCompanySettings = async (field, value) => {
    try {
      const { data } = await api.post(`/v3/company/update_settings/`, {
        [field]: value,
      });

      setCompany(data);

      toast.success('Company atualizada com sucesso');
    } catch (e) {
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  const updateUserSuccess = user => {
    const avatar = getAvatar(user.email);

    const userPayload = {
      ...user,
      avatar,
    };

    localStorage.setItem(
      '@cloudez-partners-v3/user',
      JSON.stringify(userPayload),
    );

    setData({
      ...data,
      user: userPayload,
    });
  };

  const setToken = token => {
    setApiToken(token);

    localStorage.setItem('@cloudez-partners-v3/token', token);

    setData({
      ...data,
      token,
    });
  };

  const { cookieconsent } = window as any;

  function loadCookieConsent() {
    setTimeout(
      () =>
        cookieconsent.initialise({
          palette: {
            popup: {
              background: '#fff',
            },
            button: {
              background: '#0052EF',
              text: '#fff',
            },
          },
          theme: 'classic',
          content: {
            link: 'Ler mais',
            href: 'https://cloudez.io/br/politica-de-cookies/',
            message:
              'Este website utiliza cookies para melhorar sua experiência como usuário. ',
            dismiss: 'Estou ciente',
          },
        }),
      1500,
    );
  }

  const getUser = useCallback(
    async (id?: any) => {
      try {
        const { data: user } = await getUserService(id || data.user.id);

        setData(_state => {
          const userPayload = {
            ..._state.user,
            ...user,
          };

          localStorage.setItem(
            '@cloudez-partners-v3/user',
            JSON.stringify(userPayload),
          );

          return {
            ..._state,
            user: userPayload,
          };
        });
      } catch (e) {
        console.log(e);
      }
    },
    [data],
  );

  const getCompany = useCallback(async () => {
    try {
      if (!data.user) return;
      // loadCookieConsent();

      const { data: _data } = await api.get(`/v3/company/`, {
        params: {
          page_size: 1000,
        },
      });

      const company = _data.results.find(c => c.code === data.user.company);

      localStorage.setItem(
        '@cloudez-partners-v3/company',
        JSON.stringify(company),
      );

      setCompany(company);
    } catch (e) {
      console.log(e);
    }
  }, [data]);

  const token2FA = localStorage.getItem('@cloudez-partners-v3/2fa_token');

  const signIn = useCallback(
    async (email, password, params?: any, twoAuthData?: any) => {
      setAuthLoading(true);
      setLoginData({ email, password, company: layout.code });
      try {
        let data;

        if (email.includes('@')) {
          data = twoAuthData
            ? {
                email,
                password,
                code: twoAuthData.code,
                device_type: twoAuthData.device_type,
                trust_device: twoAuthData.trust_device,
              }
            : {
                email,
                password,
              };
        } else {
          data = twoAuthData
            ? {
                username: email,
                password,
                code: twoAuthData.code,
                device_type: twoAuthData.device_type,
                trust_device: twoAuthData.trust_device,
              }
            : {
                username: email,
                password,
              };
        }

        const headers: any = {};
        if (token2FA) headers['X-token2fa'] = token2FA;

        const {
          data: { token, user, token2fa },
        } = await api.post('/auth/company/login', data, {
          params,
          headers,
        });

        if (token2fa) {
          localStorage.setItem('@cloudez-partners-v3/2fa_token', token2fa);
        }

        setApiToken(token);

        const { data: _data } = await getUserService(user.id);

        const _user = {
          ...user,
          ..._data,
        };

        handleWootric(_user);

        // loadCookieConsent();

        const { data: companyResponse } = await api.get(`/v3/company/`, {
          params: {
            page_size: 1000,
          },
        });

        const company = companyResponse.results.find(
          c => c.code === _user.company,
        );

        // if (company?.has_cloudez_chat && !company?.is_provider)
        //   loadCloudezChat(company, _user);

        handleCompass(_user, company);
        handleAmpl(_user);

        localStorage.setItem(
          '@cloudez-partners-v3/company',
          JSON.stringify(company),
        );

        setCompany(company);

        const userPayload = {
          ..._user,
          avatar: getAvatar(_user.email),
        };

        localStorage.setItem('@cloudez-partners-v3/token', token);
        localStorage.setItem(
          '@cloudez-partners-v3/user',
          JSON.stringify(userPayload),
        );

        setAuthLoading(false);
        setData({ token, user: userPayload, authLoading: false });
        setSignInError('');
      } catch (e) {
        if (
          e.response?.data.message === 'User has 2FA enabled' ||
          e.response?.data.message === 'Verification code sent in email' ||
          e.response?.data.message === 'Verification code failed'
        ) {
          setSignInError(e.response?.data.message);
          setDevices(e.response?.data.devices);
        } else {
          toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
        }
        setAuthLoading(false);
      }

      setAuthLoading(false);
    },
    [authLoading],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@cloudez-partners-v3/token');
    localStorage.removeItem('@cloudez-partners-v3/user');
    localStorage.removeItem('@cloudez-partners-v3/company');
    localStorage.removeItem('@cloudez-partners-v3/layout');

    const conpassScript = document.getElementsByName('conpassScript')[0];
    const amplScript = document.getElementsByName('amplScript')[0];
    const amplRunScript = document.getElementsByName('amplRunScript')[0];

    if (conpassScript) conpassScript.remove();
    if (amplScript) amplScript.remove();
    if (amplRunScript) amplRunScript.remove();

    const _dataChat = document.querySelectorAll('[data-chat="true"]');

    _dataChat.forEach(el => {
      el.remove();
    });

    setApiToken(null);

    setData(null);
    setCompany(null);
    history.push('/');
  }, []);

  const refreshToken = useCallback(
    async oldToken => {
      try {
        const response = await api.post('/auth/login/refresh/', {
          token: oldToken,
        });

        const { token } = response.data;

        localStorage.setItem('@cloudez-partners-v3/token', token);
        setApiToken(token);

        setData({
          ...data,
          token,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [data],
  );

  useEffect(() => {
    const { user } = data;

    if (user) {
      getUser();
      getCompany();
    }
  }, []);

  useEffect(() => {
    let id;

    if (data) {
      const { token, user } = data;

      if (token) {
        const decoded = JwtDecode(token);

        if (decoded && decoded.exp) {
          const now = moment();
          const expiredAt = moment.unix(decoded.exp);

          const diff = moment.duration(expiredAt.diff(now)).asSeconds();

          if (diff > 10) {
            id = setTimeout(async () => {
              await refreshToken(token);
            }, (diff - 10) * 1000);
          } else {
            signOut();
          }
        }
      }

      if (user) {
        // handleCompass(user, company);
        // handleAmpl(user);
      }
    }

    return () => {
      clearTimeout(id);
    };
  }, [data, company, refreshToken, signOut]);

  return (
    <AuthContext.Provider
      value={{
        user: data?.user,
        token: data?.token,
        company,
        authLoading,
        signIn,
        signOut,
        getCompany,
        getUser,
        updateUser,
        updateUserSuccess,
        setToken,
        signInError,
        setLoginData,
        loginData,
        devices,
        token2FA,
        updateCompanySettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
