import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 20px;

  text-align: center;

  color: #64728c;
  font-size: 16px;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 16px;

  color: #545c68;
`;
