import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Label = styled.span`
  padding: 1px 4px;
  border: 1px solid transparent;
  border-radius: 5px;

  a {
    color: inherit;
  }
`;

interface IAcessoProps {
  fixed?: boolean;
  active?: boolean;
  favorite?: boolean;
  paid?: boolean;
  hover?: boolean;
  remove?: boolean;
  showAccess?: boolean;
  disable?: boolean;
  like?: number;
}

export const Acesso = styled.div<IAcessoProps>`
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  padding: 0px 8px;

  svg {
    color: ${props =>
      props.theme.darkMode ? props.theme.interface5 : props.theme.interface4};
  }

  ${props =>
    props.fixed &&
    css`
      svg {
        color: ${props => props.theme.brand_primary_color};
      }
    `}

  ${props =>
    props.active &&
    css`
      &:hover {
        svg {
          color: ${(props: any) =>
            props.favorite
              ? darken(0.1, props.theme.favorite)
              : darken(0.1, props.theme.brand_primary_color)};
        }
        ${Label} {
          border: 1px solid ${props => props.theme.brand_secondary_color};
          border-radius: 5px;

          svg {
            transition: color 0.2s ease-in-out;
            color: ${props => props.theme.brand_primary_color};
          }
        }

        ${props =>
          props.hover &&
          css`
            ${AcessoHover} {
              display: block;
            }
          `}
      }
    `}

  ${props =>
    props.favorite &&
    css`
      svg {
        color: ${(props: any) => props.fixed && props.theme.favorite};
      }
    `}

  ${props =>
    props.paid &&
    css`
      svg {
        color: ${(props: any) =>
          props.fixed ? props.theme.success : props.theme.error} !important;
      }
    `}

  ${props =>
    props.remove &&
    css`
      svg {
        color: ${(props: any) => props.fixed && props.theme.error};
      }
    `}
`;

export const AcessosList = styled.div<IAcessoProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props =>
    props.showAccess ? 'space-between' : 'flex-end'};
  width: 100%;
  height: 100%;

  ${props =>
    props.disable &&
    css`
      ${Acesso} {
        color: props.theme.interface3 !important;
      }
    `}
`;

export const Value = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const AcessoHover = styled.div`
  cursor: default;
  line-height: 1.5;
  font-size: 10px;
  padding: 6px;
  position: absolute;
  top: 54px;
  margin-left: -7px;
  display: none;
  border-radius: 5px;
  background: ${props =>
    props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  color: ${props => props.theme.interface1};
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    width: 8px;
    height: 8px;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  }
`;
