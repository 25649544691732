import { useMemo } from 'react';

import CurrencyFormat from 'react-currency-format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloud,
  faWindowMaximize,
  faEnvelope,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';

import { PlanCardItem } from '../types/planCard';

import { Badge, Private, PlanCard as Card, SharedPlanCard } from '../styles';

interface PlanCardProperties {
  p: PlanCardItem;
  setEditPlan: (p: PlanCardItem) => void;
  setEditModal: (value?: boolean) => void;
}

const PlanCard: React.FC<PlanCardProperties> = ({
  p,
  setEditModal,
  setEditPlan,
}) => {
  const description = useMemo(() => {
    if (p.description && p.description !== 'False') {
      return p.description;
    }

    if (p.cloud_size?.info && p.cloud_size.info['tip_pt-br']) {
      return p.cloud_size.info['tip_pt-br'];
    }

    return '';
  }, [p]);

  const isEmail = useMemo(() => {
    if (!p.cloud_size) return false;
    return p.cloud_size.category === 3;
  }, [p]);

  return (
    <>
      {p.category !== 1 ? (
        <SharedPlanCard
          onClick={() => {
            setEditPlan(p);
            setEditModal(true);
          }}
        >
          <Badge category={p.category}>
            <FontAwesomeIcon
              icon={
                p.category === 1
                  ? faCloud
                  : p.category === 9
                  ? faWindowMaximize
                  : faEnvelope
              }
            />
            {p.category === 9 && 'website'}
            {p.category === 10 && 'email'}
          </Badge>
          <h2 className="name">{p.name}</h2>
          <span className="recurrency">Preço</span>
          <CurrencyFormat
            className="amount"
            value={p.price_total.amount || 0}
            displayType="text"
            fixedDecimalScale
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            prefix="R$ "
          />
        </SharedPlanCard>
      ) : (
        <Card
          private={p.is_private}
          onClick={() => {
            setEditPlan(p);
            setEditModal(true);
          }}
        >
          <Badge category={p.category}>
            <FontAwesomeIcon
              icon={
                p.category === 1
                  ? faCloud
                  : p.category === 9
                  ? faWindowMaximize
                  : faEnvelope
              }
            />
            {p.category === 1 && 'cloud'}
            {p.category === 9 && 'website'}
            {p.category === 10 && 'email'}
          </Badge>
          {p.is_private && (
            <Private>
              <FontAwesomeIcon icon={faUserSecret} />
            </Private>
          )}
          <div className="top">
            <div className="plan-title">
              {p.name} <span>{p.cloud_size?.memory} RAM</span>
            </div>

            <span className="tip">{description}</span>

            {p.price_total.amount !== '' && (
              <div className="prices">
                {p.has_basic && !isEmail && (
                  <div className="price">
                    <span className="label">Basic</span>
                    <CurrencyFormat
                      value={p.price_total.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      className="amount"
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix={p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '}
                    />
                  </div>
                )}
                {p.has_turbo && !isEmail && (
                  <div className="price">
                    <span className="label">Turbo</span>
                    <CurrencyFormat
                      value={p.turbo_price_total.amount}
                      displayType="text"
                      fixedDecimalScale
                      className="amount"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix={
                        p.turbo_price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      }
                    />
                  </div>
                )}
                {isEmail && (
                  <div className="price">
                    <span className="label">Preço</span>
                    <CurrencyFormat
                      value={p.price_total.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      className="amount"
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix={p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="separator" />
          <div className="bottom">
            <ul>
              <li>DISCO</li>
              <li className="value">{p.cloud_size?.disk}</li>
              <li>PROCESSADOR</li>
              <li className="value">{p.cloud_size?.cores} CPU core</li>
            </ul>
          </div>
        </Card>
      )}
    </>
  );
};

export default PlanCard;
