import {
  Field,
  Label,
  LineInput,
  Radio,
  Select,
  TextArea,
} from 'components/Input';
import { useMemo } from 'react';

interface ISurveyFieldProps {
  other?: any;
  setOther?: (value: boolean) => void;
  twoColumn?: boolean;
  setPayload: (value: any) => void;
  payload: any;
  i: any;
}

const SurveyField: React.FC<ISurveyFieldProps> = ({
  i,
  payload,
  setPayload,
  twoColumn,
  other,
  setOther,
}) => {
  const value = useMemo(() => {
    return payload[i.question.id] ? payload[i.question.id] : '';
  }, [payload, i]);

  return (
    <Field
      className={`${twoColumn ? 'd-flex flex-wrap' : ''} ${
        other && twoColumn && payload[i.question.id] === 'Outro'
          ? 'justify-content-end align-items-start'
          : ''
      }`}
    >
      {i.question.widget === 'select' && (
        <>
          <Label>{i.question.text}</Label>
          <Select
            name={i.question.text}
            required={i.is_required}
            block="true"
            value={payload[i.question.id]}
            defaultValue=""
            onChange={e => {
              setPayload({
                ...payload,
                [i.question.id]: e.target.value,
              });
            }}
          >
            <option value="" disabled>
              Selecione uma opção
            </option>
            {i.question.choices.map(c => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </Select>
        </>
      )}

      {i.question.widget === 'textarea' && (
        <>
          <Label>{i.question.text}</Label>
          <TextArea
            height="164px"
            name={i.question.text}
            required={i.is_required}
            value={value}
            onChange={e => {
              console.log('oi');
              setPayload(_payload => ({
                ..._payload,
                [i.question.id]: e.target.value,
              }));
            }}
            block="true"
          />
        </>
      )}

      {i.question.widget === 'radio' && (
        <>
          {i.question.choices.map(c => (
            <div
              style={{
                width: twoColumn ? '50%' : '',
                marginBottom: '15px',
              }}
              key={c.id}
            >
              <Label style={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  className="mr-2"
                  value={c}
                  onChange={e => {
                    setPayload({
                      ...payload,
                      [i.question.id]: e.target.value,
                    });
                  }}
                  checked={payload[i.question.id] === c}
                />

                {c}
              </Label>
            </div>
          ))}
          {other && payload[i.question.id] === 'Outro' && (
            <div
              style={{
                width: twoColumn ? '50%' : '100%',
                marginBottom: '15px',
              }}
            >
              <LineInput
                name="other"
                onChange={e => {
                  setOther({
                    ...other,
                    [i.question.id]: e.target.value,
                  });
                }}
                value={other[i.question.id]}
                placeholder="Exemplo..."
              />
            </div>
          )}
        </>
      )}
    </Field>
  );
};

interface ISurveyRendererProps {
  survey?: any;
  other?: any;
  twoColumn?: boolean;
  question?: any;
  setOther?: (value: boolean) => void;
  setPayload: (value: any) => void;
  payload: any;
}

const SurveyRenderer: React.FC<ISurveyRendererProps> = ({
  survey,
  payload,
  setPayload,
  question,
  twoColumn,
  other,
  setOther,
}) => {
  return question ? (
    <SurveyField
      i={question}
      payload={payload}
      setPayload={setPayload}
      twoColumn={twoColumn}
      other={other}
      setOther={setOther}
    />
  ) : (
    <>
      {survey?.items?.map(i => (
        <SurveyField
          key={i.id}
          i={i}
          payload={payload}
          setPayload={setPayload}
        />
      ))}
    </>
  );
};

export default SurveyRenderer;
