import { Button } from '@cloudez/cloudez-design-system';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 40px;

  padding: 0 32px;

  gap: 22px;

  .mainText {
    font-weight: normal;
    font-size: 16px;

    color: ${props => props.theme.interface5};
  }

  table {
    width: 100%;

    border-collapse: separate;
    border-spacing: 0;

    tr {
      border: 1px solid ${props => props.theme.interface3};
    }
    td {
      border: 1px solid ${props => props.theme.interface3};
      padding: 16px;

      width: 250px;

      vertical-align: top;
    }

    tr:first-child td:first-child {
      border-top-left-radius: 10px;
      border-right: 0px;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }
    tr:first-child td:last-child {
      border-top-right-radius: 10px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }

    .absolutePlus {
      right: 9px;
      position: relative;
      height: 50px;
      top: 78px;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .optionalTr {
      text-align: center;

      td {
        border-top: 0px;
      }
    }

    .title {
      font-weight: bold;
      font-size: 16px;

      margin-bottom: 10px;

      color: ${props => props.theme.interface7};
    }

    .auxiliarText {
      font-weight: normal;
      font-size: 16px;

      color: ${props => props.theme.interface5};
    }

    .boxValue {
      display: flex;
      flex-direction: column;
    }

    .value {
      font-weight: bold;
      font-size: 28px;

      color: ${props => props.theme.successNew};
    }

    .boxRecurrence {
      display: flex;
      flex-direction: column;
    }

    .extra {
      font-weight: normal;
      font-size: 18px;

      color: ${props => props.theme.interface5};
    }
  }
`;

export const CustomButton = styled(Button)`
  width: 100%;
  height: 45px;
  margin-bottom: 20px;
  border: ${props => `1px solid ${props.theme.interface3}`};
  color: ${props => props.theme.interface5};

  &:hover {
    background-color: #ffffff;
    box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
    color: ${props => props.theme.brand_primary_color} !important;
    border: ${props =>
      `2px solid ${props.theme.brand_primary_color}`} !important;
  }
`;
