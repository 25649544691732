import styled, { css } from 'styled-components';
import { Container as SidebarContainer } from './Sidebar/styles';

interface IContainerProps {
  sideMenuCollapsed?: boolean;
  isChat: boolean;
  hasNotification?: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  background: ${props => props.theme.interface2};

  display: grid;
  gap: 0px 0px;
  grid-template-columns: ${props =>
    props.sideMenuCollapsed ? '100px 1fr ' : '210px 1fr '};
  grid-template-rows: 42px 1fr 111px;

  ${props =>
    props.isChat
      ? css`
          grid-template-areas:
            'sidebar navbar'
            'sidebar content'
            'sidebar content';
        `
      : css`
          grid-template-areas:
            'sidebar navbar'
            'sidebar content'
            'sidebar footer';
        `};

  transition: all 0.2s ease;

  ${props =>
    props.hasNotification &&
    css`
      grid-template-rows: 60px 42px 1fr 111px;

      ${(props: IContainerProps) =>
        props.isChat
          ? css`
              grid-template-areas:
                'notificationbar notificationbar'
                'sidebar navbar'
                'sidebar content'
                'sidebar content';
            `
          : css`
              grid-template-areas:
                'notificationbar notificationbar'
                'sidebar navbar'
                'sidebar content'
                'sidebar footer';
            `};

      ${SidebarContainer} {
        top: 60px;
      }
    `}

  @media (max-width: 762px) {
    ${props =>
      props.isChat
        ? css`
            grid-template-areas:
              'navbar'
              'content'
              'content';
          `
        : css`
            grid-template-areas:
              'navbar'
              'content'
              'footer';
          `};

    grid-template-columns: 100vw;

    ${props =>
      props.hasNotification &&
      css`
        grid-template-rows: 60px 42px 1fr 111px;

        ${(props: IContainerProps) =>
          props.isChat
            ? css`
                grid-template-areas:
                  'notificationbar'
                  'navbar'
                  'content'
                  'content';
              `
            : css`
                grid-template-areas:
                  'notificationbar'
                  'navbar'
                  'content'
                  'footer';
              `};

        ${SidebarContainer} {
          top: 60px;
        }
      `}
  }
`;

export const Content = styled.div<IContainerProps>`
  grid-area: content;

  padding: 30px;
  padding-top: 50px;

  width: 100%;
  min-height: calc(100vh - 153px);
  height: auto;
  max-width: 1200px;
  margin: auto;

  ${props =>
    props.isChat &&
    css`
      padding: 0;
      min-height: calc(100vh - 153px);
      max-width: 100%;
    `}
`;
