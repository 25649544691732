type Props = {
  type?: string;
  payload: any;
};

export const setPrimaryLogo = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_PRIMARYLOGO',
    payload: value,
  });
};

export const setReducedLogo = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_REDUCEDLOGO',
    payload: value,
  });
};

export const setFavicon = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_FAVICON',
    payload: value,
  });
};
