import React, { useEffect, useState, useContext, useRef } from 'react';
import queryString from 'query-string';
import { Col, Row } from 'react-bootstrap';

import history from 'services/history';

import { ListingHeader, Listing, Ordering } from 'components/Listing/styles';
import Pagination from 'components/Pagination';
import { Title, Header } from 'components/Common';
import SearchBar from 'components/SearchBar';
import { Button } from '@cloudez/cloudez-design-system';

import {
  faAngleDown,
  faAngleUp,
  faCommentAlt,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt as fasCommentAlt } from '@fortawesome/free-regular-svg-icons';

import EmptyListing from 'components/EmptyListing';

import { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FilterHover,
  Filter,
  FilterSeparator,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';
import { useAuth, useDocTitle } from 'hooks';
import CustomAnimatedList from 'components/CustomAnimatedList';
import AdvancedSearch from 'components/AdvancedSearch';
import TicketCard from './TicketCard';
import { TicketListingProvider, TicketListingContext } from './_context/state';
import TicketPlaceholder from './Placeholder';
import { CustomFilter } from './CustomFIlter';

const Tickets = () => {
  const { user, company } = useAuth();

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  useDocTitle('Suporte - Cloudez Partners');

  const { loading, tickets, count, previous, next, current, getTickets } =
    useContext(TicketListingContext);

  const theme = useContext(ThemeContext);

  const {
    page = 1,
    search: _search,
    status = 1,
    filter: _filter = 'all',
  } = queryString.parse(history.location.search);

  const [statusValue, setStatusValue] = useState(Number(status));
  const [filterValue, setFilterValue] = useState(_filter);
  const [orderValue, setOrderValue] = useState<any>();

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getTicketsCallback = (
    page,
    search,
    status = statusValue,
    filter = filterValue,
    order = orderValue,
  ) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    getTickets(
      page,
      search,
      status,
      filter,
      user,
      order,
      abortControllerRef.current,
    );

    const _query = {
      ...search,
      status,
      filter,
      page,
    };

    if (!search) delete _query.search;
    if (filter === 'all') delete _query.filter;
    if (page === 1 || page === '1') delete _query.page;
    const query = queryString.stringify(_query);
    history.replace(`/suporte?${query}`);
  };

  useEffect(() => {
    getTicketsCallback(page, search, statusValue, filterValue, orderValue);
    // eslint-disable-next-line
  }, []);

  const searchValue = searchValue => {
    getTicketsCallback(page, searchValue, statusValue, filterValue, orderValue);
  };

  const handleSetStatus = status => {
    setStatusValue(status);
    getTicketsCallback(1, search, status, filterValue, orderValue);
  };

  const handleSetFilter = filter => {
    setFilterValue(filter);
    getTicketsCallback(1, search, statusValue, filter, orderValue);
  };

  const order = column => {
    const order = {
      value: orderValue?.value === 2 ? 1 : orderValue?.value === 1 ? 0 : 2,
      column,
    };
    setOrderValue(order);
    getTicketsCallback(1, search, statusValue, filterValue, order);
  };

  return (
    <>
      <Header>
        <Title>Suporte</Title>
        <Button onClick={() => history.push('/suporte/create')}>
          <span>Novo ticket</span>
        </Button>
      </Header>

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          setValue={setSearch}
          noResults={tickets?.length === 0 && search}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Título',
              value: 'summary',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Domínio',
              value: 'domain',
            },
            {
              label: 'Recurso',
              value: 'target_type',
            },
          ]}
        />
        <FilterWrapper>
          <CustomFilter
            selected={statusValue === 1}
            onClick={() => handleSetStatus(1)}
            spacing
            type="open"
          />
          <CustomFilter
            selected={statusValue === 2}
            onClick={() => handleSetStatus(2)}
            type="closed"
          />
          <FilterSeparator />
          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={
                filterValue === 'is_pinned' ? theme.favorite : theme.interface4
              }
              onClick={() =>
                filterValue === 'is_pinned'
                  ? handleSetFilter('all')
                  : handleSetFilter('is_pinned')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      {loading ? (
        <TicketPlaceholder />
      ) : (
        <>
          {tickets?.length ? (
            <>
              <ListingHeader>
                <Row>
                  <Col xs="10" sm="10" md="4" lg="1">
                    <p>Status</p>
                  </Col>
                  <Col xs="10" sm="10" md="4" lg="4">
                    <p
                      onClick={() => {
                        order('id');
                      }}
                    >
                      Ticket
                      <Ordering
                        status={
                          orderValue?.column === 'id' ? orderValue?.value : 0
                        }
                      >
                        <FontAwesomeIcon icon={faAngleUp} />
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Ordering>
                    </p>
                  </Col>
                  <Col className="d-none d-md-flex">
                    <p
                      onClick={() => {
                        order('team');
                      }}
                    >
                      Área
                      <Ordering
                        status={
                          orderValue?.column === 'team' ? orderValue?.value : 0
                        }
                      >
                        <FontAwesomeIcon icon={faAngleUp} />
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Ordering>
                    </p>
                  </Col>
                  <Col className="d-none d-md-flex">
                    <p
                      onClick={() => {
                        order('created_at');
                      }}
                    >
                      Criado em
                      <Ordering
                        status={
                          orderValue?.column === 'created_at'
                            ? orderValue?.value
                            : 0
                        }
                      >
                        <FontAwesomeIcon icon={faAngleUp} />
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Ordering>
                    </p>{' '}
                  </Col>
                  <Col className="d-none d-md-flex">
                    <p
                      onClick={() => {
                        order('updated_at');
                      }}
                    >
                      {statusValue === 2 ? (
                        'Fechado em'
                      ) : (
                        <>
                          Atualizado em
                          <Ordering
                            status={
                              orderValue?.column === 'updated_at'
                                ? orderValue?.value
                                : 0
                            }
                          >
                            <FontAwesomeIcon icon={faAngleUp} />
                            <FontAwesomeIcon icon={faAngleDown} />
                          </Ordering>
                        </>
                      )}
                    </p>{' '}
                  </Col>
                  <Col className="d-none d-md-flex" />
                </Row>
              </ListingHeader>

              <Listing>
                <CustomAnimatedList>
                  {tickets.map(ticket => (
                    <TicketCard ticket={ticket} key={ticket.id} />
                  ))}
                </CustomAnimatedList>
              </Listing>
              <Pagination
                onChangePage={getTicketsCallback}
                count={count}
                previous={previous}
                next={next}
                current={current}
                search={search}
                status={statusValue}
                filter={filterValue}
                order={orderValue}
                ticket
              />
            </>
          ) : null}
          {!loading && tickets?.length === 0 && (
            <EmptyListing>
              <p>Nenhum ticket encontrado.</p>
              <Button onClick={() => history.push('/suporte/create')} size="lg">
                Criar novo ticket
              </Button>
            </EmptyListing>
          )}
        </>
      )}
    </>
  );
};

export default props => (
  <TicketListingProvider>
    <Tickets {...props} />
  </TicketListingProvider>
);
