/* eslint-disable prettier/prettier */
import { useContext } from 'react';
import CurrencyFormat from 'react-currency-format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faSpinner,
  faCloud,
  faWindowMaximize,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { Field, Select, Label } from 'components/Input';
import { Value } from 'components/Acessos';
import { ThemeContext } from 'styled-components';
import { Badge } from '../Plans/styles';

import { PlanCard } from './styles';

const ConfirmModal = ({
  show,
  setShow,
  p,
  lifespan,
  setLifespan,
  action,
  loading,
  user,
  basePlans,
  selectedShareResource,
}) => {
  const theme = useContext(ThemeContext);

  const basePlan = basePlans?.filter(_p => _p.id === p.cloud_size)[0];

  const lifespans = {
    1: {
      name: 'mensal',
      discount: 1,
    },
    12: {
      name: 'anual',
      discount: 0.9,
    },
  };

  const price = p.price_total.amount * lifespan;
  const finalPrice = price * lifespans[lifespan].discount;

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Confirmar operação</Title>
          <Subtitle>
            Deseja vincular este plano ao cliente? Esta operação irá criar uma
            fatura.
          </Subtitle>
        </Text>
      </Header>

      <Content>
        <Field
          style={{
            marginBottom: 30,
          }}
        >
          <Label>Nome do cliente</Label>
          <Value
            style={{
              color: theme.interface5,
              fontSize: '14px',
            }}
          >
            {user?.name || user?.full_name}
          </Value>
        </Field>
        <Field
          style={{
            marginBottom: 30,
          }}
        >
          <Label>Email do cliente</Label>
          <Value
            style={{
              color: theme.interface5,
              fontSize: '14px',
            }}
          >
            {user?.email}
          </Value>
        </Field>

        {(p.category === 9 || p.category === 10) && (
          <Field
            style={{
              marginBottom: 30,
            }}
          >
            <Label>{p.category === 9 ? 'Website' : 'Email'} selecionado</Label>
            <Value
              style={{
                color: theme.interface5,
                fontSize: '14px',
              }}
            >
              {selectedShareResource?.domain}
            </Value>
          </Field>
        )}

        <Field
          style={{
            marginBottom: 30,
          }}
        >
          <Label>Recorrência</Label>
          <Select
            height="40px"
            placeholder="Selecione uma recorrência"
            value={lifespan}
            onChange={e => setLifespan(e.target.value)}
            block="true"
            name="recorrencia"
          >
            <option value="1">Mensal</option>
            <option value="12">Anual (10% de desconto)</option>
          </Select>
        </Field>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PlanCard
            style={{
              background: theme.interface2,
            }}
          >
            <Badge category={p.category}>
              <FontAwesomeIcon
                icon={
                  p.category === 1
                    ? faCloud
                    : p.category === 9
                    ? faWindowMaximize
                    : p.category === 10
                    ? faEnvelope
                    : null
                }
              />
              {p.category === 1 && 'cloud'}
              {p.category === 9 && 'website'}
              {p.category === 10 && 'email'}
            </Badge>
            <p>{p.name}</p>
            {p.price_total.amount !== '' && (
              <>
                {lifespan !== '1' && (
                  <div>
                    <span
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      <CurrencyFormat
                        value={p.price_total.amount}
                        displayType="text"
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix={p.price_total.currency === 'BRL' ? 'R$ ' : '$ '}
                      />
                      <span
                        style={{
                          fontSize: '10px',
                        }}
                      >
                        mensal ou
                      </span>
                    </span>
                  </div>
                )}
                <CurrencyFormat
                  value={finalPrice}
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix="R$ "
                />
                <span>
                  <span>{lifespans[lifespan].name}</span>
                </span>
              </>
            )}
            <div>
              {basePlan && (
                <>
                  <span>{basePlan.memory} RAM</span>
                  <span>{basePlan.cores} CPU core</span>
                  <span>{basePlan.disk}</span>
                </>
              )}
            </div>
          </PlanCard>
        </div>
      </Content>
      <Footer>
        <Button onClick={action} icon disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ConfirmModal;
