import styled, { css } from 'styled-components';

export const Tabs = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
`;

interface ITabProps {
  theme: any;
  active: boolean;
  confirm?: boolean;
  disabled?: boolean;
}

export const Tab = styled.li<ITabProps>`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: 10px 40px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border-bottom: 3px solid transparent;
  transition: border 100ms ease-in-out;

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid
        ${(props: ITabProps) =>
          props.confirm
            ? props.theme.success
            : props.theme.brand_primary_color};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: ${(props: ITabProps) =>
        props.confirm
          ? props.theme.interface5
          : props.theme.brand_primary_color};
    `}

  ${props =>
    props.confirm &&
    css`
      svg {
        margin-right: 10px;
        color: ${(props: ITabProps) =>
          props.active ? props.theme.success : props.theme.interface5};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}
`;

interface ITabContentProps {
  noBg?: boolean;
}

export const TabContent = styled.div<ITabContentProps>`
  height: auto;
  width: 100%;
  background: ${props => (props.noBg ? 'none' : props.theme.interface1)};
  border-radius: 5px;
  padding: 18px 12px;
`;
