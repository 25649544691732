import { useMemo } from 'react';
import { useAuth, useLayout } from 'hooks';
import { Layouts } from 'pages';
import { Route, Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

interface RouteWrapperProps {
  isPrivate?: boolean;
  defaultLayout?: boolean;
  roles?: string[];
  component: (props: any) => JSX.Element;
  exact?: boolean;
  path?: string | string[];
  disabled?: boolean;
  location?: any;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({
  component: Component,
  isPrivate,
  defaultLayout,
  roles,
  disabled,
  ...rest
}) => {
  const { user, company } = useAuth();
  const { partnerSurvey, answerLater } = useLayout();
  const isAuthenticated = useMemo(() => !!user, [user]);

  const lastLocation = useLastLocation();
  if (!isAuthenticated && isPrivate) {
    /*
      Redirect to '/' if user is unauthenticated and route is private
    */

    return <Redirect to="/" />;
  }

  if (
    !rest.path ||
    (roles &&
      !roles.some(role => user.roles.includes(role)) &&
      !user.roles.includes('company_owner_role') &&
      !user.roles.includes('god_role') &&
      !user.roles.some(role => role.includes('admin_')))
  )
    return <Redirect to="/404" />;
  /*
    Special routes logic
  */

  if (rest.location.pathname.includes('tickets')) {
    return (
      <Redirect to={rest.location.pathname.replace('tickets', 'suporte')} />
    );
  }

  if (disabled) {
    return <Redirect to="/404" />;
  }

  const isActivated =
    user?.is_staff || (!company?.is_blocked && company?.status === 2);

  if (isAuthenticated) {
    // if (
    //   partnerSurvey &&
    //   (!partnerSurvey.answered ||
    //     partnerSurvey?.items?.some(item => !item.answered)) &&
    //   rest.path !== '/survey' &&
    //   !answerLater &&
    //   isActivated
    // ) {
    //   return <Redirect to="/survey" />;
    // }

    if (!company && rest.path !== '/onboard') {
      return <Redirect to="/onboard" />;
    }

    if (company && rest.path === '/onboard') {
      return <Redirect to="/dashboard" />;
    }

    if (
      !isActivated &&
      !rest.path.includes('invoices') &&
      !rest.path.includes('company') &&
      !rest.path.includes('onboard')
    ) {
      return <Redirect to="/invoices" />;
    }
  }

  if (isAuthenticated && !isPrivate && !defaultLayout) {
    if (lastLocation && lastLocation?.pathname !== '/')
      return <Redirect to={lastLocation} />;

    return <Redirect to="/dashboard" />;
  }

  const Layout =
    isAuthenticated === isPrivate || defaultLayout
      ? Layouts.DefaultLayout
      : Layouts.AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
