import styled, { css } from 'styled-components';

interface IModalWrapperProps {
  isOpen?: boolean;
  sideMenuCollapsed?: any;
}

export const ModalWrapper = styled.div<IModalWrapperProps>`
  cursor: default;
  outline: 0;

  /* ${props =>
    props.isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `} */

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1050;
  display: flex;
  justify-content: center;

  visibility: hidden;
  opacity: 0;

  @keyframes Opacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes Opacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-moz-keyframes Opacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-o-keyframes Opacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.is_open {
    visibility: visible;
    opacity: 1;

    animation: 0.2s ease-in Opacity;
    -webkit-animation: 0.2s ease-in Opacity;
    -moz-animation: 0.2s ease-in Opacity;
    -o-animation: 0.2s ease-in Opacity;
  }
`;

interface IModalContentProps {
  width?: string;
}

export const ModalContent = styled.div<IModalContentProps>`
  /* position: relative; */
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  width: ${props => props.width || '435px'};
  max-height: auto;
  overflow: visible;
  z-index: 1100;
  margin: auto;

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);

  a {
    color: ${props => props.theme.brand_primary_color};
  }

  @media (max-width: 436px) {
    width: 80%;
    left: calc(50vw - 40%);
  }
`;
