import history from 'services/history';
import { getCustomersService, updateCustomerService } from 'services/customers';

type Props = {
  type?: string;
  payload: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value: boolean,
): void => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getCustomers = async (
  dispatch: ({ type, payload }: Props) => void,
  page: string | number,
  search: { page: number },
  ctrl,
): Promise<void> => {
  setLoading(dispatch, true);

  try {
    const { data } = await getCustomersService({ ...search, page }, ctrl);

    dispatch({
      type: 'GET_CUSTOMERS',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);

    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const updateCustomer = async (
  dispatch: ({ type, payload }: Props) => void,
  field: string,
  value: boolean,
  id: number | string,
): Promise<void> => {
  try {
    const { data } = await updateCustomerService(id, {
      [field]: value,
    });

    dispatch({
      type: 'UPDATE_CUSTOMER',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_CUSTOMER_ERROR',
      payload: e?.response?.data,
    });
  }
};
