import { toast } from 'react-toastify';

export default (e: any) => {
  let data;

  if (e?.response?.data) {
    data = e?.response?.data;
  } else {
    data = e;
  }

  if (data?.zipcode) {
    const message = data?.zipcode[0];

    if (message === 'não é válido') {
      toast.error('Erro ao buscar o CEP');
    } else {
      toast.error(message);
    }
    return;
  }

  if (data?.document_id) {
    const message = data?.document_id[0];

    if (message === 'invalid cpf/cnpj') {
      toast.error('Erro ao validar o CPF/CNPJ');
    } else {
      toast.error(message);
    }
    return;
  }

  if (data?.non_field_errors) {
    const message = data?.non_field_errors[0];

    if (message === 'Transação negada') {
      toast.error('Cartão negado');
    } else {
      toast.error(message);
    }
    return;
  }

  if (e?.errors) {
    data = e.errors;

    for (const item in data) {
      toast.error(`${item} é inválido`);
      return;
    }

    if (data?.messages) {
      data?.messages.forEach(element =>
        toast.error(element, {
          autoClose: 10000,
        }),
      );

      return;
    }
  }
  if (data) {
    const message =
      data[Object.keys(data)[0]]?.[0]?.length > 1
        ? data[Object.keys(data)[0]][0]
        : data[Object.keys(data)[0]];

    toast.error(message);
  }
};
