import api from 'services/api';

export const getEmailsService = async params => {
  try {
    return await api.get('/v3/email/', { params });
  } catch (e) {
    throw e;
  }
};

export const createEmailBulkService = async data => {
  try {
    return await api.post(`v3/email/account/bulk_create/`, data);
  } catch (e) {
    throw e;
  }
};

export const createEmailService = async data => {
  try {
    return await api.post(`/v3/email/`, data);
  } catch (e) {
    throw e;
  }
};
