import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 25px;
  width: 100%;
  height: auto;
`;

export const Color = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid ${props => props.theme.interface6};

  background: ${props => props.color};
  cursor: pointer;
`;

export const BlockPickerWrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 40px;
  left: -55.5px;
`;
