import React, { useContext, useEffect, useState } from 'react';

import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';

import queryString from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';

import { getWebsiteTypesService } from 'services/website';
import { getMigrationsService } from 'services/friday';
import history from 'services/history';

import {
  Pagination,
  Button,
  AdvancedSearch,
  createPagination,
} from '@cloudez/cloudez-design-system';

import { useAuth, useDocTitle, useLayout } from 'hooks';

import { FilterWrapper } from 'components/SearchBar/styles';
import { ListingHeader, Ordering } from 'components/Listing/styles';
import { Title, Header } from 'components/Common';
import SearchBar from 'components/SearchBar';
import emptyListMigration from 'assets/img/emptyListingMigration.png';

import MigrationPlaceholder from './Placeholder';
import MigrationCard from './MigrationCard';
import MigrationFilters from './Filters';
import { StyledEmpty } from './styles';
import {
  MigrationListingContext,
  MigrationListingProvider,
} from './_context/state';

const MigrationListingNew = (): JSX.Element => {
  const theme = useLayout();

  const { user } = useAuth();

  useDocTitle('Migrações - Cloudez Partners');

  const { loading, migrations, count, previous, next, current, getMigrations } =
    useContext(MigrationListingContext);

  const [showFilters, setShowFilters] = useState(false);
  const [orderValue, setOrderValue] = useState<any>();
  const [filters, setFilters] = useState<any>({});
  const [types, setTypes] = useState<any>([]);

  const {
    page = 1,
    search: _search = '',
    filter = 'all',
  } = queryString.parse(history.location.search);

  const [search, setSearch] = useState<any>(
    queryString.parse(history.location.search),
  );

  const searchValue = searchValue => {
    getMigrations(1, searchValue, filters, orderValue);
  };

  const order = column => {
    const order = {
      value: orderValue?.value === 2 ? 1 : orderValue?.value === 1 ? 0 : 2,
      column,
    };
    setOrderValue(order);
    getMigrations(1, search, filter, order);
  };

  useEffect(() => {
    async function getWebsiteTypes() {
      const { data } = await getWebsiteTypesService({
        company: user.company,
      });

      setTypes(data);
    }

    getWebsiteTypes();
  }, []);

  useEffect(() => {
    getMigrations(page, search, filter, orderValue);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header>
        <Title>Migrações</Title>
        <Button onClick={() => history.push('/migrations/create')}>
          <span>Nova migração</span>
        </Button>
      </Header>

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          noResults={false}
          setValue={setSearch}
          fields={[
            {
              label: 'Domínio da aplicação',
              value: 'domain',
            },
          ]}
        />
        <FilterWrapper>
          <FontAwesomeIcon
            onClick={() => setShowFilters(true)}
            style={{ cursor: 'pointer' }}
            color={theme.interface5}
            icon={faFilter}
          />
        </FilterWrapper>
        <MigrationFilters
          types={types?.results}
          show={showFilters}
          setShow={setShowFilters}
          orderValue={orderValue}
          getMigrations={getMigrations}
          page={page}
          search={search}
          filters={filters}
          setFilters={setFilters}
        />
      </SearchBar>

      {loading ? (
        <MigrationPlaceholder />
      ) : (
        <>
          {migrations?.length ? (
            <>
              <ListingHeader>
                <Row>
                  <Col
                    style={{ paddingLeft: '70px' }}
                    xs="8"
                    sm="8"
                    md="4"
                    lg="4"
                    xl="3"
                  >
                    <p
                      onClick={() => {
                        order('domain');
                      }}
                    >
                      Domínio
                      <Ordering
                        status={
                          orderValue?.column === 'domain'
                            ? orderValue?.value
                            : 0
                        }
                      >
                        <FontAwesomeIcon icon={faAngleUp} />
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Ordering>
                    </p>
                  </Col>
                  <Col
                    xl="1"
                    lg="3"
                    md="3"
                    className="d-none d-sm-none d-md-flex"
                  >
                    Progresso
                  </Col>
                  <Col xl="2" lg="4" md="4" className="d-none d-xl-flex">
                    Etapa Atual
                  </Col>
                  <Col className="d-none d-xl-flex">
                    <p
                      onClick={() => {
                        order('created');
                      }}
                    >
                      Criado
                    </p>
                    <Ordering
                      status={
                        orderValue?.column === 'created' ? orderValue?.value : 0
                      }
                    >
                      <FontAwesomeIcon icon={faAngleUp} />
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Ordering>
                  </Col>
                  <Col className="d-none d-xl-flex">
                    <p
                      onClick={() => {
                        order('updated');
                      }}
                    >
                      Atualizado
                    </p>
                    <Ordering
                      status={
                        orderValue?.column === 'updated' ? orderValue?.value : 0
                      }
                    >
                      <FontAwesomeIcon icon={faAngleUp} />
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Ordering>
                  </Col>
                  <Col
                    style={{ marginLeft: '20px' }}
                    className="d-none d-sm-flex d-md-flex"
                  >
                    <p
                      onClick={() => {
                        order('status');
                      }}
                    >
                      Status
                    </p>
                    <Ordering
                      status={
                        orderValue?.column === 'status' ? orderValue?.value : 0
                      }
                    >
                      <FontAwesomeIcon icon={faAngleUp} />
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Ordering>
                  </Col>
                  <Col className="d-none d-xl-flex">
                    <p>AÇÕES</p>
                  </Col>
                </Row>
              </ListingHeader>

              <AnimatedList initialAnimationDuration={800} animation="grow">
                {migrations?.map(migration => (
                  <MigrationCard migration={migration} key={migration.id} />
                ))}
              </AnimatedList>

              <Pagination
                onChangePage={getMigrations}
                count={count}
                pageSize={10}
                previous={previous}
                next={next}
                current={current}
                search={search}
                filter={filters}
              />
            </>
          ) : null}
          {!loading && migrations?.length === 0 && (
            <StyledEmpty>
              <div className="text">
                {migrations?.length === 0 && search?.search?.length > 0 ? (
                  <span>
                    Não encontramos nenhuma migração, faça uma nova pesquisa.
                  </span>
                ) : (
                  <>
                    <span>Você ainda não possui nenhuma migração :(</span>
                    <u onClick={() => history.push('/migrations/create')}>
                      Faça já sua primeira migração
                    </u>
                  </>
                )}
              </div>

              <img
                width="545px"
                height="416px"
                src={emptyListMigration}
                alt="Listagem de migração vazia"
              />
            </StyledEmpty>
          )}
        </>
      )}
    </>
  );
};

export default props => (
  <MigrationListingProvider>
    <MigrationListingNew {...props} />
  </MigrationListingProvider>
);
