import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Footer,
  Header,
  Icon,
  Subtitle,
  Text,
  Title,
} from 'components/ListingModal/styles';
import Modal from 'components/Modal';
import { Content } from './styles';

const FinishModal = ({ show, setShow, item }) => {
  return (
    <>
      <Modal width="560px" show={show} setShow={setShow}>
        <Header>
          <Icon>
            <FontAwesomeIcon size="lg" icon={item.fa} />
          </Icon>
          <Text>
            <Title>{item.title}</Title>
            <Subtitle>{item.subtitle}</Subtitle>
          </Text>
        </Header>
        <Content>
          <span className="successText">
            <b>Tudo pronto!</b> Em até 24 horas um de nossos consultores entrará
            em contato. Qualquer dúvida sobre a sua solicitação envie um e-mail
            para <span>acceleration@cloudez.io</span>.
          </span>
        </Content>
        <Footer>
          <Button icon onClick={() => setShow(false)}>
            Fechar
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export default FinishModal;
