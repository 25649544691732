import history from 'services/history';
import {
  getPlansService,
  updatePlanService,
  getBasePlansService,
  createPlanService,
  deletePlanService,
} from 'services/plans';
import { toast } from 'react-toastify';

type Props = {
  type?: string;
  payload: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getPlans = async (
  dispatch: ({ type, payload }: Props) => void,
  page: string | number,
  search,
) => {
  setLoading(dispatch, true);

  try {
    const { data } = await getPlansService({ page, search, page_size: 11 });

    dispatch({
      type: 'GET_PLANS',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    setLoading(dispatch, false);

    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const getBasePlans = async (
  dispatch: ({ type, payload }: Props) => void,
) => {
  setLoading(dispatch, true);

  try {
    const { data } = await getBasePlansService();

    dispatch({
      type: 'GET_BASE_PLANS',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    setLoading(dispatch, false);
  }
};

export const updatePlan = async (
  dispatch: ({ type, payload }: Props) => void,
  field: string,
  value,
  id: number | string,
  plan,
) => {
  try {
    const { data } = await updatePlanService(
      id,
      plan || {
        [field]: value,
      },
    );

    dispatch({
      type: 'UPDATE_PLAN',
      payload: data,
    });
    toast.success('Plano atualizado com sucesso.');
  } catch (e) {
    dispatch({
      type: 'UPDATE_PLAN_ERROR',
      payload: e?.response?.data,
    });
    throw e;
  }
};

export const createPlan = async (
  dispatch: ({ type, payload }: Props) => void,
  payload,
) => {
  try {
    const { data } = await createPlanService(payload);

    dispatch({
      type: 'CREATE_PLAN',
      payload: data,
    });
    toast.success('Plano criado com sucesso.');
  } catch (e) {
    toast.error(e.response.data[Object.keys(e.response.data)[0]][0]);
    dispatch({
      type: 'CREATE_PLAN_ERROR',
      payload: e?.response?.data,
    });
  }
};

export const deletePlan = async (
  dispatch: ({ type, payload }: Props) => void,
  id: string | number,
) => {
  try {
    await deletePlanService(id);

    dispatch({
      type: 'DELETE_PLAN',
      payload: id,
    });
    toast.success('Plano removido com sucesso.');
  } catch (e) {
    toast.error(e.response.data[Object.keys(e.response.data)[0]][0]);
    dispatch({
      type: 'DELETE_PLAN_ERROR',
      payload: e?.response?.data,
    });
  }
};
