import React, { useState, useContext } from 'react';
import { Form } from 'components/Input';

import * as Yup from 'yup';
import { Title, Header, Breadcrumbs, Breadcrumb } from 'components/Common';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'hooks';
import {
  MigrationCreateContext,
  MigrationCreateProvider,
} from '../_context/state';
import Dest from './Dest';
import Origin from './Origin';
import { Wrapper } from './styles';

const validateSchema = Yup.object().shape({
  domain: Yup.string().required('Por favor, insira um domínio.'),
});

const schemaInternal = Yup.object().shape({
  resource: Yup.string().required('Por favor, selecione um cloud.'),
  domain: Yup.string().required('Por favor, insira um domínio.'),
  auto_point_dns: Yup.string(),
  zoneapp_src: Yup.string().required(
    'Por favor, selecione um email de origem.',
  ),
});

const schemaExternal = Yup.object().shape({
  domain: Yup.string().required('Por favor, insira um domínio.'),
  host: Yup.string().required('Por favor, insira um host.'),
  port: Yup.number()
    .required('Por favor, insira uma porta válida.')
    .typeError('Por favor, insira uma porta válida.'),
  accounts: Yup.array().of(
    Yup.object().shape({
      username: Yup.string()
        .email('Por favor, insira um email válido.')
        .required('Por favor, insira um email.'),
      password: Yup.string().required('Por favor, insira uma senha.'),
    }),
  ),
  resource: Yup.string().required('Por favor, selecione um email de destino.'),
});

interface IselectedResourceData {
  type: string;
  id: number;
}

interface IselectedNodeappData {
  is_owner: boolean;
  id: number;
}

const MigrationCreateEmail = () => {
  const { user } = useAuth();

  const { createMigration, createMigrationErrorAction } = useContext(
    MigrationCreateContext,
  );

  const [vldLoading, setVldLoading] = useState(false);
  const [vldPayload, setVldPayload] = useState(null);
  const [checked, setChecked] = useState(null);
  const [selectedResource, setSelectedResource] = useState(
    {} as IselectedResourceData,
  );
  const [port] = useState(993);
  const [host, setHost] = useState('');
  const [domain, setDomain] = useState('');
  const [autoPoint, setAutoPoint] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [selectedZoneapp, setSelectedZoneapp] = useState(
    {} as IselectedNodeappData,
  );

  const submit = async data => {
    if (!vldPayload.zoneapps || isExternal) {
      const payload = {
        ...data,
        type: 0,
        migType: 2,
        src_host: data.host,
        src_port: port,
        [selectedResource.type]: selectedResource.id,
      };

      delete payload.resource;

      await createMigration(payload);
    } else {
      const payload = {
        ...data,
        [selectedResource.type]: selectedResource.id,
        zoneapp_src: selectedZoneapp.id,
        auto_point_dns: autoPoint,
        type: 1,
        migType: 2,
      };

      delete payload.resource;

      await createMigration(payload);
    }
  };

  const checkIsHosted = async ({ host, domain }) => {
    createMigrationErrorAction(null);
    setVldLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_VLD_URL}email`,
        {
          domain,
          host,
          user_id: user.id,
        },
        {
          headers: {
            Authorization: 'Bearer api_token_validator',
          },
        },
      );

      setVldPayload(data);
      setChecked(true);
      setVldLoading(false);
    } catch (error) {
      setVldLoading(false);
      createMigrationErrorAction(
        error?.response?.data?.errors || error?.response?.data,
      );
    }
  };

  return (
    <>
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/migrations">Migração</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/migrations/create">Nova migração</Link>
            </Breadcrumb>
          </Breadcrumbs>
          <FontAwesomeIcon
            icon={faExchangeAlt}
            style={{ marginRight: '10px' }}
          />
          Nova migração de email
        </Title>
      </Header>

      <Form
        schema={
          checked
            ? !vldPayload.zoneapps || isExternal
              ? schemaExternal
              : schemaInternal
            : validateSchema
        }
        onSubmit={checked ? submit : checkIsHosted}
      >
        <Wrapper>
          <Origin
            vldLoading={vldLoading}
            host={host}
            setHost={setHost}
            domain={domain}
            setDomain={setDomain}
            checked={checked}
            vldPayload={vldPayload}
            setAutoPoint={setAutoPoint}
            autoPoint={autoPoint}
            isExternal={isExternal}
            setIsExternal={setIsExternal}
            selectedZoneapp={selectedZoneapp}
            setSelectedZoneapp={setSelectedZoneapp}
          />
          <Dest
            disabled={!checked}
            setSelectedResource={setSelectedResource}
            selectedResource={selectedResource}
            // vldPayload={vldPayload}
          />
        </Wrapper>
      </Form>
    </>
  );
};

export default props => (
  <MigrationCreateProvider>
    <MigrationCreateEmail {...props} />
  </MigrationCreateProvider>
);
