export const banks = [
  {
    value: '0',
    label: 'Selecione uma opção',
  },
  {
    value: '1',
    label: 'Itaú - 341',
  },
  {
    value: '2',
    label: 'Bradesco - 237',
  },
  {
    value: '3',
    label: 'Caixa Econômica - 104',
  },
  {
    value: '4',
    label: 'Banco do Brasil - 001',
  },
  {
    value: '5',
    label: 'Santander - 033',
  },
  {
    value: '6',
    label: 'Banrisul - 041',
  },
  {
    value: '7',
    label: 'Sicredi - 748',
  },
  {
    value: '8',
    label: 'Sicoob - 756',
  },
  {
    value: '9',
    label: 'Inter - 077',
  },
  {
    value: '10',
    label: 'BRB - 070',
  },
  {
    value: '11',
    label: 'Nubank - 260',
  },
  {
    value: '12',
    label: 'Neon/Votorantim - 655',
  },
  {
    value: '13',
    label: 'Juno - 383',
  },
  {
    value: '14',
    label: 'Cora - 403',
  },
  {
    value: '15',
    label: 'PagSeguro - 290',
  },
  {
    value: '16',
    label: 'BS2 - 218',
  },
  {
    value: '17',
    label: 'Banco C6 - 336',
  },
];

export const masks = [
  {
    ag: '9999',
    account: '99999-9',
  },
  {
    ag: '9999-9',
    account: '9999999-9',
  },
  {
    ag: '9999',
    account: '99999999999-9',
  },
  {
    ag: '9999-9',
    account: '99999999-9',
  },
  {
    ag: '9999',
    account: '99999999-9',
  },
  {
    ag: '9999',
    account: '999999999-9',
  },
  {
    ag: '9999',
    account: '999999',
  },
  {
    ag: '9999',
    account: '999999999-9',
  },
  {
    ag: '9999',
    account: '999999999-9',
  },
  {
    ag: '9999',
    account: '999999999-9',
  },
  {
    ag: '9999',
    account: '9999999999-9',
  },
  {
    ag: '9999',
    account: '9999999-9',
  },
  {
    ag: '9999',
    account: '9999999999-9',
  },
  {
    ag: '9999',
    account: '9999999-9',
  },
  {
    ag: '9999',
    account: '99999999-9',
  },
  {
    ag: '9999',
    account: '999999-D',
  },
  {
    ag: '9999',
    account: '9999999-D',
  },
];
