import React, { useEffect } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { getWebsiteTypes } from 'services/customers';

import ApplicationIcon from 'components/ApplicationIcon';

import {
  faPlusSquare,
  faMinusSquare,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'components/Loading';
import { useAuth } from 'hooks';
import { Wrapper, SeeMoreButton, Type } from './styles';

const WebsiteTypes = ({ setSelectedNodeappType, selectedNodeappType }) => {
  const [qty, setQty] = React.useState(11);
  const [types, setTypes] = React.useState([]);
  const [toggleShowMore, setToggleShowMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const getTypes = async () => {
      try {
        setLoading(true);
        const { data } = await getWebsiteTypes({ company: user.company });
        setTypes(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (!types.length) getTypes();

    // eslint-disable-next-line
  }, [types]);

  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {types &&
              types.map((t, i) => {
                if (i <= qty) {
                  return (
                    <Col
                      key={t.id}
                      xl="2"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Type
                        data-testid="typeCard"
                        type={t}
                        onClick={() => setSelectedNodeappType(t)}
                        selected={
                          selectedNodeappType && selectedNodeappType.id === t.id
                        }
                      >
                        <ApplicationIcon
                          width="40px"
                          height="40px"
                          icon={`${t.slug}`}
                        />
                        <span>{t.name}</span>
                        <p>{t.description}</p>
                      </Type>
                    </Col>
                  );
                }
                return null;
              })}
          </Row>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '25px',
            }}
          >
            <SeeMoreButton
              data-testid="seeMoreButton"
              onClick={() => {
                toggleShowMore ? setQty(types.length) : setQty(11);
                setToggleShowMore(!toggleShowMore);
              }}
            >
              <FontAwesomeIcon
                icon={toggleShowMore ? faPlusSquare : faMinusSquare}
              />
              <span>Mais aplicações</span>
            </SeeMoreButton>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default withTheme(WebsiteTypes as any);
