import styled from 'styled-components';

interface IButtonProps {
  sideMenuCollapsed: boolean;
}

export const Button = styled.button<IButtonProps>`
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 15px;
  width: unset !important;
  padding: unset !important;

  transition: all 0.2s ease-in-out;

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 22px;
  }

  @media (max-width: 768px) {
    transform: ${({ sideMenuCollapsed }) =>
      !sideMenuCollapsed && 'translateX(210px)'};
  }
`;
