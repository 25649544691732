import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '@cloudez/cloudez-design-system';
import * as Styles from '../styles';

export default function DownloadCard({ filename, icon, title, desc }) {
  return (
    <Styles.DownloadCard>
      <div>
        <FontAwesomeIcon icon={icon} size="3x" />
        <span>{title}</span>
      </div>

      <div>
        <p>{desc}</p>
      </div>

      <div>
        <p className="extension">
          Tipo de arquivo: <b>{`.${filename.split('.')[1]}`}</b>
        </p>

        <Button>
          <a
            // download={filename}
            href={`${process.env.PUBLIC_URL}/docs/${filename}`}
          >
            Download
          </a>
        </Button>
      </div>
    </Styles.DownloadCard>
  );
}
