import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Field, Label } from 'components/Input';
import { Button } from '@cloudez/cloudez-design-system';
import history from 'services/history';
import CurrencyFormat from 'react-currency-format';
import { Wrapper, PlanCard } from './styles';

const InvoiceSent = ({ selectedPlan, user, companyBilling, invoice }) => {
  return (
    <Wrapper>
      <h2>Invoice enviada</h2>
      <Row
        style={{
          marginLeft: 0,
        }}
      >
        {/* <Col xl="3">
          <PlanCard>
            <p>{selectedPlan && selectedPlan.name}</p>
            {selectedPlan && selectedPlan.price_total.amount !== '' && (
              <>
                <CurrencyFormat
                  style={{
                    fontSize: '30px',
                  }}
                  value={selectedPlan?.price_total?.amount}
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={
                    selectedPlan?.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                  }
                />
                <span>
                  <span>mensal</span>
                </span>
              </>
            )}
            <div>
              <span />
              <span />
              <span />
            </div>
          </PlanCard>
        </Col> */}
        <Col
          xl="3"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: '20px 0px',
          }}
        >
          <Field>
            <Label>Resposável técnico</Label>
            <p>{user && user.email}</p>
          </Field>
          <Field>
            <Label>Responsável financeiro</Label>
            <p>{companyBilling && companyBilling.email}</p>
          </Field>
          <Button outline onClick={() => history.push(`/invoices/${invoice}`)}>
            Abrir invoice
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default InvoiceSent;
