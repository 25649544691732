import { Button } from '@cloudez/cloudez-design-system';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divisor = styled.div`
  border-bottom: 1px solid ${props => props.theme.interface3};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${Button} {
    margin-left: 20px;
  }
`;

export const LoginLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: ${props => props.theme.interface5};
`;

export const LoadingIframeBox = styled.div`
  overflow: none;

  width: 100%;
  height: 520px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 40px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

interface ContentProps {
  shadowned?: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;

  width: 100%;

  padding: 24px;

  ${({ shadowned }) =>
    shadowned &&
    css`
      box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
    `}

  background: ${props => props.theme.interface1};
  border-radius: 5px;

  p {
    font-weight: 700;
    font-size: 14px;
    color: ${props => props.theme.interface7};

    margin-bottom: 16px;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .infos {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;

    p {
      margin-bottom: 0;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${props => props.theme.interface5};
    }

    .bold {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
    }

    .value {
      font-weight: 700;
      font-size: 18px;
      color: ${props => props.theme.successNew};
    }

    .bigger {
      font-size: 32px;
      margin-left: auto;
    }
  }
`;

export const SaveCard = styled.span`
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;

  color: ${props => props.theme.interface5};
`;

interface SelectionProps {
  selected: boolean;
}

export const Selection = styled.div<SelectionProps>`
  display: flex;
  align-items: center;

  span {
    color: ${props => props.theme.interface5};
  }

  div.radio {
    cursor: pointer;

    width: 15px;
    height: 15px;

    border: 2px solid ${props => props.theme.interface4};
    border-radius: 50%;
    background: transparent;

    margin-right: 10px;

    ${props =>
      props.selected &&
      css`
        border: 2px solid ${props => props.theme.brand_primary_color};

        :after {
          content: '';
          background: ${props => props.theme.brand_primary_color};
          border-radius: 50%;
          width: 9px;
          height: 9px;
          position: relative;
          display: inline-block;
          top: -5.9px;
          left: 1px;
        }
      `}
  }
`;
