import styled from 'styled-components';

interface ILogoWrapperProps {
  sideMenuCollapsed: boolean;
}

export const LogoWrapper = styled.div<ILogoWrapperProps>`
  width: ${props => (props.sideMenuCollapsed ? '100px' : '210px')};
  padding: 24px 12px 10px 12px;

  cursor: pointer;
  align-self: flex-start;
  display: flex;
  justify-content: center;
`;

export const LogoImage = styled.img`
  height: 60px;
  width: 100%;
`;
