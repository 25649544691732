import api from 'services/api';
import fridayApi from 'services/friday';

export default token => {
  if (token) {
    api.defaults.headers.common.Authorization = `jwt ${token}`;
    fridayApi.defaults.headers.common.Authorization =
      process.env.REACT_APP_FRIDAY_URL ===
        'https://dev.friday.cloudez.me/api' ||
      process.env.NODE_ENV === 'development'
        ? `Token ${process.env.REACT_APP_FRIDAY_TOKEN}`
        : `jwt ${token}`;
  } else {
    delete api.defaults.headers.common.Authorization;
    delete fridayApi.defaults.headers.common.Authorization;
  }
};
