import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faBars,
  faWindowMaximize,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import {
  ApplicationValue,
  ApplicationName,
  CloudValue,
} from 'components/Listing/styles';
import ApplicationIcon from 'components/ApplicationIcon';
import configr from 'assets/img/cloudez.png';
import c from 'assets/img/cloudez_mini.png';
import {
  Title,
  Wrapper,
  Navbar,
  Sidebar,
  MenuList,
  ListItem,
  Content,
  LittleButton as Button,
  ListingHeader,
  Card,
} from './styles';

const Preview = ({ primary, secondary, logo1, logo2 }) => {
  const cards = [...new Array(8)];

  const [collapseMenu, setCollapseMenu] = useState(false);

  return (
    <Wrapper>
      <Navbar collapseMenu={collapseMenu}>
        <div onClick={() => setCollapseMenu(!collapseMenu)}>
          <FontAwesomeIcon icon={faBars} color={primary} />
        </div>
      </Navbar>
      <Sidebar primary={primary} collapseMenu={collapseMenu}>
        <img
          alt="logo"
          src={
            (logo1 &&
              logo2 &&
              (collapseMenu
                ? typeof logo2 === 'string'
                  ? logo2
                  : URL.createObjectURL(logo2)
                : typeof logo1 === 'string'
                ? logo1
                : URL.createObjectURL(logo1))) ||
            (collapseMenu ? c : configr)
          }
        />
        <MenuList>
          <ListItem isActive secondary={secondary} collapseMenu={collapseMenu}>
            <FontAwesomeIcon icon={faEnvelope} />
            <span>Email</span>
          </ListItem>
          <ListItem secondary={secondary} collapseMenu={collapseMenu}>
            <FontAwesomeIcon icon={faWindowMaximize} />
            <span>Websites</span>
          </ListItem>
        </MenuList>
      </Sidebar>

      <Content collapseMenu={collapseMenu}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Title>Emails</Title>
          <Button primary={primary} height="20px" width="70px">
            Novo Email
          </Button>
        </div>
        <ListingHeader>
          <Row
            style={{
              width: '100%',
            }}
          >
            <Col xs sm md="4" lg="5" xl="5">
              <p>Tipo</p>
            </Col>

            <Col className="d-none d-xl-flex">
              <p>Cloud</p>
            </Col>

            <Col className="d-none d-sm-none d-md-flex">
              <p>Disco</p>
            </Col>

            <Col className="d-none d-xl-flex">
              <p>Contas</p>
            </Col>

            <Col xl="2" className="d-none d-xl-flex" />
          </Row>
        </ListingHeader>

        {cards.map(p => (
          <Card key={p} primary={primary}>
            <Row
              style={{
                width: '100%',
              }}
            >
              <Col xs sm md="4" lg="5" xl="5">
                <ApplicationValue
                  style={{
                    marginLeft: '0',
                  }}
                >
                  <ApplicationIcon width="20px" height="20px" icon="cloudez" />
                  <ApplicationName style={{ marginTop: '0' }}>
                    Domínio
                  </ApplicationName>
                </ApplicationValue>
              </Col>

              <Col className="d-none d-xl-flex">
                <ApplicationValue
                  style={{ fontWeight: 'normal', marginLeft: '0' }}
                >
                  <CloudValue>
                    <span
                      style={{
                        fontSize: `${8}px`,
                      }}
                    >
                      AFEPA
                    </span>
                    <span
                      style={{
                        fontSize: `${6}px`,
                      }}
                    >
                      Duo
                    </span>
                  </CloudValue>
                </ApplicationValue>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <ApplicationValue
                  style={{
                    fontWeight: 'normal',
                    marginLeft: '0',
                    marginTop: '0',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0',
                    }}
                  >
                    500mb
                  </p>
                </ApplicationValue>
              </Col>

              <Col className="d-none d-xl-flex">
                <ApplicationValue
                  style={{
                    fontWeight: 'normal',
                    marginLeft: '0',
                    marginTop: '0',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0',
                    }}
                  >
                    2
                  </p>
                </ApplicationValue>
              </Col>

              <Col xl="2" className="d-none d-xl-flex" />
            </Row>
          </Card>
        ))}
      </Content>
    </Wrapper>
  );
};

export default Preview;
