import styled, { css } from 'styled-components';

interface ICardProps {
  height?: string;
}

export const Card = styled.div<ICardProps>`
  height: ${props => props.height || 'auto'};
  width: 100%;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 24px 12px;
  font-size: 18px;
  color: ${props => props.theme.interface5};
`;

export const CardBody = styled.div`
  width: 100%;
  height: auto;
  padding: 14px;
`;

export const Th = styled.th`
  padding: 8px;
  font-weight: normal;
  font-size: 12px;
  background: ${props => props.theme.interface2};
  color: ${props => props.theme.interface5};
`;

interface ITrProps {
  name: string;
}

export const Tr = styled.tr<ITrProps>`
  ${props =>
    props.name === 'Total' &&
    css`
      background: #2253f5;

      ${TdTitle} {
        color: #7be8ff;
        text-transform: uppercase;
        font-weight: bold;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      ${TdFinal} {
        color: #7be8ff;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      ${TdContent} {
        color: #7be8ff;
      }
    `}
`;

export const TdTitle = styled.td`
  padding: 10px 12px;
  font-size: 14px;
  color: ${props => props.theme.interface5};
`;

export const TdContent = styled.td`
  padding: 10px 12px;
  font-size: 14px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
  text-align: center;
`;

export const TdFinal = styled.td`
  padding: 10px 12px;
  font-size: 14px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
  text-align: center;
`;
