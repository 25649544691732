import styled from 'styled-components';

export const Content = styled.div`
  padding: 24px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  span {
    font-size: 16px;
    color: ${props => props.theme.interface5};
  }

  .attention {
    color: ${props => props.theme.interface5};
    p {
      font-weight: bold;
      color: ${props => props.theme.alertNew};
    }
  }

  svg {
    color: ${props => props.theme.brand_primary_color};
  }
`;
