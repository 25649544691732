import styled, { css } from 'styled-components';

interface IWrapperProps {
  hasNotification: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  grid-area: navbar;

  background-color: ${props => props.theme.interface1};
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 2px -2px ${props => props.theme.interface2};
  border-bottom: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface1 : props.theme.interface3};

  margin-bottom: 8px;

  ${props =>
    props.hasNotification &&
    css`
      margin-top: 60px;
    `};
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const IconList = styled.ul`
  margin-right: 47px;
  list-style: none;
  display: flex;

  & > li + li {
    padding-left: 17px;
  }

  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;

interface IIconItemProps {
  hideOnMobile?: boolean;
}

export const IconItem = styled.li<IIconItemProps>`
  position: relative;
  button {
    width: 100px;
    background: ${props => props.theme.interface2};
    color: ${props => props.theme.brand_primary_color};

    svg {
      transform: translateX(-8px);
    }

    &:hover {
      color: ${props => props.theme.interface1};
    }

    @media (max-width: 762px) {
      width: 34px;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        transform: translateX(0);
        margin: 0;
      }

      span {
        display: none;
      }
    }
  }

  ${props =>
    props.hideOnMobile &&
    css`
      @media (max-width: 762px) {
        display: none;
      }
    `}
`;

interface IUserProps {
  active: boolean;
}

export const User = styled.div<IUserProps>`
  cursor: pointer;
  height: 100%;
  width: 185px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 11px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: ${props =>
      props.theme.darkMode
        ? props.theme.interface4
        : props.theme.brand_primary_color};
  }

  svg {
    color: ${props =>
      props.theme.darkMode
        ? props.theme.interface4
        : props.theme.brand_primary_color};
    font-size: 8px;
  }

  div {
    display: flex;
    align-items: center;

    img {
      border: 2px solid transparent;
      transition: border 0.1s ease;

      ${props =>
        props.active &&
        css`
          border: 2px solid
            ${props =>
              props.theme.darkMode
                ? props.theme.interface4
                : props.theme.brand_primary_color};
        `}

      &:hover {
        border: 2px solid
          ${props =>
            props.theme.darkMode
              ? props.theme.interface4
              : props.theme.brand_primary_color};
      }

      margin-right: 10px;
    }
  }

  @media (max-width: 762px) {
    width: 80px;

    span {
      display: none;
    }
  }
`;

export const Count = styled.div`
  width: 12px;
  height: 12px;

  border-radius: 50%;

  border-width: 1px;
  border-color: white;
  background: red;

  position: absolute;
  top: 0;
  right: 0;

  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 9px;
  font-weight: bold;
`;

export const DomainsDropdown = styled.div`
  width: 150px;
  height: auto;

  position: absolute;
  background: ${props => props.theme.interface1};
  border: 1px solid ${props => props.theme.interface3};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 999;

  @media (max-width: 768px) {
    width: 120px;
    right: 10px;
  }

  li {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    a {
      color: ${props => props.theme.interface5};
      text-align: left;
      font-size: 12px;
      text-transform: none;

      cursor: pointer;

      &:hover {
        transition: color 0.1s ease-in;
        color: ${props => props.theme.brand_primary_color};
      }
    }
    padding: 10px 20px;
    text-align: left;
    font-size: 12px;

    cursor: pointer;

    svg {
      font-size: 14px;
      margin-right: 10px;
    }

    &:hover {
      transition: color 0.1s ease-in;
      color: ${props => props.theme.brand_primary_color};
    }
  }
`;
