import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 20px;
`;

interface ContentWrapperProps {
  isModal?: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  padding: ${props => !props.isModal && '32px'};
  width: 100%;
  height: auto;

  h3 {
    color: ${props => props.theme.interface6};
  }

  .warning,
  .alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    gap: 20px;

    min-width: 722px;

    box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
    border-radius: 5px;
    padding: 15px 18px;

    span {
      font: 16px Nunito;
      line-height: 22px;

      color: #f5f8ff;
    }

    svg {
      font-size: 16px;
      color: #f5f8ff;
    }
  }

  .warning {
    background: #ffbf2d;

    span {
      font-weight: bold;
    }
  }

  .alert {
    background: #f8820b;
    font-weight: normal;
  }
`;

export const LabelText = styled.label`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;

  margin-bottom: 8px;

  text-transform: uppercase;

  color: ${props => (props.color ? props.color : '#64728C')};
`;

export const RadioBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;

  .radioText {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-right: 28px;

    color: #64728c;
  }
`;

export const Icon = styled.div`
  margin-top: 34px;
  color: #64728c;
  opacity: 0.8;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  margin-bottom: 16px;

  color: #545c68;
`;

interface FieldProperties {
  direction?: string;
  noMargin?: boolean;
}

export const Field = styled.div<FieldProperties>`
  display: flex;
  margin-bottom: ${props => !props.noMargin && '20px'};
  flex-direction: ${props => (props.direction === 'row' ? 'row' : 'column')};
`;
