import styled from 'styled-components';

interface IUserCardProps {
  selected?: boolean;
}

export const UserCard = styled.div<IUserCardProps>`
  cursor: pointer;
  width: 100%;
  height: 50px;
  background: ${props => props.theme.interface2};
  padding: 13px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color
        : 'transparent'};
  border-radius: 5px;

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 20px;
  }

  &:hover {
    border: 1px solid ${props => props.theme.brand_primary_color};
  }
`;

export const Heading = styled.h3`
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.interface6};
  margin: 22px 0 15px;
`;

export const SubHeading = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  margin-bottom: 15px;
`;

export const Username = styled.span`
  font-weight: normal;
  font-size: 14px;
  margin-left: 8px;
  color: ${props => props.theme.interface5};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Host = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: ${props => props.theme.interface5};
  margin-right: 12px;

  span {
    display: block;
    font-weight: normal;
    font-style: italic;
  }
`;

export const Roles = styled.div`
  position: absolute;
  right: -10px;
  bottom: -10px;
  display: flex;
`;

export const Role = styled.div`
  margin-left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.brand_primary_color};
  svg {
    font-size: 10px;
  }
`;

export const UserDetail = styled.div`
  padding: 0px 15px;
  width: 100%;

  border-left: 1px solid ${props => props.theme.interface4};

  label {
    color: ${props => props.theme.brand_primary_color} !important;

    font-weight: 600;
    font-size: 14px;
  }

  @media (max-width: 762px) {
    border-left: none;

    img {
      width: 300px;
    }
  }
`;

export const AddUserButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.theme.brand_secondary_color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;

  svg {
    font-size: 20px;
    margin-right: -100px;
    transition: all 0.05s ease-out;
  }

  &:hover {
    width: auto;
    padding: 0px 8px;
    transition: all 0.3s ease-out;

    svg {
      margin-right: 0;
      transition: all 0.3s ease-out;
      transform: rotateZ(-180deg);
    }

    span {
      transition: opacity 0.3s ease-in-out;
      transition-delay: 0.1s;
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    margin-left: 8px;
    font-size: 12px;
    color: ${props => props.theme.brand_primary_color};
  }
`;
