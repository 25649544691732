import React from 'react';

import { Header, Title } from 'components/Common';

import { useDocTitle } from 'hooks';

import { Button } from '@cloudez/cloudez-design-system';

import processoComercial from './images/processo_comercial.png';
import backgroundAcademy from './images/backgroundAcademy.png';
import primeirosPassos from './images/primeiros_passos.png';
import painelCloudez from './images/painel_cloudez.png';

import { Container, Courses, MainSection } from './styles';

const CloudezAcademy = () => {
  useDocTitle('Academy - Cloudez Partners');

  return (
    <Container>
      <Header>
        <Title>Cloudez Academy</Title>
      </Header>

      <MainSection>
        <img
          className="background"
          src={backgroundAcademy}
          alt="Cloudez Academy"
        />

        <div className="accessAcademy">
          <span className="title">
            Garanta sua certificação em Mentalidade Cloud pela Cloudez Academy
          </span>
          <span className="text">
            A Cloudez Academy é o ambiente de capacitação da sua empresa digital
            e oferece uma certificação exclusiva para agências que trabalham com
            Cloud Hosting.
          </span>
          <span className="text">
            Tire já a sua certificação e libere todos os <br /> treinamentos
            desenhados especialmente para empresas digitais.
          </span>
          <Button
            onClick={() => window.open('http://academy.cloudez.io/', '_blank')}
            width="379px"
            height="50px"
          >
            Acesse a Cloudez Academy
          </Button>
        </div>
      </MainSection>
      <Header style={{ marginTop: '12px' }}>
        <Title>Cursos</Title>
      </Header>
      <Courses>
        <img
          onClick={() => {
            window.open(
              'https://academy.cloudez.io/cursos/mentalidade-cloud-primeiros-passos/',
              '_blank',
            );
          }}
          src={primeirosPassos}
          alt="Primeiros passos"
        />
        <img
          onClick={() => {
            window.open(
              'https://academy.cloudez.io/cursos/estruturando-o-processo-comercial/',
              '_blank',
            );
          }}
          src={processoComercial}
          alt="Processo Comercial"
        />
        <img
          onClick={() => {
            window.open(
              'https://academy.cloudez.io/cursos/conhecendo-o-painel-cloudez/',
              '_blank',
            );
          }}
          src={painelCloudez}
          alt="Painel Cloudez"
        />
      </Courses>
    </Container>
  );
};

export default CloudezAcademy;
