import styled from 'styled-components';

interface IOptionProps {
  selected: boolean;
}

export const Option = styled.div<IOptionProps>`
  cursor: pointer;
  width: 160px;
  height: 120px;
  padding: 8px 10px;
  position: relative;

  span {
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.interface6};
  }

  p {
    font-weight: normal;
    font-size: 12px;
    color: ${props => props.theme.interface5};
  }

  a {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    color: ${props => props.theme.brand_primary_color};

    svg {
      font-size: 10px;
      margin-right: 5px;
    }
  }

  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.brand_primary_color
        : props.theme.darkMode
        ? props.theme.interface5
        : props.theme.interface3};
  border-radius: 5px;

  & > svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 13px;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &:hover {
    border: 1px solid ${props => props.theme.brand_primary_color};
  }
`;

export const Options = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 762px) {
    height: 400px;
    flex-direction: column;
  }
`;
