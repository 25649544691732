import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import HoverableIcon from 'components/HoverableIcon';
import { Elipse } from './styles';

interface INavbarElipseProps {
  children?: React.ReactNode;
  icon: IconDefinition;
  hover?: IconDefinition;
  iconColor?: string;
  avatar?: any;
  id?: any;
}

const NavbarElipse: React.FC<any> = React.forwardRef(
  ({ icon, hover, avatar, iconColor, children, id }, ref) => {
    return (
      <Elipse id={id} ref={ref}>
        <HoverableIcon
          icon={icon}
          hover={hover}
          avatar={avatar}
          iconColor={iconColor}
        />
        {children}
      </Elipse>
    );
  },
);

export default NavbarElipse;
