import React, { useState } from 'react';
import { withTheme } from 'styled-components';

import { faCommentAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestHelpService } from 'services/ticket';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { Box } from './styles';

const HelpModal = ({ show, setShow, ticket, getTicket, sendMessage }) => {
  const [loading, setLoading] = useState(false);

  const actionModal = async () => {
    try {
      setLoading(true);
      const { data } = await requestHelpService(ticket.id);

      if (data.message) {
        getTicket(ticket.id);
      }

      sendMessage(
        !ticket.asked_help
          ? 'O seu atendimento foi escalado para nossos especialistas. Em breve eles entrarão em contato.'
          : 'O seu atendimento com os nossos especialistas foi encerrado.',
      );

      setShow(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  const title = ticket.asked_help
    ? 'Encerrar Ajuda com a Cloudez'
    : ' Atendimento Cloudez';
  const subtitle = ticket.asked_help
    ? 'Nós não iremos mais interagir no Ticket com o seu cliente.'
    : 'Precisa da nossa ajuda em um atendimento ao seu cliente?';

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faCommentAlt} />
        </Icon>
        <Text>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Text>
      </Header>

      <Content>
        <Box>
          {!ticket.asked_help ? (
            <>
              <span>
                Ao ativar essa opção, nosso time de especialistas será avisado
                para interagir com o seu cliente neste ticket.
              </span>
              <span>
                Durante o atendimento, o ticket só poderá ser respondido pelo
                time de especialistas, caso queria interagir, você deverá
                encerrar a ajuda.
              </span>
              <span>Você poderá encerrar a ajuda a qualquer momento.</span>
              <span>
                Ao solicitar ajuda, este ticket ficará em destaque na lista de
                tickets.
              </span>
            </>
          ) : (
            <>
              <span>
                Ao encerrar o atendimento, nós não iremos mais interagir com o
                seu cliente nesse ticket.
              </span>
              <span>
                Caso necessite novamente da ajuda do nosso time de
                especialistas, solicite novamente.
              </span>
            </>
          )}
        </Box>
      </Content>
      <Footer hasLink>
        <Button style={{ width: '117px' }} text onClick={() => setShow(false)}>
          Fechar
        </Button>
        <Button icon onClick={actionModal}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : ticket.asked_help ? (
            'Encerrar'
          ) : (
            'Solicitar'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(HelpModal as any);
