import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  createPagination,
  Label,
  Pagination,
} from '@cloudez/cloudez-design-system';

import {
  Flexbox,
  Box,
  CustomInput,
  CustomRadio,
} from 'components/NewComponents';
import ApplicationIcon from 'components/ApplicationIcon';

import { validateDomainService, validateRemoteService } from 'services/friday';
import { getWebsitesService } from 'services/website';

import {
  faChevronDown,
  faChevronUp,
  faCloudUploadAlt,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Alert } from 'components/Alert';
import { useMigCreateWebsite } from '../../contexts/MigWebsiteContext';
import { useMigDetail } from '../../contexts/MigDetailContext';

import ValidatingConnectionModal from './ValidatingConnection';

import { Card, Form } from './styles';
import { ErrorModal } from '../../ErrorModal';

const validateDomainField = yup.object().shape({
  domain: yup.string().required('Por favor, digite um domínio.'),
});

const validateFTPData = yup.object().shape({
  domain: yup.string().required('Por favor, digite um domínio.'),
  remote_host: yup.string().required('Esse campo é obrigatório'),
  remote_port: yup
    .number()
    .typeError('Esse campo é obrigatório')
    .required('Esse campo é obrigatório'),
  remote_user: yup.string().required('Esse campo é obrigatório'),
  remote_password: yup.string().required('Esse campo é obrigatório'),
  ssh_path: yup.string().when('is_sftp', {
    is: true,
    then: yup.string().required('Esse campo é obrigatório'),
  }),
  is_sftp: yup.boolean().required('Esse campo é obrigatório').default(false),
});

const OriginData = (): JSX.Element => {
  const {
    setSelectedOriginItem,
    selectedOriginItem,
    setWebsitesOrigin,
    websitesOrigin,
    setIsValidated,
    setOriginData,
    isValidated,
    originData,
    migrationErrors,
    migErrorMessage,
  } = useMigCreateWebsite();

  const [showValidatingConnectionModal, setShowValidatingConnectionModal] =
    useState(false);
  const [showAdvConfigs, setShowAdvConfigs] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setStep } = useMigDetail();

  const {
    handleSubmit,
    clearErrors,
    getValues,
    register,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isValidated &&
        websitesOrigin?.results?.length === 0 &&
        !selectedOriginItem?.id
        ? validateFTPData
        : validateDomainField,
    ),
  });

  const getWebsites = async (page, domain) => {
    try {
      const response = await getWebsitesService({
        page,
        domain: domain?.trim() || watch('domain'),
        page_size: 2,
      });

      const pagination = createPagination(response.data, 2);

      setWebsitesOrigin({
        ...pagination,
        results: response.data.results,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const [showPass, setShowPass] = useState(false);

  const validateFtp = async data => {
    try {
      const payload = {
        ...data,
        ssh_path: data.ssh_path.length > 1 ? data.ssh_path.trim() : '/',
      };

      setLoading(true);
      setShowValidatingConnectionModal(true);
      const response = await validateRemoteService(payload);

      if (!response?.data?.result) {
        toast.error(response?.data?.message);
        setLoading(false);
        return;
      }

      setShowValidatingConnectionModal(false);
      setLoading(false);
      setOriginData(payload);

      if (payload?.ssh_path) {
        setStep(2);
        setSelectedOriginItem('external');
      }
    } catch (err) {
      setShowValidatingConnectionModal(false);
      setLoading(false);
      const message = {
        '530 Login authentication failed': 'Login ftp incorreto',
        '530 Login incorrect.': 'Login ftp incorreto',
      }[err?.response?.data?.message];

      if (message) {
        setError('remote_user', {
          type: 'manual',
          message,
        });
      } else {
        migrationErrors(err?.response?.data);
      }
    }
  };

  const validateDomain = async () => {
    if (!getValues('domain')) {
      setError('domain', {
        type: 'manual',
        message: 'Por favor, digite um domínio.',
      });

      return;
    }

    try {
      setIsValidating(true);
      const { data } = await validateDomainService(watch('domain').trim());

      if (!data?.result) {
        setError('domain', {
          type: 'manual',
          message: data.message,
        });
        setIsValidating(false);
        return;
      }
      await getWebsites(1, watch('domain').trim());
      setIsValidating(false);
      setIsValidated(true);
      setOriginData({ ...originData, domain: watch('domain').trim() });
      clearErrors('domain');
    } catch (err) {
      setIsValidating(false);
      if (err?.response?.data) {
        migrationErrors(err?.response?.data);
      }
    }
  };

  const goBackFunc = () => {
    setWebsitesOrigin({ results: [] });
    setIsValidated(false);
  };

  const goAheadFunc = (data: any) => {
    if (websitesOrigin?.results?.length === 0) {
      validateFtp(data);
      return;
    }

    if (selectedOriginItem === 'external') {
      setWebsitesOrigin({ results: [] });
    }

    if (selectedOriginItem.id) {
      setStep(2);
    }
  };

  useEffect(() => {
    if (Object.values(originData).length > 0) {
      setValue('remote_password', originData.remote_password);
      setValue('remote_host', originData.remote_host);
      setValue('remote_user', originData.remote_user);
      setValue('remote_port', originData.remote_port);
      setValue('is_sftp', originData.is_sftp);
      setValue('domain', originData.domain.trim());
      setIsValidated(true);
      if (originData.ssh_path) {
        setValue('ssh_path', originData.ssh_path);
        setShowAdvConfigs(true);
      }
    }
  }, [originData]);

  useEffect(() => {
    if (isValidated && !originData.remote_port) {
      setValue('remote_port', watch('is_sftp') ? 22 : 21);
    }
  }, [isValidated, watch('is_sftp'), originData]);

  useEffect(() => {
    if (watch('is_sftp')) {
      setShowAdvConfigs(true);
    }
  }, [watch('is_sftp')]);

  return (
    <>
      {migErrorMessage && <ErrorModal err={migErrorMessage} />}
      {showValidatingConnectionModal && (
        <ValidatingConnectionModal
          show={showValidatingConnectionModal}
          setShow={setShowValidatingConnectionModal}
        />
      )}
      <Form onSubmit={handleSubmit(goAheadFunc)}>
        <div className="title">
          <FontAwesomeIcon icon={faCloudUploadAlt} />
          <span>Dados da Origem</span>
        </div>
        {websitesOrigin?.results?.length === 0 ? (
          <Flexbox w="100%">
            <CustomInput
              block
              name="domain"
              label="Digite o Domínio"
              register={register}
              disabled={(!!originData?.domain && isValidated) || isValidated}
              error={errors?.domain?.message}
            />

            <Button
              style={{
                marginTop: '23px',
                gap: '5px',
                padding: '12px',
                opacity: '1',
              }}
              width="120px"
              type="button"
              height="40px"
              onClick={validateDomain}
              icon
              disabled={isValidated || isValidating}
              outline={!isValidating && !isValidated}
              success={isValidated}
            >
              {isValidated && <FontAwesomeIcon icon={faCheckCircle} />}
              <span
                style={{
                  fontSize: '16px',
                  textTransform: 'none',
                }}
              >
                {isValidated && 'Validado'}
                {isValidating && <FontAwesomeIcon icon={faSpinner} spin />}
                {!isValidated && !isValidating && 'Validar'}
              </span>
            </Button>
          </Flexbox>
        ) : null}
        {websitesOrigin?.results?.length > 0 && (
          <Flexbox flexDir="column" spacing="26px">
            <span className="subtitle">
              Selecione abaixo qual será a origem da sua aplicação:
            </span>
            <Flexbox spacing="16px">
              {websitesOrigin?.results?.map((item: any) => (
                <Card
                  onClick={() => setSelectedOriginItem(item)}
                  selected={selectedOriginItem.id === Number(item.id)}
                >
                  <ApplicationIcon
                    width="40px"
                    height="40px"
                    icon={`${item?.type.slug}`}
                  />
                  <b>{item.domain || item.name}</b>
                  <span>
                    {item.name} - {item.cloud.disk}
                  </span>
                </Card>
              ))}
              <Card
                onClick={() => setSelectedOriginItem('external')}
                selected={selectedOriginItem === 'external'}
              >
                <FontAwesomeIcon
                  className="externalLinkAlt"
                  icon={faExternalLinkAlt}
                />
                <b>Migração externa</b>
              </Card>
            </Flexbox>
          </Flexbox>
        )}
        {isValidated && websitesOrigin?.results?.length === 0 ? (
          <>
            <Flexbox>
              <CustomInput
                label="SERVIDOR FTP"
                block
                name="remote_host"
                register={register}
                error={errors?.remote_host?.message}
              />
            </Flexbox>
            <Flexbox>
              <CustomInput
                label="USUÁRIO FTP"
                block
                name="remote_user"
                register={register}
                error={errors?.remote_user?.message}
              />
              <CustomInput
                icon={{
                  svg: showPass ? faEyeSlash : faEye,
                  isRight: true,
                  action: () => setShowPass(!showPass),
                }}
                label="SENHA FTP"
                block
                type={showPass ? 'text' : 'password'}
                name="remote_password"
                register={register}
                error={errors?.remote_password?.message}
              />
            </Flexbox>
            <Flexbox maxWidth="40%">
              <CustomInput
                label="Porta"
                block
                type="number"
                name="remote_port"
                register={register}
                error={errors?.remote_port?.message}
              />
              <CustomRadio
                style={{ marginTop: '32px' }}
                label={
                  <Label style={{ marginLeft: '12px', marginBottom: '0px' }}>
                    SFTP/SSH
                  </Label>
                }
                name="is_sftp"
                register={register}
                error={errors?.is_sftp?.message}
              />
            </Flexbox>
            <Box justify="center">
              <div
                className="advancedConfigs"
                onClick={() => setShowAdvConfigs(!showAdvConfigs)}
              >
                <span>
                  {showAdvConfigs ? 'Fechar' : 'Exibir'} configurações avançadas
                </span>
                <FontAwesomeIcon
                  icon={showAdvConfigs ? faChevronUp : faChevronDown}
                />
              </div>
            </Box>
            <Flexbox>
              <CustomInput
                style={{ display: showAdvConfigs ? 'flex' : 'none' }}
                label="Caminho da Aplicação"
                block
                name="ssh_path"
                register={register}
                error={errors?.ssh_path?.message}
              />
            </Flexbox>
          </>
        ) : null}
        {websitesOrigin?.count > 2 && (
          <Pagination
            onChangePage={getWebsites}
            style={{ marginTop: '0px' }}
            pageSize={2}
            count={websitesOrigin.count}
            previous={websitesOrigin.previous}
            next={websitesOrigin.next}
            current={websitesOrigin.current}
          />
        )}
        {isValidated &&
          Object.values(selectedOriginItem).length > 0 &&
          websitesOrigin?.count >= 1 && (
            <Alert style={{ width: '734px' }} info>
              <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
              <span>
                <b>O que será feito?</b>{' '}
                {selectedOriginItem === 'external'
                  ? 'Iremos migrar sua aplicação de um local externo, via sFTP ou SSH, para um destino interno com a gente.'
                  : 'Iremos migrar todos os arquivos dessa aplicação existente conosco e copiá-los para um novo destino internamente.'}
              </span>
            </Alert>
          )}

        {isValidated && (
          <Box alignItems="center" justify="space-between">
            <Button
              onClick={goBackFunc}
              type="button"
              style={{ fontSize: '14px' }}
              width="117px"
              text
            >
              Voltar
            </Button>
            <Button
              style={{
                fontSize: '16px',
                textTransform: 'none',
              }}
              outline
              disabled={loading}
              icon
              width="120px"
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : websitesOrigin?.results?.length === 0 ? (
                'Validar'
              ) : (
                'Continuar'
              )}
            </Button>
          </Box>
        )}
      </Form>
    </>
  );
};

export default OriginData;
