import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';
import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faDollarSign,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import toastError from 'utils/toastError';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import {
  FormInput,
  Label,
  Field,
  FormSelect,
  Form,
  FormTextArea,
  FormCheckbox,
} from 'components/Input';
import { Hover, HoverText } from 'components/Hover';

import { Scope } from '@rocketseat/unform';

import { getBasePlansService } from 'services/plans';

import { PlanListingContext } from '../_context/state';
import { PlanCardItem } from '../types/planCard';

import PlanPreviewComponent from './PlanPreview';

import { Advanced, Value } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  category: Yup.number()
    .typeError('Escolha uma categoria válido')
    .required('Este campo é obrigatório'),
  has_turbo: Yup.boolean(),
  accounts_size_limit: Yup.string(),
  accounts_limit: Yup.string(),
  turbo_base_price: Yup.object().shape({
    amount: Yup.string(),
    currency: Yup.string().default('BRL'),
  }),
  cloud_size: Yup.number()
    .typeError('Insira um valor válido')
    .positive('Valor deve ser positivo'),
  automations: Yup.object().shape({
    default_plan_type: Yup.number(),
  }),
  description: Yup.string().when('category', {
    is: category => category === 1,
    then: Yup.string().required('Este campo é obrigatório'),
    otherwise: Yup.string().optional(),
  }),
});

interface NewPlanModalProps {
  plans: PlanCardItem[];
  show: boolean;
  setShow: (value?: boolean) => void;
}

const NewPlanModal: React.FC<NewPlanModalProps> = ({
  plans,
  show,
  setShow,
}) => {
  const { createPlan } = useContext(PlanListingContext);

  const [category, setCategory] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [freeTrial, setFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basePlan, setBasePlan] = useState(null);
  const [basePlans, setBasePlans] = useState(null);

  const [hasTurbo, setHasTurbo] = useState(true);

  const [showAdvConfigs, setShowAdvConfigs] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [turboBasePrice, setTurboBasePrice] = useState<string | number>(0);

  const handleCreatePlan = async (data, { resetForm }) => {
    try {
      setLoading(true);
      const payload = {
        ...data,
        has_basic: false,
        has_turbo: true,
        is_private: isPrivate,
        base_price: {
          ...data.turbo_base_price,
          amount: turboBasePrice,
        },
        turbo_base_price: {
          ...data.turbo_base_price,
          amount: turboBasePrice,
        },
      };

      if (data.category === 10) {
        let accountsLimit = data.accounts_limit;
        let accountsSizeLimit = data.accounts_size_limit;

        if (!accountsLimit) {
          accountsLimit = 0;
        }
        if (!accountsSizeLimit) {
          accountsSizeLimit = 0;
        }

        payload.accounts_limit = accountsLimit;
        payload.accounts_size_limit = accountsSizeLimit;
      }

      if (!freeTrial) {
        delete payload.automations;
      } else {
        payload.base_price.amount = 0;
      }

      await createPlan(payload);
      resetForm();

      setLoading(false);
      setShow();
      setFreeTrial(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const plansCPU = basePlans
    ?.filter(item => item.slug.includes('cpu'))
    ?.reverse();
  const plansEmail = basePlans
    ?.filter(item => item.slug.includes('email'))
    ?.reverse();
  const plansRAM = basePlans
    ?.filter(item => item.slug.includes('ram'))
    ?.reverse();
  const plansDefault = basePlans
    ?.filter(
      item =>
        !item.slug.includes('ram') &&
        !item.slug.includes('email') &&
        !item.slug.includes('cpu'),
    )
    ?.reverse();

  const sortedBasePlans = plansDefault &&
    plansEmail &&
    plansRAM &&
    plansCPU && [...plansDefault, ...plansEmail, ...plansRAM, ...plansCPU];

  const basePlansOptions = sortedBasePlans?.map(p => ({
    id: p.id,
    title: `${p.name} | ${p.cores} vCPU | ${p.memory} RAM | ${p.disk} (${
      p.price.currency === 'BRL' ? 'R$ ' : '$ '
    } ${p.price.amount})`,
  }));

  const plansOptions = plans
    ?.filter(p => p.slug.includes('1g') && !p.is_individual)
    .map(p => ({
      id: p.id,
      title: `${p.name}`,
    }));

  useEffect(() => {
    const getPlans = async () => {
      try {
        const { data } = await getBasePlansService();
        setBasePlans(data);
      } catch (e) {
        console.log(e);
      }
    };

    getPlans();
  }, []);

  const isEmail = useMemo(() => {
    if (!basePlan) return false;
    return basePlan.category === 3;
  }, [basePlan]);

  return (
    <Modal
      show={show}
      setShow={setShow}
      width={category === 10 ? '455px' : '758px'}
    >
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Novo plano</Title>
          <Subtitle>Crie um plano personalizado para seus clientes.</Subtitle>
        </Text>
      </Header>

      <Form schema={schema} onSubmit={handleCreatePlan}>
        <Content>
          <Row>
            <Col>
              <Field>
                <Label htmlFor="category">
                  Selecione uma categoria de plano
                </Label>
                {basePlansOptions && (
                  <FormSelect
                    placeholder="Selecione uma categoria"
                    height="40px"
                    name="category"
                    block="true"
                    options={[
                      {
                        id: 1,
                        title: 'Cloud',
                      },
                      {
                        id: 9,
                        title: 'Site compartilhado',
                      },
                      {
                        id: 10,
                        title: 'Email compartilhado',
                      },
                    ]}
                    onChange={e => {
                      setCategory(Number(e.target.value));
                      if (e.target.value !== 1) {
                        setBasePlan(null);
                      }
                    }}
                  />
                )}
              </Field>
              {category && category === 1 && (
                <Field>
                  <Label htmlFor="cloud_size">Selecionar plano base</Label>
                  {basePlansOptions && (
                    <FormSelect
                      placeholder="Selecione um plano base."
                      height="40px"
                      name="cloud_size"
                      block="true"
                      options={basePlansOptions}
                      onChange={e => {
                        const basePlan = sortedBasePlans?.find(
                          p => p.id === Number(e.target.value),
                        );
                        if (basePlan.category === 3) {
                          setHasTurbo(false);
                        } else if (!hasTurbo) {
                          setHasTurbo(true);
                        }
                        setBasePlan(basePlan);
                        setFreeTrial(
                          basePlan.slug.includes('partner-free-trial'),
                        );
                      }}
                    />
                  )}
                </Field>
              )}
              {category && category === 1 && freeTrial && (
                <Field>
                  <Label>Selecionar plano padrão</Label>
                  {plans && (
                    <Scope path="automations">
                      <FormSelect
                        placeholder="Selecione um plano padrão."
                        height="40px"
                        name="default_plan_type"
                        block="true"
                        options={plansOptions}
                      />
                    </Scope>
                  )}
                </Field>
              )}
              <Field>
                <Label htmlFor="name">
                  Nome{' '}
                  <span className="counter">{`${name.length}/30 caracteres`}</span>
                </Label>
                <FormInput
                  name="name"
                  onChange={e => setName(e.target.value)}
                  maxLength={30}
                  block="true"
                  disabled={!category}
                />
              </Field>
              {category && category === 1 && (
                <Field>
                  <Label htmlFor="description">
                    Descrição do plano{' '}
                    <span className="counter">{`${description.length}/128 caracteres`}</span>
                  </Label>
                  <FormTextArea
                    style={{ resize: 'none' }}
                    name="description"
                    maxLength={128}
                    onChange={e => setDescription(e.target.value)}
                    block
                    height="90px"
                  />
                </Field>
              )}
              {category &&
                (category === 9 ||
                  category === 10 ||
                  (basePlan && isEmail)) && (
                  <Field>
                    <Label>
                      {category && category === 1
                        ? 'Margem Adicional no Plano'
                        : 'Valor do Plano'}
                    </Label>
                    <Scope path="base_price">
                      <CurrencyFormat
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        customInput={FormInput}
                        // fixedDecimalScale
                        prefix="R$"
                        value={turboBasePrice}
                        onValueChange={(values: any) => {
                          setTurboBasePrice(values.floatValue || '');
                        }}
                        name="amount"
                        placeholder={
                          category && category === 1
                            ? 'Margem Adicional no Plano'
                            : 'Valor do Plano'
                        }
                        width={category === 10 ? '100%' : '150px'}
                        block
                      />
                    </Scope>
                  </Field>
                )}
              {category && category === 1 && basePlan && !isEmail && (
                <>
                  <Field>
                    <Label htmlFor="has_turbo">MARGEM ADICIONAL NO PLANO</Label>
                    <div
                      style={{ alignItems: 'center', justifyContent: 'left' }}
                    >
                      <Scope path="turbo_base_price">
                        <CurrencyFormat
                          data-testid="turbo_base_price"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          customInput={FormInput}
                          // fixedDecimalScale
                          prefix="R$"
                          disabled={!hasTurbo}
                          background={!hasTurbo}
                          value={turboBasePrice}
                          onValueChange={values => {
                            setTurboBasePrice(values.floatValue || 0);
                          }}
                          name="amount"
                          placeholder="Margem adicional"
                          width="150px"
                          block="true"
                        />
                      </Scope>
                    </div>
                  </Field>{' '}
                </>
              )}

              <Field>
                <Label>
                  Privado{' '}
                  <Hover>
                    ?{' '}
                    <HoverText>
                      Os planos privados não aparecerão no painel principal, ou
                      seja, não poderão ser contratados diretamente pelo cliente
                      final
                    </HoverText>
                  </Hover>
                </Label>
                <FormCheckbox
                  onChange={e => setIsPrivate(e.target.checked)}
                  value={isPrivate}
                  checked={isPrivate}
                  disabled={!category}
                  name="is_private"
                />
              </Field>
              {category === 10 && (
                <Field>
                  <Label>VALOR TOTAL </Label>
                  <Value>
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(Number(turboBasePrice))}
                  </Value>
                </Field>
              )}
              {category === 10 && (
                <Advanced
                  style={{ marginBottom: showAdvConfigs ? '26px' : '0' }}
                  onClick={() => setShowAdvConfigs(!showAdvConfigs)}
                >
                  <span>Configurações avançadas</span>
                  <FontAwesomeIcon
                    icon={showAdvConfigs ? faChevronUp : faChevronDown}
                  />
                </Advanced>
              )}

              {showAdvConfigs && (
                <>
                  <Field>
                    <Label htmlFor="accounts_limit">
                      Limite de Contas de e-mail
                    </Label>
                    <FormInput
                      name="accounts_limit"
                      maxLength={60}
                      block
                      type="number"
                      placeholder="Deixe em branco ou 0 para ilimitado"
                    />
                  </Field>
                  <Field style={{ marginBottom: '0px' }}>
                    <Label htmlFor="accounts_size_limit">
                      Limite de armazenamento por conta (EM MB)
                    </Label>
                    <FormInput
                      name="accounts_size_limit"
                      maxLength={60}
                      block
                      type="number"
                      placeholder="Deixe em branco ou 0 para ilimitado"
                    />
                  </Field>
                </>
              )}
            </Col>
            {category !== 10 && (
              <Col className="d-none d-sm-block">
                <PlanPreviewComponent
                  turboBasePrice={turboBasePrice}
                  hasTurbo={hasTurbo}
                  isEmail={isEmail}
                  name={name}
                  basePlan={basePlan}
                  category={category}
                  description={description}
                  basePrice={turboBasePrice}
                />
              </Col>
            )}
          </Row>
        </Content>
        <Footer>
          <Button icon type="submit">
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Criar plano'
            )}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default NewPlanModal;
