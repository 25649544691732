import React from 'react';
import { Col } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';
import { PlanCard } from '../styles';

const PlanPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 5; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map(p => (
        <Col xl="2" key={p}>
          <PlanCard>
            <Placeholder width={40} height={14} />
            <Placeholder width={60} height={45} />
            <div>
              <span>
                <Placeholder width={40} height={12} />
              </span>
              <span>
                <Placeholder width={40} height={12} />
              </span>
              <span>
                <Placeholder width={40} height={12} />
              </span>
            </div>
          </PlanCard>
        </Col>
      ))}
    </>
  );
};

export default PlanPlaceholder;
