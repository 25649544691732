import React from 'react';

import history from 'services/history';
import { useLayout } from 'hooks';
import cloudez from 'assets/img/svg/cloudez-partners-negativo.svg';

import cloudezC from 'assets/img/svg/cloudez-partners-reduzido-negativo.svg';
import { LogoImage, LogoWrapper } from './styles';

const Logo: React.FC = () => {
  const { sideMenuCollapsed } = useLayout();

  return (
    <LogoWrapper
      sideMenuCollapsed={sideMenuCollapsed}
      onClick={() => history.push('/dashboard')}
    >
      <LogoImage src={!sideMenuCollapsed ? cloudez : cloudezC} />
    </LogoWrapper>
  );
};

export default Logo;
