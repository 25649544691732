import styled, { css } from 'styled-components';

export const CloudValue = styled.div`
  display: flex;
  font-size: 12px;
  flex-direction: column;
`;

interface ApplicationValueProps {
  closed?: boolean;
}

export const ApplicationValue = styled.div<ApplicationValueProps>`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${props => (props.closed ? '#A2A9BF' : props.theme.interface5)};
  margin-left: 12px;
  width: 100%;
  height: 100%;
  font-weight: 400;

  .bold {
    color: ${props => props.theme.interface6};
    font-weight: bold;
  }
`;

export const ApplicationName = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 12px;
`;

export const ApplicationSubName = styled.span`
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 12px;
  color: ${props => props.theme.interface5};
  font-weight: 400;
`;

interface OrderingProps {
  status?: number;
}

export const Ordering = styled.div<OrderingProps>`
  display: flex;
  flex-direction: column;
  margin-top: -2px;
  margin-left: 4px;
  color: ${props => props.theme.interface4};

  svg {
    &:first-child {
      color: ${props => props.status === 1 && props.theme.interface7};
      margin-bottom: -3px;
    }

    &:last-child {
      color: ${props => props.status === 2 && props.theme.interface7};
    }
  }
`;

export const ListingHeader = styled.div`
  text-transform: uppercase;
  padding: 0px 12px;
  margin-bottom: 10px;
  font-size: 10px;
  margin-left: 14px;
  color: ${props => props.theme.interface5};

  p {
    display: flex;
    cursor: pointer;
  }
`;

export const Listing = styled.ul`
  list-style: none;
`;

export const Notification = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  position: absolute;
  z-index: 999;

  top: calc(50% - 5px);
  left: 12px;
`;

interface IListingItemProps {
  fade?: number;
  disable?: boolean;
  ticket?: any;
  color?: string;
  favorite?: string;
}

export const ListingItem = styled.li<IListingItemProps>`
  list-style-type: none;

  ${props =>
    props.fade === 0
      ? css`
          opacity: 100%;
        `
      : props.fade === 1
      ? css`
          opacity: 80%;
        `
      : props.fade === 2
      ? css`
          opacity: 60%;
        `
      : props.fade === 3
      ? css`
          opacity: 40%;
        `
      : props.fade === 4
      ? css`
          opacity: 75%;
        `
      : props.fade === 5
      ? css`
          opacity: 70%;
        `
      : props.fade === 6
      ? css`
          opacity: 50%;
        `
      : props.fade === 7
      ? css`
          opacity: 45%;
        `
      : props.fade === 8
      ? css`
          opacity: 35%;
        `
      : props.fade === 9
      ? css`
          opacity: 15%;
        `
      : props.fade === 10
      ? css`
          opacity: 5%;
        `
      : null};

  a {
    text-decoration: none;
    color: inherit;
  }

  color: ${props =>
    props.disable ? props.theme.interface3 : props.theme.interface5};

  border: 2px solid transparent;

  margin-bottom: 10px;

  &:hover {
    box-shadow: 0px 3px 15px #64728c4d;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: 2px solid ${props => props.theme.brand_primary_color};

    a {
      color: ${props => props.theme.brand_primary_color};
    }
  }

  ${props =>
    props.ticket &&
    css`
      &:hover {
        ${ApplicationValue} {
          span {
            svg {
              color: ${props => props.theme.brand_primary_color} !important;
              transition: color 0.1s ease-in;
            }
          }
        }
      }
    `};
`;

interface CardProps {
  closed?: boolean;
}

export const Card = styled.div<CardProps>`
  position: relative;
  height: 70px;
  border-radius: 5px;

  background: ${props =>
    props.closed ? 'transparent' : props.theme.interface1};
`;

export const CardContent = styled.div<CardProps>`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  border: ${props => (props.closed ? '1px solid #E8EAEF' : 'none')};

  border-radius: 5px;

  svg {
    color: ${props => props.theme.brand_primary_color};
  }

  p {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export const FavoriteIcon = styled.div<IListingItemProps>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    ${props =>
      props.disable &&
      css`
        filter: grayscale(1);
        opacity: 0.2;
      `};
  }
`;

export const FavoriteWrapper = styled.div<IListingItemProps>`
  border: 1px solid ${props => props.theme.interface4};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 20px;
  height: 100%;
  padding: 0;
  margin: 0;
  transition: all 0.2s ease-in-out;

  ${props =>
    props.favorite &&
    css`
      background: ${(props: any) =>
        props.color ? props.color : props.theme.favorite};
      border: 1px solid transparent;

      svg {
        color: ${props => props.theme.interface1} !important;
      }
    `};

  svg {
    color: ${props => props.theme.interface4};
  }

  &:hover {
    border: 1px solid
      ${props => (props.color ? props.color : props.theme.favorite)};

    svg {
      color: ${props => (props.color ? props.color : props.theme.favorite)};
    }
  }
`;

export const FavoriteLabel = styled.div`
  width: 20px;
  height: 100%;
  padding: 0;
  margin: 0;
`;
