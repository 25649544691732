/* eslint-disable prettier/prettier */
import Axios from 'axios';
import { toast } from 'react-toastify';
import { createCreditcardService } from 'services/common';

export const createCreditCardToken = async (card: any): Promise<void> => {
  const { Iugu } = window as any;

  Iugu.setAccountID(process.env.REACT_APP_IUGU_GATEWAY_ACCOUNT_ID);

  if (process.env.NODE_ENV === 'development') {
    Iugu.setTestMode(true);
  }

  const cardData = {
    month: card.expiration.split('/')[0],
    year: card.expiration.split('/')[1],
    name: card?.name.split(' ')[0],
    lastName: card?.name.split(' ')[1],
    number: card.number,
    cvc: card.verification_value.trim(),
  };

  const creditCard = Iugu.CreditCard(
    cardData.number,
    cardData.month,
    cardData.year,
    cardData?.name,
    cardData.lastName,
    cardData.cvc,
  );

  return new Promise((resolve, reject) => {
    Iugu.createPaymentToken(creditCard, async response => {
      if (response.errors) {
        reject(response);
        return;
      }

      const token = response.id;

      const chargePayload = {
        token,
      };

      try {
        const { data } = await createCreditcardService(chargePayload);
        resolve(data);
      } catch (e) {
        console.log('createCreditcardService error - internal', e);
        reject(e?.response?.data);
      }
    });
  });
};

export const createCreditCardTokenExternal = async (
  card: any,
  hash: string,
): Promise<void> => {
  const apiUrl =
    window.location.hostname === 'demo.cloudez.io'
      ? 'https://dev-api.cloudez.io/'
      : process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : 'https://api.cloudez.io/';

  const { Iugu } = window as any;

  if (Iugu === undefined) {
    toast.error(
      'Não foi possível completar o pagamento. Obs: Desative o Adblock',
    );
    return;
  }

  Iugu.setAccountID(process.env.REACT_APP_IUGU_GATEWAY_ACCOUNT_ID);

  if (process.env.NODE_ENV === 'development') {
    Iugu.setTestMode(true);
  }

  const cardData = {
    month: card.expiration.split('/')[0],
    year: card.expiration.split('/')[1],
    name: card?.name.split(' ')[0],
    lastName: card?.name.split(' ')[1],
    number: card.number,
    cvc: card.verification_value.trim(),
  };

  const creditCard = Iugu.CreditCard(
    cardData.number,
    cardData.month,
    cardData.year,
    cardData?.name,
    cardData.lastName,
    cardData.cvc,
  );

  return new Promise((resolve, reject) => {
    Iugu.createPaymentToken(creditCard, async response => {
      if (response.errors) {
        reject(response);
        return;
      }

      const token = response.id;

      const chargePayload = {
        token,
        hash,
      };

      try {
        const { data } = await Axios.post(
          `${apiUrl}v0/creditcard-hash/`,
          chargePayload,
        );
        resolve(data);
      } catch (e) {
        console.log('createCreditcardService error - external', e);
        reject(e?.response?.data);
      }
    });
  });
};
