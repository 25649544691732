import styled, { css } from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';
import { darken } from 'polished';

export const Wrapper = styled.div`
  width: 900px;
  height: 450px;
  margin: 30px auto;

  background: ${props => props.theme.interface2};
`;

interface INavbarProps {
  collapseMenu: boolean;
}

export const Navbar = styled.div<INavbarProps>`
  width: 100%;
  height: 30px;

  background: ${props => props.theme.interface1};

  display: flex;
  padding: 8px;

  svg {
    margin-left: ${props => (props.collapseMenu ? '50px' : '100px')};
    transition: margin-left 0.2s ease-in;
    cursor: pointer;
  }
`;

interface ISidebarProps {
  collapseMenu: boolean;
  primary: any;
}

export const Sidebar = styled.div<ISidebarProps>`
  height: 100%;
  width: ${props => (props.collapseMenu ? '50px' : '100px')};
  transition: width 0.2s ease-in;
  z-index: 1;

  background: ${props => props.primary};
  position: relative;
  top: -30px;
  left: 0;

  img {
    height: 20px;
    margin: 14px;
  }

  &:hover {
    ${props =>
      props.collapseMenu &&
      css`
        width: 100px;
      `}
  }
`;

export const MenuList = styled.ul`
  list-style: none;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 2px 20px;
`;

interface IListItemProps {
  isActive?: boolean;
  secondary: string;
  collapseMenu: boolean;
}

export const ListItem = styled.li<IListItemProps>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 2px;

  svg {
    color: ${({ isActive, secondary }) => (isActive ? '#FFFFFF' : secondary)};
    font-size: 10px;
    margin-right: 4px;
  }

  span {
    color: ${({ isActive, secondary }) => (isActive ? '#FFFFFF' : secondary)};
    font-size: 8px;
    opacity: ${props => (props.collapseMenu ? 0 : 1)};
  }

  &:hover {
    svg {
      opacity: 1 !important;
      color: #ffffff;
    }

    span {
      opacity: 1 !important;
      color: #ffffff;
    }
  }
`;

interface IContentProps {
  collapseMenu: boolean;
}

export const Content = styled.div<IContentProps>`
  position: relative;
  top: -420px;
  left: ${props => (props.collapseMenu ? '70px' : '120px')};
  width: calc(100% - 140px);
  transition: all 0.2s ease-in;
`;

export const Title = styled.h3`
  color: ${props => props.theme.interface6};
`;

interface IPrimaryProps {
  primary: any;
}

export const LittleButton = styled(Button)<IPrimaryProps>`
  font-size: 6px;
  background: ${props => props.primary};
  border-radius: 3px;

  &:hover {
    background: ${props => darken(0.1, props.primary)};
  }
`;

export const ListingHeader = styled.div`
  text-transform: uppercase;
  padding: 0px 12px;
  margin-bottom: 10px;

  p {
    text-align: start;
    color: ${props => props.theme.interface4};
    font-size: 6px;
  }
`;

export const Card = styled.div<IPrimaryProps>`
  cursor: pointer;
  border: 1px solid transparent;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 6px;

  display: flex;
  align-items: center;
  padding: 10px;

  p {
    font-size: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border: 1px solid ${props => props.primary};
  }
`;
