import styled from 'styled-components';

export const P = styled.p`
  font-size: 15px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: center;

  & + span {
    display: block;
    color: ${props => props.theme.success};
    text-align: center;
    font-size: 25px;
    margin: 0 auto;
    margin-bottom: 10px;
    font-weight: bold;
    background: ${props => props.theme.interface2};
    border-radius: 5px;
    border: 2px solid ${props => props.theme.interface4};
    padding: 4px 0;
  }
`;
