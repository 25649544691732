import history from 'services/history';
import api from 'services/api';
import { getTicketsService, updateTicketService } from 'services/ticket';

type Props = {
  type?: string;
  payload: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

const mapPriorityStatus = (priority: number) => {
  switch (priority) {
    case 0:
      return undefined;
    case 1:
      return false;
    case 2:
      return true;
    default:
      true;
  }
};

export const getTickets = async (
  dispatch: ({ type, payload }: Props) => void,
  page: string | number,
  search: any,
  status: number,
  filter,
  user,
  order,
  ctrl,
) => {
  setLoading(dispatch, true);
  try {
    const { data } = await getTicketsService(
      {
        ...search,
        status,
        filter,
        page,
        [`${order?.column}_order`]: mapPriorityStatus(order?.value),
      },
      ctrl,
    );

    dispatch({
      type: 'GET_TICKETS',
      payload: data,
    });
    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const updateTicket = async (
  dispatch: ({ type, payload }: Props) => void,
  field: string,
  value,
  id: number | string,
  ticket,
) => {
  try {
    const { data } = await updateTicketService(
      id,
      ticket || {
        [field]: value,
      },
    );

    dispatch({
      type: 'UPDATE_TICKET',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_TICKET_ERROR',
      payload: e?.response?.data,
    });
  }
};

export const setRedAlert = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    const { data } = await api.patch(`v3/ticket/${id}/redalert/`);

    dispatch({
      type: 'SET_RED_ALERT',
      payload: { id, value: data?.red_alert },
    });
  } catch (e) {
    dispatch({
      type: 'SET_RED_ALERT_ERROR',
      payload: e?.response?.data,
    });
  }
};
