/* eslint-disable prettier/prettier */
import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { createPlanService } from 'services/plans';
import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import {
  FormInput,
  Label,
  Field,
  FormSelect,
  Form,
  Checkbox,
} from 'components/Input';
import { Hover, HoverText } from 'components/Hover';
import { Scope } from '@rocketseat/unform';
import { ThemeContext } from 'styled-components';

import { toast } from 'react-toastify';

const schema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  category: Yup.number()
    .typeError('Escolha uma categoria válido')
    .required('Este campo é obrigatório'),
  base_price: Yup.object().shape({
    amount: Yup.number()
      .typeError('Insira um valor válido')
      .required('Este campo é obrigatório'),
    currency: Yup.string().default('BRL'),
  }),
  cloud_size: Yup.number()
    .typeError('Insira um valor válido')
    .positive('Valor deve ser positivo'),
  automations: Yup.object().shape({
    default_plan_type: Yup.number(),
  }),
});

const NewPlanModal = ({ plans, show, setShow, basePlans, setPlans }) => {
  const [category, setCategory] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [freeTrial, setFreeTrial] = useState(false);
  const [basePlan, setBasePlan] = useState(null);
  const [basePrice, setBasePrice] = useState(null);
  const [loading, setLoading] = useState(false);

  const theme = useContext(ThemeContext);

  const createPlan = async (data, { resetForm }) => {
    setLoading(true);
    const payload = {
      ...data,
      is_private: isPrivate,
    };

    if (!freeTrial) {
      delete payload.automations;
    } else {
      payload.base_price.amount = 0;
    }

    try {
      const { data } = await createPlanService(payload);

      setPlans([data, ...plans]);
      resetForm();
      toast.success('Plano criado com sucesso');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.response.data[Object.keys(e.response.data)[0]][0]);
    }

    if (!loading) {
      setShow();
      setFreeTrial(false);
    }
  };

  const basePlansOptions = basePlans?.map(p => ({
    id: p.id,
    title: `${p.name} | ${p.cores} vCPU | ${p.memory} RAM | ${p.disk} (${
      p.price.currency === 'BRL' ? 'R$ ' : '$ '
    } ${p.price.amount})`,
  }));

  const plansOptions = plans?.plans
    ?.filter(p => p.slug.includes('1g') && !p.is_individual)
    .map(p => ({
      id: p.id,
      title: `${p.name}`,
    }));

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Novo plano</Title>
          <Subtitle>Crie um plano personalizado para seus clientes.</Subtitle>
        </Text>
      </Header>

      <Form schema={schema} onSubmit={createPlan}>
        <Content>
          <Field>
            <Label>Selecione uma categoria de plano</Label>
            {basePlansOptions && (
              <FormSelect
                placeholder="Selecione uma categoria"
                height="40px"
                name="category"
                block="true"
                options={[
                  {
                    id: 1,
                    title: 'Cloud',
                  },
                  {
                    id: 9,
                    title: 'Site compartilhado',
                  },
                  {
                    id: 10,
                    title: 'Email compartilhado',
                  },
                ]}
                onChange={e => {
                  setCategory(Number(e.target.value));
                  if (e.target.value !== 1) {
                    setBasePlan(null);
                  }
                }}
              />
            )}
          </Field>
          {category && category === 1 && (
            <Field>
              <Label>Selecionar plano base</Label>
              {basePlansOptions && (
                <FormSelect
                  placeholder="Selecione um plano base."
                  height="40px"
                  name="cloud_size"
                  block="true"
                  options={basePlansOptions}
                  onChange={e => {
                    const basePlan = basePlans.find(
                      p => p.id === Number(e.target.value),
                    );
                    setBasePlan(basePlan);
                    setFreeTrial(basePlan.slug.includes('partner-free-trial'));
                  }}
                />
              )}
            </Field>
          )}
          {category && category === 1 && freeTrial && (
            <Field>
              <Label>Selecionar plano padrão</Label>
              {plans && (
                <Scope path="automations">
                  <FormSelect
                    placeholder="Selecione um plano padrão."
                    height="40px"
                    name="default_plan_type"
                    block="true"
                    options={plansOptions}
                  />
                </Scope>
              )}
            </Field>
          )}
          <Field>
            <Label>Nome</Label>
            <FormInput name="name" block="true" />
          </Field>
          <Field>
            <Label>
              {category && category === 1
                ? 'Margem Adicional no Plano'
                : 'Valor do Plano'}
            </Label>
            <Scope path="base_price">
              <FormInput
                disabled={freeTrial}
                background={freeTrial}
                value={freeTrial ? '0' : basePrice}
                onChange={e => {
                  setBasePrice(e.target.value);
                }}
                min="0"
                step="any"
                type="number"
                name="amount"
                block="true"
              />
            </Scope>
          </Field>

          <Field>
            <Label>
              Privado{' '}
              <Hover>
                ?{' '}
                <HoverText>
                  Os planos privados não apareceram no painel principal, ou
                  seja, não poderá ser contratado diretamente pelo cliente final
                </HoverText>
              </Hover>
            </Label>
            <Checkbox
              onChange={e => setIsPrivate(e.target.checked)}
              value={isPrivate}
              checked={isPrivate}
            />
          </Field>

          <Field>
            <Label>Valor total</Label>
            <CurrencyFormat
              style={{
                color: theme.label,
                fontSize: '20px',
              }}
              value={
                basePrice && basePlan
                  ? Number(basePrice) + Number(basePlan?.price?.amount)
                  : !basePlan
                  ? Number(basePrice)
                  : !basePrice
                  ? Number(basePlan?.price?.amount)
                  : '0'
              }
              displayType="text"
              fixedDecimalScale
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              prefix="R$ "
            />
          </Field>
        </Content>
        <Footer>
          <Button icon type="submit">
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Adicionar'}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default NewPlanModal;
