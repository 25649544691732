// eslint-disable-next-line react-hooks/exhaustive-deps
import {
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';

import { useAuth, useDocTitle, useLayout } from 'hooks';

import api from 'services/api';

import {
  Card as ListingCard,
  CardContent,
  ListingHeader,
  Listing,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  Ordering,
} from 'components/Listing/styles';

import CurrencyFormat from 'react-currency-format';
import { ThemeContext } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import moment from 'moment';

import history from 'services/history';

import CustomAnimatedList from 'components/CustomAnimatedList';
import { Field, Input, Label } from '@cloudez/cloudez-design-system';
import { Header, Title } from 'components/Common';
import { NoResults } from 'components/NoResults';
import Pagination from 'components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { getBankAccountService } from 'services/common';
import EmptyListing from 'components/EmptyListing';
import {
  TransferListingProvider,
  TransferListingContext,
} from './_context/state';

import { Card, CardHeader, CardBody, Status } from './styles';

import TransferPlaceholder from './Placeholder';
import WithdrawModal from './WithdrawModal';

const Transfers = () => {
  useDocTitle('Extrato - Cloudez Partners');
  const theme = useLayout();
  const { company } = useAuth();

  const { loading, transfers, count, previous, next, current, getTransfers } =
    useContext(TransferListingContext);

  const [companyBilling, setCompanyBilling] = useState(null);
  const [companyBank, setCompanyBank] = useState(null);
  const [_initial, setInitial] = useState(null);
  const [_final, setFinal] = useState(null);
  const [orderValue, setOrderValue] = useState<any>();
  const [withdrawModal, setWithdrawModal] = useState<any>(false);
  const [loadingCSV, setLoadingCSV] = useState(false);

  useEffect(() => {
    async function getCompanyBilling() {
      try {
        const { data } = await api.get(`/v3/company/billing/${company.code}/`);
        setCompanyBilling(data);
      } catch (e) {
        console.log(e);
      }
    }

    async function getCompanyBank() {
      try {
        const { data } = await getBankAccountService(company.code);
        setCompanyBank(data);
      } catch (e) {
        console.log(e);
      }
    }

    getCompanyBilling();
    getCompanyBank();
  }, [company.code]);

  const { page = 1 } = queryString.parse(history.location.search);

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getTransfersCallback = useCallback(
    (page, initial, final, order) => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      getTransfers(page, initial, final, order, abortControllerRef.current);
    },
    [abortControllerRef],
  );

  const getTransfersCSVCallback = useCallback(async (initial, final) => {
    setLoadingCSV(true);

    try {
      const { data } = await api.get('/v3/transfer/transfer_csv/', {
        params: {
          from: initial,
          to: final,
        },
        responseType: 'blob',
      });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(data);
      a.download = `transfer_csv.xlsx`;
      a.click();
      setLoadingCSV(false);
    } catch (e) {
      setLoadingCSV(false);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getTransfersCallback(page, _initial, _final, orderValue);
  }, [_initial, _final]);

  const order = column => {
    const order = {
      value: orderValue?.value === 2 ? 1 : orderValue?.value === 1 ? 0 : 2,
      column,
    };
    setOrderValue(order);
    getTransfersCallback(1, _initial, _final, order);
  };

  const getType = type => {
    switch (type) {
      case 'Withdraw':
        return 'Saque';
      case 'Applied Credit':
        return 'Crédito Aplicado';
      default:
        return type;
    }
  };

  const warningMessage = useMemo<any>(() => {
    if (!companyBilling) return '';

    switch (companyBilling.split_account_status) {
      case 'active': {
        return {
          comp: <Status active>Validado</Status>,
          message: (
            <span
              style={{
                color: '#2253f5',
                fontWeight: 400,
                fontSize: '12px',
                textDecoration: 'underline',
              }}
              onClick={() => setWithdrawModal(true)}
            >
              Solicitar saque
            </span>
          ),
        };
      }
      case 'accepted': {
        return {
          comp: <Status active>Validado</Status>,
          message: (
            <span
              style={{
                maxWidth: 180,
                color: '#2253f5',
                textDecoration: 'underline',
                fontWeight: 400,
                fontSize: '12px',
              }}
              onClick={() => setWithdrawModal(true)}
            >
              Solicitar saque
            </span>
          ),
        };
      }
      case 'pending': {
        return {
          comp: <Status pending>Validando</Status>,
          message: (
            <span
              style={{
                maxWidth: 180,
                fontWeight: 400,
                fontSize: '12px',
              }}
            >
              Aguarde. Sistema está validando seus dados bancários.
            </span>
          ),
        };
      }
      case 'rejected': {
        return {
          comp: <Status rejected>Inválido</Status>,
          message: (
            <span
              style={{
                maxWidth: 180,
                fontWeight: 400,
                fontSize: '12px',
              }}
            >
              <Link
                to="/company?tab=2"
                style={{
                  color: '#2253f5',
                  textDecoration: 'underline',
                }}
              >
                Revise ou atualize
              </Link>{' '}
              seus dados bancários.
            </span>
          ),
        };
      }
      case 'created': {
        return {
          comp: <Status incomplete>Incompleto</Status>,
          message: (
            <span
              style={{
                maxWidth: 180,
                fontWeight: 400,
                fontSize: '12px',
              }}
            >
              Por favor,{' '}
              <Link
                to="/company?tab=2"
                style={{
                  color: '#2253f5',
                  textDecoration: 'underline',
                }}
              >
                adicione
              </Link>{' '}
              os seus dados bancários.
            </span>
          ),
        };
      }
      default: {
        return '';
      }
    }
  }, [companyBilling, companyBank]);

  return (
    <>
      {withdrawModal && (
        <WithdrawModal
          companyBank={companyBank}
          companyBilling={companyBilling}
          show={withdrawModal}
          setShow={() => setWithdrawModal(false)}
        />
      )}
      <Header>
        <Title>Extrato</Title>
        {/* <Button onClick={() => history.push('/extract/create')}>
          Solicitar saque
          <FontAwesomeIcon icon={faPlusSquare} />
        </Button> */}
      </Header>

      <Row
        style={{
          marginBottom: '20px',
        }}
      >
        <Col xl="6">
          <Card
            style={{
              padding: '6px 0 0 6px',
            }}
          >
            <CardHeader>Filtro por período</CardHeader>
            <CardBody>
              <Field
                style={{
                  marginRight: 6,
                }}
              >
                <Label>de</Label>
                <Input
                  type="date"
                  block
                  value={_initial}
                  onChange={e => {
                    setInitial(e.target.value);
                  }}
                />
              </Field>
              <Field>
                <Label>até</Label>
                <Input
                  type="date"
                  block
                  value={_final}
                  onChange={e => {
                    setFinal(e.target.value);
                  }}
                />
              </Field>
              <Field
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 700,
                  fontSize: 16,
                  cursor: 'pointer',
                  color: '#2253f5',
                }}
              >
                {loadingCSV ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{
                      position: 'unset',
                      fontSize: 24,
                    }}
                  />
                ) : (
                  <a
                    onClick={async () => {
                      await getTransfersCSVCallback(_initial, _final);
                    }}
                  >
                    Exportar CSV
                  </a>
                )}
              </Field>{' '}
            </CardBody>
          </Card>
        </Col>
        <Col xl="2" />
        <Col xl="4">
          <Card
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontWeight: 700,
                fontSize: '14px',
                color: theme.interface5,
              }}
            >
              Valor do crédito
              <CurrencyFormat
                style={{
                  color: theme.successNew,
                  fontWeight: 700,
                  fontSize: '16px',
                  margin: '8px 0',
                }}
                value={companyBilling?.balance?.amount}
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                prefix={
                  companyBilling?.balance?.currency === 'BRL' ? 'R$ ' : '$ '
                }
              />
              {warningMessage.message}
            </div>
            <div
              style={{
                width: 1,
                height: '50%',
                background: theme.interface3,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontWeight: 700,
                fontSize: '14px',
                color: theme.interface5,
              }}
            >
              Dados Bancários
              {warningMessage.comp}
            </div>
          </Card>
        </Col>
      </Row>

      {loading ? (
        <TransferPlaceholder />
      ) : transfers?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col xs="2" sm="1">
                <p
                  onClick={() => {
                    order('invoice');
                  }}
                >
                  Fatura
                  <Ordering
                    status={
                      orderValue?.column === 'invoice' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>
              </Col>

              <Col xs="1" className="d-none d-xl-flex">
                <p
                  onClick={() => {
                    order('type');
                  }}
                >
                  TRANSAÇÃO
                  <Ordering
                    status={
                      orderValue?.column === 'type' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>
              </Col>

              <Col xs="10" md="6" xl="4">
                <p
                  onClick={() => {
                    order('responsible');
                  }}
                >
                  Responsável
                  <Ordering
                    status={
                      orderValue?.column === 'responsible'
                        ? orderValue?.value
                        : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p
                  onClick={() => {
                    order('value');
                  }}
                >
                  LUCRO
                  <Ordering
                    status={
                      orderValue?.column === 'value' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col className="d-none d-xl-flex">
                <p>taxa</p>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p
                  onClick={() => {
                    order('invoice_total');
                  }}
                >
                  valor da fatura
                  <Ordering
                    status={
                      orderValue?.column === 'invoice_total'
                        ? orderValue?.value
                        : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col className="d-none d-xl-flex">
                <p
                  onClick={() => {
                    order('created_at');
                  }}
                >
                  realizado em
                  <Ordering
                    status={
                      orderValue?.column === 'created_at'
                        ? orderValue?.value
                        : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>{' '}
              </Col>
            </Row>
          </ListingHeader>
          <Listing>
            <CustomAnimatedList>
              {transfers?.map(t => {
                const createdAt = moment(t.created_at).format('DD/MM/YYYY');

                return (
                  <ListingItem
                    key={t.id}
                    onClick={e => {
                      e.stopPropagation();
                      if (t.invoice) {
                        window.open(`/invoices/${t.invoice}`);
                      }
                    }}
                  >
                    <ListingCard>
                      <CardContent>
                        <Col xs="2" sm="1">
                          <ApplicationValue>
                            <ApplicationName
                              style={{
                                marginLeft: 0,
                                color: theme.label,
                              }}
                            >
                              <p>{t.invoice}</p>
                            </ApplicationName>
                          </ApplicationValue>
                        </Col>

                        <Col xl="1" className="d-none d-xl-flex">
                          <ApplicationValue
                            style={{
                              fontWeight: 'normal',
                            }}
                          >
                            <p>{getType(t.type)}</p>
                          </ApplicationValue>
                        </Col>

                        <Col xs="10" md="6" xl="4">
                          <ApplicationValue>
                            <ApplicationName
                              style={{
                                marginLeft: 0,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                              }}
                            >
                              <span>{t.responsible.name}</span>
                              <span>{t.responsible.email}</span>
                            </ApplicationName>
                          </ApplicationValue>
                        </Col>

                        <Col className="d-none d-sm-none d-md-flex">
                          <ApplicationValue>
                            <ApplicationName
                              style={{
                                marginLeft: 0,
                              }}
                            >
                              <p>
                                <CurrencyFormat
                                  value={t.value?.amount}
                                  displayType="text"
                                  fixedDecimalScale
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  prefix={
                                    t.value?.currency === 'BRL' ? 'R$ ' : '$ '
                                  }
                                />
                              </p>
                            </ApplicationName>
                          </ApplicationValue>
                        </Col>

                        <Col className="d-none d-xl-flex">
                          <ApplicationValue>
                            <ApplicationName
                              style={{
                                marginLeft: 0,
                              }}
                            >
                              <p>
                                <CurrencyFormat
                                  value={t.operational_cost?.amount}
                                  displayType="text"
                                  fixedDecimalScale
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  prefix={
                                    t.operational_cost?.currency === 'BRL'
                                      ? 'R$ '
                                      : '$ '
                                  }
                                />
                              </p>
                            </ApplicationName>
                          </ApplicationValue>
                        </Col>

                        <Col className="d-none d-sm-none d-md-flex">
                          <ApplicationValue>
                            <ApplicationName
                              style={{
                                marginLeft: 0,
                              }}
                            >
                              <p>
                                <CurrencyFormat
                                  value={t.invoice_total?.amount}
                                  displayType="text"
                                  fixedDecimalScale
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  prefix={
                                    t.invoice_total?.currency === 'BRL'
                                      ? 'R$ '
                                      : '$ '
                                  }
                                />
                              </p>
                            </ApplicationName>
                          </ApplicationValue>
                        </Col>

                        <Col className="d-none d-xl-flex">
                          <ApplicationValue style={{ fontWeight: 'normal' }}>
                            <p>{createdAt}</p>
                          </ApplicationValue>
                        </Col>
                      </CardContent>
                    </ListingCard>
                  </ListingItem>
                );
              })}
            </CustomAnimatedList>
          </Listing>
          <Pagination
            onChangePage={getTransfersCallback}
            pageSize="10"
            count={count}
            previous={previous}
            next={next}
            current={current}
            order={orderValue}
          />
        </>
      ) : (
        <EmptyListing>
          <p>Você ainda não possui registros para serem exibidos.</p>
        </EmptyListing>
      )}
    </>
  );
};

export default props => (
  <TransferListingProvider>
    <Transfers {...props} />
  </TransferListingProvider>
);
