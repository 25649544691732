import styled, { css } from 'styled-components';

export const CardContainerFirst = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

interface ICardProps {
  height?: any;
}

export const Card = styled.div<ICardProps>`
  height: ${props => props.height || 'auto'};
  width: 100%;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardRelatorio = styled.div`
  width: 100%;
  height: 350px;
  padding: 0 1rem;
  background: ${props => props.theme.interface1};
  border-radius: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CardTable = styled.div`
  width: 100%;
  height: auto;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  padding: 1.55rem 1rem;

  @media (max-width: 768px) {
    height: auto;
    padding: 1rem 0;
  }
`;

export const CardInvoice = styled.div`
  width: 100%;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  height: auto;
  padding: 0.5rem 0.6rem;

  @media (max-width: 768px) {
    height: auto;
    padding: 0.5rem 0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.75rem;
  width: 100%;
  height: 40px;
  padding: 38px 12px;
  font-size: 18px;
  color: ${props => props.theme.interface5};
`;

export const CardBody = styled.div`
  width: 100%;
  height: auto;
  .title-invoice {
    color: #545c68;
    font-size: 0.875rem;
    font-weight: bold;
  }

  .title-last_days {
    color: #545c68;
    font-size: 0.875rem;
    font-weight: normal;
  }

  .title_icon {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: 0 0.6rem;

    .title-invoice,
    .title-last_days,
    .title-icon {
      font-size: 0.736rem;
    }
  }

  .paid {
    color: #29d975;

    margin-top: 2.4rem;
    font-size: 1.25rem;
    text-align: right;
    font-weight: 800;
  }

  .not-paid {
    color: #ffbf2d;

    margin-top: 2.4rem;
    font-size: 1.25rem;
    text-align: right;
    font-weight: 800;
  }

  .late {
    color: #fb264b;

    margin-top: 2.4rem;
    font-size: 1.25rem;
    text-align: right;
    font-weight: 800;
  }

  .credit {
    color: #2253f5;

    margin-top: 2.4rem;
    font-size: 1.25rem;
    text-align: right;
    font-weight: 800;
  }
`;

interface ThProps {
  responsive?: boolean;
}

export const Th = styled.th<ThProps>`
  padding: 7px;
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
  background: ${props => props.theme.interface2};
  color: ${props => props.theme.interface5};

  ${props =>
    props.responsive &&
    css`
      @media (max-width: 768px) {
        display: none;
      }
    `}
`;

interface ITrProps {
  name?: string;
}

export const Tr = styled.tr<ITrProps>`
  ${props =>
    props.name === 'Total' &&
    css`
      background: linear-gradient(278.18deg, #2253f5 0%, #36a0fc 99.04%);
      ${TdTitle} {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      ${TdFinal} {
        color: #fff;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      ${TdContent} {
        color: #fff;
      }
    `}
`;

export const TdTitle = styled.td`
  padding: 10px 12px;
  font-size: 1rem;
  font-weight: bold;
  color: ${props => props.theme.interface5};
`;

export const TdContent = styled.td<ThProps>`
  padding: 10px 12px;
  font-size: 1rem;
  color: ${props => props.theme.interface5};
  text-align: left;

  ${props =>
    props.responsive &&
    css`
      @media (max-width: 768px) {
        display: none;
      }
    `}
`;

export const TdFinal = styled.td`
  padding: 10px 12px;
  font-size: 1rem;
  color: ${props => props.theme.interface5};
  text-align: left;
`;
