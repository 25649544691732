class WebSocketService {
  static instance = null;

  callbacks = {};
  socketRef: any;

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }

    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connectNotifications(uuid) {
    const path = `${process.env.REACT_APP_API_WEBSOCKET_URL}notifications`;
    this.socketRef = new WebSocket(`${path}?uuid=${uuid}`);

    this.socketRef.onopen = () => {};

    this.socketRef.onmessage = ({ data }) => {
      const { message } = JSON.parse(data);
    };

    this.socketRef.onerror = () => {};

    this.socketRef.onclose = () => {
      this.connectNotifications(uuid);
    };
  }

  connectTicket(uuid: string, id: string | number, cb?: any) {
    const path = `${process.env.REACT_APP_API_WEBSOCKET_URL}ticket`;

    this.socketRef = new WebSocket(`${path}?uuid=${uuid}&id=${id}`);

    this.socketRef.onopen = () => {};

    this.socketRef.onmessage = ({ data }) => {
      const { message } = JSON.parse(data);
      cb({ ...message, isNew: true });
    };

    this.socketRef.onerror = () => {};

    this.socketRef.onclose = () => {
      this.connectTicket(uuid, id);
    };
  }

  connectChat(uuid, cb) {
    const path = `${process.env.REACT_APP_API_WEBSOCKET_URL}chat`;

    if (this.socketRef) this.socketRef.close();

    this.socketRef = new WebSocket(`${path}?uuid=${uuid}`);

    this.socketRef.onopen = () => {
      console.log('connect');
    };

    this.socketRef.onmessage = cb;

    this.socketRef.onclose = () => {
      console.log('close');
      // this.connect(uuid, cb);
    };
  }

  send(data) {
    this.socketRef.send(data);
  }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
