import styled from 'styled-components';
import { File } from './Message/styles';

export const Header = styled.div`
  grid-area: header;
  width: 100%;
  height: 74px;

  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.interface3};
`;

export const Client = styled.div`
  padding: 14px 24px;
  width: 60%;
  border-right: 1px solid ${props => props.theme.interface3};

  display: flex;
  align-items: center;

  img {
    width: 36px;

    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    span.owner {
      font-weight: bold;
      font-size: 17px;
      color: ${props => props.theme.interface7};
    }
    span.summary {
      font-size: 12px;
      color: ${props => props.theme.interface5};
    }
  }

  @media (max-width: 768px) {
    width: 70%;
    overflow: hidden;
    padding: 7px 12px;
  }
`;

export const Messages = styled.div`
  grid-area: messages;

  position: relative;

  max-height: 100%;
  overflow-y: auto;
  padding: 20px 40px;
  height: 100%;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }

  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  grid-area: footer;

  border-top: 1px solid ${props => props.theme.interface3};
  background: ${props => props.theme.interface1};

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;

  .textarea {
    position: relative;

    width: 100%;
    textarea {
      height: 100%;
      background: ${props => props.theme.interface2};
    }

    svg {
      position: absolute;
      top: calc(50% - 10px);
      right: 15px;
      color: ${props => props.theme.brand_primary_color};

      cursor: pointer;
    }
  }
`;

export const Actions = styled.div`
  padding: 14px 24px;
  width: 40%;

  position: relative;

  display: flex;
  justify-content: space-around;

  svg {
    font-size: 18px;
    color: ${props => props.theme.interface4};
    cursor: pointer;
  }

  svg.close {
    color: ${props => props.theme.error};
  }
`;

export const Typing = styled.div`
  width: auto;
  padding: 8px 0px;
  position: fixed;
  bottom: 0;

  & > p {
    display: inline;
    font-size: 13px;
    color: ${props => props.theme.interface5};
  }

  &::after {
    content: 'está digitando..';
    display: inline;
    font-size: 13px;
    color: ${props => props.theme.interface5};
  }
`;

export const Files = styled.div`
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 160px;
  position: absolute;
  right: 20px;
  top: 60px;
  background: ${props => props.theme.interface1};
  box-shadow: 0px 3px 15px #64728c4d;
  z-index: 2;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${File} {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;

    p {
      width: 100px;
      margin: 0;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 50px !important;
      }
    }
  }
`;

export const NoTicket = styled.div`
  grid-area: messages;
  text-align: center;
  color: ${props => props.theme.interface7};
  padding-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

interface IIconProps {
  hasFile: boolean;
}

export const Icons = styled.div<IIconProps>`
  display: flex;
  margin-top: 10px;
  width: 100%;
  color: ${props =>
    props.hasFile ? props.theme.primary : props.theme.interface4};

  svg {
    cursor: pointer;
    margin-right: 10px;
  }
`;

export const MobileMenu = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 160px;
  position: absolute;
  right: 20px;
  top: 60px;
  background: ${props => props.theme.interface1};
  box-shadow: 0px 3px 15px #64728c4d;
  z-index: 2;

  padding: 10px;

  li {
    padding: 10px 0;
    display: flex;

    div.icon {
      width: 30px;

      svg {
        margin-right: 5px;
        width: 25px;
      }
    }
  }

  span {
    color: ${props => props.theme.interface5};
  }
`;
