import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { createPagination } from 'utils/createPagination';
import Pagination from 'components/Pagination';
import { getDashboardBlockeds, getDashboardLates } from 'services/dashboard';
import { Select } from './styles';
import {
  CardRelatorio,
  CardHeader,
  CardBody,
  Th,
  Tr,
  TdContent,
} from '../styles';
import DashboardInvoice from './Placeholder';

const Invoices = () => {
  const [blocked, setBlocked] = useState(null as any);
  const [late, setLate] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [newPage, setNewPage] = useState(1);
  const [select, setIsSelect] = useState('1');

  const getBlocked = async page => {
    setLoading(true);
    const { data } = await getDashboardBlockeds({
      page,
      page_size: 5,
    });

    const pagination = createPagination(data, 5);

    setBlocked({
      blocked: data.results,
      ...pagination,
      action: setNewPage,
    });
    setLoading(false);
  };

  const getLate = async page => {
    setLoading(true);
    const { data } = await getDashboardLates({
      page,
      page_size: 5,
    });

    const pagination = createPagination(data, 5);

    setLate({
      late: data.results,
      ...pagination,
      action: setNewPage,
    });
    setLoading(false);
  };

  useEffect(() => {
    getBlocked(newPage);
    getLate(newPage);
  }, []);

  return (
    <>
      {loading ? (
        <DashboardInvoice />
      ) : (
        <>
          <Col xl="12">
            <CardRelatorio>
              <CardHeader>
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  Relatórios
                </span>
                <Select
                  value={select}
                  onChange={e => setIsSelect(e.target.value)}
                >
                  <option value="1">Clientes com fatura em atraso</option>
                  <option value="2">
                    Clientes suspensos por falta de pagamento
                  </option>
                </Select>
              </CardHeader>
              <CardBody>
                <table
                  cellSpacing="0"
                  style={{
                    width: '100%',
                  }}
                >
                  <>
                    {select === '1' && (
                      <>
                        {late ? (
                          <>
                            <thead>
                              <tr>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Nome
                                </Th>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Email do Cliente
                                </Th>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Número da Fatura
                                </Th>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Valor da Fatura
                                </Th>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Data Vencimento
                                </Th>
                              </tr>
                            </thead>
                            <tbody>
                              {late?.late.map(l => (
                                <tr key={l.id}>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                      maxWidth: '85px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {l.name}
                                  </TdContent>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                      maxWidth: '160px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {l.email}
                                  </TdContent>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    {l.invoice}
                                  </TdContent>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    <CurrencyFormat
                                      value={l?.total?.amount}
                                      displayType="text"
                                      fixedDecimalScale
                                      decimalScale={2}
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      prefix={
                                        l?.total?.currency === 'BRL'
                                          ? 'R$ '
                                          : '$ '
                                      }
                                    />
                                  </TdContent>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    {moment(l.expired_at).format('DD/MM/YYYY')}
                                  </TdContent>
                                </tr>
                              ))}

                              <Pagination
                                style={{
                                  position: 'absolute',
                                  bottom: '10px',
                                }}
                                onChangePage={getLate}
                                count={late?.count}
                                previous={late?.previous}
                                next={late?.next}
                                current={late?.current}
                                pageSize="5"
                              />
                            </tbody>
                          </>
                        ) : (
                          <h2
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '230px',
                            }}
                          >
                            Não há dados para serem mostrados
                          </h2>
                        )}
                      </>
                    )}

                    {select === '2' && (
                      <>
                        {blocked ? (
                          <>
                            <thead>
                              <tr>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Nome
                                </Th>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Email do Cliente
                                </Th>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Dia da Suspensão
                                </Th>
                                <Th
                                  style={{
                                    background: '#fff',
                                    borderBottom: '1px solid #cfd3de',
                                  }}
                                >
                                  Número da Fatura
                                </Th>
                              </tr>
                            </thead>

                            <tbody style={{ fontWeight: 'normal' }}>
                              {blocked?.blocked.map(b => (
                                <tr key={b.id}>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                      maxWidth: '75px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {b.name}
                                  </TdContent>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                      maxWidth: '160px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {b.email}
                                  </TdContent>
                                  <TdContent style={{ fontWeight: 'normal' }}>
                                    {moment(b.expired_at).format('DD/MM/YYYY')}
                                  </TdContent>
                                  <TdContent
                                    style={{
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    {b.invoice}
                                  </TdContent>
                                </tr>
                              ))}

                              <Pagination
                                style={{
                                  position: 'absolute',
                                  bottom: '10px',
                                }}
                                onChangePage={getBlocked}
                                count={blocked?.count}
                                previous={blocked?.previous}
                                next={blocked?.next}
                                current={blocked?.current}
                                pageSize="5"
                              />
                            </tbody>
                          </>
                        ) : (
                          <h2
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '230px',
                            }}
                          >
                            Não há dados para serem mostrados
                          </h2>
                        )}
                      </>
                    )}
                  </>
                </table>
              </CardBody>
            </CardRelatorio>
          </Col>
        </>
      )}
    </>
  );
};

export default Invoices;
