import { useState, useEffect, useMemo, useCallback } from 'react';

import { Button } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import api from 'services/api';
import history from 'services/history';
import { toast } from 'react-toastify';
import { useAuth } from 'hooks';
import { createPagination } from 'utils/createPagination';
import Plans from './Plans';
import Info from './Info';
import { Header, Wrapper } from './styles';
import ConfirmModal from './ConfirmModal';
import SharedPlan from './Plans/SharedPlan';
import NewPlans from './NewPlans';
import NewConfirmModal from './NewConfirmModal';

const ClientCreate = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(match.params.id ? 2 : 1);
  const { company } = useAuth();

  const [confirmModal, setConfirmModal] = useState(false);
  const [lifespan, setLifespan] = useState('1');
  const [payload, setPayload] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedCloud, setSelectedCloud] = useState(null);
  const [selectedNodeappType, setSelectedNodeappType] = useState(null);
  const [selectedShareResource, setSelectedShareResource] = useState(null);
  const [domain, setDomain] = useState('');
  const [tab, setTab] = useState(1);
  const [_user, _setUser] = useState(null);
  const [plans, setPlans] = useState(null);
  const [basePlans, setBasePlans] = useState(null);
  const [planLoading, setPlanLoading] = useState(false);
  const [isTurbo, setIsTurbo] = useState(false);

  const page_size =
    company.provider.includes('partner-g3') ||
    company.provider.includes('partner-g4') ||
    company?.provider?.includes('dpg-custom')
      ? 8
      : 11;

  useEffect(() => {
    async function getUser() {
      try {
        const { data } = await api.get(
          `/v3/company/user/${match.params.id || payload.id}/`,
        );
        _setUser(data);
      } catch (e) {
        console.log(e);
      }
    }

    if (match?.params?.id || payload?.id) getUser();
    // eslint-disable-next-line
  }, []);

  const getPlans = useCallback(async (page = 1) => {
    setPlanLoading(true);

    const { data } = await api.get(
      `/${
        company.provider.includes('partner-g3') ||
        company.provider.includes('partner-g4') ||
        company?.provider?.includes('dpg-custom')
          ? 'v3'
          : 'v2'
      }/company/plan/type/`,
      {
        params: {
          page_size,
          page,
        },
      },
    );

    const basePlansResponse = await api.get('/v3/cloud/size/');
    const pagination = createPagination(data, page_size);

    setPlans({ plans: data.results, ...pagination });
    setBasePlans(basePlansResponse.data);
    setPlanLoading(false);
  }, []);

  useEffect(() => {
    if (!plans) getPlans();
    // eslint-disable-next-line
  }, []);

  const createClientPlan = async () => {
    setLoading(true);

    interface DataProperties {
      user: string;
      plan_type: string;
      lifespan_months: string;
      nodeapp_id?: string;
      zoneapp_id?: string;
      nodeapp_type?: string;
      domain?: string;
      is_turbo?: boolean;
    }
    let data: DataProperties = {
      user: match.params.id || payload.id,
      plan_type: selectedPlan.id,
      lifespan_months: lifespan,
    };

    if (
      company.provider.includes('partner-g3') ||
      company.provider.includes('partner-g4') ||
      company?.provider?.includes('dpg-custom')
    ) {
      if (selectedPlan.has_turbo && !selectedPlan.has_basic) {
        data.is_turbo = true;
      } else {
        data.is_turbo = isTurbo;
      }
    }

    if (selectedPlan.category === 9) {
      data = {
        ...data,
        nodeapp_id: selectedShareResource?.id,
      };
    } else if (selectedPlan.category === 10) {
      data = {
        ...data,
        zoneapp_id: selectedShareResource?.id,
      };
    } else if (selectedPlan.category === 1) {
      if (selectedNodeappType && !domain) {
        toast.error('Digite um domínio');
        setLoading(false);
        setConfirmModal(false);
        return;
      }
      if (domain && selectedNodeappType) {
        data = {
          ...data,
          nodeapp_type: selectedNodeappType.id,
          domain: domain !== '' ? domain : null,
        };
      }
    }

    try {
      await api.post(
        `/${
          company.provider.includes('partner-g3') ||
          company.provider.includes('partner-g4') ||
          company?.provider?.includes('dpg-custom')
            ? 'v3'
            : 'v2'
        }/company/user/setup/`,
        data,
      );

      setLoading(false);
      history.push(`/customers/${match.params.id || payload.id}`);
      toast.success('Plano adicionado.');
    } catch (e) {
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);

      setLoading(false);
    }
  };

  const stageTitle = useMemo(
    () =>
      stage === 1
        ? 'Criação de cliente'
        : stage === 2
        ? 'Seleção de plano'
        : null,
    [stage],
  );

  return (
    <>
      {!company.provider.includes('partner-g3') &&
        !company.provider.includes('partner-g4') &&
        !company?.provider?.includes('dpg-custom') &&
        confirmModal && (
          <ConfirmModal
            basePlans={basePlans}
            loading={loading}
            lifespan={lifespan}
            setLifespan={setLifespan}
            p={selectedPlan}
            show={confirmModal}
            setShow={() => setConfirmModal(false)}
            action={createClientPlan}
            user={_user || payload}
            selectedShareResource={selectedShareResource}
          />
        )}

      {(company.provider.includes('partner-g3') ||
        company.provider.includes('partner-g4') ||
        company?.provider?.includes('dpg-custom')) &&
        confirmModal && (
          <NewConfirmModal
            loading={loading}
            lifespan={lifespan}
            setLifespan={setLifespan}
            p={selectedPlan}
            show={confirmModal}
            setShow={() => setConfirmModal(false)}
            action={createClientPlan}
            user={_user || payload}
            isTurbo={isTurbo}
            setIsTurbo={setIsTurbo}
            selectedShareResource={selectedShareResource}
          />
        )}

      <Wrapper>
        {stage <= 2 && (
          <Header>
            <h1>{stageTitle}</h1>
          </Header>
        )}

        {stage === 1 && (
          <Info
            loading={loading}
            setLoading={setLoading}
            setStage={setStage}
            stage={stage}
            setPayload={setPayload}
            // payload={payload}
          />
        )}

        {stage === 2 &&
          (company.provider.includes('partner-g3') ||
            company.provider.includes('partner-g4') ||
            company?.provider?.includes('dpg-custom')) && (
            <NewPlans
              setSelectedNodeappType={setSelectedNodeappType}
              selectedNodeappType={selectedNodeappType}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              planLoading={planLoading}
              setDomain={setDomain}
              basePlans={basePlans}
              setPlans={setPlans}
              getPlans={getPlans}
              domain={domain}
              onboard={false}
              plans={plans}
              // selectedCloud={selectedCloud}
              // setSelectedCloud={setSelectedCloud}
              // tab={tab}
              // setTab={setTab}
              // selectedShareResource={selectedShareResource}
              // setSelectedShareResource={setSelectedShareResource}
            />
          )}

        {stage === 2 &&
          !company.provider.includes('partner-g3') &&
          !company.provider.includes('partner-g4') &&
          !company?.provider?.includes('dpg-custom') && (
            <Plans
              setPlans={setPlans}
              getPlans={getPlans}
              planLoading={planLoading}
              plans={plans}
              basePlans={basePlans}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              domain={domain}
              setDomain={setDomain}
              selectedNodeappType={selectedNodeappType}
              setSelectedNodeappType={setSelectedNodeappType}
              onboard={false}
              // selectedCloud={selectedCloud}
              // setSelectedCloud={setSelectedCloud}
              // tab={tab}
              // setTab={setTab}
              // selectedShareResource={selectedShareResource}
              // setSelectedShareResource={setSelectedShareResource}
            />
          )}

        {stage === 3 &&
          (selectedPlan.category === 9 || selectedPlan.category === 10) && (
            <SharedPlan
              // setPlans={setPlans}
              // planLoading={planLoading}
              // plans={plans}
              // basePlans={basePlans}
              // setSelectedPlan={setSelectedPlan}
              // setSelectedCloud={setSelectedCloud}
              // tab={tab}
              // setTab={setTab}
              // domain={domain}
              // setDomain={setDomain}
              // selectedNodeappType={selectedNodeappType}
              // setSelectedNodeappType={setSelectedNodeappType}
              // onboard={false}
              selectedPlan={selectedPlan}
              selectedCloud={selectedCloud}
              selectedShareResource={selectedShareResource}
              setSelectedShareResource={setSelectedShareResource}
            />
          )}

        {stage === 1 ? (
          <Button
            icon={loading}
            style={{
              marginTop: '20px',
            }}
            type="submit"
            form="infoForm"
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Próximo passo'
            )}
          </Button>
        ) : (
          <Button
            icon={loading}
            style={{
              marginTop: '20px',
              alignSelf: 'flex-end',
            }}
            disabled={
              loading ||
              !selectedPlan ||
              (selectedPlan.category === 9 && tab === 2 && !domain)
            }
            onClick={() =>
              stage < 3 &&
              (selectedPlan.category === 9 || selectedPlan.category === 10)
                ? setStage(3)
                : setConfirmModal(true)
            }
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Próximo passo'
            )}
          </Button>
        )}

        {/* {stage >= 2 && stage < 5 && !match.params.id && (
          <Button
            outline
            onClick={() => setStage(stage - 1)}
            style={{
              marginTop: '20px',
            }}
          >
            Voltar
          </Button>
        )} */}
      </Wrapper>
    </>
  );
};

export default ClientCreate;
