import styled from 'styled-components';

export const SocialIcons = styled.div`
  cursor: pointer;
  margin-top: 15px;

  .social-icon-linkedin,
  .social-icon-instagram,
  .social-icon-facebook,
  .social-icon-twitter {
    color: #cfd3de;
    transition: color 0.3s ease-in-out;
  }

  &:hover .social-icon-linkedin {
    color: #2864aa;
    opacity: 0.7;
    transform: scale(1.1);
  }

  &:hover .social-icon-instagram {
    color: #e1306c;
    opacity: 0.7;
    transform: scale(1.1);
  }

  &:hover .social-icon-facebook {
    color: #0862f6;
    opacity: 0.7;
    transform: scale(1.1);
  }

  &:hover .social-icon-twitter {
    color: #333;
    opacity: 0.7;
    transform: scale(1.1);
  }
`;

export const CheckboxWrapper = styled.label``;

export const CheckboxText = styled.span`
  margin-left: 8px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  line-height: 24px;

  .remember-me-text {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #939cb4;
`;

export const Wrapper = styled.div`
  background-color: ${props => props.theme.brand_primary_color};
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 540px;
  text-align: center;

  img {
    width: 300px;
    margin-bottom: 80px;
  }

  form {
    text-align: start;
    border-radius: 30px;
    background: ${props => props.theme.interface1};
    display: flex;
    flex-direction: column;
    margin: 60px auto 0px auto;
    max-width: 400px;
    padding: 80px 60px 20px 60px;

    h3 {
      padding-bottom: 30px;
      margin-top: 16px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      transform: translateY(-20px);
      color: ${props => props.theme.interface5};
    }

    button {
      margin-bottom: 40px;
      margin-top: 20px;
    }

    span {
      color: #d92947;
      font-size: 11px;
      margin-top: 6px;
      margin-bottom: 5px;
    }

    p {
      margin-top: 10px;
      font-size: 11px;
      color: ${props => props.theme.interface5};

      a {
        color: ${props => props.theme.interface5};
        font-size: 11px;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 3px;
      }
    }
  }
`;
