import React, { useState, useContext, useMemo } from 'react';
import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faDollarSign,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import {
  FormInput,
  Label,
  Field,
  FormSelect,
  Form,
  Checkbox,
} from 'components/Input';
import { Hover, HoverText } from 'components/Hover';
import { Scope } from '@rocketseat/unform';
import { ThemeContext } from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { PlanListingContext } from '../_context/state';
import { Advanced } from './styles';
import PlanPreviewComponent from './PlanPreview';

const schema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  description: Yup.string().required('Este campo é obrigatório'),
  accounts_size_limit: Yup.string(),
  accounts_limit: Yup.string(),
  category: Yup.number()
    .typeError('Escolha uma categoria válido')
    .required('Este campo é obrigatório'),
  base_price: Yup.object().shape({
    amount: Yup.number()
      .typeError('Insira um valor válido')
      .required('Este campo é obrigatório'),
    currency: Yup.string().default('BRL'),
  }),
  cloud_size: Yup.number()
    .typeError('Insira um valor válido')
    .positive('Valor deve ser positivo'),
  automations: Yup.object().shape({
    default_plan_type: Yup.number(),
  }),
});

const NewPlanModal = ({ plans, show, setShow, basePlans }) => {
  const { createPlan } = useContext(PlanListingContext);

  const [category, setCategory] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [freeTrial, setFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basePlan, setBasePlan] = useState(null);
  const [basePrice, setBasePrice] = useState(null);

  const [description, setDescription] = useState('');
  const [name, setName] = useState('');

  const [showAdvConfigs, setShowAdvConfigs] = useState(false);

  const theme = useContext(ThemeContext);

  const handleCreatePlan = async (data, { resetForm }) => {
    setLoading(true);
    const payload = {
      ...data,
      is_private: isPrivate,
    };

    if (!freeTrial) {
      delete payload.automations;
    } else {
      payload.base_price.amount = 0;
    }

    await createPlan(payload);
    resetForm();

    setLoading(false);
    setShow();
    setFreeTrial(false);
  };

  const isEmail = useMemo(() => {
    if (!basePlan) return false;
    return basePlan.category === 3;
  }, [basePlan]);

  const plansCPU = basePlans
    ?.filter(item => item.slug.includes('cpu'))
    ?.reverse();
  const plansEmail = basePlans
    ?.filter(item => item.slug.includes('email'))
    ?.reverse();
  const plansRAM = basePlans
    ?.filter(item => item.slug.includes('ram'))
    ?.reverse();
  const plansDefault = basePlans
    ?.filter(
      item =>
        !item.slug.includes('ram') &&
        !item.slug.includes('email') &&
        !item.slug.includes('cpu'),
    )
    ?.reverse();

  const sortedBasePlans = plansDefault &&
    plansEmail &&
    plansRAM &&
    plansCPU && [...plansDefault, ...plansEmail, ...plansRAM, ...plansCPU];

  const basePlansOptions = sortedBasePlans?.map(p => ({
    id: p.id,
    title: `${p.name} | ${p.cores} vCPU | ${p.memory} RAM | ${p.disk} (${
      p.price.currency === 'BRL' ? 'R$ ' : '$ '
    } ${p.price.amount})`,
  }));

  const plansOptions = plans
    ?.filter(p => p.slug.includes('1g') && !p.is_individual)
    .map(p => ({
      id: p.id,
      title: `${p.name}`,
    }));

  return (
    <Modal
      width={category === 10 ? '455px' : '758px'}
      show={show}
      setShow={setShow}
    >
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Novo plano</Title>
          <Subtitle>Crie um plano personalizado para seus clientes.</Subtitle>
        </Text>
      </Header>

      <Form schema={schema} onSubmit={handleCreatePlan}>
        <Content>
          <Row>
            <Col>
              <Field>
                <Label>Selecione uma categoria de plano</Label>
                {basePlansOptions && (
                  <FormSelect
                    placeholder="Selecione uma categoria"
                    height="40px"
                    name="category"
                    block="true"
                    options={[
                      {
                        id: 1,
                        title: 'Cloud',
                      },
                      {
                        id: 9,
                        title: 'Site compartilhado',
                      },
                      {
                        id: 10,
                        title: 'Email compartilhado',
                      },
                    ]}
                    onChange={e => {
                      setCategory(Number(e.target.value));
                      if (e.target.value !== 1) {
                        setBasePlan(null);
                      }
                    }}
                  />
                )}
              </Field>
              {category && category === 1 && (
                <Field>
                  <Label>Selecionar plano base</Label>
                  {basePlansOptions && (
                    <FormSelect
                      placeholder="Selecione um plano base."
                      height="40px"
                      name="cloud_size"
                      block="true"
                      options={basePlansOptions}
                      onChange={e => {
                        const basePlan = sortedBasePlans.find(
                          p => p.id === Number(e.target.value),
                        );
                        setBasePlan(basePlan);
                        setFreeTrial(
                          basePlan.slug.includes('partner-free-trial'),
                        );
                      }}
                    />
                  )}
                </Field>
              )}
              {category && category === 1 && freeTrial && (
                <Field>
                  <Label>Selecionar plano padrão</Label>
                  {plans && (
                    <Scope path="automations">
                      <FormSelect
                        placeholder="Selecione um plano padrão."
                        height="40px"
                        name="default_plan_type"
                        block="true"
                        options={plansOptions}
                      />
                    </Scope>
                  )}
                </Field>
              )}
              <Field>
                <Label>Nome</Label>
                <FormInput
                  value={name}
                  onChange={e => setName(e.target.value)}
                  name="name"
                  block
                />
              </Field>
              <Field>
                <Label>Descrição</Label>
                <FormInput
                  name="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  block
                />
              </Field>
              <Field>
                <Label>
                  {category && category === 1
                    ? 'Margem Adicional no Plano'
                    : 'Valor do Plano'}
                </Label>
                <Scope path="base_price">
                  <FormInput
                    disabled={freeTrial}
                    background={freeTrial}
                    value={freeTrial ? '0' : basePrice}
                    onChange={e => {
                      setBasePrice(e.target.value);
                    }}
                    min="0"
                    step="any"
                    type="number"
                    name="amount"
                    block="true"
                  />
                </Scope>
              </Field>

              <Field>
                <Label>
                  Privado{' '}
                  <Hover>
                    ?{' '}
                    <HoverText>
                      Os planos privados não apareceram no painel principal, ou
                      seja, não poderá ser contratado diretamente pelo cliente
                      final
                    </HoverText>
                  </Hover>
                </Label>
                <Checkbox
                  onChange={e => setIsPrivate(e.target.checked)}
                  value={isPrivate}
                  checked={isPrivate}
                />
              </Field>

              <Field>
                <Label>Valor total</Label>
                <CurrencyFormat
                  style={{
                    color: theme.label,
                    fontSize: '20px',
                  }}
                  value={
                    basePrice && basePlan
                      ? Number(basePrice) + Number(basePlan?.price?.amount)
                      : !basePlan
                      ? Number(basePrice)
                      : !basePrice
                      ? Number(basePlan?.price?.amount)
                      : '0'
                  }
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix="R$ "
                />
              </Field>
              {category === 10 && (
                <Advanced
                  style={{ marginBottom: showAdvConfigs ? '26px' : '0' }}
                  onClick={() => setShowAdvConfigs(!showAdvConfigs)}
                >
                  <span>Configurações avançadas</span>
                  <FontAwesomeIcon
                    icon={showAdvConfigs ? faChevronUp : faChevronDown}
                  />
                </Advanced>
              )}
              {showAdvConfigs && (
                <>
                  <Field>
                    <Label htmlFor="accounts_limit">
                      Limite de Contas de email
                    </Label>
                    <FormInput
                      name="accounts_limit"
                      maxLength={60}
                      block
                      type="number"
                    />
                  </Field>
                  <Field style={{ marginBottom: '0px' }}>
                    <Label htmlFor="accounts_size_limit">
                      Limite de armazenamento por conta (EM MB)
                    </Label>
                    <FormInput
                      name="accounts_size_limit"
                      maxLength={60}
                      block
                      type="number"
                    />
                  </Field>
                </>
              )}
            </Col>
            {category !== 10 && (
              <Col className="d-none d-sm-block">
                <PlanPreviewComponent
                  isEmail={isEmail}
                  name={name}
                  basePlan={basePlan}
                  category={category}
                  description={description}
                  basePrice={basePrice}
                />
              </Col>
            )}
          </Row>
        </Content>
        <Footer>
          <Button icon type="submit">
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Adicionar'}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default NewPlanModal;
