import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow: inherit !important;
    gap: 11px;

    @media (max-width: 650px) {
      width: 100%;
    }
  }
`;
export const Title = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #2253f5;
  }

  span.description {
    font-weight: bold;
  }
`;
export const Table = styled.table`
  border-spacing: 1px;
  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);

  border-collapse: collapse;

  border-radius: 0 0 5px 5px;

  tr.headLine {
    background: linear-gradient(278.18deg, #2253f5 0%, #36a0fc 99.04%);
  }

  tr:nth-child(even) {
    background-color: ${props => props.theme.interface2};
  }

  td {
    padding: 5px 20px;

    line-height: 19px;

    border-bottom: 1px solid #cfd3de;
    border-right: 1px solid #cfd3de;

    text-align: center;

    color: #64728c;
    font-size: 12px;
  }

  tr:last-child td {
    border-bottom: transparent;
  }

  td:last-child {
    border-right: transparent;
  }

  td.namePlan,
  td.classPlan {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    padding: 14px 10px;
  }

  td.namePlan {
    border-radius: 5px 0px 0px 0px;
  }
  td.classPlan {
    border-radius: 0px 5px 0px 0px;
  }
`;
