import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { Scope } from '@rocketseat/unform';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faMinusSquare,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import {
  Form,
  Field,
  FormInput,
  Label,
  FormSelect,
  Error,
} from 'components/Input';
import { Header, Title } from 'components/Common';
import { Button } from '@cloudez/cloudez-design-system';

import history from 'services/history';
import { toast } from 'react-toastify';

import api from 'services/api';
import { Card, CardBody } from './styles';

const itemSchema = Yup.object().shape({
  description: Yup.string().required('Este campo é obrigatório'),
  price: Yup.object().shape({
    amount: Yup.number()
      .typeError('Insira um valor válido')
      .required('Este campo é obrigatório'),
    currency: Yup.string().default('BRL'),
  }),
});

const schema = Yup.object().shape({
  expired_at: Yup.date()
    .min(new Date(), 'A data de validade deve ser maior que a data de hoje.')
    .max(
      moment(new Date()).add(30, 'days').toDate(),
      'A período de validade deve ser menor que 30 dias.',
    )
    .typeError('Insira uma data válida')
    .required('Este campo é obrigatório'),
  items: Yup.array().of(itemSchema),
  user: Yup.number()
    .typeError('Insira um usuário válido')
    .required('Este campo é obrigatório'),
});

const InvoiceCreate = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    async function getCompanyUsers() {
      const {
        data: { results },
      } = await api.get('/v3/company/user/', {
        params: {
          page_size: 50,
        },
      });
      const data = results.map(u => ({ title: u.email, id: u.id }));
      setUsers(data);
    }

    if (!users.length) getCompanyUsers();
  }, [users]);

  const [items, setItems] = useState([
    {
      description: '',
      price: {
        amount: 0,
        currency: 'BRL',
      },
    },
  ]);

  const handleSubmit = async data => {
    setLoading(true);
    const payload = {
      ...data,
      expired_at: moment(data.expired_at).format('YYYY-MM-DD'),
    };

    try {
      const { data } = await api.post(`/v3/invoice/`, payload);

      history.push(`/invoices/${data.id}`);
      toast.success('Fatura criada com sucesso');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrors(e.response.data);
    }
  };

  return (
    <>
      <Header>
        <Title>Criação de fatura</Title>
      </Header>

      <Row
        style={{
          marginLeft: 0,
        }}
      >
        <Card>
          <CardBody>
            <Form schema={schema} onSubmit={handleSubmit}>
              <Row>
                <Col xl="3">
                  <Field>
                    <Label>Data de vencimento</Label>
                    <FormInput
                      type="date"
                      block="true"
                      name="expired_at"
                      min={moment().format('YYYY/MM/DD')}
                    />
                  </Field>
                </Col>
              </Row>
              {items.map((i, index) => (
                <Row key={index}>
                  <Col xl="7">
                    <Field>
                      <Label>Descrição</Label>
                      <FormInput
                        block="true"
                        name={`items[${index}].description`}
                      />
                    </Field>
                  </Col>
                  <Col xl="3">
                    <Field>
                      <Label>Preço</Label>
                      <Scope path={`items[${index}].price`}>
                        <FormInput
                          block="true"
                          type="number"
                          step="0.01"
                          name="amount"
                        />
                      </Scope>
                    </Field>

                    {errors?.items[0]?.price?.amount && (
                      <Error
                        style={{
                          marginTop: '-15px',
                        }}
                      >
                        {errors?.items[0]?.price?.amount}
                      </Error>
                    )}
                  </Col>
                  {index === items.length - 1 && index !== 0 && (
                    <Col
                      xl="2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        size="sm"
                        outline
                        error
                        style={{
                          marginTop: '8px',
                        }}
                        onClick={e => {
                          e.preventDefault();
                          const newItems = items.filter((_, i) => index !== i);
                          setItems(newItems);
                        }}
                      >
                        Remover
                        <FontAwesomeIcon icon={faMinusSquare} />
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}

              <Row>
                <Col
                  xl="9"
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Button
                    size="sm"
                    onClick={e => {
                      e.preventDefault();
                      setItems([
                        ...items,
                        {
                          description: '',
                          price: {
                            amount: 0,
                            currency: 'BRL',
                          },
                        },
                      ]);
                    }}
                    left
                    outline
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                    Item
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col xl="4">
                  <Field>
                    <Label>Usuário</Label>
                    <FormSelect
                      block="true"
                      name="user"
                      options={users}
                      placeholder="Selecione um usuário"
                      height="40px"
                    />
                  </Field>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button disabled={loading} icon type="submit">
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Criar fatura'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default InvoiceCreate;
