import React, { useMemo } from 'react';

import CurrencyFormat from 'react-currency-format';

import { BasePlanCardProps } from '../types/basePlanCard';
import { PlanCard as Card } from '../styles';

const BasePlanCard: React.FC<{ p: BasePlanCardProps }> = ({ p }) => {
  const isEmail = useMemo(() => {
    if (!p) return false;
    return p.category === 3;
  }, [p]);

  return (
    <Card style={{ cursor: 'auto' }} basePlan>
      <div className="top">
        <div className="plan-title">
          {p.name} <span>{p.memory} RAM</span>
        </div>
        <span className="tip">{p.info['tip_pt-br']}</span>
        {p.price.amount !== '' && (
          <div className="prices">
            <div className="price">
              <span className="label">{isEmail ? 'Preço' : 'Basic'}</span>
              <CurrencyFormat
                value={p.price.amount}
                displayType="text"
                fixedDecimalScale
                className="amount"
                decimalScale={p.price?.amount?.toString().includes('.') ? 2 : 0}
                decimalSeparator=","
                thousandSeparator="."
                prefix={p.price.currency === 'BRL' ? 'R$ ' : '$ '}
              />
            </div>
            {!isEmail && (
              <div className="price">
                <span className="label">Turbo</span>
                <CurrencyFormat
                  value={p.turbo_price.amount}
                  displayType="text"
                  fixedDecimalScale
                  className="amount"
                  decimalScale={
                    p.turbo_price?.amount?.toString().includes('.') ? 2 : 0
                  }
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={p.turbo_price.currency === 'BRL' ? 'R$ ' : '$ '}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="separator" />
      <div className="bottom">
        <ul>
          <li>DISCO</li>
          <li className="value">{p.disk}</li>
          <li>PROCESSADOR</li>
          <li className="value">{p.cores} CPU core</li>
        </ul>
      </div>
    </Card>
  );
};

export default BasePlanCard;
