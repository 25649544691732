import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const HttpsText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
  margin-bottom: 15px;
`;

export const NewPlanCard = styled.div`
  margin: 10px auto;
  width: 140px;
  height: 190px;
  border-radius: 5px;
  background: ${props => props.theme.interface1};
  box-shadow: 0px 3px 15px
    ${props => transparentize(0.6, props.theme.interface5)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  color: ${props => props.theme.brand_primary_color};

  p {
    font-weight: bold;
    font-size: 16px;
  }

  span {
    p {
      font-size: 8px;
      display: inline;
    }

    span {
      font-weight: normal;
      font-size: 10px;
    }

    font-size: 22px;
    font-weight: bold;
    display: block;
  }

  div {
    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 10px;
      font-weight: bold;
      & + span {
        margin-top: 10px;
      }
    }
  }
`;

interface IPlanCardProps {
  selected?: boolean;
  private?: boolean;
}

export const PlanCard = styled.div<IPlanCardProps>`
  position: relative;
  margin: 10px 0px;
  height: 234px;
  border-radius: 5px;
  background: ${props => props.theme.interface1};
  /* box-shadow: 0px 3px 15px
    ${props => transparentize(0.6, props.theme.interface5)}; */
  border: 2px solid transparent;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  padding: 10px;
  color: ${props => props.theme.brand_primary_color};

  p {
    margin-top: 15px;
    font-weight: bold;
    font-size: 16px;
  }

  span {
    p {
      font-size: 8px;
      display: inline;
    }

    span {
      font-weight: normal;
      font-size: 14px;
    }

    font-size: 40px;
    font-weight: bold;
    display: block;
  }

  div {
    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 14px;
      font-weight: bold;

      & + span {
        margin-top: 10px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color};
    `}

  ${props =>
    props.private &&
    css`
      border: 2px solid ${props => props.theme.favorite};
    `}

    @media(max-width: 768px) {
    height: 200px;
    margin: 20px 4px 0px 4px;

    p {
      font-size: 14px;
    }

    span {
      p {
        font-size: 6px;
      }

      span {
        font-size: 12px;
      }

      font-size: 30px;
    }

    div {
      span {
        font-size: 12px;
      }
    }
  }
`;
