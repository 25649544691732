import styled, { css } from 'styled-components';

interface AlertProps {
  info?: boolean;
  alert?: boolean;
  error?: boolean;
}

export const Alert = styled.div<AlertProps>`
  border-radius: 5px;

  ${({ info }) =>
    info &&
    css`
      background-color: rgba(34, 83, 245, 0.75);
    `}

  ${({ error }) =>
    error &&
    css`
      background-color: rgba(251, 38, 75, 0.75);
    `}

  ${({ alert }) =>
    alert &&
    css`
      background-color: rgba(248, 130, 11, 0.75);
    `}

  display: flex;
  align-items: center;

  padding: 16px;

  display: flex;
  flex-direction: row;

  font-size: 14px;

  line-height: 19px;

  color: ${props => props.theme.interface2};

  span {
    margin-left: 20px;
  }
`;
