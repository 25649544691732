import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';

import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { Input, Field, Label, Error } from 'components/Input';

import { Button } from '@cloudez/cloudez-design-system';
import { Alert } from 'components/Alert';
import api from 'services/api';
import { toast } from 'react-toastify';
import exclamation from 'assets/img/svg/exclamation-message.svg';

interface Props {
  show: boolean;
  setShow: (value?: boolean) => void;
  user: any;
  setUser: any;
}

const BillingDateModal: React.FC<Props> = ({
  show,
  setShow,
  user,
  setUser,
}: Props) => {
  const [errors, setErrors] = useState(null);
  const [_loading, _setLoading] = useState(false);
  const [paymentDay, setPaymentDay] = useState(0);

  const onChangePaymentDay = e => {
    if (e.target.name === 'payment_day') setPaymentDay(e.target.value);
  };

  const updateUser = async (field, value) => {
    _setLoading(true);
    try {
      const { data } = await api.patch(`/v3/company/user/${user.id}/`, {
        [field]: value,
      });
      setUser(data);

      _setLoading(false);
      setShow();
      toast.success('Informações atualizadas com sucesso.');
    } catch (e) {
      _setLoading(false);
      setErrors(e.response.data);
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faCalendar} />
        </Icon>
        <Text>
          <Title>Mudar data de cobrança</Title>
        </Text>
      </Header>
      <Content>
        <Alert style={{ margin: '0 0 24px 0' }} info>
          <img alt="exclamation" src={exclamation} />
          <span>Fique atento as condições da troca de data</span>
        </Alert>
        <p style={{ fontWeight: 'bold', margin: '5px 0', color: '#545C68' }}>
          Faturas em aberto
        </p>
        <span style={{ color: '#64728C' }}>
          As faturas em aberto não serão alteradas e devem ser pagas nas datas
          de vencimento já definidas.
        </span>
        <p
          style={{
            fontWeight: 'bold',
            margin: '20px 0 5px 0',
            color: '#545C68',
          }}
        >
          Próximas faturas
        </p>
        <span style={{ color: '#64728C' }}>
          As próximas faturas poderão vir com valor maior ou menor de acordo com
          período proporcional da troca da fatura.
        </span>
        <p
          style={{
            fontWeight: 'bold',
            margin: '20px 0 5px 0',
            color: '#545C68',
          }}
        >
          Bloqueio
        </p>
        <span style={{ color: '#64728C' }}>
          Qualquer bloqueio não será eliminado com a mudança de data, todos os
          planos do cliente serão cobrados juntos na mesma data de cobrança.
        </span>
        <div style={{ marginTop: '20px' }}>
          <Field>
            <Label htmlFor="current_date">Data Atual</Label>
            <Input
              id="current_date"
              value={user?.payment_day}
              disabled
              name="current_date"
              error={errors?.current_date}
            />
            {errors?.current_date && <Error>{errors?.current_date[0]}</Error>}
          </Field>
          <Field>
            <Label htmlFor="payment_day">Data Nova</Label>
            <Input
              id="payment_day"
              value={paymentDay}
              block
              onChange={onChangePaymentDay}
              name="payment_day"
              error={errors?.payment_day}
            />
            {errors?.payment_day && <Error>{errors?.payment_day[0]}</Error>}
          </Field>
        </div>
      </Content>
      <Footer>
        <Button
          onClick={async () => {
            await updateUser('payment_day', paymentDay);
          }}
        >
          {_loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Alterar data'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default BillingDateModal;
