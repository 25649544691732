import React, { useState } from 'react';

import {
  faArrowRightArrowLeft,
  faChartColumn,
  faCommentDots,
  faCubes,
  faEnvelope,
  faGear,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

import { Header, Title } from 'components/Common';

import { Button } from '@cloudez/cloudez-design-system';

import { useAuth, useDocTitle } from 'hooks';

import assistedMig from './images/assistedMig.svg';
import architect from './images/architect.svg';
import messenger from './images/messenger.svg';
import analisys from './images/analisys.svg';
import experts from './images/experts.svg';
import smtp from './images/smtp.svg';

import assistedMig2 from './images/assistedMig2.svg';
import architect2 from './images/architect2.svg';
import messenger2 from './images/messenger2.svg';
import analisys2 from './images/analisys2.svg';
import experts2 from './images/experts2.svg';
import smtp2 from './images/smtp2.svg';

import ProductModal from './ProductModal';

import { Card, Container } from './styles';
import FinishModal from './FinishModal';

const Products: React.FC = () => {
  const { company } = useAuth();

  useDocTitle('Produtos e Serviços - Cloudez Partners');

  const [showProductModal, setShowProductModal] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState({});
  const [showFinishModal, setShowFinishModal] = useState(false);

  const finishService = () => {
    setShowFinishModal(true);
    setShowProductModal(false);
  };

  const list = [
    {
      icon: experts,
      fa: faStar,
      iconModal: experts2,
      title: 'Cloudez Experts',
      subtitle:
        'Suporte estendido, cobrado por cloud com valores que diminuem conforme escala',
      summary:
        'Tenha a equipe de Experts da Cloudez como seu time de infraestrutura',
      content:
        'Obtenha entrega de performance e segurança somado de toda expertise do time Cloudez. Chat com especialistas, manutenção contínua, apoio consultivo e muito mais no Cloudez Experts.',
      recurrence: 'Mensal',
      value: 500.0,
      action: finishService,
    },
    {
      icon: messenger,
      fa: faCommentDots,
      iconModal: messenger2,
      title: 'Cloudez Messenger',
      subtitle: 'Chat para atender seus clientes direto pelo painel Whitelabel',
      summary:
        'Obtenha chat de atendimento com seus clientes direto do painel Whitelabel',
      content:
        'Atenda seus clientes diretamente através de um chat no seu próprio painel Whitelabel. Transfira dúvidas técnicas e avançadas diretamente para o suporte Cloudez.',
      recurrence: 'Mensal',
      value: 199.0,
      action: finishService,
    },
    {
      icon: analisys,
      fa: faChartColumn,
      iconModal: analisys2,
      title: 'Cloudez Analisys',
      subtitle:
        'Análise de performance com correções via painel e relatórios de recomendação',
      summary:
        'Análise de performance com correções via painel e relatórios de recomendação',
      content:
        'Obtenha análise de toda a infra de seus sites, com sugestões de melhorias e ações práticas em seu painel para melhorar a performance das aplicações.',
      recurrence: 'Pagamento único',
      value: 900.0,
      action: finishService,
    },
    {
      icon: architect,
      fa: faCubes,
      iconModal: architect2,
      title: 'Cloudez Architect',
      subtitle:
        'Consultoria de arquitetura para melhorar a organização dos seus clouds',
      summary:
        'Consultoria com expert em Cloud Architecture para potencializar sua infra',
      content:
        'Obtenha uma consultoria exclusiva com o Cloud Architect. Serviço ideal para grandes projetos e migrações em massa.',
      recurrence: 'Pagamento único',
      value: 600.0,
      action: finishService,
    },
    {
      icon: assistedMig,
      fa: faArrowRightArrowLeft,
      iconModal: assistedMig2,
      title: 'Migração Assistida',
      subtitle: 'Fazemos a migração para você não se preocupar com nada',
      summary: 'Deixe a migração 100% na mão de especialistas',
      content:
        'Deixe toda a migração de sua aplicação, desde o início até a conclusão, nas mãos dos especialistas Cloudez. Solução ideal para economizar tempo e prevenir problemas.',
      recurrence: 'Pagamento único por migração',
      value: 50.0,
    },
    {
      icon: smtp,
      fa: faEnvelope,
      iconModal: smtp2,
      title: 'Cloudez SMTP',
      subtitle: 'Consumo adicional de SMTP, contabilidade por disparos',
      summary: 'Obtenha consumo adicional de SMTP, contagem feita por disparos',
      content:
        'Aumente o relacionamento com seus clientes, adicione +100mil disparos de email por mês em seu cloud.',
      recurrence: 'Mensal',
      value: 80.0,
      action: finishService,
    },
  ];

  return (
    <>
      {showProductModal && (
        <ProductModal
          show={showProductModal}
          setShow={setShowProductModal}
          item={selectedProduct}
        />
      )}
      {showFinishModal && (
        <FinishModal
          show={showFinishModal}
          setShow={setShowFinishModal}
          item={selectedProduct}
        />
      )}
      <Header>
        <Title>Produtos e Serviços</Title>
      </Header>
      <Container>
        {list.map(item => (
          <Card>
            <img src={item.icon} alt={item.title} />
            <div className="title">{item.title}</div>
            <div className="summary">{item.summary}</div>
            <div className="button">
              <Button
                onClick={() => {
                  setShowProductModal(true);
                  setSelectedProduct(item);
                }}
                width="117px"
                height="30px"
              >
                Conheça
              </Button>
            </div>
          </Card>
        ))}
      </Container>
    </>
  );
};

export default Products;
