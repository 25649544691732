import { useEffect, memo, useRef } from 'react';

import moment from 'moment';

import 'moment/locale/pt-br';

import { Container } from './styles';

const SystemMessage = ({ msg }) => {
  const createdAt = moment(msg?.created_at).format('LLL');

  const messageRef = useRef(null);

  useEffect(() => {
    const url = new RegExp(
      // eslint-disable-next-line
      /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim,
    );

    if (messageRef.current) {
      messageRef.current.innerHTML = messageRef.current.innerHTML.replace(
        url,
        '<a href="$1" target="_blank">$1</a>',
      );
    }
  }, [messageRef]);

  return (
    <Container style={{ margin: '0 auto', marginBottom: '20px' }}>
      <div style={{ margin: '0 auto' }}>
        <pre ref={messageRef}>{msg.text}</pre>

        <span>{createdAt}</span>
      </div>
    </Container>
  );
};

export default memo(SystemMessage);
