import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import { useAuth } from 'hooks/auth';

import { createEmailBulkService, createEmailService } from 'services/email';
import { createMigrationEmailService } from 'services/friday';
import api from 'services/api';

import { Button } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudUploadAlt,
  faEyeSlash,
  faEye,
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { Alert } from 'components/Alert';

import { useMigCreateEmail } from '../../contexts/MigEmailContext';
import { useMigDetail } from '../../contexts/MigDetailContext';

import { ButtonsContainer, Container } from './styles';
import { ErrorModal } from '../../ErrorModal';

const Confirmation = (): JSX.Element => {
  const { user } = useAuth();

  const {
    selectedOriginItem,
    originData,
    selectedDestItem,
    changeStage,
    migrationErrors,
    migErrorMessage,
  } = useMigCreateEmail();

  const history = useHistory();

  const { setStep } = useMigDetail();

  const [showPassword, setShowPassword] = useState(false);
  const [usersLength, setUsersLength] = useState(5);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);

  const showAllUsers = () => {
    setUsersLength(
      originData?.accounts ? originData.accounts.length : emails.length,
    );
  };

  const isCloud = !!selectedDestItem?.cloud_id;

  const initMigration = async () => {
    try {
      setLoading(true);
      let payloadExternal;

      payloadExternal = {
        migration_type: selectedOriginItem === 'external' ? 1 : 2,
        user_uuid: user.uuid,
        company_uuid: user.company,
        ...originData,
        dest_zoneapp: {
          id: selectedDestItem?.id,
          domain: selectedDestItem?.domain,
          cloud_id: selectedDestItem?.cloud,
          cloud_fqdn: selectedDestItem?.cloud_fqdn,
          cloud_description: selectedDestItem?.cloud_display,
          temporary_address: `webapp${selectedDestItem.id}.${selectedDestItem?.cloud_fqdn}`,
        },
      };

      let emailDuplicated;

      if (isCloud && selectedOriginItem !== 'external') {
        const { data } = await api.post('v3/email/duplicate_email/', {
          dest_node_id: selectedDestItem.id,
          src_zoneapp_id: selectedOriginItem.id,
        });

        emailDuplicated = data;
      }

      const destinationItem = emailDuplicated || selectedDestItem;

      const payloadInternal = {
        auto_point_dns: false,
        domain: originData.domain,
        migration_type: 2,
        user_uuid: user.uuid,
        company_uuid: user.company,
        src_zoneapp: {
          id: selectedOriginItem?.id,
          domain: selectedOriginItem?.domain,
          cloud_id: selectedOriginItem?.cloud,
          cloud_fqdn: selectedOriginItem?.cloud_fqdn,
          cloud_description: selectedOriginItem?.cloud_display,
        },
        dest_zoneapp: {
          id: destinationItem?.id,
          domain: destinationItem?.domain,
          cloud_id: destinationItem?.cloud,
          cloud_fqdn: destinationItem?.cloud_fqdn,
          cloud_description: destinationItem?.cloud_display,
          temporary_address: `webapp${destinationItem.id}.${destinationItem?.cloud_fqdn}`,
        },
      };

      if (selectedOriginItem !== 'external') {
        await api.post(
          `v3/email/${
            emailDuplicated?.id || selectedDestItem.id
          }/duplicate_accounts/`,
          {
            src_zoneapp_id: selectedOriginItem.id,
          },
        );
      }

      let zoneappId;

      if (isCloud && selectedOriginItem === 'external') {
        const { data } = await createEmailService({
          cloud: selectedDestItem.id,
          domain: originData.domain,
          type: 'selfhosted-mail',
          values: [
            { slug: 'webmail', value: true },
            { slug: 'webmail_address', value: 'webmail' },
            { slug: 'roundcube_whitelabel', value: 'false' },
          ],
        });

        zoneappId = data.id;

        payloadExternal = {
          ...payloadExternal,
          dest_zoneapp: {
            id: data.id,
            domain: data.domain,
            cloud_id: data.cloud,
            cloud_fqdn: data.cloud_fqdn,
            cloud_description: data.cloud_display,
          },
        };
      }

      if (selectedOriginItem === 'external') {
        await createEmailBulkService({
          email_accounts: originData.accounts,
          zoneapp_id: zoneappId || selectedDestItem.id,
        });
      }

      const { data } = await createMigrationEmailService(
        selectedOriginItem === 'external'
          ? { ...payloadExternal }
          : { ...payloadInternal },
      );

      history.push(`/migrations/${data.id}/?success=true`);

      setLoading(false);
      setStep(4);
    } catch (err) {
      if (err?.response?.data) {
        migrationErrors(err?.response?.data);
      }
    }
  };

  useEffect(() => {
    if (selectedOriginItem !== 'external') {
      const getEmails = async () => {
        try {
          const { data } = await api.get(
            `/v3/email/account/?email=${selectedOriginItem.id}`,
          );

          setEmails(data.results);
        } catch (err) {
          console.log('err');
        }
      };

      getEmails();
    }
  }, [selectedOriginItem]);

  const goBackFunc = () => {
    if (!selectedDestItem?.cloud_id) {
      setStep(2);
      changeStage('existingEmail');
    } else if (selectedDestItem?.cloud_id) {
      setStep(2);
      changeStage('newWebsiteCloud');
    }
  };

  return (
    <>
      {migErrorMessage && <ErrorModal err={migErrorMessage} />}
      <Container>
        <div className="title">
          <FontAwesomeIcon icon={faCloudUploadAlt} />
          <span>Dados do destino</span>
        </div>
        <span className="subtitle">
          Antes de iniciar a migração, confirme os dados abaixo:
        </span>
        <div className="originData">
          <p>Dados da Origem</p>
          <Row>
            <Col>
              <label>Domínio</label>
              <span>{originData.domain}</span>
            </Col>
            {selectedOriginItem === 'external' ? (
              <>
                <Col>
                  <label>Endereço IMAP</label>
                  <span>{originData.src_email_host}</span>
                </Col>
                <Col>
                  <label>Porta</label>
                  <span>{originData.imap_port}</span>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <label>Cloud de Origem</label>
                  <span>{selectedOriginItem.cloud_display}</span>
                </Col>
              </>
            )}
          </Row>
        </div>
        <div className="users">
          <p>Usuários</p>
          {originData?.accounts ? (
            <table>
              <tr>
                <th>Email</th>
                <th>Senha</th>
              </tr>
              {originData?.accounts?.slice(0, usersLength).map(item => (
                <tr>
                  <td>{item.username}</td>
                  <td style={{ width: '144px' }}>
                    <span>
                      {showPassword
                        ? item.password
                        : item.password.replace(/[\S]/g, '*')}
                    </span>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? faEye : faEyeSlash}
                    />
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <table style={{ width: 'fit-content' }}>
              <tr>
                <th>Email</th>
              </tr>
              {emails.slice(0, usersLength).map(item => (
                <tr>
                  <td>{`${item.username}@${originData.domain}`}</td>
                </tr>
              ))}
            </table>
          )}

          <div className="visualizeAll">
            <span onClick={showAllUsers}>Visualizar todos</span>
          </div>
          <div className="originData">
            <p>Dados do Destino</p>
            <Row>
              <Col>
                <label>Tipo da Migração</label>
                <span>
                  {isCloud && originData?.accounts && 'Novo webmail'}
                  {!originData?.accounts && 'Migração Interna'}
                  {!isCloud && originData?.accounts && 'Webmail já existente'}
                </span>
              </Col>
              <Col>
                <label>Cloud</label>
                <span>
                  {selectedDestItem?.cloud_display
                    ? selectedDestItem?.cloud_display
                    : `${
                        selectedDestItem?.nickname || selectedDestItem?.name
                      } - ${selectedDestItem?.cloud_size?.name}`}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Domínio</label>
                <span>{selectedDestItem.domain}</span>
              </Col>
            </Row>
          </div>
        </div>
        <Alert alert>
          <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
          <span>
            <b>Atenção!</b> Não realize nenhuma mudança na origem e no destino
            até a finalização da sua migração.
          </span>
        </Alert>

        <ButtonsContainer>
          <Button onClick={goBackFunc} text>
            <span
              style={{
                fontSize: '14px',
              }}
            >
              Voltar
            </span>
          </Button>
          <Button icon onClick={initMigration} width="190px">
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <span
                style={{
                  fontSize: '14px',
                  textTransform: 'none',
                }}
              >
                Iniciar migração
              </span>
            )}
          </Button>
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default Confirmation;
