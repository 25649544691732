import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { PlanPreview, SharedPreview } from './styles';

const PlanPreviewComponent = ({
  _plan,
  isEmail,
  hasBasic,
  basicMarkup,
  turboMarkup,
  hasTurbo,
}) => {
  return (
    <PlanPreview>
      {_plan.category && _plan.category === 1 ? (
        <>
          <div className="plan-title">
            <span>{_plan.name || '--------------'}</span>
            <span>
              {_plan.cloud_size ? (
                <>{_plan.cloud_size?.memory} RAM</>
              ) : (
                '-------'
              )}
            </span>
          </div>

          <p className="tip">
            {_plan.description !== 'False'
              ? _plan.description
              : '------------------------'}
          </p>

          <div className="stats">
            <div>
              <span>DISCO</span>
              <span className="value">{_plan.cloud_size?.disk || '-'}</span>
            </div>

            <div>
              <span>PROCESSADOR</span>
              <span className="value">
                {_plan.cloud_size ? (
                  <>{_plan.cloud_size?.cores} CPU Core </>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>

          {isEmail && (
            <div className="type">
              <h3>Email</h3>
              <Row>
                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount"
                      value={_plan.cloud_size.price.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Preço Base</span>
                  </div>
                </Col>

                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount final"
                      value={
                        Number(_plan.cloud_size.price.amount) +
                        Number(basicMarkup)
                      }
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Final</span>
                  </div>
                </Col>
              </Row>
              <div className="profit">
                <div className="price">
                  <span className="label">Lucro Mensal*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={parseFloat(basicMarkup) || '0'}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>

                <div className="price">
                  <span className="label">Lucro Anual*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={
                      Number(basicMarkup) * 12 -
                        Number(basicMarkup) * 12 * 0.15 || 0
                    }
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>
              </div>
            </div>
          )}

          {hasBasic && _plan.cloud_size && !isEmail && (
            <div className="type">
              <h3>Basic</h3>
              <Row>
                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount"
                      value={_plan.cloud_size.price.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Preço Base</span>
                  </div>
                </Col>

                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount final"
                      value={
                        Number(_plan.cloud_size.price.amount) +
                        Number(basicMarkup)
                      }
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Final</span>
                  </div>
                </Col>
              </Row>
              <div className="profit">
                <div className="price">
                  <span className="label">Lucro Mensal*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={parseFloat(basicMarkup) || '0'}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>

                <div className="price">
                  <span className="label">Lucro Anual*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={
                      Number(basicMarkup) * 12 -
                        Number(basicMarkup) * 12 * 0.15 || 0
                    }
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>
              </div>
            </div>
          )}

          {hasTurbo && _plan.cloud_size && !isEmail && (
            <div className="type">
              <h3>Turbo</h3>
              <Row>
                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount"
                      value={_plan.cloud_size.turbo_price.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Preço Base</span>
                  </div>
                </Col>

                <Col>
                  <div className="price">
                    <CurrencyFormat
                      className="amount final"
                      value={
                        Number(_plan.cloud_size.turbo_price.amount) +
                        Number(turboMarkup)
                      }
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      // prefix={
                      //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                      // }
                      prefix="R$"
                    />
                    <span className="label">Final</span>
                  </div>
                </Col>
              </Row>
              <div className="profit">
                <div className="price">
                  <span className="label">Lucro Mensal*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={parseFloat(turboMarkup) || 0}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>

                <div className="price">
                  <span className="label">Lucro Anual*</span>

                  <CurrencyFormat
                    className="profit-amount"
                    value={
                      Number(turboMarkup) * 12 -
                        Number(turboMarkup) * 12 * 0.15 || 0
                    }
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    // prefix={
                    //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                    // }
                    prefix="R$"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : _plan.category ? (
        <SharedPreview>
          <h4>{_plan.name || '--------------'}</h4>
          <div className="shared-info">
            <span className="category">
              {_plan.category === 9
                ? 'Site compartilhado'
                : 'E-mail compartilhado'}
            </span>

            <div className="price">
              <h6>Lucro Mensal*</h6>
              <CurrencyFormat
                className="profit-amount"
                value={parseFloat(basicMarkup) || 0}
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                // prefix={
                //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                // }
                prefix="R$ "
              />
            </div>

            <div className="price">
              <h6>Lucro Anual*</h6>
              <CurrencyFormat
                className="profit-amount"
                value={
                  Number(basicMarkup) * 12 - Number(basicMarkup) * 12 * 0.15 ||
                  0
                }
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                // prefix={
                //   p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '
                // }
                prefix="R$ "
              />
            </div>
          </div>
        </SharedPreview>
      ) : (
        <div className="no-category">Selecione uma categoria</div>
      )}
    </PlanPreview>
  );
};

export default PlanPreviewComponent;
