import { createContext, useReducer } from 'react';
import reducer from './reducer';
import {
  setLoading,
  getPlans,
  updatePlan,
  getBasePlans,
  createPlan,
  deletePlan,
} from './actions';

interface PlanListingNewState {
  plans: any[];
  plan: any;
  basePlans: any[];
  count: number;
  previous: number;
  current: number;
  next: number;
  loading: boolean;
  errors: any;
  plansTab: number;
  basePlansTab: number;

  setLoading: (value: boolean) => void;
  getPlans(page: number, search: string, size_category: string): Promise<void>;
  getBasePlans(query: any, size_category: string | string[]): Promise<void>;
  createPlan(payload: any): Promise<void>;
  updatePlan(
    field: any,
    value: string,
    id: string | number,
    plan: any,
  ): Promise<void>;
  deletePlan(id: string | number, setShow?: any): Promise<void>;
  setPlansTab(tab: number): void;
  setBasePlansTab(tab: number): void;
  setErrors(error: any): void;
}

// initial state
const initialState: PlanListingNewState = {
  plans: null,
  plan: null,
  basePlans: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
  plansTab: 1,
  basePlansTab: 1,

  setLoading: (): void => {},
  getPlans: async () => {},
  createPlan: async () => {},
  updatePlan: async () => {},
  deletePlan: async () => {},
  getBasePlans: async () => {},
  setPlansTab: async () => {},
  setBasePlansTab: async () => {},
  setErrors: async () => {},
};

// context
export const PlanListingContext = createContext(initialState);

// provider
export const PlanListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlanListingContext.Provider
      value={{
        // state
        plans: state.plans,
        plan: state.plan,
        basePlans: state.basePlans,

        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,
        plansTab: state.plansTab,
        basePlansTab: state.basePlansTab,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getPlans: async (page, search, size_category) =>
          await getPlans(dispatch, page, search, size_category),
        getBasePlans: async (query, size_category) =>
          await getBasePlans(dispatch, query, size_category),
        createPlan: async payload => await createPlan(dispatch, payload),
        updatePlan: async (field, value, id, plan) =>
          await updatePlan(dispatch, field, value, id, plan),
        deletePlan: async (id, setShow) =>
          await deletePlan(dispatch, id, setShow),
        setPlansTab: async tab =>
          dispatch({
            type: 'SET_PLANS_TAB',
            payload: tab,
          }),
        setErrors: async error =>
          dispatch({
            type: 'SET_ERRORS',
            payload: error,
          }),
        setBasePlansTab: async tab =>
          dispatch({
            type: 'SET_BASE_PLANS_TAB',
            payload: tab,
          }),
      }}
    >
      {children}
    </PlanListingContext.Provider>
  );
};
