import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloud,
  faWindowMaximize,
  faEnvelope,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';

import CurrencyFormat from 'react-currency-format';
import { Badge, Private, PlanCard as Card } from '../styles';

const PlanCard = ({ p, basePlan, setEditModal, setEditPlan }) => {
  return (
    <Card
      freeTrial={p.is_free_trial}
      private={p.is_private}
      onClick={() => {
        setEditPlan(p);
        setEditModal(true);
      }}
    >
      <Badge category={p.category}>
        <FontAwesomeIcon
          icon={
            p.category === 1
              ? faCloud
              : p.category === 9
              ? faWindowMaximize
              : faEnvelope
          }
        />
        {p.category === 1 && 'cloud'}
        {p.category === 9 && 'website'}
        {p.category === 10 && 'email'}
      </Badge>
      {p.is_private && (
        <Private>
          <FontAwesomeIcon icon={faUserSecret} />
        </Private>
      )}
      <p>{p.name}</p>
      {p.price_total.amount !== '' && (
        <>
          <CurrencyFormat
            style={{
              fontSize: '30px',
            }}
            value={p.price_total.amount}
            displayType="text"
            fixedDecimalScale
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            prefix={p.price_total?.currency === 'BRL' ? 'R$ ' : '$ '}
          />
          <span>
            <span>mensal</span>
          </span>
        </>
      )}
      <div>
        {basePlan && (
          <>
            <span>{basePlan.memory} RAM</span>
            <span>{basePlan.cores} CPU core</span>
            <span>{basePlan.disk}</span>
          </>
        )}
      </div>
    </Card>
  );
};

export default PlanCard;
