import styled from 'styled-components';

interface IContainerProps {
  information?: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  height: calc(100vh - 42px);
  background: ${props => props.theme.interface1};
  border-radius: 5px;

  border-top: 1px solid ${props => props.theme.interface3};
  display: grid;
  grid-template-rows: 0.2fr 2.2fr 0.6fr;
  gap: 0px 0px;

  grid-template-columns: ${props =>
    props.information ? '0.6fr  1.4fr 1fr' : '0.8fr  1.2fr'};
  grid-template-areas: ${props =>
    props.information
      ? `'chats header information'
          'chats messages information'
          'chats footer information';`
      : `'chats header'
          'chats messages'
          'chats footer';`};

  transition: all 0.2s ease;
`;
