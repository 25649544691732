import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  grid-area: chats;
  height: 100%;
  min-width: 100%;
  border-right: 1px solid ${props => props.theme.interface3};

  ul {
    width: 100%;
    max-height: calc(100% - 73px);
    height: auto;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${props => props.theme.interface3};
  /* padding: 24px 24px; */

  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }

  button {
    width: 60px;
  }
`;

export const SearchBox = styled.div`
  border-left: 1px solid ${props => props.theme.interface3};
  width: 50%;

  padding: 18px 24px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 50%;
  padding: 18px 24px;
`;

interface IChatProps {
  selected: boolean;
}

export const Chat = styled.li<IChatProps>`
  position: relative;

  width: 100%;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.interface3};
  cursor: pointer;
  transition: background 0.1s ease;

  &:hover {
    background: ${props => props.theme.interface3};
  }

  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.interface3};
    `}

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    span.id {
      font-size: 12px;
      color: ${props => props.theme.interface4};
    }
    span.owner {
      font-weight: bold;
      font-size: 17px;
      color: ${props => props.theme.interface7};
    }
    span.summary {
      font-size: 12px;
      color: ${props => props.theme.interface5};
    }
  }
`;
