import { useMemo } from 'react';

import { Button } from '@cloudez/cloudez-design-system';
import Modal from 'components/Modal';
import { Content, Footer } from 'components/ListingModal/styles';

import { H3, P, Span } from './styles';

interface SeeMoreModalProperties {
  show: boolean;
  setShow: any;
  role: string;
}

const SeeMoreModal: React.FC<SeeMoreModalProperties> = ({
  show,
  setShow,
  role,
}) => {
  const content = useMemo(
    () => ({
      financial_role: {
        title: 'financeira',
        partners:
          'Dashboard, Clientes, Planos, Faturas, Extrato, Tickets e Downloads.',
        wl: 'Dashboard, Clouds, Tickets, Faturas e Sua Conta.',
      },
      technical_role: {
        title: 'técnica',
        partners: 'Dashboard, Clientes, Planos, Tickets e Migrations.',
        wl: 'Dashboard, Websites, Emails, Domínios, Migrações, Databases, Clouds, Tickets e Sua Conta.',
      },
      customer_success_role: {
        title: 'de atendimento',
        partners:
          'Dashboard, Clientes, Planos, Faturas, Tickets, Migrations e Chat.',
        wl: 'Dashboard, Websites, Email, Domínios, Migrações, Databases, Clouds, Tickets, Faturas e Sua Conta.',
      },
    }),
    [],
  );

  return (
    <Modal show={show} setShow={setShow}>
      <Content>
        <H3>
          A permissão {content[role].title} libera acesso para as seguintes
          páginas:
        </H3>
        <Span>No painel Partners</Span>
        <P>{content[role].partners}</P>
        <Span>No painel White Label</Span>
        <P>{content[role].wl}</P>
      </Content>
      <Footer>
        <Button icon outline size="sm" onClick={setShow}>
          Fechar
        </Button>
      </Footer>
    </Modal>
  );
};

export default SeeMoreModal;
