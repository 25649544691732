import api from 'services/api';

export const getDashboardService = async (id: number | string) => {
  try {
    return await api.get(`/v3/company-dashboard/${id}/`);
  } catch (error) {
    throw error;
  }
};

export const getDashboardLates = async params => {
  try {
    return await api.get('/v3/company/dashboard_late_users/', { params });
  } catch (error) {
    throw error;
  }
};

export const getDashboardBlockeds = async params => {
  try {
    return await api.get('/v3/company/dashboard_blocked_users/', { params });
  } catch (error) {
    throw error;
  }
};
