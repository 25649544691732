import styled, { css } from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';

export const Tabs = styled.ul`
  list-style: none;
  display: flex;
`;

interface ITabProps {
  active: boolean;
  confirm?: boolean;
  theme?: any;
}

export const Tab = styled.li<ITabProps>`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: 6px 0px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-bottom: 3px solid transparent;
  transition: border 100ms ease-in-out;

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid
        ${(props: ITabProps) =>
          props.confirm
            ? props.theme.success
            : props.theme.brand_primary_color};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: ${props =>
        props.confirm
          ? props.theme.interface5
          : props.theme.brand_primary_color};
    `}

  ${props =>
    props.confirm &&
    css`
      svg {
        margin-right: 10px;
        color: ${(props: ITabProps) =>
          props.active ? props.theme.success : props.theme.interface5};
      }
    `}


  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;

    ${props =>
      !props.active &&
      css`
        display: none;
      `}
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 50px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

interface IContentProps {
  loading?: boolean;
}

export const Content = styled.div<IContentProps>`
  ${props =>
    props.loading &&
    css`
      opacity: 0.5;
    `}
`;

interface TabContentProps {
  noBackground?: boolean;
  noPadding?: boolean;
}

export const TabContent = styled.div<TabContentProps>`
  position: relative;
  height: auto;
  width: 100%;
  background: ${props => !props.noBackground && props.theme.interface1};
  border-radius: 5px;
  padding: ${props => !props.noPadding && '18px 12px'};
`;
interface IFieldProps {
  center?: boolean;
}

export const Field = styled.div<IFieldProps>`
  width: 100%;
  margin-bottom: 16px;

  ${props =>
    props.center &&
    css`
      padding: 0px 14px;
      width: auto;
      max-width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}
`;

export const Label = styled.span`
  display: block;
  font-size: 12px;
  color: ${props => props.theme.interface4};
  svg {
    font-size: 14px;
  }
`;

interface IValueProps {
  link?: any;
}

export const Value = styled.span<IValueProps>`
  a {
    color: ${props => props.theme.interface6};
    text-decoration: underline;
  }

  margin-top: 2px;
  display: block;
  font-size: 14px !important;
  color: ${props =>
    props.link
      ? props.theme.brand_primary_color
      : props.theme.interface5} !important;
  ${props =>
    props.link &&
    css`
      cursor: pointer;
    `}

  svg {
    margin-left: 8px;
  }
`;

export const Separator = styled.div`
  height: 100%;
  width: 1px;
  background: ${props => props.theme.interface4};
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 30px;

    ${Button} {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const Title = styled.div`
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props =>
    props.theme.darkMode ? '#F5F8FF' : props.theme.interface7} !important;

  h3 {
    font-size: 18px;
    font-weight: bold;

    a {
      text-decoration: none;
      color: ${props => props.theme.interface7};
    }
  }

  p {
    font-size: 12px;
    color: ${props => props.theme.interface5};
  }
  svg {
    cursor: pointer;
    margin-left: 10px;
    color: ${props => props.theme.brand_primary_color};
  }
`;
