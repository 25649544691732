import styled, { css } from 'styled-components';
import { darken } from 'polished';
import {
  Input as UNInput,
  Select as UNSelect,
  Form as UNForm,
  Textarea as UNTextArea,
  Check,
} from '@rocketseat/unform';

import check from 'assets/img/check.png';
import chevron from 'assets/img/chevron.png';

export const InputSufix = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 4px;
  position: absolute;
  top: 21px;
  right: 16px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 38px;
  background: ${props => props.theme.interface3};
  color: ${props => props.theme.interface5};
  font-size: 12px;
`;

export const Value = styled.p`
  color: ${props => props.theme.interface5};
  font-size: 14px;
`;

export const Form = styled(UNForm)`
  span {
    font-size: 12px;
    color: #d92947;
  }
`;

export const Error = styled.span`
  display: block;
  font-size: 10px;
  color: ${props => props.theme.error};
  position: relative;
  margin-bottom: -12px;
`;

interface IInputProps {
  block?: boolean | string;
  height?: string;
  width?: string;
  error?: string | boolean;
  background?: boolean;
  maxLength?: number;
}

export const Input = styled.input<IInputProps>`
  height: ${props => props.height || '40px'};
  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};
  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};
  color: ${props => (props.theme.darkMode ? '#fff' : props.theme.interface5)};
  font-size: 14px;
  padding: 12px;
  border-radius: 5px;
  background: ${props => props.theme.darkMode && props.theme.interface1};

  ${props =>
    props.theme.darkMode &&
    css`
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    `}

  &::placeholder {
    color: ${props => props.theme.interface4};
  }

  &:focus {
    transition: border 0.2s ease-in-out;
    border: 2px solid ${props => props.theme.brand_primary_color};
    ${props =>
      props.error &&
      css`
        border-color: ${props => props.theme.error};
      `}
  }

  ${props =>
    props.background &&
    css`
      background-color: ${props => props.theme.interface2};
    `}

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.error};
    `}
`;

export const LineInput = styled(Input)`
  background: none;
  border: none;
  border-bottom: 2px solid ${props => props.theme.brand_primary_color};
  border-radius: 0px;
  padding: 0px;

  &::placeholder {
    font-style: italic;
    font-size: 16px;
  }

  &:focus {
    border: none;
    border-bottom: 2px solid ${props => props.theme.brand_primary_color};
  }
`;

interface IFormInputProps {
  height?: string;
  width?: string;
  block?: string | boolean;
  background?: boolean;
  error?: boolean;
  onChange?: any;
  disabled?: boolean;
  min?: string;
  step?: string;
  type?: string;
}

export const FormInput = styled(UNInput)<IFormInputProps>`
  height: ${props => props.height || '40px'};
  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};
  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};
  color: ${props => (props.theme.darkMode ? '#fff' : props.theme.interface5)};
  font-size: 14px;
  padding: 12px;
  border-radius: 5px;
  background-color: ${props =>
    props.theme.darkMode ? props.theme.interface1 : ''};

  &::placeholder {
    color: ${props => props.theme.interface4};
  }

  &:focus {
    transition: border 0.2s ease-in-out;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.background &&
    css`
      background-color: ${props => props.theme.interface2};
    `}
  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.error};
    `}
`;

interface ICheckboxProps {
  error?: boolean;
  value: any;
}

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})<ICheckboxProps>`
  cursor: pointer;
  position: relative;
  appearance: none;
  outline: none;
  width: 28px;
  height: 13px;
  border: 1px solid ${props => props.theme.interface4};
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 ${props => props.theme.interface2};

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 9px;
    height: 9px;
    background-color: ${props => props.theme.interface1};
    box-shadow: 0px 1px 4px #64728c4d;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  &:checked {
    border-color: ${props => props.theme.brand_primary_color};
    box-shadow: inset 28px 0 0 0 ${props => props.theme.brand_secondary_color};
  }

  &:checked:after {
    left: 16px;
    background-color: ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

interface IFormCheckboxProps {
  value?: any;
}

export const FormCheckbox = styled(Check)<IFormCheckboxProps>`
  cursor: pointer;
  position: relative;
  appearance: none;
  outline: none;
  width: 28px;
  height: 13px;
  border: 1px solid ${props => props.theme.interface4};
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 ${props => props.theme.interface2};

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 9px;
    height: 9px;
    background-color: ${props => props.theme.interface1};
    box-shadow: 0px 1px 4px #64728c4d;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  &:checked {
    border-color: ${props => props.theme.brand_primary_color};
    box-shadow: inset 28px 0 0 0 ${props => props.theme.brand_secondary_color};
  }

  &:checked:after {
    left: 16px;
    background-color: ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

interface IRoundedCheckboxProps {
  value: any;
}

export const RoundedCheckbox = styled.input.attrs({
  type: 'checkbox',
})<IRoundedCheckboxProps>`
  position: absolute;
  opacity: 0;
  z-index: 2000;
  width: 20px;
  cursor: pointer;
  height: 20px;

  & + small {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  &:not(:checked) + small:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid ${props => props.theme.brand_primary_color};
    background: #fff;
    cursor: pointer;
    border-radius: 5px;
  }

  &:checked + small:after {
    content: '';
    color: ${props => props.theme.interface1};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: ${props => props.theme.brand_primary_color};
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center center;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const Field = styled.div`
  width: 100%;
  margin-bottom: 20px;

  svg {
    position: relative;
    left: calc(100% - 23px);
    font-size: 14px;
    top: -32px;
    color: ${props => (props.theme.darkMode ? '#fff' : props.theme.interface4)};
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.interface5};
      transition: color 0.2s ease;
    }
  }

  p {
    color: ${props => props.theme.interface5};
    font-size: 14px;
  }

  span:not(.counter) {
    display: block;
    font-size: 10px;
    color: #d92947;
    position: relative;
    margin-bottom: -12px;
  }

  div {
    display: flex;
    justify-content: space-between;
  }
`;

interface ILabelProps {
  dark?: boolean;
  required?: boolean;
  for?: string;
}

export const Label = styled.label<ILabelProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 900;
  color: ${props =>
    props.color
      ? props.color
      : props.dark
      ? props.theme.interface5
      : darken(0.15, props.theme.interface4)} !important;
  text-transform: uppercase;

  span.counter {
    color: ${props =>
      props.dark
        ? props.theme.interface5
        : darken(0.15, props.theme.interface4)} !important;
    font-size: 11px !important;
  }

  ${props =>
    props.required &&
    css`
      color: ${props => props.theme.brand_primary_color} !important;
      font-weight: bold;
    `}
`;

export const Range = styled.input.attrs({
  type: 'range',
})<IInputProps>`
  -webkit-appearance: none;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    overflow: hidden;
    -webkit-appearance: none;
    padding: 10px 0;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      cursor: pointer;
      border-radius: 30px;
      background: ${props => props.theme.interface3};
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      box-shadow: 1px 2px 3px #64728c73;
      border: 1px solid ${props => props.theme.brand_primary_color};
      border-radius: 50%;
      height: 14px;
      width: 14px;
      margin-top: -4px;
      background: ${props => props.theme.interface1};
      cursor: pointer;
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-moz-range-thumb {
    box-shadow: 0px 1px 2px #64728c73;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background: ${props => props.theme.interface1};
    cursor: pointer;
  }

  &::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border-radius: 30px;
    background: ${props => props.theme.interface3};
  }

  &::-moz-range-progress {
    height: 6px;
    background: ${props => props.theme.brand_primary_color};
  }

  width: ${props => (props.block ? '100%' : '255px')};
`;

interface IFormSelectProps {
  width?: string;
  height?: string;
  placeholder: string;
  block?: string | boolean;
  options: any;
  onChange?: (event: { target: { value: number | string } }) => void;
}

export const FormSelect = styled(UNSelect)<IFormSelectProps>`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  padding: 6px 10px;

  background: ${props => props.theme.interface1};
  border-radius: 5px;
  color: ${props => (props.theme.darkMode ? '#fff' : props.theme.interface5)};
  outline: 0;
  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};

  &::placeholder {
    color: ${props => props.theme.interface4};
  }

  &:focus {
    transition: border 0.2s ease-in-out;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }
  background: url(${chevron}) no-repeat right;

  background-position-x: 98%;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const Select = styled.select<IInputProps>`
  height: ${props => props.height || '40px'};
  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};
  padding: 6px 10px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  outline: 0;
  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  color: ${props =>
    props.theme.darkMode ? props.theme.interface4 : props.theme.interface5};

  &::placeholder {
    color: ${props => props.theme.interface4};
  }

  &:focus {
    transition: border 0.2s ease-in-out;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  background: url(${chevron}) no-repeat right;

  background-position-x: 98%;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const TextArea = styled.textarea.attrs(props => ({
  rows: props.rows,
}))<IInputProps>`
  height: ${props => props.height || '40px'};
  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};
  color: ${props => (props.theme.darkMode ? '#fff' : props.theme.interface5)};
  font-size: 14px;
  padding: 12px;
  border-radius: 5px;
  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};

  &::placeholder {
    color: ${props => props.theme.interface4};
  }

  &:focus {
    transition: border 0.2s ease-in-out;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.background &&
    css`
      background-color: ${props => props.theme.interface2};
    `}

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.error};
    `}
`;

interface IFormTextAreaProps {
  width?: string;
  height?: string;
  block: boolean | string;
  background?: boolean;
  error?: boolean;
}

export const FormTextArea = styled(UNTextArea).attrs(props => ({
  rows: props.rows,
}))<IFormTextAreaProps>`
  height: ${props => props.height || '40px'};
  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};
  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};
  color: ${props => (props.theme.darkMode ? '#fff' : props.theme.interface5)};
  font-size: 14px;
  padding: 12px;
  border-radius: 5px;

  &::placeholder {
    color: ${props => props.theme.interface4};
  }

  &:focus {
    transition: border 0.2s ease-in-out;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  background-color: ${props => (props.theme.darkMode ? '#000000' : '#ffffff')};

  ${props =>
    props.background &&
    css`
      background-color: ${props => props.theme.interface2};
    `}
  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.error};
    `};
`;

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  width: unset;
  height: unset;
  & ~ label {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-left: 6px;
  }
`;
