import React from 'react';

import { Breadcrumbs, Breadcrumb } from 'components/Common';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faChevronRight,
  faHandHoldingDollar,
} from '@fortawesome/free-solid-svg-icons';

import { Header, Title, FooterText } from './styles';
import Presentation from './Presentation';
import Pricing from './Pricing';

const NewPrices: React.FC = () => {
  return (
    <>
      <Header>
        <Title>
          <Breadcrumbs className="breadcrumbs">
            <Breadcrumb>
              <Link to="/dashboard">Master</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="">Novos Preços</Link>
            </Breadcrumb>
          </Breadcrumbs>
          <p>Novos Preços</p>
        </Title>
      </Header>
      <Presentation />
      <Pricing />
      <FooterText
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ marginBottom: '30px' }}>
          Quer garantir os valores de 2023? Se você costuma pagar seus serviços
          Cloudez mensalmente, estamos liberando a possibilidade de alterar a
          periodicidade para anual mantendo os valores de 2023 pelo próximo ano.
          Solicite a alteração{' '}
          <a
            href="https://forms.gle/XhLAvLXxoABkahXV8"
            target="_blank"
            rel="noreferrer"
          >
            <b> aqui </b>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>{' '}
          e nosso time entrará em contato.
        </span>
        <span>
          Continuamos juntos na missão de mudar o mercado. Conte com nossa
          equipe em caso de dúvidas por meio dos canais de atendimento{' '}
          <b>WhatsApp</b> ou <b>sucesso@cloudez.io</b>.
        </span>
      </FooterText>
    </>
  );
};

export default NewPrices;
