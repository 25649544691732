import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  background: white;
  padding: 34px 43px !important;
  border-radius: 0;
  -webkit-box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);

  h2 {
    text-align: center;
    width: 100%;
    padding: 0 9%;
    font-size: 16.25px;
    margin-bottom: 30px;

    color: #555;
  }

  h3 {
    text-align: center;
    width: 100%;
    padding: 0 9%;
    font-size: 14.25px;
    margin-top: 10px;

    color: #555;
  }
`;

export const Content = styled.div`
  text-align: justify !important;
  line-height: 1.5;

  font-size: 13px;
  color: #555;

  a {
    color: ${props => props.theme.brand_primary_color};
    text-decoration: underline;
  }
`;
