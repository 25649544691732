import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faDollarSign,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { Scope } from '@rocketseat/unform';
import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import {
  Label,
  Field,
  FormSelect,
  FormCheckbox,
  Form,
  FormInput,
  FormTextArea,
} from 'components/Input';
import { Hover, HoverText } from 'components/Hover';
import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import * as Yup from 'yup';
import { getBasePlansService } from 'services/plans';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import { PlanListingContext } from '../_context/state';
import { PlanCardItem } from '../types/planCard';
import { Advanced, Value } from './styles';
import PlanPreviewComponent from './PlanPreview';
import ConfirmEditPlanContent from './ConfirmEditPlan';

const schema = Yup.object().shape({
  description: Yup.string().when('$category', {
    is: 1,
    then: Yup.string().required('Este campo é obrigatório'),
    otherwise: Yup.string().optional(),
  }),
  cloud_size: Yup.string().when('$category', {
    is: 1,
    then: Yup.string().required('Este campo é obrigatório'),
    otherwise: Yup.string().optional(),
  }),
  accounts_limit: Yup.string(),
  accounts_size_limit: Yup.string(),
  name: Yup.string().required('Este campo é obrigatório'),
});

interface EditPlanModalProps {
  show: boolean;
  setShow: (value?: boolean) => void;
  plan: PlanCardItem;
  plans: PlanCardItem[];
}

const EditPlanModal: React.FC<EditPlanModalProps> = ({
  show,
  setShow,
  plan,
  plans,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [_plan, setPlan] = useState(plan);
  const {
    updatePlan,
    deletePlan,
    errors,
    plan: createdPlan,
  } = useContext(PlanListingContext);
  const [freeTrial, setFreeTrial] = useState(false);
  const [basePlans, setBasePlans] = useState([]);

  const [showData, setShowData] = useState(false);

  const err = 'Não é possível editar o preço com planos ativos';

  useEffect(() => {
    if (errors?.description === err) {
      setShowData(true);
    }
  }, [errors?.description]);

  const [showAdvConfigs, setShowAdvConfigs] = useState(
    !!(_plan.accounts_limit || _plan.accounts_size_limit),
  );

  // const [isPrivate, setIsPrivate] = useState(false);

  const [hasTurbo, setHasTurbo] = useState(_plan.has_turbo);
  const [hasBasic, setHasBasic] = useState(_plan.has_basic);

  const [basicMarkup, setBasicMarkup] = useState(_plan.base_price.amount);
  const [turboMarkup, setTurboMarkup] = useState(_plan.turbo_base_price.amount);

  const basePlansOptions = useMemo(() => {
    return basePlans?.map(p => ({
      id: p.id,
      title: `${p.name} | ${p.cores} vCPU | ${p.memory} RAM | ${p.disk} (${
        p.price.currency === 'BRL' ? 'R$ ' : '$ '
      } ${p.price.amount})`,
    }));
  }, [basePlans]);
  const isEmail = useMemo(() => {
    if (!_plan.cloud_size) return false;
    return _plan.cloud_size.category === 3;
  }, [_plan.cloud_size]);

  const plansOptions = useMemo(() => {
    plans
      ?.filter(p => p.slug.includes('1g') && !p.is_individual)
      .map(p => ({
        id: p.id,
        title: `${p.name}`,
      }));
  }, [plans]);

  const onChange = e => {
    if (e.target.name === 'base_price') {
      setPlan({
        ..._plan,
        base_price: {
          ..._plan.turbo_base_price,
          amount: e.target.value,
        },
        turbo_base_price: {
          ..._plan.turbo_base_price,
          amount: e.target.value,
        },
      });
    } else if (e.target.name === 'turbo_base_price') {
      setPlan({
        ..._plan,
        base_price: {
          ..._plan.turbo_base_price,
          amount: e.target.value,
        },
        turbo_base_price: {
          ..._plan.turbo_base_price,
          amount: e.target.value,
        },
      });
    } else if (e.target.name === 'is_private') {
      setPlan({
        ..._plan,
        [e.target.name]: e.target.checked,
      });
    } else {
      setPlan({
        ..._plan,
        [e.target.name]: e.target.value,
      });
    }
  };

  const _updatePlan = async () => {
    setLoading(true);
    if (!_plan.description || _plan.description === '') {
      toast.error('Descrição é obrigatório');
      setLoading(false);
      return;
    }
    try {
      setLoading(true);

      let __plan = {
        ..._plan,
        base_price: {
          ..._plan.turbo_base_price,
          amount: turboMarkup,
        },
        turbo_base_price: {
          ..._plan.turbo_base_price,
          amount: turboMarkup,
        },
      };

      if (_plan.category === 1) {
        __plan = {
          ...__plan,
          turbo_base_price: {
            ..._plan.turbo_base_price,
            amount: turboMarkup,
          },
          has_turbo: true,
          has_basic: false,
          cloud_size: _plan.cloud_size.id as any,
        };
      } else {
        delete __plan.cloud_size;
      }

      if (_plan.category === 10) {
        if (!_plan.accounts_limit) {
          __plan.accounts_limit = 0;
        }
        if (!_plan.accounts_size_limit) {
          __plan.accounts_size_limit = 0;
        }
      }

      await updatePlan(null, null, _plan.id, __plan);
      setShow();
    } catch (e) {
      if (e.response.data.description !== err) {
        toastError(e);
      }
      setLoading(false);
    }
  };

  const setAllFalse = () => {
    setShow(false);
    setShowData(false);
  };

  const _deletePlan = async () => {
    setDeleteLoading(true);

    try {
      await deletePlan(createdPlan ? createdPlan.id : plan.id, setAllFalse);
      setDeleteLoading(false);
    } catch (e) {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    const getPlans = async () => {
      try {
        const { data } = await getBasePlansService();
        setBasePlans(data);
      } catch (e) {
        console.log(e);
      }
    };

    getPlans();
  }, []);

  useEffect(() => {
    if (_plan.category !== 1) return;
    setFreeTrial(_plan.cloud_size.slug.includes('partner-free-trial'));
  }, [_plan]);

  return (
    <Modal
      show={show}
      setShow={setShow}
      width={_plan.category === 10 || showData ? '455px' : '758px'}
    >
      {showData ? (
        <ConfirmEditPlanContent
          plan={plan}
          setShowConfirm={setShowData}
          setPlan={setPlan}
        />
      ) : (
        <>
          <Header>
            <Icon>
              <FontAwesomeIcon icon={faDollarSign} />
            </Icon>
            <Text>
              <Title>{_plan.name}</Title>
            </Text>
          </Header>

          <Form
            initialData={{
              ..._plan,
              category: _plan.category,
              cloud_size: _plan.cloud_size?.id,
            }}
            onSubmit={_updatePlan}
            schema={schema}
            id="edit-plan-form"
          >
            <Content>
              <Row>
                <Col>
                  <Field>
                    <Label htmlFor="category">
                      Selecione uma categoria de plano
                    </Label>
                    <FormSelect
                      disabled
                      placeholder="Selecione uma categoria"
                      height="40px"
                      name="category"
                      block="true"
                      options={[
                        {
                          id: 1,
                          title: 'Cloud',
                        },
                        {
                          id: 9,
                          title: 'Site compartilhado',
                        },
                        {
                          id: 10,
                          title: 'Email compartilhado',
                        },
                      ]}
                    />
                  </Field>
                  {_plan.category === 1 && (
                    <Field>
                      <Label htmlFor="cloud_size">Plano base</Label>
                      {basePlansOptions && (
                        <FormSelect
                          disabled
                          placeholder="Selecione um plano base."
                          height="40px"
                          name="cloud_size"
                          block="true"
                          options={basePlansOptions}
                          value={_plan.cloud_size.id}
                        />
                      )}
                    </Field>
                  )}
                  {_plan.category && _plan.category === 1 && freeTrial && (
                    <Field>
                      <Label htmlFor="default_plan_type">
                        Selecionar plano padrão
                      </Label>
                      {plans && (
                        <Scope path="automations">
                          <FormSelect
                            placeholder="Selecione um plano padrão."
                            height="40px"
                            name="default_plan_type"
                            block="true"
                            options={plansOptions}
                          />
                        </Scope>
                      )}
                    </Field>
                  )}
                  <Field>
                    <Label htmlFor="name">
                      Nome{' '}
                      <span className="counter">{`${_plan.name.length}/30 caracteres`}</span>
                    </Label>
                    <FormInput
                      name="name"
                      onChange={onChange}
                      maxLength={30}
                      block="true"
                    />
                  </Field>
                  {_plan.category && _plan.category === 1 && (
                    <Field>
                      <Label htmlFor="description">
                        Descrição do plano{' '}
                        <span className="counter">{`${
                          _plan.description === 'False'
                            ? 0
                            : _plan.description.length
                        }/128 caracteres`}</span>
                      </Label>
                      <FormTextArea
                        style={{ resize: 'none' }}
                        name="description"
                        maxLength={128}
                        value={
                          _plan.description !== 'False' ? _plan.description : ''
                        }
                        onChange={onChange}
                        block
                        height="90px"
                      />
                    </Field>
                  )}
                  {_plan.category &&
                    (_plan.category === 9 ||
                      _plan.category === 10 ||
                      (_plan.cloud_size && isEmail)) && (
                      <Field>
                        <Label htmlFor="amount">
                          {_plan.category && _plan.category === 1
                            ? 'Margem Adicional no Plano'
                            : 'Valor do Plano'}
                        </Label>
                        <Scope path="turbo_base_price">
                          <CurrencyFormat
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            customInput={FormInput}
                            // fixedDecimalScale
                            prefix="R$"
                            value={turboMarkup}
                            onValueChange={values => {
                              setBasicMarkup(values.floatValue || '');
                              setTurboMarkup(values.floatValue || '');
                            }}
                            name="amount"
                            placeholder="Margem adicional"
                            width={_plan.category === 10 ? '100%' : '150px'}
                            block="true"
                          />
                        </Scope>
                      </Field>
                    )}
                  {_plan.category &&
                    _plan.category === 1 &&
                    _plan.cloud_size &&
                    !isEmail && (
                      <>
                        <Field>
                          <Label htmlFor="has_turbo">
                            MARGEM ADICIONAL NO PLANO
                          </Label>
                          <div
                            style={{
                              alignItems: 'center',
                              justifyContent: 'left',
                            }}
                          >
                            <Scope path="turbo_base_price">
                              <CurrencyFormat
                                data-testid="turbo_base_price"
                                decimalScale={2}
                                decimalSeparator=","
                                thousandSeparator="."
                                customInput={FormInput}
                                prefix="R$"
                                disabled={!hasTurbo}
                                background={!hasTurbo}
                                value={turboMarkup}
                                onValueChange={values => {
                                  setBasicMarkup(values.floatValue || 0);
                                  setTurboMarkup(values.floatValue || 0);
                                }}
                                name="amount"
                                placeholder="Margem adicional"
                                width="150px"
                                block="true"
                              />
                            </Scope>
                          </div>
                        </Field>{' '}
                      </>
                    )}

                  <Field>
                    <Label htmlFor="is_private">
                      Privado{' '}
                      <Hover>
                        ?{' '}
                        <HoverText>
                          Os planos privados não aparecerão no painel principal,
                          ou seja, não poderão ser contratados diretamente pelo
                          cliente final
                        </HoverText>
                      </Hover>
                    </Label>
                    <FormCheckbox onChange={onChange} name="is_private" />
                  </Field>
                  {_plan.category === 10 && (
                    <Field>
                      <Label>VALOR TOTAL </Label>
                      <Value>
                        {Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(Number(turboMarkup))}
                      </Value>
                    </Field>
                  )}
                  {_plan.category === 10 && (
                    <Advanced
                      style={{ marginBottom: showAdvConfigs ? '26px' : '0' }}
                      onClick={() => setShowAdvConfigs(!showAdvConfigs)}
                    >
                      <span>Configurações avançadas</span>
                      <FontAwesomeIcon
                        icon={showAdvConfigs ? faChevronUp : faChevronDown}
                      />
                    </Advanced>
                  )}
                  {showAdvConfigs && (
                    <>
                      <Field>
                        <Label htmlFor="accounts_limit">
                          Limite de Contas de e-mail
                        </Label>
                        <FormInput
                          name="accounts_limit"
                          maxLength={60}
                          onChange={onChange}
                          block
                          type="number"
                          placeholder="Deixe em branco ou 0 para ilimitado"
                        />
                      </Field>
                      <Field style={{ marginBottom: '0px' }}>
                        <Label htmlFor="accounts_size_limit">
                          Limite de armazenamento por conta (EM MB)
                        </Label>
                        <FormInput
                          name="accounts_size_limit"
                          maxLength={60}
                          onChange={onChange}
                          block
                          type="number"
                          placeholder="Deixe em branco ou 0 para ilimitado"
                        />
                      </Field>
                    </>
                  )}
                </Col>
                {_plan.category !== 10 && (
                  <Col className="d-none d-sm-block">
                    <PlanPreviewComponent
                      _plan={_plan}
                      isEmail={isEmail}
                      hasBasic={hasBasic}
                      basicMarkup={basicMarkup}
                      turboMarkup={turboMarkup}
                      hasTurbo={hasTurbo}
                    />
                  </Col>
                )}
              </Row>
            </Content>
          </Form>
          <Footer hasLink>
            <Button error icon outline onClick={_deletePlan}>
              {deleteLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Remover'
              )}
            </Button>

            <Button icon type="submit" form="edit-plan-form">
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Salvar'}
            </Button>
          </Footer>
        </>
      )}
    </Modal>
  );
};

export default EditPlanModal;
