import { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Footer,
  Subtitle,
} from 'components/ListingModal/styles';
import { Alert } from 'components/Alert';
import { Content } from './styles';
import { PlanListingContext } from '../../_context/state';

const ConfirmEditPlanContent = ({ plan, setShowConfirm, setPlan }: any) => {
  const [loading, setLoading] = useState(false);

  const {
    createPlan,
    setErrors,
    plan: createdPlan,
  } = useContext(PlanListingContext);

  useEffect(() => {
    if (createdPlan) {
      setPlan(createdPlan);
    }
  }, [createdPlan]);

  const handleCreatePlan = async () => {
    try {
      setLoading(true);
      const payload = {
        base_price: plan.base_price,
        category: plan.category,
        cloud_size: plan.cloud_size.id,
        description: plan.description,
        has_basic: plan.has_basic,
        has_turbo: plan.has_turbo,
        is_private: plan.is_private,
        name: `(2) ${plan.name}`,
        turbo_base_price: plan.turbo_base_price,
      };

      await createPlan(payload);

      setShowConfirm(false);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    return setErrors(null);
  }, []);

  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Confirmação de edição de plano</Title>
          <Subtitle>{plan.name}</Subtitle>
        </Text>
      </Header>

      <Content>
        <Alert info>
          <FontAwesomeIcon
            style={{
              color: '#ffffff',
              marginRight: '15px',
              width: '20px',
              height: '20px',
            }}
            icon={faExclamationCircle}
          />
          Preste atenção as informações a seguir pois elas são importantes e
          podem resolver dúvidas futuras
        </Alert>
        <p className="attention">
          <p>Atenção:</p>
          Não é possivel editar um plano com clientes ativos.
        </p>
        <span>
          Para fazer essa ação, precisamos criar uma cópia do plano atual e
          então essa cópia pode ser vinculada aos seus clientes.
        </span>
        <span>Deseja criar a cópia com as alterações feitas?</span>
      </Content>
      <Footer hasLink>
        <Button
          style={{ width: '99px' }}
          text
          outline
          onClick={() => setShowConfirm(false)}
          icon
          disabled={loading}
        >
          Voltar
        </Button>
        <Button onClick={handleCreatePlan} icon disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Criar cópia'}
        </Button>
      </Footer>
    </>
  );
};

export default ConfirmEditPlanContent;
