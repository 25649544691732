import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'react-credit-cards/es/styles-compiled.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'emoji-mart/css/emoji-mart.css';
import { lighten } from 'polished';

export default createGlobalStyle`
  .cc-banner .cc-message {
    color: #333333 !important;
    text-align: right;
    max-width: 60% !important;
    font-size: 15px;

    @media (max-width: 414px) {
      text-align: unset;
      max-width: 100%  !important;
    }
  }

  .cc-compliance {
    a {
      text-transform: uppercase;
      font-weight: 900 !important;
      font-size: 13px;
    }
  }


  .Toastify__toast {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    height: 80px !important;

    font-size: 14px !important;
    font-weight: bold !important;
    box-shadow: 0px 3px 6px #64728C29 !important;

  }

  .Toastify__toast-body {
    margin: auto 0 auto 30px !important;
  }

  .Toastify__toast-container {
    padding: 0px !important;
    width: 250px !important;
  }

  .Toastify__toast-container--top-right {
    right: 0px !important;
    top: 50px !important;
  }

  .Toastify__toast--info {
    background: ${() => lighten(0.3, '#353FF2')} !important;
    color: #353FF2 !important;
  }

  .Toastify__toast--success {
    background: ${() => lighten(0.3, '#29D975')} !important;
    color: #29D975 !important;

  }

  .Toastify__toast--error {
    background: ${() => lighten(0.3, '#D92947')} !important;
    color: #D92947 !important;

  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      background: #F5F8FF;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background: #cfd3de;
    }
  }

  #react-select-input {
  &:focus {
    border: transparent !important;
  }
}

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  html { overflow-y: scroll; }

  body {
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, textarea, select, pre {
    font-family: "Nunito", sans-serif !important;
  }

  h1,h2 ,h3 ,h4 ,h5 ,h6 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 700;
  }

  a {
    text-decoration: none
  }

  ul {
    list-style: none
  }

  button {
    font-family: 'Nunito', sans-serif;
    cursor: pointer;
    font-weight: 700;

    &::-moz-focus-inner {
    border: 0;
    }
  }
`;
