import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import Loading from 'components/Loading';
import Pagination from 'components/Pagination';
import SearchInput from 'components/SearchInput';
import { Field } from 'components/Input';
import { getWebsitesService } from 'services/website';
import { createPagination } from 'utils/createPagination';
import { getEmailsService } from 'services/email';
import { getCloudsService } from 'services/cloud';
import { getDomainsService } from 'services/domain';
import { getInvoicesService } from 'services/invoices';
import { Wrapper, Title } from './styles';
import ResourceItem from './ResourceItem';

type ResourceProps = {
  type: string;
  resource: any;
  setResource: (value: any) => void;
  setOpenModal: (value: boolean) => void;
};

const Resource: React.FC<ResourceProps> = ({
  type,
  resource,
  setResource,
  setOpenModal,
}) => {
  const [search, setSearch] = useState(null);
  const [_resource, _setResource] = useState(null);
  const [newPage, setNewPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getResource = async (page, search) => {
    setLoading(true);
    switch (type) {
      case 'website': {
        const { data } = await getWebsitesService({
          page,
          search,
          page_size: 4,
        });
        const pagination = createPagination(data, 3);
        _setResource({
          title: 'Selecione o Website',
          resource: data.results,
          ...pagination,
          action: setNewPage,
        });
        setLoading(false);
        break;
      }
      case 'email': {
        const { data } = await getEmailsService({
          page,
          search,
          page_size: 4,
        });
        const pagination = createPagination(data, 3);
        _setResource({
          title: 'Selecione o Email',
          resource: data.results,
          ...pagination,
        });
        setLoading(false);
        break;
      }
      case 'cloud': {
        const { data } = await getCloudsService({
          page,
          search,
          page_size: 4,
        });
        const pagination = createPagination(data, 3);
        _setResource({
          title: 'Selecione o Cloud',
          resource: data.results,
          ...pagination,
        });
        setLoading(false);
        break;
      }
      case 'domain': {
        const { data } = await getDomainsService({
          page,
          search,
          page_size: 4,
        });
        const pagination = createPagination(data, 3);
        _setResource({
          title: 'Selecione o Domínio',
          resource: data.results,
          ...pagination,
        });
        setLoading(false);
        break;
      }
      case 'invoice': {
        const { data } = await getInvoicesService({
          page,
          search,
          page_size: 4,
        });

        const pagination = createPagination(data, 3);
        _setResource({
          title: 'Selecione a Fatura',
          resource: data.results,
          ...pagination,
        });
        setLoading(false);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    getResource(newPage, search);
    // eslint-disable-next-line
  }, [type]);

  const searchValue = searchValue => {
    getResource(newPage, searchValue);
  };

  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Field
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
              width: '100%',
            }}
          >
            <Title>
              <p className="titleDefault">{_resource?.title}</p>
            </Title>
            <SearchInput block setQ={setSearch} q={search} cb={searchValue} />
          </Field>
          <Col
            style={{
              padding: 0,
            }}
          >
            {_resource?.resource?.map(c => (
              <ResourceItem
                isResource
                type={type}
                onClick={() => {
                  setResource(c);
                  setOpenModal(false);
                }}
                resource={resource}
                c={c}
              />
            ))}
          </Col>
          <Row
            style={{
              marginBottom: '20px',
              marginLeft: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination
              onChangePage={getResource}
              count={_resource?.count}
              previous={_resource?.previous}
              next={_resource?.next}
              current={_resource?.current}
              search={search}
              pageSize="3"
            />
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default Resource;
