import React from 'react';

import CurrencyFormat from 'react-currency-format';
import { PlanCard as Card } from '../styles';

const BasePlanCard = ({ p }) => {
  return (
    <Card>
      <p>{p.name}</p>
      {p.price.amount !== '' && (
        <>
          <CurrencyFormat
            style={{
              fontSize: '30px',
            }}
            value={p.price.amount}
            displayType="text"
            fixedDecimalScale
            decimalScale={p.price?.amount?.toString().includes('.') ? 2 : 0}
            decimalSeparator=","
            thousandSeparator="."
            prefix={p.price.currency === 'BRL' ? 'R$ ' : '$ '}
          />
          <span>
            <span>mensal</span>
          </span>
        </>
      )}
      <div>
        {p && (
          <>
            <span>{p.memory} RAM</span>
            <span>{p.cores} CPU core</span>
            <span>{p.disk}</span>
          </>
        )}
      </div>
    </Card>
  );
};

export default BasePlanCard;
