import React, { createContext, useReducer } from 'react';

import { BasePlans, Plans } from 'types/plans';

import reducer from './reducer';
import {
  setLoading,
  getPlans,
  updatePlan,
  getBasePlans,
  createPlan,
  deletePlan,
} from './actions';

// initial state

export interface PlanListingState {
  plans: Plans[];
  basePlans: BasePlans[];
  count: number;
  previous: number;
  current: number;
  next: number;
  loading: boolean;
  errors: any;

  setLoading(value: boolean): void;
  getPlans(page: number, search: string): Promise<void>;
  getBasePlans(): Promise<void>;
  createPlan(payload: any): Promise<void>;
  updatePlan(
    field: any,
    value: string,
    id: string | number,
    plan: any,
  ): Promise<void>;
  deletePlan(id: string | number): Promise<void>;
}

// initial state
const initialState: PlanListingState = {
  plans: null,
  basePlans: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,

  setLoading: (): void => {},
  getPlans: async () => {},
  getBasePlans: async () => {},
  createPlan: async () => {},
  updatePlan: async () => {},
  deletePlan: async () => {},
};

// context
export const PlanListingContext = createContext(initialState);

// provider
export const PlanListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlanListingContext.Provider
      value={{
        // state
        plans: state.plans,
        basePlans: state.basePlans,

        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getPlans: async (page, search) =>
          await getPlans(dispatch, page, search),
        getBasePlans: async () => await getBasePlans(dispatch),
        createPlan: async payload => await createPlan(dispatch, payload),
        updatePlan: async (field, value, id, plan) =>
          await updatePlan(dispatch, field, value, id, plan),
        deletePlan: async id => await deletePlan(dispatch, id),
      }}
    >
      {children}
    </PlanListingContext.Provider>
  );
};
