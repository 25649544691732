import React, { useEffect, useState } from 'react';

import {
  faExclamationCircle,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Footer,
} from 'components/ListingModal/styles';

import { Alert } from 'components/Alert';
import { Content } from './styles';

interface ErrorModalProps {
  err: any;
}

export const ErrorModal = ({ err }: ErrorModalProps): JSX.Element => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (err) {
      setShow(true);
    }
  }, [err]);

  return (
    <Modal
      style={{ margin: 'inherit', height: 'fit-content' }}
      width="455px"
      show={show}
      setShow={setShow}
    >
      <Header>
        <Icon color="rgba(251, 38, 75, 1)">
          <FontAwesomeIcon style={{ fontSize: '20px' }} icon={faXmark} />
        </Icon>
        <Text>
          <Title>Erro!</Title>
        </Text>
      </Header>

      <Content>
        <Alert error>
          <FontAwesomeIcon
            style={{ marginRight: '15px' }}
            size="lg"
            icon={faExclamationCircle}
          />
          Ocorreu um erro, leia a mensagem com atenção para corrigir o erro e
          seguir com sua migração
        </Alert>
        <span style={{ fontWeight: 'bold' }}>{err.title}</span>
        <span>{err.main}</span>
        {err.secondary && <span>{err.secondary}</span>}
      </Content>

      <Footer>
        <Button width="149px" onClick={() => setShow(false)}>
          OK
        </Button>
      </Footer>
    </Modal>
  );
};
