import React from 'react';
import { Col } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';
import { WebsiteCard, Content, Logo } from '../../Websites/styles';

const PlanPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 12; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col xl="2" key={p}>
          <WebsiteCard>
            <Content>
              <Logo>
                <Placeholder width={40} height={40} circle />
              </Logo>
            </Content>
            <span>
              <Placeholder width={60} height={12} />
            </span>
            <p>
              <Placeholder width={40} height={12} />
            </p>
          </WebsiteCard>
        </Col>
      ))}
    </>
  );
};

export default PlanPlaceholder;
