import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
} from 'react';
import { useAuth, useDocTitle, useLayout } from 'hooks';
import queryString from 'query-string';
import { Row, Col } from 'react-bootstrap';

import history from 'services/history';
import { Header, Title } from 'components/Common';
import SearchBar from 'components/SearchBar';
import { ListingHeader, Listing, Ordering } from 'components/Listing/styles';
import Pagination from 'components/Pagination';
import { NoResults } from 'components/NoResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faCheck,
  faArrowUp,
  faArrowDown,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  Filter,
  FilterHover,
  FilterWrapper,
} from 'components/SearchBar/styles';
import CustomAnimatedList from 'components/CustomAnimatedList';
import { Button } from '@cloudez/cloudez-design-system';
import { Value } from 'components/Acessos';
import AdvancedSearch from 'components/AdvancedSearch';
import UserPlaceholder from './Placeholder';
import InvoiceCard from './InvoiceCard';
import {
  InvoiceListingContext,
  InvoiceListingProvider,
} from './_context/state';

import { DropdownList, ItemList } from './styles';

const Invoices = () => {
  const [dropdownWL, setDropdownWL] = useState<boolean>();
  useDocTitle('Faturas - Cloudez Partners');
  const { user, company } = useAuth();
  const theme = useLayout();
  const { loading, invoices, count, previous, next, current, getInvoices } =
    useContext(InvoiceListingContext);

  const { page = 1, status = null } = queryString.parse(
    history.location.search,
  );

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const [statusValue, setStatusValue] = useState(Number(status));
  const [orderValue, setOrderValue] = useState<any>();
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getInvoicesCallback = useCallback(
    (page, search, status, _orderValue) => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      getInvoices(
        page,
        search,
        status,
        _orderValue,
        abortControllerRef.current,
      );

      const _query = {
        ...search,
        page,
        status,
      };

      if (!search) delete _query.search;
      if (!status) delete _query.status;
      if (page === 1 || page === '1') delete _query.page;
      const query = queryString.stringify(_query);
      history.replace(`/invoices?${query}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getInvoicesCallback(page, search, statusValue, orderValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchValue = searchValue => {
    getInvoicesCallback(page, searchValue, statusValue, orderValue);
  };

  const handleSetStatus = status => {
    setStatusValue(status);
    getInvoicesCallback(1, search, status, orderValue);
    setDropdownWL(false);
  };

  const isActivated =
    user.is_staff || (!company?.is_blocked && company?.status === 2);

  const order = column => {
    const order = {
      value: orderValue?.value === 2 ? 1 : orderValue?.value === 1 ? 0 : 2,
      column,
    };
    setOrderValue(order);
    getInvoicesCallback(1, searchValue, statusValue, order);
  };

  return (
    <>
      <Header>
        <Title>Faturas</Title>
        {isActivated && company?.has_bankslip_detached && (
          <Button onClick={() => history.push('/invoices/create')}>
            <span>Nova fatura</span>
          </Button>
        )}
      </Header>

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          setValue={setSearch}
          noResults={invoices?.length === 0 && search}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Id',
              value: 'id',
            },
            {
              label: 'Plano',
              value: 'plan_name',
            },
            {
              label: 'Nome',
              value: 'full_name',
            },
            {
              label: 'Email',
              value: 'email',
            },
          ]}
        />
        <FilterWrapper>
          <Filter>
            <FontAwesomeIcon
              icon={faDollarSign}
              color={statusValue ? theme.brand_primary_color : theme.interface4}
              onClick={() => {
                setDropdownWL(!dropdownWL);
              }}
            />
            <FilterHover>
              <Value>
                <span>Status</span>
              </Value>
            </FilterHover>
          </Filter>

          {dropdownWL && (
            <div
              style={{
                position: 'absolute',
                bottom: '22px',
                right: '120px',
                zIndex: 2,
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                  zIndex: 998,
                }}
                onClick={() => setDropdownWL(false)}
              />
              <DropdownList>
                <ItemList
                  selected={statusValue === null}
                  onClick={() => handleSetStatus(null)}
                >
                  {statusValue === null && <FontAwesomeIcon icon={faCheck} />}
                  Todas
                </ItemList>

                <ItemList
                  selected={statusValue === 1}
                  onClick={() => handleSetStatus(1)}
                >
                  {statusValue === 1 && <FontAwesomeIcon icon={faCheck} />}
                  Pendentes
                </ItemList>

                <ItemList
                  selected={statusValue === 2}
                  onClick={() => handleSetStatus(2)}
                >
                  {statusValue === 2 && <FontAwesomeIcon icon={faCheck} />}
                  Atrasadas
                </ItemList>

                <ItemList
                  selected={statusValue === 3}
                  onClick={() => handleSetStatus(3)}
                >
                  {statusValue === 3 && <FontAwesomeIcon icon={faCheck} />}
                  Pagas
                </ItemList>
              </DropdownList>
            </div>
          )}
        </FilterWrapper>
      </SearchBar>

      {loading ? (
        <UserPlaceholder orderValue={orderValue} />
      ) : invoices?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col sm="1" className="d-none d-sm-flex">
                <p
                  onClick={() => {
                    order('id');
                  }}
                >
                  Id
                  <Ordering
                    status={orderValue?.column === 'id' ? orderValue?.value : 0}
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>
              </Col>

              <Col xs sm md="4" lg="5" xl="5">
                <p
                  onClick={() => {
                    order('email');
                  }}
                >
                  Email
                  <Ordering
                    status={
                      orderValue?.column === 'email' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p
                  onClick={() => {
                    order('value');
                  }}
                >
                  Valor
                  <Ordering
                    status={
                      orderValue?.column === 'value' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p
                  onClick={() => {
                    order('expiration');
                  }}
                >
                  Vencimento
                  <Ordering
                    status={
                      orderValue?.column === 'expiration'
                        ? orderValue?.value
                        : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>
              </Col>

              <Col className="d-none d-sm-none d-md-flex">
                <p>Nota Fiscal</p>
              </Col>

              <Col xl="2" className="d-none d-xl-flex" />
            </Row>
          </ListingHeader>

          <Listing>
            <CustomAnimatedList>
              {invoices.map(i => (
                <InvoiceCard i={i} key={i.id} />
              ))}
            </CustomAnimatedList>
          </Listing>

          <Pagination
            onChangePage={getInvoicesCallback}
            count={count}
            previous={previous}
            next={next}
            current={current}
            search={search}
            status={statusValue}
            order={orderValue}
            ticket
          />
        </>
      ) : (
        <NoResults>Nenhuma fatura</NoResults>
      )}
    </>
  );
};

export default props => (
  <InvoiceListingProvider>
    <Invoices {...props} />
  </InvoiceListingProvider>
);
