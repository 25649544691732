/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useAuth } from 'hooks/auth';
import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import WebSocketInstance from 'services/websocket';

export interface NotificationsState {
  notifications: any;
  visualizeAll: () => void;
  getNotifications: () => void;
  createNotification: (payload: any) => void;
  patchNotification: (payload: any, getNot?: boolean) => void;
}

export const NotificationsContext = createContext({} as NotificationsState);

export const NotificationsProvider: React.FC = ({ children }) => {
  const [data, setData] = useState({
    notifications: [],
  });

  const { user } = useAuth();
  const history = useHistory();

  const createNotification = payload => {
    toast.info(
      <p
        onClick={() =>
          payload.type !== 'migration' &&
          payload.type !== 'chat' &&
          history.push(`/${payload.type}s/${payload.resource_id}`)
        }
      >
        {payload.description}
      </p>,
      { toastId: payload.id },
    );

    setData(_data => {
      return {
        ..._data,
        notifications: [..._data.notifications, payload],
      };
    });
  };

  async function patchNotification(payload, getNot = true) {
    try {
      const { data } = await api.patch(
        `/v3/alert/${payload.id}/`,
        payload.data,
      );

      if (getNot) getNotifications();

      setData(_data => {
        const not = _data.notifications.filter(n => n && n.id !== data.id);

        const [_not] = _data.notifications.filter(n => n && n.id === data.id);

        return {
          ..._data,
          notifications: [
            ...not,
            {
              ..._not,
              status: data.status,
            },
          ],
        };
      });
    } catch {}
  }

  async function getNotifications() {
    const isAuthenticated = !!user;

    if (isAuthenticated) {
      try {
        const {
          data: { results },
        } = await api.get(`/v3/alert/`, {
          params: {
            status: 0,
          },
        });

        const _data = results
          .filter(r => r.type === 2 || r.type === 6)
          .map(r => ({
            ...r,
            type: r.type === 2 ? 'ticket' : 'migration',
          }));

        setData(nots => {
          return {
            ...nots,
            notifications: _data,
          };
        });
      } catch {}
    }
  }

  async function visualizeAll() {
    try {
      await api.post(`/v3/alert/check-all/`, { check_all: true });

      setData(_data => {
        const notifications = _data.notifications.map(
          n =>
            n && {
              ...n,
              status: 1,
            },
        );

        return {
          ..._data,
          notifications,
        };
      });
    } catch {}
  }

  useEffect(() => {
    if (user?.uuid) {
      WebSocketInstance.connectNotifications(user.uuid);
      WebSocketInstance.socketRef.onmessage = ({ data }) => {
        const { message } = JSON.parse(data);
        createNotification(message);
      };

      getNotifications();
    }
  }, [user]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications: data.notifications,
        visualizeAll,
        getNotifications,
        createNotification,
        patchNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default {
  NotificationsProvider,
};
