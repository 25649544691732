import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'services/api';

import { Button } from '@cloudez/cloudez-design-system';
import { FormInput, Label, Field } from 'components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth, useLayout } from 'hooks';
import { Content } from '../styles';

const schema = Yup.object().shape({
  full_name: Yup.string('Insira um nome válido.').required(
    'O nome é obrigatório.',
  ),
  email: Yup.string()
    .email('Insira um email válido.')
    .required('O email é obrigatório.'),
  password1: Yup.string()
    .min(8, 'A senha deve conter no mínimo 8 caracteres.')
    .required('A senha é obrigatória.'),
  password2: Yup.string()
    .min(8, 'A senha deve conter no mínimo 8 caracteres.')
    .oneOf([Yup.ref('password1'), null], 'As senhas são diferentes.')
    .required('Senha de confirmação é obrigatória.'),
});

const SignUp: React.FC = () => {
  const theme = useLayout();

  const { updateUserSuccess, setToken } = useAuth();

  const [recaptcha, setRecaptcha] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async data => {
    const payload = {
      ...data,
      recaptcha: process.env.NODE_ENV === 'development' ? 'SKIP' : recaptcha,
    };

    try {
      setLoading(true);
      const {
        data: { token, user },
      } = await api.post('/auth/company/signup/', payload);
      setLoading(false);
      toast.success('Cadastro concluído!');
      setToken(token);
      updateUserSuccess(user);
      // history.push('/');
    } catch (e) {
      toast.error(e.response.data[Object.keys(e.response.data)[0]][0]);
    }
  };

  const handleRecaptcha = value => {
    setRecaptcha(value);
  };

  return (
    <Content>
      <img src={theme.logo_primary} alt="logo" />
      <Form onSubmit={handleSubmit} schema={schema}>
        <Field>
          <Label>Nome completo</Label>
          <FormInput block="true" name="full_name" placeholder="Nome" />
        </Field>
        <Field>
          <Label>Email</Label>
          <FormInput
            type="email"
            block="true"
            name="email"
            placeholder="Email"
          />
        </Field>
        <Field>
          <Label>Digite uma senha secreta</Label>
          <FormInput
            block="true"
            type="password"
            name="password1"
            placeholder="Senha"
          />
        </Field>
        <Field>
          <Label>Repita a senha</Label>
          <FormInput
            block="true"
            type="password"
            name="password2"
            placeholder="Repita a senha"
          />
        </Field>
        <ReCAPTCHA
          sitekey="6LevzLsUAAAAAC7u1_NAdfbn_spDuO2gHE91nLHm"
          onChange={handleRecaptcha}
          style={{
            marginBottom: '14px',
          }}
        />
        <Button width="100%" type="submit" disabled={!recaptcha} icon>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Registrar'}
        </Button>
        <p>
          Já possui uma conta? <Link to="/">Acessar</Link>
        </p>
      </Form>
    </Content>
  );
};

export default SignUp;
