import React, { useMemo } from 'react';

import {
  faPowerOff,
  faLink,
  faBarcode,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from 'services/history';
import { useAuth } from 'hooks';
import { Wrapper, Links } from './styles';

interface IDropdownProps {
  dropdown: boolean;
  setDropdown: (value: boolean) => void;
}

const Dropdown: React.FC<IDropdownProps> = ({ setDropdown, dropdown }) => {
  const { company, signOut, user } = useAuth();

  const domain = company.app_domains.find(d => !d.includes('cloudez.app'));

  const invoiceRoles = useMemo(
    () => ['financial_role', 'customer_success_role'],
    [],
  );

  return (
    <Wrapper>
      <Links>
        {company && (
          <li>
            <FontAwesomeIcon icon={faLink} />
            <a
              href={`https://${company.app_domains[0]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Acessar o painel
            </a>
          </li>
        )}
        <li>
          <a
            href={`https://${domain}/account/?tab=3`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faKey} />
            Segurança
          </a>
        </li>
        {(invoiceRoles.some(role => user.roles.includes(role)) ||
          user.roles.includes('company_owner_role')) && (
          <li
            onClick={() => {
              history.push('/invoices');
              setDropdown(!dropdown);
            }}
          >
            <FontAwesomeIcon icon={faBarcode} />
            Faturas
          </li>
        )}
        <li onClick={() => signOut()}>
          <FontAwesomeIcon icon={faPowerOff} />
          Sair
        </li>
      </Links>
    </Wrapper>
  );
};

export default Dropdown;
