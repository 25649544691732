import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;

  background: ${props => props.theme.brand_primary_color};
  border-radius: 50%;
`;
