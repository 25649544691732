import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

import Slider from 'react-slick';
import { Arrows } from 'components/Arrows';
import { Button } from '@cloudez/cloudez-design-system';

import { NoResultsDetail } from 'components/NoResults';
import { Checkbox, Field, Input, Label } from 'components/Input';
import Pagination from 'components/Pagination';
import { NewPlan, Container, SliderContainer } from './styles';

import PlanCard from './PlanCard';

import NewPlanModal from './NewPlanModal';
import PlanPlaceholder from './Placeholder';
import WebsiteTypes from './WebsiteTypes';

const NewPlans = ({
  planLoading,
  plans,
  setPlans,
  basePlans,
  setSelectedPlan,
  selectedPlan,
  setSelectedNodeappType,
  selectedNodeappType,
  domain,
  setDomain,
  getPlans,
  onboard = false,
}) => {
  const [newPlanModal, setNewPlanModal] = useState(false);
  const [createWebsite, setCreateWebsite] = useState(false);

  function NextArrow(props) {
    const { onClick, currentSlide } = props;
    return (
      <Arrows
        right="true"
        onClick={onClick}
        icon={faChevronRight}
        disabled={currentSlide === 10}
      />
    );
  }

  function PrevArrow(props) {
    const { onClick, currentSlide } = props;

    return (
      <Arrows
        left="true"
        onClick={onClick}
        icon={faChevronLeft}
        disabled={currentSlide === 0}
      />
    );
  }

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    focusOnSelect: true,
    centerPadding: '60px',
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {newPlanModal && (
        <NewPlanModal
          plans={plans}
          setPlans={setPlans}
          basePlans={basePlans}
          show={newPlanModal}
          setShow={() => setNewPlanModal(false)}
        />
      )}
      <Container>
        <Row>
          {/* <Col xl="3" lg="3" md="3" sm="6" xs="12">
            <NewPlan>
              <span>NOVO PLANO</span>
              <Button size="sm" onClick={() => setNewPlanModal(true)}>
                Criar
                <FontAwesomeIcon icon={faPlusSquare} />
              </Button>
            </NewPlan>
          </Col> */}

          {!planLoading ? (
            plans?.plans?.length > 0 ? (
              plans?.plans?.map(p => {
                const basePlan = basePlans?.filter(
                  _p => _p.id === p.cloud_size,
                )[0];

                return (
                  <Col key={p.id} xl="3" lg="3" md="3" sm="6" xs="12">
                    <PlanCard
                      p={p}
                      // basePlan={basePlan}
                      setSelectedPlan={setSelectedPlan}
                      selectedPlan={selectedPlan}
                      onboard={onboard}
                    />
                  </Col>
                );
              })
            ) : (
              <Col xl="2" lg="3" md="4" sm="6" xs="12">
                <NoResultsDetail height="234px" width="100%">
                  Nenhum plano
                </NoResultsDetail>
              </Col>
            )
          ) : (
            <PlanPlaceholder />
          )}
        </Row>
      </Container>
      {/* Responsive slider container */}
      <SliderContainer>
        {planLoading ? (
          <div>
            <PlanPlaceholder />
          </div>
        ) : (
          <Slider {...settings}>
            <div>
              <NewPlan>
                <span>NOVO PLANO</span>
                <Button size="sm" onClick={() => setNewPlanModal(true)}>
                  Criar
                  <FontAwesomeIcon icon={faPlusSquare} />
                </Button>
              </NewPlan>
            </div>
            {plans?.plans?.length > 0 &&
              plans?.plans?.map(p => {
                // const basePlan =
                //   basePlans &&
                //   basePlans.filter(_p => _p.id === p.cloud_size)[0];

                return (
                  <div key={p.id}>
                    <PlanCard
                      p={p}
                      // basePlan={basePlan}
                      setSelectedPlan={setSelectedPlan}
                      selectedPlan={selectedPlan}
                      onboard={onboard}
                    />
                  </div>
                );
              })}
          </Slider>
        )}
      </SliderContainer>
      <Pagination
        onChangePage={getPlans}
        count={plans?.count}
        previous={plans?.previous}
        next={plans?.next}
        current={plans?.current}
        pageSize={8}
        style={{
          alignSelf: 'flex-start',
        }}
      />

      {selectedPlan?.category === 1 && (
        <>
          <Field>
            <Label>Criar website?</Label>
            <Checkbox
              checked={createWebsite}
              value={createWebsite}
              onChange={e => setCreateWebsite(e.target.checked)}
            />
          </Field>
          {createWebsite && (
            <>
              <Input
                block
                placeholder="Digite aqui seu domínio"
                value={domain}
                onChange={e => setDomain(e.target.value)}
              />
              <WebsiteTypes
                setSelectedNodeappType={setSelectedNodeappType}
                selectedNodeappType={selectedNodeappType}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewPlans;
