export default (state, action) => {
  switch (action.type) {
    case 'GET_DASHBOARD':
      return {
        ...state,
        dashboard: action.payload,
      };

    default: {
      return state;
    }

    case 'SET_DASHBOARD':
      return {
        ...state,
        dashboard: action.payload,
      };
  }
};
