import { useState } from 'react';

import Cropper from 'react-easy-crop';
import { Row } from 'react-bootstrap';

import { faImage } from '@fortawesome/free-solid-svg-icons';

import { Icon, IconHover } from 'components/HoverableIcon/styles';
import { Field, Label, Range } from 'components/Input';
import { Header } from 'components/Common';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Content,
  Footer,
  Subtitle,
  Text,
  Title,
} from 'components/ListingModal/styles';

import { CropperWrapper, FileUploadButton, CropperModal } from './styles';

interface ImageCropperProperties {
  show: boolean;
  setShow: (value?: boolean) => void;
  title: string;
  subtitle: string;
  cropSize: { width: number; height: number };
  onConfirm: (value: null | string) => void;
}

export default function ImageCropper({
  cropSize,
  onConfirm,
  show,
  setShow,
  title,
  subtitle,
}: ImageCropperProperties): JSX.Element {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imgSrc, setImgSrc] = useState(null);
  const [croppedImage, setCropppedImage] = useState(null);

  const [filename, setFilename] = useState('');
  const [extension, setExtension] = useState('');

  const handleInput = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setImgSrc(reader.result);
      },
      false,
    );

    if (file instanceof File) {
      setFilename(file.name.substring(0, file.name.lastIndexOf('.')));
      setExtension(file.name.split('.').pop());
      reader.readAsDataURL(file);
    }
  };

  const createInput = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.png,.jpeg,.gif,.jpg');
    fileSelector.addEventListener('input', handleInput);
    return fileSelector;
  };

  const fileInput = createInput();

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  const getFiletype = ext => {
    const typeMap = {
      png: 'image/png',
      gif: 'image/gif',
    };

    const mimetype = typeMap[ext];

    if (mimetype) return mimetype;

    setExtension('png');
    return 'image/png';
  };

  const getCroppedImg = async (imageSrc, crop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as any;

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(new File([blob], `${filename}.${extension}`));
      }, getFiletype(extension));
    });
  };

  const handleCropComplete = (_, cropPixels) => {
    getCroppedImg(imgSrc, cropPixels)
      .then(image => setCropppedImage(image))
      .catch(e => console.log(e));
  };

  const handleConfirm = () => {
    onConfirm(croppedImage);
    setShow();
  };

  const handleChooseAnother = () => {
    // resetCropper();
    fileInput.click();
  };

  return (
    <CropperModal show={show} setShow={setShow}>
      <Header className="pb-4">
        <Text>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Text>
      </Header>

      <Content>
        <div className="mb-2">
          <Label className="mb-0">Formatos: .png*, .jpg, .jpeg, .gif</Label>
        </div>
        {!imgSrc && <FileUploadButton onClick={() => fileInput.click()} />}

        {imgSrc && (
          <>
            <CropperWrapper>
              <Cropper
                style={{
                  cropAreaStyle: {
                    border: '2px solid black',
                  },
                  containerStyle: {
                    background: '#383838',
                  },
                }}
                image={imgSrc}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={handleCropComplete}
                onZoomChange={setZoom}
                zoomSpeed={0.05}
                cropSize={cropSize}
                minZoom={0.1}
                restrictPosition={false}
              />
            </CropperWrapper>
            <Row className="mt-4">
              <Field>
                <Label>Zoom</Label>
                <Range
                  block
                  value={zoom}
                  onChange={(e: any) => setZoom(e.target.value)}
                  min="0.1"
                  max="3"
                  step="0.05"
                />
              </Field>
            </Row>
            <Button
              width="100%"
              outline
              className="mt-2"
              onClick={() => handleChooseAnother()}
            >
              Escolher outra imagem
              <Icon icon={faImage} />
              <IconHover
                style={{ width: '18px', height: '18px' }}
                icon={faImage}
              />
            </Button>
          </>
        )}
      </Content>

      <Footer>
        <Button className="mr-4" error outline onClick={() => setShow(false)}>
          Cancelar
        </Button>
        <Button onClick={handleConfirm}>Confirmar</Button>
      </Footer>
    </CropperModal>
  );
}
