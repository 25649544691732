import { Container, Table, Title } from './styles';

interface TablePricesProps {
  pricing: {
    id: number;
    name: string;
    description: string;
    plans: {
      id: number;
      name: string;
      value: number;
    }[];
  }[];
}

const TablePrices: React.FC<TablePricesProps> = ({ pricing }) => {
  return (
    <Container>
      {pricing.map(item => (
        <div className="content">
          <Title key={item.id}>
            <span>{item.name}</span>
            <span className="description">{item.description}</span>
          </Title>
          <Table>
            <thead>
              <tr className="headLine">
                <td className="namePlan">Nome do Plano</td>
                <td className="classPlan">Turbo</td>
              </tr>
            </thead>
            <tbody>
              {item.plans.map(item => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                    }).format(item.value)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </Container>
  );
};

export default TablePrices;
