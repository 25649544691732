import { useEffect, useState, useContext, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { ThemeContext } from 'styled-components';

import CurrencyFormat from 'react-currency-format';
import { Row, Col } from 'react-bootstrap';

import moment from 'moment';

import { Header, Title, Breadcrumbs, Breadcrumb } from 'components/Common';
import { Button } from '@cloudez/cloudez-design-system';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from 'hooks';
import useDevice from 'hooks/device';
import history from 'services/history';
import { getCreditcardService } from 'services/common';
import CreditModal from './CreditModal';

import { InvoiceDetailProvider, InvoiceDetailContext } from './_context/state';
import {
  Separator,
  Card,
  CompanyBillingInfo,
  UserBillingInfo,
  OrderStatus,
  OrderBadge,
  InvoiceItemsHeader,
  InvoiceItems,
  InvoiceItem,
} from './styles';
import Placeholder from './Placeholder';
import ReactivateModal from './ReactivateModal';
import ExistentCreditCardModal from './ExistentCardModal';

const InvoiceDetail = ({ match }) => {
  const { company } = useAuth();

  const [proofSent, setProofSent] = useState(false);

  const { isMobile } = useDevice();

  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [showExistentCreditCardModal, setShowExistentCreditCardModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useContext(ThemeContext);
  const {
    invoice,
    user,
    userBilling,
    companyBilling,
    getInvoice,
    getCompanyBilling,
    getUserBilling,
    getCreditCard,
    getUser,
  } = useContext(InvoiceDetailContext);

  const [creditCard, setCreditCard] = useState(null);

  useEffect(() => {
    async function getCreditCard() {
      try {
        const response = await getCreditcardService();

        setCreditCard(response?.data[0]);
      } catch (e) {}
    }

    getCreditCard();
  }, []);

  useEffect(() => {
    const { id } = match.params;

    (async () => {
      setLoading(true);
      if (!invoice) {
        await getInvoice(id);
      } else {
        await getCompanyBilling(company.code);
        await getUserBilling(invoice.user);
        await getUser(invoice.user);
        await getCreditCard();
      }
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, [invoice]);

  const [creditModal, setCreditModal] = useState(false);

  const today = moment();
  const expiredAt = invoice && moment(invoice.expired_at);
  const isExpired = expiredAt && expiredAt.isBefore(today, 'days');

  const hasNF = useMemo(() => !!invoice?.nf, [invoice]);

  return invoice && user && !loading ? (
    <>
      {showExistentCreditCardModal && setShowExistentCreditCardModal && (
        <ExistentCreditCardModal
          invoiceLink={`https://${company.app_domains[0]}/invoices/bank-slip/${invoice.public_id}/`}
          show={showExistentCreditCardModal}
          setShow={setShowExistentCreditCardModal}
        />
      )}
      {creditModal && (
        <CreditModal
          show={creditModal}
          setShow={() => setCreditModal(false)}
          action={() => getInvoice(invoice.id)}
          billing={companyBilling}
          invoice={invoice}
        />
      )}
      {showReactivateModal && (
        <ReactivateModal
          show={showReactivateModal}
          setShow={setShowReactivateModal}
          id={invoice.id}
          proofSent={() => setProofSent(true)}
        />
      )}
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/invoices">Faturas</Link>
            </Breadcrumb>
          </Breadcrumbs>
          Fatura #{invoice.id}
        </Title>

        {hasNF && (
          <Button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();

              window.open(invoice.nf);
            }}
          >
            Baixar NF-<span style={{ textTransform: 'lowercase' }}>e</span>
          </Button>
        )}
      </Header>

      <Card>
        <Row
          style={{
            height: '150px',
          }}
        >
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {companyBilling && (
              <CompanyBillingInfo>
                <h1>{companyBilling.fantasy_name}</h1>
                <div
                  style={{
                    marginTop: '8px',
                  }}
                >
                  <p>
                    {companyBilling.address1 && `${companyBilling.address1}, `}{' '}
                    {companyBilling.address_number &&
                      `${companyBilling.address_number}, `}{' '}
                    {companyBilling.address2}
                  </p>
                  <p>
                    {companyBilling.city && `${companyBilling.city}, `}{' '}
                    {companyBilling.state && `${companyBilling.state} - `}{' '}
                    {companyBilling.zipcode}
                  </p>
                  {companyBilling.document_id && (
                    <p>
                      CNPJ:
                      {companyBilling.document_id}
                    </p>
                  )}
                </div>
              </CompanyBillingInfo>
            )}
          </Col>

          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {/* <img height="90px" alt="logo" src={cloudez} /> */}
          </Col>
        </Row>
        <Separator />

        <Row
          style={{
            // height: '100px',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {userBilling && (
              <UserBillingInfo>
                <div
                  style={{
                    marginTop: '8px',
                  }}
                >
                  {user.full_name && <span>Fatura para</span>}
                  <p>{user.full_name}</p>
                  <p>
                    {userBilling.address1}{' '}
                    {userBilling.address2 && `, ${userBilling.address2}`}
                    {userBilling.address_number &&
                      `, ${userBilling.address_number}`}
                  </p>
                  <p>
                    {userBilling.city}{' '}
                    {userBilling.state && `, ${userBilling.state}`}
                    {userBilling.zipcode && `- ${userBilling.zipcode}`}
                  </p>
                </div>
              </UserBillingInfo>
            )}
          </Col>
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: isMobile ? 'flex-start' : 'flex-end',
              alignItems: 'center',
            }}
          >
            <OrderStatus>
              <span>
                Data do pedido:{' '}
                <p>{moment(invoice.created_at).format('DD/MM/YYYY')}</p>
              </span>
              <span>
                Status do pedido:{' '}
                <OrderBadge expired={isExpired} paid={invoice.is_paid}>
                  {invoice.is_paid
                    ? 'Fatura Paga'
                    : isExpired
                    ? 'Fatura não paga'
                    : 'Processando NF'}
                </OrderBadge>
              </span>
            </OrderStatus>
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={() => setShowReactivateModal(true)}
            alert
            width="280px"
          >
            Enviar Comprovante
          </Button>

          {!invoice.is_paid && company.status === 2 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {companyBilling?.balance?.amount > 0 && (
                <Button onClick={() => setCreditModal(true)} outline>
                  Aplicar crédito
                </Button>
              )}
              <Button
                style={{ marginLeft: '20px' }}
                onClick={() =>
                  history.push(`/invoices/external/${invoice.public_id}`)
                }
              >
                Pagar agora
              </Button>
            </div>
          )}
        </div>

        <InvoiceItems>
          <InvoiceItemsHeader>
            <Row>
              <Col>Plano</Col>
              <Col>Preço</Col>
              <Col className="d-none d-md-flex ">Mês de referência</Col>
              <Col className="d-none d-md-flex ">Data de expiração</Col>
            </Row>
          </InvoiceItemsHeader>
          {invoice.items.map(i => (
            <InvoiceItem key={i.id}>
              <Row
                style={{
                  color: theme.interface5,
                  fontSize: '1rem',
                }}
              >
                <Col>{i.description}</Col>
                <Col
                  style={{
                    fontWeight: 'bolder',
                  }}
                >
                  <CurrencyFormat
                    value={i.price.amount}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={invoice.total.currency === 'BRL' ? 'R$ ' : '$ '}
                  />
                </Col>
                <Col className="d-none d-md-flex ">
                  {invoice.reference_date &&
                    moment(invoice.reference_date).format('MM/YYYY')}
                </Col>
                <Col className="d-none d-md-flex ">
                  {moment(invoice.expired_at).format('DD/MM/YYYY')}
                </Col>
              </Row>
            </InvoiceItem>
          ))}
        </InvoiceItems>
      </Card>
    </>
  ) : (
    <Placeholder />
  );
};

export default props => (
  <InvoiceDetailProvider>
    <InvoiceDetail {...props} />
  </InvoiceDetailProvider>
);
