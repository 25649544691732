import moment from 'moment';
import React from 'react';

import { Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';

import ApplicationIcon from 'components/ApplicationIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faGlobeAmericas,
  faBarcode,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import kb from 'utils/kbToMb';
import cloudez_icon from 'assets/img/svg/cloud_cloudez.svg';
import { DomainIcon } from '../../styles';
import { Card, Content, Logo } from './styles';

type ResourceItemProps = {
  gap?: string;
  c: any;
  type: number | string;
  resource?: any;
  onClick?: () => void;
  removeResource?: () => void;
  isResource?: boolean;
};

const ResourceItem: React.FC<ResourceItemProps> = ({
  type,
  resource,
  removeResource,
  onClick,
  c,
  isResource = false,
  gap,
}) => {
  const fqdnSplitted = {
    email: c?.cloud_fqdn?.split('.'),
    website: c?.cloud?.fqdn?.split('.'),
  }[type];

  return (
    <Col
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      }}
    >
      <Card
        gap={gap}
        resource={isResource}
        cloud={c}
        selected={c?.id === resource}
        onClick={onClick}
      >
        <Content>
          {type === 'cloud' ? (
            <Logo>
              <img src={cloudez_icon} width="21px" alt="logo" />
            </Logo>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {type === 'domain' ? (
                <DomainIcon disable={!c?.is_active}>
                  <FontAwesomeIcon icon={faGlobeAmericas} size="2x" />
                </DomainIcon>
              ) : type === 'website' || type === 'email' ? (
                <ApplicationIcon
                  icon={
                    c?.type?.slug === 'selfhosted-mail'
                      ? 'cloudez-footer'
                      : c?.type?.slug
                  }
                  width="21px"
                  height="21px"
                />
              ) : (
                <DomainIcon>
                  <FontAwesomeIcon
                    style={{ color: '#000000' }}
                    icon={faBarcode}
                    size="2x"
                  />
                </DomainIcon>
              )}
            </div>
          )}
        </Content>
        <div
          style={{
            justifyContent:
              type === 'domain' || type === 'cloud'
                ? 'flex-start'
                : 'space-between',
          }}
          className="infosContent"
        >
          <span
            style={{ fontWeight: type === 'cloud' ? 'normal' : 'bold' }}
            className="domain"
          >
            {type === 'cloud'
              ? c?.nickname || c?.name
              : type === 'invoice'
              ? `#${c?.id} ${c?.user_email}`
              : c?.domain || c?.nickname}
          </span>
          {type === 'website' || type === 'email' || type === 'invoice' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                width: '115px',
              }}
            >
              {type === 'invoice' ? (
                <>
                  <div>
                    <span>Vencimento:</span>&nbsp;
                    <span style={{ fontWeight: 'normal' }}>
                      {moment(c?.expired_at).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <div>
                    <span>Pagamento:</span>&nbsp;
                    <span style={{ fontWeight: 'normal' }}>
                      {c?.paid_at && moment(c.paid_at).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {c.type?.name !== 'Self Hosted' && type === 'email' ? (
                    <span>Email Externo</span>
                  ) : (
                    <>
                      <span
                        style={{
                          fontWeight: 'normal',
                        }}
                      >
                        {fqdnSplitted && fqdnSplitted[0]}
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {type === 'email'
                          ? c?.cloud_display
                          : c?.cloud?.display}
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          ) : null}

          {type === 'domain' || type === 'website' || type === 'email' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
              }}
            >
              <span>
                {type === 'website' || type === 'email'
                  ? 'Disco:'
                  : 'Atualizado em:'}
              </span>
              <span
                style={{
                  fontWeight: 'normal',
                }}
              >
                {type === 'domain'
                  ? c?.last_modified
                  : `${kb(c?.disk_usage)} MB`}
              </span>
            </div>
          ) : null}

          {type === 'invoice' && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                }}
              >
                <span>Valor:</span>
                <CurrencyFormat
                  style={{
                    fontWeight: 'normal',
                  }}
                  value={c?.total?.amount}
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={c?.total?.currency === 'BRL' ? 'R$ ' : '$ '}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                }}
              >
                <span>Status:</span>
                <span
                  style={{
                    color: c?.is_paid ? '#29D975' : '#db1414',
                  }}
                >
                  {c?.is_paid ? 'Pago' : 'Pendente'}
                </span>
              </div>
            </div>
          )}
          {type === 'cloud' && (
            <p>
              {c?.cloud_size?.name} {c?.cloud_size?.disk}
            </p>
          )}
        </div>
        {resource !== null && (
          <div className="iconRemove">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={removeResource}
              color="#64728C"
            />
          </div>
        )}
      </Card>
    </Col>
  );
};

export default ResourceItem;
