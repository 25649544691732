import React from 'react';

import { Row, Col } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';
import { NoResultsDetail } from 'components/NoResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'hooks';
import { CloudCard, Content, Logo } from './styles';

const Shared = ({ websites, emails }) => {
  const resources = websites.concat(emails);

  const { company } = useAuth();

  return (
    <Row
      style={{
        width: '100%',
        margin: '0',
      }}
    >
      {resources?.length ? (
        resources.map(r => {
          const isEmail = {
            'selfhosted-mail': true,
            gsuite: true,
            zoho: true,
            office365: true,
          }[r.type.slug];

          return (
            <Col key={r.id} xl="2" lg="3" md="4" sm="6" xs="12">
              <CloudCard
                onClick={() =>
                  window.open(
                    `https://${company.app_domains[0]}/${
                      isEmail !== undefined ? 'emails' : 'websites'
                    }/${r.id}`,
                  )
                }
              >
                <Content>
                  <Logo>
                    <ApplicationIcon
                      width="45px"
                      height="45px"
                      icon={
                        r.type.slug === 'selfhosted-mail'
                          ? 'cloudez-footer'
                          : r.type.slug
                      }
                    />
                  </Logo>
                </Content>
                <span>{r.domain}</span>
              </CloudCard>
            </Col>
          );
        })
      ) : (
        <Col xl="2" lg="3" md="4" sm="6" xs="12">
          <NoResultsDetail width="100%" height="100px">
            <FontAwesomeIcon icon={faWindowMaximize} />
            Nenhum recurso comp.
          </NoResultsDetail>
        </Col>
      )}
    </Row>
  );
};

export default Shared;
