import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import api from 'services/api';
import {
  CardBody,
  CardContainer,
  CardContainerFirst,
  CardInvoice,
  CardTable,
  TdContent,
  TdFinal,
  TdTitle,
  Th,
  Tr,
} from '../styles';
import DashboardCard from './Placeholder';

const Card = () => {
  const [dashboard, setDashboard] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getCard() {
      setLoading(true);
      try {
        const { data } = await api.get(`/v3/company/dashboard_summary/`);
        setDashboard(data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }

    if (!dashboard) getCard();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <DashboardCard />
      ) : (
        <>
          <CardTable>
            <CardBody>
              <table
                cellSpacing="0"
                style={{
                  width: '100%',
                }}
              >
                <thead>
                  <tr>
                    <Th
                      style={{
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                      }}
                      responsive
                    >
                      Receita{' '}
                      <span
                        style={{
                          fontWeight: 'normal',
                          fontSize: '0.875rem',
                        }}
                      >
                        {' '}
                        / 30 dias
                      </span>
                    </Th>
                    <Th
                      style={{
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                      }}
                      responsive
                    >
                      Clientes
                    </Th>
                    <Th responsive>Preço</Th>
                    <Th>Receita bruta</Th>
                    <Th
                      style={{
                        borderTopRightRadius: '5px',
                        borderBottomRightRadius: '5px',
                      }}
                    >
                      Receita líquida
                    </Th>
                  </tr>
                </thead>
                <tbody>
                  {dashboard?.map(s => (
                    <Tr name={s.name} key={s.name}>
                      <TdTitle>{s.name}</TdTitle>
                      <TdContent responsive>{s.customers}</TdContent>
                      <TdContent responsive>
                        <CurrencyFormat
                          value={s.cost.amount}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={s.cost.currency === 'BRL' ? 'R$ ' : '$ '}
                        />
                      </TdContent>
                      <TdContent>
                        <CurrencyFormat
                          value={s.breceipt.amount}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={s.breceipt.currency === 'BRL' ? 'R$ ' : '$ '}
                        />
                      </TdContent>
                      <TdFinal>
                        <CurrencyFormat
                          value={s.lreceipt.amount}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={s.lreceipt.currency === 'BRL' ? 'R$ ' : '$ '}
                        />
                      </TdFinal>
                    </Tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </CardTable>
        </>
      )}
    </>
  );
};

export default Card;
