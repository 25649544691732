import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  faBell,
  faChevronDown,
  faMoon,
  faSun,
  faChevronUp,
  faQuestionCircle,
  faBullhorn,
  faSignal,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBell as fasBell,
  faMoon as fasMoon,
  faSun as fasSun,
  faQuestionCircle as fasQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth, useLayout, useNotifications } from 'hooks';
import { Button } from '@cloudez/cloudez-design-system';
import useDevice from 'hooks/device';
import {
  Wrapper,
  Container,
  IconList,
  IconItem,
  User,
  Count,
  DomainsDropdown,
} from './styles';
import CollapseButton from './CollapseButton';
import Dropdown from './Dropdown';
import NavbarElipse from './NavbarElipse';
import NotificationsDropdown from './NotificationsDropdown';

interface INavbarProps {
  hasNotification?: boolean;
}

const Navbar: React.FC<INavbarProps> = ({ hasNotification }) => {
  const { notifications } = useNotifications();

  const { user, company } = useAuth();
  const { toggleDarkMode } = useLayout();
  const theme = useLayout();
  const isAuthenticated = useMemo(() => !!user, [user]);
  const { isMobile } = useDevice();

  const [dropdown, setDropdown] = useState<boolean>();
  const [dropdownWL, setDropdownWL] = useState<boolean>();
  const [notificationsDropdown, setNotificationsDropdown] = useState<boolean>();
  const headRef = useRef();

  useEffect(() => {
    if (headRef.current) {
      const { Headway } = window as any;

      if (Headway)
        Headway.init({
          selector: '#bullhorn',
          account: 'J1W2nx',
          translations: {
            title: 'Novidades',
            footer: 'Novidades Cloudez',
          },
        });
    }
  }, [headRef]);

  const count = useMemo(
    () =>
      notifications?.filter(n => n && n.status === 0 && n.type !== 'chat')
        .length,
    [notifications],
  );

  const isActivated =
    user?.is_staff || (!company?.is_blocked && company?.status === 2);

  return (
    <Wrapper hasNotification={hasNotification}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CollapseButton />
        {!isMobile && isActivated && (
          <div style={{ position: 'relative' }}>
            <Button
              size="sm"
              style={{ width: '160px', height: '30px' }}
              className="ml-5"
              outline
              onClick={() => {
                setDropdownWL(!dropdownWL);
              }}
            >
              ACESSAR O PAINEL
              <FontAwesomeIcon
                className="m-0 ml-2"
                icon={dropdownWL ? faChevronUp : faChevronDown}
              />
            </Button>

            {dropdownWL && (
              <div
                style={{
                  position: 'absolute',
                  left: '48px',
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                    zIndex: 998,
                  }}
                  onClick={() => setDropdownWL(false)}
                />
                <DomainsDropdown>
                  <ul>
                    {company.app_domains.map((domain, i) => (
                      <li key={i}>
                        <a
                          href={`https://${domain}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={domain}
                        >
                          {domain}
                        </a>
                      </li>
                    ))}
                  </ul>
                </DomainsDropdown>
              </div>
            )}
          </div>
        )}
      </div>

      <Container>
        <IconList>
          {isAuthenticated && (
            <>
              {!company?.is_provider && (
                <>
                  <IconItem>
                    <NavbarElipse
                      ref={headRef}
                      id="bullhorn"
                      icon={faBullhorn}
                      hover={faBullhorn}
                    />
                  </IconItem>
                  <IconItem
                    onClick={() => {
                      window.open('https://help.cloudez.io', '_blank');
                    }}
                    hideOnMobile
                  >
                    <NavbarElipse
                      icon={faQuestionCircle}
                      hover={fasQuestionCircle}
                    />
                  </IconItem>
                </>
              )}
              <IconItem
                data-testid="notificationsIcon"
                onClick={() => {
                  setDropdown(false);
                  setNotificationsDropdown(!notificationsDropdown);
                }}
              >
                <NavbarElipse icon={faBell} hover={fasBell} />
                {count > 0 && <Count>{count}</Count>}
                {notificationsDropdown && (
                  <NotificationsDropdown
                    setDropdown={setNotificationsDropdown}
                    // dropdown={notificationsDropdown}
                  />
                )}
              </IconItem>
              <IconItem
                onClick={() => {
                  toggleDarkMode();
                }}
                hideOnMobile
              >
                {theme.darkMode ? (
                  <NavbarElipse icon={faSun} hover={fasSun} />
                ) : (
                  <NavbarElipse icon={faMoon} hover={fasMoon} />
                )}
              </IconItem>
              <IconItem onClick={() => setDropdown(!dropdown)}>
                <User active={dropdown}>
                  <div>
                    <img
                      alt="avatar"
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                      }}
                      src={user.avatar}
                    />
                    <span>{user.full_name}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </User>
              </IconItem>
            </>
          )}
        </IconList>
        {dropdown && (
          <div
            style={{
              position: 'absolute',
              zIndex: 2,
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              }}
              onClick={() => setDropdown(false)}
            />
            <Dropdown
              // avatar={user.avatar}
              // name={user.full_name}
              setDropdown={setDropdown}
              dropdown={dropdown}
            />
          </div>
        )}
      </Container>
    </Wrapper>
  );
};

export default Navbar;
