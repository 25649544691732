import React, { useState, useEffect, useContext } from 'react';

import { toast } from 'react-toastify';

import queryString from 'query-string';

import api from 'services/api';
import history from 'services/history';

import { Tabs, Tab, TabContent } from 'components/DetailConfig';

import { getSurveySlugService } from 'services/survey';
import { useAuth, useDocTitle } from 'hooks';
import Form from './Form';

import Visual from './Visual';

import { Wrapper } from './styles';
import { CompanyProvider, CompanyContext } from './_context/state';
import Users from './Users';
import Preferences from './Preferences';

const Company = () => {
  const { tab: _tab } = queryString.parse(history.location.search);
  useDocTitle('Empresa - Cloudez Partners');

  const { company, getCompany } = useAuth();

  const {
    primaryLogo,
    reducedLogo,
    favicon,
    setPrimaryLogo,
    setReducedLogo,
    setFavicon,
  } = useContext(CompanyContext);

  useEffect(() => {
    setPrimaryLogo(company.logo_primary);
    setReducedLogo(company.logo_reduced);
    setFavicon(company.favicon);
  }, []);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState(company.name);
  const [color1, setColor1] = useState(company.brand_primary_color);
  const [color2, setColor2] = useState(company.brand_secondary_color);
  const [survey, setSurvey] = useState();

  const [tab, setTab] = useState(Number(_tab) || 1);

  const updateCompany = async () => {
    const data = new FormData();

    if (primaryLogo !== company.logo_primary)
      data.append('logo_primary', primaryLogo);
    if (reducedLogo !== company.logo_reduced)
      data.append('logo_reduced', reducedLogo);
    if (favicon !== company.favicon) data.append('favicon', favicon);

    data.append('brand_primary_color', color1);
    data.append('brand_secondary_color', color2);
    data.append('name', name);

    try {
      setLoading(true);
      await api.patch(`/v3/company/${company.code}/`, data);
      setErrors({});
      getCompany();
      setLoading(false);
      toast.success('Company atualizada com sucesso');
    } catch (e) {
      setErrors({
        ...errors,
        ...e.response.data,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getSurvey() {
      const surveySlug = 'partners-churn';

      try {
        const { data } = await getSurveySlugService(surveySlug);
        setSurvey(data);
      } catch (e) {
        console.log(e);
      }
    }

    getSurvey();
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Tabs>
        <Tab onClick={() => setTab(1)} active={tab === 1}>
          Visual
        </Tab>
        <Tab onClick={() => setTab(2)} active={tab === 2}>
          Dados Financeiros
        </Tab>
        <Tab onClick={() => setTab(3)} active={tab === 3}>
          Usuários
        </Tab>
        <Tab onClick={() => setTab(4)} active={tab === 4}>
          Preferências
        </Tab>
      </Tabs>

      <TabContent noPadding={tab === 2} noBackground={tab === 2}>
        {tab === 1 && company && (
          <Visual
            survey={survey}
            color1={color1}
            color2={color2}
            loading={loading}
            setName={setName}
            setColor1={setColor1}
            setColor2={setColor2}
            updateCompany={updateCompany}
            name={name}
            errors={errors}
          />
        )}

        {tab === 2 && <Form company={company} tab={tab} />}

        {tab === 3 && <Users />}
        {tab === 4 && <Preferences />}
      </TabContent>
    </Wrapper>
  );
};

export default props => (
  <CompanyProvider>
    <Company {...props} />
  </CompanyProvider>
);
