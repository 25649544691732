import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IArrowsProps {
  disabled?: boolean;
  left?: string;
  right?: string;
  actions?: boolean | string;
}

export const Arrows = styled(FontAwesomeIcon)<IArrowsProps>`
  color: ${props =>
    props.disabled ? props.theme.interface4 : props.theme.brand_primary_color};
  position: absolute;
  top: 50%;
  display: block;
  font-size: 24px;

  ${props =>
    props.left &&
    css`
      left: -25px;
    `}

  ${props =>
    props.right &&
    css`
      right: -25px;
    `}

  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;

  @media (max-width: 1024px) {
    ${props =>
      props.left &&
      props.actions &&
      css`
        left: -10px;
      `}

    ${props =>
      props.right &&
      props.actions &&
      css`
        right: -10px;
      `}
  }
`;
