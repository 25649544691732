import React, { useContext, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faDollarSign,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { Input, Label, Field, Checkbox, Error } from 'components/Input';
import { Hover, HoverText } from 'components/Hover';
import { Col, Row } from 'react-bootstrap';
import { PlanListingContext } from '../_context/state';
import { Advanced } from './styles';
import PlanPreviewComponent from './PlanPreview';

const EditPlanModal = ({ show, setShow, plan }) => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [_plan, setPlan] = useState(plan);
  const { updatePlan, errors, deletePlan } = useContext(PlanListingContext);

  const [showAdvConfigs, setShowAdvConfigs] = useState(
    !!(_plan.accounts_limit || _plan.accounts_size_limit),
  );

  const onChange = e => {
    if (e.target.name === 'base_price') {
      setPlan({
        ..._plan,
        base_price: {
          ..._plan.base_price,
          amount: e.target.value,
        },
      });
    } else if (e.target.name === 'is_private') {
      setPlan({
        ..._plan,
        [e.target.name]: e.target.checked,
      });
    } else if (e.target.name === 'accounts_limit') {
      setPlan({
        ..._plan,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === 'accounts_size_limit') {
      setPlan({
        ..._plan,
        [e.target.name]: e.target.value,
      });
    } else {
      setPlan({
        ..._plan,
        [e.target.name]: e.target.value,
      });
    }
  };

  const _updatePlan = async () => {
    setLoading(true);
    try {
      setLoading(true);

      await updatePlan(null, null, _plan.id, _plan);
      setShow();
    } catch (e) {
      setLoading(false);
    }
  };

  const _deletePlan = async () => {
    setDeleteLoading(true);

    try {
      await deletePlan(_plan.id);
      setDeleteLoading(false);
      setShow();
    } catch (e) {
      setDeleteLoading(false);
    }
  };

  return (
    <Modal
      width={_plan.category === 10 ? '455px' : '758px'}
      show={show}
      setShow={setShow}
    >
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>{_plan.name}</Title>
        </Text>
      </Header>

      <Content>
        <Row>
          <Col>
            <Field>
              <Label>Nome</Label>
              <Input
                name="name"
                value={_plan.name}
                block
                onChange={onChange}
                error={errors?.name}
              />
              {errors?.name && <Error>{errors?.name[0]}</Error>}
            </Field>
            <Field>
              <Label>Valor do plano</Label>
              <Input
                onChange={onChange}
                min="0"
                type="number"
                name="base_price"
                block
                value={_plan.base_price?.amount}
                error={errors?.base_price?.amount}
              />
              {errors?.base_price?.amount && (
                <Error>{errors?.base_price?.amount[0]}</Error>
              )}
            </Field>
            <Field>
              <Label>
                Privado{' '}
                <Hover>
                  ?{' '}
                  <HoverText>
                    Os planos privados não apareceram no painel principal, ou
                    seja, não poderá ser contratado diretamente pelo cliente
                    final
                  </HoverText>
                </Hover>
              </Label>
              <Checkbox
                onChange={onChange}
                name="is_private"
                value={_plan.is_private}
                checked={_plan.is_private}
                error={errors?.is_private}
              />
              {errors?.is_private && <Error>{errors?.is_private[0]}</Error>}
            </Field>
            {_plan.category === 10 && (
              <Advanced
                style={{ marginBottom: showAdvConfigs ? '26px' : '0' }}
                onClick={() => setShowAdvConfigs(!showAdvConfigs)}
              >
                <span>Configurações avançadas</span>
                <FontAwesomeIcon
                  icon={showAdvConfigs ? faChevronUp : faChevronDown}
                />
              </Advanced>
            )}
            {showAdvConfigs && (
              <>
                <Field>
                  <Label htmlFor="accounts_limit">
                    Limite de Contas de email
                  </Label>
                  <Input
                    name="accounts_limit"
                    maxLength={60}
                    value={_plan.accounts_limit}
                    onChange={onChange}
                    block
                    type="number"
                  />
                </Field>
                <Field style={{ marginBottom: '0px' }}>
                  <Label htmlFor="accounts_size_limit">
                    Limite de armazenamento por conta (EM MB)
                  </Label>
                  <Input
                    name="accounts_size_limit"
                    maxLength={60}
                    value={_plan.accounts_size_limit}
                    onChange={onChange}
                    block
                    type="number"
                  />
                </Field>
              </>
            )}
          </Col>
          {_plan.category !== 10 && (
            <Col>
              <PlanPreviewComponent
                basicMarkup={_plan.base_price?.amount}
                _plan={_plan}
              />
            </Col>
          )}
        </Row>
      </Content>
      <Footer hasLink>
        <Button size="sm" error outline onClick={_deletePlan}>
          {deleteLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Remover'
          )}
        </Button>

        <Button icon onClick={_updatePlan}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Salvar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default EditPlanModal;
