import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

import {
  faComment,
  faDollarSign,
  faPlusSquare,
  faSpinner,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { NoResultsDetail } from 'components/Common';
import { AddButton, AddCard } from 'components/AddCard';

import {
  Field,
  Input,
  Label,
  Error,
  RoundedCheckbox,
  FormInput,
} from 'components/Input';
import { Button } from '@cloudez/cloudez-design-system';
import { Form } from '@rocketseat/unform';

import {
  addUserService,
  deleteUserService,
  getUsersService,
  updateUserService,
} from 'services/users';
import api from 'services/api';

import { useAuth, useLayout } from 'hooks';
import toastError from 'utils/toastError';
import { toast } from 'react-toastify';
import { createPagination } from 'utils/createPagination';
import Pagination from 'components/Pagination';
import noresult from 'assets/img/noresult.png';
import UsersPlaceholder from './UsersPlaceholder';
import {
  UserCard,
  Username,
  UserDetail,
  Heading,
  SubHeading,
  Roles,
  Role,
} from './styles';
import Permissions from './Permissions';

const userSchema = Yup.object().shape({
  email: Yup.string()
    .email('Por favor, insira um email válido.')
    .required('Este campo é obrigatório'),
  full_name: Yup.string().required('Este campo é obrigatório'),
});

interface InewUserProperties {
  full_name: string;
  email: string;
  name?: string;
  id?: number;
}

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [userErrors, setUserErrors] = useState([] as any);
  const [updateUserErrors, setUpdateUserErrors] = useState([] as any);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [createUser, setCreateUser] = useState(false);
  const [newUser, setNewUser] = useState({
    full_name: '',
    email: '',
  } as InewUserProperties);
  const [sendCreate, setSendCreate] = useState(true);
  const [roles, setRoles] = useState([]);
  const theme = useLayout();
  const { company } = useAuth();

  const getUsersCallback = useCallback(async page => {
    setLoading(true);
    try {
      const { data } = await getUsersService({
        page,
        page_size: 10,
      });

      const pagination = createPagination(data, 10);

      setUsers({ users: data.results, ...pagination });
      setLoading(false);
    } catch (e) {
      toastError(e);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getUsersCallback(1);
    // eslint-disable-next-line
  }, []);

  const handleResetPasswordSubmit = async () => {
    const payload = {
      email: selectedUser.email,
      company: company.code,
    };

    try {
      setResetLoading(true);
      await api.post('/auth/password/reset/', payload);
      toast.success('Email de redefinição enviado com sucesso');
      setResetLoading(false);
    } catch (e) {
      setResetLoading(false);
      setLoading(false);
      toastError(e);
    }
  };

  const handleSubmit = async d => {
    let isMounted = true;
    if (isMounted) {
      try {
        setCreateLoading(true);
        const user = {
          ...d,
          notify_email: sendCreate,
          roles,
        };

        if (roles.length === 0) {
          toast.error(
            'Selecione pelo menos um perfil: Financeiro, Técnico ou Atendimento.',
          );
          setCreateLoading(false);
          return;
        }

        const { data } = await addUserService(user);
        setUsers(users => ({
          ...users,
          users: [data, ...users.users],
        }));
        setSelectedUser(data);
        setUserErrors([]);
        setCreateUser(false);
        setNewUser({
          full_name: '',
          email: '',
        });
        setCreateLoading(false);
        toast.success('Usuário adicionado com sucesso.');
      } catch (e) {
        setCreateLoading(false);
        setUserErrors(e.response?.data);
        toastError(e);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  const updateUser = async () => {
    setCreateLoading(true);

    if (selectedUser.user_roles.length === 0) {
      toast.error(
        'Selecione pelo menos um perfil: Financeiro, Técnico ou Atendimento.',
      );
      setCreateLoading(false);
      return;
    }

    try {
      const { data } = await updateUserService(selectedUser.id, {
        ...selectedUser,
        roles: selectedUser.user_roles,
      });

      setSelectedUser(data);
      setUsers(users => ({
        ...users,
        users: users.users.map(user => (user.id === data.id ? data : user)),
      }));
      setUpdateUserErrors([]);
      setCreateLoading(false);
      toast.success('Usuário alterado com sucesso.');
    } catch (e) {
      setCreateLoading(false);
      setUpdateUserErrors(e.response?.data);
      toastError(e);
    }
  };

  const deleteUser = async () => {
    setDeleteLoading(true);

    try {
      await deleteUserService(selectedUser.id);

      setUsers(users => ({
        ...users,
        users: users.users.filter(user => user.id !== selectedUser.id),
      }));
      setUserErrors([]);
      setDeleteLoading(false);
      setSelectedUser(null);
    } catch (e) {
      setDeleteLoading(false);
      setUserErrors(e.response?.data);
      toastError(e);
    }
  };

  const getRoleIcon = role => {
    switch (role) {
      case 'technical_role':
        return faWrench;
      case 'financial_role':
        return faDollarSign;
      case 'customer_success_role':
        return faComment;
      default:
        return null;
    }
  };

  return (
    <Row>
      <Col xl="5">
        <Heading>Listagem de usuários</Heading>
        <Row>
          <Col sm="6">
            {!createUser ? (
              <AddCard
                onClick={() => {
                  setCreateUser(!createUser);
                  setSelectedUser(newUser);
                }}
                width="100%"
                height="50px"
                style={{
                  marginBottom: 12,
                }}
              >
                <AddButton row transparent>
                  <FontAwesomeIcon icon={faPlusSquare} />
                  <span>criar</span>
                </AddButton>
              </AddCard>
            ) : (
              <UserCard
                selected={selectedUser && newUser.id === selectedUser.id}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                  <Username>{newUser.name}</Username>
                </div>
              </UserCard>
            )}
          </Col>

          <Col sm="6" />
          {loading ? (
            <UsersPlaceholder />
          ) : users?.users?.length ? (
            <>
              {users?.users?.map(user => (
                <Col sm="6" key={user.id}>
                  <UserCard
                    onClick={() => {
                      setSelectedUser({
                        ...user,
                      });
                      setCreateUser(false);
                    }}
                    selected={selectedUser && user.id === selectedUser.id}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <FontAwesomeIcon icon={faUserCircle} />
                      <Username>{user.full_name}</Username>
                      <Roles>
                        {user.user_roles.map(role => (
                          <Role>
                            <FontAwesomeIcon icon={getRoleIcon(role)} />
                          </Role>
                        ))}
                      </Roles>
                    </div>
                  </UserCard>
                </Col>
              ))}
            </>
          ) : (
            <Col sm="6">
              <NoResultsDetail row width="100%" height="50px">
                <FontAwesomeIcon icon={faUserCircle} />
                Nenhum usuário.
              </NoResultsDetail>
            </Col>
          )}
          <Pagination
            style={{
              margin: '20px auto',
            }}
            onChangePage={getUsersCallback}
            count={users?.count}
            previous={users?.previous}
            next={users?.next}
            current={users?.current}
            pageSize="10"
          />
        </Row>
      </Col>

      <Col xl="7">
        {createUser && (
          <>
            <Heading
              style={{
                marginLeft: 15,
                marginBottom: 12,
              }}
            >
              Usuários Adicionais
            </Heading>
            <SubHeading
              style={{
                marginLeft: 15,
                marginBottom: 22,
              }}
            >
              Adicione novos usuários e permissões a sua conta para que seu time
              tenha acesso as funcionalidades dentro dos paineis.
            </SubHeading>
          </>
        )}
        {selectedUser && !createUser && (
          <>
            <Heading
              style={{
                marginLeft: 15,
                marginBottom: 12,
              }}
            >
              Edição de Usuário
            </Heading>
            <SubHeading
              style={{
                marginLeft: 15,
                marginBottom: 22,
              }}
            >
              Edite as permissões, redefina a senha de acesso ou exclua a conta
              criada.
            </SubHeading>
          </>
        )}
        <UserDetail>
          {createUser ? (
            <>
              <Form schema={userSchema} onSubmit={handleSubmit} id="infoForm">
                <Field>
                  <Label>Nome</Label>
                  <FormInput
                    data-testid="FormCreateUserName"
                    value={newUser.full_name}
                    onChange={e => {
                      setNewUser({
                        ...newUser,
                        full_name: e.target.value,
                      });
                    }}
                    name="full_name"
                    error={!!userErrors.full_name}
                  />
                  {userErrors.full_name && (
                    <Error>{userErrors.full_name[0]}</Error>
                  )}
                </Field>
                <Field>
                  <Label>Email</Label>
                  <FormInput
                    data-testid="FormCreateEmail"
                    value={newUser.email}
                    onChange={e => {
                      setNewUser({
                        ...newUser,
                        email: e.target.value,
                      });
                    }}
                    name="email"
                    error={!!userErrors.email}
                  />
                  {userErrors.email && <Error>{userErrors.email[0]}</Error>}
                </Field>
                <Field>
                  <RoundedCheckbox
                    checked={sendCreate}
                    value={sendCreate}
                    onChange={e => setSendCreate(e.target.checked)}
                  />
                  <small>
                    Enviar email de definição de senha para o usuário
                  </small>
                  {userErrors.notify_email && (
                    <Error>{userErrors.notify_email[0]}</Error>
                  )}
                </Field>

                <Permissions roles={roles} setRoles={setRoles} />

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    margin: '18px 0',
                  }}
                >
                  <Button disabled={createLoading} icon>
                    {createLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Adicionar'
                    )}
                  </Button>
                </div>
              </Form>
            </>
          ) : selectedUser ? (
            <>
              <Field>
                <Label htmlFor="userName">Nome</Label>
                <Input
                  id="userName"
                  value={selectedUser.full_name}
                  onChange={e => {
                    setSelectedUser({
                      ...selectedUser,
                      full_name: e.target.value,
                    });
                  }}
                  error={!!updateUserErrors.full_name}
                />
                {updateUserErrors.full_name && (
                  <Error>{updateUserErrors.full_name[0]}</Error>
                )}
              </Field>
              <Field>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  value={selectedUser.email}
                  onChange={e => {
                    setSelectedUser({
                      ...selectedUser,
                      email: e.target.value,
                    });
                  }}
                  error={!!updateUserErrors.email}
                />
                {updateUserErrors.email && (
                  <Error>{updateUserErrors.email[0]}</Error>
                )}
              </Field>

              <Field>
                <Button
                  onClick={handleResetPasswordSubmit}
                  style={{
                    display: 'inline',
                    height: 30,
                    width: 160,
                    fontSize: '12px',
                    background: '#2253F51A',
                    color: '#2253F5',
                    marginRight: 11,
                  }}
                  disable={resetLoading}
                  icon
                >
                  {resetLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Redefinir senha'
                  )}
                </Button>
                <small
                  style={{
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '14px',
                    color: theme.interface5,
                    display: 'inline',
                  }}
                >
                  O usuário receberá um email de redefinição
                </small>
                {userErrors.notify_email && (
                  <Error>{userErrors.notify_email[0]}</Error>
                )}
              </Field>

              <Permissions
                roles={selectedUser.user_roles}
                setRoles={cb =>
                  setSelectedUser(user => ({
                    ...user,
                    user_roles: cb(user.user_roles),
                  }))
                }
              />

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  margin: '18px 0',
                }}
              >
                <Button
                  onClick={deleteUser}
                  icon
                  outline
                  error
                  disabled={deleteLoading}
                >
                  {deleteLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Excluir'
                  )}
                </Button>
                <Button icon onClick={updateUser} disabled={createLoading}>
                  {createLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Atualizar'
                  )}
                </Button>
              </div>
            </>
          ) : (
            <>
              <img
                src={noresult}
                alt="img"
                style={{
                  margin: '20px auto',
                  display: 'block',
                }}
              />
              <span
                style={{
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '21px',
                  textAlign: 'center',
                  color: theme.interface5,
                  display: 'block',
                  maxWidth: '365px',
                  margin: '0 auto',
                }}
              >
                Adicione novos usuários e permissões a sua conta para que seu
                time tenha acesso as funcionalidades dentro dos paineis.
              </span>
            </>
          )}
        </UserDetail>
      </Col>
    </Row>
  );
};

export default Users;
