import api from 'services/api';

export const getPlansService = async params => {
  try {
    return await api.get('/v3/company/plan/type/', { params });
  } catch (error) {
    throw error;
  }
};

export const getBasePlansService = async (params?: any): Promise<any> => {
  try {
    return await api.get('/v3/cloud/size/', { params });
  } catch (error) {
    throw error;
  }
};

export const updatePlanService = async (id: number | string, data) => {
  try {
    return await api.patch(`/v3/company/plan/type/${id}/`, data);
  } catch (error) {
    throw error;
  }
};

export const createPlanService = async data => {
  try {
    return await api.post(`/v3/company/plan/type/`, data);
  } catch (error) {
    throw error;
  }
};

export const deletePlanService = async (id: number | string) => {
  try {
    return await api.delete(`/v3/company/plan/type/${id}/`);
  } catch (error) {
    throw error;
  }
};
