import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faBarcode,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Input } from 'components/Input';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { HttpsText } from './styles';

const RemoveModal = ({ action, theme, show, setShow }) => {
  const [remove, setRemove] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faBarcode} />
        </Icon>
        <Text>
          <Title>Excluir fatura</Title>
          <Subtitle>Deseja remover esta fatura?</Subtitle>
        </Text>
      </Header>

      <Content>
        <Row>
          <Col
            xs="4"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="4x" />
          </Col>
          <Col
            xs="8"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HttpsText>
              Por favor, escreva{' '}
              <span
                style={{
                  textTransform: 'uppercase',
                  color: theme.error,
                }}
              >
                REMOVER
              </span>{' '}
              para confirmar
            </HttpsText>

            <Input
              placeholder="REMOVER"
              value={remove}
              onChange={e => {
                setRemove(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Content>
      <Footer>
        <Button
          icon
          disabled={remove !== 'REMOVER'}
          error
          onClick={() => {
            setLoading(true);
            action();
            setShow();
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(RemoveModal);
