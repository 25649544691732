export default (state, action) => {
  switch (action.type) {
    case 'GET_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'GET_BILLING': {
      return {
        ...state,
        billing: action.payload,
      };
    }
    case 'GET_INVOICE': {
      return {
        ...state,
        invoices: action.payload,
      };
    }
    case 'GET_CLOUDS': {
      return {
        ...state,
        clouds: action.payload,
      };
    }
    case 'GET_COMPANY_PLAN': {
      return {
        ...state,
        companyPlan: action.payload,
      };
    }
    case 'GET_WEBSITES': {
      return {
        ...state,
        websites: action.payload,
      };
    }
    case 'GET_EMAILS': {
      return {
        ...state,
        emails: action.payload,
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_BILLING': {
      return {
        ...state,
        billing: action.payload,
      };
    }
    case 'SET_COMPANY_PLAN': {
      return {
        ...state,
        companyPlan: action.payload,
      };
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
