import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faDollarSign, faBarcode } from '@fortawesome/free-solid-svg-icons';
import { NoResultsDetail } from 'components/NoResults';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import { InvoiceCard, Field, Label, Value } from './styles';

const Invoices = ({ invoices }) => {
  return (
    <>
      <Row
        style={{
          margin: '0',
          width: '100%',
        }}
      >
        {invoices && !!invoices.length ? (
          invoices.map(i => {
            return (
              <Col xl="4" md="6" sm="12" key={i.id}>
                <Link to={`/invoices/${i.id}`} target="_blank">
                  <InvoiceCard>
                    <Field>
                      <Label>Id</Label>
                      <Value>{i.id}</Value>
                    </Field>
                    <Field>
                      <Label>Valor</Label>
                      <Value amount>
                        <CurrencyFormat
                          value={i.total.amount}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={i.total.currency === 'BRL' ? 'R$ ' : '$ '}
                        />
                      </Value>
                    </Field>
                    <Field>
                      <Label>Vencimento</Label>
                      <Value>{moment(i.expired_at).format('DD/MM/YYYY')}</Value>
                    </Field>
                    {i.paid_at && (
                      <Field>
                        <Label>Pago em</Label>
                        <Value>{moment(i.paid_at).format('DD/MM/YYYY')}</Value>
                      </Field>
                    )}
                    <Field>
                      <Label>Status</Label>
                      <Value isPaid={i.is_paid}>
                        <FontAwesomeIcon icon={faDollarSign} />
                      </Value>
                    </Field>
                    {/* <Field>
                    <Label>Ação</Label>
                    <Value actions>
                      <FontAwesomeIcon icon={faCog} />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        size="xs"
                        style={{
                          marginLeft: '5px',
                        }}
                      />
                    </Value>
                  </Field> */}
                  </InvoiceCard>
                </Link>
              </Col>
            );
          })
        ) : (
          <Col xl="4" md="6" sm="12">
            <NoResultsDetail row width="100%" height="60px">
              <FontAwesomeIcon icon={faBarcode} />
              Nenhuma fatura
            </NoResultsDetail>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Invoices;
