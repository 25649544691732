import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.error} !important;
`;

export const Title = styled.h1`
  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.interface7} !important;
  font-size: 28px;
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding-top: 50px;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;

    z-index: -1;

    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.brand_primary_color};
  }

  button.copy {
    border-radius: 0 0 5px 5px;
  }
`;

export const Card = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  background: ${props => props.theme.interface1};

  border-radius: 5px;
  padding: 1rem;
`;

export const ErrorBox = styled.div`
  padding: 10px;

  background-color: ${props => props.theme.interface3};
  overflow-y: auto;
  max-height: 300px;
  width: 600px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
