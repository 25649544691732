import React from 'react';
import { Col } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';

const DashboardSummary = () => {
  const placeholders = [];

  for (let i = 1; i <= 1; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col xl="2" key={p}>
          <div style={{ display: 'flex', gap: '23px' }}>
            <div>
              <div style={{ display: 'flex', gap: '1.5rem' }}>
                <div
                  style={{
                    background: '#fff',
                    width: '179px',
                    height: '120px',
                    padding: '14px',
                    borderRadius: '5px',
                  }}
                >
                  <Placeholder width={115} height={16} />
                  <Placeholder width={98} height={16} />
                  <div style={{ textAlign: 'right', marginTop: '35px' }}>
                    <Placeholder width={89} height={20} />
                  </div>
                </div>

                <div
                  style={{
                    background: '#fff',
                    width: '179px',
                    height: '120px',
                    padding: '14px',
                    borderRadius: '5px',
                  }}
                >
                  <Placeholder width={115} height={16} />
                  <Placeholder width={98} height={16} />
                  <div style={{ textAlign: 'right', marginTop: '35px' }}>
                    <Placeholder width={89} height={20} />
                  </div>
                </div>
              </div>

              <div
                style={{ display: 'flex', gap: '1.5rem', marginTop: '1.5rem' }}
              >
                <div
                  style={{
                    background: '#fff',
                    width: '179px',
                    height: '120px',
                    padding: '14px',
                    borderRadius: '5px',
                  }}
                >
                  <Placeholder width={115} height={16} />
                  <Placeholder width={98} height={16} />
                  <div style={{ textAlign: 'right', marginTop: '35px' }}>
                    <Placeholder width={89} height={20} />
                  </div>
                </div>

                <div
                  style={{
                    background: '#fff',
                    width: '179px',
                    height: '120px',
                    padding: '14px',
                    borderRadius: '5px',
                  }}
                >
                  <Placeholder width={115} height={16} />
                  <Placeholder width={98} height={16} />
                  <div style={{ textAlign: 'right', marginTop: '35px' }}>
                    <Placeholder width={89} height={20} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default DashboardSummary;
