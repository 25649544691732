import { useMemo } from 'react';

import NotificationBar from 'components/NotificationBar';
import { useAuth, useLayout } from 'hooks';
import BillingModal from 'components/BillingModal';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import { Content, Container } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const { notification, sideMenuCollapsed } = useLayout();
  const { company, user } = useAuth();
  const location = useLocation();

  const isChat = useMemo(() => location.pathname === '/chat', [location]);

  return (
    <>
      <Container
        isChat={isChat}
        sideMenuCollapsed={sideMenuCollapsed}
        hasNotification={notification?.type}
      >
        {notification?.type && <NotificationBar />}
        <Sidebar />
        <Navbar />

        <Content isChat={isChat}>{children}</Content>
        {!isChat && <Footer />}
      </Container>
      {/* {company?.has_cloudez_chat && <FloatingChat />} */}
      {!user?.is_staff && <BillingModal company={company} />}
    </>
  );
};

export default DefaultLayout;
