import React, { useEffect, useState, useContext, useCallback } from 'react';
import queryString from 'query-string';

import { Header, Title } from 'components/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

import history from 'services/history';
import { NoResultsDetail } from 'components/NoResults';
import { Button } from '@cloudez/cloudez-design-system';
import SearchBar from 'components/SearchBar';
import Pagination from 'components/Pagination';
import Slider from 'react-slick';
import { Arrows } from 'components/Arrows';
import { useDocTitle } from 'hooks';
import useDevice from 'hooks/device';
import PlanPlaceholder from './Placeholder';
import EditPlanModal from './EditPlanModal';
import PlanCard from './PlanCard';
import { PlanListingProvider, PlanListingContext } from './_context/state';
import BasePlanCard from './BasePlanCard';
import { NewPlan, Subtitle, Wrapper } from './styles';
import NewPlanModal from './NewPlanModal';

const Plans = () => {
  useDocTitle('Planos - Cloudez Partners');

  const [newPlanModal, setNewPlanModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editPlan, setEditPlan] = useState(null);

  const { isMobile } = useDevice();

  const {
    loading,
    plans,
    count,
    previous,
    next,
    current,
    getPlans,
    basePlans,
    getBasePlans,
  } = useContext(PlanListingContext);

  const { page = 1, search = '' } = queryString.parse(history.location.search);

  const getPlansCallback = useCallback(
    (page, search) => {
      getPlans(page, search);

      const _query = {
        page,
        search,
      };

      if (!search) delete _query.search;
      const query = queryString.stringify(_query);
      history.replace(`/plans?${query}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getPlansCallback(page, search);
    getBasePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchValue = (e, searchValue) => {
    if (e) e.preventDefault();
    getPlansCallback(1, searchValue);
  };

  function NextArrow(props) {
    const { onClick, currentSlide } = props;
    return (
      <Arrows
        right="true"
        onClick={onClick}
        icon={faChevronRight}
        disabled={currentSlide === 10}
      />
    );
  }

  function PrevArrow(props) {
    const { onClick, currentSlide } = props;

    return (
      <Arrows
        left="true"
        onClick={onClick}
        icon={faChevronLeft}
        disabled={currentSlide === 0}
      />
    );
  }

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    focusOnSelect: true,
    centerPadding: '60px',
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header>
        <Title>Planos</Title>
      </Header>

      <Wrapper>
        <Subtitle>Meus planos</Subtitle>

        <SearchBar
          cb={searchValue}
          noResults={plans && plans.length === 0 && search}
        />

        {newPlanModal && (
          <NewPlanModal
            plans={plans}
            show={newPlanModal}
            setShow={() => setNewPlanModal(false)}
            basePlans={basePlans}
            // loading={createLoading}
          />
        )}

        {!isMobile ? (
          <Row>
            <Col xl="2" lg="3" md="4" sm="6" xs="12">
              <NewPlan>
                <span>NOVO PLANO</span>
                <Button size="sm" onClick={() => setNewPlanModal(true)}>
                  Criar
                  <FontAwesomeIcon icon={faPlusSquare} />
                </Button>
              </NewPlan>
            </Col>

            {!loading ? (
              plans?.length > 0 ? (
                plans.map(p => {
                  const basePlan =
                    basePlans &&
                    basePlans.filter(_p => _p.id === p.cloud_size)[0];

                  return (
                    <Col key={p.id} xl="2" lg="3" md="4" sm="6" xs="12">
                      {editModal && (
                        <EditPlanModal
                          show={editModal}
                          setShow={() => setEditModal(false)}
                          plan={editPlan}
                        />
                      )}
                      <PlanCard
                        p={p}
                        basePlan={basePlan}
                        setEditModal={setEditModal}
                        setEditPlan={setEditPlan}
                      />
                    </Col>
                  );
                })
              ) : (
                <Col xl="2" lg="3" md="4" sm="6" xs="12">
                  <NoResultsDetail height="234px" width="100%">
                    Nenhum plano
                  </NoResultsDetail>
                </Col>
              )
            ) : (
              <PlanPlaceholder />
            )}
          </Row>
        ) : (
          <div>
            {editModal && (
              <EditPlanModal
                show={editModal}
                setShow={() => setEditModal(false)}
                plan={editPlan}
              />
            )}
            {loading ? (
              <div>
                <PlanPlaceholder />
              </div>
            ) : (
              <Slider {...settings}>
                <div>
                  <NewPlan>
                    <span>NOVO PLANO</span>
                    <Button size="sm" onClick={() => setNewPlanModal(true)}>
                      Criar
                      <FontAwesomeIcon icon={faPlusSquare} />
                    </Button>
                  </NewPlan>
                </div>
                {plans?.map(p => {
                  const basePlan = basePlans?.filter(
                    _p => _p.id === p.cloud_size,
                  )[0];

                  return (
                    <div key={p.id}>
                      <PlanCard
                        p={p}
                        basePlan={basePlan}
                        setEditModal={setEditModal}
                        setEditPlan={setEditPlan}
                      />
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        )}

        <Pagination
          onChangePage={getPlansCallback}
          count={count}
          previous={previous}
          next={next}
          current={current}
          search={search}
          pageSize="11"
        />
      </Wrapper>

      <Subtitle>Planos base</Subtitle>

      {!isMobile ? (
        <Row>
          {!loading ? (
            basePlans?.length > 0 ? (
              basePlans.map(p => {
                return (
                  <Col key={p.id} xl="2" lg="3" md="4" sm="6" xs="12">
                    <BasePlanCard p={p} />
                  </Col>
                );
              })
            ) : (
              <Col xl="2" lg="3" md="4" sm="6" xs="12">
                <NoResultsDetail height="234px" width="100%">
                  Nenhum plano
                </NoResultsDetail>
              </Col>
            )
          ) : (
            <PlanPlaceholder />
          )}
        </Row>
      ) : (
        <div>
          {editModal && (
            <EditPlanModal
              show={editModal}
              setShow={() => setEditModal(false)}
              plan={editPlan}
            />
          )}
          {loading ? (
            <div>
              <PlanPlaceholder />
            </div>
          ) : (
            <Slider {...settings}>
              {basePlans?.map(p => {
                return (
                  <div key={p.id}>
                    <BasePlanCard p={p} />
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      )}
    </>
  );
};

export default props => (
  <PlanListingProvider>
    <Plans {...props} />
  </PlanListingProvider>
);
