import api from 'services/api';
import { toast } from 'react-toastify';
import history from 'services/history';
import { getCloudsService } from 'services/cloud';
import {
  createTicketMessageService,
  getTicketService,
  getMessagesService,
  updateTicketService,
} from 'services/ticket';

import { getUserService } from 'services/user';
import { getResourceService } from 'services/common';

type Props = {
  type?: string;
  payload: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const addWSMessage = (
  dispatch: ({ type, payload }: Props) => void,
  message,
) => {
  dispatch({
    type: 'ADD_WS_MESSAGE',
    payload: message,
  });
};

export const getTicket = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    const { data } = await getTicketService(id);

    dispatch({
      type: 'GET_TICKET',
      payload: data,
    });
  } catch (e) {
    if (e.response.status === 404) {
      toast.error('Ticket não encontrado');
      history.push('/404');
    }
  }
};

export const getUser = async (
  dispatch: ({ type, payload }: Props) => void,
  owner,
) => {
  try {
    const { data } = await getUserService(owner);

    dispatch({
      type: 'GET_USER',
      payload: data,
    });
  } catch (e) {}
};

export const getClouds = async (
  dispatch: ({ type, payload }: Props) => void,
  owner_email: string,
) => {
  try {
    const { data } = await getCloudsService({
      search: owner_email,
      page_size: 1000,
    });

    dispatch({
      type: 'GET_CLOUDS',
      payload: data.results,
    });
  } catch (e) {}
};

export const getTarget = async (
  dispatch: ({ type, payload }: Props) => void,
  target_type: string,
  target_id: number | string,
) => {
  try {
    const { data } = await getResourceService(target_type, target_id);

    dispatch({
      type: 'GET_TARGET',
      payload: data,
    });
  } catch (e) {}
};

export const getMessages = async (
  dispatch: ({ type, payload }: Props) => void,
  id: string | number,
) => {
  try {
    const { data } = await getMessagesService({
      ticket: id,
    });

    getFiles(dispatch, data);

    dispatch({
      type: 'GET_MESSAGES',
      payload: data,
    });
  } catch (e) {}
};

export const addMessage = async (
  dispatch: ({ type, payload }: Props) => void,
  _data,
) => {
  try {
    await createTicketMessageService(_data);
    // getMessages(dispatch, data.ticket);
  } catch (e) {
    toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
  }
};

export const getFiles = (
  dispatch: ({ type, payload }: Props) => void,
  data,
) => {
  const _files = data.filter(d => d.file).map(d => d.file);

  dispatch({
    type: 'GET_FILES',
    payload: _files,
  });
};

export const updateTicket = async (
  dispatch: ({ type, payload }: Props) => void,
  field: string,
  value,
  id: number | string,
  ticket,
) => {
  try {
    const { data } = await updateTicketService(
      id,
      ticket || {
        [field]: value,
      },
    );

    toast.success('Ticket atualizado com sucesso.');

    dispatch({
      type: 'UPDATE_TICKET',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_TICKET_ERROR',
      payload: e?.response?.data,
    });
  }
};

export const setRedAlert = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    const { data } = await api.patch(`v3/ticket/${id}/redalert/`);

    dispatch({
      type: 'SET_RED_ALERT',
      payload: data?.red_alert,
    });
  } catch (e) {
    dispatch({
      type: 'SET_RED_ALERT_ERROR',
      payload: e?.response?.data,
    });
  }
};
