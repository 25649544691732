import React, { CSSProperties, useCallback, useState } from 'react';
import { ParsedQuery } from 'query-string';
import SearchSelect from 'components/SearchSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Container } from './styles';

interface IAdvancedSearchProps {
  style: CSSProperties;
  setValue: (value: any) => void;
  value: ParsedQuery<string>;
  loading?: boolean;
  noResults: boolean | ParsedQuery<string> | string;
  callback: (value: any) => void;
  fields: { label: string; value: string }[];
  error?: any;
}

const AdvancedSearch: React.FC<IAdvancedSearchProps> = ({
  setValue: _setValue,
  value: _value,
  error,
  callback,
  noResults,
  loading,
  fields,
  ...rest
}) => {
  const [value, setValue] = useState('');

  const callbackHandler = useCallback(
    value => {
      if (callback) {
        callback(value);
      }
    },
    [callback],
  );

  const onChange = useCallback(
    (e, a) => {
      if (_setValue) {
        _setValue(oldSearches => {
          if (a?.option) {
            oldSearches[a.option.fieldName] = a.option.fieldValue;
          } else if (a?.removedValue) {
            delete oldSearches[a.removedValue.fieldName];
          }

          setTimeout(() => {
            document
              .querySelector('.react-select__value-container')
              ?.scrollTo(10000, 0);
          }, 0);

          callbackHandler(oldSearches);

          return oldSearches;
        });
      }
    },
    [value],
  );

  const onInputChange = useCallback(e => {
    setValue(e);
  }, []);

  const fieldsOptions = fields?.map(i => ({
    label: i.label,
    value: i.value,
    fieldName: i.value,
    fieldValue: value,
  }));

  return (
    <Container noResults={noResults} {...rest}>
      <SearchSelect
        id="alecrim"
        async={false}
        isMulti
        isSearch
        name="search"
        autoFocus
        loadOptions={fieldsOptions}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={option =>
          `${option.label} contém: ${option.fieldValue}`
        }
        isOptionDisabled={e => e?.fieldValue?.length <= 0}
        error={error}
        placeholder="Digite um termo"
        defaultValue={Object.keys(_value).map(
          i =>
            _value[i] &&
            fields.find(f => f.value === i) && {
              label: fields.find(f => f.value === i).label,
              value: i,
              fieldName: i,
              fieldValue: _value[i],
            },
        )}
      />

      <FontAwesomeIcon
        style={{
          position: 'absolute',
          top: '50%',
          left: 10,
          transform: 'translateY(-50%)',
          zIndex: 999999,
        }}
        icon={loading ? faSpinner : faSearch}
        spin={loading}
        onClick={callbackHandler}
      />
      <span>{error && 'Sua busca não foi encontrada'}</span>
    </Container>
  );
};

export default AdvancedSearch;
