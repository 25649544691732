import React from 'react';
import { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useLayout } from 'hooks';
import { Button } from './styles';

const CollapseButton = ({ theme }) => {
  const { sideMenuCollapsed, toggleMenu } = useLayout();

  return (
    <Button sideMenuCollapsed={sideMenuCollapsed} onClick={toggleMenu}>
      <FontAwesomeIcon icon={faBars} color={theme.interface8} />
    </Button>
  );
};

export default withTheme(CollapseButton);
