import api from 'services/api';

export const getTransfersService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/company/transfer/', {
      params,
      signal: ctrl.signal,
    } as any);
  } catch (error) {
    throw error;
  }
};

export const updateTransferService = async (id: number | string, data) => {
  try {
    return await api.patch(`/v3/company/transfer/${id}/`, data);
  } catch (error) {
    throw error;
  }
};
