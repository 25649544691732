import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { useAuth } from 'hooks/auth';
import { useLayout } from 'hooks/layout';
import { Terms } from './styles';

const TermsModal = () => {
  const { user, updateUser, signOut } = useAuth();

  const logout = () => {
    signOut();
  };

  const accept = () => {
    updateUser('accepted_terms', true, user.id, true);
  };

  const termsLink = useMemo(() => {
    return 'https://cloudez.io/br/termos-de-servico';
  }, []);

  const policyLink = useMemo(() => {
    return 'https://cloudez.io/br/privacidade-de-dados';
  }, []);

  return (
    <Modal noExit show>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faFileContract} size="2x" />
        </Icon>
        <Text>
          <Title>Termos e Privacidade de Dados</Title>
          <Subtitle>
            Você deve aceitar os termos e condições para continuar
          </Subtitle>
        </Text>
      </Header>

      <Content>
        <Terms>
          <p>
            Ao clicar em ACEITAR E CONTINUAR, você aceita os{' '}
            <b>TERMOS e CONDIÇÕES</b> e a <b>Política e Segurança de Dados</b>
          </p>
          <Button outline>
            <a href={termsLink} target="_blank" rel="noreferrer">
              Termos e Condições Gerais
            </a>
          </Button>
          <Button outline>
            <a target="_blank" rel="noreferrer" href={policyLink}>
              Privacidade e Segurança de Dados
            </a>
          </Button>
        </Terms>
      </Content>

      <Footer hasLink>
        <Button size="sm" onClick={logout} outline error>
          Recusar e sair
        </Button>
        <Button size="sm" onClick={accept}>
          Aceitar e continuar
        </Button>
      </Footer>
    </Modal>
  );
};

export default TermsModal;
