/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

import { Wrapper, SearchInput, SearchWrapper } from './styles';

interface ISearchBarProps {
  cb?: (e: any, searchValue: string) => void;
  placeholder?: string;
  setSearch?: (value: string) => void;
  search?: any;
  noResults?: any;
  history: any;
  location: any;
  match: any;
  noInput?: boolean;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  cb,
  noResults,
  search,
  setSearch,
  children,
  noInput,
}) => {
  const [q, setQ] = useState(search);

  useEffect(() => {
    if (setSearch && q) setSearch(q);
    // eslint-disable-next-line
  }, [q]);

  useEffect(() => {
    if (search) setQ(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <Wrapper>
      {!noInput && (
        <SearchWrapper onSubmit={e => cb(e, q)} noResults={noResults}>
          <FontAwesomeIcon icon={faSearch} onClick={() => cb(null, q)} />
          <SearchInput
            type="text"
            placeholder="Faça sua busca aqui"
            value={q}
            onChange={e => setQ(e.target.value)}
            noResults={noResults}
          />
          <p>Sua busca não foi encontrada</p>
        </SearchWrapper>
      )}
      {children && children}
    </Wrapper>
  );
};

export default withRouter<ISearchBarProps, any>(SearchBar);
