import styled, { css } from 'styled-components';

interface ICardProps {
  warn?: boolean;
  error?: boolean;
  info?: boolean;
}

export const Card = styled.div<ICardProps>`
  width: 100%;
  height: 100%;

  background: ${props => props.theme.interface1};
  border-radius: 5px;

  ${props =>
    props.warn &&
    css`
      border: 1px solid ${props => props.theme.alert};

      ${CardHeader} {
        font-size: 18px;
      }

      ${CardBody} {
        font-size: 16px;
      }

      ${CardBody}, ${CardHeader} {
        color: ${props => props.theme.alert};

        a {
          color: ${props => props.theme.alert};
          text-decoration: underline;
          font-weight: bold;
        }
      }
    `};

  ${props =>
    props.error &&
    css`
      border: 1px solid ${props => props.theme.error};

      ${CardHeader} {
        font-size: 18px;
      }

      ${CardBody} {
        font-size: 16px;
      }

      ${CardBody}, ${CardHeader} {
        color: ${props => props.theme.error};

        a {
          color: ${props => props.theme.error};
          text-decoration: underline;
          font-weight: bold;
        }
      }
    `}

  ${props =>
    props.info &&
    css`
      border: 1px solid ${props => props.theme.brand_primary_color};

      ${CardHeader} {
        font-size: 18px;
      }

      ${CardBody} {
        font-size: 16px;
      }

      ${CardBody}, ${CardHeader} {
        color: ${props => props.theme.brand_primary_color};

        a {
          color: ${props => props.theme.brand_primary_color};
          text-decoration: underline;
          font-weight: bold;
        }
      }
    `}
`;

export const CardHeader = styled.div`
  padding: 8px;

  color: ${props => props.theme.interface5};
  font-weight: bold;
`;

export const CardBody = styled.div`
  padding: 8px;
  height: auto;
  display: flex;
`;

interface IStatusProps {
  active?: boolean;
  pending?: boolean;
  rejected?: boolean;
  incomplete?: boolean;
}

export const Status = styled.div<IStatusProps>`
  margin-top: 12px;
  width: 86px;
  height: 34px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: white;

  background: ${props =>
    props.active
      ? props.theme.success
      : props.pending
      ? props.theme.labelNew
      : props.rejected
      ? props.theme.errorNew
      : props.incomplete
      ? props.theme.alertNew
      : props.theme.error};
`;
