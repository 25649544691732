import axios from 'axios';
import api from 'services/api';

export const getCreditcardService = async () => {
  try {
    return await api.get(`/v3/creditcard/`);
  } catch (e) {
    throw e;
  }
};

export const getBankAccountService = async code => {
  try {
    return await api.get(`/v3/company/bankaccount/${code}/`);
  } catch (e) {
    throw e;
  }
};

export const createCreditcardService = async data => {
  try {
    return await api.post('/v3/creditcard/', data);
  } catch (e) {
    throw e;
  }
};

export const getCepService = async cep => {
  try {
    return await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  } catch (e) {
    throw e;
  }
};

export const deleteCreditcardService = async id => {
  try {
    return await api.delete(`/v3/creditcard/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateSettingsService = async params => {
  try {
    return await api.post('v3/company/update_settings/', params);
  } catch (e) {
    throw e;
  }
};

export const getCNPJDataService = async cnpj => {
  try {
    return await api.get(`/v3/company-cnpj/${cnpj}/`);
  } catch (e) {
    throw e;
  }
};

export const getDashboardService = async (id: number | string) => {
  try {
    return await api.get(`/v3/dashboard-summary/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getCompanyBillingService = async company => {
  try {
    return await api.get(`/v3/company/billing/${company}/`);
  } catch (e) {
    throw e;
  }
};

export const deleteBillingService = async code => {
  try {
    return await api.delete(`/v3/company/billing/${code}`);
  } catch (e) {
    throw e;
  }
};

export const updateBillingService = async (code, payload) => {
  try {
    return await api.patch(`/v3/company/billing/${code}/`, payload);
  } catch (e) {
    throw e;
  }
};

export const updateBankAccountService = async (code, payload) => {
  try {
    return await api.patch(`/v3/company/bankaccount/${code}/`, payload);
  } catch (e) {
    throw e;
  }
};

export const widthdrawService = async code => {
  try {
    return await api.post(`/v3/company/${code}/withdraw/`);
  } catch (e) {
    throw e;
  }
};

export const getResourceService = async (type, id: number | string) => {
  try {
    return await api.get(`/v3/${type}/${id}/`);
  } catch (e) {
    throw e;
  }
};
