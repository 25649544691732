import { createContext, useReducer } from 'react';

import reducer from './reducer';
import * as actions from './actions';

export interface InvoiceDetailState {
  dashboard: any;

  getDashboard(id: number | string): Promise<void>;
  setDashboard: (value: any) => void;
}

// initial state
const initialState: InvoiceDetailState = {
  dashboard: null,

  getDashboard: async () => {},
  setDashboard: (): void => {},
};

// context
export const DashboardContext = createContext(initialState);

// provider
export const DashboardProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DashboardContext.Provider
      value={{
        // state
        dashboard: state.dashboard,

        // actions
        getDashboard: async id => await actions.getDashboard(dispatch, id),
        setDashboard: value => actions.setDashboard(dispatch, value),
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
