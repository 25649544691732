import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { NoResultsDetail } from 'components/NoResults';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from 'components/Pagination';
import cloudez_icon from 'assets/img/svg/cloud_cloudez.svg';
import { CloudCard, Content, Logo } from './styles';
import Placeholder from '../SharedPlan/Placeholder';

const Emails = ({
  getEmails,
  emails,
  setSelectedShareResource,
  selectedShareResource,
  search,
  loading,
}) => {
  return (
    <>
      <Row
        style={{
          width: '100%',
          margin: 0,
          marginTop: '25px',
        }}
      >
        {!loading ? (
          emails?.emails?.length ? (
            emails?.emails?.map(c => {
              return (
                <Col key={c.id} xl="2">
                  <CloudCard
                    key={c.id}
                    cloud={c}
                    selected={
                      selectedShareResource && selectedShareResource.id === c.id
                    }
                    onClick={() => setSelectedShareResource(c)}
                  >
                    <Content>
                      <Logo>
                        <img src={cloudez_icon} width="45px" alt="logo" />
                      </Logo>
                    </Content>
                    <span>{c.domain || c.name}</span>
                    <p>{c.cloud_display}</p>
                  </CloudCard>
                </Col>
              );
            })
          ) : (
            <Col xl="2">
              <NoResultsDetail height="130px" width="100%">
                <FontAwesomeIcon icon={faCloud} />
                Nenhum cloud.
              </NoResultsDetail>
            </Col>
          )
        ) : (
          <Placeholder />
        )}
      </Row>
      <Pagination
        onChangePage={getEmails}
        pageSize="12"
        count={emails?.count}
        previous={emails?.previous}
        next={emails?.next}
        current={emails?.current}
        search={search}
      />
    </>
  );
};

export default Emails;
