import styled from 'styled-components';

export const HelpedLine = styled.div`
  position: absolute;
  width: 5px;
  height: 100%;

  background-color: ${props => props.theme.successNew};

  border-radius: 20px 5px 5px 20px;
`;
