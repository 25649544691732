import axios from 'axios';

import { useAuth, useLayout } from 'hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Footer,
  Header,
  Icon,
  Subtitle,
  Text,
  Title,
} from 'components/ListingModal/styles';
import Modal from 'components/Modal';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { createInvoiceService } from 'services/invoices';
import { useState } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import apiKeys from 'utils/apikeys';
import { Content, CustomButton } from './styles';

import plus from './images/plus.svg';

const ProductModal = ({ show, setShow, item }) => {
  const { user, company } = useAuth();

  const history = useHistory();

  const theme = useLayout();

  const url = code =>
    `https://docs.google.com/presentation/d/${code}/edit?usp=sharing`;

  const selectedLink = {
    'Cloudez Experts': url('1OAeGgrW9w7iWDfWFGRpL7Ax10UWtZFFxw98d48tOPxA'),
    'Cloudez Messenger': url('1kgzpy4gomKa61-8MROBY_GK8CZkElI9VqZnQ98V8luo'),
    'Cloudez Analisys': url('15GKhgI1M5GrYxk3rHQVZAsf4xooeChSI2BSD6WJEXz8'),
    'Cloudez Automation': url('1A5aSB6E3EFO3wouYtX90fdFcdXPwxnp9BsAZwI1P4tA'),
    'Cloudez Architect': url('1FHv51xE5oyVYptaodu2McjTMyKkpbEB6yDTxDp4_R0A'),
    'Migração Assistida': url('1xMlZX9auzQspb0KAy9DGDOwNIgLRz6Jyz-Dxx_d85wg'),
    'Cloudez SMTP': url('1q6IY09ZSQOR2Xw_Pw6pFj8uZRqFcv_PfnFR7WEFsyak'),
  }[item.title];

  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const onSubmitCheckout = async () => {
    setLoadingCheckout(true);
    try {
      const { data } = await createInvoiceService({
        items: [
          {
            price: {
              amount: item.value,
              currency: 'BRL',
            },
            description: item.title,
          },
        ],
        expired_at: moment().add(1, 'days').format('YYYY-MM-DD'),
        user_email: user.email,
        user: user.id,
      });

      if (process.env.NODE_ENV !== 'development') {
        axios.post('https://api.emailjs.com/api/v1.0/email/send', {
          service_id: apiKeys.SERVICE_ID,
          template_id: apiKeys.TEMPLATE_ID,
          user_id: apiKeys.PUBLIC_KEY,
          template_params: {
            user_email: user.email,
            service: item.title,
          },
        });
      }

      setLoadingCheckout(false);
      history.push(`/invoices/${data.id}/`);
    } catch (err) {
      setLoadingCheckout(false);
      console.log(err);
    }
  };

  const onSubmit = async () => {
    try {
      if (item.title === 'Migração Assistida') {
        history.push('/migrations/create/assisted');
      } else {
        const payload: any = {
          user_name: user.full_name,
          company_name: company.name,
          phone: user.phone,
          email: user.email,
          chosen_service: item.title,
        };

        await axios.post(
          `${process.env.REACT_APP_HOOK_URL}/products_services`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_HOOK_TOKEN}`,
            },
          },
        );

        item.action();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal width="560px" show={show} setShow={setShow}>
        <Header>
          <Icon>
            <FontAwesomeIcon size="lg" icon={item.fa} />
          </Icon>
          <Text>
            <Title>{item.title}</Title>
            <Subtitle>{item.subtitle}</Subtitle>
          </Text>
        </Header>
        <Content>
          <div>
            <img src={item.iconModal} alt={item.title} />
          </div>
          <span className="mainText">{item.content || item.content1}</span>
          {item?.content2 && <span className="mainText">{item.content2}</span>}
          <table>
            <tr>
              <td>
                <div className="boxValue">
                  <span className="title">VALOR</span>
                  {item.title === 'Cloudez Automation' && (
                    <span className="auxiliarText">a partir de</span>
                  )}
                  <span className="value">
                    {item.value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                  {item.title === 'Cloudez SMTP' ||
                  item.title === 'Cloudez Experts' ? (
                    <span className="auxiliarText">por cloud ativo</span>
                  ) : null}
                  {item.title === 'Cloudez Automation' && (
                    <span className="auxiliarText">pagamento único</span>
                  )}
                </div>
              </td>
              <div className="absolutePlus">
                {item.title === 'Cloudez Automation' && (
                  <img src={plus} alt="Plus" />
                )}
              </div>

              <td>
                <div className="boxRecurrence">
                  <span className="title">RECORRÊNCIA</span>
                  <span className="auxiliarText">{item.recurrence}</span>
                  {item.title === 'Cloudez Automation' && (
                    <span className="value">
                      {(item.value / 10).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </span>
                  )}
                </div>
              </td>
            </tr>
            {item.title === 'Cloudez Automation' && (
              <tr className="optionalTr">
                <td colSpan={3}>
                  <span className="extra">Contrato mínimo de 12 meses</span>
                </td>
              </tr>
            )}
          </table>
          <CustomButton
            style={{
              width: '100%',
              height: '45px',
              marginBottom: '20px',
              border: `1px solid ${theme.interface3}`,
              color: theme.interface5,
            }}
            type="button"
            outline
            icon
            onClick={() => window.open(selectedLink)}
          >
            <div
              style={{
                backgroundColor: theme.favorite,
                padding: '4px',
                borderRadius: '5px',
                marginRight: '20px',
                display: 'flex',
              }}
            >
              <FontAwesomeIcon
                icon={faGoogleDrive}
                style={{ color: '#ffffff' }}
              />
            </div>
            Acesse o material de apoio para saber mais
          </CustomButton>
        </Content>
        <Footer hasLink>
          <Button
            disabled={loadingCheckout}
            style={{ width: '200px' }}
            type="button"
            outline
            onClick={onSubmit}
          >
            {item.title === 'Migração Assistida'
              ? 'Inicie já sua migração'
              : 'Solicitar Apresentação'}
          </Button>

          <Button
            type="button"
            icon
            disabled={loadingCheckout}
            onClick={onSubmitCheckout}
          >
            {loadingCheckout ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Contratar'
            )}
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export default ProductModal;
