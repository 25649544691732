import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import api from 'services/api';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardBody,
  CardContainer,
  CardContainerFirst,
  CardInvoice,
} from '../styles';
import DashboardSummary from './Placeholder';

const Summary = () => {
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getCard() {
      setLoading(true);
      try {
        const { data } = await api.get(`/v3/company/dashboard_invoices/`);
        setCard(data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }

    if (!card) getCard();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <DashboardSummary />
      ) : (
        <>
          <CardContainerFirst>
            <CardInvoice>
              <CardBody>
                <Link to="/invoices?status=3" className="title_icon">
                  <h3 className="title-invoice">Faturas Pagas</h3>
                  <FontAwesomeIcon icon={faArrowRight} color="#545c68" />
                </Link>
                <h4 className="title-last_days">Últimos 30 dias</h4>
                <p className="paid">
                  <CurrencyFormat
                    value={card?.paid}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={card?.paid === 'BRL' ? '$ ' : 'R$ '}
                  />
                </p>
              </CardBody>
            </CardInvoice>
            <CardInvoice>
              <CardBody>
                <Link to="/invoices?status=2" className="title_icon">
                  <h3 className="title-invoice">Faturas Atrasadas</h3>
                  <FontAwesomeIcon icon={faArrowRight} color="#545c68" />
                </Link>
                <h4 className="title-last_days">Últimos 30 dias</h4>
                <p className="late">
                  <CurrencyFormat
                    value={card?.late}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={card?.late === 'BRL' ? '$ ' : 'R$ '}
                  />
                </p>
              </CardBody>
            </CardInvoice>
          </CardContainerFirst>

          <CardContainer>
            <CardInvoice>
              <CardBody>
                <Link to="/invoices?status=1" className="title_icon">
                  <h3 className="title-invoice">Faturas Pendentes</h3>
                  <FontAwesomeIcon icon={faArrowRight} color="#545c68" />
                </Link>
                <h4 className="title-last_days">Últimos 30 dias</h4>
                <p className="not-paid">
                  <CurrencyFormat
                    value={card?.not_paid}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={card?.not_paid === 'BRL' ? '$ ' : 'R$ '}
                  />
                </p>
              </CardBody>
            </CardInvoice>
            <CardInvoice>
              <CardBody>
                <Link to="/transfers" className="title_icon">
                  <h3 className="title-invoice">Total de crédito</h3>
                  <FontAwesomeIcon icon={faArrowRight} color="#545c68" />
                </Link>
                <h4 className="title-last_days">Últimos 30 dias</h4>
                <p className="credit">
                  <CurrencyFormat
                    value={card?.credit}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={card?.credit === 'BRL' ? '$ ' : 'R$ '}
                  />
                </p>
              </CardBody>
            </CardInvoice>
          </CardContainer>
        </>
      )}
    </>
  );
};

export default Summary;
