export const plans = [
  {
    id: 1,
    name: 'partner',
    pricing: [
      {
        id: 1,
        name: 'Clouds',
        description: 'Padrão',
        plans: [
          {
            id: 1,
            name: 'Individual 1G',
            value: 129,
          },
          {
            id: 2,
            name: 'Ilimitado 1G',
            value: 149,
          },
          {
            id: 3,
            name: 'Ilimitado 2G',
            value: 289,
          },
          {
            id: 4,
            name: 'Ilimitado 4G',
            value: 579,
          },
          {
            id: 5,
            name: 'Ilimitado 8G',
            value: 969,
          },
          {
            id: 6,
            name: 'Ilimitado 16G',
            value: 1939,
          },
          {
            id: 7,
            name: 'Ilimitado 32G',
            value: 3969,
          },
          {
            id: 8,
            name: 'Ilimitado 64G',
            value: 7919,
          },
          {
            id: 9,
            name: 'Ilimitado 96G',
            value: 11978,
          },
          {
            id: 10,
            name: 'Ilimitado 128G',
            value: 15539,
          },
          {
            id: 11,
            name: 'Ilimitado 192G',
            value: 23589,
          },
        ],
      },
      {
        id: 2,
        name: 'Clouds',
        description: 'Focados em Processamento',
        plans: [
          {
            id: 1,
            name: 'Ilimitado 4G CPU',
            value: 849,
          },
          {
            id: 2,
            name: 'Ilimitado 8G CPU',
            value: 1419,
          },
          {
            id: 3,
            name: 'Ilimitado 16G CPU',
            value: 2829,
          },
          {
            id: 4,
            name: 'Ilimitado 32G CPU',
            value: 5519,
          },
          {
            id: 5,
            name: 'Ilimitado 64G CPU',
            value: 10739,
          },
          {
            id: 6,
            name: 'Ilimitado 96G CPU',
            value: 15489,
          },
        ],
      },
      {
        id: 3,
        name: 'Clouds',
        description: 'Focados em Memória',
        plans: [
          {
            id: 1,
            name: 'Ilimitado 24G RAM',
            value: 1419,
          },
          {
            id: 2,
            name: 'Ilimitado 48G RAM',
            value: 2689,
          },
          {
            id: 3,
            name: 'Ilimitado 90G RAM',
            value: 5229,
          },
          {
            id: 4,
            name: 'Ilimitado 150G RAM',
            value: 9469,
          },
          {
            id: 5,
            name: 'Ilimitado 300G RAM',
            value: 18369,
          },
        ],
      },
      {
        id: 4,
        name: 'Clouds',
        description: 'Para E-mail',
        plans: [
          {
            id: 1,
            name: 'Email 20G',
            value: 169,
          },
          {
            id: 2,
            name: 'Email 60G',
            value: 189,
          },
          {
            id: 3,
            name: 'Email 100G',
            value: 269,
          },
          {
            id: 4,
            name: 'Email 200G',
            value: 369,
          },
          {
            id: 5,
            name: 'Email 300G',
            value: 449,
          },
          {
            id: 6,
            name: 'Email 400G',
            value: 589,
          },
          {
            id: 7,
            name: 'Email 500G',
            value: 669,
          },
          {
            id: 8,
            name: 'Email 600G',
            value: 979,
          },
          {
            id: 9,
            name: 'Email 700G',
            value: 1419,
          },
          {
            id: 10,
            name: 'Email 800G',
            value: 1519,
          },
          {
            id: 11,
            name: 'Email 900G',
            value: 1629,
          },
          {
            id: 12,
            name: 'Email 1000G',
            value: 2049,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'certified',
    pricing: [
      {
        id: 1,
        name: 'Clouds',
        description: 'Padrão',
        plans: [
          {
            id: 1,
            name: 'Individual 1G',
            value: 99,
          },
          {
            id: 2,
            name: 'Ilimitado 1G',
            value: 129,
          },
          {
            id: 3,
            name: 'Ilimitado 2G',
            value: 249,
          },
          {
            id: 4,
            name: 'Ilimitado 4G',
            value: 489,
          },
          {
            id: 5,
            name: 'Ilimitado 8G',
            value: 869,
          },
          {
            id: 6,
            name: 'Ilimitado 16G',
            value: 1749,
          },
          {
            id: 7,
            name: 'Ilimitado 32G',
            value: 3439,
          },
          {
            id: 8,
            name: 'Ilimitado 64G',
            value: 6879,
          },
          {
            id: 9,
            name: 'Ilimitado 96G',
            value: 10449,
          },
          {
            id: 10,
            name: 'Ilimitado 128G',
            value: 13889,
          },
          {
            id: 11,
            name: 'Ilimitado 192G',
            value: 20769,
          },
        ],
      },
      {
        id: 2,
        name: 'Clouds',
        description: 'Focados em Processamento',
        plans: [
          {
            id: 1,
            name: 'Ilimitado 4G CPU',
            value: 729,
          },
          {
            id: 2,
            name: 'Ilimitado 8G CPU',
            value: 1329,
          },
          {
            id: 3,
            name: 'Ilimitado 16G CPU',
            value: 2479,
          },
          {
            id: 4,
            name: 'Ilimitado 32G CPU',
            value: 4949,
          },
          {
            id: 5,
            name: 'Ilimitado 64G CPU',
            value: 9769,
          },
          {
            id: 6,
            name: 'Ilimitado 96G CPU',
            value: 14479,
          },
        ],
      },
      {
        id: 3,
        name: 'Clouds',
        description: 'Focados em Memória',
        plans: [
          {
            id: 1,
            name: 'Ilimitado 24G RAM',
            value: 1329,
          },
          {
            id: 2,
            name: 'Ilimitado 48G RAM',
            value: 2469,
          },
          {
            id: 3,
            name: 'Ilimitado 90G RAM',
            value: 4849,
          },
          {
            id: 4,
            name: 'Ilimitado 150G RAM',
            value: 8979,
          },
          {
            id: 5,
            name: 'Ilimitado 300G RAM',
            value: 18019,
          },
        ],
      },
      {
        id: 4,
        name: 'Clouds',
        description: 'Para E-mail',
        plans: [
          {
            id: 1,
            name: 'Email 20G',
            value: 159,
          },
          {
            id: 2,
            name: 'Email 60G',
            value: 189,
          },
          {
            id: 3,
            name: 'Email 100G',
            value: 259,
          },
          {
            id: 4,
            name: 'Email 200G',
            value: 349,
          },
          {
            id: 5,
            name: 'Email 300G',
            value: 439,
          },
          {
            id: 6,
            name: 'Email 400G',
            value: 569,
          },
          {
            id: 7,
            name: 'Email 500G',
            value: 659,
          },
          {
            id: 8,
            name: 'Email 600G',
            value: 979,
          },
          {
            id: 9,
            name: 'Email 700G',
            value: 1419,
          },
          {
            id: 10,
            name: 'Email 800G',
            value: 1519,
          },
          {
            id: 11,
            name: 'Email 900G',
            value: 1629,
          },
          {
            id: 12,
            name: 'Email 1000G',
            value: 2049,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'master',
    pricing: [
      {
        id: 1,
        name: 'Clouds',
        description: 'Padrão',
        plans: [
          {
            id: 1,
            name: 'Individual 1G',
            value: 89,
          },
          {
            id: 2,
            name: 'Ilimitado 1G',
            value: 119,
          },
          {
            id: 3,
            name: 'Ilimitado 2G',
            value: 229,
          },
          {
            id: 4,
            name: 'Ilimitado 4G',
            value: 439,
          },
          {
            id: 5,
            name: 'Ilimitado 8G',
            value: 799,
          },
          {
            id: 6,
            name: 'Ilimitado 16G',
            value: 1629,
          },
          {
            id: 7,
            name: 'Ilimitado 32G',
            value: 3129,
          },
          {
            id: 8,
            name: 'Ilimitado 64G',
            value: 6249,
          },
          {
            id: 9,
            name: 'Ilimitado 96G',
            value: 9499,
          },
          {
            id: 10,
            name: 'Ilimitado 128G',
            value: 12629,
          },
          {
            id: 11,
            name: 'Ilimitado 192G',
            value: 18879,
          },
        ],
      },
      {
        id: 2,
        name: 'Clouds',
        description: 'Focados em Processamento',
        plans: [
          {
            id: 1,
            name: 'Ilimitado 4G CPU',
            value: 649,
          },
          {
            id: 2,
            name: 'Ilimitado 8G CPU',
            value: 1199,
          },
          {
            id: 3,
            name: 'Ilimitado 16G CPU',
            value: 2249,
          },
          {
            id: 4,
            name: 'Ilimitado 32G CPU',
            value: 4449,
          },
          {
            id: 5,
            name: 'Ilimitado 64G CPU',
            value: 8879,
          },
          {
            id: 6,
            name: 'Ilimitado 96G CPU',
            value: 13149,
          },
        ],
      },
      {
        id: 3,
        name: 'Clouds',
        description: 'Focados em Memória',
        plans: [
          {
            id: 1,
            name: 'Ilimitado 24G RAM',
            value: 1299,
          },
          {
            id: 2,
            name: 'Ilimitado 48G RAM',
            value: 2409,
          },
          {
            id: 3,
            name: 'Ilimitado 90G RAM',
            value: 4739,
          },
          {
            id: 4,
            name: 'Ilimitado 150G RAM',
            value: 8759,
          },
          {
            id: 5,
            name: 'Ilimitado 300G RAM',
            value: 17579,
          },
        ],
      },
      {
        id: 4,
        name: 'Clouds',
        description: 'Para E-mail',
        plans: [
          {
            id: 1,
            name: 'Email 20G',
            value: 129,
          },
          {
            id: 2,
            name: 'Email 60G',
            value: 149,
          },
          {
            id: 3,
            name: 'Email 100G',
            value: 209,
          },
          {
            id: 4,
            name: 'Email 200G',
            value: 289,
          },
          {
            id: 5,
            name: 'Email 300G',
            value: 359,
          },
          {
            id: 6,
            name: 'Email 400G',
            value: 489,
          },
          {
            id: 7,
            name: 'Email 500G',
            value: 549,
          },
          {
            id: 8,
            name: 'Email 600G',
            value: 869,
          },
          {
            id: 9,
            name: 'Email 700G',
            value: 1419,
          },
          {
            id: 10,
            name: 'Email 800G',
            value: 1519,
          },
          {
            id: 11,
            name: 'Email 900G',
            value: 1629,
          },
          {
            id: 12,
            name: 'Email 1000G',
            value: 2049,
          },
        ],
      },
    ],
  },
];
