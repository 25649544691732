import api from 'services/api';

export const getUsersService = async params => {
  try {
    return await api.get('/v3/company/user/permission', { params });
  } catch (error) {
    throw error;
  }
};

export const addUserService = async data => {
  try {
    return await api.post(`/v3/company/user/permission/`, data);
  } catch (error) {
    throw error;
  }
};

export const updateUserService = async (id: number | string, data) => {
  try {
    return await api.patch(`/v3/company/user/permission/${id}/`, data);
  } catch (error) {
    throw error;
  }
};

export const deleteUserService = async (id: number | string) => {
  try {
    return await api.delete(`/v3/company/user/permission/${id}/`);
  } catch (error) {
    throw error;
  }
};
