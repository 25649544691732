import React, { useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';

import { Wrapper } from './styles';

interface IToastCloseButton {
  type?: string;
  closeToast?: () => void;
}

export default ({ type, closeToast }: IToastCloseButton) => {
  const theme = useContext(ThemeContext);

  const getColor = useCallback(() => {
    switch (type) {
      case 'info':
        return theme.label;
      case 'success':
        return theme.success;
      case 'error':
        return theme.error;
      default:
        break;
    }
  }, [type, theme]);

  const getIcon = useCallback(() => {
    switch (type) {
      case 'info':
        return faInfo;
      case 'success':
        return faCheck;
      case 'error':
        return faTimes;
      default:
        break;
    }
  }, [type]);

  return (
    <Wrapper color={getColor()}>
      <FontAwesomeIcon onClick={closeToast} icon={getIcon()} />
    </Wrapper>
  );
};
