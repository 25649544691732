import { useState, useContext } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { Input, Label, Field, Error } from 'components/Input';
import api from 'services/api';
import { useLayout } from 'hooks';
import { InvoiceListingContext } from '../_context/state';

const DateModal = ({ show, setShow, invoice }) => {
  const { updateInvoice } = useContext(InvoiceListingContext);

  const [loading, setLoading] = useState(false);

  const theme = useLayout();

  const createdAt = moment(invoice?.created_at).format('DD/MM/YYYY');
  const expiredAt = moment(invoice?.expired_at).format('DD/MM/YYYY');

  const startChargingAt = moment(invoice?.start_charging_at).format(
    'YYYY-MM-DD',
  );

  const maxDate = moment(invoice?.start_charging_at)
    .add(10, 'days')
    .format('YYYY-MM-DD');

  const [date, setDate] = useState(
    moment(invoice?.expired_at).format('YYYY-MM-DD'),
  );

  const [error, setError] = useState(null);

  const changeDate = async () => {
    setLoading(true);
    setError(null);
    try {
      if (startChargingAt <= date && date <= maxDate) {
        await updateInvoice('expired_at', date, invoice.id);
        await api.post(`/v3/invoice/${invoice.id}/refresh-bankslip/`);
      } else {
        toast.error('Escolha uma data válida');
        setLoading(false);
        setError(null);
        return;
      }

      setError(null);
      setShow();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e?.response?.data);
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faCalendar} />
        </Icon>
        <Text>
          <Title>Mudar data da fatura</Title>
        </Text>
      </Header>

      <Content>
        <Field>
          <Label>Data de criação</Label>
          <p>{createdAt}</p>
        </Field>
        <Field>
          <Label>Data de expiração</Label>
          <p>{expiredAt}</p>
        </Field>
        <Field>
          <Label>Nova data de expiração:</Label>
          <Input
            type="date"
            min={startChargingAt}
            max={maxDate}
            value={date}
            onChange={e => setDate(e.target.value)}
            background
            block
            error={error?.[Object.keys(error)[0]]?.[0]}
          />
          {error?.[Object.keys(error)[0]]?.[0] && (
            <Error>{error?.[Object.keys(error)[0]]?.[0]}</Error>
          )}
        </Field>
        <span
          style={{
            fontSize: '16px',
            color: theme.interface5,
          }}
        >
          <b
            style={{
              color: theme.alertNew,
            }}
          >
            Atenção:
          </b>{' '}
          A troca da data de expiração não desbloqueia automaticamente os
          recursos do cloud.
        </span>
      </Content>
      <Footer>
        <Button onClick={changeDate} icon disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Mudar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default DateModal;
