import history from 'services/history';
import { getTransfersService, updateTransferService } from 'services/transfer';

type Props = {
  type?: string;
  payload: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

const mapPriorityStatus = (priority: number) => {
  switch (priority) {
    case 0:
      return undefined;
    case 1:
      return false;
    case 2:
      return true;
    default:
      true;
  }
};

export const getTransfers = async (
  dispatch: ({ type, payload }: Props) => void,
  page: number | string,
  initial,
  final,
  order,
  ctrl,
) => {
  setLoading(dispatch, true);

  try {
    const { data } = await getTransfersService(
      {
        page,
        initial,
        final,
        [`${order?.column}_order`]: mapPriorityStatus(order?.value),
      },
      ctrl,
    );

    dispatch({
      type: 'GET_TRANSFERS',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);

    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const updateTransfer = async (
  dispatch: ({ type, payload }: Props) => void,
  field: string,
  value,
  id: string | number,
) => {
  try {
    const { data } = await updateTransferService(id, {
      [field]: value,
    });

    dispatch({
      type: 'UPDATE_TRANSFER',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_TRANSFER_ERROR',
      payload: e?.response?.data,
    });
  }
};
