import api from 'services/api';

export const getCustomersService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/company/user/', {
      params,
      signal: ctrl.signal,
    } as any);
  } catch (error) {
    throw error;
  }
};

export const deleteCustomerService = async (id: number | string) => {
  try {
    return await api.delete(`/v3/company/user/${id}/`);
  } catch (error) {
    throw error;
  }
};

export const getWebsiteTypes = async params => {
  try {
    return await api.get('/v3/website-type/', { params });
  } catch (error) {
    throw error;
  }
};

export const getUserService = async (id: number | string) => {
  try {
    return await api.get(`/v3/company/user/${id}/`);
  } catch (error) {
    throw error;
  }
};

export const getBillingService = async (id: number | string) => {
  try {
    return await api.get(`/v3/billing/${id}/`);
  } catch (error) {
    throw error;
  }
};
export const getInvoiceService = async params => {
  try {
    return await api.get(`/v3/invoice/`, { params });
  } catch (error) {
    throw error;
  }
};
export const getCloudsService = async params => {
  try {
    return await api.get(`/v3/cloud/`, { params });
  } catch (error) {
    throw error;
  }
};
export const getWebsitesService = async params => {
  try {
    return await api.get(`/v3/website/`, { params });
  } catch (error) {
    throw error;
  }
};
export const getCompanyPlanService = async params => {
  try {
    return await api.get(`/v3/company/plan/`, { params });
  } catch (error) {
    throw error;
  }
};
export const getEmailsService = async params => {
  try {
    return await api.get(`/v3/email/`, { params });
  } catch (error) {
    throw error;
  }
};

export const updateCustomerService = async (id: number | string, data) => {
  try {
    return await api.patch(`/v3/company/user/${id}/`, data);
  } catch (error) {
    throw error;
  }
};
