import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import { setLoading, getTickets, updateTicket, setRedAlert } from './actions';

interface TicketListingState {
  tickets: any[];
  count: number;
  previous: number;
  current: number;
  next: number;
  loading: boolean;
  errors: any;

  getTickets(
    page: number,
    search: string,
    status: any,
    filter: any,
    user: any,
    order: any,
    ctrl: AbortController,
  ): Promise<void>;
  setLoading: (value: boolean) => void;
  updateTicket(
    field: string,
    value: string | number | boolean,
    id: string | number,
    ticket?: any,
  ): Promise<void>;
  setRedAlert(id: number | string): void;
}

// initial state
const initialState: TicketListingState = {
  tickets: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,

  setLoading: (): void => {},
  getTickets: async () => {},
  updateTicket: async () => {},
  setRedAlert: async () => {},
};

// context
export const TicketListingContext = createContext(initialState);

// provider
export const TicketListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TicketListingContext.Provider
      value={{
        // state
        tickets: state.tickets,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getTickets: async (page, search, status, filter, user, order, ctrl) =>
          await getTickets(
            dispatch,
            page,
            search,
            status,
            filter,
            user,
            order,
            ctrl,
          ),
        updateTicket: async (field, value, id, ticket) =>
          await updateTicket(dispatch, field, value, id, ticket),
        setRedAlert: async id => await setRedAlert(dispatch, id),
      }}
    >
      {children}
    </TicketListingContext.Provider>
  );
};
