import styled, { css } from 'styled-components';
import { Input } from 'components/Input';

interface ISearchProps {
  block: boolean;
  noResults: boolean;
}

export const SearchWrapper = styled.div<ISearchProps>`
  opacity: 1;
  position: relative;

  ${props =>
    props.block &&
    css`
      width: 100%;
    `};

  svg {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 14px;
    font-size: 17px;
    color: ${props => props.theme.interface3};
  }

  p {
    margin-left: 20px;
    display: ${props => (props.noResults ? 'block' : 'none')};
    font-size: 9px;
    font-style: italic;
    color: ${props => props.theme.error};
  }

  &:focus-within {
    svg {
      transition: all 0.2s ease-in-out;
      color: ${props => props.theme.brand_primary_color};
    }
  }
`;

export const SearchInput = styled(Input)<ISearchProps>`
  padding: 8px 8px 8px 36px;

  ${props =>
    props.noResults &&
    css`
      border: 1px solid ${props => props.theme.error};
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 65px;
  background: rgb(232, 234, 239, 0.5);
  border-radius: 5px;
  margin-bottom: 40px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 522px) {
    padding: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      display: none;
    }
  }
`;
