import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { PlanPreview, SharedPreview } from './styles';

const PlanPreviewComponent = ({ _plan, isEmail, basicMarkup }: any) => {
  return (
    <PlanPreview>
      {_plan.category && _plan.category === 1 ? (
        <>
          <div className="plan-title">
            <span>{_plan.name || '--------------'}</span>
            <span>
              {_plan.cloud_size ? (
                <>{_plan.cloud_size?.memory} RAM</>
              ) : (
                '-------'
              )}
            </span>
          </div>

          <p className="tip">
            {_plan.description !== 'False'
              ? _plan.description
              : '------------------------'}
          </p>

          <div className="stats">
            <div>
              <span>DISCO</span>
              <span className="value">{_plan.cloud_size?.disk || '-'}</span>
            </div>

            <div>
              <span>PROCESSADOR</span>
              <span className="value">
                {_plan.cloud_size ? (
                  <>{_plan.cloud_size?.cores} CPU Core </>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
        </>
      ) : _plan.category ? (
        <SharedPreview>
          <h4>{_plan.name || '--------------'}</h4>
          <div className="shared-info">
            <span className="category">
              {_plan.category === 9
                ? 'Site compartilhado'
                : 'E-mail compartilhado'}
            </span>

            <div className="price">
              <h6>Lucro Mensal*</h6>
              <CurrencyFormat
                className="profit-amount"
                value={parseFloat(basicMarkup) || 0}
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
              />
            </div>

            <div className="price">
              <h6>Lucro Anual*</h6>
              <CurrencyFormat
                className="profit-amount"
                value={
                  Number(basicMarkup) * 12 - Number(basicMarkup) * 12 * 0.15 ||
                  0
                }
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                prefix="R$ "
              />
            </div>
          </div>
        </SharedPreview>
      ) : (
        <div className="no-category">Selecione uma categoria</div>
      )}
    </PlanPreview>
  );
};

export default PlanPreviewComponent;
