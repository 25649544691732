import styled from 'styled-components';
import { transparentize } from 'polished';

export const HoverText = styled.span`
  visibility: hidden;
  width: 200px;
  background-color: ${props => transparentize(0.2, props.theme.interface6)};
  color: ${props => props.theme.interface1} !important;
  text-align: center;
  padding: 6px;
  border-radius: 6px;

  position: absolute;
  z-index: 3;
`;

export const Hover = styled.p`
  position: absolute;
  height: 18px;
  width: 8px;
  left: 64px;
  top: -1px;
  border-bottom: 1px dotted ${props => props.theme.brand_primary_color};

  &:hover ${HoverText} {
    visibility: visible;
  }
`;
