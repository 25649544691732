import { useMediaQuery } from '@react-hook/media-query';

interface UseDeviceProps {
  isMobile: boolean;
}

export default function useDevice(): UseDeviceProps {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return { isMobile };
}
