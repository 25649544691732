import React, { useEffect, useRef } from 'react';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput as SearchInputStyle, SearchWrapper } from './styles';

type SearchInputProps = {
  q: string | number;
  noResults?: any;
  cb: any;
  loading?: boolean;
  block: boolean;
  style?: React.CSSProperties;
  setQ: (e: any) => void;
};

const SearchInput: React.FC<SearchInputProps> = ({
  q,
  noResults,
  cb,
  setQ,
  block,
  loading,
  ...rest
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef)
      inputRef.current.addEventListener('keydown', e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          cb(inputRef.current.value);
        }
      });

    // eslint-disable-next-line
  }, [inputRef]);

  return (
    <SearchWrapper noResults={noResults} block={block} {...rest}>
      <FontAwesomeIcon
        data-testid="iconSearch"
        icon={loading ? faSpinner : faSearch}
        spin={loading}
        onClick={loading ? null : () => cb(q)}
      />
      <SearchInputStyle
        ref={inputRef}
        block={block}
        type="text"
        placeholder="Faça sua busca aqui"
        value={q}
        onChange={e => setQ(e.target.value)}
        noResults={noResults}
        {...rest}
      />
      <p>Sua busca não foi encontrada</p>
    </SearchWrapper>
  );
};

export default SearchInput;
