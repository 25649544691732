import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import Pagination from 'components/Pagination';

import Swipe from 'react-easy-swipe';

import { Row, Col } from 'react-bootstrap';

import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { Button } from '@cloudez/cloudez-design-system';

import { Tabs, Tab, TabContent } from 'components/DetailConfig';
import { Arrows } from 'components/Arrows';
import { withTheme } from 'styled-components';
import history from 'services/history';
import CurrencyFormat from 'react-currency-format';
import { useAuth } from 'hooks';
import {
  CustomerDetailContext,
  CustomerDetailProvider,
} from './_context/state';

import Invoices from './Invoices';
import Clouds from './Clouds';
import Shared from './Shared';
import RemoveModal from './RemoveModal';
import AddressModal from './AddressModal';
import BillingDateModal from './BillingDateModal';
import Placeholder from './Placeholder';
import PlansListing from './PlansListing';

import {
  Header,
  Title,
  Card,
  CardHeader,
  AddressCard,
  Informations,
  Label,
  Value,
  Active,
  Status,
  CardBody,
} from './styles';

const UserDetail = ({ match, theme }) => {
  const { user: partner } = useAuth();

  const [tab, setTab] = useState(1);
  const [modal, showModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [billingModal, setBillingModal] = useState(false);

  const {
    loading,
    setLoading,
    user,
    invoices,
    companyPlan,
    clouds,
    billing,
    websites,
    emails,
    getUser,
    getBilling,
    getInvoice,
    getClouds,
    getCompanyPlan,
    getWebsites,
    getEmails,
    setBilling,
    setUser,
  } = useContext(CustomerDetailContext);

  const [nextClouds, setNextClouds] = useState(null);
  const [previousClouds, setPreviousClouds] = useState(null);
  const [currentClouds, setCurrentClouds] = useState(1);

  const [nextInvoices, setNextInvoices] = useState(null);
  const [previousInvoices, setPreviousInvoices] = useState(null);
  const [currentInvoices, setCurrentInvoices] = useState(1);

  const [nextCompanyPlan, setNextCompanyPlan] = useState(null);
  const [previousCompanyPlan, setPreviousCompanyPlan] = useState(null);
  const [currentCompanyPlan, setCurrentCompanyPlan] = useState(1);

  const [countClouds, setCountClouds] = useState(null);
  const [countInvoices, setCountInvoices] = useState(null);
  const [countCompanyPlan, setCountCompanyPlan] = useState(null);

  const [totalPagesClouds, setTotalPagesClouds] = useState(null);
  const [totalPagesInvoices, setTotalPagesInvoices] = useState(null);
  const [totalPagesCompanyPlan, setTotalPagesCompanyPlan] = useState(null);

  function handleChangeTab(tab: number) {
    setCurrentClouds(1);
    setCurrentInvoices(1);
    setCurrentCompanyPlan(1);
    setTab(tab);
  }

  const updatePaginationClouds = (page: number) => {
    setCurrentClouds(page);
  };

  const updatePaginationInvoices = (page: number) => {
    setCurrentInvoices(page);
  };

  const updatePaginationCompanyPlan = (page: number) => {
    setCurrentCompanyPlan(page);
  };

  const getCloudCallBack = async (page: number) => {
    if (user) {
      const count = await getClouds(user.id, page);
      const totalPages = Math.ceil(count / 12);
      setCountClouds(count);
      setTotalPagesClouds(totalPages);
    }
  };

  const getInvoiceCallBack = async (page: number) => {
    if (user) {
      const count = await getInvoice(user.id, page);
      const totalPages = Math.ceil(count / 12);
      setCountInvoices(count);
      setTotalPagesInvoices(totalPages);
    }
  };

  const getCompanyPlanCallBack = async (page: number) => {
    if (user) {
      const count = await getCompanyPlan(user.id, page);
      const totalPages = Math.ceil(count / 12);
      setCountCompanyPlan(count);
      setTotalPagesCompanyPlan(totalPages);
    }
  };

  useEffect(() => {
    if (totalPagesClouds <= 1) {
      setNextClouds(null);
      setPreviousClouds(null);
    } else if (currentClouds === 1) {
      setNextClouds(2);
      setPreviousClouds(null);
    } else if (currentClouds === totalPagesClouds) {
      setNextClouds(null);
      setPreviousClouds(totalPagesClouds - 1);
    } else {
      setNextClouds(currentClouds + 1);
      setPreviousClouds(currentClouds - 1);
    }
  }, [totalPagesClouds, currentClouds]);

  useEffect(() => {
    if (totalPagesInvoices <= 1) {
      setNextInvoices(null);
      setPreviousInvoices(null);
    } else if (currentInvoices === 1) {
      setNextInvoices(2);
      setPreviousInvoices(null);
    } else if (currentInvoices === totalPagesInvoices) {
      setNextInvoices(null);
      setPreviousInvoices(totalPagesInvoices - 1);
    } else {
      setNextInvoices(currentInvoices + 1);
      setPreviousInvoices(currentInvoices - 1);
    }
  }, [totalPagesInvoices, currentInvoices]);

  useEffect(() => {
    if (totalPagesCompanyPlan <= 1) {
      setNextCompanyPlan(null);
      setPreviousCompanyPlan(null);
    } else if (currentCompanyPlan === 1) {
      setNextCompanyPlan(2);
      setPreviousCompanyPlan(null);
    } else if (currentCompanyPlan === totalPagesCompanyPlan) {
      setNextCompanyPlan(null);
      setPreviousCompanyPlan(totalPagesCompanyPlan - 1);
    } else {
      setNextCompanyPlan(currentCompanyPlan + 1);
      setPreviousCompanyPlan(currentCompanyPlan - 1);
    }
  }, [totalPagesCompanyPlan, currentCompanyPlan]);

  useEffect(() => {
    switch (tab) {
      case 1:
        getCloudCallBack(currentClouds);
        break;
      case 2:
        getInvoiceCallBack(currentInvoices);
        break;
      case 3:
        getCompanyPlanCallBack(currentCompanyPlan);
        break;
      default:
        null;
    }
  }, [currentClouds, currentInvoices, currentCompanyPlan, tab]);

  const isPartner = useMemo(() => {
    if (user && !loading) {
      return partner.id === user.id;
    }

    return false;
  }, [user, partner, loading]);

  useEffect(() => {
    const { id } = match.params;

    async function getInfo() {
      setLoading(true);
      if (user) {
        await getBilling(user.id);
        await getInvoice(user.id);
        if (!partner.is_team_member) {
          await getCloudCallBack(1);
          await getInvoiceCallBack(1);
          await getCompanyPlanCallBack(1);
          await getWebsites(user.id);
          await getEmails(user.id);
        }
        await getCompanyPlan(user.id);
      } else {
        await getUser(id);
      }
      setLoading(false);
    }
    getInfo();

    // eslint-disable-next-line
  }, [user]);

  return user && !loading ? (
    <>
      {modal && !isPartner && (
        <RemoveModal
          show={modal}
          setShow={() => showModal(false)}
          user={user}
        />
      )}
      {addressModal && (
        <AddressModal
          billing={billing}
          user={user}
          show={addressModal}
          setShow={() => setAddressModal(false)}
          setBilling={setBilling}
          setUser={setUser}
        />
      )}
      {billingModal && (
        <BillingDateModal
          show={billingModal}
          setShow={() => setBillingModal(false)}
          user={user}
          setUser={setUser}
        />
      )}
      <Header data-testid="headerCustomerDetail">
        <Row>
          <Col md="10">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon
                size="4x"
                icon={faUser}
                color={theme.brand_primary_color}
              />
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Link to="/customers">Clientes</Link>
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>{user && user.email}</h3>
              </Title>
            </div>
          </Col>
          <Col
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!isPartner && (
              <Button
                block
                outline
                error
                remove
                onClick={() => showModal(true)}
              >
                Excluir usuário
              </Button>
            )}
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="5">
          <Row>
            <Col xl="12">
              <Card
                style={{
                  height: '250px',
                }}
              >
                <CardHeader>Informações</CardHeader>
                <Informations>
                  <Row
                    style={{
                      height: '100%',
                      width: '100%',
                      paddingBottom: '10px',
                    }}
                  >
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: '100%',
                      }}
                    >
                      <div>
                        <Label>País</Label>
                        <Value>{billing && (billing.country || '-')}</Value>
                      </div>
                      <div>
                        <Label>Endereço</Label>
                        <Value>
                          {billing &&
                            (billing.address1 || billing.address2 || '-')}
                        </Value>
                      </div>
                      <div>
                        <Label>Email financeiro</Label>
                        <Value>{billing && billing.email}</Value>
                      </div>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: '100%',
                      }}
                    >
                      <div>
                        <Label>CIDADE/ESTADO</Label>
                        <Value>
                          {billing && `${billing.city} - ${billing.state}`}
                        </Value>
                      </div>
                      <div>
                        <Label />
                        <Value />
                      </div>
                      <div>
                        <Label />
                        <Value />
                      </div>
                      <div>
                        <Label />
                        <Value />
                      </div>
                      <div>
                        <Label />
                        <Value />
                      </div>
                      <div>
                        <Label />
                        <Value />
                      </div>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: '100%',
                      }}
                    >
                      <div>
                        <Label>CEP</Label>
                        <Value>{billing && (billing.zipcode || '-')}</Value>
                      </div>
                      <div>
                        <Label>Número</Label>
                        <Value>
                          {billing && (billing.address_number || '-')}
                        </Value>
                      </div>
                      <div>
                        <Label>CPF/CNPJ</Label>
                        <Value>{billing && (billing.document_id || '-')}</Value>
                      </div>
                    </Col>
                  </Row>
                </Informations>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="3">
          <Card
            style={{
              height: '250px',
            }}
          >
            <CardHeader>Financeiro</CardHeader>
            <Informations>
              <Row
                style={{
                  height: '100%',
                  width: '100%',
                  paddingBottom: '10px',
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    height: '100%',
                  }}
                >
                  <div>
                    <Label>Mensalidade</Label>
                    <Value mrr>
                      <CurrencyFormat
                        value={user?.total_mrr ?? '-'}
                        displayType="text"
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix={user?.total_mrr > 0 ? 'R$ ' : ''}
                      />
                    </Value>
                  </div>
                  <div>
                    <Label>Data de Cobrança</Label>
                    <Value>{user.payment_day ?? '-'}</Value>
                  </div>
                  <Button
                    onClick={() => setBillingModal(true)}
                    style={{ fontSize: '14px' }}
                  >
                    Mudar data de cobrança <FontAwesomeIcon icon={faCalendar} />
                  </Button>
                </Col>
              </Row>
            </Informations>
          </Card>
        </Col>
        <Col
          xl="4"
          style={{
            height: '250px',
          }}
        >
          <AddressCard>
            <CardHeader>
              <p>{user && user.full_name}</p>
              <div>
                <span
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '14px',
                    color: theme.interface4,
                  }}
                >
                  Status
                </span>
                <Status active={!user.is_blocked}>
                  <Active />
                  {user.is_blocked ? 'Inativo' : 'Ativo'}
                </Status>
              </div>
            </CardHeader>
            <CardBody>
              <Button
                onClick={() => history.push(`/customers/plan/${user.id}`)}
              >
                Adicionar plano
              </Button>
              <Button icon outline onClick={() => setAddressModal(true)}>
                Alterar dados
              </Button>
              {/* <Button
                  outline
                  error
                  disabled={!user.is_blocked}
                  onClick={() => updateUser('is_blocked', !user.is_blocked)}
                >
                  Desbloquear
                </Button> */}
            </CardBody>
          </AddressCard>
        </Col>
      </Row>
      <Row>
        <Col>
          {invoices && companyPlan && (
            <>
              <Tabs>
                {!partner.is_team_member && (
                  <Tab active={tab === 1} onClick={() => handleChangeTab(1)}>
                    Clouds
                  </Tab>
                )}
                <Tab active={tab === 2} onClick={() => handleChangeTab(2)}>
                  Faturas
                </Tab>
                <Tab active={tab === 3} onClick={() => handleChangeTab(3)}>
                  Planos
                </Tab>
                {!partner.is_team_member && (
                  <Tab active={tab === 4} onClick={() => handleChangeTab(4)}>
                    Recursos compartilhados
                  </Tab>
                )}
              </Tabs>
              <Arrows
                data-testid="arrowLeft"
                disabled={tab === 1}
                icon={faChevronLeft}
                left="true"
                onClick={() => tab > 1 && handleChangeTab(tab - 1)}
                actions="true"
              />
              <Swipe
                onSwipeRight={() => tab > 1 && handleChangeTab(tab - 1)}
                onSwipeLeft={() => tab < 4 && handleChangeTab(tab + 1)}
                tolerance={100}
                handleChangeTab
              >
                <TabContent>
                  {(() => {
                    switch (tab) {
                      case 1:
                        return (
                          <>
                            <Clouds clouds={clouds} />
                            <Pagination
                              onChangePage={updatePaginationClouds}
                              pageSize="12"
                              next={nextClouds}
                              count={countClouds}
                              previous={previousClouds}
                              current={currentClouds}
                            />
                          </>
                        );
                      case 2:
                        return (
                          <>
                            <Invoices invoices={invoices} />
                            <Pagination
                              onChangePage={updatePaginationInvoices}
                              pageSize="12"
                              next={nextInvoices}
                              count={countInvoices}
                              previous={previousInvoices}
                              current={currentInvoices}
                            />
                          </>
                        );
                      case 3:
                        return (
                          <>
                            <PlansListing />
                            <Pagination
                              onChangePage={updatePaginationCompanyPlan}
                              pageSize="12"
                              next={nextCompanyPlan}
                              count={countCompanyPlan}
                              previous={previousCompanyPlan}
                              current={currentCompanyPlan}
                            />
                          </>
                        );
                      case 4:
                        return <Shared websites={websites} emails={emails} />;

                      default:
                        return null;
                    }
                  })()}
                </TabContent>
              </Swipe>
              <Arrows
                data-testid="arrowRight"
                disabled={tab === 4}
                icon={faChevronRight}
                right="true"
                onClick={() => tab < 4 && handleChangeTab(tab + 1)}
                actions="true"
              />
            </>
          )}
        </Col>
      </Row>
    </>
  ) : (
    <Placeholder />
  );
};

const UserDetailWithTheme = withTheme(UserDetail);

export default props => {
  return (
    <CustomerDetailProvider>
      <UserDetailWithTheme {...props} />
    </CustomerDetailProvider>
  );
};
