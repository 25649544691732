import styled, { css } from 'styled-components';

export const Bar = styled.div`
  background: ${props => props.theme.interface3};
  border-radius: 5px;
  margin-top: 6px;
  width: 100%;
  height: 24px;
  display: flex;
`;

interface IBarValueProps {
  paid?: boolean;
  open?: boolean;
  value: number;
  total: number;
  late?: boolean;
}

export const BarValue = styled.div<IBarValueProps>`
  ${props =>
    props.paid
      ? css`
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;

          ${(props: IBarValueProps) =>
            (props.value * 100) / props.total === 100 &&
            css`
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            `};
        `
      : props.open
      ? css`
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          ${(props: IBarValueProps) =>
            (props.value * 100) / props.total === 100 &&
            css`
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            `};
        `
      : props.late
      ? css`
          ${(props: IBarValueProps) =>
            (props.value * 100) / props.total === 100 &&
            css`
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            `};
        `
      : null};
  height: 100%;
  width: ${props => (props.value * 100) / props.total}%;
  background: ${props =>
    props.paid
      ? props.theme.success
      : props.late
      ? props.theme.error
      : props.open
      ? props.theme.interface3
      : props.theme.interface3};
`;

export const Select = styled.select`
  width: auto;
  padding: 9px 10px;
  background: #fff;
  border: 1px solid #e8eaef;
  border-radius: 5px;

  font-size: 1rem;
  color: #64728c;
`;
