import { Button } from '@cloudez/cloudez-design-system';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

const yellow = '#FCFF7C';

const images = require.context('assets/img', true, /\.png$/);

interface IStepImageProps {
  isMobile: boolean;
  step: number;
}

export const StepImage = styled.div<IStepImageProps>`
  width: 40%;
  min-height: 100%;
  border-radius: 10px 0px 0px 10px;

  background-image: ${props =>
    `url(${
      images(`./step${props.step}${props.isMobile ? 'mobile' : ''}.png`).default
    })`};
  background-size: cover;
  background-repeat: no-repeat;

  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  ${props =>
    props.isMobile &&
    css`
      width: 100%;
      height: 150px;
      border-radius: 10px 10px 0px 0px;
    `}
`;

export const YellowButton = styled(Button)`
  background: ${yellow};
  color: ${props => props.theme.brand_primary_color};

  :hover {
    background: ${darken(0.3, yellow)};
  }
`;

interface IStepContentProps {
  colored: boolean;
  isMobile: boolean;
}

export const StepContent = styled.div<IStepContentProps>`
  min-height: 514px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-weight: bold;

  border-radius: 0px 10px 10px 0px;
  padding: 50px 40px 30px 70px;

  background: ${props =>
    props.colored
      ? css`linear-gradient(310.84deg, #0052EF 37.48%, #36A0FC 100%);`
      : props.theme.interface1};

  ${props =>
    props.isMobile &&
    css`
      padding-left: 40px;
    `}

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;

    ${props =>
      props.isMobile &&
      css`
        flex-direction: column-reverse;
        justify-content: center;

        margin-top: 20px;

        & > button {
          margin: 0 !important;

          margin-bottom: 10px !important;
        }

        ${YellowButton} {
          margin-bottom: 20px;
        }
      `}
  }

  .text {
    width: 100%;
  }

  ${props =>
    props.isMobile &&
    css`
      border-radius: 0px 0px 10px 10px;
    `}
`;

export const Step1 = styled.div`
  font-size: 18px;
  line-height: 40px;

  .title {
    color: ${yellow};
    margin-bottom: 40px;
  }

  .body {
    color: #fff;
  }
`;

export const StepTitle = styled.p`
  font-size: 20px;
  margin-top: 15px;

  ${props =>
    props.theme.darkMode &&
    css`
      color: ${props.theme.interface4} !important;
    `}
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const Postpone = styled.span`
  color: #fff;
  font-size: 12px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  color: ${props => props.theme.brand_primary_color};
`;

interface IProgressBarFillProps {
  progress: number | string;
}

export const ProgressBarFill = styled.div<IProgressBarFillProps>`
  width: 100%;
  border: 1px solid ${props => props.theme.brand_primary_color};
  height: 9px;
  background: ${props => `linear-gradient(90deg,
     ${props.theme.brand_primary_color} 0%,
      ${props.theme.brand_primary_color} ${props.progress}%,
       transparent ${props.progress}%)`};

  border-radius: 10px;
`;

export const Back = styled.span`
  color: ${props => props.theme.brand_primary_color};
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const Required = styled.span`
  color: ${props => props.theme.brand_primary_color};

  font-size: 12px;
`;

export const Step14 = styled.div`
  font-size: 18px;
  line-height: 40px;

  img {
    text-align: center;
  }

  .title {
    color: ${yellow};
    margin-bottom: 25px;
    margin-top: 10px;
  }

  .body {
    color: #fff;
    /* width: 550px; */
    margin-bottom: 20px;
  }
`;
