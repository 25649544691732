import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6af20c56f46a48e0bddd32190ae33d80@sentry.cloudez.io/5',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: 'cloudez-partners-release',
  });
}
ReactDOM.render(<App />, document.getElementById('root'));
