import { lighten } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  author: boolean;
  staff?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  background: ${props => props.theme.interface2};
  margin-bottom: 30px;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 0px 5px 5px 5px;

  max-width: 90%;
  min-width: 30%;
  align-self: flex-start;

  pre {
    color: ${props => props.theme.interface5};
    font-size: 13px;

    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

    a {
      color: ${props => props.theme.label};
      text-decoration: none;
      font-style: italic;
    }
  }

  span.hour {
    position: absolute;
    bottom: -16px;
    right: 0;
    font-size: 11px;
    color: #a2a9bf;
  }

  span.author {
    position: absolute;
    top: -16px;
    left: 0;
    font-size: 11px;
    color: #a2a9bf;
  }

  span.file {
    position: absolute;
    top: -16px;
    right: 0;
    font-size: 11px;
    color: #a2a9bf;

    cursor: pointer;
  }

  ${props =>
    props.staff &&
    css`
      background: ${props => props.theme.primary};

      pre {
        color: ${props => props.theme.interface2};

        a {
          color: ${props => props.theme.interface1};
        }
      }
    `};

  ${props =>
    props.author &&
    css`
      align-self: flex-end;
      border-radius: 5px 5px 0px 5px;
      background: ${props => lighten(0.3, props.theme.success)};

      pre {
        color: ${props => props.theme.interface5};
      }

      span.hour {
        left: 0;
      }

      span.author {
        display: none;
      }
    `};
`;

export const File = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  p {
    display: inline-block;
    font-size: 13px;
    width: 200px;
    margin-top: 8px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${props => props.theme.interface6};
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
      border-radius: 5px;
      height: 100%;
      width: auto;
    }
  }

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 30px;
  }
`;
