import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import SurveyRenderer from 'components/SurveyRenderer';
import { answerSurveyService } from 'services/survey';
import { useAuth } from 'hooks';

interface ChurnModalProperties {
  show: boolean;
  setShow: (value: boolean) => void;
  survey: any;
}

const ChurnModal: React.FC<ChurnModalProperties> = ({
  show,
  setShow,
  survey,
}) => {
  const [loading, setLoading] = useState(false);
  const { signOut } = useAuth();
  const [payload, setPayload] = useState({});

  const answerSurvey = async e => {
    setLoading(true);
    e.preventDefault();
    const data = {
      slug: survey.slug,
      answers: [],
    };

    for (const item in payload) {
      data.answers = [
        ...data.answers,
        { question_id: item, answer: payload[item] },
      ];
    }

    try {
      await answerSurveyService(data);
      setLoading(false);
      signOut();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faLock} />
        </Icon>
        <Text>
          <Title>Excluir revenda</Title>
          <Subtitle>Tem certeza que deseja deletar sua revenda?</Subtitle>
        </Text>
      </Header>

      <Content>
        <Row>
          <Col xs="12">
            <form id="survey" onSubmit={answerSurvey}>
              <SurveyRenderer
                survey={survey}
                payload={payload}
                setPayload={setPayload}
              />
            </form>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Button type="submit" form="survey" icon error>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ChurnModal;
