import styled from 'styled-components';

export const Card = styled.div`
  height: auto;
  padding: 15px 30px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const Separator = styled.div`
  background: ${props => props.theme.interface3};
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;
