import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Axios from 'axios';

import moment from 'moment';

import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';

import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Error, Form, FormTextArea, FormInput, Radio } from 'components/Input';
import SquareCheckbox from 'components/SquareCheckbox';
import { Button } from '@cloudez/cloudez-design-system';
import Modal from 'components/Modal';

import {
  Header,
  Icon,
  Subtitle,
  Footer,
  Text,
  Title,
} from 'components/ListingModal/styles';

import { Content } from './styles';

import { IsCyberWeek } from './CyberWeek';

interface ICommercialModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const CommercialModal: React.FC<ICommercialModalProps> = ({
  show,
  setShow,
}) => {
  const theme = useLayout();
  const { user, company } = useAuth();
  const [loading, setLoading] = useState(false);

  const [isInProgress, setIsInProgress] = useState(false);

  const [error, setError] = useState('');

  const [type, setType] = useState('');

  const today = moment();
  const startDateEventAt = moment('2021-12-04');
  const isDateEvent =
    startDateEventAt && startDateEventAt.isAfter(today, 'days');

  const endDateEventAt = moment('2021-11-28');
  const isEndDateEvent =
    endDateEventAt && endDateEventAt.isBefore(today, 'days');

  const [selected, setSelected] = useState([] as any);
  const issuesSelected = selected.map(item => item.key);

  const schema = Yup.object().shape({
    description: Yup.mixed().test(
      'valid-description',
      'Campo obrigatório!',
      val => (!issuesSelected.includes('Outros') && !val) || !!val,
    ),
    describeSituation: Yup.mixed().test(
      'valid-description',
      'Campo obrigatório!',
      val => !!val || type !== 'cliente',
    ),

    service: Yup.mixed().test(
      'valid-description',
      'Campo obrigatório!',
      val => !!val || type !== 'cliente',
    ),
  });

  const checkboxes = [
    {
      key: 'Contratar um novo Cloud específico',
    },
    {
      key: 'Desenvolver um projeto específico de CMS',
    },
    {
      key: 'Resolver sobrecarga em Cloud',
    },
    {
      key: 'Precificação',
    },
    {
      key: 'Planejar migrações',
    },
    {
      key: 'Planejar arquitetura dos meus Clouds e sites',
    },
    {
      key: 'Outros',
    },
  ];

  const handleChange = key => {
    const find = selected.findIndex(item => item.key === key);

    if (find > -1) {
      selected.splice(find, 1);
    } else {
      selected.push(checkboxes.find(item => item.key === key));
    }

    setSelected([...selected]);
  };

  const handleSubmit = async data => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      if (issuesSelected.length === 0 && type !== 'cliente') {
        setError('Selecione uma opção!');
      } else {
        let payload: any = {
          name: user.full_name,
          company: company.name,
          phone: user.phone,
          email: user.email,
        };

        if (type === 'estratégico') {
          payload = {
            ...payload,
            options: issuesSelected.includes('Outros')
              ? [
                  ...issuesSelected.filter(item => item !== 'Outros'),
                  data.description,
                ]
              : issuesSelected,
            type: 'strategy',
          };
        } else {
          payload = {
            ...payload,
            service: data.service,
            ongoing: isInProgress,
            situation: data.describeSituation,
            type: 'client',
          };
        }

        try {
          setLoading(true);

          await Axios.post(
            `${process.env.REACT_APP_HOOK_URL}/commercial_support`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_HOOK_TOKEN}`,
              },
            },
          );

          setType('success');
        } catch (e) {
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const subtitle = {
    estratégico: 'Ajuda para demandas estratégicas',
    cliente: 'Ajuda para fechar um novo cliente',
    success: 'Fale com algum de nosso especialistas',
    '': 'Fale com algum de nosso especialistas',
  }[type];

  useEffect(() => {
    if (issuesSelected.length > 0) {
      setError('');
    }
  }, [issuesSelected]);

  return (
    <Modal show={show} setShow={setShow} width="562px">
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faHandsHelping} />
        </Icon>
        <Text>
          <Title>Apoio Comercial</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Text>
      </Header>
      <Form schema={schema} onSubmit={handleSubmit}>
        {(() => {
          switch (type) {
            case 'estratégico':
              return (
                <>
                  <Content>
                    <b className="titles">
                      Por favor, detalhe a sua demanda abaixo para um melhor
                      atendimento do nosso time de crescimento
                    </b>
                    <div
                      style={{
                        marginTop: '16px',
                        gap: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        fontWeight: '300',
                      }}
                    >
                      {checkboxes?.map(item => (
                        <SquareCheckbox
                          label={item.key}
                          name={item.key}
                          value={item.key}
                          checked={issuesSelected.includes(item.key)}
                          onChange={() => handleChange(item.key)}
                        />
                      ))}
                      {issuesSelected.includes('Outros') && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                          }}
                        >
                          <span
                            className="titlesClient"
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Descreva abaixo a sua demanda:
                          </span>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '5px',
                              fontSize: '12px',
                              color: theme.errorNew,
                              fontWeight: 'normal',
                            }}
                          >
                            <FormTextArea
                              block
                              name="description"
                              placeholder="Descreva sua demanda"
                              height="75px"
                              style={{ resize: 'none' }}
                              width="100%"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {error && (
                      <Error style={{ marginTop: '5px' }}>{error}</Error>
                    )}
                  </Content>
                  <Footer hasLink>
                    <Button
                      icon
                      text
                      disabled={loading}
                      onClick={() => {
                        setType('');
                        setSelected([]);
                      }}
                    >
                      Voltar
                    </Button>
                    <Button icon disabled={loading}>
                      {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'Enviar'
                      )}
                    </Button>
                  </Footer>
                </>
              );
            case 'cliente':
              return (
                <>
                  <Content>
                    <b className="titles">
                      Por favor, detalhe a sua demanda abaixo para um melhor
                      atendimento do nosso time de crescimento
                    </b>
                    <div
                      style={{
                        marginTop: '16px',
                        gap: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        fontWeight: '300',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                        }}
                      >
                        <span className="titlesClient">
                          Qual serviço você está negociando?
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                            fontSize: '12px',
                            color: theme.errorNew,
                            fontWeight: 'normal',
                          }}
                        >
                          <FormInput
                            name="service"
                            placeholder="Escreva o serviço"
                            style={{ resize: 'none' }}
                            width="100%"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                        }}
                      >
                        <span className="titlesClient">
                          Essa negociação ja está em andamento?
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '23px',
                          }}
                        >
                          <div className="options">
                            <Radio
                              onChange={() => setIsInProgress(true)}
                              checked={isInProgress}
                            />
                            <span>Sim</span>
                          </div>
                          <div className="options">
                            <Radio
                              onChange={() => setIsInProgress(false)}
                              checked={isInProgress === false}
                            />
                            <span>Não</span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                        }}
                      >
                        <span className="titlesClient">
                          Descreva brevemente a situação na qual precisa de
                          ajuda:
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                            fontSize: '12px',
                            color: theme.errorNew,
                            fontWeight: 'normal',
                          }}
                        >
                          <FormTextArea
                            block
                            name="describeSituation"
                            placeholder="Descreva a situação"
                            height="111px"
                            style={{ resize: 'none' }}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                  </Content>
                  <Footer hasLink>
                    <Button
                      icon
                      text
                      disabled={loading}
                      onClick={() => {
                        setType('');
                        setSelected([]);
                      }}
                    >
                      Voltar
                    </Button>
                    <Button icon disabled={loading}>
                      {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'Enviar'
                      )}
                    </Button>
                  </Footer>
                </>
              );
            case 'success':
              return (
                <>
                  <Content>
                    <span className="successText">
                      <b>Tudo pronto!</b> Em até 24 horas um de nossos
                      consultores entrará em contato. Qualquer dúvida sobre a
                      sua solicitação envie um e-mail para{' '}
                      <span>acceleration@cloudez.io</span>.
                    </span>
                  </Content>
                  <Footer>
                    <Button icon onClick={() => setShow(false)}>
                      Fechar
                    </Button>
                  </Footer>
                </>
              );
            default:
              return (
                <Content>
                  {isDateEvent && isEndDateEvent ? (
                    <IsCyberWeek />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                      }}
                    >
                      <b className="titles">
                        Gostaria de ter um atendimento personalizado com os
                        especialistas da Cloudez?
                      </b>
                      <span className="text">
                        Este apoio é exclusivo para parceiros com demandas reais
                        em andamento, qualquer questão técnica ou dúvida de
                        suporte deve ser acionada pelo devido canal.
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <b className="titles">Detalhar demanda</b>
                        <span style={{ fontSize: '14px' }} className="text">
                          Tenho algo mais estratégico em mente
                        </span>
                      </div>
                      <Button
                        onClick={() => setType('estratégico')}
                        style={{
                          textTransform: 'none',
                        }}
                      >
                        Estratégico
                      </Button>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <b className="titles">Atendimento ao Cliente</b>
                        <span style={{ fontSize: '14px' }} className="text">
                          Preciso de ajuda para negociar com um cliente
                        </span>
                      </div>
                      <Button
                        onClick={() => setType('cliente')}
                        style={{
                          textTransform: 'none',
                        }}
                      >
                        Cliente
                      </Button>
                    </div>
                  )}
                </Content>
              );
          }
        })()}
      </Form>
    </Modal>
  );
};

export default CommercialModal;
