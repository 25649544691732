import styled from 'styled-components';

interface ITextBoxProps {
  sideMenuCollapsed: boolean;
}

export const TextBox = styled.div<ITextBoxProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 22px;
  margin-bottom: 150px;

  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #64728c;

  .firstBox,
  .midBox,
  .lastBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .stampsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 38px;

    .stamps {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      a {
        font-weight: bold;
        font-size: 14px;
        color: #2253f5;
        cursor: pointer;
        line-height: 16px;
        text-align: center;
        text-decoration-line: underline;

        &:hover {
          filter: opacity(0.8);
        }
      }
    }

    p {
      color: #2253f5;

      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
    }

    img {
      width: 128px;
      height: 128px;
      filter: drop-shadow(0px 2.71028px 20.3271px rgba(100, 114, 140, 0.25));
    }
  }

  .lastBox {
    @media (max-width: ${({ sideMenuCollapsed }) =>
        !sideMenuCollapsed ? '1371px' : '1261px'}) {
      grid-column: 2;
    }

    @media (max-width: ${({ sideMenuCollapsed }) =>
        !sideMenuCollapsed ? '1000px' : '890px'}) {
      grid-column: 1;
      grid-row: 3;
    }
  }
`;
