import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { Row, Col } from 'react-bootstrap';
import { Input, Label, Field, Error, Checkbox } from 'components/Input';
import api from 'services/api';
import { SearchWrapper } from 'components/SearchBar/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth, useLayout } from 'hooks';
import validateCPF from 'utils/validateCPF';
import { FormSelect } from '@cloudez/cloudez-design-system';
import { FormWrapper } from './styles';

const initialCompanyData = {
  fantasia: '',
  logradouro: '',
  complemento: '',
  numero: '',
  municipio: '',
  bairro: '',
  nome: '',
  cep: '',
  uf: '',
};

const Form = ({ setCompanyBilling, companyBilling, setDisabled, errors }) => {
  const theme = useLayout();

  const { company } = useAuth();

  const [loading, setLoading] = useState(false);
  const [cnpj, setCNPJ] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [companyData, setCompanyData] = useState(initialCompanyData);

  const [document, setDocument] = useState('CNPJ');

  const [notHasIe, setNotHasIe] = useState(false);

  const resetBilling = useCallback(() => {}, []);

  useEffect(() => {
    async function getBilling() {
      try {
        const { data } = await api.get(`/v3/company/billing/${company.code}`);

        setCompanyBilling(state => ({
          ...state,
          ...data,
        }));

        // if (!data.document_id) {
        //   setHasBilling(false);
        // }
      } catch (e) {
        console.log(e);
      }
    }

    if (company) {
      getBilling();
    }
  }, []);

  const getCNPJData = async _cnpj => {
    try {
      setLoading(true);
      const { data } = await api.get(`/v3/company-cnpj/${_cnpj}/`);
      if (data.status === 'ERROR') {
        setNoResults(true);
        setLoading(false);
        setCanEdit(true);
        setDisabled(false);
        return;
      }
      setCompanyData(data);
      setLoading(false);
      setCompanyBilling({
        ...companyBilling,
        zipcode: data.cep,
        state: data.uf,
        city: data.municipio,
        country: 'Brasil',
        address_number: data.numero,
        address1: data.logradouro,
        address2: data.bairro,
        fantasy_name: data.fantasia,
        business_name: data.nome,
        document_id: data.cnpj,
      });
      setCanEdit(false);
      setDisabled(false);
      setNoResults(false);
    } catch (e) {
      setNoResults(true);
      setLoading(false);
      setCanEdit(true);
      setDisabled(false);
    }
  };

  const [invalidCPF, setInvalidCPF] = useState(false);

  return (
    <FormWrapper>
      <Row
        style={{
          width: '100%',
          marginLeft: '0',
        }}
      >
        <Col xl="4">
          <Field>
            <Label
              style={{
                marginBottom: '-12px',
              }}
              required
            >
              <span
                style={{
                  display: 'inline',
                  cursor: 'pointer',
                  fontSize: '13px',
                  color:
                    document === 'CNPJ'
                      ? theme.brand_primary_color
                      : theme.interface4,
                }}
                onClick={() => {
                  setCanEdit(false);
                  setDocument('CNPJ');
                }}
              >
                CNPJ
              </span>
              /
              <span
                style={{
                  display: 'inline',
                  cursor: 'pointer',
                  fontSize: '13px',
                  color:
                    document === 'CPF'
                      ? theme.brand_primary_color
                      : theme.interface4,
                }}
                onClick={() => {
                  setCanEdit(true);
                  setDocument('CPF');
                }}
              >
                CPF
              </span>
              *
            </Label>

            <InputMask
              mask={
                document === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'
              }
              value={cnpj}
              maskChar={null}
              autoFocus
              onChange={e => {
                setCNPJ(e.target.value);
                const _document = e.target.value.replace(/\.|-|\//g, '');
                setCompanyBilling({
                  ...companyBilling,
                  document_id: _document,
                });
                if (e.target.value.length >= 18 && document === 'CNPJ') {
                  getCNPJData(_document);
                } else if (e.target.value.length >= 14 && document === 'CPF') {
                  const isValid = validateCPF(_document);
                  setInvalidCPF(!isValid);
                }
              }}
              block="true"
            >
              {inputProps => (
                <SearchWrapper
                  onboard
                  noResults={noResults || invalidCPF}
                  block
                  style={{
                    marginTop: '18px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={loading ? faSpinner : faSearch}
                    spin={loading}
                    onClick={
                      loading
                        ? null
                        : () => {
                            // eslint-disable-next-line
                            let _cnpj = cnpj.replace(/\.|\-|\//g, '');
                            if (document === 'CNPJ') {
                              getCNPJData(_cnpj);
                            } else {
                              //
                            }
                          }
                    }
                  />
                  <Input
                    {...inputProps}
                    error={noResults || invalidCPF}
                    style={{ paddingLeft: '38px' }}
                  />
                </SearchWrapper>
              )}
            </InputMask>
            {(invalidCPF && document === 'CPF') ||
              (errors?.document_id && <Error>CPF inválido</Error>)}
          </Field>
        </Col>
        <Col xl="4">
          <Field>
            <Label>Inscrição estadual</Label>
            <Input
              block
              value={companyBilling && companyBilling.inscricao_estadual}
              background={document === 'CPF'}
              disabled={document === 'CPF' || notHasIe}
              onChange={e => {
                setCompanyBilling({
                  ...companyBilling,
                  inscricao_estadual: e.target.value,
                });
              }}
            />
            <Field
              style={{
                marginTop: '8px',
                marginBottom: '0',
              }}
            >
              <Label
                style={{
                  fontSize: '11px',
                }}
              >
                Não possuo inscrição estadual *
              </Label>
              <Checkbox
                checked={notHasIe}
                value={notHasIe}
                disabled={document === 'CPF'}
                onChange={e => {
                  setNotHasIe(e.target.checked);

                  if (e.target.checked === true) {
                    setCompanyBilling({
                      ...companyBilling,
                      inscricao_estadual: 'ISENTO',
                    });
                  } else {
                    setCompanyBilling({
                      ...companyBilling,
                      inscricao_estadual: '',
                    });
                  }
                }}
              />
            </Field>
          </Field>
        </Col>

        <Col xl="4">
          <Field>
            <Label>CEP</Label>
            <InputMask
              mask="99999-999"
              onChange={e => {
                const _cep = e.target.value.replace(/\.|-|\//g, '');
                setCompanyBilling({
                  ...companyBilling,
                  zipcode: e.target.value,
                });
              }}
              value={companyBilling && companyBilling.zipcode}
              disabled={!canEdit}
              maskChar={null}
              block
            >
              {inputProps => <Input {...inputProps} background={!canEdit} />}
            </InputMask>
          </Field>
        </Col>
      </Row>

      <Row
        style={{
          width: '100%',
          marginLeft: '0',
        }}
      >
        <Col xl="6">
          <Field>
            <Label>Nome {document === 'CPF' ? 'Completo' : 'da empresa'}</Label>
            <Input
              block
              disabled={!canEdit}
              background={!canEdit}
              value={companyBilling.fantasy_name}
              onChange={e =>
                setCompanyBilling({
                  ...companyBilling,
                  fantasy_name: e.target.value,
                })
              }
            />
          </Field>
        </Col>
        <Col xl="3">
          <Field>
            <Label>Razão Social</Label>
            <Input
              block
              disabled={!canEdit || document === 'CPF'}
              background={!canEdit || document === 'CPF'}
              value={companyBilling.business_name}
              onChange={e =>
                setCompanyBilling({
                  ...companyBilling,
                  business_name: e.target.value,
                })
              }
            />
          </Field>
        </Col>
        <Col xl="3">
          <Field>
            <Label>Estado</Label>
            <FormSelect
              options={[
                { title: 'Acre', id: 'AC' },
                { title: 'Alagoas', id: 'AL' },
                { title: 'Amapá', id: 'AP' },
                { title: 'Amazonas', id: 'AM' },
                { title: 'Bahia', id: 'BA' },
                { title: 'Ceará', id: 'CE' },
                { title: 'Distrito Federal', id: 'DF' },
                { title: 'Espírito Santo', id: 'ES' },
                { title: 'Goias', id: 'GO' },
                { title: 'Maranhão', id: 'MA' },
                { title: 'Mato Grosso', id: 'MT' },
                { title: 'Mato Grosso do Sul', id: 'MS' },
                { title: 'Minas Gerais', id: 'MG' },
                { title: 'Pará', id: 'PA' },
                { title: 'Paraíba', id: 'PB' },
                { title: 'Paraná', id: 'PR' },
                { title: 'Pernambuco', id: 'PE' },
                { title: 'Piauí', id: 'PI' },
                { title: 'Rio de Janeiro', id: 'RJ' },
                { title: 'Rio Grande do Norte', id: 'RN' },
                { title: 'Rio Grande do Sul', id: 'RS' },
                { title: 'Rondônia', id: 'RO' },
                { title: 'Roraima', id: 'RR' },
                { title: 'Santa Catarina', id: 'SC' },
                { title: 'São Paulo', id: 'SP' },
                { title: 'Sergipe', id: 'SE' },
                { title: 'Tocantins', id: 'TO' },
              ]}
              block
              disabled={!canEdit}
              value={
                canEdit ? companyBilling.state : companyData && companyData.uf
              }
              onChange={e =>
                setCompanyBilling({
                  ...companyBilling,
                  state: e.target.value,
                })
              }
            />
          </Field>
        </Col>
      </Row>

      <Row
        style={{
          width: '100%',
          marginLeft: '0',
        }}
      >
        <Col xl="6">
          <Field>
            <Label>Logradouro</Label>
            <Input
              block
              disabled={!canEdit}
              background={!canEdit}
              value={companyBilling.address1}
              onChange={e =>
                setCompanyBilling({
                  ...companyBilling,
                  address1: e.target.value,
                })
              }
            />
          </Field>
        </Col>
        <Col xl="1">
          <Field>
            <Label>Numero</Label>
            <Input
              block
              disabled={!canEdit}
              background={!canEdit}
              value={companyBilling.address_number}
              onChange={e =>
                setCompanyBilling({
                  ...companyBilling,
                  address_number: e.target.value,
                })
              }
            />
          </Field>
        </Col>

        <Col xl="3">
          <Field>
            <Label>Bairro</Label>
            <Input
              block
              disabled={!canEdit}
              background={!canEdit}
              value={companyBilling.bairro}
              onChange={e =>
                setCompanyBilling({
                  ...companyBilling,
                  bairro: e.target.value,
                })
              }
            />
          </Field>
        </Col>
        <Col xl="2">
          <Field>
            <Label>Municipio</Label>
            <Input
              block
              disabled={!canEdit}
              background={!canEdit}
              value={companyBilling.city}
              onChange={e =>
                setCompanyBilling({
                  ...companyBilling,
                  city: e.target.value,
                })
              }
            />
          </Field>
        </Col>
      </Row>
    </FormWrapper>
  );
};

export default Form;
