import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 25px;
  width: 100%;
  height: auto;
`;

interface ITypeProps {
  selected?: boolean;
  type?: any;
}

export const Type = styled.div<ITypeProps>`
  cursor: pointer;
  width: 100%;
  height: 110px;

  display: flex;
  flex-direction: column;

  align-items: center;

  border-radius: 5px;

  margin-bottom: 30px;
  padding: 10px;

  background: ${props => props.theme.interface2};
  border: 2px solid transparent;

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color};
    `}

  &:hover {
    transition: border 0.2s ease-in;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  span {
    margin-top: 8px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  p {
    margin-top: 4px;
    font-size: 10px;
    color: ${props => props.theme.interface5};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SeeMoreButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.theme.brand_secondary_color};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 16px;
    margin-right: -90px;
    transition: all 0.05s ease-out;
  }

  &:hover {
    width: auto;
    padding: 0px 8px;
    transition: all 0.3s ease-out;

    svg {
      margin-right: 0;
      transition: all 0.3s ease-out;
      transform: rotateZ(-180deg);
    }

    span {
      transition: opacity 0.3s ease-out;
      transition-delay: 0.1s;
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    margin-left: 8px;
    font-size: 12px;
    color: ${props => props.theme.brand_primary_color};
  }
`;
