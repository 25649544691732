import openTicket from '../icons/open.svg';
import closedTicket from '../icons/closed.svg';
import closedTicketSelected from '../icons/closedSelected.svg';
import openTicketSelected from '../icons/openSelected.svg';

import { Container } from './styles';

interface CustomFilterProps {
  type: 'closed' | 'open';
  spacing?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

export function CustomFilter({
  type,
  spacing,
  selected,
  onClick,
}: CustomFilterProps): JSX.Element {
  const typeFilter = {
    closed: {
      svg: selected ? closedTicketSelected : closedTicket,
      alt: 'Tickets fechados',
      text: 'Fechado',
    },
    open: {
      svg: selected ? openTicketSelected : openTicket,
      alt: 'Tickets abertos',
      text: 'Aberto',
    },
  }[type];
  return (
    <Container onClick={onClick} selected={selected} spacing={spacing}>
      <img src={typeFilter.svg} alt={typeFilter.alt} />
      <p>{typeFilter.text}</p>
    </Container>
  );
}
