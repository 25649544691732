import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  min-width: 70%;

  @media (max-width: 900px) {
    width: 100%;
  }

  background: ${props => props.theme.interface1};

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  gap: 16px;

  padding: 32px;

  .title {
    font-weight: bold;
    font-size: 16px;

    line-height: 25px;

    color: ${props => props.theme.brand_primary_color};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;

    color: ${props => props.theme.interface5};
  }

  .advancedConfigs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: fit-content;

    cursor: pointer;

    gap: 10px;

    span {
      text-decoration: underline;
      color: ${props => props.theme.brand_primary_color};

      font-weight: bold;

      font-size: 14px;
      line-height: 24px;
    }

    svg {
      color: ${props => props.theme.brand_primary_color};
      cursor: pointer;
    }
  }
`;

interface CardProps {
  selected?: boolean;
}

export const Card = styled.div<CardProps>`
  display: grid;
  grid-template-rows: 50px 26px;

  cursor: pointer;

  transition: 0.2s;

  &:hover {
    transform: scale(1.02);
  }

  justify-items: center;
  align-items: center;
  text-align: center;

  width: 190px;

  min-height: 110px;

  padding: 16px;

  border: ${props =>
    props.selected
      ? `2px solid ${props.theme.brand_primary_color}`
      : `1px solid ${props.theme.interface3}`};

  border-radius: 5px;

  box-shadow: ${props =>
    props.selected ? '0px 2px 15px rgba(100, 114, 140, 0.25)' : null};

  color: ${props => props.theme.interface5};

  b {
    font-size: 12px;
    line-height: 100%;
  }
  span {
    font-size: 10px;
    font-weight: normal;
  }

  .externalLinkAlt {
    color: #ccedff;

    width: 35px;
    height: 35px;
    path {
      stroke: #353642;
      stroke-width: 20px;
    }
  }
`;

export const CardBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
