import React from 'react';
import { Icon, IconHover, IconAvatar } from './styles';

const HoverableIcon = ({ icon, hover, avatar, iconColor }) => {
  return (
    <>
      {icon && <Icon icon={icon} color={iconColor} />}
      {hover && <IconHover icon={hover} />}
      {avatar && <IconAvatar src={avatar} />}
    </>
  );
};

export default HoverableIcon;
