import React from 'react';
import { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loading = ({ theme }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: `${100}%`,
      }}
    >
      <FontAwesomeIcon
        color={theme.brand_primary_color}
        icon={faSpinner}
        spin
        size="3x"
      />
    </div>
  );
};

export default withTheme(Loading);
