import { Header, Title } from 'components/Common';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@cloudez/cloudez-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { animated, useSpring } from 'react-spring';
import SurveyRenderer from 'components/SurveyRenderer';
import {
  answerQuestionService,
  updateAnswerService,
  finishSurveyService,
} from 'services/survey';
import { toast } from 'react-toastify';
import { useLayout } from 'hooks';
import { useLastLocation } from 'react-router-last-location';
import { useHistory } from 'react-router-dom';
import useDevice from 'hooks/device';
import confetti from 'assets/img/confetti.png';
import * as Styles from './styles';

export default function Survey() {
  const { partnerSurvey: survey, setAnswerLater, getSurvey } = useLayout();

  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState({});
  const [other, setOther] = useState({});
  const [answered, setAnswered] = useState({} as any);

  const [answerLoading, setAnswerLoading] = useState(false);

  const lastLocation = useLastLocation();
  const history = useHistory();

  const { isMobile } = useDevice();

  const currentItem = survey?.items[step - 2];

  useEffect(() => {
    if (survey) {
      const answered = survey.items.filter(item => item.answered);

      answered.forEach(item => {
        setAnswered(state => ({
          ...state,
          [item.question?.id]: item.answer?.id,
        }));

        if (
          item.question.choices &&
          !item.question.choices.includes(item.answer.answer) &&
          item.answer.answer !== '-' &&
          item.question.widget !== 'textarea'
        ) {
          setOther(other => ({
            ...other,
            [item.question.id]: item.answer.answer,
          }));
          answers[item.question.id] = 'Outro';

          return;
        }
        answers[item.question.id] = item.answer.answer;
      });
    }
  }, [survey]);

  const next = () => {
    if (answerLoading) return;

    if (step === 1 && Object.keys(answered).length > 0) {
      setStep(Object.keys(answered).length + 2);
    } else {
      setStep(step => step + 1);
    }
  };

  const previous = () => {
    if (answerLoading) return;
    setStep(step => step - 1);
  };

  const progress = useMemo(() => {
    switch (step) {
      case 2:
        return 0;
      case 3:
        return 7;
      case 4:
        return 15;
      case 5:
        return 30;
      case 6:
        return 38;
      case 7:
        return 46;
      case 8:
        return 53;
      case 9:
        return 61;
      case 10:
        return 69;
      case 11:
        return 76;
      case 12:
        return 84;
      case 13:
        return 88;
      case 14:
        return 92;
      case 15:
        return 96;
      case 16:
        return 98;
      default:
        return 98;
    }
  }, [step]);

  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const answerLater = () => {
    setAnswerLater(true);

    if (lastLocation) {
      history.push(lastLocation.pathname);
    } else {
      history.push('/dashboard');
    }
  };

  const finishSurvey = async () => {
    await finishSurveyService('partner-survey');

    await getSurvey();

    history.push('/dashboard');
  };

  const answerQuestion = async skip => {
    setAnswerLoading(true);
    try {
      const answer = {
        answer:
          answers[currentItem.question.id] === 'Outro'
            ? other[currentItem.question.id]
            : answers[currentItem.question.id],
        question: currentItem.question.id,
      };

      if (skip) {
        answer.answer = '-';
        setAnswers(state => ({ ...state, [currentItem.question.id]: '-' }));
      }

      if (answered[currentItem.question.id]) {
        await updateAnswerService(
          answered[currentItem.question.id],
          answer.answer,
        );
      } else {
        const { data } = await answerQuestionService(answer);
        setAnswered(state => ({
          ...state,
          [currentItem.question.id]: data.id,
        }));
      }

      next();
      setAnswerLoading(false);
    } catch (e) {
      setAnswerLoading(false);
      console.log(e);
      toast.error('Erro ao responder pergunta');
    }
  };

  const direction = {
    flexDirection: isMobile ? 'column' : '',
  };

  return (
    <animated.div style={fade}>
      <Header>
        <Title>Complete seu cadastro de parceiro!</Title>
      </Header>

      <Row noGutters style={direction as React.CSSProperties}>
        <Styles.StepImage isMobile={isMobile} step={step} />
        <Col>
          <Styles.StepContent
            isMobile={isMobile}
            colored={step === 1 || step === 17}
          >
            <div className="text">
              {currentItem && (
                <Styles.ProgressBarWrapper>
                  {progress}%
                  <Styles.ProgressBarFill
                    className="ml-2"
                    progress={progress}
                  />
                </Styles.ProgressBarWrapper>
              )}

              {currentItem && (
                <Styles.StepTitle>
                  {survey?.items[step - 2].question.text}
                </Styles.StepTitle>
              )}

              {survey && currentItem && (
                <div className="mt-5">
                  <SurveyRenderer
                    question={currentItem}
                    twoColumn={(step === 2 || step === 8) && !isMobile}
                    payload={answers}
                    setPayload={setAnswers}
                    other={(step === 2 || step === 8) && other}
                    setOther={setOther}
                  />
                </div>
              )}

              {step === 1 && (
                <Styles.Step1>
                  <p className="title">Olá parceiro!</p>
                  <p className="body">
                    Contamos com seu apoio para uma rápida pesquisa sobre o seu
                    negócio. O objetivo é nos ajudar a entender cada vez mais
                    nossos parceiros e implementar mudanças que melhorem nossa
                    plataforma e atendam melhor a vocês e aos seus clientes.
                    Posso contar com você?
                  </p>
                </Styles.Step1>
              )}

              {step === 17 && (
                <Styles.Step14>
                  <div style={{ textAlign: 'center' }}>
                    <img src={confetti} alt="Confetti" width="140" />
                  </div>
                  <p className="title">Finalizamos!</p>
                  <p className="body">
                    A Cloudez agradece sua participação, com essas respostas
                    poderemos trazer cada vez mais melhorias que vão alavancar o
                    seu negócio. Até a próxima, parceiro.
                  </p>
                </Styles.Step14>
              )}
            </div>

            <div className="buttons">
              {step > 2 && currentItem && (
                <Styles.Back onClick={previous} className={isMobile && 'mt-4'}>
                  <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                  Voltar
                </Styles.Back>
              )}
              <Styles.Spacer />
              {step === 1 ? (
                <Styles.Postpone onClick={answerLater}>
                  RESPONDER DEPOIS
                </Styles.Postpone>
              ) : (
                ''
              )}
              {currentItem && survey?.items[step - 2]?.is_required ? (
                <Styles.Required>RESPOSTA OBRIGATÓRIA</Styles.Required>
              ) : (
                <>
                  {step > 1 && step < 17 && (
                    <Button
                      disabled={answerLoading}
                      outline
                      width="150px"
                      onClick={() => answerQuestion(true)}
                    >
                      Pular
                    </Button>
                  )}
                </>
              )}
              {step === 1 ? (
                <Styles.YellowButton
                  width="150px"
                  className="ml-4"
                  onClick={next}
                >
                  CONTINUAR
                </Styles.YellowButton>
              ) : currentItem ? (
                <Button
                  disabled={
                    answerLoading ||
                    !(step > 1 && answers[currentItem.question.id])
                  }
                  className="ml-4"
                  width="150px"
                  onClick={() => answerQuestion(false)}
                >
                  {answerLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Continuar'
                  )}
                </Button>
              ) : (
                <Styles.YellowButton onClick={() => finishSurvey()}>
                  IR PARA O PAINEL
                </Styles.YellowButton>
              )}
            </div>
          </Styles.StepContent>
        </Col>
      </Row>
    </animated.div>
  );
}
