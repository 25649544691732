import { useEffect, useState } from 'react';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Content,
  Header,
  Icon,
  Subtitle,
  Text,
  Title,
} from 'components/ListingModal/styles';
import Modal from 'components/Modal';
import Form from 'pages/Company/Form';
import api from 'services/api';

interface BillingModalProperties {
  company: { code: string };
}

function BillingModal({ company }: BillingModalProperties): JSX.Element {
  const [hasBilling, setHasBilling] = useState(true);
  const [show, setShow] = useState(false);
  const [noExit, setNoExit] = useState(true);

  useEffect(() => {
    async function getBilling() {
      try {
        const { data } = await api.get(`/v3/company/billing/${company.code}`);

        if (!data.document_id) {
          setHasBilling(false);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (company) {
      getBilling();
    }
  }, []);

  useEffect(() => {
    if (!hasBilling) setShow(true);
  }, [hasBilling]);

  return (
    <>
      {company && (
        <Modal width="1100px" show={show} setShow={setShow} noExit={noExit}>
          <Header>
            <Icon>
              <FontAwesomeIcon size="lg" icon={faDollarSign} />
            </Icon>
            <Text>
              <Title>Informações financeiras e bancárias</Title>
              <Subtitle>
                Informe suas informações financeiras e bancárias iniciais, você
                poderá modificá-las posteriormente, não se preocupe!
              </Subtitle>
            </Text>
          </Header>
          <Content>
            <Form
              company={company}
              modal
              onUpdateBillingSuccess={() => {
                setNoExit(true);
                setShow(false);
              }}
            />
          </Content>
        </Modal>
      )}
    </>
  );
}

export default BillingModal;
