import React from 'react';
import { Button } from '@cloudez/cloudez-design-system';

import history from 'services/history';
import { Wrapper } from './styles';

const NotFound = () => {
  return (
    <Wrapper>
      <h1>404</h1>
      <h2>Ops! Não encontramos essa página.</h2>
      <Button
        size="lg"
        style={{
          marginTop: '20px',
        }}
        onClick={() => {
          history.goBack();
        }}
      >
        Voltar
      </Button>
    </Wrapper>
  );
};

export default NotFound;
