import React, { useContext, useState } from 'react';
import InputMask from 'react-input-mask';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faSpinner,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Input, Field, Label, FormInput, Error } from 'components/Input';
import { Button, FormSelect } from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { SearchWrapper } from 'components/SearchBar/styles';
import { Tabs, Tab } from 'components/Tabs';
import Axios from 'axios';
import api from 'services/api';
import { ThemeContext } from 'styled-components';
import { toast } from 'react-toastify';

const AddressModal = ({
  show,
  setShow,
  billing,
  user,
  setUser,
  setBilling,
}) => {
  const theme = useContext(ThemeContext);

  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const [_user, _setUser] = useState({
    ...user,
    phone: {
      ...user.phone,
      number: user?.phone?.number?.substring('+55'.length),
    },
  });
  const [_billing, _setBilling] = useState(billing);
  const [cep, setCEP] = useState(billing.zipcode);
  const [document, setDocument] = useState(
    billing.document_id.length > 15 ? 1 : 0,
  );
  const [errors, setErrors] = useState(null);

  const getCEP = async cep => {
    try {
      setLoading(true);
      const { data } = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      _setBilling({
        ..._billing,
        state: data.uf,
        address1: data.logradouro,
        address2: data.bairro,
        city: data.localidade,
        zipcode: cep,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeBilling = e => {
    _setBilling({
      ..._billing,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeUser = e => {
    if (e.target.name === 'phone')
      _setUser({
        ..._user,
        phone: {
          ..._user.phone,
          number: e.target.value,
        },
      });
    else
      _setUser({
        ..._user,
        [e.target.name]: e.target.value,
      });
  };

  const updateUser = async (field, value, _user = null) => {
    _setLoading(true);
    try {
      const { data } = await api.patch(
        `/v3/company/user/${user.id}/`,
        _user || {
          [field]: value,
        },
      );
      setUser(data);

      if (_user.phone.number !== user.phone.number) {
        const { data: _data } = await api.patch(
          `/v3/user-phone/${_user.phone.id}/`,
          {
            number: `+55${_user.phone.number}`,
          },
        );
        setUser({ ..._user, phone: _data });
      }

      _setLoading(false);
      setShow();
      toast.success('Informações atualizadas com sucesso.');
    } catch (e) {
      _setLoading(false);
      setErrors(e.response.data);
    }
  };

  const updateBilling = async _billing => {
    _setLoading(true);
    try {
      const { data } = await api.patch(`/v3/billing/${user.id}/`, _billing);
      setBilling(data);
      _setLoading(false);

      toast.success('Informações atualizadas com sucesso.');
    } catch (e) {
      _setLoading(false);
      setErrors(e.response.data);
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faFolder} />
        </Icon>
        <Text>
          <Title>Dados do cliente</Title>
          <Subtitle>Informações do cliente</Subtitle>
        </Text>
      </Header>
      <Tabs>
        <Tab
          active={tab === 1}
          onClick={() => {
            setTab(1);
          }}
        >
          Pessoais
        </Tab>
        <Tab
          active={tab === 2}
          onClick={() => {
            setTab(2);
          }}
        >
          Financeiras
        </Tab>
      </Tabs>
      {tab === 1 && (
        <Content>
          <Field>
            <Label htmlFor="userEmail">Email</Label>
            <Input
              id="userEmail"
              disabled
              background
              value={_user.email}
              onChange={onChangeUser}
              name="email"
              block
              error={errors?.email}
            />
            {errors?.email && <Error>{errors?.email[0]}</Error>}
          </Field>
          <Field>
            <Label htmlFor="fullName">Nome</Label>
            <Input
              id="fullName"
              value={_user.full_name}
              name="full_name"
              block
              onChange={onChangeUser}
              error={errors?.full_name}
            />
            {errors?.full_name && <Error>{errors?.full_name[0]}</Error>}
          </Field>
          <Field>
            <Label htmlFor="phone">Telefone</Label>
            <InputMask
              mask="(99) 99999-9999"
              maskChar={null}
              value={_user.phone?.number}
              name="phone"
              block
              onChange={onChangeUser}
              error={errors?.number}
            >
              {inputProps => {
                return <FormInput {...inputProps} />;
              }}
            </InputMask>
            {errors?.number && <Error>{errors?.number[0]}</Error>}
          </Field>
        </Content>
      )}
      {tab === 2 && (
        <Content>
          <Row>
            <Col>
              <Field>
                <Label htmlFor="billingName">Nome</Label>
                <Input
                  id="billingName"
                  value={_billing.name}
                  onChange={onChangeBilling}
                  name="name"
                  block
                  error={errors?.name}
                />
                {errors?.name && <Error>{errors?.name[0]}</Error>}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field>
                <Label htmlFor="billingMail">Email financeiro</Label>
                <Input
                  id="billingMail"
                  value={_billing.email}
                  onChange={onChangeBilling}
                  name="email"
                  block
                  error={errors?.email}
                />
                {errors?.email && <Error>{errors?.email[0]}</Error>}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field>
                <Label>
                  <span
                    style={{
                      cursor: 'pointer',
                      color:
                        document === 0
                          ? theme.brand_primary_color
                          : theme.interface4,
                      display: 'inline',
                    }}
                    onClick={() => setDocument(0)}
                  >
                    CPF
                  </span>
                  /
                  <span
                    style={{
                      cursor: 'pointer',
                      color:
                        document === 1
                          ? theme.brand_primary_color
                          : theme.interface4,
                      display: 'inline',
                    }}
                    onClick={() => setDocument(1)}
                  >
                    CNPJ
                  </span>
                </Label>
                <InputMask
                  data-testid="document_id"
                  mask={
                    document === 0 ? '999.999.999-999' : '99.999.999/9999-99'
                  }
                  maskChar={null}
                  value={_billing.document_id}
                  block="true"
                  onChange={e => {
                    if (e.target.value.length > 15) {
                      setDocument(1);
                    } else {
                      setDocument(0);
                    }

                    if (e.target.value.length === 0) {
                      setDocument(0);
                    }

                    onChangeBilling(e);
                  }}
                  name="document_id"
                  error={errors?.document_id}
                >
                  {inputProps => <FormInput {...inputProps} />}
                </InputMask>
                {errors?.document_id && <Error>{errors?.document_id[0]}</Error>}
              </Field>
            </Col>
          </Row>

          <Row>
            <Col>
              <Field>
                <Label
                  htmlFor="cep"
                  style={{
                    marginBottom: '-12px',
                  }}
                  required
                >
                  CEP *
                </Label>

                <InputMask
                  id="cep"
                  mask="99999-999"
                  maskChar={null}
                  alwaysShowMask="true"
                  loading={loading}
                  value={cep}
                  onChange={e => {
                    setCEP(e.target.value);
                    if (e.target.value.length >= 9) {
                      const _cep = e.target.value.replace(
                        // eslint-disable-next-line
                        /\.|\-|\//g,
                        '',
                      );
                      getCEP(_cep);
                    }
                  }}
                  block="true"
                  error={errors?.zipcode}
                >
                  {inputProps => {
                    return (
                      <SearchWrapper
                        onboard
                        block
                        style={{
                          marginTop: '18px',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={loading ? faSpinner : faSearch}
                          spin={loading}
                          onClick={
                            loading
                              ? null
                              : e => {
                                  const _cep = cep.replace(
                                    // eslint-disable-next-line
                                    /\.|\-|\//g,
                                    '',
                                  );
                                  getCEP(_cep);
                                }
                          }
                        />
                        <Input
                          {...inputProps}
                          style={{ paddingLeft: '38px' }}
                        />
                      </SearchWrapper>
                    );
                  }}
                </InputMask>

                {errors?.zipcode && <Error>{errors?.zipcode[0]}</Error>}
              </Field>
            </Col>
            <Col>
              <Field>
                <Label htmlFor="state">Estado</Label>
                <FormSelect
                  options={[
                    { title: 'Acre', id: 'AC' },
                    { title: 'Alagoas', id: 'AL' },
                    { title: 'Amapá', id: 'AP' },
                    { title: 'Amazonas', id: 'AM' },
                    { title: 'Bahia', id: 'BA' },
                    { title: 'Ceará', id: 'CE' },
                    { title: 'Distrito Federal', id: 'DF' },
                    { title: 'Espírito Santo', id: 'ES' },
                    { title: 'Goias', id: 'GO' },
                    { title: 'Maranhão', id: 'MA' },
                    { title: 'Mato Grosso', id: 'MT' },
                    { title: 'Mato Grosso do Sul', id: 'MS' },
                    { title: 'Minas Gerais', id: 'MG' },
                    { title: 'Pará', id: 'PA' },
                    { title: 'Paraíba', id: 'PB' },
                    { title: 'Paraná', id: 'PR' },
                    { title: 'Pernambuco', id: 'PE' },
                    { title: 'Piauí', id: 'PI' },
                    { title: 'Rio de Janeiro', id: 'RJ' },
                    { title: 'Rio Grande do Norte', id: 'RN' },
                    { title: 'Rio Grande do Sul', id: 'RS' },
                    { title: 'Rondônia', id: 'RO' },
                    { title: 'Roraima', id: 'RR' },
                    { title: 'Santa Catarina', id: 'SC' },
                    { title: 'São Paulo', id: 'SP' },
                    { title: 'Sergipe', id: 'SE' },
                    { title: 'Tocantins', id: 'TO' },
                  ]}
                  id="state"
                  value={_billing.state}
                  block
                  onChange={onChangeBilling}
                  name="state"
                  error={errors?.state}
                />
                {errors?.state && <Error>{errors?.state[0]}</Error>}
              </Field>
            </Col>
          </Row>
          <Field>
            <Label htmlFor="address1">Endereço 1</Label>
            <Input
              id="address1"
              value={_billing.address1}
              block
              onChange={onChangeBilling}
              name="address1"
              background
              error={errors?.address1}
            />
            {errors?.address1 && <Error>{errors?.address1[0]}</Error>}
          </Field>
          <Field>
            <Label htmlFor="address2">Bairro</Label>
            <Input
              id="address2"
              value={_billing.address2}
              block
              onChange={onChangeBilling}
              name="address2"
              background
              error={errors?.address2}
            />
            {errors?.address2 && <Error>{errors?.address2[0]}</Error>}
          </Field>

          <Row>
            <Col>
              <Field>
                <Label htmlFor="address_number">Número</Label>
                <Input
                  id="address_number"
                  value={_billing.address_number}
                  block
                  onChange={onChangeBilling}
                  name="address_number"
                  error={errors?.address_number}
                />
                {errors?.address_number && (
                  <Error>{errors?.address_number[0]}</Error>
                )}
              </Field>
            </Col>
            <Col>
              <Field>
                <Label htmlFor="city">Cidade</Label>
                <Input
                  id="city"
                  value={_billing.city}
                  block
                  onChange={onChangeBilling}
                  name="city"
                  background
                  error={errors?.city}
                />
                {errors?.city && <Error>{errors?.city[0]}</Error>}
              </Field>
            </Col>
          </Row>
        </Content>
      )}
      <Footer>
        <Button
          onClick={async () => {
            if (tab === 1) await updateUser(null, null, _user);
            if (tab === 2) await updateBilling(_billing);
          }}
        >
          {_loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Atualizar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default AddressModal;
