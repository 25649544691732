import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 40px 0px;
`;

export const Title = styled.h1`
  font-size: 28px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.interface1};

  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Origin = styled.div`
  width: 40%;
  padding: 30px 10px 30px 40px;
  height: 100%;
  position: relative;

  h3 {
    margin-bottom: 20px;
    color: ${props => props.theme.brand_primary_color};
    font-weight: 700;
    font-size: 17px;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${props => props.theme.interface4};
  }
`;

export const Image = styled.div`
  margin: 40px auto;
  width: 280px;
  background: ${props => props.theme.brand_primary_color};

  img {
    width: 281px;
    margin-bottom: -3px;
  }
`;

interface IDestProps {
  disabled: boolean;
}

export const Dest = styled.div<IDestProps>`
  width: 60%;
  height: 100%;
  padding: 30px 40px 30px 40px;
  border-left: 1px solid ${props => props.theme.interface4};

  h3 {
    margin-bottom: 20px;
    color: ${props => props.theme.brand_primary_color};
    font-weight: 700;
    font-size: 17px;
    text-transform: uppercase;
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CloudWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.interface4};
  margin-top: 10px;
  margin-bottom: 20px;

  svg {
    color: ${props => props.theme.interface4};
    text-align: center;
  }
`;

interface ICardProps {
  selected?: boolean;
  cloud?: any;
}

export const Card = styled.div<ICardProps>`
  cursor: pointer;
  background: ${props => props.theme.interface2};
  width: 170px;
  min-height: 110px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px 0px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color};
    `}

  &:hover {
    transition: all 0.2s ease-in;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  span {
    font-size: 14px;
    color: ${props => props.theme.interface6};
  }

  p {
    font-size: 10px;
    color: ${props => props.theme.interface5};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

export const Logo = styled.div`
  padding: 0px 10px;
  width: 100%;
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px solid ${props => props.theme.interface4}; */
`;
