import styled from 'styled-components';

export const AttachmentBox = styled.div`
  margin-top: 5px;
  border: 1px dashed ${props => (props.theme.darkMode ? '#ffffff' : '#929292')};
  background: ${props => props.theme.interface2};
  padding: 12px;
  text-align: center;
  cursor: pointer;

  .fileContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .fileBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: ${props =>
        props.theme.darkMode ? '#000000' : '#d6d6d6'};
      padding: 0 10px;
      border-radius: 5px;
      gap: 20px;

      span {
        color: ${props => (props.theme.darkMode ? '#929292' : '#000000')};
      }

      svg {
        font-size: 12px;
        color: #929292;
      }
    }
  }

  transition: filter 0.4s;

  span {
    font-family: Nunito;
    color: ${props => (props.theme.darkMode ? '#ffffff' : '#000000')};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
