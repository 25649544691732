import React, { useEffect, useState } from 'react';

import {
  faCloudUploadAlt,
  faExclamationCircle,
  faSearch,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getWebsitesService } from 'services/website';

import { CustomInput } from 'components/NewComponents/NewInput';
import ApplicationIcon from 'components/ApplicationIcon';
import { Alert } from 'components/Alert';

import {
  Button,
  createPagination,
  Pagination,
} from '@cloudez/cloudez-design-system';

import { toast } from 'react-toastify';
import faSolidServer from 'assets/img/svg/fa-solid_server.svg';
import IonEarth from 'assets/img/svg/ion_earth.svg';

import { useMigCreateWebsite } from '../../contexts/MigWebsiteContext';
import { useMigDetail } from '../../contexts/MigDetailContext';

import { Card, CardBox, Container, ButtonsContainer } from './styles';

const DestData = (): JSX.Element => {
  const {
    selectedDestItem,
    setSelectedDestItem,
    selectedOriginItem,
    setSelectedOriginItem,
    selectedAppType,
    setSelectedAppType,
    getWebsiteTypes,
    clouds,
    getClouds,
    websiteTypes,
    stage,
    changeStage,
  } = useMigCreateWebsite();

  const { setStep } = useMigDetail();

  const [searching, setSearching] = useState(false);

  const [websites, setWebsites] = useState<any>({ results: [] });
  const [selectedType, setSelectedType] = useState<any>({});
  const [search, setSearch] = useState<string | null>(null);

  const getWebsites = async (params?: any) => {
    try {
      const response = await getWebsitesService(params);

      const pagination = createPagination(response.data, 4);

      setWebsites({
        ...pagination,
        results: response.data.results,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const cards = [
    {
      id: 'email',
      icon: IonEarth,
      alt: 'otherExistingEmail',
      text: 'Substituir website existente',
    },
    {
      id: 'cloud',
      icon: faSolidServer,
      alt: 'newWebmailCloud',
      text: 'Criar novo website em um cloud',
    },
  ];

  const goBackFunc = () => {
    if (stage.selectAppType) {
      setSelectedAppType({});
      changeStage('newWebsiteCloud');
      return;
    }

    if (stage.existingWebsite) {
      setSelectedDestItem({});
      changeStage();
      return;
    }

    if (stage.newWebsiteCloud) {
      setSelectedDestItem({});
      changeStage();
      return;
    }

    if (stage.newWebsiteCloud || stage.existingWebsite) {
      setSelectedOriginItem({});
      setSelectedDestItem({});
      changeStage();
      return;
    }

    if (selectedOriginItem?.id) {
      setWebsites(null);
    }

    setSelectedOriginItem({});
    setStep(1);
  };

  const goAheadFunc = () => {
    if (
      selectedOriginItem?.id === selectedDestItem?.id &&
      selectedOriginItem !== 'external'
    ) {
      toast.error(
        'O website de destino não pode ser igual ao website de origem',
      );
      return;
    }

    if (!selectedDestItem.id) {
      if (
        selectedType.id === 'email' ||
        (selectedType.id === 'email' && !selectedAppType?.id)
      ) {
        changeStage('existingWebsite');
      } else if (
        selectedType.id === 'cloud' ||
        (selectedType.id === 'cloud' && selectedAppType?.id)
      ) {
        changeStage('newWebsiteCloud');
      }
    } else if (!stage.existingWebsite && !selectedOriginItem?.id) {
      changeStage('selectAppType');
    } else {
      setStep(3);
    }

    if (selectedDestItem.id && selectedAppType.id) {
      setStep(3);
    }
  };

  const getWebsitesCallback = (page = 1) => {
    getWebsites({ page_size: 4, page, search });
  };

  const getWebsiteTypesCallback = (page = 1) => {
    getWebsiteTypes({ page_size: 8, page, search });
  };

  const getCloudsCallback = (page = 1) => {
    getClouds({ page_size: 4, page, search });
  };

  useEffect(() => {
    if (search) {
      setSearching(true);
      const timer = setTimeout(() => {
        if (stage.newWebsiteCloud) {
          getCloudsCallback();
        }
        if (stage.existingWebsite) {
          getWebsitesCallback();
        }
        if (stage.selectAppType) {
          getWebsiteTypesCallback();
        }
        setSearching(false);
      }, 1000);

      return () => clearTimeout(timer);
    }

    if (stage.newWebsiteCloud && !selectedDestItem?.id) {
      getCloudsCallback();
    }
    if (stage.existingWebsite && !selectedDestItem?.id) {
      getWebsitesCallback();
    }
    if (stage.selectAppType && !selectedAppType?.id) {
      getWebsiteTypesCallback();
    }
    setSearching(false);
  }, [search, stage]);

  useEffect(() => {
    setSearch('');
  }, [stage]);

  return (
    <Container>
      <div className="title">
        <FontAwesomeIcon icon={faCloudUploadAlt} />
        <span>Dados do destino</span>
      </div>
      <span className="subtitle">
        {(() => {
          switch (true) {
            case stage.existingWebsite:
              return 'Selecione abaixo qual será o website de destino:';

            case stage.newWebsiteCloud:
              return 'Selecione abaixo qual será o cloud de destino:';

            case stage.selectAppType:
              return 'Selecione abaixo qual será o tipo de aplicação de destino:';

            default:
              return 'Selecione abaixo qual será o destino da sua aplicação:';
          }
        })()}
      </span>
      {(() => {
        switch (true) {
          case stage.existingWebsite ||
            stage.newWebsiteCloud ||
            stage.selectAppType:
            return (
              <CustomInput
                error={
                  websites?.results?.length === 0 && stage.existingWebsite
                    ? 'Não foi encontrado nenhum website'
                    : clouds?.results?.length === 0 && stage.newWebsiteCloud
                    ? 'Não foi encontrado nenhum cloud'
                    : null
                }
                icon={{ svg: searching ? faSpinner : faSearch }}
                block
                value={search}
                onChange={e => setSearch(e.target.value)}
                name="search"
                placeholder={(() => {
                  switch (true) {
                    case stage.existingWebsite:
                      return 'Se desejar, pesquise aqui o website de destino';

                    case stage.newWebsiteCloud:
                      return 'Se desejar, pesquise aqui o cloud de destino';

                    case stage.selectAppType:
                      return 'Se desejar, pesquise aqui a aplicação de destino';

                    default:
                      return null;
                  }
                })()}
              />
            );
          default:
            return null;
        }
      })()}

      {(() => {
        switch (true) {
          case stage.existingWebsite:
            return (
              <CardBox>
                {websites?.results.map(item => {
                  return (
                    <Card
                      onClick={() => setSelectedDestItem(item)}
                      selected={selectedDestItem.id === Number(item.id)}
                    >
                      <ApplicationIcon
                        width="40px"
                        height="40px"
                        icon={`${item?.type.slug}`}
                      />
                      <b>{item.domain || item.name}</b>
                      <span>
                        {item.name} - {item.cloud.disk}
                      </span>
                    </Card>
                  );
                })}
              </CardBox>
            );

          case stage.newWebsiteCloud:
            return (
              <CardBox>
                {clouds?.results?.map((item: any) => {
                  return (
                    <Card
                      onClick={() => setSelectedDestItem(item)}
                      selected={selectedDestItem.id === Number(item.id)}
                    >
                      <img src={faSolidServer} alt="newWebmailCloud" />
                      <b>{item.nickname ? item.nickname : item.name}</b>
                      <span>{item.cloud_size.disk}</span>
                    </Card>
                  );
                })}
              </CardBox>
            );
          case stage.selectAppType:
            return (
              <CardBox>
                {websiteTypes?.results?.map((item: any) => {
                  return (
                    <Card
                      onClick={() => setSelectedAppType(item)}
                      selected={selectedAppType.id === Number(item.id)}
                    >
                      {item.slug === 'onrise' ? (
                        <ApplicationIcon
                          width="90px"
                          height="40px"
                          icon={`${item.slug}`}
                        />
                      ) : (
                        <ApplicationIcon
                          width="40px"
                          height="40px"
                          icon={`${item.slug}`}
                        />
                      )}
                      <b>{item.name}</b>
                      <span>{item.description}</span>
                    </Card>
                  );
                })}
              </CardBox>
            );
          default:
            return (
              <CardBox>
                {cards.map(item => {
                  return (
                    <Card
                      onClick={() => setSelectedType(item)}
                      selected={selectedType.id === item.id}
                    >
                      <img src={item.icon} alt={item.alt} />
                      <b>{item.text}</b>
                    </Card>
                  );
                })}
              </CardBox>
            );
        }
      })()}
      {(() => {
        switch (true) {
          case stage.existingWebsite:
            return (
              <Pagination
                style={{ marginTop: '0' }}
                onChangePage={getWebsitesCallback}
                pageSize={4}
                count={websites?.count}
                previous={websites?.previous}
                next={websites?.next}
                current={websites?.current}
              />
            );

          case stage.newWebsiteCloud:
            return (
              <Pagination
                style={{ marginTop: '0' }}
                onChangePage={getCloudsCallback}
                pageSize={4}
                count={clouds?.count}
                previous={clouds?.previous}
                next={clouds?.next}
                current={clouds?.current}
              />
            );
          case stage.selectAppType:
            return (
              <Pagination
                style={{ marginTop: '0' }}
                onChangePage={getWebsiteTypesCallback}
                pageSize={4}
                count={websiteTypes?.count}
                previous={websiteTypes?.previous}
                next={websiteTypes?.next}
                current={websiteTypes?.current}
              />
            );
          default:
            return null;
        }
      })()}
      {(() => {
        switch (true) {
          case stage.existingWebsite || selectedType.id === 'email':
            return (
              <Alert info>
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
                <span style={{ maxWidth: '90%' }}>
                  <b>O que será feito?</b> Nós vamos substituir os arquivos que
                  já existem no domínio que você selecionar pelos arquivos que
                  serão baixados da origem!
                </span>
              </Alert>
            );
          case stage.newWebsiteCloud || selectedType.id === 'cloud':
            return (
              <Alert info>
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
                <span>
                  <b>O que será feito?</b> Vamos copiar seus arquivos de origem
                  e vamos criar um novo website no cloud selecionado.
                </span>
              </Alert>
            );
          case stage.selectAppType:
            return (
              <Alert info>
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
                <span>
                  <b>O que será feito?</b> Vamos criar o tipo de sua aplicação
                  com base na tecnologia selecionada.
                </span>
              </Alert>
            );
          default:
            return null;
        }
      })()}

      <ButtonsContainer>
        <Button
          onClick={goBackFunc}
          style={{ fontSize: '14px' }}
          width="117px"
          type="button"
          text
        >
          Voltar
        </Button>
        <Button
          onClick={goAheadFunc}
          style={{
            fontSize: '16px',
            textTransform: 'none',
          }}
          disabled={
            (!selectedType?.id &&
              !selectedAppType?.id &&
              !selectedDestItem?.cloud) ||
            (stage.existingWebsite && !selectedDestItem?.id) ||
            (stage.newWebsiteCloud && !selectedDestItem?.id)
          }
          width="140px"
        >
          Continuar
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default DestData;
