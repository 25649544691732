import queryString from 'query-string';
import { Pagination } from 'types';

interface createPaginationProps {
  results: any;
}

export const createPagination = (
  req: Pagination & createPaginationProps,
  size = 10,
): any => {
  const data = req.results;
  const { count } = req;
  const next = req.next
    ? queryString.parseUrl(String(req.next)).query.page
    : null;
  let current;
  let previous;

  if (req.previous) {
    if (queryString.parseUrl(String(req.previous)).query.page) {
      previous = queryString.parseUrl(String(req.previous)).query.page;
    } else {
      previous = 1;
    }
  } else {
    previous = null;
  }

  if (previous) {
    if (next) {
      current = Number(next) - 1;
    } else {
      current = Math.ceil(count / size);
    }
  } else {
    current = 1;
  }

  return {
    data,
    count,
    next,
    previous,
    current,
  };
};
