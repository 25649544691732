import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.interface1};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  padding: 37px 30px;
  margin-bottom: 35px;

  img {
    filter: drop-shadow(0px 2.71028px 20.3271px rgba(100, 114, 140, 0.25));
    margin-bottom: 80px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;

  p {
    background-image: linear-gradient(
      90deg,
      rgba(54, 160, 252, 1) 0%,
      rgba(34, 83, 245, 1) 100%
    );

    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;

    color: black;

    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
  }

  width: 100%;
`;
