import styled from 'styled-components';

export const NoResults = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: 40px;
  border-top: 1px dashed ${props => props.theme.interface5};
  border-bottom: 1px dashed ${props => props.theme.interface5};
`;

interface INoResultsDetailProps {
  width?: string;
  height?: string;
  row?: boolean;
}

export const NoResultsDetail = styled.div<INoResultsDetailProps>`
  display: flex;
  width: ${props => props.width || '231px'};
  height: ${props => props.height || '142px'};
  align-items: center;
  justify-content: ${props => (props.row ? 'space-between' : 'center')};
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  font-size: 14px;
  margin: 10px 0px;
  color: ${props => props.theme.interface5};
  padding: ${props => (props.row ? '13px 10px' : '20px 10px')};
  border: 1px dashed ${props => props.theme.interface5};
  border-radius: 5px;
  text-transform: uppercase;

  svg {
    color: ${props => props.theme.interface5};
    margin-bottom: ${props => (props.row ? '0px' : '10px')};
    font-size: ${props => (props.row ? '24px' : '35px')};
  }

  font-size: 12px;
`;
