import axios from 'axios';

const token = localStorage.getItem('@cloudez-partners-v2/token');

const api = axios.create({
  baseURL: process.env.REACT_APP_DEADPOOL_URL,
  headers: {
    Authorization: token,
  },
});

export const getChatsService = async params => {
  return api.get(`/chat`, {
    params,
  });
};

export const getChatService = async (
  id: string | number,
  params?: any,
): Promise<any> => {
  return api.get(`/chat/${id}`, {
    params,
  });
};

export const createMessageService = async messagePayload => {
  return api.post('/message', messagePayload);
};

export const escalateToTicketService = async (
  id: number | string,
  params?: any,
): Promise<void> => {
  return api.post(`/chat/${id}/scale`, null, {
    params,
  });
};

export const closeChatService = async (
  id: number | string,
  params?: any,
): Promise<void> => {
  return api.delete(`/chat/${id}`, {
    params,
  });
};

export const readNotificationService = async (id: number | string) => {
  return api.post(`/notification/${id}/read`);
};
