import { useContext } from 'react';
import { LayoutContext } from '../context/layout';

export function useLayout(): any {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error('useLayout must be used within an LayoutProvider');
  }

  return context;
}
