import { Input } from 'components/Input';
import { ParsedQuery } from 'query-string';

import styled, { css } from 'styled-components';

interface SearchProps {
  block?: boolean;
  noResults: ParsedQuery<string> | string | boolean;
}

export const Container = styled.div<SearchProps>`
  text-align: start;
  position: relative;

  ${Input} {
    padding-left: 30px;
  }

  svg {
    cursor: pointer;
    color: ${props => props.theme.interface4};
  }

  span {
    color: ${props => props.theme.error};
    position: absolute;
    bottom: 5px;
    right: 2%;
    font-size: 9px;
  }
`;

export const SearchWrapper = styled.div<SearchProps>`
  opacity: 1;
  position: relative;

  ${props =>
    props.block &&
    css`
      width: 100%;
    `};

  p {
    margin-left: 20px;
    display: ${props => (props.noResults ? 'block' : 'none')};
    font-size: 9px;
    font-style: italic;
    color: ${props => props.theme.error};
  }
`;
