import React from 'react';
import { Button } from '@cloudez/cloudez-design-system';
import { useLayout } from 'hooks';
import danger from 'assets/img/svg/danger.svg';
import exclamation from 'assets/img/svg/exclamation.svg';
import { Wrapper } from './styles';

const NotificationBar = () => {
  const { notification, setNotification } = useLayout();

  return (
    <Wrapper type={notification?.type} button={notification?.button}>
      {(notification?.type === 'warning' ||
        notification?.type === 'danger') && (
        <img
          alt="exclamation"
          src={notification?.type === 'danger' ? danger : exclamation}
        />
      )}
      <span>{notification?.text}</span>
      <Button
        onClick={() => {
          if (notification?.button?.callback) {
            notification.button.callback();
          }
          if (notification?.button?.link) {
            window.open(notification?.button?.link, '_blank');
          }
          setNotification('', null, {});
        }}
      >
        {notification?.button?.text || 'OK'}
      </Button>
    </Wrapper>
  );
};

export default NotificationBar;
