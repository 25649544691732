import React, { useCallback, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Field, Label, Input, Error } from 'components/Input';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import api from 'services/api';

import { P } from './styles';

const CreditModal = ({ show, setShow, billing, invoice, action }) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [negativeValue, setNegativeValue] = useState(false);

  const applyCredit = useCallback(async () => {
    setLoading(true);
    try {
      await api.post(`/v3/invoice/${invoice.id}/apply-company-credit-v2/`, {
        value,
      });

      action();
      setShow();
      setLoading(false);
      toast.success('Crédito aplicado com sucesso');
    } catch (e) {
      toast.error(e.response.data[Object.keys(e.response.data)[0]]);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Aplicar crédito</Title>
          <Subtitle>Escolha o valor de crédito a ser aplicado</Subtitle>
        </Text>
      </Header>

      <Content>
        <P>Seu saldo é de</P>
        <CurrencyFormat
          value={billing.balance.amount}
          displayType="text"
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          thousandSeparator="."
          prefix={billing.balance.currency === 'BRL' ? 'R$ ' : '$ '}
        />
        <P>Qual valor você vai aplicar de crédito?</P>
        <Field>
          <Label>Digite o valor</Label>
          <Input
            block="true"
            type="number"
            value={value}
            onChange={e => {
              if (e.target.value.startsWith('-')) {
                setNegativeValue(true);
              } else {
                setNegativeValue(false);
              }
              setValue(e.target.value);
            }}
            min="0"
            max={billing.balance.amount}
          />
          {negativeValue && <Error>Apenas valores positivos</Error>}
        </Field>

        {value && !negativeValue && (
          <>
            <P>Seu novo saldo será de</P>
            <CurrencyFormat
              value={billing.balance.amount - value}
              displayType="text"
              fixedDecimalScale
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              prefix={billing.balance.currency === 'BRL' ? 'R$ ' : '$ '}
            />
          </>
        )}
      </Content>
      <Footer>
        <Button
          onClick={applyCredit}
          icon
          disabled={
            loading || !value || value === '0' || negativeValue || value < 0
          }
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Aplicar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default CreditModal;
