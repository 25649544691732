import React, { useCallback, useContext, useState } from 'react';

import { Col } from 'react-bootstrap';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  CardContent,
  ListingItem,
  ApplicationValue,
  ApplicationName,
} from 'components/Listing/styles';
import { useLayout } from 'hooks';
import { Acesso, AcessosList } from 'components/Acessos';
import { toast } from 'react-toastify';
import api from 'services/api';
import history from 'services/history';
import { Link } from 'react-router-dom';
import RemoveModal from './RemoveModal';
import { CustomerDetailContext } from '../../_context/state';

const TicketCard = ({ plan }) => {
  const theme = useLayout();

  const { companyPlan, setCompanyPlan } = useContext(CustomerDetailContext);

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const status = {
    0: 'Pagamento pendente',
    3: 'Desativado',
    2: 'Ativo',
  }[plan?.status];

  const removePlan = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`/v3/company/plan/${plan.id}/`);
      setCompanyPlan(companyPlan.filter(p => p.id !== plan.id));
      setLoading(false);
      setShowRemoveModal(false);
      toast.success('Sucesso ao remover plano');
    } catch (e) {
      setShowRemoveModal(false);
      setLoading(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  }, [plan.id]);

  return (
    <>
      {showRemoveModal && (
        <RemoveModal
          show={showRemoveModal}
          setShow={setShowRemoveModal}
          removePlan={removePlan}
          loading={loading}
        />
      )}
      <Link to={`/plans?search=${plan.type_name}`} target="_blank">
        <ListingItem key={plan.id}>
          <CardContent>
            <Col lg="3">
              <ApplicationValue style={{ marginLeft: '0px' }}>
                <ApplicationName>{plan.type_name}</ApplicationName>
              </ApplicationValue>
            </Col>
            <Col lg="2">
              <ApplicationValue>
                {status && (
                  <FontAwesomeIcon
                    style={{
                      color:
                        plan?.status === 2
                          ? theme.successNew
                          : plan?.status === 0
                          ? theme.alertNew
                          : theme.errorNew,
                    }}
                    icon={
                      plan?.status === 2
                        ? faCheckCircle
                        : plan?.status === 0
                        ? faExclamationCircle
                        : faXmarkCircle
                    }
                    size="1x"
                  />
                )}
                <ApplicationName>{status || '-'}</ApplicationName>
              </ApplicationValue>
            </Col>
            <Col lg="4">
              <ApplicationValue>
                <ApplicationName>
                  {plan.resource_description || '-'}
                </ApplicationName>
              </ApplicationValue>
            </Col>
            <Col>
              <ApplicationValue style={{ marginLeft: '0px' }}>
                <ApplicationName>
                  {plan?.activated_at
                    ? moment(plan?.activated_at).format('DD/MM/YYYY')
                    : '-'}
                </ApplicationName>
              </ApplicationValue>
            </Col>
            <Col xl="1" md="2" sm="4">
              <AcessosList>
                <Acesso style={{ cursor: 'pointer' }} active hover favorite>
                  <FontAwesomeIcon
                    onClick={() => setShowRemoveModal(true)}
                    icon={faTrash}
                    size="1x"
                  />
                </Acesso>
              </AcessosList>
            </Col>
          </CardContent>
        </ListingItem>
      </Link>
    </>
  );
};

export default TicketCard;
