import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { NoResultsDetail } from 'components/NoResults';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'hooks';
import cloudez_icon from 'assets/img/svg/cloud_cloudez.svg';
import { CloudCard, Content, Logo } from './styles';

const Clouds = ({ clouds }) => {
  const { company } = useAuth();

  return (
    <Row
      style={{
        width: '100%',
        margin: '0',
      }}
    >
      {clouds &&
        (clouds.length ? (
          clouds.map(c => {
            return (
              <Col key={c.id} xl="2" lg="3" md="4" sm="6" xs="12">
                <CloudCard
                  onClick={() =>
                    window.open(
                      `https://${company.app_domains[0]}/clouds/${c.id}`,
                    )
                  }
                  key={c.id}
                  cloud={c}
                >
                  <Content>
                    <Logo>
                      <img src={cloudez_icon} width="45px" alt="logo" />
                    </Logo>
                  </Content>
                  <span>{c.nickname || c.name}</span>
                  <p>
                    {c.cloud_size.name} {c.cloud_size.disk}
                  </p>
                </CloudCard>
              </Col>
            );
          })
        ) : (
          <Col xl="2" lg="3" md="4" sm="6" xs="12">
            <NoResultsDetail height="130px" width="100%">
              <FontAwesomeIcon icon={faCloud} />
              Nenhum cloud.
            </NoResultsDetail>
          </Col>
        ))}
    </Row>
  );
};

export default Clouds;
