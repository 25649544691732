import styled, { css } from 'styled-components';
import starSvg from 'assets/img/stamps/star.svg';

export const Wrapper = styled.div`
  width: 100%;
`;

interface ICardProps {
  sectioned?: boolean;
  width?: string;
  absolute?: boolean;
  vertical?: boolean;
  image?: boolean;
  shadow?: boolean;
}

export const Card = styled.div<ICardProps>`
  width: ${props => props.width || '100%'};

  height: fit-content;

  background-color: ${props => props.theme.interface1};

  border-radius: 10px;

  padding: 16px 24px;

  font: 'Montserrat', sans-serif;

  box-sizing: border-box;

  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
    `}

  ${props =>
    props.sectioned &&
    css`
      display: flex;
      align-content: center;
      justify-content: space-between;
      ${props.vertical &&
      css`
        flex-direction: column;
      `}

      @media (max-width: 768px) {
        .section {
          flex-direction: column;
        }
      }
    `}

  ${props =>
    props.shadow &&
    css`
      box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
    `}

  ${({ image }) =>
    image &&
    css`
      display: flex !important;
      padding: 0;

      img {
        width: 435px;
        height: 286px;
      }

      @media (max-width: 768px) {
        img {
          width: 0;
        }
      }
    `}

  div.section {
    padding: ${props => (props.vertical ? '30px 0px' : '0px 30px')};
  }

  div.section.right {
    width: 28%;
    padding-right: 0;
  }

  div.section.left {
    width: 72%;
    padding-left: 0;
  }

  .border-top {
    border-top: 2px solid rgba(149, 179, 224, 0.3);
  }

  .border-right {
    border-right: 2px solid rgba(149, 179, 224, 0.3);
  }

  .slick-dots,
  .slick-active {
    button::before {
      color: ${props => props.theme.brand_primary_color} !important;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    div.section {
      padding-top: 0;

      align-items: center;
      justify-content: center;
    }

    div.section.right {
      width: 100% !important;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const Levels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 40px;
`;

export const CardTitle = styled.h1`
  font-size: 16px;
  color: ${props => props.theme.interface6};
  font-weight: bold;

  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  display: block;

  margin-bottom: 30px;

  text-transform: capitalize;
`;

interface ITrailLevelProps {
  theme?: any;
  completed?: boolean;
  active?: boolean;
  stampName?: string;
  dark?: boolean;
}

export const TrailLevel = styled.div<ITrailLevelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;

  span.stamp-title {
    color: ${props =>
      props.completed
        ? props.theme.brand_primary_color
        : props.theme.interface4};
    text-transform: capitalize;
    margin: 5px 0px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  span.threshold {
    font-size: 12px;
    color: ${props =>
      props.completed ? props.theme.success : props.theme.interface5};
  }

  svg.check {
    color: ${props => props.theme.success};
    position: absolute;
    font-size: 16px;
    top: 24px;
    right: 0;
    z-index: 1;
  }
`;

const reqStamps = require.context('assets/img/stamps', true, /\.svg$/);

export const Stamp = styled.img.attrs((props: ITrailLevelProps) => {
  let stampImg = null;

  if (props.completed || props.active) {
    stampImg = reqStamps(
      `./${props.stampName}${props.theme.darkMode ? '-dark' : ''}.svg`,
    ).default;
  } else {
    stampImg = reqStamps(`./${props.stampName}-inactive.svg`).default;
  }

  return { src: stampImg, alt: `${props.stampName} stamp` };
})`
  width: ${(props: ITrailLevelProps) => (props.active ? '150px' : '125px')};

  ${(props: ITrailLevelProps) =>
    props.completed &&
    !props.active &&
    css`
      opacity: 0.7;
    `}

  ${props =>
    props.active &&
    css`
      filter: drop-shadow(0px 2px 15px ${props.color});
    `}

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

export const StampSm = styled.img.attrs((props: ITrailLevelProps) => {
  return {
    src: reqStamps(`./${props.stampName}${props.dark ? '-dark' : ''}-sm.svg`)
      .default,
  };
})<ITrailLevelProps>`
  width: 146px;
`;

export const DownloadStamp = styled.div`
  padding-right: 20px;
  width: 35%;

  .slider {
    width: 100%;
  }

  ${StampSm} {
    width: 100%;
  }
`;

interface ICurrentStampProps {
  theme: any;
  currentStampName: string;
}

export const CurrentStamp = styled.img.attrs((props: ICurrentStampProps) => {
  return {
    src: reqStamps(
      `./${props.currentStampName}${props.theme.darkMode ? '-dark' : ''}.svg`,
    ).default,
  };
})<ICurrentStampProps>`
  width: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const Star = styled.img.attrs({ src: starSvg, alt: 'star' })`
  width: ${props => props.width || '75px'};
  margin-bottom: 15px;
`;

export const Goal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-size: 14px;
  text-align: center;

  color: ${props => props.theme.interface5};

  span.label {
    font-weight: 700;
    margin-bottom: 10px;
  }

  span.highlight {
    color: ${props => props.theme.error};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.error} !important;
  }

  span.goal {
    text-decoration: underline;
    margin-bottom: 10px;
  }

  span.current {
    font-size: 12px;
    margin-bottom: 20px;
  }

  a.more {
    color: ${props => props.theme.brand_primary_color};
    text-decoration: underline;

    font: 'Lato', sans-serif;

    font-style: italic;

    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    /* svg {
      col
    } */
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 120px;

  color: ${props => props.theme.success};

  margin-bottom: 5px;
`;

interface iProgressBarFillProps {
  progress: string | number;
}

export const ProgressBarFill = styled.div<iProgressBarFillProps>`
  width: 100%;
  border: 1px solid ${props => props.theme.success};
  height: 9px;
  background: ${props => `linear-gradient(90deg,
     ${props.theme.success} 0%,
      ${props.theme.success} ${props.progress}%,
       transparent ${props.progress}%)`};

  border-radius: 10px;
`;

export const BenefitsList = styled.div`
  width: 70%;
  flex-grow: 2;

  padding-left: 30px;

  .slider {
    width: 100%;

    padding: 10px;

    box-sizing: border-box;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
`;

export const BenefitsTitle = styled.h4`
  font-weight: bold;
  font-size: 14px;
  margin-left: 17px;
  margin-bottom: 20px;

  color: ${props => props.theme.brand_primary_color};
`;

interface IBenefitProps {
  next?: boolean;
}

export const Benefit = styled.div<IBenefitProps>`
  font: 'Lato', sans-serif;
  text-overflow: ellipsis;
  line-height: 20px;

  color: ${props => props.theme.interface5};

  svg {
    margin-right: 4px;
    color: ${props =>
      props.next ? props.theme.interface5 : props.theme.success};
    ${props =>
      props.next &&
      css`
        margin-bottom: 1px;
        font-size: 10px;
      `}
  }

  & + & {
    margin-top: 10px;
  }
`;

export const Embed = styled.div`
  border: 1px solid ${props => props.theme.interface3};
  border-radius: 5px;

  padding: 8px;

  p {
    word-break: break-all;
    color: ${props => props.theme.interface5};
  }

  h4 {
    font-size: 14px;
    color: ${props => props.theme.interface7};
  }
`;

export const EmbedWrapper = styled.div`
  width: 65%;
`;

export const Ads = styled.div`
  .slick-slider {
    width: calc(100% + 30px);
    transform: translateX(-15px);
  }

  .slick-slide {
    padding: 15px;
  }

  .slick-dots,
  .slick-active {
    button::before {
      color: ${props => props.theme.brand_primary_color} !important;
    }
  }

  /* @media (max-width: 768px) {
    .slick-slider {
      width: auto;
      transform: none;
    }
    .slick-slide {
      padding: 0px;
    }
  } */
`;

export const Ad = styled.div`
  text-align: left;

  padding: 20px 50px 20px 50px;

  h4.title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: ${props => props.theme.interface5};
    margin-bottom: 20px;
    span.highlight {
      font-weight: 700;
      color: ${props => props.theme.brand_primary_color};
    }
    img {
      width: 150px;
      height: 24px;
    }
  }

  p {
    color: ${props => props.theme.interface5};
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CloudCount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 84%;

  position: relative;

  svg {
    font-size: 24px;
    margin: 0;
    margin-right: 8px;
    margin-top: 3px;
  }

  h2.title {
    font-weight: bold;
    font-size: 16px;
    color: ${props => props.theme.interface6};
  }

  div.value {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;

    background: linear-gradient(to left, #2253f5, #36a0fc);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-right: 20px;

    .highlight {
      /* text-decoration-line: underline; */
      font-size: 20px;
      font-weight: 400;

      border-bottom: 2px solid #2253f5;
    }
  }

  a.more {
    color: ${props => props.theme.brand_primary_color} !important;
    text-decoration: underline;

    font: 'Lato', sans-serif;

    font-style: italic;

    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  img {
    position: absolute;
    left: -35px;
    bottom: -16px;
  }
`;
