import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 83px;

  img {
    width: 34rem;
    height: 26rem;
  }

  span {
    color: ${props => props.theme.interface5};
    font-weight: bold;
    font-size: 16px;

    text-align: center;
  }
`;
