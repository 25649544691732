import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const Header = styled.div`
  padding: 24px;

  min-height: 90px;

  display: flex;
  align-items: center;

  border-bottom: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};
`;

export const Icon = styled.div`
  background: ${props => lighten(0.04, props.theme.brand_secondary_color)};
  width: 41px;
  height: 41px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 3px 15px rgba(100, 114, 140, 0.1);
  border-radius: 5px;

  svg {
    font-size: 20px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Text = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 18px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
`;

export const Subtitle = styled.span`
  font-size: 12px;
  color: ${props => props.theme.interface5};
`;

export const Content = styled.div`
  padding: 24px;
  width: 100%;

  svg {
    color: ${props => props.theme.brand_primary_color};
  }
`;

interface IFooterProps {
  hasLink?: boolean;
}

export const Footer = styled.div<IFooterProps>`
  padding: 24px;

  min-height: 90px;

  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: ${props => (props.hasLink ? 'space-between' : 'flex-end')};
  background: ${props => props.theme.interface2};

  border-top: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};

  ${props =>
    props.hasLink &&
    css`
      button {
        width: 170px;
      }

      a {
        text-decoration: none;

        color: ${props => props.theme.error};
      }
    `}
`;

export const Link = styled.div`
  color: ${props => props.theme.interface5};
  p {
    font-size: 10px;
    font-weight: bold;
  }
  a {
    font-size: 12px;
    text-decoration: underline;
  }
`;
