import { Icon } from './styles';

type ApplicationIconProps = {
  height: string;
  width: string;
  disable?: boolean;
  icon?: string | string[];
  style?: React.CSSProperties;
};

const ApplicationIcon: React.FC<ApplicationIconProps> = ({
  icon,
  disable,
  height,
  width,
}) => {
  return <Icon disable={disable} icon={icon} height={height} width={width} />;
};

export default ApplicationIcon;
