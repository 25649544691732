import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  border-radius: 5px;
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};

  & > li {
    border-bottom: 1px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};
  }

  & > li:last-child {
    border-bottom: none;
  }
`;
