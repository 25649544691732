import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { AttachmentBox } from './styles';

export const StyledDropzone = ({
  acceptedFiles,
  getInputProps,
  getRootProps,
}) => {
  const removeFile = file => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
  };

  return (
    <AttachmentBox {...getRootProps({ className: 'dropzone' })}>
      {acceptedFiles.length > 0 ? (
        <>
          {acceptedFiles.map((file: any) => (
            <div key={file?.name} className="fileContainer">
              <div onClick={() => removeFile(file)} className="fileBox">
                <span>{file?.name}</span>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          ))}
        </>
      ) : (
        <span className="text">Clique para adicionar ou arraste o arquivo</span>
      )}
      <input {...getInputProps()} />
    </AttachmentBox>
  );
};
