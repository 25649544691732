import api from 'services/api';

export const getSurveySlugService = async (
  slug: string,
  params?: any,
): Promise<{ data: any }> => {
  try {
    return await api.get(`/v3/survey/${slug}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const answerSurveyService = async data => {
  try {
    return await api.post(`/v3/answer-template/`, data);
  } catch (e) {
    throw e;
  }
};

export const getAnswersPerSurveyService = async survey => {
  try {
    return await api.get(`/v3/answer/`, {
      params: {
        survey,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const answerQuestionService = async data => {
  try {
    return await api.post('/v3/answer/', data);
  } catch (e) {
    throw e;
  }
};

export const updateAnswerService = async (id: number | string, answer) => {
  try {
    return await api.patch(`/v3/answer/${id}/`, {
      answer,
    });
  } catch (e) {
    throw e;
  }
};

export const finishSurveyService = async slug => {
  try {
    return await api.post(`/v3/survey/${slug}/answer/`);
  } catch (e) {
    throw e;
  }
};
