import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Button } from '@cloudez/cloudez-design-system';

import {
  faCloudUploadAlt,
  faEyeSlash,
  faEye,
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { useAuth } from 'hooks/auth';

import { createMigrationWebsiteService } from 'services/friday';
import { createWebsiteService } from 'services/website';
import { getDatabaseService } from 'services/database';
import { getCloudsService } from 'services/cloud';

import { Alert } from 'components/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMigDetail } from '../../contexts/MigDetailContext';

import { ButtonsContainer, Container, Flexbox } from './styles';
import { useMigCreateWebsite } from '../../contexts/MigWebsiteContext';
import { ErrorModal } from '../../ErrorModal';

const Confirmation = (): JSX.Element => {
  const [databaseNameOrigin, setDatabaseNameOrigin] = useState('');
  const [databaseNameDest, setDatabaseNameDest] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState<any>({});

  const history = useHistory();

  const { user } = useAuth();

  const {
    selectedOriginItem,
    selectedAppType,
    originData,
    selectedDestItem,
    changeStage,
    migrationErrors,
    migErrorMessage,
  } = useMigCreateWebsite();

  const { setStep } = useMigDetail();

  const isCloud = !selectedDestItem?.cloud?.id;

  const originItem = originData?.remote_host
    ? { ...originData }
    : {
        id: selectedOriginItem.id,
        domain: originData.domain,
        cloud_id: selectedOriginItem.cloud.id,
        app_name: selectedOriginItem.app_name,
        nodeapp_type:
          isCloud && selectedOriginItem === 'external'
            ? selectedAppType?.slug
            : selectedOriginItem?.type?.slug,
        database_name: databaseNameOrigin,
        cloud_description: selectedOriginItem.cloud.display,
        cloud_fqdn: selectedOriginItem.cloud.fqdn,
        temporary_address: selectedOriginItem.temporary_address,
      };

  const initMigration = async () => {
    try {
      let createdWebsite;

      setLoading(true);
      if (isCloud) {
        const { data } = await createWebsiteService({
          values: [
            {
              slug: 'domain',
              value:
                selectedOriginItem === 'external'
                  ? originData.domain
                  : selectedOriginItem.domain,
            },
          ],
          cloud: selectedDestItem.id,
          type:
            selectedOriginItem === 'external'
              ? selectedAppType?.slug
              : selectedOriginItem?.type?.slug,
        });

        createdWebsite = data;
      }

      const variable = isCloud ? createdWebsite : selectedDestItem;

      let databaseName;

      if (createdWebsite?.databases?.length > 0) {
        const response = await getDatabaseService(createdWebsite?.databases[0]);

        const database = response.data.values.filter(
          item => item.slug === 'database_name',
        );
        databaseName = database[0].value;
      }

      const destItemData = {
        id: variable.id,
        domain: variable.domain,
        app_name: variable.app_name,
        nodeapp_type: variable?.slug || variable?.type?.slug,
        database_name: databaseName || databaseNameDest,
        temporary_address: variable.temporary_address,
        cloud_fqdn: variable.cloud_fqdn || variable?.cloud?.fqdn,
        cloud_description: variable.cloud_display || variable?.cloud?.display,
        cloud_id: variable.cloud.id || variable.cloud,
      };

      const payloadInternal = {
        company_uuid: user.company,
        domain: originData.domain,
        migration_type: 2,
        user_uuid: user.uuid,
        src_nodeapp: originItem,
        dest_nodeapp: destItemData,
      };

      const payloadExternal = {
        ...originItem,
        migration_type: 1,
        company_uuid: user.company,
        user_uuid: user.uuid,
        dest_nodeapp: destItemData,
      };

      const { data } = await createMigrationWebsiteService(
        selectedOriginItem === 'external'
          ? { ...payloadExternal }
          : { ...payloadInternal },
      );

      history.push(`/migrations/${data.id}/?success=true`);

      setStep(4);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err?.response?.data) {
        migrationErrors(err?.response?.data);
      }
    }
  };

  const goBackFunc = () => {
    if (selectedDestItem?.cloud) {
      setStep(2);
      changeStage('existingWebsite');
    }

    if (!selectedDestItem?.cloud && !selectedOriginItem?.id) {
      setStep(2);
      changeStage('selectAppType');
    }

    if (!selectedDestItem?.cloud && selectedOriginItem?.id) {
      setStep(2);
      changeStage('newWebsiteCloud');
    }
  };

  useEffect(() => {
    if (!isCloud) {
      const getCloud = async () => {
        const response = await getCloudsService(selectedDestItem?.cloud?.id);
        setAdmin(response.data.admin);
      };
      getCloud();
    }
  }, [isCloud]);

  useEffect(() => {
    if (
      selectedOriginItem !== 'external' &&
      selectedOriginItem?.databases?.length > 0
    ) {
      const getDatabaseOrigin = async () => {
        const response = await getDatabaseService(
          selectedOriginItem.databases[0],
        );

        const database = response.data.values.filter(
          item => item.slug === 'database_name',
        );

        setDatabaseNameOrigin(database[0].value);
      };

      getDatabaseOrigin();
    }

    if (selectedDestItem?.databases?.length > 0) {
      const getDatabaseDest = async () => {
        const response = await getDatabaseService(
          selectedDestItem.databases[0],
        );

        const database = response.data.values.filter(
          item => item.slug === 'database_name',
        );

        setDatabaseNameDest(database[0].value);
      };

      getDatabaseDest();
    }
  }, [selectedOriginItem]);

  return (
    <>
      {migErrorMessage && <ErrorModal err={migErrorMessage} />}
      <Container>
        <div className="title">
          <FontAwesomeIcon icon={faCloudUploadAlt} />
          <span>Confirmação de dados</span>
        </div>
        <span className="subtitle">
          Antes de iniciar a migração, confirme os dados abaixo:
        </span>
        <div className="dataMig">
          <p>Dados da Origem</p>
          <Flexbox spacing="5px" flexDir="column">
            <label>Domínio</label>
            <span>
              {selectedOriginItem?.id
                ? selectedOriginItem?.domain
                : originData.domain}
            </span>
          </Flexbox>

          {selectedOriginItem === 'external' && (
            <>
              <Flexbox>
                <Flexbox w="100%" maxWidth="30%" spacing="5px" flexDir="column">
                  <label>Servidor</label>
                  <span>{originData.remote_host}</span>
                </Flexbox>
                <Flexbox w="100%" spacing="5px" flexDir="column">
                  <label>Porta</label>
                  <span>{originData.remote_port}</span>
                </Flexbox>
              </Flexbox>
              <Flexbox>
                <Flexbox w="100%" maxWidth="20%" spacing="5px" flexDir="column">
                  <label>Usuário</label>
                  <span style={{ width: '150px' }} className="overflowText">
                    {originData.remote_user}
                  </span>
                </Flexbox>
                <Flexbox w="100%" maxWidth="20%" spacing="5px" flexDir="column">
                  <label>Senha</label>
                  <div
                    style={{ alignItems: showPassword ? 'end' : '' }}
                    className="password"
                  >
                    <span className="overflowText">
                      {showPassword
                        ? originData.remote_password
                        : originData.remote_password.replace(/[\S]/g, '*')}
                    </span>
                    <FontAwesomeIcon
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? faEye : faEyeSlash}
                    />
                  </div>
                </Flexbox>
                <Flexbox w="100%" spacing="5px" flexDir="column">
                  <label>Caminho da aplicação</label>
                  <span>{originData.ssh_path}</span>
                </Flexbox>
              </Flexbox>
            </>
          )}
        </div>
        <div className="dataMig">
          <p>Dados do Destino</p>
          <Flexbox>
            <Flexbox w="100%" maxWidth="30%" flexDir="column" spacing="5px">
              <label>Tipo da Migração</label>
              <span>
                {selectedAppType?.id && 'Criar novo website em um Cloud'}
                {selectedOriginItem === 'external' &&
                  !selectedAppType?.id &&
                  'Website já existente'}
                {!selectedAppType?.id &&
                  selectedOriginItem !== 'external' &&
                  'Interna'}
              </span>
            </Flexbox>
            <Flexbox w="100%" flexDir="column" spacing="5px">
              <label>Cloud</label>
              <span>
                {selectedDestItem?.cloud?.display
                  ? selectedDestItem.cloud.display
                  : `${selectedDestItem?.name} - ${selectedDestItem?.cloud_size?.name}`}
              </span>
            </Flexbox>
          </Flexbox>
          <Flexbox>
            <Flexbox w="100%" maxWidth="30%" flexDir="column" spacing="5px">
              <label>Domínio</label>
              <span>
                {!isCloud
                  ? selectedDestItem?.domain
                  : selectedOriginItem?.domain || originData.domain}
              </span>
            </Flexbox>

            <Flexbox w="100%" flexDir="column" spacing="5px">
              <label>Dono do Cloud</label>
              {selectedDestItem?.cloud?.id ? (
                <span>{admin?.email}</span>
              ) : (
                <span>{selectedDestItem?.admin?.email}</span>
              )}
            </Flexbox>
          </Flexbox>
        </div>
        <Alert alert>
          <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
          <span>
            <b>Atenção!</b> Não realize nenhuma mudança na origem e no destino
            até a finalização da sua migração.
          </span>
        </Alert>
        <ButtonsContainer>
          <Button onClick={goBackFunc} style={{ fontSize: '14px' }} text>
            Voltar
          </Button>
          <Button
            onClick={initMigration}
            style={{ fontSize: '16px', marginLeft: '0', textTransform: 'none' }}
            width="140px"
            icon
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Migrar'}
          </Button>
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default Confirmation;
