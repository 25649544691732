import axios from 'axios';

const validation =
  process.env.REACT_APP_FRIDAY_URL === 'https://dev.friday.cloudez.me/api' ||
  process.env.NODE_ENV === 'development';

const config = validation
  ? {
      Authorization: `Token ${process.env.REACT_APP_FRIDAY_TOKEN}`,
      'x-jarvis': 'god_role;1;1',
    }
  : {};

const friday = axios.create({
  baseURL: process.env.REACT_APP_FRIDAY_URL,
  headers: config,
});

export const removeBackupService = async (sync: number): Promise<void> => {
  try {
    return await friday.post(`/sync/${sync}/run/`);
  } catch (e) {
    throw e;
  }
};

export const changeStatusMigrationService = async (id: number, params: any) => {
  try {
    return await friday.patch(`/migration/${id}/`, params);
  } catch (e) {
    throw e;
  }
};

export const ratingMigrationService = async (
  id: number,
  payload: any,
): Promise<void> => {
  try {
    return await friday.post(`/migration/${id}/rate/`, payload);
  } catch (e) {
    throw e;
  }
};

type MigrationAssistedPayload = {
  assisted_info: string;
  assisted_targets: string;
  assisted_value: number;
  chooseCheckbox: string;
  domain: string;
  migration_type: number;
  user_id: number;
};

export const createMigrationAssistedService = async (
  payload: MigrationAssistedPayload,
): Promise<{ data: any }> => {
  try {
    return await friday.post(`/migration/assisted/`, payload);
  } catch (e) {
    throw e;
  }
};

type ValidateRemotePayload = {
  domain: string;
  is_sftp: boolean;
  remote_host: string;
  remote_password: string;
  remote_port: number;
  remote_user: boolean;
  ssh_path: string;
};

export const validateRemoteService = async (
  payload: ValidateRemotePayload,
): Promise<{ data: any }> => {
  try {
    return await friday.post(
      `/validate${validation ? '-remote' : ''}/`,
      payload,
    );
  } catch (e) {
    throw e;
  }
};

export const validateDomainService = async (
  domain: string,
): Promise<{ data: any }> => {
  try {
    return await friday.post(`/validate-domain/`, { domain });
  } catch (e) {
    throw e;
  }
};

type Account = {
  username: string;
  password: string;
};

export const validateImapService = async (params: {
  src_email_host: string;
  imap_port: number;
  accounts: Account[];
}): Promise<void> => {
  try {
    return await friday.post(`/validate-imap/`, params);
  } catch (e) {
    throw e;
  }
};

export const createMigrationEmailService = async (
  payload: any,
): Promise<{ data: any }> => {
  try {
    return await friday.post(`/migration/email/`, payload);
  } catch (e) {
    throw e;
  }
};

export const createMigrationWebsiteService = async (
  payload: any,
): Promise<{ data: any }> => {
  try {
    return await friday.post(`/migration/website/`, payload);
  } catch (e) {
    throw e;
  }
};

export const addTicketToMigrationService = async (
  id: number,
  params: { ticket_id: number },
): Promise<{ data: any }> => {
  try {
    return await friday.post(`/migration/${id}/add-ticket/`, params);
  } catch (e) {
    throw e;
  }
};

export const getMigrationsService = async (
  params: any,
): Promise<{ data: any }> => {
  try {
    return await friday.get(`/migrations/`, { params });
  } catch (e) {
    throw e;
  }
};

export const confirmMigrationService = async (
  id: number,
): Promise<{ data: any }> => {
  try {
    return await friday.post(`/migration/${id}/validate/`);
  } catch (e) {
    throw e;
  }
};

export const getStatusMigrationService = async (id: number) => {
  try {
    return await friday.get(`/migration/${id}/status/`);
  } catch (e) {
    throw e;
  }
};

export default friday;
