import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import { setLoading, getTransfers, updateTransfer } from './actions';

interface TransfersState {
  transfers: any[];
  count: number;
  previous: number;
  current: number;
  next: number;
  loading: boolean;
  errors: any;

  setLoading: (value: boolean) => void;
  updateTransfer(field: any, value: string, id: string | number): Promise<void>;
  getTransfers(
    page: number,
    de: string,
    ate: string,
    order: any,
    ctrl: AbortController,
  ): Promise<void>;
}

// initial state
const initialState: TransfersState = {
  transfers: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,

  setLoading: (): void => {},
  getTransfers: async () => {},
  updateTransfer: async () => {},
};

// context
export const TransferListingContext = createContext(initialState);

// provider
export const TransferListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TransferListingContext.Provider
      value={{
        // state
        transfers: state.transfers,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getTransfers: async (page, de, ate, order, ctrl) =>
          await getTransfers(dispatch, page, de, ate, order, ctrl),
        updateTransfer: async (field, value, id) =>
          await updateTransfer(dispatch, field, value, id),
      }}
    >
      {children}
    </TransferListingContext.Provider>
  );
};
