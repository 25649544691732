import styled, { css } from 'styled-components';

interface IWebsiteCardProps {
  cloud?: any;
  selected?: boolean;
}
export const WebsiteCard = styled.div<IWebsiteCardProps>`
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;

  span {
    font-size: 14px;
    color: ${props => props.theme.interface5};
    max-width: 99%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 10px;
    max-width: 99%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.interface5};
  }

  &:hover {
    border: 1px solid ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.selected &&
    css`
      border: 1px solid ${props => props.theme.brand_primary_color};
    `}
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

export const Logo = styled.div`
  padding: 0px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px solid ${props => props.theme.interface4}; */
`;

export const Disk = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;
