import { useEffect, useState, useMemo, useContext, useCallback } from 'react';

import { useSpring, animated } from 'react-spring';

import { ThemeContext, withTheme } from 'styled-components';
import * as Yup from 'yup';

import history from 'services/history';

import { Button } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import md5 from 'md5';

import { useAuth, useDocTitle } from 'hooks';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CryptoJS from 'crypto-js';

import { CustomInput } from 'components/NewComponents';
import cloudez from 'assets/img/svg/cloudez-partners-negativo.svg';
import {
  Content,
  Wrapper,
  CheckboxWrapper,
  CheckboxText,
  SocialIcons,
} from '../styles';

const schema = Yup.object().shape({
  email: Yup.string().required('E-mail obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
});

const SignIn = ({ theme }) => {
  useDocTitle('Login — Cloudez Partners');
  const [storedEmail, setStoredEmail] = useState<string | null>(null);

  const { signIn, signInError, authLoading } = useAuth();
  const [rememberMe, setRememberMe] = useState(false);

  const encryptionKey = '4877dea0c54f06454b6a030f8878a79e';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem('email');
    setStoredEmail(emailFromLocalStorage);

    const storedEmail = localStorage.getItem('email');
    const encryptedPassword = localStorage.getItem('password');

    if (encryptedPassword) {
      const bytes = CryptoJS.AES.decrypt(encryptedPassword, encryptionKey);
      const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);

      if (storedEmail && decryptedPassword) {
        setRememberMe(true);
        setValue('email', storedEmail);
        setValue('password', decryptedPassword);
      }
    }
  }, [setValue]);

  const avatar = useMemo(() => {
    if (storedEmail) {
      return `https://www.gravatar.com/avatar/${md5(storedEmail)}?&d=mp&s=300`;
    }
    return `https://www.gravatar.com/avatar/?d=mp&s=300`;
  }, [storedEmail]);

  const openSocialMedia = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const [showPw, setShowPw] = useState(false);

  const props1 = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const onSubmit = data => {
    // dispatch(signInRequest(data.email, data.password));

    const encryptedPassword = CryptoJS.AES.encrypt(
      data.password,
      encryptionKey,
    ).toString();
    if (rememberMe) {
      localStorage.setItem('email', data.email);
      localStorage.setItem('password', encryptedPassword);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }

    signIn(data.email, data.password);
  };

  useEffect(() => {
    if (
      signInError === 'User has 2FA enabled' ||
      signInError === 'Verification code sent in email'
    ) {
      history.push('login/2fa');
    }
  }, [signInError]);

  return (
    <Wrapper>
      <Content>
        {theme.loading ? (
          <FontAwesomeIcon icon={faSpinner} spin size="4x" />
        ) : (
          <>
            <animated.div
              style={props1}
              // immediate={theme.loading.toString()}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={cloudez} alt="logo" />
                <img
                  src={avatar}
                  alt="UserAvatar"
                  style={{
                    borderRadius: '20px',
                    width: '120px',
                    height: '120px',
                    marginBottom: '-120px',
                    zIndex: 1,
                    border: `6px solid ${theme.interface1}`,
                  }}
                />
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomInput
                  mb="20px"
                  autoFocus
                  block
                  name="email"
                  label="E-mail:"
                  register={register}
                  error={errors?.email?.message}
                  placeholder="Digite seu e-mail"
                  height="40px"
                />
                <CustomInput
                  mb="20px"
                  block
                  name="password"
                  register={register}
                  error={errors?.password?.message}
                  type={showPw ? 'text' : 'password'}
                  icon={{
                    svg: !showPw ? faEye : faEyeSlash,
                    action: () => setShowPw(!showPw),
                    isRight: true,
                  }}
                  label="Senha:"
                  placeholder="Digite sua senha"
                  height="40px"
                />

                <CheckboxWrapper>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <CheckboxText>
                    <span className="remember-me-text">Lembrar-me</span>
                  </CheckboxText>
                </CheckboxWrapper>

                <Button
                  disabled={authLoading}
                  icon
                  width="100%"
                  height="50px"
                  type="submit"
                >
                  {authLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Entrar'
                  )}
                </Button>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                    marginTop: '60px',
                    borderTop: `2px solid ${theme.interface3}`,
                  }}
                >
                  <SocialIcons>
                    <div
                      onClick={() =>
                        openSocialMedia(
                          'https://www.linkedin.com/company/cloudez/',
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        className="social-icon-linkedin"
                        size="2x"
                      />
                    </div>
                  </SocialIcons>

                  <SocialIcons>
                    <div
                      onClick={() =>
                        openSocialMedia('https://www.instagram.com/cloudezbr/')
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="social-icon-instagram"
                        size="2x"
                      />
                    </div>
                  </SocialIcons>

                  <SocialIcons>
                    <div
                      onClick={() =>
                        openSocialMedia('https://www.facebook.com/cloudezbr')
                      }
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="social-icon-facebook"
                        size="2x"
                      />
                    </div>
                  </SocialIcons>

                  <SocialIcons>
                    <div
                      onClick={() =>
                        openSocialMedia('https://twitter.com/cloudez_io')
                      }
                    >
                      <FontAwesomeIcon
                        icon={faXTwitter}
                        className="social-icon-twitter"
                        size="2x"
                      />
                    </div>
                  </SocialIcons>
                </div>
                {/* <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '14px',
                  fontSize: '12px',
                }}
              >
                <p>
                  <Link to="/register">Criar minha conta</Link>
                </p>
              </div> */}
              </form>
            </animated.div>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default withTheme(SignIn);
