import React from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { transparentize } from 'polished';
import { useLayout } from 'hooks/layout';

const LoadingIndicator = () => (
  <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '1rem' }} />
);

const MultiValue = props => {
  const theme = useLayout();

  return (
    <components.MultiValue {...props}>
      <p
        style={{
          display: 'inline',
          color: theme.darkMode ? theme.interface5 : theme.brand_primary_color,
          marginRight: 5,
        }}
      >
        {props.data.label}:
      </p>{' '}
      {props.data.fieldValue}
    </components.MultiValue>
  );
};

const reactSelectOptions = props => ({
  styles: {
    container: provided => ({
      ...provided,
      fontSize: '14px',
    }),
    placeholder: provided => ({
      ...provided,
      color: props.theme.interface4,
    }),
    valueContainer: provided => ({
      ...provided,
      flexWrap: 'nowrap',
      maxWidth: '80%',
      overflowX: 'hidden',
    }),
    control: (provided, state) => {
      const style = {
        ...provided,
        boxShadow: '0',
        border: props.error
          ? '2px solid #FB264B'
          : state.isFocused
          ? `2px solid ${props.theme.brand_primary_color}`
          : `2px solid ${
              props.theme.darkMode
                ? props.theme.interface6
                : props.theme.interface3
            }`,
        transition: state.isFocused ? 'border 0.2s ease-in-out' : '',
        ':hover': {
          border: props.error
            ? '2px solid #FB264B'
            : state.isFocused
            ? `2px solid ${props.theme.brand_primary_color}`
            : `2px solid ${
                props.theme.darkMode
                  ? props.theme.interface2
                  : props.theme.interface3
              }`,
        },
        borderRadius: state.menuIsOpen ? ' 5px 5px 0 0' : '5px',
        backgroundColor: `${
          props.isDisabled && !props.theme.darkMode
            ? props.theme.interface2
            : props.theme.interface1
        }`,
        paddingLeft: props.isSearch ? 30 : 0,
        maxHeight: 42,
      };
      return style;
    },
    singleValue: provided => ({
      ...provided,
      color: `${
        props.theme.darkMode ? props.theme.interface4 : props.theme.interface5
      }`,
    }),
    clearIndicator: styles => ({
      ...styles,
      color: props.theme.interface5,
    }),
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: transparentize(
          0.9,
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color,
        ),
        borderRadius: '5px',
        padding: '4px',
        color: props.theme.interface5,
        textOverflow: 'unset !important',
        minWidth: 'unset',
        '>div': {
          textOverflow: 'unset',
        },
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: props.theme.interface5,
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: props.theme.interface5,
    }),
    multiValueContainer: styles => ({
      ...styles,
      width: 600,
    }),
    menu: provided => ({
      ...provided,
      marginTop: '-1px',
      borderRadius: '0 0 5px 5px',
      border: `1px solid ${props.theme.brand_primary_color}`,
      borderTop: `1px solid ${props.theme.brand_primary_color}`,
      backgroundColor: `${props.theme.interface1}`,
      boxShadow: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      ':hover': {
        backgroundColor: transparentize(
          0.85,
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color,
        ),
      },
      backgroundColor: state.isFocused
        ? transparentize(
            0.85,
            props.theme.darkMode
              ? props.theme.interface5
              : props.theme.brand_primary_color,
          )
        : props.theme.interface1,
      color: props.theme.darkMode
        ? props.theme.interface4
        : props.theme.interface7,
      padding: '12px 30px',
    }),
    input: provided => ({
      ...provided,
      color: props.theme.darkMode
        ? props.theme.interface4
        : props.theme.interface5,
    }),
  },
  isClearable: true,
  defaultOptions: true,
  components: {
    LoadingIndicator,
    MultiValue,
    IndicatorSeparator: () => null,
  },
  loadingMessage: () => 'Carregando...',
});

export const StyledAsyncSelect = styled(AsyncSelect).attrs(
  reactSelectOptions,
)``;

export const StyledSelect = styled(Select).attrs(reactSelectOptions)``;
