/* eslint-disable react/no-children-prop */
import { memo, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { useMeasure } from 'utils/useMeasure';

interface ITabProps {
  name: any;
  children: any;
  defaultOpen: boolean;
}

export default memo(
  ({ name: Name, children, defaultOpen = false }: ITabProps) => {
    const [isOpen, setOpen] = useState(defaultOpen);

    const [bind, { height: viewHeight }] = useMeasure();

    const { height, opacity } = useSpring<any>({
      from: { height: 0, opacity: 0 },
      to: {
        height: isOpen ? viewHeight : 0,
        opacity: isOpen ? 1 : 0,
      },
    });

    return (
      <li>
        <Name
          style={{
            position: 'relative',
          }}
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        />

        <animated.div
          style={{
            zIndex: '-1',
            opacity,
            height: isOpen ? 'auto' : height,
          }}
          {...bind}
          children={children}
        />
      </li>
    );
  },
);

export const Content = styled(animated.div)`
  will-change: height, transform, opacity;
`;
