import { createContext, useReducer } from 'react';

import { Customers } from 'types/customers';

import reducer from './reducer';
import { setLoading, getCustomers, updateCustomer } from './actions';

export interface CustomerListingState {
  customers: Customers[];
  count: number;
  previous: number;
  current: number;
  next: number;
  loading: boolean;
  errors: any;

  setLoading(value: boolean): void;
  getCustomers(
    page: number,
    search: { page: number },
    ctrl: AbortController,
  ): Promise<void>;
  updateCustomer(
    field: string,
    value: boolean,
    id: string | number,
  ): Promise<void>;
}

// initial state
const initialState: CustomerListingState = {
  customers: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,

  setLoading: (): void => {},
  getCustomers: async () => {},
  updateCustomer: async () => {},
};

// context
export const CustomerListingContext =
  createContext<CustomerListingState>(initialState);

// provider
export const CustomerListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CustomerListingContext.Provider
      value={{
        // state
        customers: state.customers,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getCustomers: async (page, search, ctrl) =>
          await getCustomers(dispatch, page, search, ctrl),
        updateCustomer: async (field, value, id) =>
          await updateCustomer(dispatch, field, value, id),
      }}
    >
      {children}
    </CustomerListingContext.Provider>
  );
};
