import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { Input, Label, Field } from 'components/Input';
import copy from 'copy-to-clipboard';
import { useAuth } from 'hooks';

const InformationModal = ({ show, setShow, invoice }) => {
  const { company } = useAuth();

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faBarcode} />
        </Icon>
        <Text>
          <Title>Informações da fatura</Title>
        </Text>
      </Header>

      <Content>
        {company.status === 2 && (
          <Field>
            <Label htmlFor="linkInput">Link do boleto</Label>
            <Input
              id="linkInput"
              style={{
                paddingRight: '30px',
              }}
              name="name"
              background
              block
              value={`https://${company.app_domains[0]}/invoices/bank-slip/${invoice.public_id}/`}
            />
            <FontAwesomeIcon
              style={{
                cursor: 'pointer',
              }}
              icon={faCopy}
              onClick={e => {
                copy(
                  `https://${company.app_domains[0]}/invoices/bank-slip/${invoice.public_id}/`,
                );
              }}
            />
          </Field>
        )}

        <Field>
          <Label htmlFor="external">Acesso externo</Label>
          <Input
            id="external"
            style={{
              paddingRight: '30px',
            }}
            name="name"
            background
            block
            value={`https://${
              company.status === 2 ? company.app_domains[0] : 'beta.cloudez.io'
            }/invoices/external/${invoice.public_id}/`}
          />
          <FontAwesomeIcon
            style={{
              cursor: 'pointer',
            }}
            icon={faCopy}
            onClick={e => {
              copy(
                `https://${
                  company.status === 2
                    ? company.app_domains[0]
                    : 'beta.cloudez.io'
                }/invoices/external/${invoice.public_id}/`,
              );
            }}
          />
        </Field>
      </Content>
      <Footer>
        <Button onClick={() => setShow()}>Ok</Button>
      </Footer>
    </Modal>
  );
};

export default InformationModal;
