import api from 'services/api';
import Axios from 'axios';

export const getTicketsService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/ticket/', { params, signal: ctrl.signal } as any);
  } catch (e) {
    throw e;
  }
};

export const getMessagesService = async params => {
  try {
    return await api.get('/v3/message/', { params });
  } catch (e) {
    throw e;
  }
};

export const getTicketService = async (id: number | string, params?) => {
  try {
    return await api.get(`/v3/ticket/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const updateTicketService = async (
  id: number | string,
  data: any,
  params?: any,
): Promise<any> => {
  try {
    return await api.patch(`/v3/ticket/${id}/`, data, { params });
  } catch (e) {
    throw e;
  }
};

export const createTicketService = async payload => {
  try {
    return await api.post('/v3/ticket/', payload);
  } catch (e) {
    throw e;
  }
};

export const createTicketMessageService = async payload => {
  try {
    return await api.post('/v3/message/', payload);
  } catch (e) {
    throw e;
  }
};

export const getTicketIssuesService = async (params?) => {
  try {
    return await api.get('/v3/ticket-issue/', { params });
  } catch (e) {
    throw e;
  }
};

export const getTicketRatingService = async (authenticated, hash) => {
  const apiUrl =
    window.location.hostname === 'demo.cloudez.io'
      ? 'https://dev-api.cloudez.io/'
      : process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : 'https://api.cloudez.io/';

  try {
    return authenticated
      ? await api.get(`/v3/ticket-rating/${hash}/`)
      : await Axios.get(`${apiUrl}v3/ticket-rating/${hash}/`);
  } catch (e) {
    throw e;
  }
};

export const updateTicketRatingService = async (
  authenticated,
  hash,
  rating,
) => {
  const apiUrl =
    window.location.hostname === 'demo-partners.cloudez.io'
      ? 'https://dev-api.cloudez.io/'
      : process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : 'https://api.cloudez.io/';

  try {
    return authenticated
      ? await api.patch(`/v3/ticket-rating/${hash}/`, rating)
      : await Axios.patch(`${apiUrl}v3/ticket-rating/${hash}/`, rating);
  } catch (e) {
    throw e;
  }
};

export const requestHelpService = async (id: number): Promise<any> => {
  try {
    return await api.post(`/v3/ticket/${id}/ask_help/`);
  } catch (e) {
    throw e;
  }
};
