import {
  faCheckCircle,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Breadcrumbs, Header, Title } from 'components/Common';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import * as Styles from '../Stamps/styles';

const StampBenefits = () => {
  const stampLevels = useMemo(
    () => [
      {
        name: 'partner',
        threshold: '1 Cloud ativo',
        color: 'rgba(34, 83, 245, 0.3)',
        benefits: [
          'Acesso ao painel whitelabel',
          'Utilização ilimitada das automações de processos de hospedagem e financeiro',
          'Migração automatizada gratuita de websites e e-mails',
          'Acesso aos cursos básicos e avançados da Cloudez Academy',
          'Acesso aos materiais do Toolkit básico (cases de sucesso, materiais comerciais, institucionais, minuta de contrato, etc)',
          'Acesso a um selo da jornada de parceiros',
        ],
      },
      {
        name: 'certified',
        threshold: '2-5 Clouds ativos',
        color: 'rgba(138, 236, 31, 0.3)',
        min: 2,
        benefits: [
          'Benefícios do Selo Partner',
          'Disponibilidade de compra de 01 cloud volume (cloud compartilhado, para educação de clientes)',
          'Acesso ao selo via link validado para utilização em seu website',
          'Acesso aos canais e eventos da Comunidade Cloudez Partners',
          'Serviço cadastrado no ecossistema de parceiros para divulgação e recebimento de leads qualificados',
          'Acesso aos materiais do Toolkit comercial (apresentação comercial, argumentos comparativos de tecnologia, landing page, etc)',
          'Custo para realização de automação pocket de template próprio: R$ 1.500',
        ],
      },
      {
        name: 'master',
        threshold: '6-30 Clouds ativos',
        color: 'rgba(255, 191, 45, 0.3)',
        min: 6,
        benefits: [
          'Benefícios dos Selos Partner + Certified',
          'Disponibilidade de compra de até 02 cloud volume (cloud compartilhado, para educação de clientes) disponíveis para o parceiro',
          'Serviço cadastrado no ecossistema de parceiros para divulgação e recebimento de leads qualificados',
          'Acesso aos materiais do Toolkit de gestão (modelos de processos, checklists de execução, etc)',
          'Postagem de um case de sucesso seu junto da Cloudez no blog',
          'Recebimento do kit de marca da Cloudez',
          'Grupo de WhatsApp com consultores especializados para atendimento personalizado',
          'Ações de marketing conjunto em webinários, postagens, campanhas, etc',
          'Apoio comercial de consultores especializados para realização de vendas',
          'Custo para realização de automação pocket de template próprio: R$ 1.000',
        ],
      },
      {
        name: 'expert',
        threshold: '31-99 Clouds ativos',
        color: 'rgba(250, 102, 40, 0.3)',
        min: 31,
        benefits: [
          'Benefícios dos Selos Partner + Certified + Master',
          'Disponibilidade de compra de até 03 cloud volume (cloud compartilhado, para educação de clientes) disponíveis para o parceiro',
          'Camada de precificação disponível: Escala (1GB - R$ 69)',
          'Custo para realização de automação pocket de template próprio: R$ 700',
        ],
      },
      {
        name: 'exclusive',
        threshold: '100+ Clouds ativos',
        color: 'rgba(127, 156, 199, 0.3)',
        min: 100,
        benefits: [
          'Benefícios dos Selos Partner + Certified + Master + Expert',
          'Disponibilidade de compra de até 03 cloud volume (cloud compartilhado, para educação de clientes) disponíveis para o parceiro',
          'Camada de precificação disponível: Negociado com consultor comercial da Cloudez',
          'Custo para realização de automação pocket de template próprio: Negociado com consultor comercial da Cloudez',
        ],
      },
    ],
    [],
  );
  return (
    <>
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/stamps">Selos</Link>
            </Breadcrumb>
          </Breadcrumbs>
          Conheça os selos
        </Title>
      </Header>
      {stampLevels.map(level => {
        return (
          <Styles.Card className="mb-5" sectioned vertical>
            <Styles.CardTitle style={{ width: '100%' }}>
              {level.name}
            </Styles.CardTitle>
            <div className="section d-flex">
              <Styles.CurrentStamp currentStampName={level.name} />
              <Styles.BenefitsList>
                <Styles.BenefitsTitle>
                  Benefícios Garantidos
                </Styles.BenefitsTitle>
                {level.benefits.map((benefit, index) => (
                  <div key={index}>
                    <Styles.Benefit className="mb-2">
                      <FontAwesomeIcon icon={faCheckCircle} /> {benefit}
                    </Styles.Benefit>
                  </div>
                ))}
              </Styles.BenefitsList>
            </div>
          </Styles.Card>
        );
      })}
    </>
  );
};

export default StampBenefits;
