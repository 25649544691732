import React from 'react';

import { Button } from '@cloudez/cloudez-design-system';

import history from 'services/history';
import { Header, Title } from 'components/Common';
import { useDocTitle } from 'hooks';
import migAssist from 'assets/img/svg/nmig_assist.svg';
import migEmail from 'assets/img/svg/nmig_email.svg';
import migSite from 'assets/img/svg/nmig_site.svg';

import { MigrationWrapper, MigrationSelect, AssistedMigration } from './styles';

const MigrationCreateNew = () => {
  useDocTitle('Migrações - Cloudez Partners');

  return (
    <>
      <Header>
        <Title>Nova Migração</Title>
      </Header>

      <MigrationWrapper>
        <MigrationSelect>
          <img src={migSite} alt="migsite" />
          <p>Migre sites e aplicações com facilidade.</p>
          <Button onClick={() => history.push('/migrations/create/website')}>
            MIGRAÇÃO DE SITE
          </Button>
        </MigrationSelect>
        <MigrationSelect>
          <img src={migEmail} alt="migemail" />
          <p>Migre contas de e-mail para nosso provedor.</p>
          <Button onClick={() => history.push('/migrations/create/email')}>
            MIGRAÇÃO DE EMAIL
          </Button>
        </MigrationSelect>
      </MigrationWrapper>
      <AssistedMigration>
        <img src={migAssist} alt="migassist" />
        <div className="textContainer">
          <div className="textContent">
            <b>Deixe sua migração 100% nas mãos dos especialistas.</b>
            <span className="subtitle">
              Mais tranquilidade enquantos trazemos suas migrações para o Cloud
            </span>
          </div>
          <p>
            Se você deseja otimizar ainda mais seu tempo, deixe a migração de
            suas aplicações e e-mails nas mãos do nosso time de especialistas.
            Com a comodidade adicional da Migração Assistida, todo o processo é
            feito pela equipe para que você tenha mais tranquilidade e tempo
            para cuidar do que realmente importa: seus clientes.
          </p>
          <span className="alert">
            *esta modalidade está sujeita à uma taxa e é exclusiva para
            migrações que tem a Cloudez como destino, não funcionando para
            migrações de saída da Cloudez.
          </span>
          <Button
            onClick={() => history.push('/migrations/create/assisted')}
            outline
          >
            MIGRAÇÃO ASSISTIDA
          </Button>
        </div>
      </AssistedMigration>
    </>
  );
};

export default props => <MigrationCreateNew {...props} />;
