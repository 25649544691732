export const customMessages = [
  {
    shortcut: 'ataque-ssh',
    value:
      'Seu servidor estava sendo escaneado por vários servidores zumbis que estavam tentando se conectar no seu SSH. Isso é algo muito comum, e provavelmente você não era um alvo específico, apenas mais um numa multidão de possíveis vítimas. \n\n O que esse pessoal costuma fazer é invadir alguns servidores, transformar em servidores zumbis que executam ordens em massa que passam a tentar se conectar em outros servidores na porta 22 (SSH e SFTP) dezenas ou centenas de vezes por segundo. Caso você tenha algum usuário com nome comum e senha fraca, eventualmente eles conseguem acesso ao seu servidor e ele passa a ser um zumbi também, fazendo a mesma coisa contra outras vítimas. \n\n Nós normalmente combatemos isso com o Fail2Ban, um firewall que fica analisando as tentativas de login e bane os IPs que erram a senha repetidamente em pouco tempo. Isso é eficiente para bloquear pessoas mal intecionadas que estejam tentando descobrir sua senha em particular, mas quando está lidando com centenas ou milhares de servidores zumbi, isso gera um grande estresse no servidor. A outra forma de combater isso é fechando completamente a porta 22, e foi o que fizemos no seu servidor. Agora você vai poder se conectar no SFTP ou SSH apenas pelas portas: 22122, 22000 ou 2222.',
  },
  {
    shortcut: 'kernel',
    value:
      'Recentemente, em 14 de maio de 2019, a Intel divulgou publicamente uma vulnerabilidade em seus processadores que ficou conhecida como “ZombieLoad”. A falha ocorre na "execução especulativa", uma função dos processadores que tenta adivinhar o que vai ser executado e antecipar a operação, a fim de ganhar desempenho e entregar os resultados mais rápido. Infelizmente, ao fazer isso, o processador precisa armazenar por um curto período de tempo as informações dessa operação, o que abre uma brecha para que esses dados sejam lidos por um atacante que saiba pelo que procurar, como por exemplo, senhas, documentos privados, chaves de criptografia, etc. \n\n A fim de mitigar eventuais riscos que possam afetar nossos servidores e clientes, o datacenter que utilizamos aplicou uma série de atualizações no núcleo (kernel) do sistema operacional de todos os clouds. Infelizmente parte dessa atualização conflita com um conjunto específico de configurações fazendo com que, nesses casos, o processamento do servidor aumente podendo causar lentidão ou travamentos. \n\n Nossa time de infraestrutura está trabalhando para corrigir essa situação mas em carater emergêncial estamos voltando seu ambiente para uma versão anterior do kernal que não possui as últimas atualizações. Também estamos em contato com o datacenter investigando a causa da sobrecarga gerada por essa atualização, evitando assim que esse incidente se repita novamente no futuro.',
  },
  { shortcut: 'vamos', value: 'Beleza! \n\nVamos migrar!' },

  {
    shortcut: 'fila',
    value:
      'Olá olá! \n\nSua migração foi colocada na fila :) \n\nEu te aviso aqui quando for finalizada ou tiver alguma outra novidade!',
  },

  {
    shortcut: 'imap-auth',
    value:
      'Houve um problema durante a migração do seu email. \n\nQuando rodamos os comandos tivemos erros com a autenticação do usuário de origem, o que significa que foram fornecidos dados de login ou senha incorretos no seu email de origem. \n\nVocê poderia nos mandar novamente os dados de acesso dos emails, assim como o IMAP host que você deseja que seja migrado? \n\nQualquer dúvida estamos por aqui para ajudar!',
  },

  {
    shortcut: 'finalizar',
    value:
      'Posso ajudar com alguma outra coisa ou posso finalizar esse ticket?',
  },

  {
    shortcut: 'info',
    value:
      'Você pode pedir o processo de migração de forma automática pelo nosso painel :slightly_smiling_face: \n\nSe quiser saber mais sobre o nosso sistema de migração, temos este manual: http://ajuda.configr.com/processo-migracao/ \n\nTemos este aqui para te auxiliar no processo de migração dos seus websites: http://ajuda.configr.com/como-migrar-websites/ E temos este para te auxiliar na migração dos seus e-mails: http://ajuda.configr.com/como-migrar-emails/ Qualquer dúvida, é só entrar em contato com nossa equipe!',
  },

  {
    shortcut: 'confirmar',
    value: 'Poderia confirmar se a sua aplicação foi migrada como o esperado?',
  },

  {
    shortcut: 'ip-site',
    value:
      'Bom dia, \n\nVerificamos que você já realizou o apontamento do DNS dessa aplicação de e-mail para nossos servidores, \n\nPara que a migração seja concluída, podeira nos passar o IP do servidor antigo para que possamos dar continuidade ao processo?',
  },

  {
    shortcut: 'email-done',
    value:
      'As contas de email de "DOMINIO" já foram migradas e podem ser testadas pelo link temporário do webmail. \n\nTeste todas as contas e verifique se as mensagens estão todas lá, beleza? \n\nLink do webmail: http://webmail.FQDN',
  },

  {
    shortcut: 'site-done',
    value:
      'Olá, \n\nO seu site DOMINIO acabou de ser migrado e está pronto para ser validado. Verifique se está tudo certinho pelo link temporário e nos dê um feedback :) \n\nlink temporário: LINKTEMPORARIO.',
  },

  {
    shortcut: 'verificando',
    value:
      'Olá, nossa equipe já está verificado sua questão! Assim que tiver mais detalhes atualizo aqui!',
  },

  { shortcut: 'fim', value: 'Pode confirmar se está tudo certo?' },

  { shortcut: 'fim1', value: 'Poderia te ajudar em algo mais?' },

  { shortcut: 'fim2', value: 'Conseguiu verificar a questão?' },

  {
    shortcut: 'final',
    value:
      'Olá, poderia me informar se está tudo certo e podemos fechar o ticket?',
  },

  {
    shortcut: 'comeco',
    value:
      'Estou cuidando da sua questão e assim que for possível eu te retorno por aqui mesmo.',
  },

  {
    shortcut: 'comeco2',
    value:
      'Olá, estou dando uma olhada aqui e vou te manter atualizado sobre o que está ocorrendo.',
  },

  {
    shortcut: 'auth_ftp',
    value:
      'Nosso sistema não está conseguindo ter acesso ao ftp com os dados que foram informados. \n\nPoderia verificar se os dados estão corretos, ou se você ainda tem acesso a esse ftp com essas credenciais?',
  },

  {
    shortcut: 'dns_apontando',
    value:
      'Olá. \n\nVerifiquei que já apontou o DNS dessa aplicação, devido a esse apontamento precisamos que nos informe o IP do seu server para onde sua aplicação estava apontando anteriormente.',
  },

  {
    shortcut: 'fechar',
    value:
      'Marquei esse ticket como fechado. \n\nCaso tenha alguma questão adicional continuo à disposição, basta enviar uma mensagem que o ticket vai ser aberto novamente.',
  },

  {
    shortcut: 'pedir_banco',
    value:
      'Os arquivos da sua aplicação já estão no seu cloud, mas o Domo não conseguiu pegar o seu banco de dados.\nVocê poderia me passar os dados de acesso ao seu painel de "admin" de onde sua aplicação está hospedada atualmente ou me passar o banco de dados para que possamos dar continuidade à migração?',
  },
  { shortcut: 'novidades', value: 'novidades sobre os dados de acesso ?' },
  {
    shortcut: 'like',
    value:
      'Legal! Que bom que está tudo certo agora 😉 \n\nMe da uma força e avalia o ticket? É importante tanto para a Configr como para mim.\n\nAssim fica mais fácil saber o que você espera de um bom atendimento.\n\nE se precisar de mais alguma coisa, conta comigo, beleza?\n\nAbs.',
  },
  {
    shortcut: 'whitelabel-list',
    value:
      'Olá, seu Whitelabel estará na lista de criação assim que recebermos o conteúdo necessário para sua criação.\n\nPrecisamos de:\n\n- Duas imagens do seu logo, de preferência uma em negativo e uma normal, em png ou svg;\n\n- Uma imagem em png do favicon de seu website;\n\n- Pelo menos um hexa da cor principal que deseja para seu whitelabel. Ex.:#ffffff;\n\n- O domínio que irá utilizar para seu whitelabel, por exemplo "<subdominio>.seudominio.com.br".\n\n- O subdomínio escolhido só poderá utilizar https caso esteja apontando para o ip 138.197.11.106. Caso o domínio principal escolhido já esteja apontando para nós, pode deixar que nós mesmos organizamos essa configuração.\n\n- Criar e nos enviar uma conta de email para: suporte, financeiro, comercial e boas vindas. Para configurarmos elas como seus emails de comunicação com seus clientes.',
  },
  {
    shortcut: 'fechar_like',
    value:
      'Olá! Como não tivemos retorno, deixaremos o ticket fechado. Caso precise, basta responder para que seja reaberto. Ah! Se o atendimento ajudou na solução da questão, nos ajude avaliando positivamente 😉',
  },
  {
    shortcut: 'app_removido',
    value:
      'Verificamos que sua aplicação foi removida, caso ainda queira realizar a migração, peço que crie a aplicação no painel e inicie o processo de migração novamente 😃',
  },
  {
    shortcut: 'pagespeed',
    value:
      'Lembrando que a funcionalidade está liberada em modo BETA, então aproveito para reforçar que contamos com seus feedbacks. Te convido a acompanhar de perto as mudanças no desempenho do seu site e como está sendo a nova experiência.\n\nCaso prefira voltar atrás e esperar para utilizar a versão completa, já finalizada, basta responder este ticket que desabilitamos por aqui.\n\nAbração,',
  },
];

export function getMessage(shortcut) {
  return customMessages.filter(c => c.shortcut === shortcut)[0];
}
