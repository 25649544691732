import { getDashboardService } from 'services/dashboard';

export const getDashboard = async (dispatch, user) => {
  try {
    const { data } = await getDashboardService(user);

    dispatch({
      type: 'GET_DASHBOARD',
      payload: data,
    });
  } catch (e) {}
};

export const setDashboard = (dispatch, value) => {
  dispatch({
    type: 'SET_DASHBOARD',
    payload: value,
  });
};
