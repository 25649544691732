import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 20px 0px;

  button {
    font-size: 13px;
  }

  @media (max-width: 768px) {
    button {
      width: 60px;

      span {
        display: none;
      }

      &::after {
        content: 'Novo';
      }
    }
  }
`;

export const Title = styled.h1`
  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.interface7} !important;
  font-size: 28px;
  font-weight: bold;

  svg {
    color: ${props => props.theme.brand_primary_color} !important;
  }

  p {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.75px;
    margin-top: 10px;
  }

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const TextBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 22px;

  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #64728c;

  .miniBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const FooterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 70px;

  span {
    max-width: 800px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    text-align: center;

    color: #64728c;
  }
`;
