import React, { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faDollar,
  faDollarSign,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
  Content,
} from 'components/ListingModal/styles';
import Modal from 'components/Modal';
import { Button, Field, Input, Label } from '@cloudez/cloudez-design-system';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { useAuth, useLayout } from 'hooks';
import { banks } from 'pages/Company/Form/variables';
import { widthdrawService } from 'services/common';
import history from 'services/history';

const WithdrawModal = ({ show, setShow, companyBank, companyBilling }) => {
  const [loading, setLoading] = useState(false);
  const theme = useLayout();
  const { company } = useAuth();

  const withdraw = async () => {
    setLoading(true);
    try {
      const { data } = await widthdrawService(company.code);
      setLoading(false);
      setShow();
      history.push(`/tickets/${data.ticket}`);
    } catch (e) {
      toast.error(e.response.data.error);
      setLoading(false);
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Solicitar saque</Title>
          <Subtitle>
            Transferência em até 48hs úteis após a confirmação via ticket.
          </Subtitle>
        </Text>
      </Header>

      <Content>
        {companyBilling?.balance?.amount > 0 ? (
          <>
            <Row>
              <Col xs="6">
                <Field>
                  <Label>Valor atual disponível</Label>
                  <Input
                    block
                    disabled
                    background
                    value={`R$ ${companyBilling.balance.amount}`}
                  />
                </Field>
              </Col>
              <Col xs="6">
                <Field>
                  <Label>Taxa cobrada</Label>
                  <div
                    style={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 400,
                      fontSize: '16px',
                      color: theme.interface5,
                    }}
                  >
                    R$ 2,50 / por transação
                  </div>
                </Field>
              </Col>
            </Row>
            <Row
              style={{
                marginBottom: 10,
              }}
            >
              <Col xs="12">
                <Label>Dados bancários para transferência</Label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Field>
                  <Label>Banco</Label>
                  <div
                    style={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 400,
                      fontSize: '16px',
                      color: theme.interface5,
                    }}
                  >
                    {
                      banks.find(b => b.value === companyBank?.bank?.toString())
                        ?.label
                    }
                  </div>{' '}
                </Field>
              </Col>
              <Col xs="4">
                <Field>
                  <Label>Agência</Label>
                  <div
                    style={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 400,
                      fontSize: '16px',
                      color: theme.interface5,
                    }}
                  >
                    {companyBank.bank_ag}
                  </div>{' '}
                </Field>
              </Col>
              <Col xs="4">
                <Field>
                  <Label>Conta</Label>
                  <div
                    style={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 400,
                      fontSize: '16px',
                      color: theme.interface5,
                    }}
                  >
                    {companyBank.bank_cc}
                  </div>{' '}
                </Field>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <Field>
                  <Label>Valor atual disponível</Label>
                  <p
                    style={{
                      marginTop: 20,
                      fontWeight: 400,
                      fontSize: '14px',
                    }}
                  >
                    Atualmente você não possui nenhum valor para saque.
                  </p>
                </Field>
              </Col>
            </Row>
          </>
        )}
      </Content>

      <Footer>
        <Button
          disabled={loading}
          icon={loading ? faSpinner : undefined}
          onClick={withdraw}
        >
          {!loading && 'Solicitar saque'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default WithdrawModal;
