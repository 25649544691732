import React, { createContext, useContext, useState } from 'react';
import { createPagination } from '@cloudez/cloudez-design-system';

import { getCloudsService } from 'services/cloud';
import { getEmailsService } from 'services/email';
import toastError from 'utils/toastError';

const MigCreateEmailContext = createContext({} as any);

type Message = {
  title: string;
  main: string;
  secondary?: string;
};

export function MigCreateEmailProvider({ children }) {
  const [isValidated, setIsValidated] = useState(false);
  const [originData, setOriginData] = useState({});

  const [stage, setStage] = useState({
    existingEmail: false,
    newWebsiteCloud: false,
  });

  const [migErrorMessage, setMigErrorMessage] = useState<Message>();

  const migrationErrors = err => {
    const error = JSON.stringify(err) || '';

    if (error.includes('Erro ao validar domínio')) {
      setMigErrorMessage({
        title: 'Erro ao validar o domínio',
        main: 'Isso pode acontecer por diversos motivos: erro de digitação, domínio expirado, domínio não registrado, domínio inválido, entre outros. Confira seu domínio e tente novamente.',
      });
    } else if (
      error.includes(
        'Estes valores já estão em uso em outro aplicativo neste Node',
      )
    ) {
      setMigErrorMessage({
        title: 'Aplicação já existente no cloud selecionado',
        main: 'Tente substituir o site ou removê-lo e solicitar novamente.',
      });
    } else if (error?.includes('ssh: handshake failed')) {
      setMigErrorMessage({
        title: 'Login ssh incorreto',
        main: 'Tente verificar as credenciais de acesso ao servidor',
      });
    } else if (error?.includes('dial tcp') && error?.includes('timeout')) {
      setMigErrorMessage({
        title: 'Tempo de conexão com o servidor de origem esgotado',
        main: 'Sugestão: Confirmar os dados da migração, depois tentar mudar a porta para 143 ou para a 993.',
        secondary:
          'Caso o erro persista entre em contato com nosso suporte que iremos te auxiliar.',
      });
    } else if (error?.includes('Authentication failed.')) {
      setMigErrorMessage({
        title: 'Usuário ou senha incorretos, ou usuário não existe.',
        main: 'Verifique se não foi um erro de digitação. Caso esteja tudo correto, cheque as senhas no servidor de origem e se necessário redefina a senha.',
      });
    } else if (
      error?.includes('read tcp') &&
      error?.includes('connection reset by peer')
    ) {
      setMigErrorMessage({
        title:
          'Nossos servidores não conseguem acessar o FTP. Abra um ticket com nosso time de suporte que vamos tentar conectar no FTP por um de nossos clouds.',
        main: 'Você pode também solicitar uma migração assistida e nosso time vai cuidar de tudo pra você.',
      });
    } else if (
      error?.includes('dial tcp') &&
      error?.includes('connection refused')
    ) {
      setMigErrorMessage({
        title: 'Conexão com o servidor de origem recusada pelo provedor',
        main: 'Sugestão: Confirmar os dados da migração, depois tentar mudar a porta para 143 ou para a 993.',
        secondary:
          'Caso o erro persista entre em contato com nosso suporte que iremos te auxiliar.',
      });
    } else if (error?.includes('check for file existance')) {
      setMigErrorMessage({
        title:
          'Nossos servidores não conseguem acessar o FTP. Abra um ticket com nosso time de suporte que vamos tentar conectar no FTP por um de nossos clouds.',
        main: 'Você pode também solicitar uma migração assistida e nosso time vai cuidar de tudo pra você.',
      });
    } else if (error?.includes('The upstream server is timing out')) {
      setMigErrorMessage({
        title: 'Tempo de conexão do servidor de origem esgotado.',
        main: 'Sugestão: Tente mudar a porta para 143.',
        secondary:
          'Caso o erro persista entre em contato com nosso suporte que iremos te auxiliar.',
      });
    } else if (error?.includes('No application in path')) {
      setMigErrorMessage({
        title: 'Nenhuma aplicação no diretório informado',
        main: 'Verifique se há arquivos no diretório informado. Se houver verifique se o provedor de origem realizou um bloqueio da migração.',
      });
    } else if (
      error?.includes('There is already a migration for this nodeapp')
    ) {
      setMigErrorMessage({
        title: 'Já existe uma migração para esse website.',
        main: 'Cancele a migração existente ou espere ela finalizar para iniciar uma nova.',
      });
    } else if (error?.includes('There is already a migration for this email')) {
      setMigErrorMessage({
        title: 'Já existe uma migração para esse e-mail.',
        main: 'Cancele a migração existente ou espere ela finalizar para iniciar uma nova.',
      });
    } else if (
      error?.includes(
        'There are more than 2 migration to the same src_host already running',
      )
    ) {
      setMigErrorMessage({
        title: 'Existem mais de duas migrações com a mesma origem em progresso',
        main: 'Aguarde as migrações anteriores serem finalizadas.',
      });
    } else {
      toastError(err);
    }
  };

  const changeStage = (selected?: string) => {
    const newObj = Object.keys(stage).reduce((accumulator, key) => {
      return { ...accumulator, [key]: selected ? key === selected : false };
    }, {}) as {
      existingEmail: boolean;
      newWebsiteCloud: boolean;
    };

    setStage(newObj);
  };

  const [selectedOriginItem, setSelectedOriginItem] = useState({});
  const [selectedDestItem, setSelectedDestItem] = useState({});

  const [emailsOrigin, setEmailsOrigin] = useState({ results: [] });
  const [emailsDest, setEmailsDest] = useState({ results: [] });

  const [clouds, setClouds] = useState<any>([]);
  const [emails, setEmails] = useState<any>({ results: [] });

  const getEmails = async (params?: any) => {
    try {
      const response = await getEmailsService(params);

      const pagination = createPagination(response.data, 4);

      setEmails({
        ...pagination,
        results: response.data.results,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const getClouds = async (params?: any) => {
    try {
      const response = await getCloudsService(params);

      const pagination = createPagination(response.data, 4);

      setClouds({
        ...pagination,
        results: response.data.results,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <MigCreateEmailContext.Provider
      value={{
        getClouds,
        setSelectedOriginItem,
        setSelectedDestItem,
        setEmailsOrigin,
        setEmailsDest,
        setIsValidated,
        setOriginData,
        selectedOriginItem,
        selectedDestItem,
        emailsOrigin,
        emailsDest,
        isValidated,
        originData,
        clouds,

        stage,
        setStage,
        changeStage,

        getEmails,
        emails,
        setEmails,

        migErrorMessage,
        migrationErrors,
      }}
    >
      {children}
    </MigCreateEmailContext.Provider>
  );
}

export const useMigCreateEmail = () => useContext(MigCreateEmailContext);
