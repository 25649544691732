import { ThemeContext } from 'styled-components';
import { useContext } from 'react';

import Partner from 'assets/img/stamps/partner.svg';
import PartnerDark from 'assets/img/stamps/partner-dark.svg';
import Certified from 'assets/img/stamps/certified.svg';
import CertifiedDark from 'assets/img/stamps/certified-dark.svg';
import Master from 'assets/img/stamps/master.svg';
import MasterDark from 'assets/img/stamps/master-dark.svg';
import TablePrices from './Table';

import { plans } from './variables';

import { Container, Title } from './styles';

const Pricing: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      {plans.map(item => {
        const stamp = {
          partner: theme.darkMode ? PartnerDark : Partner,
          certified: theme.darkMode ? CertifiedDark : Certified,
          master: theme.darkMode ? MasterDark : Master,
        }[item.name];
        return (
          <Container
            id={
              item.name === 'partner'
                ? 'partner'
                : item.name === 'certified'
                ? 'certified'
                : 'master'
            }
          >
            <Title>
              <p>Precificação</p>
            </Title>
            <img width="145px" height="145px" src={stamp} alt="Selo Partner" />
            <TablePrices pricing={item.pricing} />
          </Container>
        );
      })}
    </>
  );
};

export default Pricing;
