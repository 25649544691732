import { Checkbox } from '@cloudez/cloudez-design-system';
import { useAuth } from 'hooks';
import { useState } from 'react';
import { updateSettingsService } from 'services/common';
import { Container, Text, Title } from './styles';

const Preferences = (): JSX.Element => {
  const { company, getCompany } = useAuth();

  const [waiting, setWaiting] = useState(false);

  const [values, setValues] = useState({
    auto_apply_credit: company?.settings.auto_apply_credit,
    ticket_disabled: company?.settings.ticket_disabled,
  });

  const onSubmit = async params => {
    try {
      setWaiting(true);

      setValues(params);

      await updateSettingsService(params);

      getCompany();
      setWaiting(false);
    } catch (err) {
      console.log(err);
      setWaiting(false);
    }
  };

  return (
    <Container>
      <Title>
        Sem interações nos tickets
        <Checkbox
          disabled={waiting}
          checked={values.ticket_disabled}
          onChange={e => {
            onSubmit({
              ticket_disabled: e.target.checked,
              auto_apply_credit: values.auto_apply_credit,
            });
          }}
        />
        {values.ticket_disabled && <span className="active">ATIVADO</span>}
      </Title>
      <Text>
        Ao ativar esta opção, a Cloudez <b>NÃO</b> irá interagir com os seus
        clientes em atendimentos via tickets..
      </Text>
      <Title>
        Aplicar crédito automaticamente
        <Checkbox
          disabled={waiting}
          checked={values.auto_apply_credit}
          onChange={e => {
            onSubmit({
              ticket_disabled: values.ticket_disabled,
              auto_apply_credit: e.target.checked,
            });
          }}
        />
        {values.auto_apply_credit && <span className="active">ATIVADO</span>}
      </Title>
      <div>
        <Text>
          Ao desativar esta opção, os créditos de revenda que você possui no seu
          painel não serão mais aplicados automaticamente nas faturas em que
          você é responsável.
        </Text>
        <br />
        <Text>
          <b>Atenção:</b> Uma vez aplicado o crédito na fatura, o mesmo não
          poderá ser estornado.
        </Text>
      </div>
    </Container>
  );
};

export default Preferences;
