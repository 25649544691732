import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.color || props.theme.brand_primary_color};
`;

interface IIconHoverProps {
  icon: any;
}

export const IconHover = styled(FontAwesomeIcon)<IIconHoverProps>`
  display: none;
  color: ${props => props.theme.interface1};
  width: 15px;
  height: 15px;
  background: url(${props => props.icon}) center center no-repeat padding-box;
`;

export const IconAvatar = styled.img`
  width: 0;
  height: 0;
  border-radius: 50%;
`;
