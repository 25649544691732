import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import { Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';

import Modal from 'components/Modal';
import {
  Field,
  Label,
  Input,
  FormSelect,
  Form,
  Select,
} from 'components/Input';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { getTicketIssuesService } from 'services/ticket';
import { getUsersService } from 'services/user';

const issueSchema = Yup.object().shape({
  issue: Yup.string().required('Este campo é obrigatório'),
});

const IssueModal = ({ action, show, setShow, now, ticket }) => {
  const [loading, setLoading] = useState(false);
  const [issues, setIssues] = useState(null);
  const [filteredIssues, setFilteredIssues] = useState(issues);
  const [staffs, setStaffs] = useState(null);
  const [search, setSearch] = useState(null);

  const [issue, setIssue] = useState(ticket?.issue);
  const [whoHelped, setWhoHelped] = useState(ticket?.who_helped);

  const teams = {
    1: 'Sup',
    2: 'Fin',
    3: 'Com',
    4: 'Mig',
    5: 'Spam',
    6: 'Ins',
    7: 'WL',
    0: 'Oth',
  };

  const submitForm = data => {
    setLoading(true);
    action(data.issue, whoHelped, now);
    setLoading(false);
    setIssue(null);
    setWhoHelped(null);
    setShow(false);
  };

  useEffect(() => {
    async function getIssues() {
      try {
        const { data } = await getTicketIssuesService();

        let issues;

        if (ticket.team === 6 || ticket.team === 5) {
          issues = data.filter(item => item.name.startsWith('Sup'));
        } else {
          issues = data.filter(item =>
            item.name.startsWith(teams[ticket.team]),
          );
        }

        setIssues(issues);
        setFilteredIssues(issues);
      } catch (e) {
        console.log(e);
      }
    }

    async function getStaff() {
      try {
        const res = await getUsersService({
          staff: true,
        });

        setStaffs(res.data.results);
      } catch (e) {
        console.log(e);
      }
    }

    getStaff();
    getIssues();
    // eslint-disable-next-line
  }, []);

  const options = filteredIssues?.map(i => ({
    id: i.id,
    title: i.name,
  }));

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faLock} />
        </Icon>
        <Text>
          <Title>Fechar ticket</Title>
          <Subtitle>Descrição do problema</Subtitle>
        </Text>
      </Header>

      <Content>
        <Form id="issueForm" schema={issueSchema} onSubmit={submitForm}>
          <Row>
            <Field>
              <Label>BUSQUE UM PROBLEMA</Label>
              <Input
                block
                value={search}
                autoFocus
                onChange={e => {
                  const filterIssues = issues?.filter(i =>
                    i.name.toLowerCase().includes(e.target.value),
                  );
                  setIssue(filterIssues[0]?.id.toString());
                  setFilteredIssues(filterIssues);
                  setSearch(e.target.value);
                }}
              />
            </Field>
            <Field>
              <Label>QUAL FOI O PROBLEMA NESTE TICKET?</Label>
              {options && (
                <FormSelect
                  placeholder="Selecione uma issue"
                  height="40px"
                  value={issue?.toString()}
                  onChange={e => setIssue(e.target.value)}
                  name="issue"
                  options={options}
                />
              )}
            </Field>
            <Field>
              <Label>ALGUÉM TE AJUDOU NA SOLUÇÃO? </Label>
              <Select
                height="40px"
                value={whoHelped?.toString()}
                onChange={e => setWhoHelped(e.target.value)}
              >
                <option value="">Escolha um usuário</option>
                {staffs?.map(s => (
                  <option key={s.id} value={s.id}>
                    {s.email}
                  </option>
                ))}
              </Select>
            </Field>
          </Row>
        </Form>
      </Content>
      <Footer>
        <Button
          type="submit"
          form="issueForm"
          icon
          // onClick={() => {
          //
          // }}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Fechar ticket'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(IssueModal as any);
