import React, { useEffect, useMemo, useState } from 'react';
import { Input } from '@rocketseat/unform';
import { StyledAsyncSelect, StyledSelect } from './styles';

type SearchSelectProps = {
  placeholder: string;
  name: string;
  defaultValue?: any;
  isSearch?: boolean;
  value?: any;
  onInputChange?: (input: InputEvent) => void;
  onChange?: (event: any, value?: any) => void;
  noOptionsMessage?: any;
  async?: boolean;
  id?: string;
  loadOptions?: any;
  isMulti?: boolean;
  autoFocus?: boolean;
  error?: any;
  isOptionDisabled?: (e: any) => boolean;
  options?: any;
  height?: string;
  getOptionLabel?: (option: any) => void;
  testId?: string;
  isDisabled?: boolean;
};

const SearchSelect: React.FC<SearchSelectProps> = ({
  placeholder,
  loadOptions,
  name,
  defaultValue = null,
  testId,
  noOptionsMessage,
  onChange,
  onInputChange,
  async = true,
  value,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const inputValue = useMemo(() => currentValue?.value, [currentValue]);

  return (
    <>
      {async ? (
        <StyledAsyncSelect
          placeholder={placeholder || '--'}
          loadOptions={
            loadOptions ||
            function () {
              return [];
            }
          }
          onChange={newValue => {
            setCurrentValue(newValue);
            if (onChange) onChange(newValue);
          }}
          value={currentValue}
          noOptionsMessage={() => noOptionsMessage || 'Sem resultados'}
          {...rest}
        />
      ) : (
        <StyledSelect
          placeholder={placeholder || '--'}
          options={loadOptions}
          onChange={(newValue, a) => {
            setCurrentValue(newValue);
            if (onChange) onChange(newValue, a);
          }}
          onInputChange={value => onInputChange && onInputChange(value)}
          noOptionsMessage={() => noOptionsMessage || 'Sem resultados'}
          value={currentValue}
          {...rest}
        />
      )}
      <Input
        data-testid={testId}
        name={name}
        value={inputValue || ''}
        type="hidden"
      />
    </>
  );
};

export default SearchSelect;
