import { Label } from '@cloudez/cloudez-design-system';
import React, { InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Select } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  register?: UseFormRegister<any>;
  error?: any;
  block?: boolean;
  options: { value: string | number; title: string }[];
  value?: any;
  onChange?: any;
  disableOption?: boolean;
  background?: any;
}

export const CustomSelect = ({
  label,
  name,
  register,
  error,
  value,
  onChange,
  options,
  background,
  disableOption,
}: InputProps) => {
  return (
    <Select background={background}>
      <Label>{label}</Label>

      {register ? (
        <select {...register(name)}>
          {options.map(item => (
            <option value={item.value}>{item.title}</option>
          ))}
        </select>
      ) : (
        <select value={value} onChange={onChange}>
          {options.map((item, index) => (
            <option disabled={index === 0 && disableOption} value={item.value}>
              {item.title}
            </option>
          ))}
        </select>
      )}

      {error && <span className="error">{error}</span>}
    </Select>
  );
};
