import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;
  height: auto;
`;

interface IBadgeProps {
  category: number;
}

export const Badge = styled.div<IBadgeProps>`
  width: 64px;
  height: 18px;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  font-size: 9px;
  right: calc(50%+64px);
  color: #fff;
  text-transform: uppercase;

  svg {
    margin-right: 4px;
  }

  background: ${props =>
    props.category === 1
      ? props.theme.brand_secondary_color
      : props.category === 9
      ? props.theme.success
      : props.category === 10 && props.theme.alert};
`;

export const NewPlan = styled.div`
  height: 234px;
  margin: 10px 0px;

  border: 1px dashed ${props => props.theme.interface5};
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: ${props => props.theme.interface5};
    font-size: 12px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    height: 300px;
    margin: 20px 4px 0px 4px;
  }
`;

interface IPlanCardProps {
  selected?: boolean;
  private?: boolean;
  freeTrial?: boolean;
}

export const PlanCard = styled.div<IPlanCardProps>`
  position: relative;
  margin: 10px 0px;
  height: 234px;
  border-radius: 5px;
  background: ${props => props.theme.interface1};
  /* box-shadow: 0px 3px 15px
    ${props => transparentize(0.6, props.theme.interface5)}; */
  border: 2px solid transparent;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  padding: 10px;
  color: ${props => props.theme.brand_primary_color};

  p {
    margin-top: 15px;
    font-weight: bold;
    font-size: 16px;
  }

  span {
    p {
      font-size: 8px;
      display: inline;
    }

    span {
      font-weight: normal;
      font-size: 14px;
    }

    font-size: 40px;
    font-weight: bold;
    display: block;
  }

  div {
    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 14px;
      font-weight: bold;

      & + span {
        margin-top: 10px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color};
    `}

  ${props =>
    props.private &&
    css`
      border: 2px solid ${props => props.theme.favorite};
    `}


  ${props =>
    props.freeTrial &&
    css`
      border: 2px solid ${props => props.theme.success};
    `}

    @media(max-width: 768px) {
    height: 300px;
    margin: 20px 4px 0px 4px;
  }
`;

export const Private = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 18px;
  color: ${props => props.theme.favorite};

  svg {
    opacity: 0.5;
  }
`;

export const Subtitle = styled.h2`
  color: ${props => props.theme.interface5};
  margin-top: -10px;
  margin-bottom: 30px;
`;

export const PlanPreview = styled.div`
  /* height: 360px; */
  width: 270px;

  background: ${props => props.theme.interface1};
  border-radius: 5px;

  margin: 20px auto;

  padding: 25px 20px;

  div.type + div.type {
    margin-bottom: 20px;
  }

  box-shadow: 0px 3px 15px
    ${props => transparentize(0.6, props.theme.interface5)};

  div.plan-title {
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin-bottom: 5px;
    word-break: break-word;

    & > span {
      color: ${props => props.theme.brand_primary_color};
      font-weight: 700;
      font-size: 14px;
    }
  }

  p.tip {
    font-size: 12px;
    color: ${props => props.theme.interface5};
    line-height: 16px;
    word-break: break-all;
    word-wrap: break-word;
  }

  div.stats {
    display: flex;
    justify-content: space-between;

    padding: 0px 10px;
    margin-top: 10px;

    span {
      font-size: 10px;
      color: ${props => props.theme.interface5};
    }

    span.value {
      font-size: 13px;
    }

    div {
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      text-align: center;
    }
  }

  div.type {
    border: 1px solid ${props => props.theme.interface3};
    border-radius: 4px;

    margin-top: 25px;
    padding: 15px;

    /* height: 165px; */

    h3 {
      text-align: center;
      font-size: 15px;
      font-weight: 700;

      color: ${props => props.theme.interface5};

      margin-bottom: 10px;
    }

    div.prices {
      display: flex;
      justify-content: space-between;
    }

    div.profit {
      display: flex;
      align-items: center;

      flex-wrap: wrap;

      justify-content: space-between;

      padding: 8px;
      margin-top: 15px;

      border: 1px solid ${props => props.theme.interface3};
      border-radius: 4px;

      span.profit-amount {
        font-weight: 700;
        font-size: 14px !important;
        color: ${props => props.theme.success} !important;
      }

      span.label {
        margin-bottom: 10px;
      }
    }

    div.price {
      display: flex;
      flex-direction: column;

      span.amount {
        font-size: 18px;
        font-weight: 700;

        color: ${props => props.theme.interface5};
      }

      span.label {
        font-weight: 300;
        font-size: 13px;

        color: ${props => props.theme.interface5};
      }

      span.final {
        color: ${props => props.theme.brand_primary_color};
      }
    }
  }

  div.no-category {
    text-align: center;
    font-size: 16px;
    color: ${props => props.theme.interface5};
    /* margin: auto; */
  }
`;

export const SharedPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;

  h4 {
    font-size: 14px;
    color: ${props => props.theme.brand_primary_color};
    margin-bottom: 11px;
  }

  div.shared-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border: 1px solid ${props => props.theme.interface3};
    border-radius: 10px;

    padding: 20px 40px;
  }

  span.category {
    color: ${props => props.theme.interface5};
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 20px;
  }

  div.price {
    border: 1px solid ${props => props.theme.interface3};
    border-radius: 5px;

    padding: 5px 10px;

    width: 100%;

    text-align: center;

    h6 {
      font-size: 14px;
      font-weight: 300;
      color: ${props => props.theme.interface5};
      margin-bottom: 5px;
    }

    span {
      font-size: 18px;
      color: ${props => props.theme.success};
    }
  }

  div.price + div.price {
    margin-top: 13px;
  }
`;
