import React from 'react';
import { Col } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';

const DashboardCard = () => {
  const placeholders = [];

  for (let i = 1; i <= 1; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col xl="5" key={p}>
          <div style={{ display: 'flex', gap: '23px' }}>
            <div
              style={{
                background: '#fff',
                height: 'auto',
                padding: '1rem',
                borderRadius: '5px',
              }}
            >
              <div style={{ marginTop: '7px' }}>
                <Placeholder width={700} height={17} />
              </div>
              <div style={{ display: 'flex', gap: '5rem', marginTop: '33px' }}>
                <Placeholder width={104} height={16} />
                <Placeholder width={20} height={16} />
                <Placeholder width={67} height={16} />
                <Placeholder width={67} height={16} />
                <Placeholder width={67} height={16} />
              </div>

              <div style={{ display: 'flex', gap: '5rem', marginTop: '20px' }}>
                <Placeholder width={104} height={16} />
                <Placeholder width={20} height={16} />
                <Placeholder width={67} height={16} />
                <Placeholder width={67} height={16} />
                <Placeholder width={67} height={16} />
              </div>

              <div style={{ display: 'flex', gap: '5rem', marginTop: '20px' }}>
                <Placeholder width={104} height={16} />
                <Placeholder width={20} height={16} />
                <Placeholder width={67} height={16} />
                <Placeholder width={67} height={16} />
                <Placeholder width={67} height={16} />
              </div>

              <div style={{ display: 'flex', gap: '5rem', marginTop: '14px' }}>
                <Placeholder width={700} height={45} />
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default DashboardCard;
