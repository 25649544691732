import styled from 'styled-components';

export const FormWrapper = styled.div`
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 30px;
  height: auto;
  padding: 20px 8px;
`;
