import { useState, useContext, useMemo } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfo,
  faDollarSign,
  faCalendar,
  faShare,
  faListAlt,
  faSpinner,
  faCog,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';

import history from 'services/history';

import {
  Card,
  CardContent,
  ListingItem,
  ApplicationValue,
  ApplicationName,
} from 'components/Listing/styles';
import { Acesso, AcessosList, AcessoHover, Value } from 'components/Acessos';

import { ThemeContext } from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import api from 'services/api';
import InformationModal from '../InformationModal';
import RemoveModal from '../RemoveModal';
import DateModal from '../DateModal';
import { InvoiceListingContext } from '../_context/state';
import { DownloadNF } from '../styles';

const InvoiceCard = ({ i }) => {
  const { deleteInvoice } = useContext(InvoiceListingContext);

  const [infoModal, setInfoModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const theme = useContext(ThemeContext);

  const startChargingAt = moment(i?.start_charging_at).format('YYYY-MM-DD');
  const today = moment();
  const expiredAt = i && moment(i.expired_at);
  const isExpired = expiredAt && expiredAt.isBefore(today, 'days');

  const maxDate = moment(i?.start_charging_at)
    .add(10, 'days')
    .format('YYYY-MM-DD');

  const canResend = i.has_gateway && !i.is_paid;

  const canChangeDate =
    canResend && moment().isBetween(startChargingAt, maxDate, null, '[]');

  const resendBankslip = async i => {
    setResendLoading(true);
    try {
      await api.post(`/v3/invoice/${i.id}/notify-bankslip/`);

      toast.success('Fatura reenviada.');
      setResendLoading(false);
    } catch (e) {
      setResendLoading(false);
      toast.error(e?.response?.data);
    }
  };

  const refreshBankslip = async i => {
    setRefreshLoading(true);
    try {
      await api.post(`/v3/invoice/${i.id}/refresh-bankslip/`);
      toast.success('Fatura atualizada.');
      setRefreshLoading(false);
    } catch (e) {
      toast.error(e?.response?.data);
      setRefreshLoading(false);
    }
  };

  return (
    <ListingItem onClick={() => history.push(`/invoices/${i.id}/`)} key={i.id}>
      {removeModal && (
        <RemoveModal
          action={async () => {
            await deleteInvoice(i.id);
          }}
          show={removeModal}
          setShow={() => setRemoveModal(false)}
        />
      )}

      {infoModal && (
        <InformationModal
          show={infoModal}
          setShow={() => setInfoModal(false)}
          invoice={invoice}
        />
      )}

      {canChangeDate && dateModal && (
        <DateModal
          show={dateModal}
          setShow={() => setDateModal(false)}
          invoice={invoice}
        />
      )}

      <Card>
        <CardContent style={{ position: 'relative' }}>
          <Col sm="1" className="d-none d-sm-flex">
            <ApplicationValue style={{ fontWeight: 'normal' }}>
              <p>{i.id}</p>
            </ApplicationValue>
          </Col>

          <Col
            xs={!showAccess && 10}
            sm={!showAccess && 10}
            md={!showAccess && 4}
            lg="5"
            xl="5"
          >
            <ApplicationValue>
              <ApplicationName
                style={{
                  marginLeft: 0,
                }}
              >
                {i.user_email}
              </ApplicationName>
            </ApplicationValue>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <ApplicationValue style={{ fontWeight: 'normal' }}>
              <p>
                <CurrencyFormat
                  value={i.total.amount}
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={i.total.currency === 'BRL' ? 'R$ ' : '$ '}
                />
              </p>
            </ApplicationValue>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <ApplicationValue style={{ fontWeight: 'normal' }}>
              {moment(i.expired_at).format('DD/MM/YYYY')}
            </ApplicationValue>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <ApplicationValue style={{ fontWeight: 'normal', zIndex: 1 }}>
              {i.nf !== '' ? (
                <DownloadNF
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.open(i.nf);
                  }}
                >
                  Baixar NF-e
                </DownloadNF>
              ) : i.is_paid ? (
                <p>Processando NF-e</p>
              ) : (
                <p>Fatura não paga</p>
              )}
            </ApplicationValue>
          </Col>

          {!showAccess && (
            <Col className="d-sm-flex d-md-none">
              <div>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    color: '#64728C',
                  }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowAccess(true);
                  }}
                />
              </div>
            </Col>
          )}

          <Col xl="2" className="d-none d-xl-flex" />

          {/* <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              backgroundColor: '#f3f3f3',
              height: '100%',
              width: '100%',
              opacity: 0.1,
            }}
          /> */}

          <div
            className={!showAccess && 'd-none d-md-flex'}
            onClick={() => setShowAccess(false)}
            style={{
              background: showAccess ? theme.interface3 : 'transparent',
              height: '100%',
              minWidth: '50%',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
              position: 'absolute',
              right: 0,
              padding: '0 10px 0 10px',
            }}
          >
            <AcessosList showAccess={showAccess}>
              {canResend && (
                <Acesso
                  active
                  hover
                  onClick={e => {
                    e.stopPropagation();
                    resendBankslip(i);
                  }}
                >
                  {resendLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faShare} />
                  )}
                  <AcessoHover>
                    <Value>
                      <span>Reenviar</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              )}
              {canResend && (
                <Acesso
                  active
                  hover
                  onClick={e => {
                    e.stopPropagation();
                    refreshBankslip(i);
                  }}
                >
                  {refreshLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faListAlt} />
                  )}
                  <AcessoHover>
                    <Value>
                      <span>Atualizar</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              )}
              {canChangeDate && (
                <Acesso
                  active
                  hover
                  onClick={e => {
                    e.stopPropagation();
                    setInvoice(i);
                    setDateModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faCalendar} />
                  <AcessoHover>
                    <Value>
                      <span>Trocar data</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              )}

              <Acesso
                active
                hover
                onClick={e => {
                  e.stopPropagation();
                  setInvoice(i);
                  setInfoModal(true);
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
                <AcessoHover>
                  <Value>
                    <span>Informações</span>
                  </Value>
                </AcessoHover>
              </Acesso>
              <Acesso
                active
                hover
                paid
                fixed={i.is_paid}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon data-testid="dolarIcon" icon={faDollarSign} />
                <AcessoHover>
                  <Value>
                    <span>Status</span>
                  </Value>
                </AcessoHover>
              </Acesso>
              {/* <Acesso
                active
                hover
                remove
                fixed
                onClick={e => {
                  e.stopPropagation();
                  setInvoice(i.id);
                  setRemoveModal(true);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
                <AcessoHover>
                  <Value>
                    <span>Remover</span>
                  </Value>
                </AcessoHover>
              </Acesso> */}
            </AcessosList>
          </div>
        </CardContent>
      </Card>
    </ListingItem>
  );
};

export default InvoiceCard;
