import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  span {
    font-family: 'Nunito';
    font-style: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: ${props => props.theme.interface5};
  }
`;
