import history from 'services/history';
import {
  getUserService,
  getBillingService,
  getInvoiceService,
  getCloudsService,
  getWebsitesService,
  getCompanyPlanService,
  getEmailsService,
} from 'services/customers';

type Props = {
  type?: string;
  payload?: any;
};

export const setLoading = (
  dispatch: ({ type, payload }: Props) => void,
  value,
) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getUser = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    const { data } = await getUserService(id);

    dispatch({
      type: 'GET_USER',
      payload: data,
    });
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const getBilling = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    const { data } = await getBillingService(id);

    dispatch({
      type: 'GET_BILLING',
      payload: data,
    });
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const getInvoice = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
  page?: number,
) => {
  try {
    const response = await getInvoiceService({
      user: id,
      page_size: 12,
      page,
    });

    dispatch({
      type: 'GET_INVOICE',
      payload: response.data.results,
    });
    return response.data.count;
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const getClouds = async (
  dispatch: ({ type, payload }: Props) => void,
  id: string | number,
  page?: number,
) => {
  try {
    const response = await getCloudsService({
      admin: id,
      page_size: 12,
      page,
    });
    dispatch({
      type: 'GET_CLOUDS',
      payload: response.data.results,
    });
    return response.data.count;
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const getCompanyPlan = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
  page?: number,
) => {
  try {
    const response = await getCompanyPlanService({
      user: id,
      page_size: 12,
      page,
    });

    dispatch({
      type: 'GET_COMPANY_PLAN',
      payload: response.data.results,
    });
    return response.data.count;
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const getWebsites = async (
  dispatch: ({ type, payload }: Props) => void,
  id: string | number,
) => {
  try {
    const response = await getWebsitesService({ shared_users: id });

    dispatch({
      type: 'GET_WEBSITES',
      payload: response.data.results,
    });
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const getEmails = async (
  dispatch: ({ type, payload }: Props) => void,
  id: number | string,
) => {
  try {
    const response = await getEmailsService({ shared_users: id });

    dispatch({
      type: 'GET_EMAILS',
      payload: response.data.results,
    });
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};
