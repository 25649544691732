import styled from 'styled-components';

export const P = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 6px !important;
  margin-bottom: 13px !important;
  color: ${props => props.theme.interface5};
`;

export const Span = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.brand_primary_color} !important;
`;

export const H3 = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.interface7};
  margin-bottom: 10px;
`;
