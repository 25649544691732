import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { HttpsText } from './styles';

const RemoveModal = ({ show, setShow, removePlan, loading }) => {
  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faRectangleXmark} />
        </Icon>
        <Text>
          <Title>Remover plano</Title>
        </Text>
      </Header>

      <Content>
        <HttpsText>Tem certeza que deseja remover este plano?</HttpsText>
      </Content>
      <Footer>
        <Button icon error onClick={removePlan}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveModal;
