import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faDollarSign,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import React, { useMemo, useState } from 'react';
import { Option, Options } from './styles';
import SeeMoreModal from './SeeMoreModal';

const Permissions = ({ roles, setRoles }) => {
  const [seeMoreModal, setSeeMoreModal] = useState(false);
  const [seeMoreOption, setSeeMoreOption] = useState(null);

  const options = useMemo(
    () => [
      {
        label: 'Financeiro',
        value: 'financial_role',
        description:
          'Cuide da parte financeira da sua agência e de seus clientes',
      },
      {
        label: 'Técnico',
        value: 'technical_role',
        description:
          'Acesse tudo que você precisa para gerenciar seus clientes no dia-a-dia',
      },
      {
        label: 'Atendimento',
        value: 'customer_success_role',
        description:
          'Acesse nossas ferramentas de contato com o cliente e cultive uma boa relação',
      },
    ],
    [],
  );

  const getRoleIcon = role => {
    switch (role) {
      case 'technical_role':
        return faWrench;
      case 'financial_role':
        return faDollarSign;
      case 'customer_success_role':
        return faComment;
      default:
        return null;
    }
  };

  return (
    <Options>
      {options.map(option => (
        <Option
          onClick={() =>
            roles.includes(option.value)
              ? setRoles(_roles => _roles.filter(item => item !== option.value))
              : setRoles(_roles => [...roles, option.value])
          }
          selected={roles.includes(option.value)}
        >
          {seeMoreModal && (
            <SeeMoreModal
              show={seeMoreModal}
              setShow={() => setSeeMoreModal(false)}
              role={seeMoreOption}
            />
          )}
          <FontAwesomeIcon icon={getRoleIcon(option.value)} />
          <span>{option.label}</span>
          <p>{option.description}</p>
          <a
            onClick={e => {
              e.stopPropagation();
              setSeeMoreOption(option.value);
              setSeeMoreModal(true);
            }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
            Saiba mais
          </a>
        </Option>
      ))}
    </Options>
  );
};

export default Permissions;
