import { createContext, useReducer } from 'react';

import { Invoice } from 'types/invoice';

import reducer from './reducer';

import {
  setLoading,
  getInvoices,
  updateInvoice,
  deleteInvoice,
} from './actions';

export interface InvoiceDetailState {
  invoices: Invoice[];
  previous: number;
  current: number;
  count: number;
  next: number;
  errors: any;
  loading: boolean;

  setLoading(value: boolean): void;
  getInvoices(
    page: number,
    search: string,
    status: any,
    orderValue: any,
    ctrl: AbortController,
  ): Promise<void>;
  updateInvoice(
    field: any,
    value: string | number | boolean,
    id: string | number,
  ): Promise<void>;
  deleteInvoice(id: string | number): Promise<void>;
}

// initial state
const initialState: InvoiceDetailState = {
  invoices: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,

  setLoading: (): void => {},
  getInvoices: async () => {},
  updateInvoice: async () => {},
  deleteInvoice: async () => {},
};

// context
export const InvoiceListingContext = createContext(initialState);

// provider
export const InvoiceListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <InvoiceListingContext.Provider
      value={{
        // state
        invoices: state.invoices,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getInvoices: async (page, search, status, orderValue, ctrl) =>
          await getInvoices(dispatch, page, search, status, orderValue, ctrl),
        updateInvoice: async (field, value, id) =>
          await updateInvoice(dispatch, field, value, id),
        deleteInvoice: async id => await deleteInvoice(dispatch, id),
      }}
    >
      {children}
    </InvoiceListingContext.Provider>
  );
};
