import {
  faCheck,
  faCheckCircle,
  faCircle,
  faCopy,
  faDownload,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@cloudez/cloudez-design-system';
import { Header, Title } from 'components/Common';
import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import paginateArray from 'utils/paginateArray';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { getCloudsService } from 'services/cloud';
import { useAuth, useDevice, useDocTitle } from 'hooks';
import { Link } from 'react-router-dom';
import trophy from 'assets/img/svg/trophy.svg';

import comunidade from 'assets/img/selos-comunidade.png';
import academy from 'assets/img/selos-academy.png';
import iconAcademy from 'assets/img/svg/cloudez-academy.svg';
import * as Styles from './styles';

const Stamps: React.FC = () => {
  const [cloudCount, setCloudCount] = useState(0);
  useDocTitle('Selos - Cloudez Partners');

  const [stampToDownload, setStampToDownload] = useState('light');

  const { isMobile } = useDevice();

  const [copied, setCopied] = useState(false);

  const { company } = useAuth();

  const partnerStamp = useMemo(() => {
    return company.stamp.toLowerCase();
    // return 'expert';
  }, [company]);

  useEffect(() => {
    async function getCloudCount() {
      try {
        const { data } = await getCloudsService();
        setCloudCount(data.count);
      } catch (err) {
        toast.error('');
      }
    }

    getCloudCount();
  }, []);

  const stampLevels = useMemo(
    () => [
      {
        name: 'partner',
        threshold: '1 Cloud ativo',
        color: 'rgba(34, 83, 245, 0.3)',
        benefits: [
          'Acesso ao painel whitelabel',
          'Utilização ilimitada das automações de processos de hospedagem e financeiro',
          'Migração automatizada gratuita de websites e e-mails',
          'Acesso aos cursos básicos e avançados da Cloudez Academy',
          'Acesso aos materiais do Toolkit básico (cases de sucesso, materiais comerciais, institucionais, minuta de contrato, etc)',
          'Acesso a um selo da jornada de parceiros',
        ],
      },
      {
        name: 'certified',
        threshold: '2-5 Clouds ativos',
        color: 'rgba(138, 236, 31, 0.3)',
        min: 2,
        benefits: [
          'Benefícios do Selo Partner',
          // '01 cloud start (cloud compartilhado, para educação de clientes) disponíveis para o parceiro',
          'Acesso ao selo via link validado para utilização em seu website',
          'Acesso aos canais e eventos da Comunidade Cloudez Partners',
          'Serviço cadastrado no ecossistema de parceiros para divulgação e recebimento de leads qualificados',
          'Acesso ao atendimento via chat (N1) para o parceiro no painel administrativo',
          'Acesso aos materiais do Toolkit comercial (apresentação comercial, argumentos comparativos de tecnologia, landing page, etc)',
          'Custo para realização de automação pocket de template próprio: R$ 1.500',
        ],
      },
      {
        name: 'master',
        threshold: '6-30 Clouds ativos',
        color: 'rgba(255, 191, 45, 0.3)',
        min: 6,
        benefits: [
          'Benefícios dos Selos Partner + Certified',
          // '02 cloud start (cloud compartilhado, para educação de clientes) disponíveis para o parceiro',
          'Serviço cadastrado no ecossistema de parceiros para divulgação e recebimento de leads qualificados',
          'Acesso aos materiais do Toolkit de gestão (modelos de processos, checklists de execução, etc)',
          'Postagem de um case de sucesso seu junto da Cloudez no blog',
          'Recebimento do kit de marca da Cloudez',
          'Grupo de WhatsApp com consultores especializados para atendimento personalizado',
          'Ações de marketing conjunto em webinários, postagens, campanhas, etc',
          'Apoio comercial de consultores especializados para realização de vendas',
          'Custo para realização de automação pocket de template próprio: R$ 1.000',
        ],
      },
      {
        name: 'expert',
        threshold: '31-99 Clouds ativos',
        color: 'rgba(250, 102, 40, 0.3)',
        min: 31,
        benefits: [
          'Benefícios dos Selos Partner + Certified + Master',
          // '03 cloud start (cloud compartilhado, para educação de clientes) disponíveis para o parceiro',
          'Custo para realização de automação pocket de template próprio: R$ 700',
        ],
      },
      {
        name: 'exclusive',
        threshold: '100+ Clouds ativos',
        color: 'rgba(127, 156, 199, 0.3)',
        min: 100,
        benefits: [
          'Benefícios dos Selos Partner + Certified + Master + Expert',
          // '03 cloud start (cloud compartilhado, para educação de clientes) disponíveis para o parceiro',
          'Camada de precificação disponível: Negociado',
          'Custo para realização de automação pocket de template próprio: Negociado',
        ],
      },
    ],
    [],
  );

  const currentStamp = useMemo(() => {
    return stampLevels.filter(level => level.name === partnerStamp)[0];
  }, [partnerStamp]);

  const currentStampIndex = useMemo(() => {
    return stampLevels.map(l => l.name).indexOf(currentStamp.name);
  }, [currentStamp]);

  const nextStamp = useMemo(() => {
    const next = currentStampIndex + 1;
    if (next === stampLevels.length) return;
    return stampLevels[next];
  }, [partnerStamp, currentStampIndex]);

  const cloudsUntilNextStamp = useMemo(() => {
    if (!nextStamp) return;
    return nextStamp.min - cloudCount;
  }, [nextStamp, cloudCount]);

  const progress = useMemo(() => {
    if (cloudCount && cloudsUntilNextStamp && nextStamp) {
      const _progress = (100 * cloudCount) / nextStamp.min;
      return _progress;
    }

    return 0;
  }, [cloudsUntilNextStamp, cloudCount, nextStamp]);

  const settings = useMemo(
    () => ({
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
    }),
    [],
  );

  const paginatedCurrentBenefits = useMemo(() => {
    return paginateArray(currentStamp.benefits, 3);
  }, [currentStamp]);

  const paginatedNextBenefits = useMemo(() => {
    if (!nextStamp) return;
    return paginateArray(nextStamp.benefits, 3);
  }, [nextStamp]);

  const embed = useMemo(() => {
    return `<a href="https://cloudez.io/br?utm_campaign=partner-${
      company.slug
    }-badge&utm_medium=badge&utm_source=partner&utm_content=${partnerStamp}&utm_term="><img alt="" width="220" height="60" src="https://files.cloudez.io/stamp/${partnerStamp}${
      stampToDownload === 'light' ? '' : '-dark'
    }-sm.svg"></a>`;
  }, [partnerStamp, stampToDownload]);

  return (
    <Styles.Wrapper>
      <Header>
        <Title>Programa de Selos</Title>
      </Header>

      <Styles.Card sectioned>
        {!isMobile && (
          <div className="section left border-right">
            <Styles.CardTitle>TRILHA DOS SELOS</Styles.CardTitle>
            <Styles.Levels>
              {stampLevels.map((stamp, index) => {
                const active = stamp.name === partnerStamp;
                const completed =
                  index === 0 || index < currentStampIndex || active;

                return (
                  <Styles.TrailLevel completed={completed} key={stamp.name}>
                    {completed && !active && (
                      <FontAwesomeIcon icon={faCheckCircle} className="check" />
                    )}
                    <Styles.Stamp
                      active={active}
                      completed={completed}
                      stampName={stamp.name}
                      color={stamp.color}
                    />
                    <span className="stamp-title">{stamp.name}</span>
                    <span className="threshold">{stamp.threshold}</span>
                  </Styles.TrailLevel>
                );
              })}
            </Styles.Levels>
          </div>
        )}
        <div className="section right">
          {nextStamp ? (
            <>
              <Styles.CardTitle>META</Styles.CardTitle>

              <Styles.Goal>
                <Styles.Star />
                <span className="label">Meta para o próximo selo</span>
                <span className="goal">
                  Falta{cloudCount > 1 ? 'm' : ''}{' '}
                  <span className="highlight">{cloudsUntilNextStamp}</span>{' '}
                  Clouds
                </span>
                <Styles.ProgressBarWrapper>
                  <Styles.ProgressBarFill
                    className="ml-2"
                    progress={progress}
                  />
                </Styles.ProgressBarWrapper>
                <span className="current">{cloudCount} Clouds Ativos</span>
                <Link to="/stamps/benefits" className="more">
                  <FontAwesomeIcon icon={faInfoCircle} /> Saiba mais sobre os
                  Selos
                </Link>
              </Styles.Goal>
            </>
          ) : (
            <>
              <Styles.CardTitle>CONQUISTA</Styles.CardTitle>
              <Styles.CloudCount>
                <img src={trophy} alt="trophy" />
                <div className="value">
                  Total de Clouds Ativos <br />
                  <span className="highlight">{`${cloudCount} Clouds`}</span>
                </div>
                <Link to="/stamps/benefits" className="more">
                  <FontAwesomeIcon icon={faInfoCircle} /> Saiba mais sobre os
                  Selos
                </Link>
              </Styles.CloudCount>
            </>
          )}
        </div>
      </Styles.Card>

      <div className="mt-4 mb-4 d-flex flex-wrap flex-md-nowrap">
        <Styles.Card
          className="mr-sm-4 mb-4 mb-sm-0"
          width="50%"
          shadow
          sectioned
          vertical
        >
          <Styles.CardTitle>SELO ATUAL</Styles.CardTitle>
          <div className="section d-flex">
            <Styles.CurrentStamp currentStampName={currentStamp.name} />
            <Styles.BenefitsList>
              <Styles.BenefitsTitle>Benefícios Garantidos</Styles.BenefitsTitle>
              <Slider className="slider" {...settings}>
                {paginatedCurrentBenefits.map((page, index) => (
                  <div key={index}>
                    {page.map((benefit, index) => (
                      <Styles.Benefit key={index}>
                        <FontAwesomeIcon icon={faCheckCircle} /> {benefit}
                      </Styles.Benefit>
                    ))}
                  </div>
                ))}
              </Slider>
            </Styles.BenefitsList>
          </div>
          <div className="section d-sm-flex d-none d-sm-block">
            <Styles.DownloadStamp>
              <Slider
                beforeChange={() => {
                  setStampToDownload(state =>
                    state === 'light' ? 'dark' : 'light',
                  );
                }}
                className="slider mb-5"
                {...settings}
              >
                {['light', 'dark'].map(type => {
                  return (
                    <Styles.StampSm
                      dark={type === 'dark'}
                      stampName={currentStamp.name}
                    />
                  );
                })}
              </Slider>
              <span
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#64728C',
                  marginBottom: 10,
                  display: 'block',
                }}
              >
                Baixe o kit e divulgue seu selo
              </span>
              <Button
                size="sm"
                style={{
                  width: '100%',
                }}
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = `${process.env.PUBLIC_URL}/smstamps/${partnerStamp}.zip`;

                  link.download = `${partnerStamp}.zip`;

                  link.click();
                }}
              >
                <FontAwesomeIcon icon={faDownload} className="m-0 mr-1" />
                Download do Kit
              </Button>
            </Styles.DownloadStamp>

            <Styles.EmbedWrapper>
              <h4 className="mb-3">Incorporar</h4>
              <Styles.Embed>
                <p>{embed}</p>
                <div className="d-flex justify-content-end">
                  <Button
                    success={copied}
                    text
                    width="auto"
                    onClick={() => {
                      copy(embed);

                      setCopied(true);
                      toast.success('Link copiado com sucesso');
                      setTimeout(() => {
                        setCopied(false);
                      }, 4000);
                    }}
                  >
                    Copiar <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                  </Button>
                </div>
              </Styles.Embed>
            </Styles.EmbedWrapper>
          </div>
        </Styles.Card>
        {nextStamp && (
          <Styles.Card sectioned vertical width="48%">
            <Styles.CardTitle>PRÓXIMO SELO</Styles.CardTitle>
            <div className="section d-flex">
              <Styles.CurrentStamp currentStampName={nextStamp.name} />
              <Styles.BenefitsList>
                <Styles.BenefitsTitle>Próximos Benefícios</Styles.BenefitsTitle>
                <Slider className="slider" {...settings}>
                  {paginatedNextBenefits.map((page, index) => (
                    <div key={index}>
                      {page.map((benefit, index) => (
                        <Styles.Benefit key={index} next>
                          <FontAwesomeIcon icon={faCircle} /> {benefit}
                        </Styles.Benefit>
                      ))}
                    </div>
                  ))}
                </Slider>
              </Styles.BenefitsList>
            </div>
          </Styles.Card>
        )}
      </div>

      <Styles.Ads>
        <Slider className="slider" {...settings} autoplay autoplaySpeed={6000}>
          <Styles.Card image shadow>
            <img src={academy} alt="cloudez-academy" />
            <Styles.Ad>
              <h4 className="title">
                Conheça nossos &nbsp;
                <span className="highlight">Treinamentos &nbsp;</span>
                {!isMobile && (
                  <>
                    |&nbsp;
                    <img src={iconAcademy} alt="Cloudez Academy Icon" />
                  </>
                )}
              </h4>
              <p>
                A Cloudez Academy é o ambiente de capacitação da sua agência!{' '}
                <br />
                <br />
                Reunimos nossos especialistas em hosting e criamos treinamentos
                para você e seu time assimilarem as melhores práticas da
                mentalidade Cloud, e entender melhor como os nossos painéis
                funcionam.
                <br />
                <br />
                Confira já! Todos os treinamentos são gratuitos, 100% desenhados
                para agências digitais.
              </p>
              <Button
                outline
                className="mt-4"
                size="sm"
                width="222px"
                onClick={() =>
                  window.open('https://academy.cloudez.io/', '_blank')
                }
              >
                Acessar Treinamentos
              </Button>
            </Styles.Ad>
          </Styles.Card>
          <Styles.Card image shadow>
            <img src={comunidade} alt="cloudez-academy" />
            <Styles.Ad>
              <h4 className="title">
                Faça parte da&nbsp;
                <span className="highlight"> Comunidade Cloudez Partners</span>
              </h4>
              <p>
                A Comunidade Cloudez Partners é um ecossistema que reúne os
                melhores especialistas do mercado. <br />
                <br /> Composta por +130 CEO's e donos de agências, a Comunidade
                se reúne periodicamente para tratar de estratégias de negócios,
                discutir táticas de crescimento, falar das inovações do mercado
                digital, trocar serviços entre si, e muito mais. <br />
                <br />
                Não perca a oportunidade de fazer parte dessa comunidade
                exclusiva.
              </p>
              <Button
                className="mt-4"
                size="sm"
                width="222px"
                outline
                onClick={() =>
                  window.open('https://comunidade.cloudez.io/', '_blank')
                }
              >
                Acessar Comunidade
              </Button>
            </Styles.Ad>
          </Styles.Card>
        </Slider>
      </Styles.Ads>
    </Styles.Wrapper>
  );
};

export default Stamps;
