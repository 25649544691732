import React from 'react';
import { Link } from 'react-router-dom';

import { Content, Card } from './styles';

const Terms: React.FC = () => {
  return (
    <>
      <Card>
        <h2>TERMO E CONDIÇÕES GERAIS DE USO DO SITE CLOUDEZ</h2>

        <Content>
          Este Termo e Condições Gerais ("Termo") aplicam-se ao uso dos serviços
          oferecidos pela CLOUD EASY, CORP corporação devidamente registrada em
          Dover, condado de Kent, Delaware, E.U.A., pelo EIN 30-0990640 gestora
          dos Serviços CLOUDEZ, disponível para contratação através do Site
          https://cloudez.io/, objeto deste Termo, doravante denominado CLOUDEZ,
          por parte de qualquer PESSOA FÍSICA OU JURÍDICA, maior e capaz, que
          tenha preenchido o Cadastro, cujos dados passam a ser parte integrante
          deste Termo, bem como que tenha "aceitado" eletronicamente todas as
          cláusulas do mesmo e todas as demais políticas disponíveis no Site,
          doravante denominado USUÁRIO.
          <br />
          Os serviços disponíveis pelo CLOUDEZ no Site https://cloudez.io/ e
          seus subdomínios serão regidos pelas cláusulas e condições abaixo.
          <br />
          Ao aceitar eletronicamente o presente Termo, através do clique no
          botão "Aceito os Termos de Uso" da página de cadastro complementar a
          esta, o USUÁRIO estará automaticamente aderindo e concordando em se
          submeter integralmente a seus termos e condições e de qualquer de suas
          alterações futuras, além de aceitar as disposições das políticas do
          Site.
          <br />
          <h3>CLÁUSULA 1ª - DAS DEFINIÇÕES</h3>
          <br />
          1.1 Para a exata compreensão e interpretação dos direitos e obrigações
          previstos no presente Termo, são adotadas as seguintes definições:
          <br />
          a) CADASTRO: ficha cadastral com os dados pessoais do USUÁRIO, e no
          caso de USUÁRIO pessoa jurídica, os dados da pessoa jurídica
          contratante, obrigatoriamente preenchida com o Login e a Senha do
          USUÁRIO para a utilização da plataforma e dos Serviços de CLOUDEZ;
          <br />
          b) LOGIN: trata-se do email ou apelido, informado pelo USUÁRIO no ato
          do CADASTRO, que usará para acessar os Serviços CLOUDEZ e o seu
          CADASTRO;
          <br />
          c) RECORRÊNCIA: valor devido pelo USUÁRIO em virtude da utilização dos
          Serviços CLOUDEZ, conforme contratados no ato do CADASTRO ou mesmo de
          forma posterior;
          <br />
          d) SENHA: sequência de letras maiúsculas e minúsculas, números e/ou
          símbolos, escolhida pelo USUÁRIO, composta de no mínimo 8 caracteres,
          a qual deverá ser previamente informada pelo USUÁRIO quando da sua
          visita ao Site de acesso aos Serviços CLOUDEZ, sendo que trocas
          periódicas das SENHAS no período máximo de 60 (sessenta) dias são
          altamente recomendadas pelo CLOUDEZ, por questões de segurança. O
          CLOUDEZ ressalta que não terá acesso a esta SENHA. Portanto, se o
          USUÁRIO quiser recuperar seu acesso, este deverá enviar o pedido de
          recuperação de senha através do formulário on-line, disponível no link
          https://cloud.cloudez.io/password/reset, que solicitará uma
          confirmação no email cadastrado do USUÁRIO ou por SMS para, a partir
          deste, gerar uma nova SENHA. Sendo assim, com este procedimento, a
          SENHA poderá ser alterada a qualquer momento pelo USUÁRIO. Caso o
          USUÁRIO queira efetuar qualquer outra atividade relacionada com seu
          CADASTRO, tal como modificar alguma informação em seu CADASTRO, este
          deverá seguir os procedimentos descritos no Site do CLOUDEZ e acessar
          os dados de seu perfil e efetuar as mudanças;
          <br />
          e) Serviços CLOUDEZ: plataforma prestadora dos serviços que
          possibilita ao USUÁRIO: (i) gerenciar os servidores por ele
          contratados, e a instalação das aplicações por ele desenvolvidas; ou
          (ii) contratar diretamente no CLOUDEZ os servidores, que os
          configurará para se tornarem aptos a receber os conteúdos e as
          aplicações desenvolvidos pelo USUÁRIO e disponibilizá-los na internet,
          com o objetivo final de diminuir o atrito e as dificuldades
          enfrentadas pelos USUÁRIOS a terem que configurar diferentes
          plataformas com servidores, conforme o pacote de serviços escolhidos
          pelo USUÁRIO, mediante a outorga, pelo CLOUDEZ, de uma licença
          limitada, temporária, revogável, não exclusiva, não sublicenciável e
          intransferível de uso da plataforma pelo período contratado dos
          Serviços CLOUDEZ;
          <br />
          f) Site: portal eletrônico do CLOUDEZ, por meio do qual o USUÁRIO
          poderá contratar e terá acesso aos Serviços CLOUDEZ, conforme pacotes
          disponíveis no Site, mediante preenchimento de CADASTRO, informação de
          LOGIN e SENHA de acesso próprias.
          <br />
          g) Terceiro: parceiros do CLOUDEZ, tais como prestador de serviços de
          meio de pagamentos digitais, que serão utilizados para efetuar os
          pagamentos das RECORRÊNCIAS dos Serviços CLOUDEZ, prestadores de
          serviço de email, CDN, dentre outros, conforme for contratado pelo
          USUÁRIO;
          <br />
          <br />
          <h3>CLÁUSULA 2ª - DO OBJETO</h3>
          <br />
          2.1. O CLOUDEZ é uma plataforma que permite ao USUÁRIO, gerenciar de
          forma autônoma os servidores terceirizados por ele contratados, e a
          instalação das aplicações por ele desenvolvidas com os Serviços
          CLOUDEZ, ou se for do interesse do USUÁRIO, ele poderá optar por um
          pacote de Serviços CLOUDEZ, que configurará, a pedido do USUÁRIO,
          servidores terceirizados para se tornarem aptos a receber os conteúdos
          e as aplicações desenvolvidos pelo USUÁRIO e disponibilizá-los na
          internet, mediante pagamento de uma RECORRÊNCIA, conforme pacote
          contratado pelo USUÁRIO no Site.
          <br />
          2.1.1. Portanto, o USUÁRIO pode usar os Serviços CLOUDEZ para
          selecionar servidores para inserir, armazenar, visualizar, recuperar,
          transmitir, operar e processar seus dados, desde que o USUÁRIO
          assegure que os dados ali inseridos, seu uso, transmissão, operação
          e/ou o seu processamento estejam em conformidade com os termos e
          condições deste Termo e todas as leis aplicáveis. Sem limitação, o
          USUÁRIO será o único responsável por: (i) a operação, uso, conteúdo,
          transmissão e manutenção dos seus dados; (ii) todo e qualquer
          resultado obtido (ou não atingido) com relação aos seus dados ou ao
          uso dos Serviços CLOUDEZ; e (iii) todos os dados, conteúdos e
          informações gerados através do uso dos seus dados. Para fins da
          presente prestação, dados do USUÁRIO podem ser todo o conteúdo que o
          USUÁRIO possui autorização para armazenar, incluindo, sem limitação,
          todos os dados, textos, áudios, softwares (incluindo imagens ou
          processos de máquina) ou arquivos visuais (estáticos e dinâmicos) que
          são transmitidos, enviados, implantados ou fornecidos para
          armazenamento nos servidores contratados com a CLOUDEZ, incluindo todo
          o conteúdo de terceiros e todos os dados pessoais.
          <br />
          2.2. Através da contratação pelo Site do CLOUDEZ, ou através da
          contratação através de um dos revendedores do CLOUDEZ, mediante Senha
          e Login, o USUÁRIO terá à sua disposição uma ferramenta que integra
          automaticamente os servidores do USUÁRIO, os seus websites, bancos de
          dados e aplicativos, além de fornecer um monitoramento contínuo dos
          mesmos, de forma descomplicada, sendo que todos os Serviços CLOUDEZ,
          mesmo adquiridos através de revendedores do CLOUDEZ, deverão respeitar
          os termos e condições do presente Termo. Portanto, em razão da própria
          natureza dos Serviços CLOUDEZ, este terá acesso aos servidores do
          USUÁRIO, que serão geridos e mantidos para uso exclusivo deste, não
          sendo, em hipótese alguma, compartilhados com outros USUÁRIOS.
          <br />
          2.2.1. Caso os Serviços CLOUDEZ não sejam mais utilizados pelo
          USUÁRIO, os servidores contratados pessoalmente, sua gestão e
          manutenção passarão a ser de responsabilidade exclusiva do USUÁRIO que
          se compromete, inclusive, a realizar processo de desinstalação para
          encerrar os acessos do CLOUDEZ aos servidores do USUÁRIO no prazo
          máximo de 15 (quinze) dias a contar da notificação ao CLOUDEZ. Durante
          este prazo, os Serviços CLOUDEZ serão cobrados normalmente. Após este
          período, o USUÁRIO perderá o acesso aos Serviços CLOUDEZ.
          <br />
          2.2.2. No entanto, caso o USUÁRIO contratou os servidores diretamente
          com o CLOUDEZ e não tenha mais interesse em manter os Serviços
          CLOUDEZ, o USUÁRIO terá 30 (trinta) dias, por sua própria conta e
          risco e de sua única e exclusiva responsabilidade, a contar da
          notificação de cancelamento dos Serviços CLOUDEZ, para
          exportar/eliminar todos os conteúdos e aplicações armazenadas nos
          servidores contratados com o CLOUDEZ, substituindo-os por servidores
          contratados pessoalmente e/ou realizar as exportações dos seus dados.
          Durante este prazo, os Serviços CLOUDEZ serão cobrados normalmente.
          Após este período, o USUÁRIO perderá o acesso aos servidores atrelados
          aos Serviços CLOUDEZ.
          <br />
          2.3. Em que pese ser passível de utilização a partir de qualquer parte
          do mundo, o USUÁRIO declara-se ciente e concordando que,
          independentemente do local de onde estejam sendo acessados os
          serviços, a relação entre o USUÁRIO e o CLOUDEZ estará sempre, e em
          qualquer hipótese, sujeita à legislação brasileira.
          <br />
          2.4. Após a escolha do pacote de Serviços CLOUDEZ à disposição no
          Site, o CLOUDEZ usará serviços de meio de pagamentos digitais de
          Terceiros, parceiros do CLOUDEZ, para efetuar a cobrança do valor
          pedido pelo pacote. Com a confirmação do pagamento do pacote, o pedido
          especificado pelo USUÁRIO será processado pelo CLOUDEZ e os Serviços
          CLOUDEZ serão implementados.
          <br />
          2.4.1. O CLOUDEZ, buscando respeitar a legislação aplicável ao
          consumidor com relação aos Serviços CLOUDEZ, efetuará o débito da
          compra na conta indicada pelo USUÁRIO e este terá o prazo de 07 (sete)
          dias, contados da realização do pagamento dos Serviços CLOUDEZ para
          exercer seu direito de arrependimento, conforme disposições previstas
          na Cláusula 4.8 e seguintes.
          <br />
          2.4.2. Caso, em até 07 (sete) dias após a realização do pagamento dos
          Serviços CLOUDEZ, o CLOUDEZ não tenha recebido nenhuma notificação de
          arrependimento do USUÁRIO através do email financeiro@cloudez.io,
          entender-se-á que o USUÁRIO renunciou tacitamente ao seu direito de
          arrependimento e a quaisquer direitos referentes ao presente, passado
          e futuro, relativos a fatos, obrigações e responsabilidades, direta ou
          indiretamente relacionados aos Serviços CLOUDEZ, nada mais tendo a
          reclamar do CLOUDEZ, seja a que título for, judicial ou
          extrajudicialmente, em que tempo for. Neste sentido, o USUÁRIO desde
          já concorda que os procedimentos de arrependimento descritos no
          presente Termo serão os seus únicos e exclusivos remédios para ser
          reembolsado pelo valor pago pelos Serviços CLOUDEZ e qualquer ato
          ofensivo do USUÁRIO contra o CLOUDEZ neste sentido poderá caracterizar
          hipótese de indenização, pelo USUÁRIO ao CLOUDEZ, por perdas e danos
          incorridos.
          <br />
          2.5. O CLOUDEZ facilitará a realização do pagamento dos pacotes de
          Serviços CLOUDEZ adquiridas pelo USUÁRIO, intermediando a troca de
          informações dos USUÁRIOS com Terceiros, desde que exista a anuência do
          USUARIO, mediante o aceite do presente Termo. Tais informações poderão
          se referir àqueles disponibilizadas no CADASTRO, tais como o nome de
          USUÁRIO, o email, ou qualquer outra informação que seja
          disponibilizada pelo USUÁRIO para o Site do CLOUDEZ. Ainda neste
          sentido, o USUÁRIO fica desde já ciente que o CLOUDEZ apenas é um
          intermediário entre o USUÁRIO e os serviços de meios de pagamentos de
          Terceiros para a realização dos pagamentos devidos e/ou emissão das
          respectivas notas fiscais. Neste caso, o USUÁRIO compartilhará
          informações de dados pessoais, dados de cartão de crédito, dados sobre
          as transações comerciais com o Terceiro, autorizado pelo CLOUDEZ, e
          permitirá que os USUÁRIOS possam, dentro do ambiente indicado de uso
          do CLOUDEZ, autorizar o Terceiro a realizar os débitos, créditos e
          transferências de fundos relevantes, inclusive para o pagamento das
          multas aplicáveis, conforme descrito no presente Termo. Portanto,
          mediante o aceite do presente Termo, o USUÁRIO deliberadamente aceita
          o uso e fornecimento de suas informações pelos Terceiros, parceiros do
          CLOUDEZ, para a correta prestação dos Serviços CLOUDEZ.
          <br />
          2.6. As comunicações e as transações comerciais entre os USUÁRIOS
          serão acompanhadas ou mediadas pelo CLOUDEZ, com o objetivo de zelar
          pelo bom atendimento, pela prevalência da boa-fé e dos princípios
          constitucionais de lealdade e segurança nas comunicações entre os
          USUÁRIOS, e o CLOUDEZ buscará retirar de sua base de USUÁRIOS, aqueles
          que não compactuam com estes princípios.
          <br />
          <br />
          <h3>CLÁUSULA 3ª - DO CADASTRO E USO DOS SERVIÇOS CLOUDEZ</h3>
          <br />
          3.1. Para contratação dos Serviços CLOUDEZ, o USUÁRIO deverá acessar o
          Site, preencher o CADASTRO disponibilizado para a habilitação dos
          Serviços CLOUDEZ, informando ao CLOUDEZ todos os dados exigidos (nome,
          telefone de contato, inclusive seu número de registro no CNPJ e o
          registro no CPF do seu representante legal, ou só do CPF, se for
          USUÁRIO pessoa física), e responsabilizando-se o USUÁRIO civil e
          criminalmente pela veracidade das informações, inclusive perante
          terceiros, obrigando-se também a manter seus dados atualizados. O
          USUÁRIO deverá ainda informar obrigatoriamente um endereço de e-mail
          de uso único e exclusivo do USUÁRIO. Ao efetuar o CADASTRO, o USUÁRIO
          será direcionado à área de compra dos pacotes de Serviços CLOUDEZ
          disponíveis no Site do CLOUDEZ. Neste sentido, o CLOUDEZ não efetua
          qualquer checagem de informações de nenhum USUÁRIO que deseje utilizar
          os serviços disponibilizados no Site no ato do CADASTRO, nem realiza
          uma profunda investigação sobre a veracidade das informações e dados
          armazenados nos servidores, sejam eles contratados diretamente com o
          CLOUDEZ, ou pessoalmente pelos USUÁRIOS, com exceção do previsto nas
          Cláusulas 3.2, 3.3 e 3.3.1. Desta forma, o CLOUDEZ não se
          responsabiliza, de nenhuma maneira e em nenhuma hipótese, pela
          utilização indevida dos Serviços CLOUDEZ.
          <br />
          3.1.1. Os Serviços CLOUDEZ estarão a disposição do USUÁRIO 24 (vinte e
          quatro) horas por dia, 7 (sete) dias por semana, com um nível de
          serviço de no mínimo 99% (noventa e nove por cento) do tempo de
          disponibilidade, com exceção de manutenções regulares, problemas com
          os data centers, que são tercerizados, casos fortuitos e de força
          maior, que serão informados previamente mediante comunicado no próprio
          Site do CLOUDEZ. Caso os Serviços CLOUDEZ tenham um nível de serviço
          menor do que 99%, a cada 1% (um por cento) de indisponibilidade, o
          CLOUDEZ reembolsará o USUÁRIO, desde que o USUÁRIO notifique o CLOUDEZ
          sobre a mesma dentro do prazo de 30 (trinta) dias, a contar do evento.
          O reembolso será creditado na RECORRÊNCIA seguinte, ou seja, o USUÁRIO
          será cobrado um valor menor para compensar o seu crédito com o
          CLOUDEZ.
          <br />
          3.1.2. O LOGIN e a SENHA que dão acesso ao CADASTRO são de uso pessoal
          e intransferível do USUÁRIO, razão pela qual o CLOUDEZ não se
          responsabiliza por eventual manipulação não autorizada dessas
          informações e dados por terceiros, e/ou perda de acesso ao email de
          autenticação do LOGIN do USUÁRIO, e devendo, portanto, o USUÁRIO tomar
          todas as medidas necessárias para manter em sigilo e salvaguardar as
          referidas informações.
          <br />
          3.1.3. O USUÁRIO compromete-se a notificar o CLOUDEZ imediatamente,
          mediante apresentação da reclamação através do email
          suporte@cloudez.io, a respeito de qualquer uso não autorizado de sua
          conta, bem como o acesso não autorizado por terceiros a mesma. O
          USUÁRIO será o único responsável pelas operações efetuadas em sua
          conta, uma vez que o acesso à mesma só será possível mediante a
          aposição da SENHA, cujo conhecimento é exclusivo do USUÁRIO.
          <br />
          3.2. O CLOUDEZ se reserva o direito de utilizar todos os meios válidos
          e possíveis para identificar seus USUÁRIOS, portanto poderá o CLOUDEZ,
          a qualquer momento, a seu critério e/ou após o recebimento de
          denúncia, solicitar cópias de documentos do USUÁRIO, de forma a
          garantir a veracidade dos dados pessoais do USUÁRIO. Nestes casos, o
          CLOUDEZ poderá suspender o fornecimento de Serviços CLOUDEZ, até o
          recebimento dos documentos solicitados, que deverão ser enviados em
          até 72 (setenta e duas) horas, contadas da notificação, ficando isento
          de qualquer responsabilidade ou ressarcimento ao pretendente. Caso
          algum dado seja verificado pelo CLOUDEZ como inconsistente com os
          documentos apresentados, o USUÁRIO terá o prazo de 2 (dois) dias úteis
          para efetuar as devidas correções, sob pena de ter o seu acesso aos
          Serviços CLOUDEZ bloqueados, até a regularização do CADASTRO, podendo
          inclusive cancelar definitivamente o referido CADASTRO e rescindir os
          Serviços CLOUDEZ, se assim entender necessário à proteção dos
          legítimos interesses do CLOUDEZ e seus USUÁRIOS, sem prejuízo de
          outras medidas que entender necessárias e oportunas.
          <br />
          3.2.1. Se for rescindido o CADASTRO e os Serviços CLOUDEZ, os
          procedimentos previstos na Cláusula 2.2.2 acima serão respeitados,
          sendo que a cobrança dos valores constantes como RECORRÊNCIA não serão
          interrompidos até a finalização dos Serviços CLOUDEZ pelo USUÁRIO e/ou
          pelo CLOUDEZ.
          <br />
          3.3. As informações fornecidas pelo USUÁRIO serão de uso confidencial
          e exclusivo do CLOUDEZ e seus parceiros, única e exclusivamente para a
          correta prestação dos serviços objeto do presente Termo e somente
          serão fornecidas nos casos previstos pela Lei ou quando constatada(s)
          fraude(s), como por exemplo: falsidade ideológica (utilização de nomes
          e documentos falsos), falsificação ou incorreta utilização dos
          Serviços CLOUDEZ, hipótese em que as informações do fraudador (nome,
          endereço, IP etc) poderão ser fornecidas ao prejudicado e às
          autoridades responsáveis (incluindo-se aí a DERCIFE - Delegacia
          Especializada de Repressão a Crimes contra a Informática e Fraudes
          Eletrônicas, ou outra Delegacia Especializada em Crimes Cibernéticos
          do respectivo estado), desde que mediante o recebimento de ordem
          judicial, intimando a entrega de ditas informações, ou no caso de
          legitimo interesse do CLOUDEZ de proteger seus interesses e dos demais
          USUÁRIOS.
          <br />
          3.3.1. Nesse sentido, e sem prejuízo de outras medidas, o CLOUDEZ
          poderá advertir, suspender ou cancelar, temporária ou definitivamente
          o CADASTRO de um USUÁRIO, a qualquer tempo, iniciando as ações legais
          cabíveis e/ou suspendendo a prestação de seus Serviços CLOUDEZ se: a)
          o USUÁRIO não cumprir qualquer dispositivo deste Termo e demais
          políticas do CLOUDEZ;
          <br />
          b) se descumprir com seus deveres de USUÁRIO;
          <br />
          c) se praticar atos fraudulentos ou dolosos, que possam provocar ações
          criminosas, ilícitas, disfuncionais ou moralmente censuráveis, ou que
          tenham como objetivo interferir no funcionamento e qualidade dos
          Serviços CLOUDEZ;
          <br />
          d) se não puder ser verificada a identidade do USUÁRIO ou qualquer
          informação fornecida por ele esteja incorreta. Sem prejuízo do acima,
          se ficar verificado que o USUÁRIO se utilizou dos Serviços CLOUDEZ
          para praticar atividades suspeitas, e não tenha apresentado
          documentação comprobatória da legalidade de suas atividades, o USUÁRIO
          sofrerá uma multa penal no valor de R$ 5.000,00 (cinco mil) reais,
          descontados diretamente do seu cartão de crédito e/ou conta de crédito
          informados no ato do CADASTRO, em uma parcela ou em diversas parcelas,
          caso o referido cartão e/ou conta não possua crédito para tanto, até a
          quitação integral da multa, além do pagamento de qualquer indenização
          que o CLOUDEZ tenha que efetuar em decorrência dos atos do referido
          USUÁRIO.
          <br />
          3.4. O USUÁRIO declara estar ciente de que somente poderá ter um único
          CADASTRO sob sua titularidade, não sendo, portanto, aceito pelo
          CLOUDEZ, em nenhuma hipótese, o CADASTRO e/ou novos logins simultâneos
          de titularidade do mesmo USUÁRIO (além dos 2 logins simultâneos
          permitidos), ou a cessão/transferência da respectiva conta, em
          decorrência de extravio de senha, verificando-se para tanto, entre
          outros dados, o nome do USUÁRIO, seu CPF/CNPJ, endereço de e-mail,
          dados sobre os responsáveis pela Pessoa Jurídica, caso esta seja a
          pretendente, entre outros dados, a critério do CLOUDEZ. Caso o USUÁRIO
          mude de endereço ou necessite atualizar qualquer informação de seu
          CADASTRO, ele deverá simplesmente atualizar seu CADASTRO com os novos
          dados.
          <br />
          3.5. Os Serviços CLOUDEZ estão disponíveis apenas para as pessoas que
          tenham capacidade legal para utilizá-las, portanto, pessoas que não
          gozem dessa capacidade, inclusive menores de idade, ou pessoas que
          tenham sido inabilitadas do CLOUDEZ, temporária ou definitivamente,
          por qualquer infração ao presente Termo, estão advertidas das sanções
          legais do Código Civil, notadamente, dos artigos 166, inciso I, 171,
          inciso I e 180 da lei n° 10.406/02. Ressalta-se que é terminantemente
          proibido que menores de idade naveguem e façam uso das diversos
          Serviços CLOUDEZ, sem o consentimento dos pais ou responsável, e o
          mesmo deverá apresenta-lo no ato do CADASTRO e contratação dos
          Serviços CLOUDEZ, para validação dos mesmos pelo CLOUDEZ.
          <br />
          3.6. O USUÁRIO expressamente reconhece e concorda com a RECORRÊNCIA
          cobrada pelo CLOUDEZ, como remuneração pela utilização dos Serviços
          CLOUDEZ, que serão pagos mediante a forma escolhida no ato em CADASTRO
          pelo USUÁRIO.
          <br />
          3.7. O apelido que o USUÁRIO utiliza no Site não poderá guardar
          semelhança com o nome CLOUDEZ. Também serão eliminados apelidos e
          imagens considerados ofensivos, ou de origem ilícita, bem como os que
          contenham dados pessoais do USUÁRIO ou alguma URL ou endereço
          eletrônico. O CLOUDEZ se reserva o direito de recusar qualquer
          solicitação de CADASTRO e de cancelar um CADASTRO previamente aceito,
          desde que em desacordo com as políticas e regras do presente Termo.
          <br />
          3.8. A não utilização do CADASTRO pelo USUÁRIO, definida como a
          ausência de qualquer acesso ao mesmo, pelo prazo de 01 (um) ano,
          ensejará o encerramento do referido CADASTRO, ficando o CLOUDEZ isento
          de qualquer responsabilidade ou ressarcimento em virtude deste
          cancelamento de CADASTRO. Para recuperação destes dados pelo USUÁRIO,
          este deverá respeitar os procedimentos previstos na Cláusula 4.7
          abaixo.
          <br />
          3.8.1. Para os USUÁRIOS que permanecem com um CADASTRO ativo e com os
          pagamentos da RECORRÊNCIA em dia, o CLOUDEZ manterá os dados e
          informações dos USUÁRIOS, atreladas ao CADASTRO do USUÁRIO, por prazo
          indeterminado, na forma prevista na legislação. Para os USUÁRIOS que
          realizem o cancelamento de seu CADASTRO, os procedimentos previstos na
          Cláusula 4.2 abaixo deverão ser seguidos.
          <br />
          3.9. O USUÁRIO será responsável pelo uso dos serviços providos no
          presente Termo, observando todas as leis, decretos e regulamentos
          nacionais, estaduais e municipais aplicáveis e em vigor, as Normas de
          Segurança e Privacidade e as Políticas publicadas e divulgadas pelo
          CLOUDEZ e por seus parceiros comerciais, constantes em seu Site.
          <br />
          3.10. O CLOUDEZ não será responsabilizado por erros, interrupções, mal
          funcionamentos, falhas de segurança, falhas nos servidores, provedores
          de serviços utilizados (como datacenter, internet, dentre outros) ou
          nos sistemas operacionais, atrasos ou outras imperfeições que possam
          surgir nos Serviços CLOUDEZ, inclusive pela qualidade e estabilidade
          de servidores terceirizados contratados, conforme pacote de Serviços
          CLOUDEZ, ou de utilização de aplicativos de propriedade alheia ao
          CLOUDEZ, que são possíveis de ocorrer em se tratando de informática,
          não garantindo, o CLOUDEZ, de nenhuma forma, a prestação dos Serviços
          CLOUDEZ de forma ininterrupta e/ou isenta de erros, exceto na forma
          prevista na Cláusula 3.1.1 acima.
          <br />
          3.11. O USUÁRIO será integralmente responsável por certificar-se de
          que a configuração de seu equipamento está em pleno acordo com os
          requisitos mínimos de segurança, necessários para proteger os seus
          dados e realizar, de forma pacífica, o acesso do Site e aos Serviços
          CLOUDEZ. A título exemplificativo mas não limitativo, o USUÁRIO deve
          garantir que os seus dados e de seus clientes finais contenham
          proteções físicas, administrativas e técnicas apropriadas com o padrão
          do setor (incluindo o uso de criptografia e outras ferramentas) para
          proteção contra qualquer violação, divulgação ou acesso não autorizado
          de quaisquer dados pessoais, informações confidenciais ou outros
          conteúdos ou informações que sejam armazenados, processados,
          transmitidos, mantidos ou de outra forma utilizados em conexão com os
          seus dados e Serviços CLOUDEZ. Sem limitação, as salvaguardas
          apropriadas devem incluir todas as salvaguardas exigidas pelas leis e
          regulamentos aplicáveis ??de segurança de dados e/ou privacidade de
          dados. O USUÁRIO será única e exclusivamente responsável pelas
          atividades de qualquer indivíduo ou entidade que obtenha acesso aos
          Serviços CLOUDEZ como resultado do descumprimento por parte do USUÁRIO
          de suas obrigações descritas acima, estando o CLOUDEZ livre e isento
          de qualquer responsabilidade decorrente da não observância do disposto
          nesta cláusula. Portanto, EM NENHUM CASO O CLOUDEZ SERÁ RESPONSÁVEL
          POR QUAISQUER DANOS DIRETOS OU INDIRETOS, RESULTANTES DE QUALQUER
          DEFEITO DE SEGURANÇA (INCLUINDO A FALHA DO USUÁRIO EM IMPLEMENTAR
          ADEQUADAMENTE AS FERRAMENTAS E SALVAGUARDAS DE SEGURANÇA APROPRIADAS)
          EM QUALQUER SOFTWARE, BANCO DE DADOS OU TECNOLOGIA USADO PELO USUÁRIO
          EM CONEXÃO COM OS SEUS DADOS OU DOS SEUS CLIENTES FINAIS OU COM OS
          SERVIÇOS CLOUDEZ.
          <br />
          <br />
          <h3>CLÁUSULA 4ª - DO VALOR DA RECORRÊNCIA DEVIDA PELO USUÁRIO</h3>
          <br />
          4.1. Em razão da utilização dos Serviços CLOUDEZ, seja diretamente,
          seja através da contratação por revendedores do CLOUDEZ, o USUÁRIO
          pagará ao CLOUDEZ, mensalmente, trimestralmente, semestralmente,
          anualmente, ou em qualquer outro período de RECORRÊNCIA disponível,
          por meio de boleto bancário ou de forma antecipada, por meio de cartão
          de crédito, o valor previsto na página de planos do CLOUDEZ, cujo
          vencimento ocorrerá até a data acordada no CADASTRO. O valor previsto
          no CADASTRO poderá sofrer alguma alteração, de acordo com os Serviços
          CLOUDEZ contratados posteriormente pelo USUÁRIO, e que serão
          acrescidos nos valores da RECORRÊNCIA.
          <br />
          4.1.1. Em contratações dos Serviços CLOUDEZ, independente do usufruto
          ou não do Período de Teste, conforme previsto no item 4.8.1 abaixo,
          para a segurança dos USUÁRIOS, o CLOUDEZ confirma os dados de
          pagamento e valida o cartão de crédito por este apresentado, enviando
          uma cobrança-teste no valor de R$1,00 (um real) ou valor próximo.
          Ressalta-se que esta cobrança-teste também acontecerá na mudança do
          cartão cadastrado, ou adição de novo cartão, no CADASTRO do USUÁRIO.
          Depois que a cobrança é feita, o valor é automaticamente estornado
          para o USUÁRIO, apesar do prazo de estorno variar, dependendo do banco
          emissor do cartão de crédito. Portanto, espera-se que esta
          cobrança-teste não seja lançada na fatura final do cartão de crédito e
          serve apenas para comprovar o cadastro de uma forma válida de
          pagamento dos Serviços CLOUDEZ.
          <br />
          4.1.2. Assim que uma RECORRÊNCIA é contratada, o vencimento ocorrerá
          no aniversário da data da contratação, realizada no ato do CADASTRO.
          Após o ciclo finalizado da primeira RECORRÊNCIA, o USUÁRIO autoriza
          desde já a renovação automática do seu CADASTRO, mediante aceite do
          presente termo.
          <br />
          4.1.3. Independente dos serviços contratados, o CLOUDEZ unifica as
          faturas, em caso de uma posterior contratação pelo USUÁRIO, e o
          USUÁRIO passa a ter uma única fatura e um único vencimento por mês. No
          entanto, se o USUÁRIO contratar uma nova RECORRÊNCIA, o valor é
          integralmente cobrado e esta nova RECORRÊNCIA que constará na próxima
          fatura do USUÁRIO. Se ainda houver saldo da RECORRÊNCIA anterior, o
          USUÁRIO receberá este valor como crédito.
          <br />
          4.1.4. Para o USUÁRIO que tenha contratado um plano com um desconto
          promocional, após o último aniversário da data de contratação
          promocional, os valores da RECORRÊNCIA serão automaticamente renovados
          nos valores vigentes do CLOUDEZ, já que o valor da RECORRÊNCIA com
          desconto é só válido pelo prazo informado, a contar da sua
          contratação, mesmo que o USUÁRIO efetue o pagamento antecipado, seja
          este de forma anual, por exemplo. Neste caso, caso o USUÁRIO não
          deseje a renovação automática, ele deverá seguir os procedimentos
          previstos na Cláusula 4.1.4 abaixo. Se este cancelamento não for
          efetuado na forma aqui prevista, presume-se que o USUÁRIO concordou
          com esta renovação automática dos valores da RECORRÊNCIA nos valores
          vigentes, independente se o pagamento é anual ou mensal ou qualquer
          outro período de RECORRÊNCIA disponibilizado pelo CLOUDEZ para
          contratação.
          <br />
          4.1.5. Caso o USUÁRIO não deseje a renovação automática, este deverá
          comunicar por email (financeiro@cloudez.io) ou diretamente na
          plataforma, através do acesso ao seu CADASTRO, o seu desejo da não
          renovação, que será efetuado em até 72 (setenta e duas) horas a contar
          do pedido, sendo que, se feito após a cobrança da RECORRÊNCIA, o mesmo
          só valerá a partir do próximo aniversário, não sendo reembolsada
          qualquer RECORRÊNCIA já paga (apesar do acesso ao CADASTRO do USUÁRIO
          somente ser bloqueado na data da próxima renovação, que foi
          cancelada). Ou seja, à título de exemplificação, se o USUÁRIO
          contratou uma RECORRÊNCIA com pagamento anual, mesmo que este efetue o
          cancelamento antes de usufruir do prazo todo contratado, nenhum
          estorno será devido se o USUÁRIO tenha cancelado após o prazo previsto
          na Cláusula 2.4.1, mesmo que de maneira pro rata, e os Serviços
          CLOUDEZ só serão suspensos após o último dia do período de RECORRÊNCIA
          contratado. O mesmo se aplica para as RECORRÊNCIAS mensais,
          trimestrais, semestrais ou qualquer outro período de RECORRÊNCIA
          disponibilizado pelo CLOUDEZ para contratação no ato da contratação
          pelo USUÁRIO.
          <br />
          4.2. Ao cancelar o seu CADASTRO, o USUÁRIO terá o prazo de 30 (trinta)
          dias a contar do último dia de acesso no Site de acesso dos Serviços
          CLOUDEZ para exportar os seus dados e dos demais USUÁRIOS que estavam
          atrelados ao seu contrato. Após este prazo, o CLOUDEZ não garantirá
          mais a disponibilidade dos dados do USUÁRIO, sendo que o USUÁRIO tem
          ciência que este possui uma obrigação de manter um backup físico
          destes arquivos pelos prazos exigidos em lei, independente dos
          serviços fornecidos pelo CLOUDEZ.
          <br />
          4.3. Novas RECORRÊNCIAS sempre serão contratadas pelos valores dos
          planos previstos no Site do CLOUDEZ.
          <br />
          4.4. No caso de pagamento com cartão de crédito, o CLOUDEZ efetuará
          tentativas de cobrança no cartão de crédito informado pelo USUÁRIO. Se
          o CLOUDEZ não lograr êxito em efetuar a devida cobrança da RECORRÊNCIA
          dentro dos 30 (trinta) dias posteriores à data de vencimento, o
          bloqueio do CADASTRO do USUÁRIO (e consequentemente os demais
          CADASTROS dos USUÁRIOS atrelados àquele contrato) será realizado
          automaticamente.
          <br />
          4.4.1. O atraso no pagamento da RECORRÊNCIA prevista no caput desta
          Cláusula, que não seja decorrente de caso fortuito ou força maior,
          implicará na incidência de multa de 10% (dez por cento), além de
          correção monetária e juros de mora de 2% (dois por cento) ao mês. Será
          facultado ao CLOUDEZ a suspensão do acesso ao CADASTRO do USUÁRIO até
          que este quite os valores pendentes, após 15 (quinze) dias de mora, ou
          até os prazos de rescisão dos Serviços CLOUDEZ sejam atingidos. O
          CLOUDEZ se reserva o direito de tomar as medidas judiciais e
          extrajudiciais pertinentes para receber os valores devidos.
          <br />
          4.4.2. Caso o USUÁRIO fique em mora mais do que 15 (quinze) dias a
          contar da notificação do CLOUDEZ, além das penalidades previstas
          acima, o USUÁRIO perderá o acesso aos servidores atrelados aos
          Serviços CLOUDEZ, sem qualquer notificação prévia além daquela do
          CLOUDEZ informando dos problemas de processamento do pagamento da
          RECORRÊNCIA. Este acesso será somente reestabelecido após o USUÁRIO
          quitar qualquer RECORRÊNCIA pendente. Portanto, mesmo que o USUÁRIO
          queira posteriormente rescindir os Serviços CLOUDEZ e exportar os seus
          dados dos servidores do CLOUDEZ, este deverá quitar qualquer valor de
          RECORRÊNCIA pendente.
          <br />
          4.4.3. Sem prejuízo do disposto no parágrafo anterior, o CLOUDEZ
          poderá dar o presente Termo por rescindido, bloqueado o acesso ao seu
          CADASTRO e os Serviços CLOUDEZ, caso o USUÁRIO permaneça inadimplente
          por mais de 30 (trinta) dias, sendo que o CLOUDEZ poderá ainda, a seu
          exclusivo critério, e independente de qualquer aviso, comunicar dita
          inadimplência aos órgãos de proteção de crédito, que promoverão a
          inscrição do USUÁRIO em seus cadastros (SERASA/SPC).
          <br />
          4.5. Os valores da RECORRÊNCIA contratada será reajustada
          automaticamente a cada 12 (doze) meses, no mês de junho, de acordo com
          o IGP-M ou, na sua ausência, com o índice que vier a substituí-lo,
          independentemente de prévia notificação ao USUÁRIO.
          <br />
          4.6. Caso o USUÁRIO não cancele seu CADASTRO, a RECORRÊNCIA será ainda
          devida, sempre na data do aniversário da contratação, mesmo que o
          CADASTRO seja bloqueado por falta de pagamento. Para desbloquear o seu
          CADASTRO (e consequentemente dos demais USUÁRIOS atrelados àquele
          contrato) e recuperar as informações, o USUÁRIO deverá quitar todos os
          valores pendentes, inclusive pelo período que o CADASTRO ficou
          bloqueado, para reativar o seu CADASTRO e dos demais USUÁRIOS, e ter
          acesso às suas informações.
          <br />
          4.7. Caso sejam criados novos tributos ou alteradas as condições de
          cálculo e/ou cobrança de tributos já existentes, que venham a impactar
          nos valores de remuneração vigentes, os custos resultantes de tal
          impacto poderão ser repassados ao USUÁRIO e somados à remuneração
          vigente, de forma a restabelecer o equilíbrio econômico-financeiro da
          prestação dos serviços.
          <br />
          4.8. Como a própria plataforma do CLOUDEZ já esteja totalmente
          integrada os seus parceiros de meio de pagamento digital, o USUÁRIO
          poderá efetuar o pagamento da RECORRÊNCIA escolhida para acesso aos
          Serviços CLOUDEZ diretamente no ambiente do CLOUDEZ. Com a confirmação
          do pagamento, o acesso aos Serviços CLOUDEZ.
          <br />
          4.8.1. O CLOUDEZ, buscando respeitar a legislação aplicável ao
          consumidor com relação ao direito de arrependimento dos Serviços
          CLOUDEZ adquiridos por USUÁRIOS através do pedido self service do site
          https://cloudez.io, que é de 07 (sete) dias a contar do CADASTRO, o
          CLOUDEZ outorga um período de até 5 (cinco) dias de teste gratuito dos
          Serviços CLOUDEZ que podem ser utilizados na fase de teste ("Período
          de Teste"). Qualquer dado que o USUÁRIO inserir nos Serviços CLOUDEZ e
          qualquer personalização efetuada neles durante o período de teste irá
          se perder, permanentemente, a menos que: (i) o USUÁRIO adquira um
          plano de RECORRÊNCIA, insira os seus dados de cartão de crédito e/ou
          boleto bancário e efetue o seu pagamento, para os mesmos Serviços
          CLOUDEZ que tenha interesse; ou (ii) exporte os dados antes do final
          do Período de Teste. Imediatamente após o fim do Período de Teste,
          todos os dados do CADASTRO e dos USUÁRIOS atrelados serão excluídos,
          independente do ato de exportação, sendo obrigação do próprio USUÁRIO
          manter um backup físico destes arquivos pelo prazo exigido por lei e
          pelos respectivos códigos de ética e resoluções.
          <br />
          4.8.2. Caso, durante o Período de Teste, ou mesmo logo após o seu
          encerramento, o USUÁRIO contratar um dos planos de RECORRÊNCIA
          disponíveis no Site e efetuar o seu pagamento e/ou não efetuar o seu
          cancelamento antes de concluir o Período de Teste, entender-se-á que o
          USUÁRIO renunciou tacitamente ao seu direito de arrependimento e a
          quaisquer direitos referentes ao presente, passado e futuro, relativos
          a fatos, obrigações e responsabilidades, direta ou indiretamente
          relacionados ao Período de Teste dos Serviços CLOUDEZ, nada mais tendo
          a reclamar do CLOUDEZ, seja a que título for, judicial ou
          extrajudicialmente, em que tempo for.
          <br />
          <br />
          <h3>CLÁUSULA 5ª - DAS MODIFICAÇÕES NO PRESENTE TERMO.</h3>
          <br />
          O CLOUDEZ poderá alterar, a qualquer tempo, as condições do presente
          Termo, visando seu aprimoramento e melhoria dos serviços prestados. As
          novas condições entrarão em vigor 10 (dez) dias após sua publicação no
          Site. Sendo assim, caso o USUÁRIO não concorde com as modificações
          publicadas, este deverá imediatamente cessar o uso dos Serviços
          CLOUDEZ, proceder com o cancelamento de suas informações e dados,
          mediante pedido de cancelamento diretamente na plataforma prestadora
          dos Serviços CLOUDEZ, na área de administração do CADASTRO do USUÁRIO,
          sob pena de responder pelos danos gerados a terceiros e ao CLOUDEZ
          pela retirada tardia dos dados armazenados em seus servidores. Neste
          caso, o vínculo contratual deixará de existir. No entanto, não havendo
          manifestação no prazo acima estipulado, entender-se-á que o USUÁRIO
          aceitou tacitamente as novas condições do Termo e continuará
          vinculando as partes.
          <br />
          <br />
          <h3>CLÁUSULA 6ª - DOS PRODUTOS E PRÁTICAS PROIBIDOS</h3>
          <br />
          6.1. Estão expressamente vedadas as seguintes práticas com os Serviços
          CLOUDEZ, que contenham relação ou que façam apologia a: (i) atividades
          ilícitas; (ii) Narcóticos; (iii) propriedades roubadas, furtadas ou
          subtraídas, ou de qualquer origem ilícita, tais como contrabando,
          falsificações ou adulterações; (iv) Produtos e serviços relacionados à
          prostituição ou similares; (v) Produtos e serviços que promovam a
          violência e/ou discriminação, baseada em questões de raça, sexo,
          religião, nacionalidade, orientação sexual ou de qualquer outro tipo;
          (vi) Produtos e serviços que violem as leis sobre a propriedade
          intelectual, tais como cópias não autorizadas, de imagens ou textos
          sem autorização do autor, sejam elas marcas, símbolos, réplicas e/ou
          falsificações; (vii) Produtos e serviços relacionados a loterias,
          rifas e jogos de azar; e (viii) quaisquer outros produtos e serviços
          vedados na legislação vigente.
          <br />
          6.1.1. Neste sentido, é responsabilidade exclusiva do USUÁRIO velar
          pela legalidade de suas atividades e dados e o CLOUDEZ não assume
          nenhuma responsabilidade pela existência deles nos Serviços CLOUDEZ,
          podendo suspender os Serviços CLOUDEZ e/ou excluir os USUÁRIOS que não
          cumpram com as restrições descritas no presente Termo. Portanto, o
          USUÁRIO será o único e exclusivo responsável perante o CLOUDEZ ou
          terceiros, pela transgressão desta regra que resulte em infração a
          direitos intelectuais de terceiros, estando o CLOUDEZ isenta de
          qualquer responsabilidade perante o titular destes direitos.
          <br />
          6.2. Os USUÁRIOS também não poderão, entre outras atitudes previstas
          no presente Termo: a) manipular os preços dos Planos e/ou informações
          do CLOUDEZ;
          <br />
          b) agredir, caluniar, injuriar ou difamar o CLOUDEZ e/ou outros
          USUÁRIOS, por qualquer meio disponível para a comunicação dos
          USUÁRIOS;
          <br />
          c) compartilhar screenshots, vídeos, fotos, textos ou conteúdos
          próprios do CLOUDEZ a com nenhum terceiro sem a devida autorização por
          escrito do CLOUDEZ;
          <br />
          d) usar qualquer robô, mecanismo de busca, aplicativo de
          pesquisa/recuperação de sites ou outro dispositivo para recuperar ou
          indexar qualquer parte dos Serviços CLOUDEZ ou coletar informações
          sobre os USUÁRIOS para qualquer finalidade não autorizada;
          <br />
          e) enviar conteúdo com propaganda enganosa afirmando que tal conteúdo
          é patrocinado ou endossado pelo CLOUDEZ;
          <br />
          f) criar contas de USUÁRIOS por meios automatizados ou com intenções
          falsas ou fraudulentas;
          <br />
          g) transmitir quaisquer vírus, worms, defeitos, cavalos de Tróia ou
          quaisquer itens de natureza destrutiva;
          <br />
          h) modificar, realizar uma engenharia reversa, descompilar, desmontar,
          copiar, criar espelhos, distribuir, sublicenciar, vender, revender,
          arrendar, dar em garantia, doar, alienar, criar trabalhos derivados
          com base em, ou de outra forma explorar, toda ou qualquer parte
          relacionada aos Serviços CLOUDEZ;
          <br />
          i) nomear, autorizar ou permitir que qualquer pessoa revenda, subloca,
          sublicença ou distribua os Serviços CLOUDEZ, ou qualquer outro recurso
          da plataforma do CLOUDEZ, a qualquer terceiro, ou permita que
          terceiros não autorizados acessem ou utilizem, total ou parcialmente,
          os Serviços CLOUDEZ; e
          <br />
          j) acessar ou usar os Serviços CLOUDEZ a fim de: (j.1) construir um
          produto ou serviço concorrente, (j.2) copiar quaisquer ideias,
          características, funções ou gráficos dos Serviços CLOUDEZ. Estes tipos
          de comportamento poderão ser sancionados com a suspensão ou
          cancelamento do seu CADASTRO como USUÁRIO do CLOUDEZ, sem prejuízo das
          ações legais que possam ocorrer pela configuração de delitos ou
          contravenções ou os prejuízos civis que possam causar aos USUÁRIOS.
          <br />
          6.3. Caso o CLOUDEZ receba denúncia, ou suspeite que algum USUÁRIO
          esteja violando alguma condição especifica do presente Termo ou que
          seja considerada uma atividade ilícita, além das medidas de proteção
          já descriminadas no presente Termo, tal como a suspensão ou o
          cancelamento do referido CADASTRO, os dados do USUÁRIO infrator serão
          encaminhados às autoridades correspondentes, para que estas tomem as
          medidas judiciais cabíveis.
          <br />
          <br />
          <h3>CLÁUSULA 7ª - DA PRIVACIDADE DA INFORMAÇÃO</h3>
          <br />
          7.1. A plataforma prestadora dos Serviços CLOUDEZ utiliza-se do
          aplicativo de "cookies" (dados no computador, tablets, celular(es) ou
          quaisquer outros dispositivos móveis do USUÁRIO) para permitir sua
          correta identificação, além de melhorar a qualidade das informações
          oferecidas em seu portal para os USUÁRIOS. O uso regular de cookies é
          uma prática aceitável na indústria, pois permite o armazenamento de
          informações importantes, como, por exemplo, os acessos do USUÁRIO na
          plataforma prestadora dos Serviços CLOUDEZ, para posteriormente não
          ser apresentado um conteúdo repetido ou ultrapassado. Se não for a
          vontade do USUÁRIO receber cookies, ou o mesmo desejar uma notificação
          prévia antes de seu uso, ele deverá programar, a seu critério, seu Web
          browser (navegador da Web) com estas opções, caso seu browser disponha
          do recurso. No entanto, fica desde já o USUÁRIO ciente e de acordo que
          se ele não autorizar o uso de cookies pela CLOUDEZ, ele não poderá
          acessar os Serviços CLOUDEZ.
          <br />
          7.2. Além das informações pessoais fornecidas, a plataforma prestadora
          dos Serviços CLOUDEZ tem a capacidade tecnológica de recolher outras
          informações técnicas, como o endereço de protocolo de Internet do
          USUÁRIO, o sistema operacional do computador, do tablet ou do celular,
          o tipo de browser e o endereço de websites de referência, além de
          outras informações relevantes ao funcionamento dos seus serviços, mas
          que respeitam a privacidade e a individualidade do USUÁRIO,
          respeitando os termos e limites do Marco Civil da Internet e da Lei de
          Proteção de Dados Pessoais.
          <br />
          7.3. Conforme já determinado anteriormente, o CLOUDEZ não fornecerá as
          informações do USUÁRIO a terceiros sem prévia autorização do mesmo,
          com exceção de casos que o CLOUDEZ já possui a autorização para o seu
          compartilhamento, conforme o presente Termo, ou quando são necessários
          para responder às solicitações ou perguntas de entidades
          governamentais, ou nos casos onde, de boa fé e com base no seu
          legitimo interesse, o CLOUDEZ entender que é necessária a sua
          divulgação, desde com pedido judicial e/ou com o propósito de
          responder às reclamações que o conteúdo submetido aos Serviços CLOUDEZ
          infringe direitos de terceiros, ou seja, necessária para a proteção de
          direitos, propriedades e/ou segurança do CLOUDEZ, de seus USUÁRIOS
          e/ou do público em geral.
          <br />
          7.3.1. Portanto, com a contratação dos Serviços CLOUDEZ, o USUÁRIO
          concede expressamente ao CLOUDEZ o direito de processar, manter,
          reproduzir, armazenar, usar e/ou divulgar os dados do USUÁRIO: (i)
          conforme necessário para fornecer os Serviços CLOUDEZ (inclusive
          divulgando estes dados a terceiros que suportam o fornecimento de
          Serviços CLOUDEZ), (ii) conforme expressamente permitido por este
          Termo, ou (iii) conforme exigido por lei, regulamento, ordem judicial,
          intimação ou autoridade governamental. O USUÁRIO é o único e exclusivo
          responsável por obter todos os direitos e consentimentos necessários
          (incluindo tais direitos e consentimentos de seus clientes finais, se
          aplicável) para o CLOUDEZ usar, processar e armazenar todos os dados
          do USUÁRIO para fins de prestação dos Serviços CLOUDEZ.
          <br />
          7.4. O CLOUDEZ poderá, a seu critério, fazer uso das informações
          armazenadas nos seus bancos de dados, conforme descrito acima, por um
          prazo razoável, sem que exceda os requisitos ou limitações legais,
          para dirimir quaisquer disputas, solucionar problemas e garantir os
          direitos do CLOUDEZ, assim como os termos e condições presentes na
          Política de Privacidade.
          <br />
          7.5. O CLOUDEZ deverá também, a seu critério, examinar as informações
          armazenadas nos seus bancos de dados com o propósito de identificar
          USUÁRIOS que estejam infringindo as políticas de segurança do CLOUDEZ,
          com múltiplas identidades ou pseudônimos para fins legais e/ou de
          segurança. Em outra hipótese, se o CLOUDEZ for obrigado por lei, ordem
          judicial ou outro processo legal, a divulgar alguma informação pessoal
          do USUÁRIO, não hesitará em cooperar com estes agentes. Assim, por
          meio deste instrumento, o USUÁRIO autoriza o CLOUDEZ a divulgar estas
          informações pessoais para atender aos fins acima indicados.
          <br />
          7.6. Além disso, o USUÁRIO concede ao CLOUDEZ uma licença para
          despersonalizar (remover quaisquer informações de identificação
          pessoal protegidas pela lei aplicável) e agregar qualquer um dos dados
          do USUÁRIO para determinar tendências de uso, realizar análises,
          melhorar os Serviços CLOUDEZ, ou para qualquer outro fim comercial,
          desde que tais usos estejam relacionados às obrigações do CLOUDEZ de
          fornecer os Serviços CLOUDEZ ao USUÁRIO. Portanto, as informações
          cedidas pelo USUÁRIO e registradas devido ao uso do sistema (com
          exceção ao conteúdo de mensagens pessoais), mas que não identifiquem
          pessoalmente o USUÁRIO, servirão como insumos para o mapeamento de
          informações de mercado e formação de estatísticas do CLOUDEZ sem
          qualquer obrigação ou responsabilidade do CLOUDEZ em relação a
          qualquer uso dos dados do USUÁRIO que seja anonimizado ou
          desatualizado, a menos que e na medida em que seja restrito pela lei.
          Através do cadastramento, uso e fornecimento de informações ao
          CLOUDEZ, o USUÁRIO deliberadamente aceita o presente Termo e as
          condições previstas na Política de Privacidade sobre o uso de suas
          informações.
          <br />
          7.7. As informações cedidas pelo USUÁRIO que o torna pessoalmente
          identificável têm como único objetivo fazer com os USUÁRIOS do CLOUDEZ
          se relacionem melhor com os Serviços CLOUDEZ. Informações adicionais
          coletadas pelos Serviços CLOUDEZ através da análise da navegação de
          cada USUÁRIO e que não o torne identificável pessoalmente (como o
          padrão de navegação, por exemplo) serão utilizados o CLOUDEZ do modo
          que melhor julgar apropriada para melhorar os seus serviços, ou na
          forma prevista na Cláusula 7.6 acima. Além disso, as informações
          fornecidas são usadas para: (i) administrar a conta dos USUÁRIOS a fim
          de customizar cada vez mais os Serviços CLOUDEZ; e (ii) comunicar
          novidades e atualizações. Porém, o CLOUDEZ pode eventualmente
          retransmitir mensagens de parceiros, através do protocolo opt-in. No
          entanto, o CLOUDEZ não se responsabiliza pela guarda ou conservação
          dos dados pessoais de seus USUÁRIOS ao acessarem estes links de
          terceiros, sendo de exclusiva responsabilidade do USUÁRIO verificar a
          correspondente política de privacidade. A responsabilidade pela guarda
          e conservação dos dados pessoais dos USUÁRIOS do CLOUDEZ se limita
          somente aos dados que são coletados, na forma acima, pelo uso normal
          dos Serviços CLOUDEZ.
          <br />
          7.8. O USUÁRIO que não concordar com o presente Termo e proceder com o
          cancelamento de seu CADASTRO, removerá seus dados para futuras
          consultas, mas o CLOUDEZ ainda manterá algumas informações, única e
          exclusivamenter para atender às exigências legais a que está
          submetido, nos limites do Marco Civil e da Lei de Proteção de Dados, e
          as fornecerá somente sob ordem judicial..
          <br />
          7.8.1. Neste sentido, para cancelar seu CADASTRO nos Serviços CLOUDEZ,
          o USUÁRIO o poderá efetuar através do painel de configurações ou
          mediante pedido aos administradores dos Serviços CLOUDEZ pelo e-mail
          financeiro@cloudez.io.
          <br />
          7.9. Não é permitida a utilização de nenhum dispositivo, software ou
          outro recurso que venha a interferir nas atividades e operações dos
          Serviços CLOUDEZ, bem como nas contas ou seus bancos de dados.
          Qualquer intromissão, tentativa de, ou atividade que viole ou
          contrarie as leis de direito de propriedade intelectual e/ou as
          proibições estipuladas neste Termo, inclusive que realizem cópias não
          autorizadas, engenharia reversa, e/ou modificações que possam criar
          obras derivadas, tornarão o responsável passível das ações legais
          pertinentes, bem como das sanções aqui previstas, sendo ainda
          responsável pelas indenizações por eventuais danos causados.
          <br />
          7.10. Em caso de dúvidas sobre a proteção a dados pessoais, ou para
          obter maiores informações sobre dados pessoais e os casos excepcionais
          nos quais poderá ser quebrado o sigilo de que trata esta cláusula,
          consultar a seção Contato do Site do CLOUDEZ.
          <br />
          <br />
          <h3>CLÁUSULA 8ª - DO PRAZO E DA VIGÊNCIA</h3>
          <br />
          8.1. O presente Termo possui prazo indeterminado, entrando em vigor na
          data de seu aceite eletrônico. O USUÁRIO poderá rescindir a prestação
          dos Serviços CLOUDEZ e proceder com os procedimentos de rescisão aqui
          dispostos a qualquer momento, mediante comunicação prévia e expressa à
          outra parte no prazo de 30 (trinta) dias, desde eventuais débitos
          existentes (custos, despesas, encargos operacionais) ou eventuais
          indenizações decorrentes de lesão a terceiros por conta do mal-uso dos
          Serviços CLOUDEZ sejam quitados. No entanto, qualquer cancelamento não
          ensejará o reembolso de qualquer RECORRÊNCIA já contratada, conforme
          previsto na Cláusula 4.1.4 acima.
          <br />
          8.2. O CLOUDEZ poderá rescindir o presente Termo e excluir algum
          USUÁRIO de sua base de dados e de sua plataforma, de pleno direito,
          independentemente de qualquer notificação ou interpelação, judicial ou
          extrajudicial, sem prejuízo de demandar as perdas e danos, nas
          seguintes hipóteses: (a) pela quebra de qualquer de suas disposições,
          de forma a impedir a continuidade da execução do objeto do mesmo; (b)
          se o USUÁRIO, de qualquer forma, comprometer a imagem pública do
          CLOUDEZ e/ou de qualquer empresa associada a esta na prestação dos
          Serviços CLOUDEZ; (c) se o USUÁRIO utilizar de práticas que
          desrespeitem a lei, a ordem pública, a moral, os bons costumes ou,
          ainda, o presente Termo e a política de segurança e privacidade, e/ou
          faça mal uso dos Serviços CLOUDEZ, inclusive na forma de envio de
          SPAM; (d) se o USUÁRIO desrespeitar direitos de propriedade
          intelectual do CLOUDEZ e/ou de terceiros, ou faça mal uso dos Serviços
          CLOUDEZ, inclusive infringindo a privacidade e os dados pessoais de
          terceiros, conforme definido em legislação; (e) se o USUÁRIO
          desrespeitar os colaboradores do CLOUDEZ com ofensas ou com colocações
          que desrespeitem a lei, a ordem pública, a moral, os bons costumes;
          (f) se o USUÁRIO exigir serviços superiores às forças do CLOUDEZ,
          defesos por lei ou contrários aos bons costumes; (g) se o USUÁRIO
          tratar o CLOUDEZ com rigor excessivo de forma degradante; e/ou (h)
          pelo não pagamento de uma ou mais das RECORRÊNCIAS e/ou outras
          remunerações devidas.
          <br />
          <br />
          <h3>CLÁUSULA 9ª - DAS RESPONSABILIDADES</h3>
          <br />
          9.1. O CLOUDEZ não possui qualquer ingerência sobre os dados
          armazenados pelos USUÁRIOS nos servidores, não gerando a aceitação do
          presente Termo qualquer contrato de sociedade, de mandato, franquia ou
          relação de trabalho entre o CLOUDEZ e o USUÁRIO. No entanto, o CLOUDEZ
          mantem um controle estrito com relação às transações de seus USUÁRIOS
          e os dados armazenados em suas dependências, periodicamente
          avaliando-os e retirando de sua base quaisquer USUÁRIOS que não
          atendam aos padrões de ética e boa-fé do CLOUDEZ ou que tenham sido
          objeto de denúncia de terceiros. Neste sentido, em nenhum caso, o
          CLOUDEZ será responsável pelo lucro cessante ou por qualquer outro
          dano e/ou prejuízo que o USUÁRIO possa sofrer devido ao armazenado
          incorreto dos dados nos servidores, seja estes contratados
          pessoalmente pelo USUÁRIO, ou contratados a pedido USUÁRIO pelo
          CLOUDEZ, conforme pacote de serviços escolhido.
          <br />
          9.2. O CLOUDEZ não se responsabiliza pela qualidade, estado,
          integridade ou legitimidade dos dados armazenados pelos USUÁRIOS nos
          servidores, assim como pela capacidade para contratar dos USUÁRIOS ou
          pela veracidade dos dados pessoais por eles inseridos em seus
          CADASTROS. O CLOUDEZ também não outorga garantia por vícios ocultos ou
          aparentes nos dados armazenados pelos USUÁRIOS, sendo o armazenamento
          pelo USUÁRIO feito por sua própria conta e risco.
          <br />
          9.3. Em caso de interpelação judicial que tenha como réu o CLOUDEZ,
          cujos fatos fundem-se em ações do USUÁRIO, este será chamado ao
          processo devendo arcar com todos os ônus que daí decorram, nos termos
          do Código de Processo Civil. Da mesma forma, o USUÁRIO indenizará o
          CLOUDEZ, suas filiais, empresas controladas ou controladoras,
          diretores, administradores, colaboradores, representantes,
          fornecedores e empregados por qualquer demanda promovida por outros
          USUÁRIOS ou terceiros decorrentes de suas atividades no Site ou por
          seu descumprimento do presente Termo ou pela violação de qualquer lei
          ou direitos de terceiros, incluindo honorários de advogados.
          <br />
          9.4. O CLOUDEZ não se responsabiliza pelas obrigações tributárias que
          recaiam sobre as atividades dos USUÁRIOS que se utilizam dos Serviços
          CLOUDEZ.
          <br />
          9.5. O CLOUDEZ não se responsabiliza por qualquer dano direto,
          indireto, incidenciais, especiais, punitivos, exemplares ou
          consequentes, bem como qualquer prejuízo ou perda de receita, perda de
          dados ou lucro cessante do USUÁRIO causado por falhas no sistema
          prestador dos Serviços CLOUDEZ, no servidor ou na internet, seja
          decorrentes de condutas de terceiros, caso fortuito ou força maior, ou
          da própria tecnologia utilizada pelo CLOUDEZ para suportar os Serviços
          CLOUDEZ, conforme definido na legislação vigente, ou por qualquer
          outro evento alheio ao controle do CLOUDEZ, tais como o acesso,
          interceptação, eliminação, alteração, modificação ou manipulação, por
          terceiros não autorizados, do conteúdo disponibilizado pelo USUÁRIO
          nos servidores. O CLOUDEZ também não será responsável por qualquer
          vírus que possa atacar o equipamento do USUÁRIO em decorrência do
          acesso, utilização ou navegação no Site, em virtude da internet; ou
          como consequência da transferência de dados. Os USUÁRIOS não poderão
          atribuir ao CLOUDEZ nenhuma responsabilidade nem exigir o pagamento
          por lucro cessante em virtude de prejuízos resultantes de dificuldades
          técnicas ou falhas nos sistemas ou na internet. Eventualmente, o
          CLOUDEZ poderá não estar disponível por motivos técnicos ou falhas da
          internet, ou por qualquer outro evento fortuito ou de força maior
          alheio ao controle do CLOUDEZ. Na máxima extensão permitida por lei, a
          responsabilidade agregada do CLOUDEZ em virtude da prestação dos
          Serviços CLOUDEZ não deverá exceder a quantidade de taxas pagas pelo
          respectivo USUÁRIO nos 12 (doze) meses anterior à data da reclamação.
          Os recursos fornecidos por este Termo são exclusivos, aplicam-se a
          todas as causas de ação e aplicam-se mesmo que uma parte declare que
          tinha conhecimento anterior que tais danos seriam possíveis e/ou mesmo
          que um recurso falhe em seu propósito essencial. Para mais
          informações, acesse a Política de Backup do CLOUDEZ, localizado no
          https://cloudez.io/ajuda/backups/.
          <br />
          <br />
          <h3>CLÁUSULA 10ª - DA PROPRIEDADE INTELECTUAL DO CLOUDEZ</h3>
          <br />
          10.1. O uso comercial da expressão "CLOUDEZ" como marca, nome
          empresarial ou nome de domínio, bem como os conteúdos das telas
          relativas aos Serviços CLOUDEZ assim como os programas, bancos de
          dados, redes e arquivos, que permitem que o USUÁRIO acesse e use sua
          conta, são propriedade do CLOUDEZ e estão protegidos pelas leis e
          tratados internacionais de direito autoral, marcas, patentes, modelos
          e desenhos industriais. O uso indevido e a reprodução total ou parcial
          dos referidos conteúdos são proibidos, salvo quando autorizados
          expressamente pelo CLOUDEZ.
          <br />
          10.2. Os Serviços CLOUDEZ poderá conectar o USUÁRIO a outros sites da
          internet, o que não significa que esses sites sejam de propriedade ou
          operados pelo CLOUDEZ. Como não possui controle sobre esses sites, o
          CLOUDEZ não será responsável pelos conteúdos, práticas e serviços
          ofertados nos mesmos. A presença de links para outros sites não
          implica relação de sociedade, de supervisão, de cumplicidade ou
          solidariedade do CLOUDEZ para com esses sites e seus conteúdos.
          <br />
          10.3. Deverá ser expressamente autorizada pelo CLOUDEZ a reprodução,
          exibição, distribuição e/ou alteração de quaisquer dos materiais,
          serviços e conteúdos do Site, uma vez que o mero uso dos Serviços
          CLOUDEZ pelo USUÁRIO não significa em nenhuma hipótese a cessão ou a
          transferência dos direitos sobre sua propriedade intelectual, bem como
          a de terceiros.
          <br />
          10.3.1. Portanto, como os Serviços CLOUDEZ são licenciados e não
          vendidos sob demanda, o CLOUDEZ única e exclusivamente retém todos os
          direitos, títulos e interesses referentes aos Serviços CLOUDEZ, bem
          como a todos os direitos de propriedade intelectual e obras derivadas
          relacionadas aos mesmos. Da mesma forma, qualquer produto de trabalho
          concebido ou desenvolvido pelo CLOUDEZ em conexão com o desempenho de
          qualquer Serviço CLOUDEZ pertencerá única e exclusivamente ao CLOUDEZ.
          Caso o USUÁRIO ou qualquer um de seus clientes finais envie ideias,
          sugestões, melhorias propostas ou outro feedback relacionado aos
          Serviços CLOUDEZ (coletivamente, "Feedback"), o CLOUDEZ poderá
          utilizar tal Feedback sem qualquer necessidade de remuneração para o
          USUÁRIO, cedendo portanto o USUÁRIO (ou seus clientes finais, conforme
          o caso) todos os direitos sobre tal Feedback ao CLOUDEZ.
          <br />
          <br />
          <h3>CLÁUSULA 11ª - DAS DISPOSIÇÕES GERAIS E DO FORO</h3>
          <br />
          11.1. O USUÁRIO expressamente aceita que o CLOUDEZ e/ou qualquer de
          seus parceiros enviem aos seus assinantes mensagens de e-mail de
          caráter informativo, referentes a comunicações específicas inerentes
          aos Serviços CLOUDEZ prestados sob este Termo, bem como de natureza
          comercial, incluindo, entre outras, a ofertas dos parceiros do
          CLOUDEZ, novidades no Site, entre outras informações. Caso o USUÁRIO
          não deseje mais receber referidas mensagens poderá solicitar o
          cancelamento pelo Site ou pelo link do próprio e-mail recebido.
          <br />
          11.2. A fim de manter e atender os últimos requisitos de mercado e de
          desenvolvimento tecnológicos, o CLOUDEZ poderá alterar, a seu
          critério, tanto em forma como em conteúdo, suspender ou cancelar, a
          seu exclusivo critério, a qualquer tempo, quaisquer dos Serviços
          CLOUDEZ, produtos, utilidade ou aplicação, disponibilizados por si ou
          por terceiros, mediante prévio aviso ao USUÁRIO.
          <br />
          11.3. A tolerância do CLOUDEZ com o USUÁRIO, relativamente ao
          descumprimento de qualquer das obrigações ora assumidas, não será
          considerada novação ou renúncia a qualquer direito, constituindo mera
          liberalidade, que não impedirá o CLOUDEZ de exigir posteriormente o
          fiel cumprimento das condições previstas neste Termo, a qualquer
          tempo.
          <br />
          11.4. O USUÁRIO não poderá ceder o seu CADASTRO para terceiros ou
          outros USUÁRIOS. Por sua vez, o CLOUDEZ poderá, independentemente de
          qualquer aviso ou notificação, ceder os Serviços CLOUDEZ, no todo ou
          em parte, a empresas parceiras ou de seu grupo econômico, a qualquer
          momento.
          <br />
          11.5. O USUÁRIO declara e concorda expressamente: a) ser maior de 18
          anos e possuir capacidade jurídica;
          <br />
          b) em instalar e manter atualizados programas Anti Spywares,
          Anti-vírus e outros que impeçam a violação do sistema que é utilizado
          para ter acesso aos Serviços CLOUDEZ;
          <br />
          c) não utilizar os Serviços CLOUDEZ para quaisquer transações
          ilícitas;
          <br />
          d) em manter o sigilo de sua SENHA da conta cadastrada, não os
          fornecendo a terceiros;
          <br />
          e) em notificar imediatamente o CLOUDEZ caso tome ciência de qualquer
          violação de segurança relacionada aos Serviços CLOUDEZ ou uso não
          autorizado de seu nome de USUÁRIO e SENHA;
          <br />
          f) ter condições financeiras de arcar com os pagamentos, custos e
          despesas decorrentes das transações efetuadas com o CLOUDEZ;
          <br />
          g) reconhecer que o presente Termo se formaliza, vinculando as partes,
          com a sua aceitação eletrônica pelo USUÁRIO, o que se fará mediante o
          clique no botão "Aceito os Termos de Uso";
          <br />
          h) que leu e está ciente e de pleno acordo com todos os termos e
          condições do presente Termo, razão pela qual o aceita de livre e
          espontânea vontade. 11.6. Assim, tendo em vista as cláusulas do
          presente Termo, o USUÁRIO concorda integralmente com essas
          disposições, se comprometendo a respeitar as condições aqui previstas
          de forma irretratável e irrevogável, bem como a utilizar de modo
          proveitoso e legal os serviços a ele oferecidos.
          <br />
          11.7. Todos os itens deste Termo de Uso estão regidos pelas leis
          vigentes na República Federativa do Brasil. Para todos os assuntos
          referentes à sua interpretação e cumprimento, as partes se submeterão
          ao Foro Central da Comarca de Brasília, Distrito Federal, exceção
          feita a reclamações apresentadas por USUÁRIOS que se enquadrem no
          conceito legal de consumidores, que poderão submeter ao foro de seu
          domicílio.
          <Link to="/register">Voltar</Link>
        </Content>
      </Card>
    </>
  );
};

export default Terms;
