import styled from 'styled-components';

const SidebarTag = styled.div`
  width: 50px;

  background: #fcff7c;

  font-size: 10px;
  font-weight: 900;
  text-align: center;
  line-height: 20px;

  border-radius: 6px 0 0 6px;

  position: absolute;
  bottom: 50%;
  right: 0;
  transform: translateY(50%);

  color: ${props => props.theme.brand_primary_color};
`;

export default SidebarTag;
