import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 32px;

  background-color: ${props => props.theme.interface1};

  padding: 32px;
`;

export const Card = styled.div`
  display: grid;

  justify-items: center;

  grid-template-rows: 70px 40px 80px 30px;

  max-width: 240px;

  padding: 30px 25px;

  border: 1px solid ${props => props.theme.interface3};
  border-radius: 5px;

  .title {
    font-weight: bold;
    font-size: 16px;

    color: ${props => props.theme.interface7};
  }

  .summary {
    font-weight: normal;
    font-size: 14px;

    color: ${props => props.theme.interface5};
  }
`;
