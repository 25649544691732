import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  ListingHeader,
  Listing,
  ListingItem,
  ApplicationValue,
  CloudValue,
} from 'components/Listing/styles';
import CustomAnimatedList from 'components/CustomAnimatedList';

const TicketPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col xs="10" sm="10" md="4" lg="5">
            <p>Ticket</p>
          </Col>
          <Col className="d-none d-md-flex">ÁREA</Col>
          <Col className="d-none d-md-flex">
            <p>CRIADO EM </p>
          </Col>
          <Col className="d-none d-md-flex">
            <p>ATUALIZADO EM</p>
          </Col>
          <Col className="d-none d-md-flex" />
        </Row>
      </ListingHeader>

      <Listing>
        <CustomAnimatedList>
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card>
                <CardContent>
                  <Col xs="10" sm="10" md="4" lg="5">
                    <ApplicationValue>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: 12,
                        }}
                      >
                        <Placeholder width={20} height={8} />
                      </div>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-md-flex">
                    <ApplicationValue>
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: 12,
                          flexDirection: 'column',
                        }}
                      >
                        <Placeholder width={50} height={8} />
                      </div>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-xl-flex">
                    <ApplicationValue style={{ fontWeight: 'normal' }}>
                      <CloudValue>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Placeholder width={50} height={8} />
                        </div>
                      </CloudValue>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={8} />
                    </ApplicationValue>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </CustomAnimatedList>
      </Listing>
    </>
  );
};

export default TicketPlaceholder;
