import styled from 'styled-components';

interface IWrapperProps {
  type: string;
  button?: {
    text?: string;
  };
}

export const Wrapper = styled.div<IWrapperProps>`
  z-index: 999;
  height: 60px;
  width: 100%;
  padding: 15px 115px;
  background: ${props =>
    props.type === 'warning'
      ? '#EFA703'
      : props.type === 'danger'
      ? 'rgba(251, 38, 75, 0.15)'
      : 'white'};
  position: fixed;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-around;

  span {
    max-width: 70%;

    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: ${props =>
      props.type === 'danger' ? '#5a5a5a' : props.theme.interface1};
    font-weight: ${props => (props.type === 'danger' ? 'bold' : '')};
    opacity: 1;
  }

  button {
    background: ${props =>
      props?.button?.text === 'Acessar Faturas'
        ? 'blue'
        : props.theme.interface1};
    color: ${props =>
      props?.button?.text === 'Acessar Faturas'
        ? 'white'
        : props.theme.interface7};
    &:hover {
      background: ${props => props.theme.interface1};
      color: ${props => props.theme.interface7};
    }
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }

    button {
      width: 40px;
    }

    padding: 0;
  }
`;
