import styled, { css } from 'styled-components';

interface IInvoiceCardProps {
  selected?: boolean;
}

export const InvoiceCard = styled.div<IInvoiceCardProps>`
  width: 100%;
  height: 60px;
  background: ${props => props.theme.interface2};
  padding: 13px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid
    ${props =>
      props.selected ? props.theme.brand_primary_color : 'transparent'};
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px solid ${props => props.theme.brand_primary_color};
  }
`;

export const Field = styled.div`
  display: flex;
  height: 100%;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.div`
  color: ${props => props.theme.interface5};
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
`;

interface IValueProps {
  amount?: boolean;
  isPaid?: boolean;
  actions?: boolean;
}

export const Value = styled.div<IValueProps>`
  margin-top: 4px;
  font-size: 12px;
  text-transform: uppercase;

  color: ${props =>
    props.amount || props.isPaid
      ? props.theme.success
      : props.actions
      ? props.theme.label
      : props.theme.interface5};

  ${props =>
    props.amount &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.actions &&
    css`
      cursor: pointer;
    `}
`;
