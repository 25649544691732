export default {
  success: '#29D975',
  alert: '#F8820B',
  error: '#FB264B',
  label: '#353FF2',
  favorite: '#FFBF2D',
  successNew: '#29D975',
  alertNew: '#F8820B',
  errorNew: '#FB264B',
  labelNew: '#353FF2',
  favoriteNew: '#FFBF2D',
  interface1: '#000000',
  interface2: '#101218',
  interface3: '#E8EAEF',
  interface4: '#CFD3DE',
  interface5: '#64728C',
  interface6: '#545C68',
  interface7: '#333333',
};
