import React, { useState, useContext } from 'react';
import { Form } from 'components/Input';

import * as Yup from 'yup';
import { vldValidateWebsiteService } from 'services/migration';
import { Title, Header, Breadcrumbs, Breadcrumb } from 'components/Common';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'hooks';
import {
  MigrationCreateContext,
  MigrationCreateProvider,
} from '../_context/state';
import Dest from './Dest';
import Origin from './Origin';
import { Wrapper } from './styles';

const validateSchema = Yup.object().shape({
  domain: Yup.string().required('Por favor, insira um domínio.'),
});

const internalSchema = Yup.object().shape({
  domain: Yup.string().required('Por favor, insira um domínio.'),
  resource: Yup.string().required('Por favor, selecione um destino.'),
  nodeapp_src: Yup.string().required(
    'Por favor, selecione um email de origem.',
  ),
});

const externalSchema = Yup.object().shape({
  host: Yup.string().required('Por favor, insira um host.'),
  domain: Yup.string().required('Por favor, insira um domínio.'),
  password: Yup.string().required('Por favor, insira sua senha.'),
  user: Yup.string().required('Por favor, insira seu usuário.'),
  resource: Yup.string().required('Por favor, selecione um destino.'),
  resource_type: Yup.string(),
  website_type: Yup.string().when('resource_type', {
    is: r => r === 'node',
    then: Yup.string().required('Por favor, selecione um tipo de aplicação.'),
    otherwise: Yup.string(),
  }),
});

interface IselectedResourceData {
  type: string;
  id: number;
}

interface IselectedNodeappData {
  is_owner: boolean;
  id: number;
}

const MigrationCreateWebsite = () => {
  const { user } = useAuth();

  const { createMigration, createMigrationErrorAction } = useContext(
    MigrationCreateContext,
  );

  const [vldLoading, setVldLoading] = useState(false);
  const [vldPayload, setVldPayload] = useState(null);
  const [checked, setChecked] = useState(null);
  const [selectedResource, setSelectedResource] = useState(
    {} as IselectedResourceData,
  );
  const [sftp, setSftp] = useState(false);
  const [port, setPort] = useState(22);
  const [path, setPath] = useState('');
  const [host, setHost] = useState('');
  const [domain, setDomain] = useState('');
  const [ftpUser, setFtpUser] = useState({});
  const [autoPoint, setAutoPoint] = useState(false);
  const [websiteType, setWebsiteType] = useState('');
  const [isExternal, setIsExternal] = useState(false);
  const [selectedNodeapp, setSelectedNodeapp] = useState(
    {} as IselectedNodeappData,
  );

  const createWebsiteMigration = async payload => {
    try {
      createMigrationErrorAction(null);
      setVldLoading(true);
      let migration;
      if (!vldPayload.websites || isExternal) {
        let _payload;

        if (sftp) {
          _payload = {
            ...payload,
            user_id: user.id,
            migType: 1,
            sftp,
            path,
            port,
            website_type: websiteType,
          };
        } else {
          _payload = {
            ...payload,
            user_id: user.id,
            migType: 1,
            sftp: false,
            port: 21,
            website_type: websiteType,
          };
        }

        const res = await vldValidateWebsiteService(_payload);

        const { authenticated, type } = res.data;

        if (authenticated) {
          if (type === 1) {
            migration = {
              migType: 1,
              [selectedResource.type]: selectedResource.id,
              type,
              nodeapp_src: res.data.website,
              domain: payload.domain,
              auto_point_dns: autoPoint,
            };
          } else {
            migration = {
              migType: 1,
              [selectedResource.type]: selectedResource.id,
              type,
              src_host: payload.host,
              src_paths: res.data.files,
              src_port: port || 21,
              src_username: payload.user,
              src_password: payload.password,
              domain: payload.domain,
              website_type: websiteType !== '' ? websiteType : null,
            };
          }

          await createMigration(migration);
          setVldLoading(false);
        }
      } else {
        migration = {
          type: 1,
          is_owner: selectedNodeapp.is_owner,
          [selectedResource.type]: selectedResource.id,
          migType: 1,
          domain: payload.domain,
          nodeapp_src: selectedNodeapp.id,
          auto_point_dns: autoPoint,
          src_password: payload.password,
          src_username: payload.user,
        };

        await createMigration(migration);
        setVldLoading(false);
      }
    } catch (error) {
      setVldLoading(false);
      createMigrationErrorAction(
        error?.response?.data?.errors || error?.response?.data,
      );
    }
  };

  const checkIsHosted = async ({ host, domain }) => {
    createMigrationErrorAction(null);
    setVldLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_VLD_URL}domain`,
        {
          domain,
          host,
          user_id: user.id,
        },
        {
          headers: {
            Authorization: 'Bearer api_token_validator',
          },
        },
      );

      setVldPayload(data);
      setChecked(true);
      setVldLoading(false);
    } catch (error) {
      setVldLoading(false);
      createMigrationErrorAction(
        error?.response?.data?.errors || error?.response?.data,
      );
    }
  };

  return (
    <>
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/migrations">Migração</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/migrations/create">Nova migração</Link>
            </Breadcrumb>
          </Breadcrumbs>
          <FontAwesomeIcon
            icon={faExchangeAlt}
            style={{ marginRight: '10px' }}
          />
          Nova migração de website
        </Title>
      </Header>

      <Form
        schema={
          checked
            ? !vldPayload.websites || isExternal
              ? externalSchema
              : internalSchema
            : validateSchema
        }
        onSubmit={checked ? createWebsiteMigration : checkIsHosted}
      >
        <Wrapper>
          <Origin
            // checkIsHosted={checkIsHosted}
            checked={checked}
            vldPayload={vldPayload}
            // createWebsiteMigration={createWebsiteMigration}
            setFtpUser={setFtpUser}
            ftpUser={ftpUser}
            setPath={setPath}
            path={path}
            port={port}
            setPort={setPort}
            setSftp={setSftp}
            sftp={sftp}
            domain={domain}
            host={host}
            setHost={setHost}
            setDomain={setDomain}
            // disabled={!selectedResource.id}
            autoPoint={autoPoint}
            setAutoPoint={setAutoPoint}
            vldLoading={vldLoading}
            isExternal={isExternal}
            setIsExternal={setIsExternal}
            selectedNodeapp={selectedNodeapp}
            setSelectedNodeapp={setSelectedNodeapp}
          />
          <Dest
            disabled={!checked}
            // createWebsiteMigration={createWebsiteMigration}
            setSelectedResource={setSelectedResource}
            selectedResource={selectedResource}
            websiteType={websiteType}
            setWebsiteType={setWebsiteType}
            vldPayload={vldPayload}
            isExternal={isExternal}
          />
        </Wrapper>
      </Form>
    </>
  );
};

export default props => (
  <MigrationCreateProvider>
    <MigrationCreateWebsite {...props} />
  </MigrationCreateProvider>
);
