import styled, { css } from 'styled-components';
import requireContext from 'require-context.macro';

const reqSvgs = requireContext('../../assets/img/svg', true, /\.svg$/);

export const Wrapper = styled.div`
  margin-left: 12px;
`;

interface IIconProps {
  icon?: string | string[];
  disable?: boolean;
}

export const Icon = styled.img.attrs((props: IIconProps) => {
  let icon;

  try {
    icon = reqSvgs(`./${props.icon}.svg`).default;
  } catch (e) {
    icon = null;
  }

  return {
    src: icon,
  };
})<IIconProps>`
  width: ${props => props.width};
  height: ${props => props.height};

  ${props =>
    props.disable &&
    css`
      filter: grayscale(1);
      opacity: 0.2;
    `};
`;
