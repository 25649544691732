/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Header, Title } from 'components/Common';

import { useAuth, useDocTitle } from 'hooks';

import empty from 'assets/img/empty.png';
import EmptyState from './EmptyState';

import Invoices from './Invoices';
import Card from './Card';
import Summary from './Summary';
import { DashboardProvider } from './_context/state';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  useDocTitle('Dashboard - Cloudez Partners');

  return (
    <>
      <Header>
        <Title>Dashboard</Title>
      </Header>
      {user.roles.includes('technical_role' || 'customer_success_role') ? (
        <EmptyState>
          <span>
            Você não possui a permissão necessária para acessar as informações
            do Dashboard.
          </span>
          <img src={empty} alt="Empty State" />
        </EmptyState>
      ) : (
        <>
          <Row
            style={{
              marginBottom: '50px',
            }}
          >
            <Col xl="8">
              <Card />
            </Col>
            <Col xl="4">
              <Summary />
            </Col>
          </Row>

          <Row>
            <Invoices />
          </Row>
        </>
      )}
    </>
  );
};

export default (props: JSX.IntrinsicAttributes) => (
  <DashboardProvider>
    <Dashboard {...props} />
  </DashboardProvider>
);
