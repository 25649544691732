import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ListingHeader, Listing } from 'components/Listing/styles';

import CustomAnimatedList from 'components/CustomAnimatedList';
import TicketCard from './TicketCard';
import TicketPlaceholder from './Placeholder';
import { CustomerDetailContext } from '../_context/state';

const PlansListing = () => {
  const { companyPlan } = useContext(CustomerDetailContext);

  const filteredCompanyPlan = companyPlan?.filter(item => item.status !== 4);

  return (
    <>
      {!companyPlan ? (
        <TicketPlaceholder />
      ) : (
        <>
          {filteredCompanyPlan?.length ? (
            <>
              <ListingHeader>
                <Row>
                  <Col xs="10" sm="10" md="4" lg="3">
                    <p>Nome do plano</p>
                  </Col>
                  <Col lg="2">
                    <p>Status</p>
                  </Col>
                  <Col lg="4" className="d-none d-md-flex">
                    <p>Recurso</p>
                  </Col>
                  <Col className="d-none d-md-flex">
                    <p>Data de ativação</p>
                  </Col>
                </Row>
              </ListingHeader>

              <Listing>
                <CustomAnimatedList>
                  {companyPlan
                    ?.filter(item => item.status !== 4)
                    ?.map(plan => (
                      <TicketCard plan={plan} key={plan.id} />
                    ))}
                </CustomAnimatedList>
              </Listing>
            </>
          ) : null}
          {filteredCompanyPlan && filteredCompanyPlan?.length === 0 && (
            <p>Nenhum plano encontrado.</p>
          )}
        </>
      )}
    </>
  );
};

export default props => <PlansListing {...props} />;
