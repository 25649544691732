import { useLayout } from 'hooks/layout';
import { CSSProperties, useEffect } from 'react';

import { ModalWrapper, ModalContent } from './styles';

type ModalProps = {
  show: boolean;
  setShow?: (value?: boolean) => void;
  noExit?: boolean;
  width?: string;
  style?: CSSProperties;
  background?: string;
};

const Modal: React.FC<ModalProps> = ({
  children,
  show,
  setShow,
  noExit,
  width,
  style,
}) => {
  const { sideMenuCollapsed } = useLayout();

  useEffect(() => {
    if (show && !noExit) {
      // document.getElementById('root').style.overflow = 'hidden';
      document.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          setShow();
        }
      });
    }

    return () => {
      document.getElementById('root').style.overflow = '';
    };
    // eslint-disable-next-line
  }, [show, noExit]);

  return (
    <ModalWrapper
      data-testid="modal"
      className={show ? 'is_open' : 'is_closed'}
      sideMenuCollapsed={sideMenuCollapsed}
      onClick={e => {
        if (!noExit) {
          e.stopPropagation();
          setShow();
        }
      }}
    >
      <ModalContent
        id="modalContent"
        width={width}
        style={style}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
