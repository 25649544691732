import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormInput, Field, Label, Error, Radio } from 'components/Input';

import ApplicationIcon from 'components/ApplicationIcon';
import Pagination from 'components/Pagination';
import SearchInput from 'components/SearchInput';
import { createPagination } from 'utils/createPagination';
import { getCloudsService } from 'services/cloud';

import { getEmailsService } from 'services/email';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddCard, AddButton } from 'components/AddCard';
import history from 'services/history';
import EmailPlaceholder from './EmailPlaceholder';
import CloudPlaceholder from './CloudPlaceholder';
import { CloudWrapper, Card, Dest as DestStyle } from '../styles';
import { MigrationCreateContext } from '../../_context/state';

interface DestProperties {
  disabled: boolean;
  selectedResource: { id: number | string };
  setSelectedResource: any;
}

const Dest: React.FC<DestProperties> = ({
  disabled,
  selectedResource,
  setSelectedResource,
}) => {
  const { errors } = useContext(MigrationCreateContext);
  const [type, setType] = useState(1);

  const [emails, setEmails] = useState(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [searchEmail, setSearchEmail] = useState(null);
  const [search] = useState('');
  const [cloud, setCloud] = useState(null);
  const [cloudLoading, setCloudLoading] = useState(false);
  const [cloudSearch, setCloudSearch] = useState('');

  const getEmailsCallback = async (page?: number, search?: string) => {
    setEmailLoading(true);
    try {
      const { data } = await getEmailsService({
        page_size: 6,
        nomigration: 1,
        page,
        search,
      });

      const pagination = createPagination(data, 6);
      setEmails({ emails: data.results, ...pagination });
      setEmailLoading(false);
    } catch (e) {
      setEmailLoading(false);
    }
  };

  const getCloudsCallback = async (page?: number, search?: string) => {
    setCloudLoading(true);
    try {
      const { data } = await getCloudsService({
        page_size: 6,
        nomigration: 1,
        page,
        search,
      });

      const pagination = createPagination(data, 6);
      setCloud({ clouds: data.results, ...pagination });
      setCloudLoading(false);
    } catch (e) {
      setCloudLoading(false);
    }
  };

  const searchCloudValue = searchValue => {
    getCloudsCallback(1, searchValue);
  };

  const searchValueEmail = searchValue => {
    getEmailsCallback(1, searchValue);
  };

  useEffect(() => {
    getEmailsCallback(); // eslint-disable-next-line
    getCloudsCallback();
  }, []);

  return (
    <DestStyle disabled={disabled}>
      <h3>Destino</h3>

      <Field
        style={{
          marginBottom: '30px',
        }}
      >
        <Radio
          id="cloud"
          name="type"
          value="1"
          onChange={e => setType(Number(e.target.value))}
          checked={type === 1}
        />
        <Label onClick={() => setType(1)}>Cloud</Label>
        <Radio
          style={{
            marginLeft: '20px',
          }}
          id="email"
          name="type"
          value="2"
          onChange={e => setType(Number(e.target.value))}
          checked={type === 2}
        />
        <Label onClick={() => setType(2)}>Email</Label>
      </Field>

      {type === 1 && (
        <>
          <Label>Selecione o cloud de destino</Label>
          <CloudWrapper>
            <SearchInput
              style={{
                marginBottom: '20px',
              }}
              block
              setQ={setCloudSearch}
              q={cloudSearch}
              cb={searchCloudValue}
              noResults={cloudSearch && cloud?.clouds?.length === 0}
            />

            <>
              <Row
                style={{
                  width: '100%',
                  marginLeft: 0,
                }}
              >
                {cloud?.clouds?.length === 0 && (
                  <Col
                    lg="4"
                    sm="6"
                    xs="12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AddCard
                      width="100%"
                      height="110px"
                      onClick={() => history.push('/clouds/create')}
                    >
                      <AddButton row>
                        <FontAwesomeIcon icon={faPlusSquare} />
                        <span>adicionar</span>
                      </AddButton>
                    </AddCard>
                  </Col>
                )}
                {cloudLoading ? (
                  <CloudPlaceholder />
                ) : (
                  cloud?.clouds?.map(c => {
                    return (
                      <Col
                        key={c.id}
                        lg="4"
                        sm="6"
                        xs="12"
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Card
                          key={c.id}
                          cloud={c}
                          selected={c.id === selectedResource.id}
                          onClick={() =>
                            setSelectedResource({
                              id: c.id,
                              type: 'node',
                            })
                          }
                        >
                          <ApplicationIcon
                            icon={`${
                              c.cloud_provider.slug.includes('partner')
                                ? 'cloudez-footer'
                                : c.cloud_provider.slug === 'configr-g3' ||
                                  c.cloud_provider.slug === 'configr-g4' ||
                                  c.cloud_provider.slug === 'configr-g5'
                                ? 'configr'
                                : c.cloud_provider.slug
                            }`}
                            width="45px"
                            height="45px"
                          />
                          <div
                            style={{
                              textAlign: 'center',
                              width: '100%',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            <span>{c.name}</span>
                          </div>
                          <div
                            style={{
                              textAlign: 'center',
                              width: '100%',
                            }}
                          >
                            <p>{c.nickname}</p>
                          </div>
                        </Card>
                      </Col>
                    );
                  })
                )}
              </Row>
              <Row
                style={{
                  width: '100%',
                  marginTop: '-30px',
                  marginBottom: '20px',
                  marginLeft: 0,
                }}
              >
                <Pagination
                  onChangePage={getCloudsCallback}
                  count={cloud?.count}
                  previous={cloud?.previous}
                  next={cloud?.next}
                  current={cloud?.current}
                  search={cloudSearch}
                  pageSize="6"
                />
              </Row>
              <FormInput
                name="resource"
                style={{
                  display: 'none',
                }}
                value={selectedResource.id}
                onChange={() => {}}
                error={!!errors?.node}
              />
              {!!errors?.node && (
                <Error
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  {errors?.node}
                </Error>
              )}
            </>
            {errors &&
              Object.keys(errors).map(
                (e, i) =>
                  e !== 'domain' &&
                  e !== 'host' &&
                  e !== 'auth' &&
                  e !== 'username' &&
                  e !== 'password' &&
                  e !== 'path' &&
                  e !== 'accounts' &&
                  e !== 'port' && (
                    <Error
                      key={i}
                      style={{
                        fontSize: '14px',
                        marginBottom: '10px',
                      }}
                    >
                      {errors[e][0]}
                    </Error>
                  ),
              )}
          </CloudWrapper>
        </>
      )}

      {type === 2 && (
        <>
          <Label>Selecione o email de destino</Label>
          <CloudWrapper>
            <SearchInput
              style={{
                marginBottom: '20px',
              }}
              block
              setQ={setSearchEmail}
              q={searchEmail}
              cb={searchValueEmail}
              noResults={searchEmail && emails?.emails?.length === 0}
            />

            <>
              <Row
                style={{
                  width: '100%',
                  marginLeft: 0,
                }}
              >
                {emails?.emails?.length === 0 && (
                  <Col
                    lg="4"
                    sm="6"
                    xs="12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AddCard
                      width="100%"
                      height="110px"
                      onClick={() => history.push('/emails/create')}
                    >
                      <AddButton row>
                        <FontAwesomeIcon icon={faPlusSquare} />
                        <span>adicionar</span>
                      </AddButton>
                    </AddCard>
                  </Col>
                )}

                {emailLoading ? (
                  <EmailPlaceholder />
                ) : (
                  emails?.emails?.map(c => (
                    <Col
                      key={c.id}
                      lg="4"
                      sm="6"
                      xs="12"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Card
                        key={c.id}
                        cloud={c}
                        selected={c.id === selectedResource.id}
                        onClick={() =>
                          setSelectedResource({
                            id: c.id,
                            type: 'zoneapp',
                          })
                        }
                      >
                        <ApplicationIcon
                          icon={
                            c.type.slug === 'selfhosted-mail'
                              ? 'cloudez-footer'
                              : `${c.type.slug}`
                          }
                          width="45px"
                          height="45px"
                        />

                        <div
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          <span>{c.nickname || c.name || c.domain}</span>
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                            width: '100%',
                          }}
                        >
                          <p>{c.cloud_display}</p>
                        </div>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
              <Row
                style={{
                  width: '100%',
                  marginTop: '-30px',
                  marginBottom: '20px',
                  marginLeft: 0,
                }}
              >
                <Pagination
                  onChangePage={getEmailsCallback}
                  count={emails?.count}
                  previous={emails?.previous}
                  next={emails?.next}
                  current={emails?.current}
                  search={searchEmail}
                  pageSize="6"
                />
              </Row>
              <FormInput
                name="resource"
                style={{
                  display: 'none',
                }}
                value={selectedResource.id}
                onChange={() => {}}
                error={!!errors?.zoneapp}
              />
              {!!errors?.zoneapp && (
                <Error
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  {errors?.zoneapp}
                </Error>
              )}
            </>
            {errors &&
              Object.keys(errors).map(
                (e, i) =>
                  e !== 'domain' &&
                  e !== 'host' &&
                  e !== 'auth' &&
                  e !== 'username' &&
                  e !== 'password' &&
                  e !== 'path' &&
                  e !== 'port' &&
                  e !== 'accounts' && (
                    <Error
                      key={i}
                      style={{
                        fontSize: '14px',
                        marginBottom: '10px',
                      }}
                    >
                      {errors[e][0]}
                    </Error>
                  ),
              )}
          </CloudWrapper>
        </>
      )}
    </DestStyle>
  );
};

export default Dest;
