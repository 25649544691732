import { createContext, useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import CloseButton from 'components/ToastCloseButton';
import light from 'styles/themes/light';
import dark from 'styles/themes/dark';
import usePersistedState from 'utils/usePersistedState';
import { getSurveySlugService } from 'services/survey';
import { useAuth } from 'hooks';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import TermsModal from 'components/TermsModal';
import WebinarModal from 'components/WebinarModal';
import cloudezMini from 'assets/img/cloudez_mini.png';
import cloudez from 'assets/img/cloudez.png';

export const LayoutContext = createContext({} as any);

export const LayoutProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { user } = useAuth();

  const [data, setData] = useState(_state => {
    const layout = localStorage.getItem('@cloudez-partners-v3/layout');

    if (process.env.NODE_ENV !== 'development') {
      loadAnalytics('UA-106012387-1');
      loadGTM('GTM-K5CH3XM');
      // loadHeadwayApp();
    }

    if (layout) {
      return JSON.parse(layout);
    }

    return {
      sideMenuCollapsed: false,
      notification: {
        type: null,
        text: '',
        button: {},
      },
      brand_primary_color: '#0052EF',
      brand_secondary_color: '#7BE8FF',
      logo_primary: cloudez,
      logo_reduced: cloudezMini,
      loading: false,
      darkMode: false,
      ...light,
    };
  });

  const [answerLater, setAnswerLater] = usePersistedState(
    'answerLater',
    false,
    sessionStorage,
  );

  const setNotification = useCallback((text, type, button) => {
    setData(_data => {
      return {
        ..._data,
        notification: {
          type,
          text,
          button,
        },
      };
    });
  }, []);

  const toggleDarkMode = useCallback(() => {
    setData(_data => {
      const theme = _data.darkMode ? light : dark;

      localStorage.setItem(
        '@cloudez-partners-v3/layout',
        JSON.stringify({
          ...data,
          darkMode: !_data.darkMode,
          ...theme,
        }),
      );

      return {
        ..._data,
        darkMode: !_data.darkMode,
        ...theme,
      };
    });
  }, []);

  const toggleMenu = useCallback(() => {
    setData(_data => {
      return {
        ..._data,
        sideMenuCollapsed: !_data.sideMenuCollapsed,
      };
    });
  }, []);

  async function getSurvey() {
    try {
      const { data } = await getSurveySlugService('partner-survey');
      setData(_data => ({
        ..._data,
        partnerSurvey: data,
      }));
    } catch (e) {
      console.log(e);
    }
  }

  const [fetchedSurvey, setFetchedSurvey] = useState(false);

  useEffect(() => {
    if (user && !fetchedSurvey) {
      getSurvey();
      setFetchedSurvey(true);
    }
  }, [user]);

  function loadAnalytics(code) {
    console.log('analytics');

    if (code) {
      ReactGA.initialize(code);

      history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
    }
  }

  function loadHeadwayApp() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.text = `
    var config = {
      selector: "#bullhorn",
      account:  "J1W2nx"
    }
    Headway.init(config);
    `;

    document.querySelector('body').appendChild(script);
  }

  function loadGTM(code) {
    console.log('gtm');
    const { gtag } = window as any;

    if (code && !gtag) {
      const script1 = document.createElement('script');
      script1.type = 'text/javascript';

      script1.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${code}');
    `;

      const noscript = document.createElement('noscript');
      const iframe: HTMLIFrameElement = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${code}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.setAttribute('style', 'display:none;visibility:hidden');

      noscript.appendChild(iframe);

      document
        .querySelector('body')
        .insertBefore(noscript, document.querySelector('body').firstChild);
      document.querySelector('head').appendChild(script1);
    }
  }

  return (
    <LayoutContext.Provider
      value={{
        ...data,
        toggleMenu,
        toggleDarkMode,
        setNotification,
        answerLater,
        setAnswerLater,
        getSurvey,
      }}
    >
      <ThemeProvider theme={data}>
        <ToastContainer autoClose={7000} closeButton={<CloseButton />} />
        {/* <WebinarModal /> */}
        {user && !user.accepted_terms && <TermsModal />}

        {children}
      </ThemeProvider>
    </LayoutContext.Provider>
  );
};

export default {
  LayoutProvider,
};
