import * as Yup from 'yup';

export const staffSchema = Yup.object().shape({
  summary: Yup.string().required('Este campo é obrigatório.'),
  message: Yup.string().required('Este campo é obrigatório.'),
  owner: Yup.object().shape({
    value: Yup.string().required('Este campo é obrigatório.'),
    label: Yup.string().required('Este campo é obrigatório.'),
  }),
  team: Yup.object().shape({
    value: Yup.number().required('Este campo é obrigatório.'),
    label: Yup.string().required('Este campo é obrigatório.'),
  }),
  target_type: Yup.object()
    .when('team.value', {
      is: 1,
      then: Yup.object().shape({
        value: Yup.string().required('Este campo é obrigatório.'),
        label: Yup.string().required('Este campo é obrigatório.'),
      }),
      otherwise: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    })
    .when('team.value', {
      is: 4,
      then: Yup.object().shape({
        value: Yup.string().required('Este campo é obrigatório.'),
        label: Yup.string().required('Este campo é obrigatório.'),
      }),
      otherwise: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    }),
  target_id: Yup.number().required('Este campo é obrigatório.'),
});

export const schema = Yup.object().shape({
  summary: Yup.string().required('Este campo é obrigatório.'),
  message: Yup.string().required('Este campo é obrigatório.'),
  target_type: Yup.object()
    .when('team.value', {
      is: 1,
      then: Yup.object().shape({
        value: Yup.string().required('Este campo é obrigatório.'),
        label: Yup.string().required('Este campo é obrigatório.'),
      }),
      otherwise: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    })
    .when('team.value', {
      is: 4,
      then: Yup.object().shape({
        value: Yup.string().required('Este campo é obrigatório.'),
        label: Yup.string().required('Este campo é obrigatório.'),
      }),
      otherwise: Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    }),
  team: Yup.object().shape({
    value: Yup.number().required('Este campo é obrigatório.'),
    label: Yup.string().required('Este campo é obrigatório.'),
  }),
  target_id: Yup.number().required('Este campo é obrigatório.'),
});
