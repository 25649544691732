import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  ListingHeader,
  Listing,
  ListingItem,
  ApplicationValue,
  Ordering,
} from 'components/Listing/styles';

import { Acesso, AcessosList } from 'components/Acessos';
import CustomAnimatedList from 'components/CustomAnimatedList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const UserPlaceholder = ({ orderValue }) => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col xl="1">
            <p>
              Id
              <Ordering
                status={orderValue?.column === 'id' ? orderValue?.value : 0}
              >
                <FontAwesomeIcon icon={faAngleUp} />
                <FontAwesomeIcon icon={faAngleDown} />
              </Ordering>
            </p>
          </Col>

          <Col xl="5">
            <p>
              Email
              <Ordering
                status={orderValue?.column === 'email' ? orderValue?.value : 0}
              >
                <FontAwesomeIcon icon={faAngleUp} />
                <FontAwesomeIcon icon={faAngleDown} />
              </Ordering>
            </p>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <p>
              Valor
              <Ordering
                status={orderValue?.column === 'value' ? orderValue?.value : 0}
              >
                <FontAwesomeIcon icon={faAngleUp} />
                <FontAwesomeIcon icon={faAngleDown} />
              </Ordering>
            </p>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <p>
              Vencimento
              <Ordering
                status={
                  orderValue?.column === 'expiration' ? orderValue?.value : 0
                }
              >
                <FontAwesomeIcon icon={faAngleUp} />
                <FontAwesomeIcon icon={faAngleDown} />
              </Ordering>
            </p>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <p>Nota Fiscal</p>
          </Col>

          <Col xl="2" className="d-none d-xl-flex" />
        </Row>
      </ListingHeader>

      <Listing>
        <CustomAnimatedList>
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card>
                <CardContent>
                  <Col xl="1">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col xl="5">
                    <ApplicationValue>
                      <Placeholder width={200} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex d-xl-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex d-xl-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex d-xl-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col xl="2" className="d-none d-xl-flex">
                    <AcessosList>
                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>
                    </AcessosList>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </CustomAnimatedList>
      </Listing>
    </>
  );
};

export default UserPlaceholder;
