import React, { useContext, useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import { SketchPicker } from 'react-color';
import { Button } from '@cloudez/cloudez-design-system';
import { withTheme } from 'styled-components';

import { Input, Field, Label, Error } from 'components/Input';
import ImageCropper from 'components/ImageCropper';
import buildSelector from 'utils/buildSelector';
import { Wrapper, Color } from './styles';
import Preview from './Preview';
import { OnboardContext } from '../_context/state';

const Visual = ({
  theme,
  color1,
  color2,
  picker1,
  picker2,
  setColor1,
  setColor2,
  setPicker1,
  setPicker2,
  companyName,
  setCompanyName,
  companyDomain,
  setCompanyDomain,
  errors,
}) => {
  const [openReducedCrop, setOpenReducedCrop] = useState(false);
  const [openPrimaryCrop, setOpenPrimaryCrop] = useState(false);

  const [selectedLogo, setSelectedLogo] = useState('');

  const {
    setPrimaryLogo,
    setReducedLogo,
    setFavicon,
    primaryLogo,
    reducedLogo,
    favicon,
  } = useContext(OnboardContext);

  const [faviconInput] = useState(buildSelector(setFavicon));

  const handleConfirm = file => {
    switch (selectedLogo) {
      case 'primary':
        setPrimaryLogo(file);
        break;
      case 'reduced':
        setReducedLogo(file);
        break;
      case 'favicon':
        setFavicon(file);
        break;
      default:
        break;
    }
  };

  const handleSelectClick = logo => {
    switch (logo) {
      case 'primary':
        setOpenPrimaryCrop(true);
        break;
      case 'reduced':
        setOpenReducedCrop(true);
        break;
      case 'favicon':
        break;
      default:
        break;
    }

    setSelectedLogo(logo);
  };

  const styles = {
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  return (
    <>
      <Wrapper>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            width: '600px',
            marginTop: '40px',
          }}
        >
          <Col>
            <Field>
              <Label required>Nome da empresa *</Label>
              <Input
                value={companyName}
                autoFocus
                onChange={e => setCompanyName(e.target.value)}
              />
            </Field>
          </Col>
          <Col>
            <Field>
              <Label required>Domínio de acesso ao cloud *</Label>
              <Input
                value={companyDomain}
                onChange={e => setCompanyDomain(e.target.value)}
                placeholder="Ex: cloud.cloudez.io"
                error={!!errors.app_domains}
              />
              {errors.app_domains && <Error>{errors.app_domains[0]}</Error>}
            </Field>
          </Col>
        </Row>

        <div
          style={{
            width: '700px',
            margin: 'auto',
            marginTop: '40px',
          }}
        >
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Col xl="1">
              {picker1 && (
                <div style={styles.popover as React.CSSProperties}>
                  <div
                    style={styles.cover as React.CSSProperties}
                    onClick={() => setPicker1(false)}
                  />
                  <SketchPicker
                    disableAlpha
                    color={color1}
                    onChange={(c, e) => {
                      setColor1(c.hex);
                    }}
                  />
                </div>
              )}
              <Color
                color={color1}
                onClick={() => {
                  setPicker1(!picker1);
                  setPicker2(false);
                }}
              />
            </Col>
            <Col xl="3">
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: theme.interface5,
                }}
              >
                Cor primária
              </span>
            </Col>
            <Col
              xl="3"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: theme.brand_primary_color,
                  width: '100%',
                }}
              >
                {(primaryLogo && primaryLogo.name) || 'Logo Horizontal *'}
              </span>
              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '10px',
                  color: theme.interface5,
                  textAlign: 'start',
                  marginTop: '4px',
                }}
              >
                Tamanho sugerido: 170x60px
              </p>
              <p
                style={{
                  textAlign: 'start',
                  marginTop: '-1px',
                  fontWeight: 'normal',
                  fontSize: '10px',
                  color: theme.interface5,
                }}
              >
                Formato: .png
              </p>
            </Col>
            <Col
              xl="4"
              style={{
                marginLeft: '-30px',
              }}
            >
              <Button outline onClick={() => handleSelectClick('primary')}>
                Selecionar
              </Button>
              {errors.logo_primary && <Error>{errors.logo_primary[0]}</Error>}
            </Col>
            <Col xl="1">
              {primaryLogo && (
                <img
                  alt="file"
                  style={{ height: '50px' }}
                  src={primaryLogo && URL.createObjectURL(primaryLogo)}
                />
              )}
            </Col>
          </Row>

          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <Col xl="1">
              {picker2 && (
                <div style={styles.popover as React.CSSProperties}>
                  <div
                    style={styles.cover as React.CSSProperties}
                    onClick={() => setPicker2(false)}
                  />
                  <SketchPicker
                    disableAlpha
                    color={color2}
                    onChange={(c, e) => {
                      setColor2(c.hex);
                    }}
                  />
                </div>
              )}
              <Color
                color={color2}
                onClick={() => {
                  setPicker2(!picker2);
                  setPicker1(false);
                }}
              />
            </Col>
            <Col xl="3">
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: theme.interface5,
                }}
              >
                Cor secundária
              </span>
            </Col>

            <Col
              xl="3"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: theme.brand_primary_color,
                  width: '100%',
                }}
              >
                {(reducedLogo && reducedLogo.name) || 'Logo Quadrada *'}
              </span>
              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '10px',
                  color: theme.interface5,
                  textAlign: 'start',
                  marginTop: '4px',
                }}
              >
                Tamanho sugerido: 60x60px
              </p>
              <p
                style={{
                  textAlign: 'start',
                  marginTop: '-1px',
                  fontWeight: 'normal',
                  fontSize: '10px',
                  color: theme.interface5,
                }}
              >
                Formato: .png
              </p>
            </Col>
            <Col
              xl="4"
              style={{
                marginLeft: '-30px',
              }}
            >
              <Button outline onClick={() => handleSelectClick('reduced')}>
                Selecionar
              </Button>
              {errors.logo_reduced && <Error>{errors.logo_reduced[0]}</Error>}
            </Col>
            <Col xl="1">
              {reducedLogo && (
                <img
                  alt="file"
                  style={{ height: '50px' }}
                  src={reducedLogo && URL.createObjectURL(reducedLogo)}
                />
              )}
            </Col>
          </Row>

          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <Col xl="4" />
            <Col
              xl="3"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: theme.brand_primary_color,
                  width: '100%',
                }}
              >
                {(favicon && favicon.name) || 'Favicon *'}
              </span>
            </Col>
            <Col
              xl="4"
              style={{
                marginLeft: '-30px',
              }}
            >
              <Button outline onClick={() => faviconInput.click()}>
                Selecionar
              </Button>
              {errors.favicon && <Error>{errors.favicon[0]}</Error>}
            </Col>
            <Col xl="1">
              {favicon && (
                <img
                  alt="file"
                  style={{ height: '50px' }}
                  src={favicon && URL.createObjectURL(favicon)}
                />
              )}
            </Col>
          </Row>
        </div>

        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: '30px',
            color: theme.interface5,
          }}
        >
          <div>
            <span>
              Essas configurações se aplicam ao painel de gerenciamento de
              clouds.
            </span>
            <p>O painel de parceiro não é configurável.</p>
          </div>
        </Row>

        <Preview
          primary={color1}
          secondary={color2}
          logo1={primaryLogo}
          logo2={reducedLogo}
        />
      </Wrapper>

      <ImageCropper
        onConfirm={handleConfirm}
        show={openPrimaryCrop}
        setShow={setOpenPrimaryCrop}
        cropSize={{
          width: 340,
          height: 120,
        }}
        title="LOGO PRIMÁRIA"
        subtitle="Escolha a logo primária - 170x60px"
      />

      <ImageCropper
        onConfirm={handleConfirm}
        show={openReducedCrop}
        setShow={setOpenReducedCrop}
        cropSize={{
          width: 60,
          height: 60,
        }}
        title="LOGO REDUZIDA"
        subtitle="Escolha a logo reduzida - 60x60px"
      />

      {/* <ImageCropper
        onConfirm={handleConfirm}
        show={openFaviconCrop}
        setShow={setOpenFaviconCrop}
        cropSize={{
          width: 32,
          height: 32,
        }}
        title="FAVICON"
        subtitle="Escolha o favicon - 16x16px ou 32x32px"
      /> */}
    </>
  );
};

export default withTheme(Visual as any);
