import React from 'react';
import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  ListingHeader,
  Listing,
  ListingItem,
  ApplicationValue,
  CloudValue,
} from 'components/Listing/styles';

import { Acesso, AcessosList } from 'components/Acessos';

const MigrationPlaceholder = (): JSX.Element => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col
            style={{ paddingLeft: '70px' }}
            xs="8"
            sm="8"
            md="4"
            lg="4"
            xl="3"
          >
            <p>Domínio</p>
          </Col>
          <Col xl="1" lg="3" md="3" className="d-none d-sm-none d-md-flex">
            Progresso
          </Col>
          <Col xl="2" lg="4" md="4" className="d-none d-xl-flex">
            Etapa Atual
          </Col>
          <Col className="d-none d-xl-flex">
            <p>Criado</p>
          </Col>
          <Col className="d-none d-xl-flex">
            <p>Atualizado</p>
          </Col>
          <Col
            style={{ marginLeft: '20px' }}
            className="d-none d-sm-flex d-md-flex"
          >
            <p>Status</p>
          </Col>
          <Col className="d-none d-xl-flex">
            <p>Avaliação</p>
          </Col>
          <Col xl="1" className="d-none d-xl-flex" />
        </Row>
      </ListingHeader>

      <Listing>
        <AnimatedList initialAnimationDuration={800} animation="grow">
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card>
                <CardContent>
                  <Col xs sm md="4" lg="4" xl="4">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 15,
                      }}
                    >
                      <Placeholder circle width={40} height={40} />
                      <span style={{ marginLeft: 12 }}>
                        <Placeholder width={200} height={12} />
                      </span>
                    </div>
                  </Col>

                  <Col xl="3" className="d-none d-xl-flex">
                    <ApplicationValue style={{ fontWeight: 'normal' }}>
                      <CloudValue>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Placeholder />
                          <Placeholder height={12} width={230} />
                        </div>
                      </CloudValue>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={8} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={8} />
                    </ApplicationValue>
                  </Col>

                  <Col xl="1" className="d-none d-xl-flex">
                    <AcessosList>
                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>
                    </AcessosList>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </AnimatedList>
      </Listing>
    </>
  );
};

export default MigrationPlaceholder;
