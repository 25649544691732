import React, { useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { toast } from 'react-toastify';

import { uploadProofOfPaymentService } from 'services/invoices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button, Error, Modal } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';
import { StyledDropzone } from 'components/NewComponents/Dropzone';
import { Content } from './styles';

interface ReactivateModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  id: number;
  proofSent: () => void;
}

const ReactivateModal = ({
  show,
  setShow,
  id,
  proofSent,
}: ReactivateModalProps): JSX.Element => {
  const { acceptedFiles, getInputProps, getRootProps } = useDropzone();

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const removeFile = file => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
  };

  const submitProofOfPayment = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const fd = new FormData();

    if (acceptedFiles.length === 0) {
      setShowError(true);
      return;
    }

    fd.append('proof_of_payment', acceptedFiles[0]);

    try {
      setLoading(true);

      await uploadProofOfPaymentService(id, fd, config);
      proofSent();
      setLoading(false);
      setShowSuccess(true);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.proof_of_payment[0]);
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} />
        </Icon>
        <Text>
          <Title>Confirmar pagamento</Title>
          <Subtitle>Envie seu comprovante para reativar seus produtos</Subtitle>
        </Text>
      </Header>
      <Content>
        {showSuccess ? (
          <div className="successBox">
            <span className="success">Comprovante enviado com sucesso!</span>
            <span>
              Se não houverem contratempos, em até 10 (dez) minutos o seu cloud
              será reativado.
            </span>
          </div>
        ) : (
          <>
            <span>
              Para a reativação do cloud, envie o comprovante de pagamento a
              seguir.
            </span>
            <span>
              A reativação ocorrerá em até 10(dez) minutos e ficará ativo por
              até 3(três) dias.
            </span>
            <div className="box">
              <StyledDropzone
                acceptedFiles={acceptedFiles}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
              />
              {showError && <Error>Esse campo é obrigatório.</Error>}
            </div>
          </>
        )}
      </Content>
      <Footer style={{ justifyContent: 'space-between' }}>
        <Button onClick={() => setShow(false)} text outline>
          Fechar
        </Button>
        {!showSuccess && (
          <Button onClick={submitProofOfPayment} disabled={loading} icon>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Enviar comprovante'
            )}
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default ReactivateModal;
